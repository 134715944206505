import axios from 'axios';

import {
  Form,
  QuestionForm,
  NewBlobDocument,
  Document,
  ConfirmCompleteDocument,
  UploadTemplateFormBody,
  UploadTemplateFormInput,
  BaseResponse,
} from 'types';
import { GET_CASE, GET_DOCUMENT, GET_FORM } from 'lib/config';

import { getDefaultHeader } from '../utils';
import { DOCUMENT_STATUS_IDS } from 'lib/document';
import { stampDocument, updateDocument } from 'api/document';
import { PROMISE_ALL_SETTLED_RESULT, allSettledPolyfill } from 'lib/promise';

const putFile = async (endpoint: string, data: any) => {
  return axios.put(endpoint, data, { headers: { 'Content-Type': 'multipart/form-data' } });
};

export async function getForms(
  caseId: number | string,
  sendToPatient: number
): Promise<Array<Form>> {
  const response = await axios.get(`${GET_CASE}/${caseId}/forms`, {
    params: {
      sendToPatient,
    },
    headers: getDefaultHeader(),
  });

  return response.data;
}

export async function getForm(formId: number | string, formKey?: string): Promise<QuestionForm> {
  const response = await axios.get(`${GET_FORM}/${formId}`, {
    headers: getDefaultHeader(formKey),
  });

  return response.data;
}

export async function getFormUrl(formId: number | null, patientId): Promise<string> {
  const response = await axios.get(`${GET_FORM}/${formId}/formUrl`, {
    headers: getDefaultHeader(),
    params: {
      patientId,
    },
  });

  return response.data;
}

export async function updateFormAnswers(
  formId: number | string,
  data: object,
  formKey?: string
): Promise<QuestionForm> {
  const response = await axios.put(`${GET_FORM}/${formId}/answers`, data, {
    headers: getDefaultHeader(formKey),
  });

  return response.data;
}

export async function generateNewSetOfForms(caseId: number): Promise<QuestionForm> {
  const response = await axios(`${GET_CASE}/${caseId}/newSetOfForms`, {
    method: 'POST',
    headers: getDefaultHeader(),
  });

  return response.data;
}

export async function generateNewForm(
  formId: number | string,
  caseId: number
): Promise<QuestionForm> {
  const response = await axios.post(
    `${GET_CASE}/${caseId}/newForm`,
    {
      formId: formId,
    },
    {
      headers: getDefaultHeader(),
    }
  );

  return response.data;
}

export async function sendForm(formId: number | string): Promise<QuestionForm> {
  const response = await axios(`${GET_FORM}/${formId}/send`, {
    method: 'POST',
    headers: getDefaultHeader(),
  });

  return response.data;
}

interface CheckBirthDateResult {
  isBirthDate: boolean;
}

export async function checkBirthDateForPatient(
  formId: number | string,
  dateOfBirth: string,
  formKey?: string
): Promise<CheckBirthDateResult> {
  const response = await axios.get(`${GET_FORM}/${formId}/dateOfBirth`, {
    params: {
      date: dateOfBirth,
    },
    headers: getDefaultHeader(formKey),
  });

  return response.data;
}

export async function confirmCompleteDocument(documentId: number, token?: string): Promise<void> {
  await axios.put(
    `${GET_FORM}/${documentId}/confirm`,
    {},
    {
      headers: getDefaultHeader(token),
    }
  );
}

export async function submitFormDocument(
  formId: number | string,
  data: NewBlobDocument,
  token?: string,
  signingDoctorId?: number,
  signingDoctorBillingNumber?: string
): Promise<ConfirmCompleteDocument> {
  /* The Server Endpoint will
   * Gets Correct Document type from form type
   * Creates completed document in the user case
   * Replaces form document url with new one
   * Creates signed request to upload document
   */

  const fd = new FormData();

  fd.append('name', data.name);
  fd.append('mimeType', data.mimeType);
  fd.append('fileName', data.fileName);

  const response = await axios.post(
    `${GET_FORM}/${formId}/submitFormDocument`,
    {
      name: data.name,
      mimeType: data.mimeType,
      fileName: data.fileName,
      softDeleteDocument: data.softDelete,
    },
    {
      headers: getDefaultHeader(token),
    }
  );
  const uploadUrl = response.data.uploadSignedRequest;
  const uploadStatuses = await allSettledPolyfill([
    putFile(uploadUrl.signedRequest, data.document),
  ]);
  const uploadStatusId =
    uploadStatuses[0].status == PROMISE_ALL_SETTLED_RESULT.FULFILLED
      ? DOCUMENT_STATUS_IDS.SUCCESSFUL
      : DOCUMENT_STATUS_IDS.FAILED;

  await updateDocument(
    response.data.id,
    {
      documentStatusId: uploadStatusId,
    },
    token
  );
  let signedRequest = response.data.viewSignedRequest.signedRequest;
  if (uploadStatusId != DOCUMENT_STATUS_IDS.FAILED) {
    const stampedResponse = await stampDocument(
      response.data.id,
      formId,
      token,
      signingDoctorId,
      signingDoctorBillingNumber
    );
    signedRequest = stampedResponse.signedRequest;
  }
  return { documentId: response.data.id, signedRequest };
}

export async function uploadTemplateForm(input: UploadTemplateFormInput): Promise<BaseResponse> {
  const data: UploadTemplateFormBody = {
    file: {
      fileName: input.fileName,
      mimeType: input.mimeType,
    },
    template: {
      title: input.title,
      description: input.description,
      templateTypeId: input.templateTypeId,
      templateKey: input.templateKey,
      templateTypeDescription: input.templateTypeDescription,
    },
    permissions: input.permissions,
    doctorSignature: input.doctorSignature,
  };

  const response = await axios.post(`${GET_FORM}/uploadTemplateForm`, data, {
    headers: getDefaultHeader(),
  });

  const uploadUrl = response.data.uploadSignedRequest.signedRequest;
  try {
    const awsResponse = await putFile(uploadUrl, input.file);
    return { success: true };
  } catch (error) {
    throw error;
  }
}

export async function deleteFormTemplate(id: string): Promise<BaseResponse> {
  await axios.delete(`${GET_FORM}/deleteTemplateAndAllForms/${id}`, {
    headers: getDefaultHeader(),
  });

  return { success: true };
}
