import moment from 'moment';

import { Holidays } from './holidays';

export class NovaScotia extends Holidays {
  private observedHolidays: moment.Moment[] = [];
  private yearsInitialized: { [index: number]: boolean } = {};

  public getobservedHolidays(year: number): moment.Moment[] {
    this.initializeHolidaysForYear(year);
    return this.observedHolidays;
  }

  public isDateIsHoliday(date: moment.Moment): boolean {
    this.initializeHolidaysForYear(date.year());
    return this.observedHolidays.some((d: moment.Moment) => d.isSame(date, 'day'));
  }

  private initializeHolidaysForYear(year: number): void {
    if (this.yearsInitialized[year]) return;
    this.yearsInitialized[year] = true;
    this.observedHolidays.push(this.newYear(year));
    this.observedHolidays.push(this.familyDay(year)); //heratige day
    this.observedHolidays.push(this.goodFriday(year));
    this.observedHolidays.push(this.canadaDay(year));
    this.observedHolidays.push(this.labourDay(year));
    this.observedHolidays.push(this.christmasDay(year));
  }
}

export default NovaScotia;
