import React, { ReactElement, useContext, useEffect, useState } from 'react';
import Dialog from 'components/Dialog';
import styles from './AppointmentHistoryReportDialog.module.scss';
import DialogButton from 'components/DialogButton';
import { DialogButtonType } from 'components/DialogButton/DialogButton.view';
import classNames from 'classnames';
import { NewDocument, User } from 'types';
import PagePopup from 'pages/Patient/components/PagePopup';
import { MIME_TYPE_PDF } from 'lib/document';
import useAppointmentHistoryPDFRender from './AppointmentHistoryReportRender.hook';
import { PDFDownloadLink } from '@react-pdf/renderer';
import {
  AppointmentHistoryReportHead,
  AppointmentHistoryReportRow,
} from './AppointmentHistoryReportRow';
import { DateTimeFormat, formatDate } from 'lib/dateFormatter';
import { AppointmentInfo } from 'api/appointment/appointment.interfaces';
import moment from 'moment';
import { DocumentApi } from 'api';
import * as logger from 'api/logger';

interface AppointmentHistoryReportDialogProps {
  isOpen?: boolean;
  onClose?: () => void;
  appointments?: Array<AppointmentInfo>;
  patient: User | null;
  history: any;
  handelAlert: (arg1: string, arg2?: string) => void;
}

export type CreatePdf = {
  id: number;
  header: string;
  signedUrl: string;
  fileName: string;
};

function AppointmentHistoryReportDialog({
  isOpen = false,
  onClose,
  patient,
  appointments = [],
  history,
  handelAlert,
  ...rest
}: AppointmentHistoryReportDialogProps): ReactElement {
  const [loading, setLoading] = useState(false);
  const [openPagePopup, setOpenPagePopup] = useState<boolean>(false);
  const [selectedUrl, setSelectedUrl] = useState<any>();
  const [pdfUrl, setPdfUrl] = useState('');

  const [pdfDetails, setPDFDetails] = useState<{
    patientName: string;
    patientBirthday: string;
    patientHealthCardNumber: string;
    patientGender: string;
    appointments: Array<AppointmentInfo>;
  }>({
    patientName: '',
    patientBirthday: '',
    patientHealthCardNumber: '',
    patientGender: '',
    appointments: [],
  });
  const { AppointmentHistoryReportLayout } = useAppointmentHistoryPDFRender(pdfDetails);

  const updateReportAppointment = (appointment: AppointmentInfo, add: boolean) => {
    const temp = JSON.parse(JSON.stringify(pdfDetails));
    if (add) {
      temp.appointments.push(appointment);
    } else {
      temp.appointments = temp.appointments.filter(appt => {
        return appt.id != appointment.id;
      });
    }
    setPDFDetails(temp);
  };

  useEffect(() => {
    setPDFDetails({
      ...pdfDetails,
      patientName: patient?.fullName || '',
      patientBirthday: patient?.patientInfo?.dateOfBirth || '',
      patientHealthCardNumber: patient?.patientInfo?.hcn || '',
      patientGender: patient?.patientInfo?.gender || '',
      appointments: [],
    });
  }, [patient, appointments]);

  const seePreview = async () => {
    try {
      setOpenPagePopup(true);
      setSelectedUrl(pdfUrl);
    } catch (e) {
      console.error('Error', e);
    }
  };

  const makeDocument = async () => {
    const fileResult = await fetch(pdfUrl);
    const blob = await fileResult.blob();
    const fileName = `Appointment_History_Report_${formatDate(
      DateTimeFormat.DateAndTimeWithoutMeridies,
      moment()
    )}.pdf`;

    const fileObject = new File([blob], fileName, { type: MIME_TYPE_PDF });

    const document: NewDocument = {
      name: fileName,
      typeId: 1,
      document: fileObject,
    };
    return document;
  };

  const save = async (sendToPatient = false) => {
    try {
      setLoading(true);
      if (patient?.patientInfo?.caseId) {
        const document = await makeDocument();
        const result = (await DocumentApi.createDocument(
          patient?.patientInfo?.caseId,
          document
        )) as any;
        if (sendToPatient) {
          await DocumentApi.sendMultipleDocuments(
            [result[0].value.data.id],
            patient.email,
            patient.id,
            ''
          );
        }
      }
    } catch (e) {
      logger.error(e.message);
      handelAlert(`Failed to Create  ${sendToPatient ? 'and/or Send ' : ''}Document`);
      return;
    }
    handelAlert(`Document Created ${sendToPatient ? 'and Sent ' : ''}Successfully!`, 'Success!');
    onClose && onClose();
    setLoading(false);
  };

  const clearPdf = () => {
    setPDFDetails({ ...pdfDetails, appointments: [] });
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        onClose && onClose();
        clearPdf();
      }}
      title={'Create Appointment History Report'}
    >
      <PDFDownloadLink document={AppointmentHistoryReportLayout(pdfDetails)}>
        {({ blob, url, loading, error }) => {
          if (url && pdfDetails) {
            setPdfUrl(url);
          }
          return <div></div>;
        }}
      </PDFDownloadLink>
      <PagePopup
        open={openPagePopup}
        type={MIME_TYPE_PDF}
        url={selectedUrl}
        onClose={() => setOpenPagePopup(false)}
      />
      <div className={classNames(styles.dialogBody, styles.padding)}>
        <div className={styles.appointmentSelectionTable}>
          <AppointmentHistoryReportHead />
          {appointments.map((appt: AppointmentInfo, index: number) => {
            return (
              <AppointmentHistoryReportRow
                key={`AppointmentHistoryReportRow_${index}`}
                appointment={appt}
                isOdd={index % 2 == 1}
                addAppointmentToReport={updateReportAppointment}
              />
            );
          })}
        </div>
        <div className={classNames(styles.row, styles.buttonRow)}>
          <div className={classNames(styles.button, styles.padding)}>
            <DialogButton
              buttonType={DialogButtonType.NegationLink}
              onClick={() => {
                onClose && onClose();
                clearPdf();
              }}
            >
              Cancel
            </DialogButton>
          </div>

          <div className={classNames(styles.button, styles.padding)}>
            <DialogButton
              buttonType={DialogButtonType.AffirmativeLink}
              onClick={seePreview}
              disabled={!pdfDetails.appointments.length}
            >
              Preview
            </DialogButton>
          </div>

          <div className={classNames(styles.button, styles.padding)}>
            <DialogButton
              buttonType={DialogButtonType.Bordered}
              loading={loading}
              onClick={() => {
                save(false);
              }}
              disabled={!pdfDetails.appointments.length}
            >
              Save
            </DialogButton>
          </div>

          <div className={classNames(styles.button, styles.padding)}>
            <DialogButton
              buttonType={DialogButtonType.Bordered}
              loading={loading}
              onClick={() => {
                save(true);
              }}
              disabled={!patient?.email || !pdfDetails.appointments.length}
            >
              Save And Send To Patient
            </DialogButton>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default AppointmentHistoryReportDialog;
