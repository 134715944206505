import React, { ReactElement } from 'react';
import Link from '@material-ui/core/Link';
import Paper from 'components/Paper';
import TextCollapse from 'components/NotesAndTasks/components/TextCollapse';
import styles from './NoteStyles.module.scss';
import { User } from 'types';

interface NoteProps {
  title?: string;
  createdAt?: string;
  createdBy?: string;
  updatedAt?: string;
  updatedBy?: string;
  description?: any;
  className?: string;
  onSendNoteClick: (noteId) => void;
  onDeleteNoteClick: (noteId) => void;
  onEditNoteClick: (noteId) => void;
  userLogged: User | null;
}

function Note({
  title,
  createdAt,
  createdBy,
  updatedAt,
  updatedBy,
  description,
  className,
  userLogged,
  onSendNoteClick,
  onDeleteNoteClick,
  onEditNoteClick,
}: NoteProps): ReactElement {
  const infoRow = (title, info) => {
    return (
      <div className={styles.infoRow}>
        <div className={styles.infoTitle}>{`${title}:`}</div>
        <div className={styles.info}>{info}</div>
      </div>
    );
  };

  return (
    <Paper className={className}>
      <div className={styles.title}>{title}</div>
      {infoRow('Created At', createdAt)}
      {infoRow('Created By', createdBy)}
      {infoRow('Updated At', updatedAt)}
      {infoRow('Updated By', updatedBy)}
      <div className={styles.description}>
        <TextCollapse description={description}></TextCollapse>
      </div>
      <div className={styles.buttons}>
        {userLogged && userLogged.type === 'admin' && (
          <Link className={styles.link} onClick={onDeleteNoteClick}>
            Delete
          </Link>
        )}
        <Link className={styles.link} onClick={onSendNoteClick}>
          Send
        </Link>
        <Link className={styles.link} onClick={onEditNoteClick}>
          Edit
        </Link>
      </div>
    </Paper>
  );
}

export default Note;
