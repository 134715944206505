import React, { ReactElement } from 'react';

import MultipleQuestionSection from './MultipleQuestionSection.view';
import OpenQuestionSection from './OpenQuestionSection.view';
import NumberQuestionSection from './NumberQuestionSection.view';
import RadioQuestionSection from './RadioQuestionSection.view';
import BooleanQuestionSection from './BooleanQuestionSection.view';
import ImageQuestionSection from './ImageQuestionSection.view';
import TextQuestionSection from './TextQuestionSection.view';
import DateQuestionSection from './DateQuestionSection.view';
import OpenChoiceOption from './OpenChoiceOption.view';
import DropDownQuestionSection from './DropDownQuestionSection.view';

interface QuestionSectionProps {
  title?: string;
  variant?: string;
  description?: string;
  options?: Array<any>;
  value: Array<any>;
  defaultValue: Array<any>;
  required: boolean;
  onChange: (value: any) => void;
  optionCapitalize: (option: string) => string;
}

function QuestionSection({
  title,
  description = '',
  variant = 'multi',
  value,
  defaultValue,
  required,
  ...rest
}: QuestionSectionProps): ReactElement | null {
  switch (variant) {
    case 'multi':
      return (
        <MultipleQuestionSection
          {...{ ...rest, required, value, defaultValue, title, description }}
        />
      );
    case 'open':
    case 'text':
      if (!title || title === '') {
        return (
          <OpenChoiceOption {...{ ...rest, required, value, defaultValue, title, description }} />
        );
      }

      return (
        <OpenQuestionSection {...{ ...rest, required, value, defaultValue, description, title }} />
      );
    case 'date':
      return (
        <DateQuestionSection {...{ ...rest, required, value, defaultValue, title, description }} />
      );
    case 'number':
      return (
        <NumberQuestionSection
          {...{ ...rest, required, value, defaultValue, title, description }}
        />
      );
    case 'radio':
      return (
        <RadioQuestionSection {...{ ...rest, required, value, defaultValue, title, description }} />
      );
    case 'boolean':
      return (
        <BooleanQuestionSection
          {...{ ...rest, required, value, defaultValue, title, description }}
        />
      );
    case 'statement':
      return <TextQuestionSection {...{ required, value, defaultValue, title, description }} />;
    case 'staticImage':
      return <ImageQuestionSection {...{ required, value, defaultValue, title, description }} />;
    case 'dropDown':
      return (
        <DropDownQuestionSection
          {...{ ...rest, required, value, defaultValue, title, description }}
        />
      );
    default:
      return null;
  }
}

export default QuestionSection;
