import React, { ReactElement, useContext } from 'react';
import classNames from 'classnames';
import Link from '@material-ui/core/Link';
import Avatar from '@material-ui/core/Avatar';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import { User } from 'types';

import RowRecord from 'components/RowRecord';
import ConditionDiv from 'components/ConditionDiv';

import styles from './UserInfoModalStyles.module.scss';
import { Context as AuthContext } from '../../context/auth';

interface UserInfoModalProp {
  user?: User;
  open: boolean;
  doLogout: () => void;
  onEdit: () => void;
  onClose: () => void;
  onReset: () => void;
}

function UserInfoModal({ open, user, onEdit, onClose, onReset }: UserInfoModalProp): ReactElement {
  const { logout } = useContext(AuthContext);
  const phone = user?.contacts.filter(contact => contact.type === 'phone')[0]?.value;

  return (
    <React.Fragment>
      <ConditionDiv condition={open} className={styles.modalBackground} onClick={onClose} />
      <ConditionDiv condition={open} className={styles.container}>
        <div className={styles.body}>
          <Avatar className={styles.avatar} />
          <div className={styles.info}>
            <div className={styles.top}>
              <b className={styles.name}>
                {user?.firstName} {user?.lastName}
              </b>
              <IconButton aria-label="close" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className={styles.userRole}>{user?.type}</div>
            <RowRecord name={'Number'} value={phone || ''} className={styles.padding} />
            <RowRecord name={'Email'} value={user?.email || ''} className={styles.padding} />
            <div className={styles.bottom}>
              <div className={styles.buttons}>
                <Link className={styles.button} onClick={onEdit}>
                  Edit
                </Link>
                <Link className={styles.button} onClick={onReset}>
                  Change Password
                </Link>
                <Link className={classNames(styles.button, styles.logOut)} onClick={logout}>
                  Log Out
                </Link>
              </div>
            </div>
          </div>
        </div>
      </ConditionDiv>
    </React.Fragment>
  );
}

export default UserInfoModal;
