import { TextField } from '@material-ui/core';
import React, { ReactElement, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './PossiblePatientItem.module.scss';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import moment from 'moment';
import { DateTimeFormatString } from 'lib/dateFormatter';

interface PossiblePatientItemProps {
  patient: any;
}

function PossiblePatientItem({ patient }: PossiblePatientItemProps): ReactElement {
  const displayInfo = () => {
    const fullName = patient.fullName ? patient.fullName : '* No Name *';
    const healthCard = patient.healthCard ? patient.healthCard : '';
    const dob = patient.dob ? moment(patient.dob).format(DateTimeFormatString.DayMonthYear) : '';

    let display = fullName;
    if (dob) display += ` - ${dob}`;
    if (healthCard) display += ` - ${healthCard}`;

    return display;
  };
  return (
    <div className={styles.container}>
      <div className={styles.info}>{displayInfo()}</div>
      <NavLink
        onClick={event => event.stopPropagation()}
        to={{
          pathname: `/patient/${patient.id}`,
          state: { presetPatienIdt: patient.id },
        }}
        className={styles.icon}
        title="Go to patient"
      >
        <ArrowForwardOutlinedIcon fontSize="small" />
      </NavLink>
    </div>
  );
}
export default PossiblePatientItem;
