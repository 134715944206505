import React, { ReactElement, useState, useEffect, useRef, useContext } from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import MuiSelect from '@material-ui/core/Select';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import CreateIcon from '@material-ui/icons/Create';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import { getStaff, downloadSignature } from 'api/user';
import { Context as ProfileContext } from 'context/profile';

import { CirclePicker } from 'react-color';
import ImageCompression, { jsPDF } from 'jspdf';
import pdfAnnotate from 'pdf-annotate';
import pdfjs from '@bundled-es-modules/pdfjs-dist/build/pdf';

import DialogButton from 'components/DialogButton';
import timeout from 'lib/timeout';
import { allSettledPolyfill } from 'lib/promise';

import styles from './PDFAnnotator.module.scss';
import classNames from 'classnames';
import { MIME_TYPE_PDF, SMALL_DEVICE_WIDTH } from 'lib/document';
import { AlertDialog } from 'components/AlertDialog';
import { MATZ_BILLING_NUMBERS, MATZ_ID, ROLE } from 'lib/user';
import { Select } from '@material-ui/core';
import { User } from 'types';
import useUnsavedChangesWarning from 'hook/useUnsavedChangesWarning';

import * as logger from 'api/logger';

const annotationColours = {
  red: '#f44336',
  purple: '#9c27b0',
  blue: '#2196f3',
  green: '#009688',
  yellow: '#ffeb3b',
  orange: '#ff9800',
  brown: '#795548',
  grey: '#607d8b',
  black: '#000000',
  white: '#ffffff',
};

export interface PDFAnnotatorProps {
  url: string;
  onCancel(): void;
  onSave(blob): void;
  setSaving(state): void;
  saveText?: string;
  cancelText?: string;
  doctorSignature?: boolean;
  setDoctorId?: (arg1: number) => void;
  setBilling?: (arg1: string) => void;
}

function PDFAnnotator({
  url,
  onCancel,
  onSave,
  setSaving,
  saveText = 'Save',
  cancelText = 'Cancel',
  doctorSignature = false,
  setDoctorId,
  setBilling,
}: PDFAnnotatorProps): ReactElement {
  const [doctors, setDoctors] = useState<Array<User>>([]);
  const profilecontext = useContext(ProfileContext);
  const [selectedDoctor, setSelectedDoctor] = useState<User>();

  const fetchDoctors = async () => {
    const doctors = await getStaff('', ROLE.DOCTOR, null, null, profilecontext.user?.id);
    setDoctors(doctors);
  };

  useEffect(() => {
    if (doctorSignature) {
      fetchDoctors();
    }
  }, [url]);

  const MATZ_DEFAULT_LOCATION = 'NS';
  const handleDoctorSelection = event => {
    if (!doctors || !doctorSignature) return;
    const id = event.target.value;
    const doctor = doctors.find(doctor => doctor.id == id);
    setSelectedDoctor(doctor);
    if (doctor?.id == MATZ_ID) {
      setBilling && setBilling(MATZ_BILLING_NUMBERS[MATZ_DEFAULT_LOCATION]);
    } else {
      setBilling && setBilling(doctor?.staffInfo?.ohipPhysicianId || '');
    }
    setDoctorId && setDoctorId(doctor?.id || 0);
  };

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    useEffect(() => {
      const handleResize = () => {
        setWindowDimensions(getWindowDimensions());
      };
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
    return windowDimensions;
  }

  const viewer = useRef(null);
  const [textSize, setTextSize] = useState<number>(15);
  const [penSize, setPenSize] = useState<number>(2);
  const [colour, setColour] = useState<string>(annotationColours.blue);
  const [committedAnnotations, setCommittedAnnotations] = useState<Array<any>>([]);
  const [isTextEnabled, setTextEnabled] = useState<boolean>(false);
  const [isPenEnabled, setPenEnabled] = useState<boolean>(false);
  const [openWarning, setOpenWarning] = useState<boolean>(false);
  const [openWarningTitle, setOpenWarningTitle] = useState<string>('');
  const [openWarningMessage, setOpenWarningMessage] = useState<string>('');
  const [typeOfEditing, setTypeOfEditing] = useState('');
  const [screenReady, setScreenReady] = useState<boolean>(false);
  const [renderOptions, setRenderOptions] = useState({
    documentId: 'MyPDF.pdf',
    pdfDocument: null,
    scale: useWindowDimensions().width < SMALL_DEVICE_WIDTH ? 0.75 : 1.25,
    rotate: 0,
  });

  const [
    Prompt,
    LocalPrompt,
    changesMade,
    setChangesMade,
    setWarningOpen,
  ] = useUnsavedChangesWarning(onCancel);

  useEffect(() => {
    if (committedAnnotations.length > 0) {
      setChangesMade(true);
    } else {
      setChangesMade(false);
    }
  }, [committedAnnotations]);

  /**
   * PDF annotater can on render Latin-1 characters.
   * This regex is used to find any non Latin-1 characters
   * Link to where found https://gist.github.com/leodutra/3044325
   */
  const nonLatin1Regex = /[^\u0020-\u007e\u00a0-\u00ff]/g;

  // PDF JS & Annotate setup
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  pdfAnnotate.setStoreAdapter(new pdfAnnotate.LocalStoreAdapter());
  const { UI } = pdfAnnotate;
  const desiredCanvasSize = { width: 680, height: 872 };

  /*
   * Forcefully renders all pages of the PDF.
   * for resizing, saving etc.
   */
  function renderPages(scale: number | undefined = undefined) {
    logger.log(`              Render Pages`);
    const options = { ...renderOptions };

    if (scale) options.scale = scale;
    if (options.pdfDocument && options.pdfDocument!['numPages']) {
      logger.log(`              Render Pages: ${options.pdfDocument!['numPages']}`);
      const NUM_PAGES = options.pdfDocument!['numPages'];
      const renderPromises: Array<any> = [];
      for (let i = 0; i < NUM_PAGES; i++) {
        renderPromises.push(UI.renderPage(i + 1, options));
      }
      // All Settled since the library throws an error with `text annotaiton layers` which we do not use
      return allSettledPolyfill(renderPromises);
    }
  }

  // Merges PDF & Annotation Layers into one canvas and creates PDF document blob
  async function createDocumentBlob() {
    logger.log(`${profilecontext.user?.fullName} is Creating PDF Document`);

    //await renderPages(2);
    // Create document with blank page
    const doc = new jsPDF('p', 'pt', 'a4');
    doc.deletePage(1);

    // Retrieve page viewer to merge annotation and document layers
    const viewerNode = viewer.current;
    if (!viewerNode) return;

    const dupePages = Array.from((viewerNode as HTMLDivElement).children);
    const pageIDs = Array.from(new Set(dupePages.map(a => a.id)));

    const pages = pageIDs.map(pageID => {
      return dupePages.find(page => page.id === pageID);
    });
    logger.log('      Rendering Pages');
    for (const page of pages) {
      if (!page) return;
      (page as HTMLDivElement).style.visibility = 'visible';
      const canvasElements = page.getElementsByTagName('canvas');
      const svgElements = page.getElementsByTagName('svg');
      logger.log('      Starting to render canvas elements');
      logger.log(`          Canvas elements: ${canvasElements.length}`);
      if (canvasElements && canvasElements.length) {
        const canvas = canvasElements[0];

        // Merge annotation with PDF
        logger.log(`          Svg Elements: ${svgElements.length}`);
        if (svgElements && svgElements.length && canvas) {
          logger.log(`          Merging Annotations with PDF`);
          const svg = svgElements[0];

          const clonedSvgElement = svg.cloneNode(true);
          const xml = new XMLSerializer().serializeToString(clonedSvgElement);

          // make it base64
          const svg64 = btoa(xml);
          const b64Start = 'data:image/svg+xml;base64,';
          // prepend a "header"
          const image64 = b64Start + svg64;

          const rect = svg.getBoundingClientRect();
          const img = new Image(rect.width, rect.height);
          // set it as the source of the img element
          img.onload = function() {
            // draw the image onto the canvas
            logger.log(`          Drawing Image into canvas`);
            canvas.getContext('2d')?.drawImage(img, 0, 0, canvas.width, canvas.height);
          };
          img.src = image64;
        }
      }
    }

    // Allow time for all canvas' to update
    logger.log(`          Adding wait time of 1500 per page total: ${pages.length * 1500}`);
    await timeout(pages.length * 1500);
    pages.forEach((page, index) => {
      if (page) {
        logger.log(`          Adding Page${index} to doc`);
        const canvasElements = page.getElementsByTagName('canvas');
        if (canvasElements && canvasElements.length) {
          const imgPDF = canvasElements[0].toDataURL('image/jpeg', 0.8);
          doc.addPage([desiredCanvasSize.width, desiredCanvasSize.height]);
          doc.addImage(
            imgPDF,
            'JPEG',
            0,
            0,
            desiredCanvasSize.width,
            desiredCanvasSize.height,
            `Page${index}`,
            ImageCompression['FAST'],
            0
          );
        }
      }
    });

    //renderPages();
    return doc;
  }

  // Converts Document to Blob and Sends to save function
  async function saveEditedDocument() {
    setSaving(true);
    if (doctorSignature && !selectedDoctor) {
      setSaving(false);
      setOpenWarningMessage(
        'This form requires a doctor signature, please select a doctor to continue'
      );
      setOpenWarningTitle('Invalid Doctor Signature');
      setOpenWarning(true);
      return;
    }
    const doc = await createDocumentBlob();
    if (doc) {
      UI.disablePen();
      UI.disableText();
      const blob = new Blob([doc.output('blob')], { type: MIME_TYPE_PDF });
      onSave(blob);
    }
  }

  const handleZoomChange = event => {
    const options = { ...renderOptions };
    options.scale = (event.target.value as number) || options.scale;

    setRenderOptions(options);
  };

  const handleTextSizeChange = event => {
    const sizeUse = (event.target.value as number) || textSize;
    setTextSize(sizeUse);
    UI.setText(sizeUse, colour);
  };

  const handlePenSizeChange = event => {
    const sizeUse = (event.target.value as number) || penSize;
    setPenSize(sizeUse);
    UI.setPen(sizeUse, colour);
  };

  const handleColourChange = colourEvent => {
    const colourUse = colourEvent.hex;
    setColour(colourUse);
    UI.setPen(penSize, colourUse);
    UI.setText(textSize, colourUse);
  };

  const cancelAnnotation = () => {
    setTextEnabled(false);
    UI.disableText();
    setPenEnabled(false);
    UI.disablePen();
    setTypeOfEditing('');
  };

  const undoAnnotation = () => {
    const toUndo = committedAnnotations.pop();
    if (toUndo) {
      const { page, uuid } = toUndo;
      const storeAdapter = pdfAnnotate.getStoreAdapter();
      storeAdapter.deleteAnnotation(renderOptions.documentId, uuid);
      UI.renderPage(page, renderOptions);
      setCommittedAnnotations(committedAnnotations);
    }
  };

  const printAnnotation = async () => {
    const doc = await createDocumentBlob();
    if (doc) {
      const blob = new Blob([doc.output('blob')], { type: MIME_TYPE_PDF });
      const fileURL = window.URL.createObjectURL(blob);
      const win = window.open('https://www.google.com/', '_blank');
      if (win) {
        win.location.assign(fileURL);
        win.focus();
        return false;
      }
    }
  };

  const handleMatzLocationSelection = event => {
    const location = event.target.value;
    setBilling && setBilling(MATZ_BILLING_NUMBERS[location]);
  };

  // Loads PDF and renders pages
  const loadPDF = async url => {
    setScreenReady(false);
    const loadingTask = pdfjs.getDocument(url);
    const pdf = await loadingTask.promise;
    const options = { ...renderOptions };
    options.documentId = url;
    options.pdfDocument = pdf;

    // Total Pages
    const numPages = pdf.numPages;

    // Ensure Viewer area is loaded
    const viewerNode = viewer.current;
    if (!viewerNode) return;

    // Clear all PDF page annotations and render page
    for (let i = 0; i < numPages; i++) {
      const pageNum = i + 1;
      (viewerNode as HTMLDivElement).appendChild(UI.createPage(pageNum));
      // Clear annotations & Render Page
      const storeAdapter = pdfAnnotate.getStoreAdapter();
      pdfAnnotate.getAnnotations(renderOptions.documentId, pageNum).then(annotationsObj => {
        annotationsObj.annotations.forEach(annotation => {
          storeAdapter.deleteAnnotation(renderOptions.documentId, annotation.uuid);
        });
        UI.renderPage(pageNum, options);
      });
    }

    // Track annotation to undo
    UI.addEventListener('annotation:add', (docId, page, annotation) => {
      const annotations = [...committedAnnotations];
      annotations.push({ page: annotation.page, uuid: annotation.uuid });
      setCommittedAnnotations(annotations);
      if (annotation.content) {
        const match = annotation.content.match(nonLatin1Regex);
        if (match) {
          setOpenWarningMessage(
            `Please change special characters ( ' , " ).  Retype special characters with keyboard if text was copied and pasted`
          );
          setOpenWarningTitle('Invalid characters');
          setOpenWarning(true);
        }
      }
    });

    // Allow time for the ipads screen to load.  Hides the flicker when loading
    setTimeout(async () => {
      setScreenReady(true);
    }, 5000);

    setRenderOptions(options);
  };

  const toggleText = async () => {
    if (isTextEnabled) {
      setTextEnabled(false);
      UI.disableText();
      setTypeOfEditing('');
    } else {
      setTextEnabled(true);
      UI.enableText();
      UI.setText(textSize, colour);
      UI.disablePen();
      setPenEnabled(false);
      setTypeOfEditing('TextEdit');
    }
  };

  const togglePen = async () => {
    if (isPenEnabled) {
      setPenEnabled(false);
      UI.disablePen();
      setTypeOfEditing('');
    } else {
      setPenEnabled(true);
      UI.enablePen();

      UI.setPen(penSize, colour);
      setTextEnabled(false);
      UI.disableText();
      setTypeOfEditing('PenEdit');
    }
  };

  useEffect(() => {
    renderPages();
  }, [renderOptions]);

  // Load page on document URL retrieved
  useEffect(() => {
    if (!url) return;

    // Timeout to allow more time for the ipads to load content.
    setTimeout(async () => {
      if (viewer !== null && viewer.current !== null && url) {
        await loadPDF(url);
      }
    }, 5000);
  }, [url]);

  const reload = () => {
    if (viewer !== null && viewer.current !== null && url) {
      if (changesMade) {
        setWarningOpen(true);
      } else {
        loadPDF(url);
      }
    }
  };

  const getSizeDropdown = () => {
    if (!isTextEnabled && !isPenEnabled) return <></>;

    let onClick = handleTextSizeChange;
    let title = 'Text Size';
    let size = textSize;

    if (isPenEnabled) {
      onClick = handlePenSizeChange;
      title = 'Pen Size';
      size = penSize;
    }

    return (
      <div className={styles.toolbarItem}>
        <div className={styles.title}>{title}</div>
        <MuiSelect value={size} onChange={onClick}>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={4}>4</MenuItem>
          <MenuItem value={6}>6</MenuItem>
          <MenuItem value={8}>8</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={15}>15</MenuItem>
          <MenuItem value={20}>20</MenuItem>
        </MuiSelect>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.toolbar}>
        <div className={styles.leftToolbar}>
          <ToggleButtonGroup
            size="small"
            value={typeOfEditing}
            className={styles.toggleButtons}
            exclusive
          >
            <ToggleButton value="TextEdit" onClick={toggleText}>
              <TextFieldsIcon fontSize="small"></TextFieldsIcon>
            </ToggleButton>
            <ToggleButton value="PenEdit" onClick={togglePen}>
              <CreateIcon fontSize="small"></CreateIcon>
            </ToggleButton>
          </ToggleButtonGroup>

          {getSizeDropdown()}
        </div>

        {(isTextEnabled || isPenEnabled) && (
          <div className={styles.colourPicker}>
            <div className={styles.title}>Colour:</div>
            <CirclePicker
              width={'100%'}
              hex={colour.toLowerCase()}
              colors={Object.values(annotationColours)}
              onChange={handleColourChange}
            />
          </div>
        )}
        <div className={styles.rightToolbar}>
          <div className={styles.toolbarItem}>
            <div className={styles.title}>Zoom:</div>
            <MuiSelect value={renderOptions.scale} onChange={handleZoomChange}>
              <MenuItem value={0.25}>25%</MenuItem>
              <MenuItem value={0.5}>50%</MenuItem>
              <MenuItem value={0.75}>75%</MenuItem>
              <MenuItem value={1}>100%</MenuItem>
              <MenuItem value={1.25}>125%</MenuItem>
              <MenuItem value={1.5}>150%</MenuItem>
              <MenuItem value={1.75}>175%</MenuItem>
              <MenuItem value={2}>200%</MenuItem>
            </MuiSelect>
          </div>
          {doctorSignature && (
            <div className={styles.toolbarItem}>
              <div className={styles.title}>Doctor Signature:</div>
              <Select
                label="Select Doctor"
                name={'doctor'}
                fullWidth
                onChange={handleDoctorSelection}
                value={selectedDoctor?.id ? selectedDoctor?.id : 0}
              >
                <MenuItem value={0} selected>
                  None
                </MenuItem>
                {doctors?.map(item => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.fullName}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
          )}
          {doctorSignature && selectedDoctor?.id == MATZ_ID && (
            <div className={styles.toolbarItem}>
              <div className={styles.title}>Doctor Location:</div>
              <Select
                label="Select Location"
                name={'location'}
                fullWidth
                onChange={handleMatzLocationSelection}
                defaultValue={MATZ_DEFAULT_LOCATION}
              >
                <MenuItem key={'NS'} value={'NS'}>
                  Nova Scotia
                </MenuItem>
                <MenuItem key={'NB'} value={'NB'}>
                  New Brunswick
                </MenuItem>
                <MenuItem key={'ON'} value={'ON'}>
                  Ontario
                </MenuItem>
              </Select>
            </div>
          )}
          <div className={styles.toolbarItem}>
            <DialogButton
              className={styles.button}
              onClick={undoAnnotation}
              disabled={committedAnnotations.length === 0}
            >
              Undo
            </DialogButton>
            <DialogButton className={styles.button} onClick={printAnnotation}>
              Print
            </DialogButton>
          </div>
        </div>
      </div>

      {!screenReady ? (
        <div className={styles.loading}>
          <div>
            <div className={styles.title}>{'Loading...'}</div>
            <div className={styles.message}>May take up to 10 seconds</div>
          </div>
        </div>
      ) : (
        <div className={styles.reload}>
          <div className={styles.message}>If page does not load properly please click here</div>
          <DialogButton className={styles.button} onClick={reload}>
            Reload
          </DialogButton>
        </div>
      )}
      <div
        ref={viewer}
        id="viewer"
        className={classNames(
          !screenReady && styles.hide,
          isPenEnabled && styles.freezeScreen,
          styles.pdfViewer
        )}
      />
      {screenReady && (
        <div className={styles.buttons}>
          <DialogButton className={styles.button} onClick={onCancel}>
            {cancelText}
          </DialogButton>
          {isPenEnabled || isTextEnabled ? (
            <DialogButton className={styles.button} onClick={cancelAnnotation}>
              {'Finish Editing'}
            </DialogButton>
          ) : (
            <DialogButton className={styles.button} onClick={saveEditedDocument}>
              {saveText}
            </DialogButton>
          )}
        </div>
      )}
      {LocalPrompt}
      <AlertDialog
        open={openWarning}
        message={openWarningMessage}
        title={openWarningTitle}
        onClose={() => {
          undoAnnotation();
          setOpenWarning(false);
        }}
      />
    </div>
  );
}

export default PDFAnnotator;
