import React, { ReactElement } from 'react';
import TextField from '@material-ui/core/TextField';

import styles from './CVCHealthCardInputStyles.module.scss';
import Select from 'components/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { CanadaProvinceCodes } from 'lib/addresses';

interface CVCHealthCardInputProps {
  value?: {
    healthCardProvinceCode?: string;
    hcn?: string;
    healthCard?: string;
  };
  disabled?: boolean;
  onChange?(name, value): void;
  healthCardError?: boolean;
  hcnError?: boolean;
  healthCardHelperText?: string | undefined;
  healthCardProvinceError?: string;
  hcnHelperText?: string | undefined;
  starFromEditAppt: boolean | undefined;
}

function CVCHealthCardInput({
  value,
  disabled = false,
  onChange = (): null => null,
  healthCardError,
  healthCardProvinceError,
  hcnError,
  healthCardHelperText,
  hcnHelperText,
  starFromEditAppt = false,
}: CVCHealthCardInputProps): ReactElement {
  function onChangeHCN({ target }): void {
    if (target.value.length < 3) onChange('hcn', target.value);
  }

  return (
    <div className={styles.main}>
      <Select
        fullWidth
        label={starFromEditAppt ? '*Province' : 'Province'}
        name={'healthCardProvinceCode'}
        renderValue={(value): ReactElement => <div className={styles.selectItem}>{value}</div>}
        value={value?.healthCardProvinceCode}
        disabled={disabled}
        error={healthCardProvinceError}
        onChange={({ target }): void => onChange(target.name, target.value)}
      >
        {Object.values(CanadaProvinceCodes).map(item => (
          <MenuItem key={item} value={item} className={styles.selectItem}>
            {item}
          </MenuItem>
        ))}
      </Select>
      <div className={styles.healthCardContainer}>
        <TextField
          disabled={disabled || !value?.healthCardProvinceCode}
          name="healthCard"
          label={starFromEditAppt ? '*Health-Card' : 'Health-Card'}
          InputLabelProps={{ shrink: !!value?.healthCard }}
          value={value?.healthCard ? value?.healthCard : ''}
          fullWidth
          onChange={({ target }): void => onChange(target.name, target.value)}
          error={healthCardError}
          helperText={healthCardHelperText}
        />
        {value?.healthCardProvinceCode === CanadaProvinceCodes.ON && (
          <TextField
            className={styles.hcn}
            name={'hcn'}
            label="VC"
            value={value?.hcn ? value?.hcn : ''}
            InputLabelProps={{ shrink: !!value?.hcn }}
            onChange={onChangeHCN}
            disabled={disabled}
            error={hcnError}
            helperText={hcnHelperText}
          />
        )}
      </div>
    </div>
  );
}

export default CVCHealthCardInput;
