import React, { ReactElement, useState, useEffect } from 'react';
import { AppointmentOpenings, AppointmentTypes, RescheduleInfo } from 'types';
import styles from './AvailabilityByTypeHeader.module.scss';
import { APPOINTMENT_TYPES } from 'lib/appointments';
import { convertTo12From24 } from 'lib/schedule';
import classNames from 'classnames';

interface AvailabilityByTypeHeaderProps {
  locationDailyAppointmentAvailability: Array<AppointmentOpenings>;
  appointmentHeaderType: AppointmentTypes;
  rescheduleAppointmentInfo?: RescheduleInfo | undefined;
  staffHeader: string;
  readOnly: boolean;
  hideAvailability: boolean;
  onNewAppointment: (startTime, endTime, scheduleType, staffId) => void;
}

function AvailabilityByTypeHeader({
  locationDailyAppointmentAvailability,
  appointmentHeaderType,
  rescheduleAppointmentInfo,
  staffHeader,
  readOnly,
  hideAvailability,
  onNewAppointment,
}: AvailabilityByTypeHeaderProps): ReactElement {
  const [allOpenings, setAllOpenings] = useState(locationDailyAppointmentAvailability);
  const [staffHeaderArray, setStaffHeaderArray] = useState<Array<string>>([]);
  const [showMore, setShowMore] = useState<Array<boolean>>([]);

  const isDisabled = () => {
    const rescheduleType = APPOINTMENT_TYPES.find(
      type => type.name === rescheduleAppointmentInfo?.mainType
    );
    if (!rescheduleType) return false;
    const typeName = APPOINTMENT_TYPES.find(type => type.id === rescheduleType.id);
    return typeName ? typeName.name !== appointmentHeaderType : false;
  };

  useEffect(() => {
    setAllOpenings(locationDailyAppointmentAvailability);
  }, [showMore, locationDailyAppointmentAvailability]);

  useEffect(() => {
    const staffArr = staffHeader?.length ? staffHeader?.split(', ') : [];
    setStaffHeaderArray([...Array.from(new Set(staffArr))]);
    const tmpShowMore = Array(staffArr.length).fill(false);
    setShowMore(tmpShowMore);
  }, [staffHeader]);
  return (
    <div className={styles.dailyHoursAvailabilityContainer}>
      <h4 className={styles.typeHeader}>{appointmentHeaderType}</h4>
      {readOnly || hideAvailability ? (
        <></>
      ) : (
        <div className={styles.dailyHoursAvailabilityContent}>
          {!allOpenings?.length && !staffHeaderArray?.length ? (
            <div className={styles.noOpenings}>No Staff Booked.</div>
          ) : (
            staffHeaderArray?.map((staff, staffIdx) => (
              <div className={styles.providerWrapper} key={staffIdx}>
                {staff && <div>{staff}: </div>}
                {!allOpenings?.filter(opening => opening.staffFullName == staff)?.length ? (
                  <div className={styles.noOpenings}>No more openings available on this day.</div>
                ) : (
                  allOpenings
                    ?.filter(opening => opening.staffFullName == staff)
                    ?.map((appointmentAvailability, index) => (
                      <>
                        {(showMore[staffIdx] || (index <= 2 && !showMore[staffIdx])) && (
                          <div
                            key={index}
                            className={classNames(
                              styles.dailyHours,
                              isDisabled() && styles.disabled
                            )}
                          >
                            <p>
                              {convertTo12From24(appointmentAvailability?.startTime)} -{' '}
                              {convertTo12From24(appointmentAvailability?.endTime)}
                            </p>
                            <div
                              onClick={() => {
                                onNewAppointment(
                                  appointmentAvailability?.startTime,
                                  appointmentAvailability?.endTime,
                                  appointmentHeaderType,
                                  appointmentAvailability?.staffId
                                );
                              }}
                              className={styles.buttonPlus}
                            ></div>
                          </div>
                        )}
                      </>
                    ))
                )}
                {showMore[staffIdx] ? (
                  <>
                    {allOpenings?.filter(opening => opening.staffFullName == staff).length > 3 && (
                      <div
                        className={styles.buttonShow}
                        onClick={() => {
                          setShowMore(prevState => {
                            const arr = [...prevState];
                            arr[staffIdx] = !arr[staffIdx];
                            return arr;
                          });
                        }}
                      >
                        Show Less
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {allOpenings?.filter(opening => opening.staffFullName == staff).length > 3 && (
                      <div
                        className={styles.buttonShow}
                        onClick={() => {
                          setShowMore(prevState => {
                            const arr = [...prevState];
                            arr[staffIdx] = !arr[staffIdx];
                            return arr;
                          });
                        }}
                      >
                        Show More
                      </div>
                    )}
                  </>
                )}
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
}
export default AvailabilityByTypeHeader;
