import axios from 'axios';

import {
  CreateReceipt,
  DownloadDocumentType,
  NewDocument,
  Receipt,
  PaymentMethod,
  ReceiptType,
} from 'types';
import { GET_CASE, GET_RECEIPTS } from 'lib/config';

import { getDefaultHeader } from '../utils';

const putFile = async (endpoint: string, data: NewDocument) => {
  return axios.put(endpoint, data.document, { headers: { 'Content-Type': data.document.type } });
};

export async function createReceipts(caseId: number, params: CreateReceipt) {
  const response = await axios.post(`${GET_CASE}/${caseId}/receipts`, params, {
    headers: getDefaultHeader(),
  });
  return response.data;
}

export async function pushReceiptToS3(uploadUrl: string, document: NewDocument) {
  return await putFile(uploadUrl, document);
}

export async function completeRecieptCreation(receiptId: number, params) {
  const response = await axios.put(`${GET_CASE}/${receiptId}/completeReceipt`, params, {
    headers: getDefaultHeader(),
  });
  return response.data;
}

export async function updateReceipt(receiptId: number, params): Promise<Receipt> {
  const response = await axios.put(`${GET_CASE}/${receiptId}/receipts`, params, {
    headers: getDefaultHeader(),
  });
  return response.data;
}

export async function getTemporaryReceiptUrl(receiptId: number): Promise<DownloadDocumentType> {
  const response = await axios.get(`${GET_CASE}/${receiptId}/temporaryReceiptUrl`, {
    headers: getDefaultHeader(),
  });
  return response.data;
}

export async function getPaymentMethods(): Promise<Array<PaymentMethod>> {
  const response = await axios.get(`${GET_RECEIPTS}/paymentMethods`, {
    headers: getDefaultHeader(),
  });
  return response.data;
}

export async function getReceiptTypes(): Promise<Array<ReceiptType>> {
  const response = await axios.get(`${GET_RECEIPTS}/types`, {
    headers: getDefaultHeader(),
  });
  return response.data;
}

export async function getReceipts(
  caseId: number | string,
  order = 'desc'
): Promise<Array<Receipt>> {
  const response = await axios.get(`${GET_CASE}/${caseId}/receipts`, {
    headers: getDefaultHeader(),
    params: { order },
  });

  return response.data;
}

export async function sendReceipt(caseId: number, receiptId: number): Promise<void> {
  await axios.post(
    `${GET_CASE}/${caseId}/receipts/${receiptId}/send`,
    {},
    { headers: getDefaultHeader() }
  );
}

export async function deleteReceipt(caseId: number, receiptId: number): Promise<void> {
  await axios.delete(`${GET_CASE}/${caseId}/receipts/${receiptId}`, {
    headers: getDefaultHeader(),
  });
}

export async function hardDeleteReceipt(caseId: number, receiptId: number): Promise<void> {
  await axios.delete(`${GET_CASE}/${caseId}/hardDeleteReceipts/${receiptId}`, {
    headers: getDefaultHeader(),
  });
}
