import React, { ReactElement } from 'react';
import { Route, match } from 'react-router-dom';

import PatientManagementRoute from 'routings/PatientManagement';

import PatientManagementHeader from './components/PatientManagementHeader';

import styles from './PatientManagementStyles.module.scss';

interface PatientManagementProps {
  match: match;
}

function PatientManagement({ match }: PatientManagementProps): ReactElement {
  return (
    <div className={styles.container}>
      <PatientManagementHeader parentUrl={match?.url} />
      <div className={styles.content}>
        <Route component={PatientManagementRoute} />
      </div>
    </div>
  );
}

export default PatientManagement;
