import React, { ReactElement, useEffect, useState } from 'react';

import { UserApi, LocationApi } from 'api';
import { formatEmail } from 'lib/user';
import { Staff } from 'api/user/user.interfaces';
import EditStaffDialog from './EditStaffDialog.view';
import { NewStaffDialogValidationError, ContactType, Location, LocationListItem } from 'types';
import useUserApi from 'hook/useUserApi.hook';
import ChangesInProgressWarningDialog from 'components/ChangesInProgressWarningDialog';
import useUnsavedChangesWarning from 'hook/useUnsavedChangesWarning';
import * as logger from 'api/logger';

interface EditStaffDialogContainer {
  staffId?: string;
  open?: boolean;
  onClose: () => void;
  onSaveSuccess: () => void;
  dialogType?: string;
}

interface UserType {
  id: number;
  type: string;
}

function EditStaffDialogContainer({
  open,
  staffId,
  onClose,
  onSaveSuccess,
  dialogType,
}: EditStaffDialogContainer): ReactElement {
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneId, setPhoneId] = useState<number>();
  const [staffInfoId, setStaffInfoId] = useState<number>();
  const [OHIPPhysicianId, setOHIPPhysicianId] = useState('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [signatureS3Key, setSignatureS3Key] = useState<string | undefined>('');
  const [signatureS3Url, setSignatureS3Url] = useState<string | undefined>('');
  const [typeSearchQuery, setTypeSearchQuery] = useState('');
  const [userType, setUserType] = useState<UserType | null>(null);
  const [userTypesList, setUserTypesList] = useState<Array<UserType>>([]);
  const [locationsList, setLocationsList] = useState<Array<Location>>([]);
  const [locations, setLocations] = useState<Array<Location>>([]);
  const [abbreviations, setAbbreviations] = useState('');
  const [validationErrors, setValidationErrors] = useState<Array<NewStaffDialogValidationError>>(
    []
  );

  function resetForm(): void {
    setUserType(null);
    setFirstName('');
    setLastName('');
    setEmail('');
    setPhone('');
    setLoading(false);
    setOHIPPhysicianId('');
    setTypeSearchQuery('');
    setSignatureS3Key('');
    setSignatureS3Url('');
    setLocations([]);
    setAbbreviations('');
    setErrorMessage('');
  }

  function onLocalClose() {
    onClose();
    resetForm();
  }

  const [
    Prompt,
    LocalPrompt,
    changesMade,
    setChangesMade,
    setWarningOpen,
  ] = useUnsavedChangesWarning(onLocalClose);

  const { user } = useUserApi(staffId);

  function capitalizeFirstLetter(stringIn): string {
    if (stringIn) {
      return stringIn[0].toUpperCase() + stringIn.slice(1);
    }
    return stringIn;
  }

  useEffect(() => {
    if (user) {
      const phoneContacts = user?.contacts?.filter(
        contact => contact.type === 'office' || contact.type === 'phone'
      );

      if (phoneContacts && phoneContacts.length > 0) {
        setPhone(phoneContacts[0].value);
        setPhoneId(phoneContacts[0].id);
      }

      if (user.typeId) {
        setUserType({ type: capitalizeFirstLetter(user.type), id: user.typeId });
      }

      setFirstName(user.firstName);
      setLastName(user.lastName);
      setEmail(user.email);
      setOHIPPhysicianId(user.staffInfo ? user.staffInfo.ohipPhysicianId : '');
      setLocations(user.staffInfo ? user.staffInfo.locations : []);
      setStaffInfoId(user.staffInfo?.id);
      setAbbreviations(user.staffInfo ? user.staffInfo.abbreviations : '');
      setSignatureS3Key(user.staffInfo ? user.staffInfo.signatureS3Key : '');
      setSignatureS3Url(user.staffInfo ? user.staffInfo.signatureS3Url : '');
    }
  }, [user]);

  function validateForm(): boolean {
    let validated = true;
    const validationErrors: Array<NewStaffDialogValidationError> = [];

    if (firstName === '') {
      const validationError: NewStaffDialogValidationError = {
        field: 'firstName',
        message: 'Please type first name',
      };

      validationErrors.push(validationError);
      validated = false;
    }

    if (lastName === '') {
      const validationError: NewStaffDialogValidationError = {
        field: 'lastName',
        message: 'Please type last name',
      };

      validationErrors.push(validationError);
      validated = false;
    }

    if (email === '') {
      const validationError: NewStaffDialogValidationError = {
        field: 'email',
        message: 'Please type email',
      };

      validationErrors.push(validationError);
      validated = false;
    }

    if (phone === '') {
      const validationError: NewStaffDialogValidationError = {
        field: 'phone',
        message: 'Please type phone number',
      };

      validationErrors.push(validationError);
      validated = false;
    }

    if (userType === null) {
      const validationError: NewStaffDialogValidationError = {
        field: 'userType',
        message: 'Please select user type',
      };

      validationErrors.push(validationError);
      validated = false;
    }

    if (locations.length === 0) {
      const validationError: NewStaffDialogValidationError = {
        field: 'locations',
        message: 'Please select location',
      };

      validationErrors.push(validationError);
      validated = false;
    }

    setValidationErrors(validationErrors);

    return validated;
  }

  function onCloseAndReset() {
    if (changesMade) {
      setWarningOpen(true);
      return;
    }
    onLocalClose();
  }

  async function onSave(): Promise<void> {
    if (validateForm()) {
      setLoading(true);
      const locationIds: number[] = [];
      for (let index = 0; index < locations.length; index++) {
        locationIds.push(locations[index].id);
      }
      const body = {
        firstName: firstName,
        lastName: lastName,
        email: formatEmail(email),
        userType: userType?.type.toLowerCase(),
        ohipPhysicianId: OHIPPhysicianId,
        locations: locationIds,
        abbreviations: abbreviations,
        contacts: [
          {
            type: ContactType.Phone,
            value: phone,
          },
        ],
      };

      try {
        await UserApi.createStaff(body);

        resetForm();
        onSaveSuccess();
      } catch (err) {
        const error = err as any;
        if (error) {
          setErrorMessage(error.response.data.message);
        }
      }
      setLoading(false);
    }
  }

  async function onUpdate(): Promise<void> {
    if (validateForm()) {
      setLoading(true);
      const locationIds: number[] = [];
      for (let index = 0; index < locations.length; index++) {
        locationIds.push(locations[index].id);
      }
      const body = {
        firstName: firstName,
        lastName: lastName,
        email: formatEmail(email),
        locations: locationIds,
        contacts: [
          {
            id: phoneId,
            type: ContactType.Phone,
            value: phone,
          },
        ],
        staffInfo: {
          id: staffInfoId,
          abbreviations: abbreviations,
          ohipPhysicianId: OHIPPhysicianId,
          signatureS3Key: signatureS3Key,
          signatureS3Url: signatureS3Url,
        },
      };

      try {
        await UserApi.updateStaff(staffId, body);
        resetForm();
        onSaveSuccess();
      } catch (err) {
        const error = err as any;
        logger.error(error.message);
        if (error) {
          setErrorMessage(error.response.data.message);
        }
      }
      setLoading(false);
    }
  }

  function createLocationsRowFromLocations(locations: Location[]): LocationListItem[] {
    const locationRow: LocationListItem[] = [];
    const lastLocationRows: LocationListItem[] = [];

    if (locations) {
      const locationRow: Location[] = [];
      const lastLocationRows: Location[] = [];
      locations.forEach(location => {
        if (location.sortOrder == 0) {
          lastLocationRows.push(location);
        } else {
          locationRow.push(location);
        }
      });
    }
    return locationRow.concat(lastLocationRows);
  }

  async function getUserTypes(): Promise<void> {
    const data = await UserApi.getUserTypes();
    const filteredData = data.filter(item => item.type !== 'patient');
    const formattedData = filteredData.map(item => {
      item.type = capitalizeFirstLetter(item.type);
      return item;
    });
    setUserTypesList(formattedData);
  }

  async function getLocations(): Promise<void> {
    const locations = await LocationApi.getLocations();

    setLocationsList(locations);
  }

  useEffect(() => {
    getUserTypes();
    getLocations();
  }, []);

  return (
    <div>
      <EditStaffDialog
        updateStaff={!!user}
        open={open}
        onClose={onCloseAndReset}
        loading={loading}
        firstName={firstName}
        lastName={lastName}
        email={email}
        phone={phone}
        userType={userType}
        OHIPPhysicianId={OHIPPhysicianId}
        userTypesList={userTypesList}
        locationsList={locationsList}
        locations={locations}
        abbreviations={abbreviations}
        validationErrors={validationErrors}
        onUserTypeSelect={value => {
          setChangesMade(true);
          setUserType(value);
        }}
        onFirstNameChange={value => {
          setChangesMade(true);
          setFirstName(value);
        }}
        onOHIPPhysicianId={value => {
          setChangesMade(true);
          setOHIPPhysicianId(value);
        }}
        onLastNameChange={value => {
          setChangesMade(true);
          setLastName(value);
        }}
        onEmailChange={value => {
          setChangesMade(true);
          setEmail(value);
        }}
        onPhoneChange={value => {
          setChangesMade(true);
          setPhone(value);
        }}
        onUserTypeChange={value => {
          setChangesMade(true);
          setTypeSearchQuery(value);
        }}
        onLocationChange={value => {
          setChangesMade(true);
          setLocations(value);
        }}
        onAbbreviationsChange={value => {
          setChangesMade(true);
          setAbbreviations(value);
        }}
        onSave={onSave}
        onUpdate={onUpdate}
        dialogType={dialogType}
        errorMessage={errorMessage}
      />
      {LocalPrompt}
    </div>
  );
}

export default EditStaffDialogContainer;
