export const convertEditableMimeTypeToFileExtension = mimeType => {
  if (mimeType) {
    switch (mimeType.toUpperCase()) {
      case 'APPLICATION/PDF':
        return 'pdf';
      case 'PDF':
        return 'pdf';
    }
  }
  return null;
};

export const REFERRAL_DOCUMENT_VALID_MONTHS = 6;

export const MIME_TYPE_PDF = 'application/pdf';
export const MIME_TYPE_IMAGE = 'image/jpeg';

export const SMALL_DEVICE_WIDTH = 800;

const supportedFileTypes = ['.pdf', '.bmp', '.png', '.jpeg', '.jpg', '.webp'];

export const supportedFileTypesString = () => supportedFileTypes.join(',');
export const pdfSupportFileType = '.pdf';

export const DOCUMENT_TYPES = {
  IMAGES: 'Images',
  REFERRAL: 'Referral',
  PROCEDURE: 'Procedure',
  CONSULRATION_LETTER: 'Consultation Letter',
  POST_PROCEDURE_NOTES: 'Post Procedure Notes',
  ULTRASOUND_REPORT: 'Ultrasound Report',
  CONSENT_FORMS: 'Consent Forms',
  RECEIPT: 'Receipt',
  PRESCRIPTIONS: 'Prescriptions',
  SCREENING_FORMS: 'Screening Forms',
  CONSENT_FORM: 'Consent Form',
};

export enum SEND_TYPES {
  FAX = 'Fax',
  EMAIL = 'Email',
}

export const SEND_TYPE_LIST = [SEND_TYPES.EMAIL, SEND_TYPES.FAX];
export const DOCUMENT_STATUS_IDS = {
  NO_STATUS: 1,
  IN_PROGRESS: 2,
  SUCCESSFUL: 3,
  FAILED: 4,
};
