import axios from 'axios';

import { HealthCardValidationResults } from 'types';
import { VALIDATE_HEALTH_CARD } from 'lib/config';
import { getDefaultHeader } from '../utils';

export async function validateCard(
  cardNumber: string,
  cardVersionCode: string
): Promise<HealthCardValidationResults> {
  const response = await axios.post(
    `${VALIDATE_HEALTH_CARD}`,
    {
      cardNumber: cardNumber,
      cardVersionCode: cardVersionCode,
    },
    {
      headers: getDefaultHeader(),
    }
  );

  return response.data;
}
