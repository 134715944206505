import React, { ReactElement, useContext, useEffect, useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import useAppointmentApi from 'hook/useAppointmentApi.hook';
import TitledSection from 'components/TitledSection';
import ConditionDiv from 'components/ConditionDiv';
import { formatDate, DateTimeFormat, DateTimeFormatString } from 'lib/dateFormatter';
import { AppointmentsApi, CaseApi } from 'api';
import { NavLink } from 'react-router-dom';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import MailIcon from '@material-ui/icons/Mail';

import Dialog from 'components/Dialog';
import DialogButton from 'components/DialogButton';
import OddPaper from './components/OddPaper';
import { AppointmentStatus, APPOINTMENT_STATUSES } from 'lib/appointments';

import styles from './AppointmentsHistoryStyles.module.scss';
import { DialogButtonType } from 'components/DialogButton/DialogButton.view';
import UpdateAppointmentStatusDialog from 'components/UpdateAppointmentStatusDialog';
import { AppointmentInfo } from 'api/appointment/appointment.interfaces';
import { handleAPIErrors } from 'lib/handleAPIErrors';
import Button from 'components/Button';
import AppointmentHistoryReportDialog from './components/AppointmentHistoryReportDialog';
import usePatientInfo from '../PatientInfo/usePatientApi.hook';
import { MenuItem, Select } from '@material-ui/core';

import { getStaff, downloadSignature } from 'api/user';
import { MATZ_BILLING_NUMBERS, MATZ_ID, ROLE } from 'lib/user';
import { User } from 'types';
import StyledButton from 'components/Button/Button.view';
import { FormStatus } from 'lib/form';
import useFormsApi from '../Forms/useFormsApi.hook';
import { AlertDialog } from 'components/AlertDialog';

import { Context as ProfileContext } from 'context/profile';
import useProfileRoles from 'hook/useProfileRoles.hook';
import * as logger from 'api/logger';

interface HeadProps {
  sortDown: boolean;
  changeOrder(): void;
}

function Head({ sortDown, changeOrder }: HeadProps): ReactElement {
  const Arrow = sortDown ? ArrowDropDownIcon : ArrowDropUpIcon;

  return (
    <OddPaper className={styles.head} isOdd>
      <Grid container>
        <Grid item xs={1} className={styles.dateContent}>
          <div className={styles.date}>Date</div>
          <Arrow onClick={changeOrder} />
        </Grid>
        <Grid item xs={2}>
          <div className={styles.headItem}>Time slot</div>
        </Grid>
        <Grid item xs={2}>
          <div className={styles.headItem}>Type</div>
        </Grid>
        <Grid item xs={1}>
          <div className={styles.headItem}>Location</div>
        </Grid>
        <Grid item xs={1}>
          <div className={styles.headItem}>Doctor</div>
        </Grid>
        <Grid item xs={1}>
          <div className={styles.headItem}>Status</div>
        </Grid>
        <Grid item xs={1}>
          <div className={styles.headItem}>Created By</div>
        </Grid>
        <Grid item xs={1}>
          <div className={styles.headItem}>Updated By</div>
        </Grid>
        <Grid item xs={1}>
          <div className={styles.goToItem}>Resend Mail</div>
        </Grid>
        <Grid item xs={1}>
          <div className={styles.goToItem}>Go To</div>
        </Grid>
      </Grid>
    </OddPaper>
  );
}

function isOdd(num): boolean {
  return !!(num % 2);
}

function getTimeSlot(startTime, endTime): string {
  const startString = moment(startTime, DateTimeFormatString.TimeOnlyWithoutMeridies).format(
    DateTimeFormatString.TimeOnlyCompact
  );
  const endString = moment(endTime, DateTimeFormatString.TimeOnlyWithoutMeridies).format(
    DateTimeFormatString.TimeOnlyCompact
  );
  return `${startString} - ${endString}`;
}

interface AppointmentHistoryRowProps {
  date: string;
  timeSlot: string;
  type?: string;
  location?: string;
  locationId?: number;
  appointmentId?: number;
  doctor?: string;
  status?: string;
  isOdd: boolean;
  deleted?: boolean;
  createdBy?: string;
  updatedBy?: string;
  updateAppointmentStatus?: (id: number | undefined) => void;
}

function AppointmentHistoryRow({
  date,
  timeSlot,
  type,
  location,
  locationId,
  appointmentId,
  doctor,
  isOdd,
  status,
  deleted,
  createdBy,
  updatedBy,
  updateAppointmentStatus,
}: AppointmentHistoryRowProps): ReactElement {
  const [open, setOpen] = useState<boolean>(false);
  const [sentConfirmation, setSentConfirmation] = useState<boolean>(false);
  const [responseMessage, setResponseMessage] = useState<string>('');
  async function resendEmail(): Promise<void> {
    try {
      await AppointmentsApi.mailPatientAppointment(appointmentId, deleted);
      setResponseMessage('Email confirmation has been resent to the patient successfully!');
    } catch (e) {
      const message = handleAPIErrors(e, 'Error sending email');
      setResponseMessage(message);
    }
    setSentConfirmation(true);
  }

  function onCloseDialog(): void {
    setOpen(false);
    setResponseMessage('');
  }

  const canUpdate = () => {
    const statusId = APPOINTMENT_STATUSES.find(apptStatus => apptStatus.name == status);
    return statusId?.id != AppointmentStatus.Cancelled && statusId?.id != AppointmentStatus.Deleted;
  };

  return (
    <OddPaper className={styles.head} isOdd={isOdd}>
      <Grid container className={styles.container}>
        <Grid item xs={1}>
          <div
            className={
              status == APPOINTMENT_STATUSES.find(st => st.id == AppointmentStatus.Deleted)?.name
                ? styles.disabledText
                : styles.contentItem
            }
          >
            {date}
          </div>
        </Grid>
        <Grid item xs={2}>
          <div
            className={
              status == APPOINTMENT_STATUSES.find(st => st.id == AppointmentStatus.Deleted)?.name
                ? styles.disabledText
                : styles.contentItem
            }
          >
            {timeSlot}
          </div>
        </Grid>
        <Grid item xs={2}>
          <div
            className={
              status == APPOINTMENT_STATUSES.find(st => st.id == AppointmentStatus.Deleted)?.name
                ? styles.disabledText
                : styles.contentItem
            }
          >
            {type}
          </div>
        </Grid>
        <Grid item xs={1}>
          <div
            className={
              status == APPOINTMENT_STATUSES.find(st => st.id == AppointmentStatus.Deleted)?.name
                ? styles.disabledText
                : styles.contentItem
            }
          >
            {location}
          </div>
        </Grid>
        <Grid item xs={1}>
          <div
            className={
              status == APPOINTMENT_STATUSES.find(st => st.id == AppointmentStatus.Deleted)?.name
                ? styles.disabledText
                : styles.contentItem
            }
          >
            {doctor}
          </div>
        </Grid>
        <Grid item xs={1}>
          <div
            className={classNames(
              status == APPOINTMENT_STATUSES.find(st => st.id == AppointmentStatus.Deleted)?.name
                ? styles.disabledText
                : styles.contentItem,
              canUpdate() && styles.canUpdate
            )}
            onClick={() => {
              return updateAppointmentStatus && canUpdate()
                ? updateAppointmentStatus(appointmentId)
                : null;
            }}
          >
            {status}
          </div>
        </Grid>
        <Grid item xs={1}>
          <div
            className={
              status == APPOINTMENT_STATUSES.find(st => st.id == AppointmentStatus.Deleted)?.name
                ? styles.disabledText
                : styles.contentItem
            }
          >
            {createdBy}
          </div>
        </Grid>
        <Grid item xs={1}>
          <div
            className={
              status == APPOINTMENT_STATUSES.find(st => st.id == AppointmentStatus.Deleted)?.name
                ? styles.disabledText
                : styles.contentItem
            }
          >
            {updatedBy}
          </div>
        </Grid>
        <Grid item xs={1}>
          <div className={styles.goToItem}>
            <MailIcon onClick={(): void => setOpen(true)} fontSize="small" />
          </div>
        </Grid>
        {!deleted ? (
          <Grid item xs={1}>
            <div className={styles.goToItem}>
              <NavLink
                to={{
                  pathname: `/appointments/${moment(date).format(
                    DateTimeFormatString.APIDateFormat
                  )}`,
                  state: { date: date, locationId: locationId, appointmentId: appointmentId },
                }}
                className={styles.icon}
                title="Go to appointments"
              >
                <ArrowForwardOutlinedIcon fontSize="small" />
              </NavLink>
            </div>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
      <Dialog open={open} onClose={onCloseDialog} title={'Resend Appointment Email'}>
        {sentConfirmation ? (
          <div className={styles.container}>
            <div className={styles.containerHeader}>
              <div className={styles.appointmentOpeningInfo}>
                <p>{responseMessage}</p>
              </div>
            </div>
            <div className={classNames(styles.buttonSection)}>
              <DialogButton
                buttonType={DialogButtonType.AffirmativeLink}
                className={styles.btn}
                onClick={onCloseDialog}
              >
                Ok
              </DialogButton>
            </div>
          </div>
        ) : (
          <div className={styles.container}>
            <div className={styles.containerHeader}>
              <div className={styles.appointmentOpeningInfo}>
                <p>Are you sure you want to resend the appointment details to the patient?</p>
              </div>
            </div>
            <div className={classNames(styles.buttonSection)}>
              <DialogButton buttonType={DialogButtonType.NegationLink} onClick={onCloseDialog}>
                No
              </DialogButton>
              <DialogButton
                buttonType={DialogButtonType.AffirmativeLink}
                className={styles.btn}
                onClick={resendEmail}
              >
                Yes
              </DialogButton>
            </div>
          </div>
        )}
      </Dialog>
    </OddPaper>
  );
}

function AppointmentsHistory(props): ReactElement {
  const patientId = props?.match?.params?.patientId;
  const { patient } = usePatientInfo(patientId);
  const [sortDown, setSortDown] = useState(true);

  const profilecontext = useContext(ProfileContext);
  const { isAdminOrReceptionist } = useProfileRoles();

  const { appointments, loading, changeOrder, refresh } = useAppointmentApi(patientId, true);
  const [openUpdateStatus, setOpenUpdateStatus] = useState<boolean>(false);
  const [selectedAppointment, setSelectedAppointment] = useState<AppointmentInfo>();
  const [isCreateReportDialogOpen, setIsCreateReportDialogOpen] = useState<boolean>(false);
  const [alertDialogText, setAlertDialogText] = useState<string>('');
  const [alertDialogTitle, setAlertDialogTitle] = useState<string>('');
  const [necessityModalOpen, setNecessityModalOpen] = useState<boolean>(false);
  const [doctorsWithApprovedSignatures, setDoctorsWithApprovedSignatures] = useState<Array<User>>(
    []
  );
  const [selectedDoctor, setSelectedDoctor] = useState<User>();
  const [signatureUrl, setSignatureUrl] = useState<string>('');
  const [caseId, setCaseId] = useState<number | undefined>();
  const [hasProcedureAppointment, setHasProcedureAppointment] = useState<boolean>(false);

  const { forms } = useFormsApi(patientId);

  const updateAppointmentStatus = id => {
    const appointment = appointments.find(appt => appt.id == id);
    setSelectedAppointment(appointment);
    setOpenUpdateStatus(true);
  };

  const handelAlert = (text: string, title?: string) => {
    setAlertDialogText(
      text || 'Could Not Create Appointment History Report, Please Try Again Later'
    );
    setAlertDialogTitle(title || 'An Error Occured While Creating Appointment History Report');
  };

  const fetchDoctors = async () => {
    const doctors = await getStaff('', ROLE.DOCTOR, null, null, profilecontext.user?.id);
    setDoctorsWithApprovedSignatures(doctors);
  };

  const fetchCaseId = async () => {
    const ci = await CaseApi.getCaseByUserId(patientId);
    setCaseId(ci[0].id);
  };

  async function downloadSignatureFile(s3Key, doctorId): Promise<any> {
    try {
      const signedRequest = (await downloadSignature(s3Key, doctorId)).signedRequest;
      setSignatureUrl(signedRequest);
    } catch (e) {
      logger.error(e.message);
      setSignatureUrl('');
    }
  }

  function mainFormsCompleted(forms): boolean {
    if (!forms) {
      return false;
    }
    const mainForms = forms.filter(form => {
      if (form.key === 'Doctor' || form.key === 'Patient' || form.key === 'Tech') {
        return form;
      }
    });

    if (!mainForms) {
      return false;
    }
    return mainForms.every(form => {
      return FormStatus.Complete === form.status;
    });
  }

  function reviewReport(): void {
    const procedure = appointments.filter(x => x.typeId == 2)[0];
    props?.history?.push(`/case/${caseId}/workflowResult`, {
      patientId: patientId,
      everyFormCompleted: mainFormsCompleted(forms),
      necessityLetter: true,
      selectedDoctor: selectedDoctor,
      signatureUrl: signatureUrl,
      procedureDate: procedure.start,
    });
  }

  const handleDoctorSelection = event => {
    if (!doctorsWithApprovedSignatures) return;
    const id = event.target.value;
    const doctor = doctorsWithApprovedSignatures.find(doctor => doctor.id == id);
    setSelectedDoctor(doctor);

    if (downloadSignatureFile && doctor?.staffInfo?.signatureS3Key) {
      downloadSignatureFile(doctor?.staffInfo?.signatureS3Key, doctor?.id);
    }
  };

  const onClose = () => {
    setSelectedDoctor(undefined);
    setNecessityModalOpen(false);
  };

  const onPreview = () => {
    reviewReport();
  };

  useEffect(() => {
    if (patientId) {
      fetchCaseId();
    }
  }, [patientId]);

  useEffect(() => {
    if (patient) {
      fetchDoctors();
    }
  }, [patient]);

  useEffect(() => {
    const foundAppointment = appointments.find(appointment => appointment.typeId == 2);
    setHasProcedureAppointment(!!foundAppointment);
  }, [appointments]);

  const getHeadContent = appointments => {
    return (
      <div className={styles.buttons}>
        <div className={styles.button}>
          <Button
            disabled={appointments.length === 0}
            onClick={(): void => setIsCreateReportDialogOpen(true)}
          >
            Create Report
          </Button>
        </div>
        {isAdminOrReceptionist && (
          <StyledButton
            className={styles.button}
            disabled={!mainFormsCompleted(forms) || !caseId || !hasProcedureAppointment}
            onClick={() => setNecessityModalOpen(true)}
          >
            Letter of Necessity
          </StyledButton>
        )}
      </div>
    );
  };

  const handleMatzLocationSelection = event => {
    const location = event.target.value;

    const doc = { ...selectedDoctor, staffInfo: { ...selectedDoctor?.staffInfo } } as User;
    if (doc.staffInfo) {
      doc.staffInfo.ohipPhysicianId = MATZ_BILLING_NUMBERS[location];
      setSelectedDoctor(doc as User);
    }
  };

  return (
    <TitledSection title="Appointments history" headContent={getHeadContent(appointments)}>
      <AlertDialog
        open={!!alertDialogText}
        onClose={() => {
          setAlertDialogText('');
        }}
        title={alertDialogTitle}
        message={alertDialogText}
      ></AlertDialog>
      <AppointmentHistoryReportDialog
        handelAlert={handelAlert}
        isOpen={isCreateReportDialogOpen}
        onClose={() => {
          setIsCreateReportDialogOpen(false);
        }}
        patient={patient}
        appointments={appointments.filter(item => {
          return (
            item.status?.id != AppointmentStatus.Deleted &&
            item.status?.id != AppointmentStatus.Cancelled &&
            item.status?.id != AppointmentStatus.NoShow
          );
        })}
        history={props?.history}
      />
      <Head
        sortDown={sortDown}
        changeOrder={() => {
          setSortDown(!sortDown);
          changeOrder();
        }}
      />
      <ConditionDiv condition={!!appointments.length}>
        {appointments.map((appt, index) => (
          <AppointmentHistoryRow
            key={appt.id}
            timeSlot={getTimeSlot(appt.startTimeOnly, appt.endTimeOnly)}
            isOdd={isOdd(index)}
            date={formatDate(DateTimeFormat.DayMonthYear, appt.start)}
            type={`${appt.type} (${appt.subTypeName})`}
            location={appt.location?.name}
            locationId={appt.location?.id}
            appointmentId={appt.id}
            doctor={appt?.staff?.fullName || ''}
            status={appt?.status?.status || ''}
            updatedBy={appt?.updatedBy}
            createdBy={appt?.createdBy}
            deleted={appt?.deleted}
            updateAppointmentStatus={updateAppointmentStatus}
          />
        ))}
      </ConditionDiv>
      <UpdateAppointmentStatusDialog
        open={openUpdateStatus}
        appointmentId={selectedAppointment?.id}
        onClose={() => {
          setSelectedAppointment(undefined);
          setOpenUpdateStatus(false);
        }}
        refresh={refresh}
      />

      <Dialog open={necessityModalOpen} onClose={onClose} title={`Please Select Doctor`}>
        <div className={styles.signatureWarning}>
          <span>Doctor Signature required</span>: Only doctors that have given permission to use
          their signature will appear here
        </div>
        <div className={styles.selectdiv}>
          <Select
            label="Select Doctor"
            name={'doctor'}
            fullWidth
            onChange={handleDoctorSelection}
            value={selectedDoctor?.id ? selectedDoctor?.id : ''}
          >
            {doctorsWithApprovedSignatures?.map(item => {
              return (
                <MenuItem key={item.id} value={item.id}>
                  {item.fullName}
                </MenuItem>
              );
            })}
          </Select>
        </div>
        {selectedDoctor?.id == MATZ_ID && (
          <div className={styles.selectdiv}>
            <Select
              label="Select Location"
              name={'location'}
              fullWidth
              onChange={handleMatzLocationSelection}
            >
              <MenuItem key={'NS'} value={'NS'}>
                Nova Scotia
              </MenuItem>
              <MenuItem key={'NB'} value={'NB'}>
                New Brunswick
              </MenuItem>
              <MenuItem key={'ON'} value={'ON'}>
                Ontario
              </MenuItem>
            </Select>
          </div>
        )}
        <div className={styles.buttondiv}>
          <StyledButton onClick={onPreview} disabled={selectedDoctor === undefined}>
            Preview Letter
          </StyledButton>
        </div>
      </Dialog>

      <ConditionDiv condition={!appointments.length}>
        {loading ? 'Loading...' : 'Currently no appointments history'}
      </ConditionDiv>
    </TitledSection>
  );
}

export default AppointmentsHistory;
