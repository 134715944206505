import { useState, useEffect } from 'react';
import { QuestionTemplate, Section, QuestionTemplateAnswer } from 'types';
import { Dictionary } from 'lodash';
import _ from 'lodash';

export const useFormQuestions = (
  sections: Array<Section>,
  currentTabAnswers: Map<number, QuestionTemplateAnswer[]>,
  setAnswers: (value) => void
) => {
  // TODO: Switch to questionsMap where possible in `FormView` to
  const [questions, setQuestions] = useState<Dictionary<QuestionTemplate[]>>();
  // Map of all questions form-tab agnostic
  const [questionsMap, setQuestionsMap] = useState<Map<number, QuestionTemplate>>();

  // set initially answered questions as state instead of manipulating the data directly
  useEffect(() => {
    const questionsByTab = {} as Dictionary<Array<QuestionTemplate>>;
    const newQuestionsMap = new Map();
    sections.forEach((section, tab) => {
      const questionsWithInitialAnswers = _.orderBy(section?.templateQuestions || [], 'order');
      questionsByTab[tab] = questionsWithInitialAnswers;

      questionsWithInitialAnswers.forEach(templateQuestion =>
        newQuestionsMap.set(templateQuestion.templateQuestionId, templateQuestion)
      );
    });
    setQuestions(questionsByTab);
    setQuestionsMap(newQuestionsMap);
  }, [sections]);

  /**
   * Generates an array of empty answers to overwrite a questions answers
   *
   * @param templateQuestionId - ID Of the question the answers are being generated for
   * @param answersToGenerateFrom - The answer options we want to replace with these empty answers
   */
  const generateEmptyAnswerArray = (templateQuestionId, answersToGenerateFrom) => {
    // Create empty set of answers
    const emptyAnswers = answersToGenerateFrom.map(answer => {
      const newAnswer: QuestionTemplateAnswer = {
        value: '',
        questionOptionId: answer.questionOptionId,
        templateQuestionId: templateQuestionId,
      };
      return newAnswer;
    });
    return emptyAnswers;
  };

  /**
   * Overwrites the local answers for a question with an empty set to give the apperance that it has not been filled
   * Also so when the form is saved these empty answers overwrite existing ones
   */
  const resetAnswerForQuestion = (currentTabAnswers, templateQuestionId, answers) => {
    // reset the answers for hidden questions because we don't need that kind of negativity in our lives
    const foundAnswers = currentTabAnswers.get(templateQuestionId);
    const serverQuestionAnswers = (questionsMap?.has(templateQuestionId) &&
      questionsMap.get(templateQuestionId)?.answers) || [''];

    // IFF `foundAnswers` has zero length that means we have default values from the servers so we need to overwrite with blanks
    const answersToOverwrite =
      (foundAnswers && foundAnswers.length > 0 && foundAnswers) || serverQuestionAnswers;
    const emptyAnswers = generateEmptyAnswerArray(templateQuestionId, answersToOverwrite);
    currentTabAnswers.set(templateQuestionId, emptyAnswers);
    answers.forEach(answerSectionArray => {
      if (answerSectionArray.has(templateQuestionId)) {
        answerSectionArray.set(templateQuestionId, emptyAnswers);
      }
    });
  };

  const resetAnswersOnChange = (templateQuestionId, answers) => {
    resetAnswerForQuestion(currentTabAnswers, templateQuestionId, answers);
    setAnswers(answers);
  };

  const resetAnswersForHiddenQuestions = (question, currentTabAnswers, answers) => {
    /* Makes sure that each question that gets hidden has their questions reset because we don't need them */
    resetAnswerForQuestion(currentTabAnswers, question.templateQuestionId, answers);
    setAnswers(answers);
  };

  const checkIfQuestionIsShown = (question, currentTabAnswers) => {
    let showTheQuestion = true;

    // if the question requires previous questions
    if (question.requiresPrevious && question.requiresPrevious.length > 0) {
      // Track the number of required questions that need to be answered
      let requiredNum = 0;
      // Retrieve the total number of required questions that are correctely answered
      const requiredPreviousAnswered = question.requiresPrevious.reduce((curRes, curReq, index) => {
        let includesPrevious = 0;

        // Retrieve answers for required parent questions from the currentTabAnswers (newly entered answers). If there is none then use the existing answers from the server
        const newQuestionAnswers = currentTabAnswers.get(curReq.templateQuestionId);
        const serverQuestionAnswers =
          (questionsMap?.has(curReq.templateQuestionId) &&
            questionsMap.get(curReq.templateQuestionId)?.answers) ||
          [];

        const foundAnswers =
          newQuestionAnswers && newQuestionAnswers.length > 0
            ? newQuestionAnswers
            : serverQuestionAnswers;

        const doesntRequireAllPassed = !curReq.requiresAll && curRes > 0;

        // Only if all the subquestions are required do we actually track the number of required questions
        if (curReq.requiresAll) {
          requiredNum += 1;
        }

        // if the current question has a required value
        if (doesntRequireAllPassed) {
          includesPrevious = curRes;
        } else if (curReq.requiredValue) {
          // if there is an answer for the question this current question depends on
          if (foundAnswers) {
            // check each answer
            foundAnswers.forEach(a => {
              const answerLower = (a.value && new String(a.value).toLowerCase()) || '';
              const requiredAnswerLower =
                (curReq.requiredValue && curReq.requiredValue.toLowerCase()) || '';

              //if the answer matches the required value of the question
              if (_.trim(answerLower) === _.trim(requiredAnswerLower)) {
                // it means that previous question is gucci mane
                includesPrevious = 1;
              }
            });
          }
        } else if (curReq.requiredNotNull && foundAnswers && foundAnswers.length > 0) {
          includesPrevious = 1;
        }

        return curRes + includesPrevious;
      }, 0);

      // If we don't require all previous questions then. We show this question if one is answered
      // If we do require all previous questions we must have enough answered questions
      showTheQuestion = requiredPreviousAnswered > 0 && requiredPreviousAnswered >= requiredNum;
    }

    return showTheQuestion;
  };

  return {
    resetAnswersOnChange,
    questions,
    resetAnswersForHiddenQuestions,
    checkIfQuestionIsShown,
  };
};

export default useFormQuestions;
