import { useState, useEffect } from 'react';
import useWorkflowResultApi from 'pages/WorkflowResult/useWorkflowApi.hook';
import { CompositeImageApi } from 'api';
import {
  calculateFollowUpResultsMap,
  calcResults,
} from '../FollowUpResults/lib/calculateFollowUpResults';

interface UseFollowUpResultPDFFragmentProps {
  caseId?: number;
  formId?: number;
}

export const useFollowUpResultPDFFragment = ({
  formId,
  caseId,
}: UseFollowUpResultPDFFragmentProps) => {
  const { formCaseResult } = useWorkflowResultApi(formId, caseId);
  const [followUpFormResults, setFollowUpFormResults] = useState({
    deepVeinThrombosis: '',
    symptomsImproved: '',
    USConfirmedVeinClosed: '',
    cosmeticResultsImprovedVaricoseVeins: '',
    cosmeticResultsImprovedSpiderVeins: '',
    postOPPicturesTaken: '',
    satisfaction: '',
    treatmentRecommended: '',
    additionalNotes: '',
    recommendedFollowUpIn: '',
    recommendedBy: '',
    chargeOrNoCharge: '',
  });

  useEffect(() => {
    if (formCaseResult) {
      setFollowUpFormResults({
        deepVeinThrombosis: formCaseResult?.answers.find(
          ans => ans.id == 'Follow-Up::DeepVeinThrombosis'
        )?.value as string,
        symptomsImproved: formCaseResult?.answers.find(
          ans => ans.id == 'Follow-Up::SymptomsImproved'
        )?.value as string,
        USConfirmedVeinClosed: formCaseResult?.answers.find(
          ans => ans.id == 'Follow-Up::USConfirmedVeinClosed'
        )?.value as string,
        cosmeticResultsImprovedVaricoseVeins: formCaseResult?.answers.find(
          ans => ans.id == 'Follow-Up::CosmeticResultsImprovedVaricoseVeins'
        )?.value as string,
        cosmeticResultsImprovedSpiderVeins: formCaseResult?.answers.find(
          ans => ans.id == 'Follow-Up::CosmeticResultsImprovedSpiderOrReticularVeins'
        )?.value as string,
        postOPPicturesTaken: formCaseResult?.answers.find(
          ans => ans.id == 'Follow-Up::PostOpPicturesTaken'
        )?.value as string,
        satisfaction: formCaseResult?.answers.find(ans => ans.id == 'Follow-Up::Satisfaction')
          ?.value as string,
        treatmentRecommended: formCaseResult?.answers.find(
          ans => ans.id == 'Follow-Up::TreatmentRecommended'
        )?.value as string,
        additionalNotes: formCaseResult?.answers.find(ans => ans.id == 'Follow-Up::AdditionalNotes')
          ?.value as string,
        recommendedFollowUpIn: formCaseResult?.answers.find(
          ans => ans.id == 'Follow-Up::RecommendedFollowUpIn'
        )?.value as string,
        recommendedBy: formCaseResult?.answers.find(ans => ans.id == 'Follow-Up::RecommendedBy')
          ?.value as string,
        chargeOrNoCharge: formCaseResult?.answers.find(
          ans => ans.id == 'Follow-Up::ChargeOrNoCharge'
        )?.value as string,
      });
    }
  }, [formCaseResult]);

  return {
    formCaseResult,
    followUpFormResults,
  };
};

export default useFollowUpResultPDFFragment;
