import React, { ReactElement } from 'react';
import IconButton from '@material-ui/core/IconButton';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import moment, { Moment } from 'moment';

import styles from './MonthSelectorStyles.module.scss';

const FORMAT = 'MMMM';

interface MonthSelectorProps {
  defaultValue?: Moment;
  value?: Moment;
  onChange?: (date: Moment) => void;
}

function MonthSelector({ value = moment(), onChange }: MonthSelectorProps): ReactElement {
  return (
    <div className={styles.main}>
      <IconButton
        color="primary"
        component="span"
        onClick={() => onChange && onChange(value.clone().subtract(1, 'month'))}
      >
        <ArrowLeftIcon fontSize="large" />
      </IconButton>
      <p className={styles.month}>{value?.format(FORMAT)}</p>
      <IconButton
        color="primary"
        component="span"
        onClick={() => onChange && onChange(value.clone().add(1, 'month'))}
      >
        <ArrowRightIcon fontSize="large" />
      </IconButton>
    </div>
  );
}

export default MonthSelector;
