import React, { ReactElement } from 'react';
import classNames from 'classnames';

import Paper from 'components/Paper';

import styles from './OddPaperStyles.module.scss';

interface AppointmentHistoryRowProps {
  isOdd?: boolean;
  className?: string;
  [rest: string]: any;
}

function AppointmentHistoryRow({
  isOdd = false,
  className,
  ...rest
}: AppointmentHistoryRowProps): ReactElement {
  return <Paper {...rest} className={classNames(className, isOdd ? styles.odd : '')} />;
}

export default AppointmentHistoryRow;
