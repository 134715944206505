import React, { ReactElement, useEffect, useState } from 'react';
import { useFormik } from 'formik';

import { Contact, EditableUser, User } from 'types';

import StaffEditDialog from '../StaffEditDialog';

import { UserApi } from 'api';

interface EditProfileContainerProps {
  open: boolean;
  user: User | null;
  loading: boolean;
  onClose(): void;
  onSave(data: EditableUser): void;
}

function getContactByName(contacts: Array<Contact>, name: string): string {
  const contact = contacts.find(item => item.type === name);

  return contact?.value || '';
}

function getContactIdByName(contacts: Array<Contact>, name: string): number | undefined {
  const contact = contacts.find(item => item.type === name);

  return contact?.id;
}

function EditProfileContainer({
  user,
  open,
  loading,
  onClose,
  onSave,
}: EditProfileContainerProps): ReactElement {
  const [staffList, setStaffList] = useState<Array<User>>([]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
      userType: user?.type,
      phone: getContactByName(user?.contacts || [], 'phone'),
      contactId: getContactIdByName(user?.contacts || [], 'phone'),
      ohipPhysicianId: user?.staffInfo?.ohipPhysicianId,
      abbreviations: user?.staffInfo?.abbreviations,
      signatureApprovedUsers: [],
      signatureFile: undefined,
      removeSignature: false,
    },
    onSubmit: async values => {
      if (onSave) {
        await onSave(values);
        formik.setFieldValue('removeSignature', false);
        formik.setFieldValue('signatureFile', undefined);
        formik.resetForm();
      }
    },
  });

  const onLocalClose = () => {
    formik.setFieldValue('removeSignature', false);
    formik.setFieldValue('signatureFile', undefined);
    formik.resetForm();
    onClose();
  };

  const onChangeSignatureFile = file => {
    formik.setFieldValue('signatureFile', file);
    formik.setFieldValue('removeSignature', false);
  };
  const onRemoveSignature = remove => {
    formik.setFieldValue('removeSignature', remove);
    if (!remove) {
      formik.setFieldValue('signatureFile', undefined);
      formik.setFieldValue('signatureApprovedUsers', []);
    }
  };

  const onChangeSignatureApprovedStaff = (staff: Array<User>) => {
    formik.setFieldValue('signatureApprovedUsers', staff);
  };

  const getStaff = async () => {
    const staff = await UserApi.getStaff();
    setStaffList(staff);
  };

  useEffect(() => {
    if (!user?.staffInfo?.signatureApprovedIds) return;
    const approved: Array<User> = [];
    user?.staffInfo?.signatureApprovedIds.forEach(id => {
      const user = staffList.find(staff => staff.id == id);
      if (user) approved.push(user);
    });
    onChangeSignatureApprovedStaff(approved);
  }, [staffList, open]);

  useEffect(() => {
    getStaff();
  }, []);

  return (
    <StaffEditDialog
      open={open}
      title="Edit Staff"
      value={formik.values}
      disabled={{
        userType: true,
      }}
      staffList={staffList}
      loading={loading}
      onChangeSignatureFile={onChangeSignatureFile}
      onChangeSignatureApprovedStaff={onChangeSignatureApprovedStaff}
      onChange={formik.handleChange}
      onSave={formik.handleSubmit}
      onRemoveSignature={onRemoveSignature}
      onClose={onLocalClose}
    />
  );
}

export default EditProfileContainer;
