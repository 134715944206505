import React, { ReactElement } from 'react';

import styles from './TitledSectionStyles.module.scss';

interface TitledSectionProps {
  title?: string;
  headContent?: ReactElement;
  children?: any;
}

function TitledSection({ title, headContent, children }: TitledSectionProps): ReactElement {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>{title}</div>
      </div>
      {headContent}
      {children}
    </div>
  );
}

export default TitledSection;
