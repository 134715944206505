import React, { ReactElement, useState, Fragment } from 'react';
import { useFormik } from 'formik';
import moment from 'moment';

import { UserApi } from 'api';

import PatientEditDialog from 'components/PatientEditDialog';
import { FamDocInfoConfirmDialog } from 'components/FamDocInfoConfirmDialog';
import NewPatientDialogValidator from './utils/NewPatientDialog.validator';
import { handleAPIErrors } from 'lib/handleAPIErrors';
import { ContactType } from 'types';
import { formatHealthCard } from 'lib/patient';

interface NewPatientListDialogContainer {
  open?: boolean;
  onClose: () => void;
  onSaveSuccess: () => void;
  handlePatientChange?: (event, value) => Promise<void>;
  pdfUrl?: string;
}

function NewPatientListDialogContainer({
  open = false,
  pdfUrl,
  onClose,
  onSaveSuccess = () => null,
  handlePatientChange,
}: NewPatientListDialogContainer): ReactElement {
  const [loading, setLoading] = useState(false);
  const [famDocInfoConfirmDialogOpen, setFamDocInfoConfirmDialogOpen] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      cellPhone: '',
      homePhone: '',
      healthCardProvinceCode: '',
      hcn: '',
      healthCard: '',
      provinceCode: '',
      gender: '',
      prefix: '',
      dateOfBirth: moment(),
      other: '',
      familyDoctorId: undefined,
      noHealthCard: false,
      updateFamilyPhysician: false,
      address: {
        line1: '',
        line2: '',
        city: '',
        province: '',
        postalCode: '',
        country: '',
      },
      newPatient: true,
    },
    validate: NewPatientDialogValidator,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async ({
      firstName,
      lastName,
      email,
      phone,
      cellPhone,
      homePhone,
      healthCard,
      hcn,
      healthCardProvinceCode,
      gender,
      dateOfBirth,
      prefix,
      familyDoctorId,
      noHealthCard,
      updateFamilyPhysician,
      address,
      newPatient,
    }) => {
      try {
        setLoading(true);
        const sex = gender === 'Female' ? 'F' : 'M';
        const addresses = address ? [address] : [];
        const body = {
          firstName,
          lastName,
          email,
          userType: 'patient',
          contacts: [
            {
              type: ContactType.Phone,
              value: phone,
            },
            {
              type: ContactType.Cell,
              value: cellPhone,
            },
            {
              type: ContactType.Home,
              value: homePhone,
            },
          ],
          patientInfo: {
            healthCard: formatHealthCard(healthCard),
            hcnVersion: hcn,
            healthCardProvinceCode,
            gender,
            prefix,
            sex,
            dateOfBirth,
            familyDoctorId: familyDoctorId ? familyDoctorId : null,
            noHealthCard,
            updateFamilyPhysician,
            newPatient,
          },
          addresses,
        };
        const isNewPatient = await UserApi.createPatient(body);

        await onSaveSuccess();
        if (isNewPatient && handlePatientChange) handlePatientChange(null, isNewPatient);
        formik.resetForm();
      } catch (e) {
        formik.setErrors({ other: handleAPIErrors(e) });
      }

      setLoading(false);
    },
  });

  function onCloseAndResetForm() {
    formik.resetForm();
    onClose();
  }

  return (
    <Fragment>
      <PatientEditDialog
        open={open}
        title="New Patient"
        loading={loading}
        value={formik.values}
        errors={formik.errors}
        onChange={formik.setFieldValue}
        onClose={onCloseAndResetForm}
        isNewPatient={true}
        onSave={formik.handleSubmit}
        pdfUrl={pdfUrl}
      />
    </Fragment>
  );
}

export default NewPatientListDialogContainer;
