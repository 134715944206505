import React, { ReactElement, useContext } from 'react';
import { Task as TaskApi } from 'types';
import moment from 'moment';

import { User } from 'types';
import ConditionDiv from 'components/ConditionDiv';
import TitledSection from 'components/TitledSection';
import Task from './components/Task';
import { ROLE } from 'lib/user';
import { Context as AuthContext } from 'context/auth';
import { Context as ProfileContext } from 'context/profile';
import styles from './TasksStyles.module.scss';

interface TasksProps {
  tasks: Array<TaskApi>;
  patient: User | null;
  headContent?: any;
  onReassign?: () => void | undefined;
  setTaskToEdit?: (value) => void;
  changeTaskStatus?: (taskId, value) => void;
}

function Tasks({
  tasks,
  headContent,
  onReassign,
  changeTaskStatus,
  setTaskToEdit,
}: TasksProps): ReactElement {
  const headContentUse = headContent ? headContent : <div />;
  const { role } = useContext(AuthContext);
  const { user } = useContext(ProfileContext);

  const callSetTaskToEdit = item => {
    if (setTaskToEdit) {
      return setTaskToEdit(item);
    }
  };

  const callOnClickComplete = item => {
    if (changeTaskStatus) {
      const params = {
        title: item.title,
        description: item.description,
        completed: !item.completed ? true : false,
      };
      return changeTaskStatus(item.id, params);
    }
  };

  return (
    <TitledSection title="Tasks" headContent={headContentUse}>
      <div className={styles.tasks}>
        <div className={styles.paper}>
          <ConditionDiv condition={!!tasks.length}>
            {tasks.map((item: TaskApi) => {
              return (
                <Task
                  key={`task-${item.id}`}
                  data={{
                    title: item.title,
                    patientName: item.patientFullName,
                    createdBy: item.creatorFullName,
                    assignedTo: item.assignedTo,
                    createdAt: moment(item.created_at).format('YYYY MMM DD'),
                    description: item.description,
                    patientId: item.patientId,
                  }}
                  title={item.title}
                  setTaskToEdit={() => callSetTaskToEdit(item)}
                  onClickComplete={
                    role === ROLE.SUPER_ADMIN ||
                    role === ROLE.ADMIN ||
                    user?.staffInfo?.id === item.staff_id
                      ? () => callOnClickComplete(item)
                      : undefined
                  }
                  onReassign={
                    role === ROLE.SUPER_ADMIN ||
                    role === ROLE.ADMIN ||
                    user?.staffInfo?.id === item.staff_id ||
                    user?.id === item.created_by
                      ? onReassign
                      : undefined
                  }
                  completed={item.completed}
                  className={styles.task}
                  parentUrl={''}
                />
              );
            })}
          </ConditionDiv>
          <ConditionDiv condition={!tasks.length}>Currently no tasks</ConditionDiv>
        </div>
      </div>
    </TitledSection>
  );
}

export default Tasks;
