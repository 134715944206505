import React, { ReactElement, useEffect, useState } from 'react';
import TitledSection from 'components/TitledSection';
import Button from 'components/Button';
import FaxSent from './FaxSent.view';
import InputSearch from 'pages/Staff/components/InputSearch';
import { NewFaxDialogContainer } from '../NewFaxDialog';
import { SendFaxDialogContainer } from '../SendFaxDialog';
import styles from './FaxSent.module.scss';
import { getOutboundFax, refreshOutbound } from 'api/fax';
import { FaxOutbound } from 'types';
import { Checkbox } from '@material-ui/core';
import DialogButton from 'components/DialogButton';
import { DialogButtonType } from 'components/DialogButton/DialogButton.view';
import { AlertDialog } from 'components/AlertDialog';
import * as logger from 'api/logger';

function FaxSentContainer(): ReactElement | null {
  const [openNewFax, setOpenNewFax] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [faxes, setFaxes] = useState<Array<FaxOutbound>>([]);
  const [includeCompleted, setIncludeCompleted] = useState<boolean>(true);
  const [includePending, setIncludePending] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [openWarning, setOpenWarning] = useState<boolean>(false);

  async function fetchFaxes(): Promise<void> {
    setLoading(true);
    try {
      const faxes = await getOutboundFax(searchQuery, includePending, includeCompleted);
      setFaxes(faxes);
    } catch (e) {
      logger.error(e.message);
      setOpenWarning(true);
    }
    setLoading(false);
  }

  const onRefresh = async () => {
    setLoading(true);
    const faxes = await refreshOutbound(searchQuery, includePending, includeCompleted);
    setFaxes(faxes);
    setLoading(false);
  };

  const onNewFaxClick = (): void => {
    setOpenNewFax(true);
  };

  const getFaxes = () => {
    fetchFaxes();
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getFaxes();
    }, 300);

    return (): void => clearTimeout(delayDebounceFn);
  }, [searchQuery, includeCompleted, includePending]);

  const headContent = (
    <div className={styles.formListButtonContainer}>
      <div className={styles.checkbox}>
        <Checkbox
          name={'includeCompleted'}
          checked={includeCompleted}
          onChange={event => setIncludeCompleted(event.target.checked)}
        />
        <div>{'Sent'}</div>
      </div>
      <div className={styles.checkbox}>
        <Checkbox
          name={'includePending'}
          checked={includePending}
          onChange={event => setIncludePending(event.target.checked)}
        />
        <div>{'Pending'}</div>
      </div>
      <div className={styles.button}>
        <InputSearch
          value={searchQuery}
          onChange={(event): void => setSearchQuery(event.target.value)}
        />
      </div>
      <div className={styles.button}>
        <Button className={`${styles.marginLeft} ${styles.marginTop}`} onClick={onNewFaxClick}>
          New Fax
        </Button>
      </div>
      <div className={styles.refresh}>
        <DialogButton
          className={`${styles.marginLeft} ${styles.marginTop}`}
          buttonType={DialogButtonType.AffirmativeLink}
          onClick={onRefresh}
          loading={loading}
        >
          Refresh
        </DialogButton>
      </div>
    </div>
  );

  return (
    <TitledSection headContent={headContent}>
      <FaxSent faxes={faxes} />
      <NewFaxDialogContainer
        open={openNewFax}
        onClose={(): void => setOpenNewFax(false)}
        onSaveSuccess={(): void => {
          setOpenNewFax(false);
          getFaxes();
        }}
      />
      <AlertDialog
        open={openWarning}
        message={'Contact developers if problem persists'}
        title={'Error Getting Faxes'}
        onClose={() => setOpenWarning(false)}
      />
    </TitledSection>
  );
}

export default FaxSentContainer;
