import React, { ReactElement } from 'react';

import styles from './ProfileInfoRecordStyles.module.scss';

interface ProfileInfoRecordProps {
  label?: string;
  value?: string;
  rightContent?: ReactElement;
}

function ProfileInfoRecord({ label, value, rightContent }: ProfileInfoRecordProps): ReactElement {
  return (
    <div className={styles.container}>
      <div className={styles.dot} />
      <div className={styles.data}>
        <div className={styles.label}>{label}:</div>
        <div className={styles.value}>{value}</div>
      </div>
      {rightContent}
    </div>
  );
}

export default ProfileInfoRecord;
