import React, { ReactElement } from 'react';
import classNames from 'classnames';
import styles from './OperationInProgress.module.scss';
import { OperationInfo } from 'types';

interface OperationInProgressProps {
  title: string;
  description: string;
  disabled?: boolean;
  onClick: (event: any) => void;
}

function OperationInProgress({
  title,
  description,
  disabled = true,
  onClick,
}: OperationInProgressProps): ReactElement {
  return (
    <div className={classNames(styles.container, disabled ? styles.disabled : styles.active)}>
      <div className={styles.details}>
        <div className={styles.operation}>{`${title}:`}</div>
        <div className={styles.description}>{description}</div>
      </div>

      <button className={styles.resetBtn} onClick={onClick} />
    </div>
  );
}

export default OperationInProgress;
