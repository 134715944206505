import { useState, useEffect } from 'react';
import { NewBlobDocument, Document, DocumentType, Form, ConfirmCompleteDocument } from 'types';
import * as logger from 'api/logger';

import {
  createBlobDocument as createBlobDocumentRequest,
  getDocument,
  getDocumentTypes,
  deleteDocument,
} from 'api/document';

import { sendForm, submitFormDocument, confirmCompleteDocument } from 'api/form';

import { getCaseByUserId } from 'api/case';

import { getForm } from 'api/form';
import { FormStatusId } from 'lib/form';
import { bind } from 'lodash';

export interface DocumentEditState {
  loading: boolean;
  createBlobDocument(
    data: NewBlobDocument,
    signingDoctorId?: number,
    signingDoctorBillingNumber?: string
  ): Promise<ConfirmCompleteDocument>;
  confirmDocument(documentId: number, sendToPatient: boolean): void;
  form?: Form;
}

function useDocumentEditApi(patientId, caseId, formId, sendToPatient): DocumentEditState {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState<Form>();

  async function createBlobDocument(
    data: NewBlobDocument,
    signingDoctorId?: number,
    signingDoctorBillingNumber?: string
  ): Promise<ConfirmCompleteDocument> {
    setLoading(true);
    try {
      const documentDetails = await submitFormDocument(
        formId,
        data,
        undefined,
        signingDoctorId,
        signingDoctorBillingNumber
      );
      setLoading(false);
      return documentDetails;
    } catch (e) {
      logger.error(e.message);
      setLoading(false);
      throw e;
    }
  }

  async function confirmDocument(documentId: number, sendToPatient: boolean): Promise<void> {
    try {
      if (sendToPatient) {
        // send to patient
        await sendForm(formId);
      } else {
        await confirmCompleteDocument(documentId);
      }
    } catch (e) {
      logger.error(e.message);
      setLoading(false);
      throw e;
    }
  }

  async function retrieveFormToEdit(): Promise<void> {
    try {
      const form = await getForm(formId);
      setForm(form);
    } catch (e) {
      logger.error(e.message);
    }
  }

  useEffect(() => {
    retrieveFormToEdit().catch();
  }, []);

  return {
    loading,
    createBlobDocument,
    confirmDocument,
    form,
  };
}

export default useDocumentEditApi;
