import React, { ReactElement, useState } from 'react';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import { CircularProgress } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import Dialog from 'components/Dialog';
import DialogButton from 'components/DialogButton';
import Autocomplete from 'components/Autocomplete';
import ConditionDiv from 'components/ConditionDiv';
import OddPaper from 'pages/Patient/components/AppointmentsHistory/components/OddPaper';
import { AppointmentAvailabilityNext } from 'types';
import { SLOT_LENGTH_MINIMUM_5_MINUTES, APPOINTMENT_TYPES } from 'lib/appointments';
import { formatDate, DateTimeFormat, DateTimeFormatString } from 'lib/dateFormatter';
import { Staff as UserStaff } from 'api/user/user.interfaces';
import styles from '../../ResponsiveTopBar.module.scss';
import { AppointmentsApi } from 'api';

interface HeadProps {
  sortDown: boolean;
  changeOrder(): void;
}

function Head({ sortDown, changeOrder }: HeadProps): ReactElement {
  const Arrow = sortDown ? ArrowDropDownIcon : ArrowDropUpIcon;

  return (
    <OddPaper className={styles.head} isOdd>
      <Grid container className={styles.rowContainer}>
        <Grid item xs={2} className={styles.dateContent}>
          <div className={styles.date}>Date</div>
          <Arrow onClick={changeOrder} />
        </Grid>
        <Grid item xs={3}>
          <div className={styles.headItem}>Time slot</div>
        </Grid>
        <Grid item xs={2}>
          <div className={styles.headItem}>Length</div>
        </Grid>
        <Grid item xs={2}>
          <div className={styles.location}>Location</div>
        </Grid>
        <Grid item xs={2}>
          <div className={styles.headItem}>Staff</div>
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </OddPaper>
  );
}

function isOdd(num): boolean {
  return !!(num % 2);
}

function getTimeSlot(startTime, endTime): string {
  const startString = moment(startTime, DateTimeFormatString.TimeOnlyWithoutMeridies).format(
    DateTimeFormatString.TimeOnlyCompact
  );
  const endString = moment(endTime, DateTimeFormatString.TimeOnlyWithoutMeridies).format(
    DateTimeFormatString.TimeOnlyCompact
  );
  return `${startString} - ${endString}`;
}

interface FirstAppointmentRowProps {
  index: number;
  date: string;
  timeSlot: string;
  location?: string;
  staff?: string;
  length?: number;
  isOdd: boolean;
  onSelectOpening: (index) => void;
}

function FirstAppointmentRow({
  index,
  date,
  timeSlot,
  isOdd,
  location,
  length,
  staff,
  onSelectOpening,
}: FirstAppointmentRowProps): ReactElement {
  return (
    <OddPaper className={styles.head} isOdd={isOdd}>
      <Grid container className={styles.rowContainer}>
        <Grid item xs={2}>
          <div className={styles.contentItem}>{date}</div>
        </Grid>
        <Grid item xs={3}>
          <div className={styles.contentItem}>{timeSlot}</div>
        </Grid>
        <Grid item xs={2}>
          <div className={styles.contentItem}>{length}</div>
        </Grid>
        <Grid item xs={2}>
          <div className={styles.contentItem}>{location}</div>
        </Grid>
        <Grid item xs={2}>
          <div className={styles.contentItem}>{staff}</div>
        </Grid>
        <Grid item xs={1}>
          <div className={styles.goToItem}>
            <ArrowForwardOutlinedIcon
              onClick={(): void => onSelectOpening(index)}
              fontSize="small"
            />
          </div>
        </Grid>
      </Grid>
    </OddPaper>
  );
}

interface Location {
  id: number;
  name: string;
}

interface AppointmentType {
  id: number;
  name: string;
}

interface SelectedLength {
  id: number;
  offsetSpaceUse: number;
  name: string;
}

interface FirstAppointmentProps {
  open: boolean;
  showError: boolean;
  staffList: Array<UserStaff> | undefined;
  locations: Array<Location>;
  closeModal: () => void;
  onSelectOpening: (date, location, appointmentDetails) => void;
}

function FirstAppointment({
  open,
  showError,
  staffList,
  locations,
  closeModal,
  onSelectOpening,
}: FirstAppointmentProps): ReactElement {
  const [sortDown, setSortDown] = useState(true);

  const [selectedLocations, setSelectedLocations] = useState<Array<Location>>([]);
  const [appointmentType, setAppointmentType] = useState<AppointmentType>();
  const [selectedLength, setSelectedLength] = useState<SelectedLength>();
  const [validationErrors, setValidationErrors] = useState<Array<any>>([]);
  const [staff, setStaff] = useState<UserStaff>();
  const [appointmentOpenings, setAppointmentOpenings] = useState<
    Array<AppointmentAvailabilityNext>
  >([]);
  const [loading, setLoading] = useState<boolean>(false);

  function validateForm(): boolean {
    let validated = true;
    const validationErrors: Array<any> = [];
    if (!appointmentType) {
      const validationError = {
        field: 'Appointment type',
        message: 'Please select an appointment type',
      };
      validationErrors.push(validationError);
      validated = false;
    }
    setValidationErrors(validationErrors);
    return validated;
  }

  const getAppointmentAvailability = async (): Promise<void> => {
    if (!validateForm()) return;
    if (appointmentType) {
      setLoading(true);
      const response = await AppointmentsApi.getNextAvailableAppointment(
        appointmentType.id,
        selectedLength ? selectedLength.id : SLOT_LENGTH_MINIMUM_5_MINUTES[0].id,
        selectedLocations.map(loc => loc.id),
        staff?.id
      );
      setLoading(false);
      if (response) {
        setAppointmentOpenings(response);
      } else {
        //setShowError(true);
      }
    }
  };

  const selectOpening = (index): void => {
    const opening = appointmentOpenings[index];
    const locationId = opening.location_id;
    const newDate = moment(opening.date);
    const startTime = opening.start_time;
    const appointmentLength = selectedLength
      ? selectedLength.id
      : SLOT_LENGTH_MINIMUM_5_MINUTES[0].id;
    const appointmentEndTime = moment(startTime, DateTimeFormatString.TimeOnlyWithoutMeridies)
      .add(appointmentLength, 'minutes')
      .format(DateTimeFormatString.TimeOnlyWithoutMeridies);
    const appointmentDetails = {
      day: newDate,
      type: appointmentType ? appointmentType.name : '',
      startTime: startTime,
      endTime: appointmentEndTime,
      length: appointmentLength,
      staffId: opening.staff_id,
    };
    onSelectOpening(newDate, locationId, appointmentDetails);
  };

  const onClose = () => {
    setSelectedLength(undefined);
    setSelectedLocations([]);
    setStaff(undefined);
    setAppointmentType(undefined);
    setValidationErrors([]);
    setAppointmentOpenings([]);
    closeModal();
  };

  if (appointmentOpenings.length > 0) {
    return (
      <Dialog open={open} onClose={onClose} title={'First Appointment'}>
        <div className={styles.resultsContainer}>
          <Grid item xs={12} sm={12}>
            <Head
              sortDown={sortDown}
              changeOrder={() => {
                setSortDown(!sortDown);
              }}
            />
            <ConditionDiv condition={!!appointmentOpenings.length}>
              {appointmentOpenings.map((result, index) => {
                const newDate = moment(result.date);
                const startTime = result.start_time;
                const appointmentLength = selectedLength
                  ? selectedLength.id
                  : SLOT_LENGTH_MINIMUM_5_MINUTES[0].id;
                const appointmentEndTime = moment(
                  startTime,
                  DateTimeFormatString.TimeOnlyWithoutMeridies
                )
                  .add(appointmentLength, 'minutes')
                  .format(DateTimeFormatString.TimeOnlyWithoutMeridies);
                return (
                  <FirstAppointmentRow
                    key={index}
                    index={index}
                    timeSlot={getTimeSlot(startTime, appointmentEndTime)}
                    isOdd={isOdd(index)}
                    date={formatDate(DateTimeFormat.DayMonthYear, newDate)}
                    length={appointmentLength}
                    location={result.location}
                    staff={result.staff_full_name}
                    onSelectOpening={selectOpening}
                  />
                );
              })}
            </ConditionDiv>
          </Grid>
        </div>
      </Dialog>
    );
  }
  return (
    <Dialog open={open} onClose={closeModal} title={'First Appointment'}>
      <form className={styles.container}>
        <Grid container>
          <Grid item xs={12} sm={12}>
            <div className={styles.topPadding}>
              <Autocomplete
                label="Appointment type"
                name="Appointment type"
                items={APPOINTMENT_TYPES}
                value={appointmentType}
                validationErrors={validationErrors}
                vaidationErrorKey="Appointment type"
                getOptionLabel={(item): string => `${item.name}`}
                onChange={(event, newValue): void => setAppointmentType(newValue)}
                fullWidth
              />
            </div>
            <div className={styles.topPadding}>
              <Autocomplete
                label="Length of appointment"
                name="Length of appointment"
                items={SLOT_LENGTH_MINIMUM_5_MINUTES}
                value={selectedLength}
                validationErrors={validationErrors}
                vaidationErrorKey="Length of appointment"
                getOptionLabel={(value): string => {
                  if (value) {
                    value = value.id;
                    return value < 60
                      ? `${value} minutes`
                      : `${Math.floor(value / 60)} hour${Math.floor(value / 60) > 1 ? 's' : ''} ${
                          value % 60 > 0 ? `and ${value % 60} minutes` : ''
                        }`;
                  } else {
                    return '';
                  }
                }}
                onChange={(event, newValue): void => setSelectedLength(newValue)}
                fullWidth
              />
            </div>
            <div className={styles.topPadding}>
              <Autocomplete
                label="Locations"
                items={locations}
                value={selectedLocations}
                getOptionLabel={type => `${type.name}`}
                onChange={(event, newValue) => setSelectedLocations(newValue)}
                multiple
                fullWidth
              />
            </div>
            <div className={styles.topPadding}>
              <Autocomplete
                label="Staff"
                name="Staff"
                items={staffList}
                value={staff}
                validationErrors={validationErrors}
                vaidationErrorKey="Staff"
                getOptionLabel={(type): string => `${type.name}`}
                onChange={(event, newValue): void => setStaff(newValue)}
                fullWidth
              />
            </div>
            {showError ? (
              <div className={styles.topPadding}>
                <div className={styles.textError}>No available appointments.</div>
              </div>
            ) : null}
            <div className={styles.buttons}>
              <DialogButton onClick={closeModal}>Cancel</DialogButton>
              {!loading ? (
                <DialogButton
                  className={styles.marginLeft}
                  loading={false}
                  onClick={getAppointmentAvailability}
                >
                  Find
                </DialogButton>
              ) : (
                <CircularProgress
                  className={styles.circularProgress}
                  size={48}
                  style={{ padding: '10px' }}
                />
              )}
            </div>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
}

export default FirstAppointment;
