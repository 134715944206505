import React, { ReactElement } from 'react';
import TextField from '@material-ui/core/TextField';

import Logo from 'assets/images/logo.png';
import Card from 'components/Card';
import ConditionDiv from 'components/ConditionDiv';
import Button from '../Login/components/Button';

import styles from './ForgotPasswordStyles.module.scss';

interface ForgotPasswordProps {
  email?: string;
  loading?: boolean;
  nextStepInstruction?: string;
  errors?: {
    email?: string;
    api?: string;
  };
  onChange?: (e) => void;
  onSubmit?: () => void;
}

function ForgotPassword({
  email,
  onSubmit,
  errors,
  nextStepInstruction,
  onChange,
  loading = false,
}: ForgotPasswordProps): ReactElement {
  return (
    <form className={styles.container} onSubmit={onSubmit}>
      <Card>
        <div className={styles.form}>
          <img className={styles.logo} src={Logo} alt="logo" />
          <ConditionDiv className={styles.fields} condition={!nextStepInstruction}>
            <div className={styles.field}>
              <p className={styles.info}>
                Enter the email you use to login for receive further instructions.
              </p>
            </div>
            <div className={styles.field}>
              <TextField
                error={!!errors?.email}
                label={'Email'}
                name="email"
                value={email}
                onChange={onChange}
                placeholder={'email'}
                helperText={errors?.email}
                type="email"
              />
            </div>
          </ConditionDiv>
          <ConditionDiv className={styles.fields} condition={!!nextStepInstruction}>
            <div className={styles.field}>
              <p className={styles.info}>{nextStepInstruction}</p>
            </div>
          </ConditionDiv>
          <div className={styles.buttonWrapper}>
            <Button loading={loading} type="submit">
              {nextStepInstruction ? 'Ok' : 'Send Email'}
            </Button>
          </div>
        </div>
      </Card>
    </form>
  );
}

export default ForgotPassword;
