import { red } from '@material-ui/core/colors';
import { StyleSheet, Font } from '@react-pdf/renderer';

Font.register({
  family: 'Lora',
  fonts: [{ src: 'https://fonts.gstatic.com/s/lora/v17/0QI6MX1D_JOuGQbT0gvTJPa787z5vCJG.ttf' }],
});

// Create styles
export const stylesPdf = StyleSheet.create({
  page: {
    flexDirection: 'row',
    width: '100%',
    paddingTop: 10,
    paddingBottom: 20,
    paddingLeft: 30,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  header: {
    fontSize: 20,
    marginBottom: 5,
    marginTop: 10,
    width: '100%',
    fontWeight: 'heavy',
    borderBottomColor: '#a5a5a5',
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
  },
  subheader: {
    fontSize: 16,
    marginBottom: 5,
    width: '100%',
    fontWeight: 'heavy',
  },
  headerNote: {
    fontSize: 11,
    marginBottom: 0,
    marginTop: 0,
    paddingBottom: 0,
    paddingTop: 0,
    width: '100%',
    fontColor: '#a5a5a5',
    fontWeight: 'heavy',
    textAlign: 'center',
  },
  headerLocations: {
    fontSize: 8.5,
    marginBottom: 0,
    marginTop: 0,
    paddingBottom: 15,
    paddingTop: 0,
    width: '100%',
    fontColor: '#a5a5a5',
    fontWeight: 'heavy',
    textAlign: 'center',
  },
  veinSubheader: {
    fontSize: 12,
    paddingBottom: 5,
    marginBottom: 5,
    marginTop: 5,
    width: '100%',
    fontWeight: 'heavy',
    borderBottomColor: '#a5a5a5',
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
  },
  infoLine: {
    marginTop: 10,
    flexDirection: 'row',
  },
  resultsColumn: {
    flexDirection: 'row',
    width: '100%',
    paddingTop: 5,
  },
  resultsBreakdown: {
    width: '50%',
    paddingTop: 5,
  },
  column: {
    width: '50%',
    fontSize: 11,
    marginRight: 20,
  },
  issue: {
    marginBottom: 10,
  },
  issueText: {
    marginBottom: 5,
    width: '100%',
    marginLeft: 10,
  },
  resultsContainer: {
    flexDirection: 'row',
    width: '100%',
  },
  letterPage: {
    flexDirection: 'row',
    width: '100%',
    paddingTop: 10,
    paddingBottom: 20,
    paddingLeft: 30,
    paddingRight: 30,
  },
  letterContent: {
    fontSize: 11,
  },
  infoMeta: {
    fontSize: 11,
    marginBottom: 5,
    marginTop: 5,
  },
  letterBody: {
    marginTop: 30,
    marginBottom: 30,
  },
  paragraph: {
    marginBottom: 5,
  },
  legendImage: {
    width: '60%',
  },
  headerImage: {
    width: '50%',
    aspectRatio: 5 / 2,
  },
  letterHeader: {
    width: '100%',
    marginLeft: -30,
    alignItems: 'center',
  },
  letterSection: {
    marginLeft: 10,
    marginRight: 10,
    marginTop: 0,
    marginBottom: 0,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 0,
    paddingBotom: 0,
    flexGrow: 1,
  },
  letterContentDate: {
    textAlign: 'right',
    width: '100%',
    fontSize: 14,
    fontFamily: 'Lora',
    marginBottom: 0,
    marginTop: 0,
    paddingBottom: 5,
    paddingTop: 0,
  },
  letterContentTitle: {
    textAlign: 'left',
    width: '100%',
    fontSize: 14,
    fontFamily: 'Lora',
    marginBottom: 10,
    marginTop: 0,
    paddingBottom: 0,
    paddingTop: 0,
  },
  followUpPage: {
    fontFamily: 'Lora',
    height: 'auto',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 20,
    paddingLeft: 10,
    fontSize: '17px',
  },
  followUpPageAdditionalNotes: {
    fontFamily: 'Lora',
    height: 'auto',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 20,
    paddingLeft: 10,
    fontSize: '17px',
  },
  followUpContainer: {
    margin: 'auto',
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  followUpHeader: {
    marginTop: '10px',
    marginBottom: '10px',
    borderBottomColor: '#a5a5a5',
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  followUpPatientName: {
    width: '80%',
  },
  followUpResults: {
    marginTop: '10px',
    marginBottom: '10px',
    display: 'flex',
    flexDirection: 'row',
    borderBottomColor: '#a5a5a5',
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    justifyContent: 'space-between',
  },
  followUpHeaderImage: {
    width: '350px',
    height: 'auto',
  },
  followUpAdditionalNotesHeader: {
    marginBottom: '5px',
    borderBottomColor: '#a5a5a5',
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
  },
  followUpAdditionalNotes: {
    marginTop: '20px',
    marginBottom: '20px',
    textAlign: 'center',
    fontSize: '15px',
  },
  followUpResultsHeader: {
    borderBottomColor: '#a5a5a5',
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    marginTop: '20px',
    marginBottom: '20px',
    textAlign: 'center',
    fontSize: '20px',
    fontWeight: 'bold',
  },
  signatureImageContainer: {
    width: '30%',
    maxHeight: '150px',
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 50,
  },
});
