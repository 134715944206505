import React, { Fragment, ReactElement, useState, useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import { UserApi } from 'api';
import { User, Note, Task } from 'types';

import Notes from './components/Notes';
import Tasks from '../Tasks';
import Button from 'components/Button';
import CreateNewNoteModal from './components/CreateNewNoteModal';
import AssignNewTaskModal from './components/AssignNewTaskModal';
import usePatientInfo from 'pages/Patient/components/PatientInfo/usePatientApi.hook';
import useNotesApi from '../../hook/useNotesApi.hook';
import useTasksApi from '../../hook/useTasksApi.hook';
import { Staff as UserStaff } from 'api/user/user.interfaces';
import { transformUserToStaff } from 'api/user/user.transformer';

import styles from './NotesAndTasksStyles.module.scss';
import SuccessSnackBar from 'components/SuccessSnackBar';
import { AlertDialog } from 'components/AlertDialog';
import * as logger from 'api/logger';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function NotesAndTasks(props): ReactElement {
  const patientId = props?.match?.params?.patientId;
  const [taskToEdit, setTaskToEdit] = useState<any>(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [staff, setStaff] = useState<User | null>(null);
  const [noteStaffSendList, setNoteStaffSendList] = useState<Array<User>>([]);
  const [staffList, setStaffList] = useState<Array<UserStaff>>([]);
  const [isNewNoteModal, setIsNewNoteModal] = useState(false);
  const [noteSelected, setNoteSelected] = useState<Note | null>(null);

  const [isEditTask, setIsEditTask] = useState(false);
  const [isAssignNewTaskModal, setIsAssignNewTaskModal] = useState(false);
  const { patient } = usePatientInfo(patientId);
  const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
  const [openWarningDialog, setOpenWarningDialog] = useState<boolean>(false);
  const [popupMessage, setPopupMessage] = useState<string>('');
  const { notes, loading, createNote, sendNote, deleteNote, updateNote, fetchNotes } = useNotesApi(
    patientId
  );
  const { tasks, createTask, fetchTasks, updateTask } = useTasksApi({ patientId });
  const [loadingTask, setLoadingTask] = useState(false);

  const patientViewTasksHeadContent = (
    <div className={styles.button}>
      <Button onClick={() => setIsAssignNewTaskModal(true)}>Assign Task</Button>
    </div>
  );

  async function getStaff(): Promise<void> {
    try {
      const users = await UserApi.getStaff('', undefined, '');
      let staffList: UserStaff[] = [];

      if (users) staffList = await transformUserToStaff(users);

      setStaffList(staffList);
    } catch (e) {
      if (e.response.status === 401) props?.history?.push('/');
    }
  }

  useEffect(() => {
    getStaff();
  }, []);

  async function onNewNoteSave(values) {
    try {
      const newNote = { ...values };
      newNote.staff = noteStaffSendList.map(staff => staff?.id);
      let action = 'created';

      if (!noteSelected) {
        newNote.staff = noteStaffSendList.map(staff => staff?.id);
        await createNote(patientId, newNote);
      } else {
        action = 'updated';
        newNote.noteId = noteSelected.id;
        await updateNote(patientId, newNote);
      }
      setNoteStaffSendList([]);
      setPopupMessage(`Note was successfully ${action}`);
      setIsNewNoteModal(false);
      await fetchNotes(patientId);
      setSnackBarOpen(true);
    } catch (e) {
      setPopupMessage(e.response.data.message);
      setOpenWarningDialog(true);
    }
  }

  function resetForm(): void {
    setTitle('');
    setDescription('');
    setStaff(null);
    setNoteStaffSendList([]);
  }

  async function changeTaskStatus(taskId, task) {
    try {
      await updateTask(taskId, task);
      await fetchTasks(patientId);
    } catch (e) {
      logger.error(e.message);
    }
  }

  async function onNewTaskSave() {
    setLoadingTask(true);
    try {
      const task = {
        title: title,
        description: description,
        staffId: staff?.id,
      };
      if (isEditTask) {
        await updateTask(taskToEdit.id, task);
      } else {
        await createTask(patientId, task);
      }
      await fetchTasks(patientId);
      setIsAssignNewTaskModal(false);
      setIsEditTask(false);
      resetForm();
      setPopupMessage(`The task has been  ${isEditTask ? 'updated' : 'created'}`);
      setSnackBarOpen(true);
      setTaskToEdit(null);
    } catch (e) {
      setPopupMessage(e.response.data.message);
      setOpenWarningDialog(true);
      logger.error(e.message);
    }
    setLoadingTask(false);
  }

  async function onSend(noteId: number, staffSendId: Array<number>) {
    try {
      await sendNote(noteId, staffSendId);
      setPopupMessage('The Note has been sent');
      setSnackBarOpen(true);
    } catch (e) {
      setPopupMessage(
        'Sending note failed, try again. If problem persist please contact developer'
      );
      setOpenWarningDialog(true);
    }
  }

  async function onDelete(noteId: number) {
    try {
      await deleteNote(patientId, noteId);
      setPopupMessage('Note was successfully deleted');
      setSnackBarOpen(true);
    } catch (e) {
      setPopupMessage('Deletion failed, try again. If problem persist please contact developer');
      setOpenWarningDialog(true);
    }
    setSnackBarOpen(true);
  }

  function onCreateNote() {
    setIsNewNoteModal(true);
  }

  function onEdit(note: Note) {
    setNoteSelected(note);
    setIsNewNoteModal(true);
  }

  useEffect(() => {
    if (isNewNoteModal) return;
    setNoteSelected(null);
  }, [isNewNoteModal]);

  return (
    <Fragment>
      <Grid container>
        <Grid item sm={6} xs={12}>
          <div className={styles.left}>
            <Notes
              notes={notes}
              loading={loading}
              onCreateNote={onCreateNote}
              onSendNoteClick={onSend}
              onDeleteNoteClick={onDelete}
              onUpdateNote={onEdit}
            />
          </div>
        </Grid>
        <Grid item sm={6} xs={12}>
          <div className={styles.right}>
            <Tasks
              tasks={tasks}
              patient={patient}
              headContent={patientViewTasksHeadContent}
              setTaskToEdit={setTaskToEdit}
              changeTaskStatus={changeTaskStatus}
              onReassign={() => setIsEditTask(true)}
            />
          </div>
        </Grid>
      </Grid>
      <CreateNewNoteModal
        open={isNewNoteModal}
        patientName={patient?.fullName}
        loading={loading}
        onClose={() => setIsNewNoteModal(false)}
        onSave={onNewNoteSave}
        staffSendList={noteStaffSendList}
        staffList={staffList}
        onStaffSelect={setNoteStaffSendList}
        isCreateNote={!noteSelected}
        note={noteSelected}
      />

      <AssignNewTaskModal
        open={isAssignNewTaskModal || isEditTask}
        isEditTask={isEditTask}
        isPreSelected={true}
        taskToEdit={taskToEdit}
        loading={loadingTask}
        patient={patient}
        staff={staff}
        showResponse={false}
        response={{
          responseIcon: null,
          responseMessage: '',
        }}
        title={title}
        description={description}
        staffList={staffList}
        onTitleChange={setTitle}
        onDescriptionChange={setDescription}
        onStaffSelect={setStaff}
        onClose={() => {
          setIsAssignNewTaskModal(false);
          setIsEditTask(false);
        }}
        onSave={onNewTaskSave}
        onPatientSelect={(): null => null}
        onChangeSearchQuery={(): string => ''}
      />
      <SuccessSnackBar open={snackBarOpen} setOpen={setSnackBarOpen} message={popupMessage} />
      <AlertDialog
        open={openWarningDialog}
        title={'Error'}
        message={popupMessage}
        onClose={() => setOpenWarningDialog(false)}
      />
    </Fragment>
  );
}

export default NotesAndTasks;
