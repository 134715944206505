import React, { ReactElement } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import moment, { Moment } from 'moment';
import { YEAR, MONTH, DAY } from 'lib/dateFormatter';

import styles from './CVCDatePickerStyles.module.scss';

interface CVCDatePickerProps {
  label?: string;
  value?: Moment;
  onChange?(value: Moment): void;
  disabled?: boolean;
  shouldShowError?: boolean;
}

const START_YEARS = moment().year() + 2;
const YEARS_COUNT = 120;
const YEARS = Array(YEARS_COUNT)
  .fill(0)
  .map((_, i) => START_YEARS - i + '');

function CVCDatePicker({
  label = '',
  value,
  onChange = value => null,
  disabled = false,
  shouldShowError = false,
}: CVCDatePickerProps): ReactElement {
  const changedValue = value || moment();
  const DAYS =
    value && value.isValid()
      ? Array(value.daysInMonth())
          .fill(0)
          .map((_, i) => i + 1 + '')
      : [];

  /*
   * Reduce default padding which is 30 on input fields and 10 on dropdowns.
   * Optimized to make date selector fit and work properly in fixed with `600px` container
   */
  const yearSelectorStyle = makeStyles({
    root: {
      '& .MuiAutocomplete-inputRoot': {
        paddingRight: 15,
        marginRight: 15,
      },
    },
    listbox: {
      boxSizing: 'border-box',
      '& li': {
        padding: 5,
      },
    },
  });
  const monthSelectorStyle = makeStyles({
    root: {
      flex: 1,
      '& .MuiAutocomplete-inputRoot': {
        paddingRight: 15,
        marginRight: 10,
      },
    },
    listbox: {
      boxSizing: 'border-box',
      '& li': {
        padding: 5,
      },
    },
  });
  const daySelectorStyle = makeStyles({
    root: {
      flex: 1,
      '& .MuiAutocomplete-inputRoot': {
        paddingRight: 10,
      },
    },
    listbox: {
      boxSizing: 'border-box',
      '& li': {
        padding: 5,
      },
    },
  });

  function handleChange(name, newValue): void {
    const year = name === YEAR ? newValue : changedValue.year();
    const month = name === MONTH ? newValue : changedValue.format('MMM');
    let day = name === DAY ? newValue : changedValue.date();

    const lastDayOfMonth = moment(`${year}-${month}-01`)
      .endOf('month')
      .date();

    day = lastDayOfMonth < day ? lastDayOfMonth : day;

    onChange(moment(`${month}-${day}-${year}`, 'MMM-DD-YYYY'));
  }

  function reformatMonthValue(value): string {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }

  function onInputChange(name, value): void {
    let makeChange = false;

    if (name === YEAR) {
      makeChange = YEARS.includes(value);
    } else if (name === MONTH) {
      makeChange = moment.monthsShort().includes(reformatMonthValue(value));
    } else if (name === DAY) {
      makeChange = DAYS.includes(value);
    }

    if (makeChange) handleChange(name, value);
  }

  return (
    <div className={styles.main}>
      <Autocomplete
        classes={yearSelectorStyle()}
        disabled={disabled}
        disableClearable
        fullWidth
        onChange={(e, newValue) => handleChange(YEAR, newValue)}
        onInputChange={(e, value) => onInputChange(YEAR, value)}
        value={value ? value.year() + '' : ''}
        renderInput={props => (
          <TextField
            {...props}
            name="Year"
            label={label}
            placeholder="Year"
            className={styles.inputLabel}
            InputLabelProps={{ shrink: true, style: { width: '100px' } }}
            error={shouldShowError}
          />
        )}
        options={YEARS}
        className={styles.field}
      />
      <Autocomplete
        classes={monthSelectorStyle()}
        disabled={disabled}
        fullWidth
        onChange={(e, newValue) => handleChange(MONTH, newValue)}
        onInputChange={(e, value) => onInputChange(MONTH, value)}
        disableClearable
        value={value ? value.format('MMM') : ''}
        renderInput={props => (
          <TextField
            {...props}
            label={label && 'Month'}
            placeholder={'Month'}
            InputLabelProps={{ shrink: true, style: { width: '100px', color: 'transparent' } }}
            required={true}
            error={shouldShowError}
          />
        )}
        options={moment.monthsShort()}
        className={styles.field}
      />
      <Autocomplete
        classes={daySelectorStyle()}
        disabled={disabled}
        fullWidth
        value={value ? value.date() + '' : ''}
        onChange={(e, newValue) => handleChange(DAY, newValue)}
        onInputChange={(e, value) => onInputChange(DAY, value)}
        disableClearable
        renderInput={props => (
          <TextField
            {...props}
            label={label && 'Day'}
            placeholder={'Day'}
            InputLabelProps={{ shrink: true, style: { width: '100px', color: 'transparent' } }}
            error={shouldShowError}
          />
        )}
        options={DAYS}
        className={styles.field}
      />
    </div>
  );
}

export default CVCDatePicker;
