import React, { Fragment, ReactElement, useContext, useState } from 'react';

import { EditableUser } from 'types';

import UserInfoModal from './UserInfoModal.view';
import EditProfileDialog from './EditProfileDialog.view';
import ResetPasswordDialog from './ResetPasswordDIalog.view';
import { Context as ProfileContext } from 'context/profile';
import { Context as AuthContext } from 'context/auth';

import useMyProfileUpdateApi from './useMyProfileUpdateApi.hook';

interface UserInfoModalContainerProps {
  open: boolean;
  onClose: () => void;
}

function UserInfoModalContainer({ open, onClose }: UserInfoModalContainerProps): ReactElement {
  const { logout } = useContext(AuthContext);
  const { user, setUser } = useContext(ProfileContext);
  const { loading, updateProfile } = useMyProfileUpdateApi(user?.id);
  const [isUserEdit, setIsUserEdit] = useState(false);
  const [isReset, setIsReset] = useState(false);

  async function onSave(data: EditableUser): Promise<void> {
    const newProfile = await updateProfile(data);
    setUser(newProfile);
    setIsUserEdit(false);
  }

  return (
    <Fragment>
      <UserInfoModal
        user={user || undefined}
        open={open}
        onClose={onClose}
        doLogout={logout}
        onEdit={() => setIsUserEdit(true)}
        onReset={() => setIsReset(true)}
      />
      <EditProfileDialog
        user={user}
        open={isUserEdit}
        onSave={onSave}
        onClose={() => setIsUserEdit(false)}
        loading={loading}
      />
      <ResetPasswordDialog
        user={user}
        open={isReset}
        loading={false}
        onClose={() => setIsReset(false)}
        onSave={() => setIsReset(false)}
      />
    </Fragment>
  );
}

export default UserInfoModalContainer;
