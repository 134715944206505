import React, { ReactElement } from 'react';

import styles from './TripleDotStyles.module.scss';

function TripleDot(): ReactElement {
  return (
    <div className={styles.main}>
      <div className={styles.dot} />
      <div className={styles.dot} />
      <div className={styles.dot} />
    </div>
  );
}

export default TripleDot;
