import React, { ReactElement, useState, useEffect } from 'react';
import TitledSection from 'components/TitledSection';
import FaxReceived from './FaxReceived.view';
import InputSearch from 'pages/Staff/components/InputSearch';
import { getReceivedFax, refreshInbound } from 'api/fax';
import { NewFaxDialogContainer } from '../NewFaxDialog';
import { SendFaxDialogContainer } from '../SendFaxDialog';
import styles from './FaxReceived.module.scss';
import PreviewAndAssign from '../PreviewAndAssign';
import { Checkbox } from '@material-ui/core';
import { FaxInbound } from 'types';
import DialogButton from 'components/DialogButton';
import { DialogButtonType } from 'components/DialogButton/DialogButton.view';
import SuccessSnackBar from 'components/SuccessSnackBar';
import * as logger from 'api/logger';

function FaxReceivedContainer(): ReactElement | null {
  const [openSendFax, setOpenSendFax] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [faxes, setFaxes] = useState<Array<FaxInbound>>([]);
  const [openPreviewAndAssign, setOpenPreviewAndAssign] = useState<boolean>(false);
  const [selectedFax, setSelectedFax] = useState<FaxInbound>();
  const [includeAssigned, setIncludeAssigned] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [successSnackBarMessage, setSuccessSnakbarMessage] = useState<string>('');
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const [includeDuplicates, setIncludeDuplicates] = useState<boolean>(false);

  async function fetchFaxes(): Promise<void> {
    setLoading(true);
    try {
      const faxes = await getReceivedFax(searchQuery, includeAssigned, includeDuplicates);
      setFaxes(faxes);
    } catch (e) {
      logger.error(e.message);
    }
    setLoading(false);
  }

  const onRefresh = async () => {
    setLoading(true);
    try {
      const faxes = await refreshInbound(searchQuery, includeAssigned);
      setFaxes(faxes);
    } catch (e) {
      logger.error(e.message);
    }
    setLoading(false);
  };

  const onSendFaxClick = (): void => {
    setOpenSendFax(true);
  };

  const onClickFax = (id): void => {
    setOpenPreviewAndAssign(true);
    setSelectedFax(faxes.find(singleFax => singleFax.id === id));
  };

  const getFaxes = () => {
    fetchFaxes();
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getFaxes();
    }, 300);

    return (): void => clearTimeout(delayDebounceFn);
  }, [searchQuery, includeAssigned, includeDuplicates]);

  const headContent = (
    <div className={styles.formListButtonContainer}>
      <div className={styles.checkbox}>
        <Checkbox
          name={'includeDuplicates'}
          checked={includeDuplicates}
          onChange={event => setIncludeDuplicates(event.target.checked)}
        />
        <div>{'Include Duplicates'}</div>
      </div>
      <div className={styles.checkbox}>
        <Checkbox
          name={'includeAssigned'}
          checked={includeAssigned}
          onChange={event => setIncludeAssigned(event.target.checked)}
        />
        <div>{'Include Assigned'}</div>
      </div>
      <div className={styles.button}>
        <InputSearch
          value={searchQuery}
          onChange={(event): void => setSearchQuery(event.target.value)}
        />
      </div>
      <div className={styles.refresh}>
        <DialogButton
          className={`${styles.marginLeft} ${styles.marginTop}`}
          buttonType={DialogButtonType.AffirmativeLink}
          onClick={onRefresh}
          loading={loading}
        >
          Refresh
        </DialogButton>
      </div>
    </div>
  );

  return (
    <div>
      <TitledSection headContent={headContent}>
        <FaxReceived faxes={faxes} onClickFax={onClickFax} />
        <SendFaxDialogContainer
          open={openSendFax}
          onClose={(): void => setOpenSendFax(false)}
          onSendFaxClick={onSendFaxClick}
        />
      </TitledSection>
      <PreviewAndAssign
        open={openPreviewAndAssign && !!selectedFax}
        fax={selectedFax}
        onClose={() => setOpenPreviewAndAssign(false)}
        onComplete={successMessage => {
          setSuccessSnakbarMessage(successMessage);
          setOpenSnackBar(true);
          getFaxes();
          setOpenPreviewAndAssign(false);
        }}
      />
      <SuccessSnackBar
        open={openSnackBar}
        message={successSnackBarMessage}
        setOpen={setOpenSnackBar}
      />
    </div>
  );
}

export default FaxReceivedContainer;
