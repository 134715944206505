import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { stylesPdf } from 'lib/workflowPdf';
import { Page, Text, View, Document, PDFDownloadLink, Image, PDFViewer } from '@react-pdf/renderer';
import Dialog from 'components/Dialog';
import useWorkflowPDF from 'pages/WorkflowResult/components/WorkflowPDF/useWorkflowPDF.hook';
import DialogButton from 'components/DialogButton';
import styles from './RequisitionResultsPDFStyles.module.scss';
import {
  PatientMetaLabels,
  parseText,
} from 'pages/WorkflowResult/components/PatientResults/lib/calculatePatientResults';
import { FollowUpResultPDFFragment } from 'pages/WorkflowResult/components/FollowUpResultPDFFragment';
import RequisitionResultPDFFragment from 'pages/WorkflowResult/components/RequisitionResultPDFFragment';
import useDocumentsApi from 'pages/Patient/components/Documents/useDocumentsApi.hook';
import { MIME_TYPE_PDF } from 'lib/document';
import { NewDocument } from 'types';
import formatDate, { DateTimeFormat } from 'lib/dateFormatter';

interface RequisitionResultsPDFProps {
  isOpen: boolean;
  onClose: () => void;
  caseId?: number;
  formId?: number;
  patientId: number;
  signatureUrl?: string;
}

export const RequisitionResultsPDF = ({
  isOpen,
  onClose,
  formId,
  caseId,
  patientId,
  signatureUrl,
}: RequisitionResultsPDFProps) => {
  const [pdfURL, setPDFURL] = useState('');
  const [generatedUrl, setGeneratedUrl] = useState('');
  const [urlLoading, setURLLoading] = useState(false);

  // Ensures the user only saves once
  const [savingInProgress, setSavingInProgress] = useState(false);
  const { createDocument } = useDocumentsApi(patientId);

  const RequisitionPDF = (
    <RequisitionResultPDFFragment
      formId={formId}
      caseId={caseId}
      patientId={patientId}
      signatureUrl={signatureUrl}
    />
  );

  const saveRequisitionToDocument = async (): Promise<void> => {
    const fileResult = await fetch(pdfURL);
    const blob = await fileResult.blob();

    const fileName = `Requisition Form - ${formatDate(
      DateTimeFormat.DateAndTimeWithoutMeridies12h,
      moment()
    )}.pdf`;
    const fileObject = new File([blob], fileName, { type: MIME_TYPE_PDF });

    const data: NewDocument = {
      name: fileName,
      typeId: 1, //TODO:  Verify --> Doc Name, File Name, Type,
      document: fileObject,
    };

    if (data.document) await createDocument(data);
  };

  async function onSave() {
    setSavingInProgress(true);
    await saveRequisitionToDocument();
    setSavingInProgress(false);
    onClose();
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!urlLoading && !!generatedUrl && !pdfURL) {
        setPDFURL(generatedUrl);
        setURLLoading(false);
      }
    }, 3000);

    return () => clearTimeout(delayDebounceFn);
  }, [generatedUrl]);

  return (
    <div>
      <Dialog open={isOpen} onClose={onClose} title={'Completed Requisition Form'}>
        <div className={styles.container}>
          <p className={styles.text}>
            The Requisition Document is being generated and will be available under the Patient
            Documents
          </p>
          <div className={styles.buttons}>
            <DialogButton disabled={!pdfURL || savingInProgress} onClick={onSave}>
              {!pdfURL || savingInProgress ? 'Loading...' : 'Ok'}
            </DialogButton>
          </div>
        </div>
      </Dialog>
      <PDFDownloadLink document={RequisitionPDF}>
        {({ blob, url, loading, error }) => {
          setURLLoading(loading);
          if (url) {
            setGeneratedUrl(url);
          }
          return <div></div>;
        }}
      </PDFDownloadLink>
    </div>
  );
};
