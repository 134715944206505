import React, { ReactElement } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { capitalize } from 'lodash';
import styles from './QuestionSectionStyles.module.scss';
import classNames from 'classnames';

interface RadioQuestionSectionProps {
  title?: string;
  description?: string;
  options?: Array<any>;
  optionExtractor?: (option) => string;
  value: Array<any>;
  defaultValue?: Array<any>;
  required?: boolean;
  onChange?(value): void;
  valueExtractor?: (option) => string;
  optionCapitalize?: (option) => string;
}

function defaultOptionExtractor(option): string {
  return option?.value ? option.value.toString() : '';
}

function RadioQuestionSection({
  title,
  description,
  options = [],
  value = [],
  defaultValue = [],
  required,
  onChange = () => null,
  optionExtractor,
  valueExtractor = defaultOptionExtractor,
  optionCapitalize,
}: RadioQuestionSectionProps): ReactElement {
  const currentOptionExtractor = optionExtractor || defaultOptionExtractor;
  const currentDefaultValue = defaultValue || [];

  function handleChange(event, value): void {
    onChange([
      {
        ...(currentDefaultValue[0]?.id && { id: currentDefaultValue[0]?.id }),
        value,
      },
    ]);
  }

  // We want to capture an input value empty string, as that means we want the answer to be empty and not use the incoming default value
  const hasValue = value && value[0] && (value[0]?.value || value[0]?.value === '');
  const defaultValueUse = defaultValue && defaultValue[0] && defaultValue[0]?.value;
  const selectedValue = hasValue ? value[0]?.value : defaultValueUse;

  return (
    <div className={styles.main}>
      <div className={styles.title}>
        {title} {required ? <span className={styles.reqMarker}>*</span> : null}
      </div>
      <div className={classNames(styles.title, styles.statement)}>{description}</div>
      <div className={styles.answerContainer}>
        <div className={styles.answer}>
          <FormControl component="fieldset">
            <RadioGroup
              name="radioQuestions"
              defaultValue={valueExtractor(currentDefaultValue[0])}
              onChange={(event, value) => {
                handleChange(event, value);
              }}
            >
              {options.map(option => (
                <FormControlLabel
                  checked={currentOptionExtractor(option) === selectedValue}
                  key={currentOptionExtractor(option)}
                  value={currentOptionExtractor(option)}
                  control={<Radio />}
                  label={
                    optionCapitalize
                      ? optionCapitalize(currentOptionExtractor(option))
                      : capitalize(currentOptionExtractor(option))
                  }
                />
              ))}
            </RadioGroup>
          </FormControl>
        </div>
      </div>
    </div>
  );
}

export default RadioQuestionSection;
