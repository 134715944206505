import React, { ReactElement } from 'react';
import { EditLocationDialogContainer } from '../EditLocationDialog';

interface NewLocationDialogContainer {
  open?: boolean;
  onClose: () => void;
  onSaveSuccess: () => void;
  dialogType?: string;
  locationToEditId?: string;
}

function NewLocationDialogContainer({
  open,
  onClose,
  dialogType = 'new',
  locationToEditId,
  onSaveSuccess,
}: NewLocationDialogContainer): ReactElement {
  return (
    <EditLocationDialogContainer
      locationId={locationToEditId}
      open={open}
      onClose={onClose}
      onSaveSuccess={onSaveSuccess}
      dialogType={dialogType}
    />
  );
}

export default NewLocationDialogContainer;
