import validator, {
  ValidationRule,
  emailValidationRule,
  faxNumberValidationRule,
} from 'lib/validator';

const SendDocumentDialog: Array<ValidationRule> = [
  {
    name: 'email',
    rule: emailValidationRule,
    message: 'Please type a valid email',
  },
  {
    name: 'fax',
    rule: faxNumberValidationRule,
    message: 'Please type a valid fax',
  },
];

export default validator(SendDocumentDialog);
