import React, { ReactElement, useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';

import Dialog from 'components/Dialog';
import DialogButton from 'components/DialogButton';

import CVCDatePicker from 'components/CVCDatePicker';

import { FormApi } from 'api';
import { APIDATEFORMAT } from 'lib/config';
import * as logger from 'api/logger';

import styles from './BirthDateCheckDialogStyles.module.scss';

interface BirthDateCheckDialogProps {
  formId: number;
  token: any;
  hasBirthdateBeenAnswered: boolean;
  onBirthDateCheck: (birthDateExists: boolean) => void;
}

function BirthDateCheckDialog({
  formId,
  token,
  hasBirthdateBeenAnswered,
  onBirthDateCheck,
}: BirthDateCheckDialogProps): ReactElement {
  const [birthDateValue, setBirthdateValue] = useState(moment());
  const [failedBirthDateCheck, setFailedBirthDateCheck] = useState(false);

  function handleDateChange(value): void {
    setBirthdateValue(value);
  }

  const onSubmitDate = async () => {
    try {
      const checkDateResult = await FormApi.checkBirthDateForPatient(
        formId,
        moment(birthDateValue).format(APIDATEFORMAT),
        token
      );

      if (checkDateResult?.isBirthDate) {
        onBirthDateCheck(true);
        setFailedBirthDateCheck(false);
      } else {
        onBirthDateCheck(false);
        setFailedBirthDateCheck(true);
      }
    } catch (e) {
      logger.error(e.message);
    }
  };

  return (
    <Dialog open={!hasBirthdateBeenAnswered} title="Please Enter Your Birth Date">
      <div className={classNames(styles.dialogBody, styles.withPadding)}>
        <CVCDatePicker onChange={handleDateChange} value={moment(birthDateValue)} />
        <DialogButton className={styles.dialogButton} onClick={onSubmitDate}>
          Submit
        </DialogButton>
        {failedBirthDateCheck && (
          <div className={styles.validationError}>
            You did not enter the birth date we have on file.
          </div>
        )}
      </div>
    </Dialog>
  );
}

export default BirthDateCheckDialog;
