import React, { ReactElement } from 'react';
import Layout from 'Layout';

import PDFAnnotator from 'components/PDFAnnotator';
import Dialog from 'components/Dialog';
import DialogButton from 'components/DialogButton';

import styles from './DocumentEditStyles.module.scss';

interface DocumentEditorProps {
  url: string;
  onCancel(): void;
  onSave(blob): void;
  setSaving(state): void;
  saving: boolean;
}

function DocumentEditView({ saving, ...props }: DocumentEditorProps): ReactElement {
  return (
    <Layout hideFooter>
      <PDFAnnotator {...props}></PDFAnnotator>
      <Dialog open={saving} title={'Saving Document'}>
        <div className={styles.container}>
          <p className={styles.text}>The Document is being saved</p>
          <div className={styles.buttons}>
            <DialogButton disabled={saving}>{saving ? 'Saving...' : 'Complete'}</DialogButton>
          </div>
        </div>
      </Dialog>
    </Layout>
  );
}

export default DocumentEditView;
