import React, { ReactElement, useEffect, useState } from 'react';
import { Checkbox } from '@material-ui/core';
import Dialog from '../Dialog';
import styles from './ValidateHealthcardDialogStyles.module.scss';
import DialogButton from '../DialogButton';
import { DialogButtonType } from 'components/DialogButton/DialogButton.view';
import moment, { Moment } from 'moment';
import { validateCard } from 'api/healthCardValidation';
import { HealthCardUpdate } from 'types';
import { AlertDialog } from 'components/AlertDialog';
import formatDate, { DateTimeFormat } from 'lib/dateFormatter';
import { handleAPIErrors } from 'lib/handleAPIErrors';
import _ from 'lodash';
import * as logger from 'api/logger';

interface ValidateHealthcardDialogProps {
  open: boolean;
  onClose: () => void;
  onSaveSuccess: (healthcardInfo: HealthCardUpdate) => void;
  healthcardNumber: string;
  healthcardCode: string;
  oldFirstName: string;
  oldLastName: string;
  oldDateOfBirth: Moment;
  oldGender: string;
  includeFirstName?: boolean;
  includeLastName?: boolean;
  includeDateOfBirth?: boolean;
  includeGender?: boolean;
}

function ValidateHealthcardDialog({
  open,
  onClose,
  onSaveSuccess,
  healthcardNumber,
  healthcardCode,
  oldFirstName,
  oldLastName,
  oldDateOfBirth,
  oldGender,
  includeFirstName = false,
  includeLastName = false,
  includeDateOfBirth = false,
  includeGender = false,
}: ValidateHealthcardDialogProps): ReactElement {
  const [hasValidated, setHasValidated] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>('');
  const [updateFirstName, setUpdateFirstName] = useState<boolean>(false);
  const [lastName, setLastName] = useState<string>('');
  const [updateLastName, setUpdateLastName] = useState<boolean>(false);
  const [dateOfBirth, setDateOfBirth] = useState<Moment | undefined>(undefined);
  const [updateDateOfBirth, setUpdateDateOfBirth] = useState<boolean>(false);
  const [gender, setGender] = useState<string>('');
  const [updateGender, setUpdateGender] = useState<boolean>(false);
  const [healthcardStatus, setHealthcardStatus] = useState<string>('');
  const [isValid, setIsValid] = useState<boolean>(false);
  const [popupMessage, setPopupMessage] = useState<string>('');

  const formatName = name => {
    if (!name) return '';
    return _.capitalize(name);
  };

  useEffect(() => {
    // Validate the healthcard info on load
    const healthcardValidation = async () => {
      if (!healthcardNumber || !healthcardCode) {
        setIsValid(false);
        setHasValidated(true);
      } else {
        try {
          const result = await validateCard(healthcardNumber, healthcardCode);
          const cardValidation = result.results[0];
          setIsValid(!!cardValidation);
          setHasValidated(true);
          if (cardValidation) {
            const newFirstName = formatName(cardValidation.firstName);
            const newLastName = formatName(cardValidation.lastName);
            let newGender = '';
            if (cardValidation.gender) {
              const gender = cardValidation.gender.toLowerCase();
              if (gender === 'm') {
                newGender = 'Male';
              } else if (gender === 'f') {
                newGender = 'Female';
              }
            }
            setFirstName(newFirstName.trim());
            setLastName(newLastName.trim());
            setGender(newGender.trim());

            setDateOfBirth(cardValidation.dateOfBirth);
            setHealthcardStatus(cardValidation.responseDescription.trim());
          }
        } catch (err) {
          logger.error(err.message);
          setIsValid(false);
          setHasValidated(true);
          setPopupMessage(handleAPIErrors(err, 'Error Validating Health Card'));
        }
      }
    };
    if (open) {
      healthcardValidation();
    }
  }, [open]);

  const reset = () => {
    setFirstName('');
    setLastName('');
    setGender('');
    setPopupMessage('');
    setDateOfBirth(undefined);
    setIsValid(false);
    setUpdateFirstName(false);
    setUpdateLastName(false);
    setUpdateDateOfBirth(false);
    setUpdateGender(false);
    setHasValidated(false);
  };

  const onSave = async () => {
    try {
      const healthcardInfo = {
        firstName: updateFirstName ? firstName : oldFirstName,
        lastName: updateLastName ? lastName : oldLastName,
        dateOfBirth: updateDateOfBirth ? dateOfBirth : oldDateOfBirth,
        gender: updateGender ? gender : oldGender,
      };
      reset();
      onSaveSuccess(healthcardInfo);
    } catch (err) {
      logger.error(err.message);
    }
  };

  const localOnClose = () => {
    reset();
    onClose();
  };

  const areValuesSame = (oldValue, newValue) => {
    return oldValue.trim() === newValue.trim();
  };

  const noInfoDiv = field => {
    return <div className={styles.formFieldContainer}>{`${field} Not Provided`}</div>;
  };

  const getHealthCardInfoDiv = () => {
    return (
      <div className={styles.container}>
        <div>
          <form className={styles.formContainer}>
            <div className={styles.formColumn}>
              <div className={styles.formFieldContainer}>
                <p className={styles.formStatusLabel}>
                  Healthcard Status:{' '}
                  <span className={styles.formStatus}>{`"${healthcardStatus}"`}</span>
                </p>
              </div>
              {includeFirstName &&
                (firstName ? (
                  <div className={styles.formFieldContainer}>
                    <p className={styles.formPIILabel}>{'Update First Name'}</p>
                    <Checkbox
                      name={'updateFirstName'}
                      checked={updateFirstName}
                      onChange={() => setUpdateFirstName(!updateFirstName)}
                      className={styles.checkbox}
                      disabled={areValuesSame(oldFirstName, firstName)}
                    />
                    <p className={styles.formPII}>{`${oldFirstName} => ${firstName}`}</p>
                  </div>
                ) : (
                  <>{noInfoDiv('First Name')}</>
                ))}
              {includeLastName &&
                (lastName ? (
                  <div className={styles.formFieldContainer}>
                    <p className={styles.formPIILabel}>{'Update Last Name'}</p>
                    <Checkbox
                      name={'updatelastName'}
                      checked={updateLastName}
                      onChange={() => setUpdateLastName(!updateLastName)}
                      className={styles.checkbox}
                      disabled={areValuesSame(oldLastName, lastName)}
                    />
                    <p className={styles.formPII}>{`${oldLastName} => ${lastName}`}</p>
                  </div>
                ) : (
                  <>{noInfoDiv('Last Name')}</>
                ))}
              {includeGender &&
                (gender ? (
                  <div className={styles.formFieldContainer}>
                    <p className={styles.formPIILabel}>{'Update Gender'}</p>
                    <Checkbox
                      name={'updateGender'}
                      checked={updateGender}
                      onChange={() => setUpdateGender(!updateGender)}
                      className={styles.checkbox}
                      disabled={areValuesSame(oldGender, gender)}
                    />
                    <p className={styles.formPII}>{`${oldGender} => ${gender}`}</p>
                  </div>
                ) : (
                  <>{noInfoDiv('Gender')}</>
                ))}
              {includeDateOfBirth &&
                (dateOfBirth ? (
                  <div className={styles.formFieldContainer}>
                    <p className={styles.formPIILabel}>{'Update Date of Birth'}</p>
                    <Checkbox
                      name={'updateDateOfBirth'}
                      checked={updateDateOfBirth}
                      onChange={() => setUpdateDateOfBirth(!updateDateOfBirth)}
                      className={styles.checkbox}
                      disabled={areValuesSame(
                        oldDateOfBirth
                          ? formatDate(DateTimeFormat.MonthDayYear, moment(oldDateOfBirth))
                          : '',
                        formatDate(DateTimeFormat.MonthDayYear, moment(dateOfBirth))
                      )}
                    />
                    <p className={styles.formPII}>
                      {oldDateOfBirth
                        ? formatDate(DateTimeFormat.MonthDayYear, moment(oldDateOfBirth))
                        : ''}
                      {` => ${formatDate(DateTimeFormat.MonthDayYear, moment(dateOfBirth))}`}
                    </p>
                  </div>
                ) : (
                  <>{noInfoDiv('Date Of Birth')}</>
                ))}
            </div>
          </form>
          <div className={styles.buttonSection}>
            <DialogButton buttonType={DialogButtonType.NegationLink} onClick={localOnClose}>
              Cancel
            </DialogButton>
            <DialogButton
              buttonType={DialogButtonType.AffirmativeLink}
              className={styles.btn}
              onClick={onSave}
            >
              {'Update'}
            </DialogButton>
          </div>
        </div>
      </div>
    );
  };

  const getHealthCardInvalidDiv = message => {
    return (
      <div className={styles.container}>
        <p>
          {message
            ? message
            : 'Issue Validating Healthcard. Please Double Check the Card Number and Code or Try Again Later.'}
        </p>
      </div>
    );
  };

  const getValidatingHealthCardDiv = () => {
    return (
      <div className={styles.container}>
        <p>Validating Healthcard...</p>
      </div>
    );
  };

  return (
    <Dialog open={open} title={'Validate Healthcard'} onClose={localOnClose}>
      {hasValidated
        ? isValid
          ? getHealthCardInfoDiv()
          : getHealthCardInvalidDiv(popupMessage)
        : getValidatingHealthCardDiv()}
    </Dialog>
  );
}

export default ValidateHealthcardDialog;
