import React, { ReactElement, useEffect, useState } from 'react';

import Paper from 'components/Paper';
import TripleDot from 'components/TripleDot';
import ConditionDiv from 'components/ConditionDiv';

import styles from './FormStyles.module.scss';
import classNames from 'classnames';

interface FormProp {
  id?: string | number;
  key: string;
  className?: string;
  title?: string;
  info?: string;
  statusId?: number | string;
  createdAt?: string;
  completedAt?: string;
  onClick?: () => void;
  duplicateForm: (id) => void;
  showDeleteOption?: boolean;
  onClickDeleteForm: (id: string | number | undefined) => void;
}

function Form({
  id,
  key,
  title,
  info,
  statusId,
  className,
  createdAt,
  completedAt,
  onClick,
  duplicateForm,
  onClickDeleteForm,
  showDeleteOption,
}: FormProp): ReactElement {
  const [displaySettings, setDisplaySettings] = useState<boolean>(false);

  const copyDocument = event => {
    event.stopPropagation();
    duplicateForm(id);
    setDisplaySettings(false);
  };

  const optionDeleteSelected = event => {
    event.stopPropagation();
    if (id) {
      onClickDeleteForm(id);
    }
    setDisplaySettings(false);
  };

  const menuSelect = event => {
    event.stopPropagation();
    setDisplaySettings(true);
  };

  const setStatusKey = () => {
    if (statusId == 3) return 'complete';
    if (statusId == 4) return 'sentToPatient';
    return '';
  };
  return (
    <Paper className={className} onClick={onClick}>
      <div className={styles.main}>
        <div className={styles.description}>
          <div className={styles.title}>{title}</div>
          <div className={classNames(styles.statusDate, styles[setStatusKey()])}>{info}</div>
          <div className={styles.statusDate}>Created: {createdAt}</div>
          <ConditionDiv condition={!!completedAt}>
            <div className={styles.statusDate}>Completed: {completedAt}</div>
          </ConditionDiv>
        </div>

        <div className={styles.dots} onClick={menuSelect}>
          <div className={styles.dropdown} id={key}>
            {displaySettings && (
              <div className={styles.dropdownContent}>
                <a onClick={copyDocument} id={'create'}>
                  New Form
                </a>
                {showDeleteOption && (
                  <a onClick={optionDeleteSelected} id={'delete'}>
                    Delete Form
                  </a>
                )}
              </div>
            )}
          </div>
          <div>
            <TripleDot />
          </div>
        </div>
      </div>
    </Paper>
  );
}

export default Form;
