import moment from 'moment';

import { Holidays } from './holidays';

export class Ontario extends Holidays {
  private observedHolidays: moment.Moment[] = [];
  private yearsInitialized: { [index: number]: boolean } = {};

  //December 26
  private boxingDay(year: number): moment.Moment {
    return moment(`${year}-12-26`);
  }

  //second Monday in October
  private thanksgiving(year: number): moment.Moment {
    return this.second(year, '10', 'Monday');
  }

  //Monday before May 25
  private victoriaDay(year: number): moment.Moment {
    const firstMondayBeforeMay25th = moment(`${year}-05-25`)
      .subtract(1, 'days') //in case in falls on a sunday
      .startOf('week')
      .add(1, 'days');
    return firstMondayBeforeMay25th;
  }

  public getobservedHolidays(year: number): moment.Moment[] {
    this.initializeHolidaysForYear(year);
    return this.observedHolidays;
  }

  public isDateIsHoliday(date: moment.Moment): boolean {
    this.initializeHolidaysForYear(date.year());
    return this.observedHolidays.some((d: moment.Moment) => d.isSame(date, 'day'));
  }

  private initializeHolidaysForYear(year: number): void {
    if (this.yearsInitialized[year]) return;
    this.yearsInitialized[year] = true;
    this.observedHolidays.push(this.newYear(year)); //fed
    this.observedHolidays.push(this.familyDay(year));
    this.observedHolidays.push(this.goodFriday(year));
    this.observedHolidays.push(this.victoriaDay(year));
    this.observedHolidays.push(this.canadaDay(year)); //fed
    this.observedHolidays.push(this.labourDay(year));
    this.observedHolidays.push(this.thanksgiving(year)); //fed
    this.observedHolidays.push(this.christmasDay(year)); //fed
    this.observedHolidays.push(this.boxingDay(year)); //fed
  }
}

export default Ontario;
