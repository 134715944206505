import validator, { DynamicData, ValidationRule } from 'lib/validator';

const LoginValidator: Array<ValidationRule> = [
  {
    name: 'email',
    rule: (data: DynamicData, name: string): boolean => !!data[name],
    message: 'Please insert login',
  },
  {
    name: 'password',
    rule: (data: DynamicData, name: string): boolean => !!data[name],
    message: 'Please insert password',
  },
];

export default validator(LoginValidator);
