import React, { ReactElement } from 'react';
import classNames from 'classnames';

import styles from './RowRecordStyles.module.scss';

interface RowRecordProp {
  name: string;
  value: string;
  className?: string;
}

function RowRecord({ name, value, className }: RowRecordProp): ReactElement {
  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.name}>{name}:</div>
      <div className={styles.value}>{value}</div>
    </div>
  );
}

export default RowRecord;
