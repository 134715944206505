import React, { ReactElement } from 'react';
import { Checkbox as MuiCheckbox, withStyles, Theme } from '@material-ui/core';

import styles from './CheckboxStyles.module.scss';

const StyledMuiCheckbox = withStyles((theme: Theme) => ({
  root: {
    maxHeight: '24px',
    maxWidth: '24px',
    padding: 0,

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}))(MuiCheckbox);

interface CheckboxProps {
  title: string;
  [rest: string]: any;
}

function Checkbox({ title, ...rest }: CheckboxProps): ReactElement {
  return (
    <div className={styles.container}>
      <StyledMuiCheckbox {...rest} />
      <div className={styles.checkBoxText}>
        <b>{title}</b>
      </div>
    </div>
  );
}

export default Checkbox;
