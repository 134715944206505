import React, { ReactElement, useEffect } from 'react';
import classNames from 'classnames';
import { useFormik } from 'formik';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import Dialog from 'components/Dialog';
import DialogButton from 'components/DialogButton';
import Autocomplete from 'components/Autocomplete';
import { Staff as UserStaff } from 'api/user/user.interfaces';
import { Note } from 'types';
import styles from './CreateNewNoteModalStyles.module.scss';
import { User } from 'types';
import createNoteValidator from './CreateNoteValidator.validator';
import * as logger from 'api/logger';

interface CreateNewNoteModalProps {
  open: boolean;
  loading?: boolean;
  patientName?: string;
  staffSendList: Array<User>;
  onStaffSelect: (value) => void;
  staffList?: Array<UserStaff>;
  onClose: () => void;
  onSave: (data) => void;
  isCreateNote: boolean;
  note: Note | null;
}

function CreateNewNoteModal({
  open,
  loading,
  staffSendList,
  onStaffSelect,
  staffList,
  onClose,
  onSave,
  isCreateNote,
  note,
}: CreateNewNoteModalProps): ReactElement {
  const validate = values => {
    const errors: any = {};
    if (!values.title) errors.title = 'Create title';
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: isCreateNote ? '' : note?.title,
      contents: isCreateNote ? '' : note?.contents,
    },
    onSubmit: async values => {
      try {
        onSave(values);
      } catch (e) {
        logger.error(e.message);
      }
    },
  });

  useEffect(() => {
    if (!open) {
      formik.resetForm();
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} title={isCreateNote ? 'New Note' : 'Edit Note'}>
      <form className={styles.container}>
        <Grid container className={styles.gridContainer}>
          <TextField
            label="Note name"
            name="title"
            value={formik.values.title}
            inputProps={{ maxLength: 50 }}
            onChange={formik.handleChange}
            fullWidth
          />
          <TextField
            label="Note"
            className={styles.topPadding}
            name="contents"
            value={formik.values.contents}
            multiline={true}
            onChange={formik.handleChange}
            fullWidth
          />

          <div className={classNames(styles.row, styles.topPadding)}>
            <div className={styles.staffInput}>
              <Autocomplete
                label="Send To Staff (Optional)"
                name="staffList"
                multiple={true}
                items={staffList}
                value={staffSendList}
                getOptionLabel={item => `${item.name}`}
                onChange={(event, newValue) => onStaffSelect(newValue)}
                fullWidth
              />
            </div>
          </div>

          <div className={styles.buttons}>
            <DialogButton onClick={onClose}>Cancel</DialogButton>
            <DialogButton
              className={styles.marginLeft}
              loading={loading}
              onClick={formik.handleSubmit}
            >
              {staffSendList.length ? 'Save & Send' : 'Save'}
            </DialogButton>
          </div>
        </Grid>
      </form>
    </Dialog>
  );
}

export default CreateNewNoteModal;
