import moment from 'moment';

import { Holidays } from './holidays';

export class NewBrunswick extends Holidays {
  private observedHolidays: moment.Moment[] = [];
  private yearsInitialized: { [index: number]: boolean } = {};

  //November 11th
  private rememberanceDay(year: number): moment.Moment {
    return moment(`${year}-11-11`);
  }

  //first Monday in August
  protected civicHoliday(year: number): moment.Moment {
    return this.first(year, '8', 'Monday');
  }

  public getobservedHolidays(year: number): moment.Moment[] {
    this.initializeHolidaysForYear(year);
    return this.observedHolidays;
  }

  public isDateIsHoliday(date: moment.Moment): boolean {
    this.initializeHolidaysForYear(date.year());
    return this.observedHolidays.some((d: moment.Moment) => d.isSame(date, 'day'));
  }

  private initializeHolidaysForYear(year: number): void {
    if (this.yearsInitialized[year]) return;
    this.yearsInitialized[year] = true;
    this.observedHolidays.push(this.newYear(year)); //fed
    this.observedHolidays.push(this.goodFriday(year));
    this.observedHolidays.push(this.canadaDay(year)); //fed
    this.observedHolidays.push(this.civicHoliday(year)); //new bruinswick day
    this.observedHolidays.push(this.labourDay(year));
    this.observedHolidays.push(this.rememberanceDay(year));
    this.observedHolidays.push(this.christmasDay(year)); //fed
    this.observedHolidays.push(this.familyDay(year));
  }
}

export default NewBrunswick;
