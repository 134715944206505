import { DocumentServerObj } from './document.interfaces';
import { Document } from 'types';

export function transformOneDocument(serverDoc: DocumentServerObj): Document {
  return {
    id: serverDoc.id,
    createdBy: serverDoc.created_by,
    title: serverDoc.title,
    uploadedAt: serverDoc.uploaded_at,
    url: serverDoc.s3_url,
    documentType: serverDoc.mime_type,
    fileType: serverDoc.mime_type,
    type: serverDoc.type,
    folderName: serverDoc.folder_name,
    flagged: !!serverDoc.flagged,
    needsStamp: serverDoc.needsStamp,
  };
}

export function formGetDocumentsResponse(data: string): Array<Document> {
  return JSON.parse(data).map(transformOneDocument);
}

export function formGetDocumentResponse(data: string): Document {
  const parsedData = JSON.parse(data);
  return transformOneDocument(parsedData);
}
