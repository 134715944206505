import React, { ReactElement } from 'react';
import classNames from 'classnames';

import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';

import ConditionDiv from '../ConditionDiv';

import styles from './DialogButtonStyles.module.scss';

export enum DialogButtonType {
  Bordered = 'bordered',
  NegationLink = 'negationLink',
  AffirmativeLink = 'affirmativeLink',
}

interface DialogButtonProps {
  buttonType?: DialogButtonType;
  classMain?: string;
  classCondition?: string;
  classLink?: string;
  loading?: boolean;
  disabled?: boolean;
  active?: boolean;
  children: any;
  [rest: string]: any;
}

function DialogButton({
  buttonType,
  classMain,
  classLink,
  classCondition,
  children,
  loading,
  disabled,
  active,
  onClick,
  ...rest
}: DialogButtonProps): ReactElement {
  return (
    <div
      className={classNames(
        buttonType && styles[buttonType],
        styles.main,
        active && styles.active,
        disabled && styles.disabled
      )}
      onClick={onClick}
    >
      <ConditionDiv condition={loading || disabled}>{children}</ConditionDiv>
      <ConditionDiv condition={!loading && !disabled} className={classCondition}>
        <Link {...rest} className={classNames(classLink)}>
          {children}
        </Link>
      </ConditionDiv>
      <ConditionDiv condition={loading} className={classCondition}>
        <CircularProgress size={16} className={styles.progress} thickness={6} />
      </ConditionDiv>
    </div>
  );
}

export default DialogButton;
