import React, { ReactElement, useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';

import { ConfirmCompleteDocument, QuestionForm } from 'types';

import { getForm, submitFormDocument, confirmCompleteDocument } from 'api/form';
import { convertEditableMimeTypeToFileExtension, MIME_TYPE_PDF } from 'lib/document';
import { DateTimeFormatString } from 'lib/dateFormatter';

import moment from 'moment';

import InfoModal from './components/InfoModal';

import FormDocumentView from '../FormDocument/FormDocument.view';
import BirthDateCheckDialog from './components/BirthDateCheckDialog.container';
import { FormStatusId } from 'lib/form';

import sleep from 'lib/sleep';
import { AlertDialog } from 'components/AlertDialog';
import { handleAPIErrors } from 'lib/handleAPIErrors';
import ConfirmDocumentDialog from 'pages/FormDocument/components/ConfirmDocumentDialog';
import * as logger from 'api/logger';

//eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImVAZS5jIiwib25seUZvcm0iOjR9.Jvk4Iw8d8UNI6VD5pmZQUJeqxpVvIB8_aXyjKW2Ost8

interface TokenBody {
  id: number;
  email: string;
  onlyForm: number;
  firstName: string;
  lastName: string;
}

function PatientFormDocumentContainer(props): ReactElement {
  const [form, setForm] = useState<QuestionForm | null>(null);
  const [userId, setUserId] = useState(-1);
  const [formId, setFormId] = useState(-1);
  const [hasBirthdateBeenAnswered, setHasBirthdateBeenAnswered] = useState(false);
  const [saving, setSaving] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [newDocument, setNewDocument] = useState<ConfirmCompleteDocument>();

  const token = props?.match?.params?.jwtToken;
  const [open, setOpen] = useState(false);
  const [formAlreadyCompleted, setFormAlreadyCompleted] = useState(false);

  useEffect(() => {
    if (token) {
      try {
        const tokenBody: TokenBody = jwtDecode(token);
        setUserId(tokenBody.id);
        setFormId(tokenBody.onlyForm);
        getForm(tokenBody.onlyForm, token).then(setForm);
        setFirstName(tokenBody.firstName);
        setLastName(tokenBody.lastName);
      } catch (e) {
        console.log('ERROR');
        props?.history?.push('/404');
      }
    }
  }, [hasBirthdateBeenAnswered]);

  useEffect(() => {
    if (form?.status_id === FormStatusId.Complete) {
      setFormAlreadyCompleted(true);
    }
  }, [form]);

  function onClose() {
    setOpen(false);
    const win = window.open('https://www.google.com/', '_self');
    if (win) {
      win.location.assign('https://www.canadaveinclinics.ca/');
      win.focus();
      return false;
    }
  }

  const onBirthDateCheck = (birthDateExists: boolean) => {
    setHasBirthdateBeenAnswered(birthDateExists);
  };

  const onSave = async blob => {
    setSaving(true);
    if (!form) {
      setSaving(false);
      alert('SAVE ERROR NO DOCUMENT LOADED');
      return;
    }

    // We only support PDFS
    const fileExtenstion = convertEditableMimeTypeToFileExtension('PDF');
    if (!fileExtenstion) {
      setSaving(false);
      alert('SAVE ERROR - Please Reload Page');
      return;
    }

    const curDate = moment().format(DateTimeFormatString.APIDateFormat);
    const documentOut = {
      document: blob,
      typeId: 1, // Overwritten by server, defaults to 1
      name: `Completed-${form.description}`,
      mimeType: MIME_TYPE_PDF,
      fileName: `Completed-${form.description}.${fileExtenstion}`,
      replacedDocumentId: null,
    };

    try {
      const documentDetails = await submitFormDocument(formId, documentOut, token);
      setNewDocument(documentDetails);
    } catch (e) {
      logger.error(e.message);
      const message = handleAPIErrors(e, 'Error Submitting Document, Please Try Again');
      setAlertMessage(message);
      setOpenAlert(true);
    }
    setSaving(false);
  };

  const confirmDocument = async () => {
    if (!newDocument) return;
    await confirmCompleteDocument(newDocument?.documentId, token);
    setNewDocument(undefined);
    setOpen(true);
  };

  return (
    <>
      <BirthDateCheckDialog
        formId={formId}
        token={token}
        hasBirthdateBeenAnswered={hasBirthdateBeenAnswered}
        onBirthDateCheck={onBirthDateCheck}
      />
      <AlertDialog
        open={openAlert}
        title="Error"
        message={alertMessage}
        onClose={() => setOpenAlert(false)}
      />

      {hasBirthdateBeenAnswered && !formAlreadyCompleted && (
        <FormDocumentView
          onCancel={() => props?.history?.push('/')}
          onSave={onSave}
          url={form?.document_url || ''}
          setSaving={setSaving}
          saving={saving}
          sendToPatient={false}
        />
      )}
      <InfoModal open={open} onClose={onClose} text={'Thank you for completing this form.'} />
      {hasBirthdateBeenAnswered && (
        <InfoModal
          open={formAlreadyCompleted}
          onClose={onClose}
          text={'This form has already been completed thank you.'}
        />
      )}
      <ConfirmDocumentDialog
        open={!!newDocument}
        pdfUrl={newDocument?.signedRequest}
        onClose={() => setNewDocument(undefined)}
        onSave={confirmDocument}
        saveTitle={'Complete'}
      />
    </>
  );
}

export default PatientFormDocumentContainer;
