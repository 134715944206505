// import React, { ReactElement } from 'react';
// import classNames from 'classnames';
//
// import styles from './QuestionSectionStyles.module.scss';
// import Checkbox from 'components/Checkbox';
// import CVCButton from 'components/CVCButton';
//
// function defaultOptionExtractor(option): string {
//   return option?.value;
// }
//
// interface BooleanQuestionSectionProps {
//   title?: string;
//   answers: Array<any>;
//   answerExtractor?: (option) => boolean;
// }
//
// function BooleanQuestionSection({
//   title,
//   answerExtractor,
//   answers = [],
// }: BooleanQuestionSectionProps): ReactElement {
//   const currentAnswerExtractor = answerExtractor || defaultOptionExtractor;
//   const noAnswer = currentAnswerExtractor(answers[0]) === 'no' || false;
//
//   return (
//     <div className={styles.main}>
//       <div className={styles.title}>{title}</div>
//       <div className={styles.answerContainer}>
//         <div className={classNames(styles.answer, styles.booleanAnswer)}>
//           <CVCButton className={classNames(styles.button, noAnswer && styles.notActive)}>
//             YES
//           </CVCButton>
//           <CVCButton className={classNames(styles.button, !noAnswer && styles.notActive)}>
//             NO
//           </CVCButton>
//         </div>
//       </div>
//     </div>
//   );
// }
//
// export default BooleanQuestionSection;

import React, { ReactElement } from 'react';
import classNames from 'classnames';

import styles from './QuestionSectionStyles.module.scss';
import CVCButton from 'components/CVCButton';

function defaultOptionExtractor(option): string {
  return option?.value;
}

interface BooleanQuestionSectionProps {
  title?: string;
  description?: string;
  value: Array<any>;
  defaultValue?: Array<any>;
  required?: boolean;
  onChange?(value): void;
  valueExtractor?: (option) => boolean;
}

function BooleanQuestionSection({
  title,
  description,
  valueExtractor,
  value,
  defaultValue = [],
  required,
  onChange = () => null,
}: BooleanQuestionSectionProps): ReactElement {
  const currentDefaultValue = defaultValue || [];
  const currentValue =
    value && value.length > 0
      ? value[0]
      : currentDefaultValue && currentDefaultValue.length > 0
      ? currentDefaultValue[0]
      : {};
  const currentAnswerExtractor = valueExtractor || defaultOptionExtractor;
  const noAnswer = currentAnswerExtractor(currentValue) === 'no' || false;
  const yesAnswer = currentAnswerExtractor(currentValue) === 'yes' || false;

  function onClick(value): void {
    onChange([
      {
        value,
      },
    ]);
  }

  return (
    <div className={styles.main}>
      <div className={styles.title}>
        {title} {required ? <span className={styles.reqMarker}>*</span> : null}
      </div>
      <div className={classNames(styles.title, styles.statement)}>{description}</div>
      <div className={styles.answerContainer}>
        <div className={classNames(styles.answer, styles.booleanAnswer)}>
          <CVCButton
            className={classNames(styles.button, !yesAnswer && styles.notActive)}
            onClick={() => onClick('yes')}
          >
            YES
          </CVCButton>
          <CVCButton
            className={classNames(styles.button, !noAnswer && styles.notActive)}
            onClick={() => onClick('no')}
          >
            NO
          </CVCButton>
        </div>
      </div>
    </div>
  );
}

export default BooleanQuestionSection;
