import React, { ReactElement } from 'react';
import { Location } from 'types';
import styles from './LocationSideBar.module.scss';
import LocationInfo from '../LocationInfo/LocationInfo';

interface LocationSideBarProps {
  filteredLocations: Array<Location>;
  selectedLocationId: number;
  setSelectedLocationId: React.Dispatch<React.SetStateAction<number>>;
  onMailScheduleClick: () => void;
}

const LocationSideBar = ({
  filteredLocations,
  selectedLocationId,
  setSelectedLocationId,
  onMailScheduleClick,
}: LocationSideBarProps): ReactElement => {
  return (
    <div className={styles.locationSidebarContainer}>
      {filteredLocations.map(location => {
        return (
          <LocationInfo
            key={location.id}
            location={location}
            weeklyScheduleAvailability={location.weeklyScheduleAvailability}
            selectedLocationId={selectedLocationId}
            setSelectedLocationId={setSelectedLocationId}
            onMailScheduleClick={onMailScheduleClick}
          />
        );
      })}
    </div>
  );
};

export default LocationSideBar;
