import React, { ReactElement } from 'react';
import Dialog from 'components/Dialog';
import styles from './DeleteConfirmationDialogStyles.module.scss';
import Button from '@material-ui/core/Button';

interface DeleteConfirmationDialogProps {
  open?: boolean;
  loading?: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

function DeleteConfirmationDialog({
  open,
  loading,
  onClose,
  onConfirm,
}: DeleteConfirmationDialogProps): ReactElement {
  return (
    <Dialog open={open} onClose={onClose} title="Delete Receipt">
      <div className={styles.container}>
        <div className={styles.text}>Are you sure you want to delete this receipt ?</div>
        <div className={styles.buttons}>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={onConfirm} disabled={loading}>
            Confirm
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default DeleteConfirmationDialog;
