import { useState, useEffect } from 'react';
import { NewBlobDocument, Document } from 'types';

import {
  createBlobDocument as createBlobDocumentRequest,
  getDocument,
  getDocumentTypes,
  verifyDocumentValid,
} from 'api/document';

import { getCaseByUserId } from 'api/case';
import * as logger from 'api/logger';

export interface DocumentEditState {
  loading: boolean;
  createBlobDocument(data: NewBlobDocument): Promise<number | string>;
  document?: Document;
  getDocumentTypeId(type: string): Promise<number>;
  verifyDocumentIsValidAPI(documentId: number | string): Promise<boolean>;
}

function useDocumentEditApi(userId, documentId): DocumentEditState {
  const [loading, setLoading] = useState(false);
  const [document, setDocument] = useState<Document>();

  async function createBlobDocument(data: NewBlobDocument): Promise<number | string> {
    setLoading(true);
    try {
      const cases = await getCaseByUserId(userId);
      if (cases.length) {
        return (await createBlobDocumentRequest(cases[0].id, data)).id;
      }
      return -1;
    } catch (e) {
      logger.error(e.message);
      return -1;
    }
    setLoading(false);
  }

  async function getDocumentTypeId(type): Promise<number> {
    if (!type) {
      return -1;
    }
    try {
      const documentTypes = await getDocumentTypes();
      const documentType = documentTypes.find(
        typeObj => typeObj.type.toUpperCase() === type.toUpperCase()
      );
      if (documentType) {
        return documentType.id;
      }
    } catch (e) {
      logger.error(e.message);
    }

    return -1;
  }

  async function retrieveDocumentToEdit(): Promise<void> {
    try {
      const document = await getDocument(documentId);
      setDocument(document);
    } catch (e) {
      logger.error(e.message);
    }
  }

  async function verifyDocumentIsValidAPI(documentId): Promise<boolean> {
    try {
      if (documentId > 0) {
        return await verifyDocumentValid(documentId);
      }
      return true;
    } catch (e) {
      logger.error(e.message);
      return true;
    }
  }

  useEffect(() => {
    retrieveDocumentToEdit().catch();
  }, []);

  return {
    loading,
    createBlobDocument,
    document,
    getDocumentTypeId,
    verifyDocumentIsValidAPI,
  };
}

export default useDocumentEditApi;
