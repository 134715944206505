import React, { ReactElement, useEffect, useState } from 'react';
import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';
import moment, { Moment } from 'moment';
import { Checkbox, MenuItem } from '@material-ui/core';
import { PDFDownloadLink } from '@react-pdf/renderer';
import CVCDatePicker from 'components/CVCDatePicker';
import Dialog from 'components/Dialog';
import DialogButton from 'components/DialogButton';
import styles from './NewReceiptDialogStyles.module.scss';
import Select from 'components/Select';
import { DialogButtonType } from 'components/DialogButton/DialogButton.view';
import InvoiceRow from './components/InvoiceRow';
import useReceiptPDFActions from './useReceiptPDFActions.hook';
import useReceiptPDFRender from './useReceiptPDFRender.hook';
import { CreateReceipt, NewReceiptValidationError, User } from 'types';
import { DateTimeFormatString } from 'lib/dateFormatter';
import formatPhoneInput from 'lib/phoneNumFormatter';
import { ValidationError } from 'components/Forms/ValidationError';
import { PROVINCES } from 'lib/addresses';
import { MIME_TYPE_PDF } from 'lib/document';
import PagePopup from 'pages/Patient/components/PagePopup';

const DEFAULT_TITLE = 'New receipt';
const STOCKING_VALUE = 'Medical Compression Stockings 20-30 mm HG';

const STOCKING_STYLES: Array<string> = [
  'Knee High',
  'Thigh High Open Toe',
  'Thigh High Close Toe',
  'Panty Hose',
  'Leggings',
  'Sport Knee High',
];

interface StyleColourCorrespondance {
  type: string;
  colour: string;
  typeId: number;
}

const STOCKING_COLOURS: Array<StyleColourCorrespondance> = [
  { type: 'Knee High', colour: 'Black', typeId: 21 },
  { type: 'Knee High', colour: 'Nude', typeId: 22 },
  { type: 'Thigh High Open Toe', colour: 'Black', typeId: 23 },
  { type: 'Thigh High Open Toe', colour: 'Nude', typeId: 24 },
  { type: 'Thigh High Close Toe', colour: 'Black', typeId: 25 },
  { type: 'Thigh High Close Toe', colour: 'Nude', typeId: 26 },
  { type: 'Panty Hose', colour: 'Black', typeId: 27 },
  { type: 'Panty Hose', colour: 'Nude', typeId: 28 },
  { type: 'Leggings', colour: 'Black', typeId: 29 },
  { type: 'Leggings', colour: 'Nude', typeId: 30 },
  { type: 'Sport Knee High', colour: 'Blue', typeId: 31 },
  { type: 'Sport Knee High', colour: 'Grey', typeId: 32 },
  { type: 'Sport Knee High', colour: 'Pink', typeId: 33 },
  { type: 'Sport Knee High', colour: 'Green', typeId: 34 },
];

interface NewReceiptDialogProps {
  open?: boolean;
  loading?: boolean;
  title?: string;
  value?: CreateReceipt;
  pdfDetails: any;
  patient?: User;
  setPdfDetails(details): void;
  onChange(name: string, value: any): void;
  onClose(): void;
  onSave(): void;
}

export interface PaymentRow {
  id?: number;
  paymentId: number;
  paymentMethod: string;
  amount?: number;
}
interface ReceiptFields {
  contents: string;
  header: string;
  patientName: string;
  receiptDate: moment.Moment | undefined;
  serviceDate: moment.Moment | undefined;
  province: string;
  healthCard: string;
  receiptDateOfBirth: moment.Moment | undefined;
  messageToPatient: string;
  paidInFull: boolean;
  partiallyPaid: boolean;
  phoneNumber: string;
  reservationFee: boolean;
  refund: boolean;
}

function NewReceiptDialog({
  open = false,
  loading = false,
  title = DEFAULT_TITLE,
  pdfDetails,
  patient,
  setPdfDetails,
  onChange,
  onClose,
  onSave,
}: NewReceiptDialogProps): ReactElement {
  const [stockingOptionsOpen, setStockingOptionsOpen] = useState<boolean>(false);
  const [stockingStyle, setStockingStyle] = useState<string>('');
  const [stockingColour, setStockingColour] = useState<string>('');
  const activeOnClose = !loading ? onClose : undefined;
  const [validationErrors, setValidationErrors] = useState<Array<NewReceiptValidationError>>([]);
  const [amountError, setAmountError] = useState<boolean>(false);
  const [fieldsValidated, setFieldsValidated] = useState<boolean>(false);
  const [openPagePopup, setOpenPagePopup] = useState<boolean>(false);
  const [selectedUrl, setSelectedUrl] = useState<any>();
  const [receiptTypeValues, setReceiptTypeValues] = useState<Array<string>>([]);
  const [paymentRows, setPaymentRows] = useState<Array<PaymentRow>>([
    {
      id: undefined,
      paymentId: 0,
      paymentMethod: 'N/A',
      amount: undefined,
    },
  ]);
  const [fieldValues, setFieldValues] = useState<ReceiptFields>({
    contents: '',
    header: '',
    patientName: '',
    receiptDate: undefined,
    serviceDate: undefined,
    province: '',
    healthCard: '',
    receiptDateOfBirth: undefined,
    messageToPatient: '',
    paidInFull: false,
    partiallyPaid: false,
    phoneNumber: '',
    reservationFee: false,
    refund: false,
  });

  let delayDebounceFn;

  const {
    getInvoiceAmount,
    invoiceTotal,
    invoiceRows,
    setInvoiceRows,
    receiptTypes,
    paymentMethods,
    receiptURL,
    setReceiptURL,
    previewReceiptDocument,
  } = useReceiptPDFActions();

  function validateForm(showErrors = false): boolean {
    let validated = true;
    const validationErrors: Array<NewReceiptValidationError> = [];
    if (showErrors) {
      setFieldsValidated(true);
    }

    if (fieldValues.header == '') {
      const validationError: NewReceiptValidationError = {
        field: 'header',
        message: 'Please create a header',
      };
      validationErrors.push(validationError);
      validated = false;
    }

    for (const row of invoiceRows) {
      if (!row.invoiceLine) {
        const validationError: NewReceiptValidationError = {
          field: 'invoiceRow',
          message: 'Missing description',
        };
        validationErrors.push(validationError);
        validated = false;
        break;
      }
      if (row.invoiceAmount < 0) {
        const validationError: NewReceiptValidationError = {
          field: 'invoiceRow',
          message: 'Amount must be non-negative',
        };
        validationErrors.push(validationError);
        validated = false;
        break;
      }
    }

    for (const row of paymentRows) {
      if (row.amount && row.amount < 0) {
        const validationError: NewReceiptValidationError = {
          field: 'paymentMethod',
          message: 'Amount must be non-negative',
        };
        validationErrors.push(validationError);
        validated = false;
        break;
      }
    }

    if (fieldValues.contents == '') {
      const validationError: NewReceiptValidationError = {
        field: 'type',
        message: 'Please select a receipt type',
      };
      validationErrors.push(validationError);
      validated = false;
    }

    if (fieldValues.patientName == '') {
      const validationError: NewReceiptValidationError = {
        field: 'patientName',
        message: 'Please add a patient name',
      };
      validationErrors.push(validationError);
      validated = false;
    }

    if (!fieldValues.receiptDate) {
      const validationError: NewReceiptValidationError = {
        field: 'receiptDate',
        message: 'Please create a receipt date',
      };
      validationErrors.push(validationError);
      validated = false;
    }

    if (!fieldValues.serviceDate) {
      const validationError: NewReceiptValidationError = {
        field: 'serviceDate',
        message: 'Please create a service date',
      };
      validationErrors.push(validationError);
      validated = false;
    }

    if (fieldValues.province == '') {
      const validationError: NewReceiptValidationError = {
        field: 'province',
        message: 'Please select a province',
      };
      validationErrors.push(validationError);
      validated = false;
    }

    if (amountError) {
      const validationError: NewReceiptValidationError = {
        field: 'amountError',
        message: 'Total paid is greater than amount due',
      };
      validationErrors.push(validationError);
      validated = false;
    }

    if (fieldsValidated || showErrors) {
      setValidationErrors(validationErrors);
    }
    return validated;
  }

  const { ReceiptLayout } = useReceiptPDFRender(pdfDetails);

  const invoiceRowChange = (name, value): void => {
    const idToUpdate = name.split('-')[1];
    const fieldUpdated = name.split('-')[0];

    const updatedInvoices = invoiceRows.map(item => {
      if (item.invoiceId == idToUpdate) {
        if (fieldUpdated == 'invoiceLine') {
          const receiptType = receiptTypes.find(item => item.receiptType == value);
          if (idToUpdate == 0 && value == '') {
            return { ...item, invoiceLine: value, invoiceAmount: 0 };
          }
          return { ...item, id: receiptType?.id, invoiceLine: value };
        } else {
          const price = parseFloat(value);
          const validPrice = value && !isNaN(price);
          return { ...item, invoiceAmount: validPrice ? price : 0.0 };
        }
      }
      return item;
    });
    setInvoiceRows(updatedInvoices);
    getInvoiceAmount(updatedInvoices);
  };

  const addInvoiceRow = (): void => {
    setInvoiceRows([
      ...invoiceRows,
      {
        id: undefined,
        invoiceId: invoiceRows.length,
        invoiceLine: '',
        invoiceAmount: 0,
      },
    ]);
  };

  const addPaymentRow = (): void => {
    setPaymentRows([
      ...paymentRows,
      {
        id: undefined,
        paymentId: paymentRows.length,
        paymentMethod: 'N/A',
        amount: undefined,
      },
    ]);
  };

  const handleSave = () => {
    if (validateForm(true)) {
      onSave();
    }
  };

  const handlePreview = () => {
    if (validateForm(true)) {
      setOpenPagePopup(true);
      setSelectedUrl(receiptURL);
    }
  };

  useEffect(() => {
    onChange('refund', fieldValues.refund);
    onChange('contents', fieldValues.contents);
    onChange('header', fieldValues.header);
    onChange('province', fieldValues.province);
    onChange('receiptDate', fieldValues.receiptDate?.format(DateTimeFormatString.APIDateFormat));
    onChange('serviceDate', fieldValues.serviceDate?.format(DateTimeFormatString.APIDateFormat));
    validateForm();
  }, [fieldValues]);

  useEffect(() => {
    setPdfDetails({ ...fieldValues, invoiceRows, invoiceTotal, paymentRows });
  }, [invoiceRows, fieldValues, invoiceTotal, paymentRows]);

  useEffect(() => {
    onChange('total', invoiceTotal);
  }, [invoiceTotal]);

  useEffect(() => {
    setReceiptTypeValues(receiptTypes.map(item => item.receiptType));
  }, [receiptTypes]);

  useEffect(() => {
    if (!patient) return;
    fieldValues.patientName = patient.fullName;
    fieldValues.receiptDateOfBirth = moment(patient.patientInfo?.dateOfBirth);

    if (patient.patientInfo?.healthCard) {
      fieldValues.healthCard = patient.patientInfo?.healthCard;
    }
    if (patient.contacts[2]?.value) {
      fieldValues.phoneNumber = formatPhoneInput(patient.contacts[2]?.value);
    }
  }, [patient]);

  function onChangeField(name, value): void {
    if (delayDebounceFn) clearTimeout(delayDebounceFn);

    delayDebounceFn = setTimeout(() => {
      switch (name) {
        case 'contents':
          setFieldValues({ ...fieldValues, contents: value });
          return;
        case 'header':
          setFieldValues({ ...fieldValues, header: value });
          return;
        case 'amount':
          const updatedPaymentAmounts = [...paymentRows];
          const updatedPaymentAmount = updatedPaymentAmounts.find(
            item => item.paymentId == value.id
          );
          if (updatedPaymentAmount) updatedPaymentAmount.amount = value.amount;
          setPaymentRows(updatedPaymentAmounts);
          return;
        case 'paymentMethod':
          const updatedPaymentMethods = [...paymentRows];
          const updatedPaymentMethod = updatedPaymentMethods.find(
            item => item.paymentId == value.id
          );
          if (updatedPaymentMethod) {
            const paymentMethod = paymentMethods.find(item => item.paymentMethod == value.method);
            updatedPaymentMethod.id = paymentMethod?.id;
            updatedPaymentMethod.paymentMethod = value.method;
          }
          setPaymentRows(updatedPaymentMethods);
          return;
        case 'patientName':
          setFieldValues({ ...fieldValues, patientName: value });
          return;
        case 'healthCard':
          setFieldValues({ ...fieldValues, healthCard: value });
          return;
        case 'province':
          setFieldValues({ ...fieldValues, province: value });
          return;
        case 'receiptDate':
          setFieldValues({ ...fieldValues, receiptDate: moment(value) });
          return;
        case 'serviceDate':
          setFieldValues({ ...fieldValues, serviceDate: moment(value) });
          return;
        case 'receiptDateOfBirth':
          setFieldValues({ ...fieldValues, receiptDateOfBirth: moment(value) });
          return;
        case 'messageToPatient':
          setFieldValues({ ...fieldValues, messageToPatient: value });
          return;
        case 'paidInFull':
          setFieldValues({ ...fieldValues, paidInFull: value });
          return;
        case 'partiallyPaid':
          setFieldValues({ ...fieldValues, partiallyPaid: value });
          return;
        case 'reservationFee':
          setFieldValues({ ...fieldValues, reservationFee: value });
          return;
        case 'refund':
          setFieldValues({
            ...fieldValues,
            refund: value,
            reservationFee: false,
            partiallyPaid: false,
            paidInFull: false,
          });
          return;
        default:
          return;
      }
    }, 500);
  }

  const resetState = (): void => {
    setValidationErrors([]);
    setFieldsValidated(false);
    setFieldValues({
      contents: '',
      header: '',
      patientName: fieldValues.patientName,
      receiptDate: undefined,
      serviceDate: undefined,
      province: '',
      healthCard: fieldValues.healthCard,
      receiptDateOfBirth: fieldValues.receiptDateOfBirth,
      messageToPatient: '',
      paidInFull: false,
      partiallyPaid: false,
      phoneNumber: '',
      reservationFee: false,
      refund: false,
    });
    setAmountError(false);
    setInvoiceRows([
      {
        invoiceId: 0,
        invoiceLine: '',
        invoiceAmount: 0,
      },
    ]);
    setPaymentRows([
      {
        paymentId: 0,
        paymentMethod: 'N/A',
        amount: undefined,
      },
    ]);
  };

  const checkAmountPaid = (amount, paid): void => {
    const paidInFull = amount === paid;
    const partiallyPaid = amount > paid;
    setFieldValues({ ...fieldValues, paidInFull, partiallyPaid });
    setAmountError(!paidInFull && !partiallyPaid);
  };

  useEffect(() => {
    let totalPaid = 0;
    paymentRows.forEach(item => {
      if (item.amount) {
        totalPaid += +item.amount;
      }
    });
    if (totalPaid !== 0) {
      checkAmountPaid(invoiceTotal, totalPaid);
    }
  }, [invoiceTotal, paymentRows]);

  useEffect(() => {
    if (!open) {
      resetState();
      return;
    }
  }, [open]);

  return (
    <div>
      <Dialog open={open} title={title} onClose={activeOnClose}>
        <div className={classNames(styles.dialogBody, styles.padding)}>
          <div className={styles.row}>
            <div className={classNames(styles.row, styles.padding)}>
              <div className={classNames(fieldValues.partiallyPaid && styles.disabled)}>
                {'Refund'}
              </div>
              <Checkbox
                name={'refund'}
                onChange={(event): void => onChangeField(event.target.name, event.target.checked)}
              />
            </div>
          </div>
          <div className={styles.padding}>
            <TextField
              label="Receipt Header"
              name="header"
              fullWidth
              onChange={({ target }): void => onChangeField(target.name, target.value)}
            />
            <ValidationError field={'header'} validationErrors={validationErrors} />
          </div>
          <div className={styles.padding}>
            {paymentRows.map(paymentRow => {
              return (
                <div className={styles.row} key={paymentRow.paymentId}>
                  <div className={classNames(styles.fullFlex)}>
                    <Select
                      fullWidth
                      label={fieldValues.refund ? 'Refund To' : 'Payment Method'}
                      name={'paymentMethod'}
                      className={styles.padding}
                      renderValue={(value): ReactElement => (
                        <div className={styles.selectItem}>{value}</div>
                      )}
                      onChange={({ target }): void =>
                        onChangeField(target.name, {
                          method: target.value,
                          id: paymentRow.paymentId,
                        })
                      }
                    >
                      {paymentMethods.map(item => (
                        <MenuItem
                          key={item.id}
                          value={item.paymentMethod}
                          className={styles.selectItem}
                        >
                          {item.paymentMethod}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  <div className={styles.padding}>
                    <TextField
                      label="Amount Paid"
                      name="amount"
                      type="number"
                      fullWidth
                      InputProps={{ inputProps: { min: '0' } }}
                      onChange={({ target }): void =>
                        onChangeField(target.name, {
                          amount: target.value,
                          id: paymentRow.paymentId,
                        })
                      }
                    />
                  </div>
                </div>
              );
            })}
            <ValidationError field={'paymentMethod'} validationErrors={validationErrors} />
          </div>
          <div>
            <DialogButton
              onClick={(): void => addPaymentRow()}
              buttonType={DialogButtonType.Bordered}
            >
              {'+ Add payment method'}
            </DialogButton>
          </div>
          <div className={styles.row}>
            <div className={classNames(styles.padding, styles.fullFlex)}>
              <Select
                fullWidth
                label={fieldValues.refund ? 'Refund for:' : 'Receipt for:'}
                name={'contents'}
                className={styles.padding}
                renderValue={(value): ReactElement => (
                  <div className={styles.selectItem}>{value}</div>
                )}
                onChange={({ target }): void => {
                  setStockingStyle('');
                  setStockingColour('');
                  const newOpenOptions = target.value === STOCKING_VALUE;
                  setStockingOptionsOpen(newOpenOptions);
                  if (!newOpenOptions) {
                    onChangeField(target.name, target.value);
                  }
                }}
              >
                {receiptTypes
                  .filter(item => item.isVisible)
                  .map(item => (
                    <MenuItem key={item.id} value={item.receiptType} className={styles.selectItem}>
                      {item.receiptType}
                    </MenuItem>
                  ))}
                <MenuItem key={0} value={STOCKING_VALUE} className={styles.selectItem}>
                  {STOCKING_VALUE}
                </MenuItem>
              </Select>
              <ValidationError field={'type'} validationErrors={validationErrors} />
            </div>

            <div className={styles.padding}>
              <CVCDatePicker
                label="Date Of Service"
                value={fieldValues.serviceDate}
                onChange={(value): void => onChangeField('serviceDate', value)}
              />
              <ValidationError field={'serviceDate'} validationErrors={validationErrors} />
            </div>
          </div>
          {stockingOptionsOpen && (
            <div className={styles.row}>
              <div className={classNames(styles.padding, styles.fullFlex)}>
                <Select
                  fullWidth
                  label="Style:"
                  name={'contents'}
                  value={stockingStyle}
                  className={styles.padding}
                  renderValue={(value): ReactElement => (
                    <div className={styles.selectItem}>{value}</div>
                  )}
                  onChange={({ target }): void => {
                    setStockingStyle(target.value);
                    setStockingColour('');
                  }}
                >
                  {STOCKING_STYLES.map((item, index) => (
                    <MenuItem key={index} value={item} className={styles.selectItem}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
                <ValidationError field={'type'} validationErrors={validationErrors} />
              </div>
              <div className={classNames(styles.padding, styles.fullFlex)}>
                <Select
                  fullWidth
                  label="Colour:"
                  name={'contents'}
                  value={stockingColour}
                  className={styles.padding}
                  renderValue={(value): ReactElement => (
                    <div className={styles.selectItem}>{value}</div>
                  )}
                  onChange={({ target }): void => {
                    setStockingColour(target.value);
                    const id = STOCKING_COLOURS.find(
                      item => item.type == stockingStyle && item.colour == target.value
                    )?.typeId;
                    if (id) {
                      const receiptType =
                        receiptTypes.find(item => item.id == id)?.receiptType || '';
                      onChangeField(target.name, receiptType);
                    }
                  }}
                >
                  {STOCKING_COLOURS.filter(item => stockingStyle === item.type).map(
                    (item, index) => (
                      <MenuItem key={index} value={item.colour} className={styles.selectItem}>
                        {item.colour}
                      </MenuItem>
                    )
                  )}
                </Select>
                <ValidationError field={'type'} validationErrors={validationErrors} />
              </div>
            </div>
          )}
          <div className={styles.row}>
            <div className={classNames(styles.padding, styles.fullFlex)}>
              <TextField
                label="Patient Name"
                name="patientName"
                className={styles.padding}
                fullWidth
                value={fieldValues.patientName}
                disabled={true}
              />
              <ValidationError field={'patientName'} validationErrors={validationErrors} />
            </div>
            <div className={styles.padding}>
              <CVCDatePicker
                label="Date Of Receipt"
                value={fieldValues.receiptDate}
                onChange={(value): void => onChangeField('receiptDate', value)}
              />
              <ValidationError field={'receiptDate'} validationErrors={validationErrors} />
            </div>
          </div>

          <div className={styles.row}>
            <div className={classNames(styles.padding, styles.fullFlex)}>
              <TextField
                label="Health Card"
                name="healthCard"
                className={styles.padding}
                fullWidth
                value={fieldValues.healthCard}
                disabled={true}
              />
            </div>
            <div className={styles.padding}>
              <CVCDatePicker
                label="Date of Birth"
                value={fieldValues.receiptDateOfBirth}
                disabled={true}
              />
            </div>
          </div>

          <div className={styles.invoiceContainer}>
            <InvoiceRow rowType={'header'} />
            {invoiceRows.map((invoiceRow, index) => {
              return (
                <InvoiceRow
                  key={index}
                  onRowChange={invoiceRowChange}
                  invoiceRow={invoiceRow}
                  receiptTypes={receiptTypeValues}
                  rowType={'default'}
                />
              );
            })}
            <InvoiceRow amountSum={invoiceTotal} rowType={'footer'} refund={fieldValues.refund} />
            <ValidationError field={'invoiceRow'} validationErrors={validationErrors} />
          </div>

          <div>
            <DialogButton
              onClick={(): void => addInvoiceRow()}
              buttonType={DialogButtonType.Bordered}
            >
              {'+ Add invoice row'}
            </DialogButton>
          </div>

          <TextField
            label="Message to Patient"
            name="messageToPatient"
            className={styles.padding}
            fullWidth
            onChange={({ target }): void => onChangeField(target.name, target.value)}
            multiline
            rows={4}
          />
          <div className={styles.row}>
            <div className={classNames(styles.row, styles.padding)} style={{ width: 200 }}>
              <Select
                fullWidth
                label="Tax Region:"
                name={'province'}
                className={styles.padding}
                renderValue={(value): ReactElement => (
                  <div className={styles.selectItem}>{value}</div>
                )}
                onChange={({ target }): void => onChangeField(target.name, target.value)}
              >
                {PROVINCES.map(item => (
                  <MenuItem key={item} value={item} className={styles.selectItem}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
              <ValidationError field={'province'} validationErrors={validationErrors} />
            </div>
            <div style={{ width: '50%' }}></div>
            <div className={classNames(styles.row, styles.padding)} style={{ width: 180 }}>
              {!fieldValues.refund && (
                <>
                  <div className={classNames(fieldValues.reservationFee)}>{'Reservation Fee'}</div>
                  <Checkbox
                    name={'reservationFee'}
                    onChange={(event): void =>
                      onChangeField(event.target.name, event.target.checked)
                    }
                  />
                </>
              )}
            </div>
          </div>

          <div className={classNames(styles.row, styles.bottom)}>
            {!fieldValues.refund && (
              <>
                <div className={classNames(styles.row, styles.padding)}>
                  <div className={classNames(fieldValues.partiallyPaid && styles.disabled)}>
                    {'Paid in full'}
                  </div>
                  <Checkbox
                    name={'paidInFull'}
                    onChange={(event): void =>
                      onChangeField(event.target.name, event.target.checked)
                    }
                    checked={fieldValues.paidInFull}
                    disabled={fieldValues.partiallyPaid}
                  />
                </div>
                <div className={classNames(styles.row, styles.padding)}>
                  <div className={classNames(fieldValues.paidInFull && styles.disabled)}>
                    {'Partially paid'}
                  </div>
                  <Checkbox
                    name={'partiallyPaid'}
                    onChange={(event): void =>
                      onChangeField(event.target.name, event.target.checked)
                    }
                    checked={fieldValues.partiallyPaid}
                    disabled={fieldValues.paidInFull}
                  />
                </div>
              </>
            )}

            <div className={classNames(styles.previewButton, styles.padding)}>
              <DialogButton onClick={handlePreview} buttonType={DialogButtonType.Bordered}>
                {'Preview receipt'}
              </DialogButton>
            </div>
            <div className={classNames(styles.previewButton, styles.padding)}>
              <DialogButton
                onClick={handleSave}
                buttonType={DialogButtonType.Bordered}
                loading={loading}
              >
                {'Save Receipt'}
              </DialogButton>
            </div>
          </div>
          <div className={classNames(styles.row, styles.bottom)}>
            <ValidationError field={'amountError'} validationErrors={validationErrors} />
          </div>
        </div>
      </Dialog>
      <PDFDownloadLink document={ReceiptLayout(0)}>
        {({ url, loading }): ReactElement => {
          if (url) {
            setReceiptURL(url);
          }
          return <div></div>;
        }}
      </PDFDownloadLink>
      <PagePopup
        open={openPagePopup}
        type={MIME_TYPE_PDF}
        url={selectedUrl}
        onClose={() => setOpenPagePopup(false)}
      />
    </div>
  );
}

export default NewReceiptDialog;
