import React, { ReactElement } from 'react';
import classNames from 'classnames';

import Dialog from '../Dialog';
import DialogButton from '../DialogButton';
import { DialogButtonType } from '../DialogButton/DialogButton.view';

import styles from './AlertDialog.module.scss';

interface AlertDialogProps {
  open: boolean | undefined;
  message: string;
  title: string;
  onClose: () => void;
}

function AlertDialog({ open, message, title, onClose }: AlertDialogProps): ReactElement {
  return (
    <Dialog open={open} onClose={onClose} title={title}>
      <div className={styles.container}>
        <div className={styles.containerHeader}>
          <div className={styles.appointmentOpeningInfo}>
            <p>{message}</p>
          </div>
        </div>
        <div className={classNames(styles.buttonSection)}>
          <DialogButton
            buttonType={DialogButtonType.AffirmativeLink}
            className={styles.btn}
            onClick={onClose}
          >
            OK
          </DialogButton>
        </div>
      </div>
    </Dialog>
  );
}

export default AlertDialog;
