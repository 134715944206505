import React, { useState } from 'react';

import Layout from 'Layout';
import useProfileRoles from 'hook/useProfileRoles.hook';

import useWorkflowResultApi from 'pages/WorkflowResult/useWorkflowApi.hook';

import styles from './WorkflowStyles.module.scss';
import PatientResults from './components/PatientResults';
import TechnicianResults from './components/TechnicianResults';
import CoverLetter from './components/CoverLetter';
import { CoverLetterParagraphEntry } from './components/CoverLetter';
import { WorkflowPDFComponent } from './components/WorkflowPDF';
import PropTypes from 'prop-types';
import { User } from 'types';

interface WorkflowComponentProps {
  caseId?: number;
  formId?: number;
  editable?: boolean;
  isDoctorOrAdmin?;
  formCaseResult?;
  coverLetterHeaderFooter?;
  onParagraphTextSet?: (a: Array<CoverLetterParagraphEntry>) => void;
  onCoverLetterHeaderFooterSet?: (value) => void;
  selectedDoctor?: User;
  signatureUrl?: string;
  additionalData?: string;
  procedureDate?: Date;
}

export const WorkflowComponent = ({
  formId,
  caseId,
  editable,
  coverLetterHeaderFooter,
  onParagraphTextSet,
  onCoverLetterHeaderFooterSet,
}: WorkflowComponentProps) => {
  const { formCaseResult } = useWorkflowResultApi(formId, caseId);
  const { isDoctorOrAdmin } = useProfileRoles();

  return (
    <div className={styles.container}>
      {isDoctorOrAdmin && <PatientResults formCaseResult={formCaseResult} />}
      <TechnicianResults formCaseResult={formCaseResult} />
      {isDoctorOrAdmin && (
        <CoverLetter
          caseId={caseId}
          editable={editable}
          formCaseResult={formCaseResult}
          onParagraphTextSet={onParagraphTextSet}
          onCoverLetterHeaderFooterSet={onCoverLetterHeaderFooterSet}
          coverLetterHeaderFooter={coverLetterHeaderFooter}
        />
      )}
    </div>
  );
};

export const NecessityComponent = ({
  formId,
  caseId,
  editable,
  coverLetterHeaderFooter,
  onParagraphTextSet,
  onCoverLetterHeaderFooterSet,
  selectedDoctor,
  signatureUrl,
  procedureDate,
}: WorkflowComponentProps) => {
  const { formCaseResult } = useWorkflowResultApi(formId, caseId);
  const { isStaff } = useProfileRoles();

  return (
    <div className={styles.container}>
      {isStaff && (
        <CoverLetter
          caseId={caseId}
          editable={true}
          formCaseResult={formCaseResult}
          onParagraphTextSet={onParagraphTextSet}
          onCoverLetterHeaderFooterSet={onCoverLetterHeaderFooterSet}
          coverLetterHeaderFooter={coverLetterHeaderFooter}
          necessityLetter={true}
          selectedDoctor={selectedDoctor}
          signatureUrl={signatureUrl}
          procedureDate={procedureDate}
        />
      )}
    </div>
  );
};

function WorkflowPage(props) {
  const { caseId, formId } = props?.match?.params;

  const patientId = props.location.state.patientId;
  const everyFormCompleted = props.location.state.everyFormCompleted;
  const necessityLetter = props.location.state.necessityLetter;
  const history = props.history;
  const selectedDoctor = props.location.state.selectedDoctor;
  const signatureUrl = props.location.state.signatureUrl;
  const procedureDate = props.location.state.procedureDate;

  const [paragraphTexts, setParagraphTexts] = useState<Array<CoverLetterParagraphEntry>>([]);
  const [coverLetterHeaderFooter, setCoverLetterHeaderFooter] = useState();

  const onParagraphTextSet = (paragraphTexts: Array<CoverLetterParagraphEntry>) => {
    setParagraphTexts(paragraphTexts);
  };

  const onCoverLetterHeaderFooterSet = coverLetterHeaderFooter => {
    setCoverLetterHeaderFooter(coverLetterHeaderFooter);
  };

  if (necessityLetter) {
    return (
      <Layout hideFooter>
        <div className={styles.necessityLetterContainer}>
          <WorkflowPDFComponent
            caseId={parseInt(caseId)}
            patientId={patientId}
            formId={formId}
            paragraphTexts={paragraphTexts}
            coverLetterHeaderFooter={coverLetterHeaderFooter}
            history={history}
            everyFormCompleted={everyFormCompleted}
            necessityLetter={true}
          />
          <NecessityComponent
            caseId={parseInt(caseId)}
            editable={true}
            onParagraphTextSet={onParagraphTextSet}
            coverLetterHeaderFooter={coverLetterHeaderFooter}
            onCoverLetterHeaderFooterSet={onCoverLetterHeaderFooterSet}
            selectedDoctor={selectedDoctor}
            signatureUrl={signatureUrl}
            procedureDate={procedureDate}
          />
        </div>
      </Layout>
    );
  }

  return (
    <Layout hideFooter>
      <div className={styles.workflowPageContainer}>
        <WorkflowPDFComponent
          caseId={parseInt(caseId)}
          patientId={patientId}
          formId={formId}
          paragraphTexts={paragraphTexts}
          coverLetterHeaderFooter={coverLetterHeaderFooter}
          history={history}
          everyFormCompleted={everyFormCompleted}
        />
        <WorkflowComponent
          caseId={parseInt(caseId)}
          editable={true}
          onParagraphTextSet={onParagraphTextSet}
          coverLetterHeaderFooter={coverLetterHeaderFooter}
          onCoverLetterHeaderFooterSet={onCoverLetterHeaderFooterSet}
        />
      </div>
    </Layout>
  );
}

WorkflowPage.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
};

export default WorkflowPage;
