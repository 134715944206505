import React, { ReactElement } from 'react';

interface ConditionDiv {
  condition?: boolean;
  [other: string]: any;
}

function ConditionDiv({ condition, ...other }: ConditionDiv): ReactElement | null {
  return condition ? <div {...other} /> : null;
}

export default ConditionDiv;
