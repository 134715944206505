export const GENDERS = ['Male', 'Female', 'Other'];

export const PREFIXES = ['Mr', 'Mrs', 'Ms', 'Dr'];

export enum CanadaProvinceCodes {
  NL = 'NL',
  PE = 'PE',
  NS = 'NS',
  NB = 'NB',
  QC = 'QC',
  ON = 'ON',
  MB = 'MB',
  SK = 'SK',
  AB = 'AB',
  BC = 'BC',
  YT = 'YT',
  NT = 'NT',
  NU = 'NU',
}

export enum FamDocInfo {
  FirstName = 'familyPhysicianFirstName',
  LastName = 'familyPhysicianLastName',
  Email = 'familyPhysicianEmail',
  Phone = 'familyPhysicianPhone',
  Fax = 'familyPhysicianFax',
  Billing = 'familyPhysicianBilling',
  Id = 'familyPhysicianId',
}

export const HAS_HEALTH_CARD = true;

export const formatHealthCard = (healthCard: string) => {
  return healthCard.replace(/[^a-zA-Z0-9]+/g, '');
};
