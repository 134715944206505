import React, { ReactElement } from 'react';
import { Moment } from 'moment';
import formatDate, { DateTimeFormat } from 'lib/dateFormatter';
import classNames from 'classnames';
import styles from './ScheduleGrid.module.scss';
import LocationHours from '../LocationHours';
import LocationSchedule from '../LocationSchedule';
import { LocationsSchedule as LocationScheduleInterface } from '../../useLocations.Interface';
import { ScheduleTypesNumberToString } from 'lib/scheduleTypes';
import moment from 'moment';
import { isSpreadAssignment } from 'typescript';

//TODO: Will make props not optional when testing with data
interface SchduleGridProps {
  DayOfWeek?: string;
  Time?: string;
  selectedDate?: Moment;
  staffScheduleInfo: Array<unknown>;
  scheduleMap: Map<string, Array<LocationScheduleInterface>>;
  locationExceptions?: Array<string>;
  datesOfWeek: Array<string>;
  selectedScheduleType?: string;
  locationScheduleTypes: Array<number>;
  highlightedStaff: number;
  handleSelectedScheduleType?: (selectedScheduleType: string) => void;
  handleDateChange?: (selectedDate: Moment) => void;
  handleStaffScheduleEditIconClick: (
    locationRecurrence,
    locationRecurrenceEndDate,
    staffSchedule,
    userId,
    start,
    end,
    scheduleType
  ) => void;
  handleLocationScheduleEditIconClick: (locationSchedule, date, scheduleType) => void;
  handleProfileIconClick: (
    locationRecurrence,
    locationRecurrenceEndDate,
    start,
    end,
    scheduleType
  ) => void;
}

function ScheduleGrid({
  highlightedStaff,
  handleLocationScheduleEditIconClick,
  handleStaffScheduleEditIconClick,
  handleProfileIconClick,
  staffScheduleInfo,
  scheduleMap,
  datesOfWeek,
  locationExceptions,
  locationScheduleTypes,
}: SchduleGridProps): ReactElement {
  const dateException = date => {
    if (!locationExceptions || !date) return;
    return !!locationExceptions.find(exception => moment(exception).isSame(date, 'day'));
  };
  return (
    <div className={styles.container}>
      <LocationHours scheduleMap={scheduleMap} />
      <div className={styles.scheduleContainer}>
        {/* Maps each schedule type to distinct row */}
        {locationScheduleTypes.map((scheduleType, appIndex) => {
          // Determine if last row
          const notLastRow =
            locationScheduleTypes.indexOf(scheduleType) !== locationScheduleTypes.length - 1;

          let rowElements: Array<JSX.Element> = [];

          rowElements.push(
            <div
              className={classNames(
                styles.scheduleTypeContainer,
                notLastRow && styles.bottomBorderCell
              )}
            >
              <div className={styles.scheduleType}>
                <b>{ScheduleTypesNumberToString(scheduleType)}</b>
              </div>
            </div>
          );

          // String dates of the week ex. 10/18/2021, 10/19/2021 etc.
          rowElements = rowElements.concat(
            datesOfWeek.map((dateOfWeek, dayIndex) => {
              // For rendering the LocationSchedule component based on chosen schedule type and day of week
              //and renders empty div is indexs nont found
              // TODO: make the inputs a day and schedule type instead of numbers

              //  Get all schedules for date
              const scheduleToDisplayForType = (scheduleMap.get(dateOfWeek) || []).filter(
                schedule => {
                  return schedule.type === scheduleType;
                }
              );

              const dayHasNoSchedules = (scheduleMap.get(dateOfWeek) || []).length === 0;
              const exception = dateException(dateOfWeek);

              if (dayHasNoSchedules) {
                return (
                  <div
                    className={classNames(
                      styles.disabled,
                      notLastRow && styles.bottomBorderCell,
                      exception && styles.dateException
                    )}
                  >
                    <div
                      id={appIndex.toString() + dayIndex.toString()}
                      key={dayIndex}
                      className={styles.scheduled}
                    >
                      &nbsp;
                    </div>
                  </div>
                );
              } else if (scheduleToDisplayForType.length > 0) {
                // Gird has one grid spot, so we wrap all of our schedules to consume 1 spot
                return (
                  <div
                    className={classNames(
                      notLastRow && styles.bottomBorderCell,
                      exception && styles.dateException
                    )}
                  >
                    {scheduleToDisplayForType.map((schedule: LocationScheduleInterface) => {
                      return (
                        <div key={schedule.start} className={styles.scheduled}>
                          <LocationSchedule
                            schedule={schedule}
                            locationException={!!exception}
                            startTime={formatDate(
                              DateTimeFormat.TimeOnlyWithoutMeridies,
                              schedule.start
                            )}
                            endTime={formatDate(
                              DateTimeFormat.TimeOnlyWithoutMeridies,
                              schedule.end
                            )}
                            handleLocationScheduleEditIconClick={(): void => {
                              handleLocationScheduleEditIconClick(
                                schedule.scheduleId,
                                schedule.start,
                                scheduleType
                              );
                            }}
                            handleStaffScheduleEditIconClick={(
                              locationRecurrence,
                              locationRecurrenceEndDate,
                              staffScheduledId,
                              userId
                            ): void => {
                              handleStaffScheduleEditIconClick(
                                locationRecurrence,
                                locationRecurrenceEndDate,
                                staffScheduledId,
                                userId,
                                schedule.start,
                                schedule.end,
                                scheduleType
                              );
                            }}
                            handleProfileIconClick={(
                              locationRecurrence,
                              locationRecurrenceEndDate
                            ): void => {
                              handleProfileIconClick(
                                locationRecurrence,
                                locationRecurrenceEndDate,
                                schedule.start,
                                schedule.end,
                                scheduleType
                              );
                            }}
                            staffScheduleInfo={staffScheduleInfo}
                            highlightedStaff={highlightedStaff}
                          />
                        </div>
                      );
                    })}
                  </div>
                );
              } else {
                // Empty Cell
                return (
                  <div
                    className={classNames(
                      notLastRow && styles.bottomBorderCell,
                      exception && styles.dateException
                    )}
                  >
                    <div
                      id={appIndex.toString() + dayIndex.toString()}
                      key={dayIndex}
                      className={styles.scheduled}
                    >
                      &nbsp;
                    </div>
                  </div>
                );
              }
            })
          );
          return rowElements;
        })}
      </div>
    </div>
  );
}

export default ScheduleGrid;
