import React, { ReactElement, useEffect, useState } from 'react';

import styles from './EditStaffNotesDialog.module.scss';
import Dialog from 'components/Dialog';
import DialogButton from 'components/DialogButton';
import { Grid, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import { User } from 'types';

interface EditStaffNotesProps {
  open: boolean;
  staff?: User;
  note?: string;
  onClose: () => void;
  onSave: (values) => void;
}

function EditStaffNotesDialog({
  open,
  staff,
  note,
  onClose,
  onSave,
}: EditStaffNotesProps): ReactElement {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      note: note ? note : '',
    },
    onSubmit: async values => {
      try {
        const data = { note: values.note ? values.note : null };
        onSave(data);
        onClose();
      } catch (e) {
        throw e;
      }
    },
  });

  return (
    <Dialog open={open} onClose={onClose} title={`Edit ${staff?.fullName}'s Notes`}>
      <div className={styles.container}>
        <Grid container className={styles.gridContainer}>
          <TextField
            label="Note"
            name="note"
            value={formik.values.note}
            inputProps={{ maxLength: 500 }}
            onChange={formik.handleChange}
            fullWidth
          />

          <div className={styles.buttons}>
            <DialogButton onClick={onClose}>Cancel</DialogButton>
            <DialogButton className={styles.marginLeft} onClick={formik.handleSubmit}>
              {'Save'}
            </DialogButton>
          </div>
        </Grid>
      </div>
    </Dialog>
  );
}

export default EditStaffNotesDialog;
