import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { ReactComponent as ScheduleBtnIcon } from 'assets/svgs/CalendarWithPlusIcon.svg';
import { ReactComponent as CancelDayIcon } from 'assets/svgs/closeButton.svg';
import { ReactComponent as OpenDayIcon } from 'assets/svgs/greenCheckLarge.svg';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import styles from './LocationDayScheduleOptions.module.scss';
import classNames from 'classnames';
import moment from 'moment';
import { DateTimeFormatString } from 'lib/dateFormatter';

interface LocationDayScheduleOptionsPanelProps {
  consultationAndFollowUp: boolean;
  procedure: boolean;
  ultrasound: boolean;
  staffSchedule: boolean;
  date: string;
  locationId: number;
  excludeLocation: boolean;
  handleNewScheduleButtonClick: () => void;
  handleCancelDayClick: (locationId: number, dateString: string) => void;
}

function LocationDayScheduleOptions({
  consultationAndFollowUp,
  procedure,
  ultrasound,
  staffSchedule,
  handleNewScheduleButtonClick,
  handleCancelDayClick,
  date,
  locationId,
  excludeLocation,
}: LocationDayScheduleOptionsPanelProps) {
  const appointmentType = { Procedure: 'P', ConsultationOrFollowUp: 'C-F', Ultrasound: 'U' };
  const [result, setResult] = useState<string>('');
  const appointmentTypeArray: Array<string> = [];

  useEffect((): void => {
    setResult('');
    if (consultationAndFollowUp) {
      appointmentTypeArray.push(appointmentType.ConsultationOrFollowUp);
    }
    if (procedure) {
      appointmentTypeArray.push(appointmentType.Procedure);
    }
    if (ultrasound) {
      appointmentTypeArray.push(appointmentType.Ultrasound);
    }
    if (appointmentTypeArray.length > 0) {
      setResult(`${appointmentTypeArray.join('/')} | `);
    }
  }, [consultationAndFollowUp, procedure, ultrasound]);

  // Has nothing scheduled
  const dayHasNoSchedules = !consultationAndFollowUp && !procedure && !ultrasound;

  return (
    <div className={classNames(styles.container)}>
      <div
        className={classNames(
          styles.mainContent,
          excludeLocation && styles.exclude,
          dayHasNoSchedules && styles.disabled
        )}
      >
        {result}
        {!excludeLocation ? (
          <ScheduleBtnIcon
            className={styles.icon}
            onClick={handleNewScheduleButtonClick}
            title="Add New Schedule"
          />
        ) : (
          <></>
        )}

        {staffSchedule ? (
          <NavLink
            to={{
              pathname: `/appointments/${moment(date).format(DateTimeFormatString.APIDateFormat)}`,
              state: { date: date, locationId: locationId },
            }}
            className={styles.icon}
            title="Go to appointments"
          >
            <ArrowForwardOutlinedIcon fontSize="small" />
          </NavLink>
        ) : null}
      </div>
      <div className={styles.excludeContainer}>
        {excludeLocation ? (
          <OpenDayIcon
            className={styles.exclusionIcon}
            onClick={() => handleCancelDayClick(locationId, date)}
            title="Include Day"
          />
        ) : (
          <CancelDayIcon
            className={styles.exclusionIcon}
            onClick={() => handleCancelDayClick(locationId, date)}
            title="Exclude Day"
          />
        )}
      </div>
    </div>
  );
}

export default LocationDayScheduleOptions;
