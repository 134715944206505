import { creatContextStore } from './base';

export interface UserInfo {
  email?: string;
  userName?: string;
  role: string;
  authenticated: boolean;
}

const initialState = {
  email: undefined,
  userName: '',
  role: 'admin',
  authenticated: false,
};

const Context = creatContextStore<UserInfo>(initialState);

const [Provider, useState, useSetState, useMergeState] = Context;

export {
  Provider as UserContextProvider,
  useState as useUserState,
  useSetState as useSetUserState,
  useMergeState as useMergeUserState,
};
