import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Layout from 'Layout';

import { LocationApi } from 'api';
import Locations from './Locations.view';
import { NewLocationDialogContainer } from './components/NewLocationDialog';
import { EditLocationDialogContainer } from './components/EditLocationDialog';
import { SendScheduleDialog } from './components/SendScheduleDialog';
import DeleteConfirmationDialog from 'components/DeleteConfirmationDialog';
import { Location } from 'types';
import { DeleteConfirmationDialogType } from 'components/DeleteConfirmationDialog/DeleteConfirmationDialog.view';
import useLocationHook from './useLocations.hook';
import SuccessSnackBar from 'components/SuccessSnackBar';
import useProfileRoles from 'hook/useProfileRoles.hook';
import { DateTimeFormatString } from 'lib/dateFormatter';
import * as logger from 'api/logger';

function Alert(props: AlertProps): ReactElement {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function LocationsContainer({ history, match }: any): ReactElement | null {
  const [open, setOpen] = useState<boolean>(false);
  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [openSendScheduleDialog, setOpenSendScheduleDialog] = useState<boolean>(false);
  const [locationToDelete, setLocationToDelete] = useState<Location | null>(null);
  const [locationToEditId, setLocationToEditId] = useState<string>('');
  const [deleting, setDeleting] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedDate, setSelectedDate] = useState<Moment>(moment());
  const [highlightedStaff, setHighlightedStaff] = useState<number>(-1);
  const [successSnackbarMessage, setSuccessSnackbarMessage] = useState('');
  const [isSuccessSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const { isAdmin } = useProfileRoles();
  const isValidUser = isAdmin;

  // TODO: Populate with list of locations & date of start of week to pull from
  const { locationSchedules, staffList, refresh, locations, getLocations } = useLocationHook(
    selectedDate
  );
  /*
  const {  events, schedule, scheduleExceptions,  refresh } = useLocationSchedule(
    locationId,
    date
      .clone()
      .startOf('month')
      .startOf('week')
      .toISOString(),
    date
      .clone()
      .endOf('month')
      .endOf('week')
      .toISOString()
  );
*/

  useEffect(() => {
    document.title = 'CVC - Scheduling';
    const date = moment(match?.params?.date);
    if (!date.isValid()) return;
    setSelectedDate(moment(date, DateTimeFormatString.APIDateFormat));
  }, []);

  async function onLocationScheduleSaveSuccess(): Promise<void> {
    await refresh();
  }

  function onSaveSuccess(): void {
    getLocations();
    setOpen(false);
  }

  async function onDialogSaveSuccess(): Promise<void> {
    await refresh();
  }

  async function deleteLocation(locationToDelete): Promise<void> {
    setDeleting(true);
    try {
      if (locationToDelete.id) {
        await LocationApi.deleteLocation(locationToDelete.id);
        setSuccessSnackbarMessage('The Location has been successfully deleted');
        setSuccessSnackbarOpen(true);
        setLocationToDelete(null);
        getLocations();
      }
    } catch (e) {
      logger.error(e.message);
    }
    setDeleting(false);
  }

  useEffect(() => {
    getLocations(searchQuery);
  }, [searchQuery, selectedDate]);

  return (
    <Layout hideFooter>
      <Locations
        locations={locations}
        date={selectedDate}
        onSelectDate={setSelectedDate}
        locationsSchedule={locationSchedules}
        staffList={staffList}
        searchQuery={searchQuery}
        highlightedStaff={highlightedStaff}
        setHighlightedStaff={setHighlightedStaff}
        onLocationScheduleSaveSuccess={onLocationScheduleSaveSuccess}
        //locationsSchedule={locationSchedules}
        onChangeSearchQuery={setSearchQuery}
        onSelectLocation={useCallback(locationId => history?.push(`locations/${locationId}`), [
          history,
        ])}
        onDialogSaveSuccess={onDialogSaveSuccess}
        onNewLocationClick={useCallback(() => setOpen(true), [])}
        onSendSchedulesClick={() => setOpenSendScheduleDialog(true)}
        onDeleteLocationClick={(location): void => setLocationToDelete(location)}
        onEditLocationClick={(location): void => {
          if (!location) return;
          setLocationToEditId(location.id);
          setEditOpen(true);
        }}
        updateUrlDate={day =>
          history?.replace(`/locations/${day.format(DateTimeFormatString.APIDateFormat)}`)
        }
      />

      <SendScheduleDialog
        open={openSendScheduleDialog}
        staffList={staffList}
        onClose={(): void => setOpenSendScheduleDialog(false)}
        onSaveSuccess={onSaveSuccess}
      />

      <NewLocationDialogContainer
        open={open}
        onClose={(): void => setOpen(false)}
        onSaveSuccess={onSaveSuccess}
      />

      <EditLocationDialogContainer
        locationId={locationToEditId}
        open={editOpen}
        onClose={(): void => {
          setLocationToEditId('');
          setEditOpen(false);
        }}
        onSaveSuccess={getLocations}
        isValidUser={isValidUser}
      />

      <DeleteConfirmationDialog
        itemToDelete={locationToDelete}
        loading={deleting}
        onClose={(): void => setLocationToDelete(null)}
        onConfirm={deleteLocation}
        deleteConfirmDialogType={DeleteConfirmationDialogType.Locations}
      />

      <SuccessSnackBar
        open={isSuccessSnackbarOpen}
        setOpen={setSuccessSnackbarOpen}
        message={successSnackbarMessage}
      />
    </Layout>
  );
}

export default LocationsContainer;
