import React, { ReactElement } from 'react';

import Dialog from 'components/Dialog';
import styles from './CreateNewSetOfFormsConfirmationDialogStyles.module.scss';
import Button from '@material-ui/core/Button';

interface CreateNewSetOfFormsConfirmationDialogProps {
  show?: boolean;
  onClose: () => void;
  onConfirm: () => void;
  reviewReport: () => void;
}

function CreateNewSetOfFormsConfirmationDialog({
  show,
  onClose,
  onConfirm,
  reviewReport,
}: CreateNewSetOfFormsConfirmationDialogProps): ReactElement {
  return (
    <Dialog open={show} onClose={onClose} title={`Create New Sets of Forms`}>
      <div className={styles.container}>
        <div className={styles.text}>
          Warning: Creating a New Set of forms will link The Review Report to the new forms. Please
          take a moment to generate the current report if you would like a PDF copy. Would you like
          to continue anyway ?
        </div>
        <div className={styles.buttons}>
          <Button onClick={reviewReport}>Review Report</Button>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={onConfirm}>Continue</Button>
        </div>
      </div>
    </Dialog>
  );
}

export default CreateNewSetOfFormsConfirmationDialog;
