import { ACCESS_TOKEN } from 'lib/const';

export interface HttpHeader {
  Authorization?: string;
  [other: string]: any;
}

export function getDefaultHeader(customToken?: string): HttpHeader {
  const token = localStorage.getItem(ACCESS_TOKEN);

  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${customToken ? customToken : token}`,
  };
}
