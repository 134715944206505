import React, { ReactElement } from 'react';
import { DateLocalizer } from 'react-big-calendar';
import moment from 'moment';
import classNames from 'classnames';

import styles from './WeekHeader.module.scss';

interface WeekHeaderProps {
  currentDate?: Date;
  date?: Date;
  label?: string;
  localizer?: DateLocalizer;
}

function WeekHeader({ currentDate, date }: WeekHeaderProps): ReactElement | null {
  const momentDate = moment(date);
  const momentCurrentDate = moment(currentDate);
  const isActive = momentDate.day() === momentCurrentDate.day();

  const dayClassName = classNames({
    [styles.day]: true,
    [styles.active]: isActive,
  });

  const monthClassName = classNames({
    [styles.month]: true,
    [styles.active]: isActive,
  });

  return (
    <div className={styles.container}>
      <div className={dayClassName}>{momentDate.date()}</div>
      <div className={monthClassName}>{momentDate.format('ddd')}</div>
    </div>
  );
}

export default WeekHeader;
