export const taxRegions = [
  { id: 'ON', value: 'Ontario' },
  { id: 'QC', value: 'Quebec' },
  { id: 'NS', value: 'Nova Scotia' },
  { id: 'NB', value: 'New Brunswick' },
  { id: 'MB', value: 'Manitoba' },
  { id: 'BC', value: 'British Columbia' },
  { id: 'PE', value: 'Prince Edward Island' },
  { id: 'SK', value: 'Saskatchewan' },
  { id: 'AB', value: 'Alberta' },
  { id: 'NL', value: 'Newfoundland and Labrador' },
];
