import React, { ReactElement, useEffect, useState } from 'react';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { useFormik } from 'formik';
import styles from './SendDocumentDialogStyles.module.scss';
import {
  Document as DocumentObj,
  FaxFields,
  MultipleNewDocuments,
  NewDocument,
  PatientInfo,
  User,
} from 'types';
import Dialog from 'components/Dialog';
import DialogButton from 'components/DialogButton';
import { MIME_TYPE_PDF, SEND_TYPES, SEND_TYPE_LIST } from 'lib/document';

import { DocumentsApiHookInterface } from '../../../../useDocumentsApi.hook';
import SendDocumentDialogValidator from './SendDocumentDialog.validator';
import { MenuItem } from '@material-ui/core';
import Select from 'components/Select';
import { PDFDownloadLink } from '@react-pdf/renderer';
import useFaxApi from 'hook/useFaxApi.hook';
import useNewFaxPDFRender from 'pages/Fax/components/NewFaxDialog/useNewFaxPDFRender.hook';
import useNewFaxPDFActions from 'pages/Fax/components/NewFaxDialog/useFaxActions.hook';
import InfoRow from 'components/InfoRow';
import { AlertDialog } from 'components/AlertDialog';
import { handleAPIErrors } from 'lib/handleAPIErrors';

interface SendDocumentDialogProps {
  open?: boolean;
  onClose?: () => void;
  selectedDocuments: DocumentObj[];
  documentsApiHook: DocumentsApiHookInterface;
  familyPhysicianEmail: string | undefined;
  familyPhysicianFax: string | undefined;
  patientEmail: string;
  patientName: string;
  patientId: string | number;
  patient: User | null;
  toggle: (boolean) => void;
}

function SendDocumentDialog({
  open,
  onClose,
  selectedDocuments,
  documentsApiHook,
  familyPhysicianEmail,
  familyPhysicianFax,
  patientName,
  patientEmail,
  patientId,
  patient,
  toggle,
}: SendDocumentDialogProps): ReactElement {
  const [loading, setloading] = useState<boolean>(false);
  const [openAlertDialog, setOpenAlertDialog] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const { sendDocument, sendMultipleEmailDocuments } = documentsApiHook;
  const [sendType, setSendType] = useState<string>(SEND_TYPES.EMAIL);
  const [fieldValues, setFieldValues] = useState<FaxFields>({
    title: '',
    faxNumber: '',
    note: '',
    recipientName: '',
    clinicName: '',
    patientName: '',
    dateOfBirth: '',
    healthcard: '',
  });

  const {
    setCoverLetterURL,
    previewPdfDocument,
    coverLetterURL,
    createFaxWithCoverLetter,
  } = useNewFaxPDFActions();
  const { CoverLetterLayout } = useNewFaxPDFRender(fieldValues);
  const { sendMultipleFaxDocuments } = useFaxApi();
  const getSelectedDocumentIds = () => {
    return selectedDocuments.map(doc => doc.id);
  };

  const createCoverLetterAndSendFax = async (
    selectedDocumentIds: (string | number)[],
    faxNumber: string
  ) => {
    const coverLetterS3Details = await createFaxWithCoverLetter(
      selectedDocumentIds,
      '',
      faxNumber,
      fieldValues.recipientName
    );
    await sendMultipleFaxDocuments(selectedDocumentIds, coverLetterS3Details.startingId, faxNumber);
  };

  const formik = useFormik({
    initialValues: {
      email: patient?.patientInfo?.familyPhysicianEmail,
      note: '',
      fax: patient?.patientInfo?.familyPhysicianFax,
      sendType: sendType,
    },
    validate: SendDocumentDialogValidator,
    onSubmit: async values => {
      setloading(true);
      try {
        const selectedDocumentIds = getSelectedDocumentIds();
        if (sendType == SEND_TYPES.EMAIL) {
          sendMultipleEmailDocuments(selectedDocumentIds, values.email, undefined, values.note);
        } else if (sendType == SEND_TYPES.FAX) {
          createCoverLetterAndSendFax(selectedDocumentIds, fieldValues.faxNumber);
        }
        toggle(false);
      } catch (err) {
        const message = handleAPIErrors(err);
        setErrorMessage(message);
        setOpenAlertDialog(true);
      }
      setloading(false);
    },
  });

  const resetState = () => {
    setSendType(SEND_TYPES.EMAIL);
    formik.resetForm();
  };

  const handlePreview = (): void => {
    //if (validateForm(true)) {
    previewPdfDocument();
    //}
  };

  useEffect(() => {
    formik.setFieldValue('sendType', sendType);
  }, [sendType]);

  useEffect(() => {
    if (open) {
      setFieldValues({
        ...fieldValues,
        faxNumber: formik.values.fax ? formik.values.fax : '',
        dateOfBirth: patient?.patientInfo?.dateOfBirth ? patient.patientInfo.dateOfBirth : '',
        healthcard: patient?.patientInfo?.healthCard ? patient.patientInfo.healthCard : '',
        patientName: patient?.fullName ? patient.fullName : '',
      });
    } else {
      resetState();
      setFieldValues({
        title: '',
        faxNumber: '',
        note: '',
        recipientName: '',
        clinicName: '',
        patientName: '',
        dateOfBirth: '',
        healthcard: '',
      });
    }
  }, [open]);

  function sendToPatient(): void {
    const selectedDocumentIds = getSelectedDocumentIds();
    sendMultipleEmailDocuments(selectedDocumentIds, patientEmail, patientId, formik.values.note);
    toggle(false);
  }

  function sendToFamilyDoctor(): void {
    if (patient?.patientInfo?.familyPhysicianFax) {
      const selectedDocumentIds = getSelectedDocumentIds();
      createCoverLetterAndSendFax(selectedDocumentIds, patient?.patientInfo?.familyPhysicianFax)
        .then(() => {
          toggle(false);
        })
        .catch(error => {
          console.error('error: ', error);
        });
    }
  }

  return (
    <Dialog open={open} title="Send Document" onClose={onClose}>
      <div className={styles.padding}>
        <div className={styles.details}>
          <div className={styles.info}>
            <InfoRow label={'Patient'} value={patient?.fullName} />
          </div>
          <div className={styles.info}>
            <InfoRow label={'D.O.B.'} value={patient?.patientInfo?.dateOfBirth} />
          </div>
          <div className={styles.info}>
            <InfoRow label={'Health Card'} value={patient?.patientInfo?.healthCard} />
          </div>
          <InfoRow label={`Documents`} value={''} />
          {selectedDocuments.map((doc, index) => (
            <div key={doc.id} className={styles.document}>
              <InfoRow label={`${index + 1}`} value={doc.title} />
            </div>
          ))}
        </div>

        <Grid container className={styles.options}>
          <Grid item sm={12} xs={12}>
            <div className={styles.padding}>
              <Select
                fullWidth
                label="Communication Type"
                name={'communicationType'}
                renderValue={value => <div className={styles.selectItem}>{value}</div>}
                onChange={event => setSendType(event.target.value)}
                value={sendType}
              >
                {SEND_TYPE_LIST.map(item => (
                  <MenuItem key={item} value={item} className={styles.selectItem}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </Grid>
          <Grid item sm={12} xs={12}>
            <div className={styles.padding}>
              {sendType == SEND_TYPES.FAX && (
                <TextField
                  fullWidth
                  name={'receiptient'}
                  label="Receipent Name"
                  placeholder="Enter Receipient Name"
                  onChange={event =>
                    setFieldValues({ ...fieldValues, recipientName: event.target.value })
                  }
                  value={fieldValues.recipientName}
                  type="text"
                />
              )}
            </div>
          </Grid>
          <Grid item sm={12} xs={12}>
            <div className={styles.padding}>
              {sendType == SEND_TYPES.FAX && (
                <TextField
                  fullWidth
                  name={'clinicName'}
                  label="Clinic Name"
                  placeholder="Enter Clinic Name"
                  onChange={event =>
                    setFieldValues({ ...fieldValues, clinicName: event.target.value })
                  }
                  value={fieldValues.clinicName}
                  type="text"
                />
              )}
            </div>
          </Grid>
          <Grid item sm={12} xs={12}>
            <div className={styles.padding}>
              <TextField
                error={
                  formik.values.note && formik.values.note.length > 0 && !!formik.errors.note
                    ? true
                    : false
                }
                fullWidth
                name={'note'}
                label="Note"
                placeholder="Enter note to be sent"
                onChange={event => {
                  formik.handleChange(event);
                  setFieldValues({ ...fieldValues, note: event.target.value });
                }}
                value={formik.values.note}
                helperText={
                  formik.values.note && formik.values.note.length > 0 && !!formik.errors.note
                    ? formik.errors?.note
                    : ''
                }
                multiline
                rows={3}
              />
            </div>
          </Grid>

          <Grid item sm={12} xs={12}>
            <div className={styles.padding}>
              {sendType == SEND_TYPES.EMAIL ? (
                <TextField
                  error={
                    formik.values.email && formik.values.email.length > 0 && !!formik.errors.email
                      ? true
                      : false
                  }
                  fullWidth
                  name={'email'}
                  label="Email"
                  placeholder="Enter destination email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  helperText={
                    formik.values.email && formik.values.email.length > 0 && !!formik.errors.email
                      ? formik.errors?.email
                      : ''
                  }
                  type="email"
                />
              ) : (
                <TextField
                  error={
                    !!(formik.values.fax && formik.values.fax.length > 0 && !!formik.errors.fax)
                  }
                  fullWidth
                  name={'fax'}
                  label="Fax Number"
                  placeholder="Enter fax number"
                  onChange={event => {
                    formik.handleChange(event);
                    setFieldValues({ ...fieldValues, faxNumber: event.target.value });
                  }}
                  value={formik.values.fax}
                  helperText={
                    formik.values.fax && formik.values.fax.length > 0 && !!formik.errors.fax
                      ? formik.errors?.fax
                      : ''
                  }
                  type="fax"
                />
              )}
            </div>
          </Grid>
        </Grid>
        <div className={classNames(styles.padding, styles.buttons)}>
          <DialogButton onClick={onClose}>Cancel</DialogButton>
          <DialogButton
            disabled={
              sendType == SEND_TYPES.EMAIL ? !formik.values.email : !formik.values.fax || loading
            }
            className={styles.marginLeft}
            onClick={formik.handleSubmit}
            loading={loading}
          >
            Send
          </DialogButton>
          {sendType === SEND_TYPES.FAX && patient?.patientInfo?.familyPhysicianFax && (
            <DialogButton
              loading={loading}
              disabled={loading}
              className={styles.marginLeft}
              onClick={sendToFamilyDoctor}
            >
              Send To Family Doctor
            </DialogButton>
          )}
          {sendType == SEND_TYPES.EMAIL ? (
            <DialogButton
              loading={loading}
              disabled={loading}
              className={styles.marginLeft}
              onClick={sendToPatient}
            >
              Send To Patient
            </DialogButton>
          ) : (
            <DialogButton disabled={loading} className={styles.marginLeft} onClick={handlePreview}>
              Preview Cover Letter
            </DialogButton>
          )}
        </div>
      </div>
      <PDFDownloadLink document={CoverLetterLayout()}>
        {({ blob, url, loading, error }) => {
          if (url) {
            setCoverLetterURL(url);
          }
          return <div></div>;
        }}
      </PDFDownloadLink>
      <AlertDialog
        open={openAlertDialog}
        message={errorMessage}
        title={'Error Sending Documents'}
        onClose={() => setOpenAlertDialog(false)}
      />
    </Dialog>
  );
}

export default SendDocumentDialog;
