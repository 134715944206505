import Button from 'components/Button';
import CVCButton from 'components/CVCButton';
import React, { ReactElement, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { CSVHeader } from 'types';
import styles from './CSVDownload.module.scss';

interface CSVDownloadProps {
  values: Array<any>;
  headers: Array<CSVHeader>;
  title?: string;
  filename?: string;
  disabled?: boolean;
}

function CSVDownload({
  values,
  headers,
  title = 'Download',
  filename = 'download.csv',
  disabled,
}: CSVDownloadProps): ReactElement {
  return (
    <Button disabled={disabled}>
      <CSVLink data={values} headers={headers} filename={filename}>
        <div className={disabled ? styles.titleDisabled : styles.titleEnabled}>{title}</div>
      </CSVLink>
    </Button>
  );
}

export default CSVDownload;
