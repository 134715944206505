import axios, { CancelTokenSource } from 'axios';
import {
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  GET_USERS,
  LOGIN,
  REFRESH_TOKEN as REFRESH_TOKEN_URL,
  CREATE_PATIENT,
  CREATE_STAFF,
  GET_PATIENTS_LIST,
  GET_STAFF,
  GET_AVAILABLE_STAFF,
  GET_OTHER_PHYSICIANS_LIST,
  CREATE_OTHER_PHYSICIAN,
  SIGNATURE_DOWNLOAD,
} from 'lib/config';
import { ACCESS_TOKEN, REFRESH_TOKEN } from 'lib/const';
import { MIME_TYPE_IMAGE } from 'lib/document';
import {
  fromGetUserResponse,
  transformUserScheduleResponse,
  transformOneUser,
  fromGetOtherPhysicianResponse,
  fromGetOtherPhysicianFromIdResponse,
} from './user.transformer';
import { getDefaultHeader } from '../utils';
import { Schedule, Tokens, UserType } from './user.interfaces';
import {
  User,
  StringValueItem,
  EditableUser,
  DoctorSchedule,
  ContactType,
  OtherPhysician,
  PossiblePatient,
  DownloadDocumentType,
  StaffNote,
} from 'types';
import { Staff } from './user.interfaces';
import moment from 'moment';
import { DateTimeFormatString } from 'lib/dateFormatter';
import * as logger from 'api/logger';

export async function login(email: string, password: string): Promise<Tokens> {
  const response = await axios.post(LOGIN, {
    email,
    password,
  });

  localStorage.setItem(ACCESS_TOKEN, response.data.authToken);
  localStorage.setItem(REFRESH_TOKEN, response.data.refreshToken);

  return response.data;
}

export async function refreshToken(): Promise<Tokens> {
  const token = localStorage.getItem(REFRESH_TOKEN);

  const response = await axios.post(
    REFRESH_TOKEN_URL,
    {},
    {
      headers: getDefaultHeader(token ? token : undefined),
    }
  );

  return response.data;
}

export async function forgotPassword(email: string): Promise<void> {
  await axios.post(FORGOT_PASSWORD, {
    email,
  });
}

export async function resetPasswordFromLink(
  email: string,
  password: string,
  resetKey: string
): Promise<void> {
  await axios.post(
    RESET_PASSWORD,
    { password },
    {
      params: {
        email,
        resetKey,
      },
    }
  );
}

export async function changePassword(
  userId: number | null,
  password: string,
  oldpassword: string
): Promise<void> {
  await axios.post(
    `${GET_USERS}/${userId}/changePassword`,
    {
      password: password,
      oldpassword: oldpassword,
    },
    {
      headers: getDefaultHeader(),
    }
  );
}

export async function getUsers(
  searchName = '',
  userType = '',
  locationId: string | null = null
): Promise<Array<User>> {
  const response = await axios(GET_USERS, {
    method: 'GET',
    params: {
      searchName,
      userType,
      locationId,
    },
    headers: getDefaultHeader(),
    transformResponse: fromGetUserResponse,
  });
  return response.data;
}

export async function getPatientList(
  searchQuery = '',
  userType = '',
  locationId: number | null = null,
  appointmentTypeId: string | number | null = null,
  appointmentStartDate: string | null = null,
  cancellationList: true | null = null,
  cancelTokenSource?: CancelTokenSource
): Promise<Array<User>> {
  const response = await axios(GET_PATIENTS_LIST, {
    method: 'GET',
    params: {
      searchQuery,
      userType,
      locationId,
      appointmentTypeId,
      appointmentStartDate,
      cancellationList,
    },
    headers: getDefaultHeader(),
    transformResponse: fromGetUserResponse,
    cancelToken: cancelTokenSource?.token,
  });

  return response.data;
}

export async function getPossiblePatients(params): Promise<Array<PossiblePatient>> {
  const response = await axios(`${GET_USERS}/possiblePatients`, {
    method: 'GET',
    params: params,
    headers: getDefaultHeader(),
  });

  return response.data;
}

export async function getOtherPhysiciansList(
  searchQuery: string | null = null
): Promise<Array<OtherPhysician>> {
  const response = await axios(GET_OTHER_PHYSICIANS_LIST, {
    method: 'GET',
    params: {
      searchQuery: searchQuery,
    },
    headers: getDefaultHeader(),
    transformResponse: fromGetOtherPhysicianResponse,
  });

  return response.data;
}
export async function getOtherPhysicianById(otherPhysicianId: number): Promise<OtherPhysician> {
  const response = await axios(`${GET_OTHER_PHYSICIANS_LIST}/${otherPhysicianId}`, {
    method: 'GET',
    headers: getDefaultHeader(),
    transformResponse: fromGetOtherPhysicianFromIdResponse,
  });

  return response.data;
}

export async function getStaff(
  searchName = '',
  userType = '',
  locationId: string | number | null = null,
  staffId: number | null = null,
  signatureApprovedUserId: number | null = null
): Promise<Array<User>> {
  const response = await axios(GET_STAFF, {
    method: 'GET',
    params: {
      searchName,
      userType,
      locationId,
      staffId,
      signatureApprovedUserId,
    },
    headers: getDefaultHeader(),
  });

  if (response.status !== 401) return response.data.map(transformOneUser);

  return response.data;
}

export async function getUser(userId: number, trackAccessTime = false): Promise<User> {
  const response = await axios.get(`${GET_USERS}/${userId}`, {
    params: { trackAccessTime },
    headers: getDefaultHeader(),
  });
  return response.data;
}

const putFile = async (endpoint: string, data: any) => {
  return axios.put(endpoint, data, { headers: { 'Content-Type': MIME_TYPE_IMAGE } });
};

export async function updateUser(id: number, data: EditableUser): Promise<User> {
  const response = await axios.put(`${GET_USERS}/${id}`, data, {
    headers: getDefaultHeader(),
  });
  const uploadUrl = response.data.signatureSignedRequest;
  if (uploadUrl) {
    await putFile(uploadUrl, data.signatureFile);
  }
  return response.data;
}

export async function downloadSignature(
  s3Key: string,
  doctorId: number
): Promise<DownloadDocumentType> {
  const response = await axios.get(SIGNATURE_DOWNLOAD, {
    params: {
      s3Key,
      doctorId,
    },
    headers: getDefaultHeader(),
  });
  return response.data;
}

export async function createOtherPhysician(data): Promise<OtherPhysician> {
  const response = await axios.post(`${CREATE_OTHER_PHYSICIAN}`, data, {
    headers: getDefaultHeader(),
    transformResponse: fromGetOtherPhysicianFromIdResponse,
  });

  return response.data;
}

export async function updateOtherPhysician(
  otherPhysicianId: number,
  data
): Promise<OtherPhysician> {
  const response = await axios.put(`${CREATE_OTHER_PHYSICIAN}/${otherPhysicianId}`, data, {
    headers: getDefaultHeader(),
    transformResponse: fromGetOtherPhysicianFromIdResponse,
  });

  return response.data;
}

export async function deletePatient(userId: number): Promise<void> {
  await axios(`${GET_USERS}/patients/${userId}`, {
    method: 'DELETE',
    headers: getDefaultHeader(),
  });
}

export async function deleteStaff(
  userId: number,
  date: string = moment().format(DateTimeFormatString.APIDateFormat)
): Promise<void> {
  await axios(`${GET_USERS}/staff/${userId}`, {
    method: 'DELETE',
    headers: getDefaultHeader(),
    params: { date },
  });
}

export async function getUserTypes(): Promise<Array<UserType>> {
  const response = await axios.get(`${GET_USERS}/types`, {
    headers: getDefaultHeader(),
  });

  return response.data;
}

export async function updateProfile(id: number, data: EditableUser): Promise<User> {
  const patientBody = {
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    contacts: [
      {
        id: data.contactId,
        type: ContactType.Phone,
        value: data.phone,
      },
    ],
    staffInfo: {
      ohipPhysicianId: data.ohipPhysicianId,
      abbreviations: data.abbreviations,
      signatureApprovedIds: data.signatureApprovedUsers,
      mimeType: data.signatureFile?.type,
      fileName: data.signatureFile?.name,
      removeSignature: data.removeSignature,
    },
    signatureFile: data.signatureFile,
  };

  return updateUser(id, patientBody);
}

export async function updatePatient(id: number, data: any): Promise<User> {
  return updateUser(id, data);
}

export async function getUserSchedule(
  userId: number,
  startDate: string | null = null,
  endDate: string | null = null,
  locationId: number | null = null
): Promise<Array<Schedule>> {
  const response = await axios(`${GET_USERS}/${userId}/schedule`, {
    method: 'GET',
    params: {
      startDate,
      endDate,
      locationId,
    },
    headers: getDefaultHeader(),
    transformResponse: transformUserScheduleResponse,
  });

  return response.data;
}

export async function getProviderTypes(): Promise<Array<StringValueItem>> {
  return [
    { id: 2, name: 'Doctor' },
    { id: 3, name: 'Technician' },
  ];
}

export async function getIndividualSchedule(
  userId: number,
  scheduleId: number
): Promise<DoctorSchedule> {
  const response = await axios.get(`${GET_USERS}/${userId}/schedule/${scheduleId}`, {
    headers: getDefaultHeader(),
  });

  return response.data;
}

export async function getStaffSchedules(userId: number, params): Promise<Array<DoctorSchedule>> {
  const response = await axios.get(`${GET_USERS}/${userId}/staffSchedules`, {
    params,
    headers: getDefaultHeader(),
  });

  return response.data;
}

export async function getStaffNotes(staffId): Promise<StaffNote> {
  const response = await axios.get(`${GET_STAFF}/${staffId}`, {
    headers: getDefaultHeader(),
  });

  return response.data;
}

export async function sendSchedule(userId: number): Promise<void> {
  const response = await axios.post(
    `${GET_USERS}/${userId}/sendSchedule`,
    {},
    {
      headers: getDefaultHeader(),
    }
  );

  return response.data;
}

export async function createSchedule(
  userId: number,
  schedule: DoctorSchedule
): Promise<Array<DoctorSchedule>> {
  const response = await axios.post(`${GET_USERS}/${userId}/schedule`, schedule, {
    headers: getDefaultHeader(),
  });

  return response.data;
}

export async function updateSchedule(
  userId: number,
  schedule: DoctorSchedule
): Promise<Array<DoctorSchedule>> {
  const response = await axios.put(`${GET_USERS}/${userId}/schedule`, schedule, {
    headers: getDefaultHeader(),
  });

  return response.data;
}

export async function deleteSchedule(scheduleId: number, body): Promise<Array<DoctorSchedule>> {
  const response = await axios.delete(`${GET_USERS}/${scheduleId}/schedule`, {
    headers: getDefaultHeader(),
    data: body,
  });

  return response.data;
}

export async function deleteException(
  userId: number,
  scheduleId: number,
  startDate
): Promise<void> {
  const response = await axios.delete(`${GET_USERS}/${scheduleId}/scheduleException`, {
    data: {
      startDate,
      userId,
    },
    headers: getDefaultHeader(),
  });

  return response.data;
}

export async function createPatient(data): Promise<User | null> {
  try {
    const response = await axios.post(`${CREATE_PATIENT}`, data, { headers: getDefaultHeader() });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function createStaff(data): Promise<User | null> {
  try {
    const response = await axios(`${CREATE_STAFF}`, {
      method: 'POST',
      data,
      headers: getDefaultHeader(),
    });

    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function updateStaff(staffId, data): Promise<User | null> {
  try {
    const response = await axios(`${GET_USERS}/${staffId}`, {
      method: 'PUT',
      data,
      headers: getDefaultHeader(),
    });

    return response.data;
  } catch (error) {
    logger.error(error.message);
    throw error;
  }
}

export async function updateStaffNote(staffId, data): Promise<void> {
  try {
    const response = await axios.put(`${GET_STAFF}/${staffId}`, data, {
      headers: getDefaultHeader(),
    });

    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getAvailableStaff(data): Promise<Array<Staff | null>> {
  try {
    const response = await axios(`${GET_AVAILABLE_STAFF}`, {
      method: 'GET',
      params: data,
      headers: getDefaultHeader(),
    });

    return response.data;
  } catch (error) {
    throw error;
  }
}
