import React, { Fragment, ReactElement } from 'react';
import { Route, match } from 'react-router-dom';

import FaxReceived from 'pages/Fax/components/FaxReceived/FaxReceived.container';
import FaxSent from 'pages/Fax/components/FaxSent/FaxSent.container';

interface FaxManagementRoutingProps {
  match?: match;
}

function FaxManagementRouting({ match }: FaxManagementRoutingProps): ReactElement {
  const path = match?.path && match.path !== '/' ? match.path : '';

  return (
    <Fragment>
      <Route exact path={`${path || '/'}`} component={FaxReceived} />
      <Route path={`${path}/sent`} component={FaxSent} />
    </Fragment>
  );
}

export default FaxManagementRouting;
