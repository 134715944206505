import React, { ReactElement, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Dialog from 'components/Dialog';
import DialogButton from 'components/DialogButton';
import Button from 'components/CVCButton';

import styles from './AddNewDocumentDialogStyles.module.scss';
import Select from 'components/Select';
import { MenuItem } from '@material-ui/core';
import { DocumentType, NewDefaultDocument, NewDocument } from 'types';
import Checkbox from 'components/Checkbox';

interface AddDocumentDialogProps {
  open?: boolean;
  categories?: Array<DocumentType>;
  loading?: boolean;
  onClose?: () => void;
  onSave: (date: NewDefaultDocument) => Promise<void>;
}

function AddDocumentDialog({
  open,
  categories = [],
  onClose,
  onSave,
  loading,
}: AddDocumentDialogProps): ReactElement {
  const [title, setTitle] = useState<string>();
  const [category, setCategory] = useState<DocumentType>();
  const [document, setDocument] = useState<any>();
  const [needsStamp, setNeedsStamp] = useState<boolean>(false);
  const fileInput = useRef<any>(null);

  const onSaveDocument = async () => {
    if (!title || !category || !document) return;
    const data = {
      title,
      type: category,
      document: document,
      needsStamp: needsStamp,
    };
    await onSave(data);
  };

  const handleFileChange = (event): void => {
    event.stopPropagation();
    event.preventDefault();
    const selectedFile = event.target.files[0];
    setDocument(selectedFile);
  };

  useEffect(() => {
    if (!title && document?.name) setTitle(document?.name);
  }, [document]);

  useEffect(() => {
    if (!open) {
      setTitle(undefined);
      setCategory(undefined);
      setDocument(undefined);
      setNeedsStamp(false);
    }
  }, [open]);

  const handleCategoryChange = event => {
    const category = categories.find(category => category.id == event.target.value);
    setCategory(category);
  };

  return (
    <Dialog open={open} title="New Default Document" onClose={onClose}>
      <div className={styles.padding}>
        <Grid container>
          <Grid item sm={12} xs={12}>
            <div className={styles.padding}>
              <TextField
                fullWidth
                name={'title'}
                placeholder="Title"
                onChange={e => setTitle(e.target.value)}
                value={title}
              />
            </div>
          </Grid>
          <Grid item sm={12} xs={12}>
            <div className={styles.padding}>
              <Button onClick={(): void => fileInput?.current?.click()}>{'Select PDF file'}</Button>

              <input
                type="file"
                ref={fileInput}
                style={{ display: 'none' }}
                name="document"
                onChange={handleFileChange}
                accept={'.pdf'}
              />
              <div className={styles.fileName}>{document?.name || ''}</div>
            </div>
          </Grid>
          <Grid item sm={12} xs={12}>
            <div className={styles.padding}>
              <Select
                label="Category"
                name={'category'}
                fullWidth
                onChange={handleCategoryChange}
                value={category ? category.id : ''}
              >
                {categories.map(category => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.type}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </Grid>
          <Checkbox
            title="Needs Stamp"
            name={'needsStamp'}
            checked={needsStamp}
            onChange={event => setNeedsStamp(event.target.checked)}
            className={styles.checkbox}
          />
        </Grid>

        <div className={classNames(styles.padding, styles.buttons)}>
          <DialogButton onClick={onClose}>Cancel</DialogButton>
          <DialogButton className={styles.marginLeft} loading={loading} onClick={onSaveDocument}>
            Create Document
          </DialogButton>
        </div>
      </div>
    </Dialog>
  );
}

export default AddDocumentDialog;
