export enum CanadaProvinceCodes {
  NL = 'NL',
  PE = 'PE',
  NS = 'NS',
  NB = 'NB',
  QC = 'QC',
  ON = 'ON',
  MB = 'MB',
  SK = 'SK',
  AB = 'AB',
  BC = 'BC',
  YT = 'YT',
  NT = 'NT',
  NU = 'NU',
}

export const PROVINCES = [
  CanadaProvinceCodes.AB,
  CanadaProvinceCodes.BC,
  CanadaProvinceCodes.MB,
  CanadaProvinceCodes.NB,
  CanadaProvinceCodes.NL,
  CanadaProvinceCodes.NT,
  CanadaProvinceCodes.NS,
  CanadaProvinceCodes.NU,
  CanadaProvinceCodes.ON,
  CanadaProvinceCodes.PE,
  CanadaProvinceCodes.QC,
  CanadaProvinceCodes.SK,
  CanadaProvinceCodes.YT,
];

export enum CanadaProvinceFullName {
  AB = 'Alberta',
  BC = 'British Columbia',
  MB = 'Manitoba',
  NB = 'New Brunswick',
  NL = 'Newfoundland and Labrador',
  NT = 'Northwest Territories',
  NS = 'Nova Scotia',
  NU = 'Nunavut',
  ON = 'Ontario',
  PE = 'Prince Edward Island',
  QC = 'Quebec',
  SK = 'Saskatchewan',
  YT = 'Yukon',
}
