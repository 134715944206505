import { UserApi } from 'api';
import formatDate, { DateTimeFormat } from 'lib/dateFormatter';
import moment from 'moment';
import useWorkflowResultApi from 'pages/WorkflowResult/useWorkflowApi.hook';
import React, { useEffect, useState } from 'react';
import { User } from 'types';

interface PrescriptionResultPDFFragmentProps {
  formId?: number;
  caseId?: number;
  medical?: boolean;
}

function usePrescriptionResultPDFFragment({
  formId,
  caseId,
  medical,
}: PrescriptionResultPDFFragmentProps) {
  const { formCaseResult } = useWorkflowResultApi(formId, caseId);
  const [formResults, setFormResults] = useState<any>();

  const medicalPrescriptionForm = 'Medical Prescription Form';
  const medicalCompressionPrescriptionForm = 'Medical Compression Prescription Form';
  useEffect(() => {
    const answers = formCaseResult?.answers;
    if (answers) {
      const data = {
        patient: {
          fullName: answers.find(ans => ans.id == `PatientMetaData::fullName`)?.value as string,
          dateOfBirth: answers.find(ans => ans.id == `PatientMetaData::dateOfBirth`)
            ?.value as string,
          hcn: answers.find(ans => ans.id == `PatientMetaData::healthCard`)?.value as string,
          cellPhone: answers.find(ans => ans.id == `PatientMetaData::contactValue`)
            ?.value as string,
          homePhone: answers.find(ans => ans.id == `PatientMetaData::contact1Value`)
            ?.value as string,
          phone: answers.find(ans => ans.id == `PatientMetaData::contact2Value`)?.value as string,
        },
        date: formatDate(DateTimeFormat.DayMonthYear, moment()),

        doctor: answers.find(ans =>
          medical
            ? ans.id == `${medicalPrescriptionForm}::medicalDoctor`
            : ans.id == `${medicalCompressionPrescriptionForm}::doctor`
        )?.value as string,
        billing: answers.find(ans =>
          medical
            ? ans.id == `${medicalPrescriptionForm}::medicalBilling`
            : ans.id == `${medicalCompressionPrescriptionForm}::billing`
        )?.value as string,
        fax: answers.find(ans =>
          medical
            ? ans.id == `${medicalPrescriptionForm}::medicalFax`
            : ans.id == `${medicalCompressionPrescriptionForm}::fax`
        )?.value as string,

        prescriptionFor: answers.find(ans =>
          medical
            ? ans.id == `${medicalPrescriptionForm}::medicalPrescriptionFor`
            : ans.id == `${medicalCompressionPrescriptionForm}::prescriptionFor`
        )?.value as string,
        compressionStockingsPreasureMeasurementTH: answers.find(
          ans =>
            ans.id ==
            `${medicalCompressionPrescriptionForm}::compressionStockingsPreasureMeasurementTH`
        )?.value as string,
        compressionStockingsAmountTH: answers.find(
          ans => ans.id == `${medicalCompressionPrescriptionForm}::compressionStockingsAmountTH`
        )?.value as string,
        compressionStockingsAmountSpecifyTH: answers.find(
          ans =>
            ans.id == `${medicalCompressionPrescriptionForm}::compressionStockingsAmountSpecifyTH`
        )?.value as string,
        compressionStockingsRepeatsTH: answers.find(
          ans => ans.id == `${medicalCompressionPrescriptionForm}::compressionStockingsRepeatsTH`
        )?.value as string,
        compressionStockingsRepeatsSpecifyTH: answers.find(
          ans =>
            ans.id == `${medicalCompressionPrescriptionForm}::compressionStockingsRepeatsSpecifyTH`
        )?.value as string,

        compressionStockingsPreasureMeasurementPH: answers.find(
          ans =>
            ans.id ==
            `${medicalCompressionPrescriptionForm}::compressionStockingsPreasureMeasurementPH`
        )?.value as string,
        compressionStockingsAmountPH: answers.find(
          ans => ans.id == `${medicalCompressionPrescriptionForm}::compressionStockingsAmountPH`
        )?.value as string,
        compressionStockingsAmountSpecifyPH: answers.find(
          ans =>
            ans.id == `${medicalCompressionPrescriptionForm}::compressionStockingsAmountSpecifyPH`
        )?.value as string,
        compressionStockingsRepeatsPH: answers.find(
          ans => ans.id == `${medicalCompressionPrescriptionForm}::compressionStockingsRepeatsPH`
        )?.value as string,
        compressionStockingsRepeatsSpecifyPH: answers.find(
          ans =>
            ans.id == `${medicalCompressionPrescriptionForm}::compressionStockingsRepeatsSpecifyPH`
        )?.value as string,

        compressionStockingsPreasureMeasurementKH: answers.find(
          ans =>
            ans.id ==
            `${medicalCompressionPrescriptionForm}::compressionStockingsPreasureMeasurementKH`
        )?.value as string,
        compressionStockingsAmountKH: answers.find(
          ans => ans.id == `${medicalCompressionPrescriptionForm}::compressionStockingsAmountKH`
        )?.value as string,
        compressionStockingsAmountSpecifyKH: answers.find(
          ans =>
            ans.id == `${medicalCompressionPrescriptionForm}::compressionStockingsAmountSpecifyKH`
        )?.value as string,
        compressionStockingsRepeatsKH: answers.find(
          ans => ans.id == `${medicalCompressionPrescriptionForm}::compressionStockingsRepeatsKH`
        )?.value as string,
        compressionStockingsRepeatsSpecifyKH: answers.find(
          ans =>
            ans.id == `${medicalCompressionPrescriptionForm}::compressionStockingsRepeatsSpecifyKH`
        )?.value as string,

        xeraltoAmount: answers.find(ans => ans.id == `${medicalPrescriptionForm}::xeraltoAmount`)
          ?.value as string,
        xeraltoTimesTaken: answers.find(
          ans => ans.id == `${medicalPrescriptionForm}::xeraltoTimesTaken`
        )?.value as string,
        xeraltoTimesTakenSpecify: answers.find(
          ans => ans.id == `${medicalPrescriptionForm}::xeraltoTimesTakenSpecify`
        )?.value as string,
        xeraltoPeriodOfTimeTaken: answers.find(
          ans => ans.id == `${medicalPrescriptionForm}::xeraltoPeriodOfTimeTaken`
        )?.value as string,
        xeraltoPeriodOfTimeTakenSpecify: answers.find(
          ans => ans.id == `${medicalPrescriptionForm}::xeraltoPeriodOfTimeTakenSpecify`
        )?.value as string,
        xeraltoRepeats: answers.find(ans => ans.id == `${medicalPrescriptionForm}::xeraltoRepeats`)
          ?.value as string,
        xeraltoRepeatsSpecify: answers.find(
          ans => ans.id == `${medicalPrescriptionForm}::xeraltoRepeatsSpecify`
        )?.value as string,

        othersName: answers.find(ans =>
          medical
            ? ans.id == `${medicalPrescriptionForm}::medicalOthersName`
            : ans.id == `${medicalCompressionPrescriptionForm}::othersName`
        )?.value as string,
        othersAmount: answers.find(ans =>
          medical
            ? ans.id == `${medicalPrescriptionForm}::medicalOthersAmount`
            : ans.id == `${medicalCompressionPrescriptionForm}::othersAmount`
        )?.value as string,
        othersTimesTaken: answers.find(ans =>
          medical
            ? ans.id == `${medicalPrescriptionForm}::medicalOthersTimesTaken`
            : ans.id == `${medicalCompressionPrescriptionForm}::othersTimesTaken`
        )?.value as string,
        othersTimesTakenSpecify: answers.find(ans =>
          medical
            ? ans.id == `${medicalPrescriptionForm}::medicalOthersTimesTakenSpecify`
            : ans.id == `${medicalCompressionPrescriptionForm}::othersTimesTakenSpecify`
        )?.value as string,
        othersPeriodOfTimeTaken: answers.find(ans =>
          medical
            ? ans.id == `${medicalPrescriptionForm}::medicalOthersPeriodOfTimeTaken`
            : ans.id == `${medicalCompressionPrescriptionForm}::othersPeriodOfTimeTaken`
        )?.value as string,
        othersPeriodOfTimeTakenSpecify: answers.find(ans =>
          medical
            ? ans.id == `${medicalPrescriptionForm}::medicalOthersPeriodOfTimeTakenSpecify`
            : ans.id == `${medicalCompressionPrescriptionForm}::othersPeriodOfTimeTakenSpecify`
        )?.value as string,
        othersRepeats: answers.find(ans =>
          medical
            ? ans.id == `${medicalPrescriptionForm}::medicalOthersRepeats`
            : ans.id == `${medicalCompressionPrescriptionForm}::othersRepeats`
        )?.value as string,
        othersRepeatsSpecify: answers.find(ans =>
          medical
            ? ans.id == `${medicalPrescriptionForm}::medicalOthersRepeatsSpecify`
            : ans.id == `${medicalCompressionPrescriptionForm}::othersRepeatsSpecify`
        )?.value as string,

        diagnostic: answers.find(ans =>
          medical
            ? ans.id == `${medicalPrescriptionForm}::medicalDiagnostic`
            : ans.id == `${medicalCompressionPrescriptionForm}::diagnostic`
        )?.value as string,
        diagnosticSpecify: answers.find(ans =>
          medical
            ? ans.id == `${medicalPrescriptionForm}::medicalDiagnosticSpecify`
            : ans.id == `${medicalCompressionPrescriptionForm}::diagnosticSpecify`
        )?.value as string,
      };

      setFormResults(data);
    }
  }, [formCaseResult]);

  return {
    formResults,
  };
}

export default usePrescriptionResultPDFFragment;
