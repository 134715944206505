import React, { ReactElement } from 'react';
import { Event } from 'react-big-calendar';
import moment from 'moment';
import styles from './MonthEventStyles.module.scss';
import { CalendarType } from 'components/Calendar/Calendar.view';
import ConditionDiv from 'components/ConditionDiv';
import { formatTimePeriod } from 'lib/dateFormatter';

interface MonthEventProps {
  event?: Event;
  [rest: string]: any;
  calendarType?: CalendarType;
}

function renderMonthEventBasedOnCalendarType(calendarType, event) {
  switch (calendarType) {
    case CalendarType.DoctorSchedule:
      return (
        <div>
          <div className={styles.title}>{event?.title}</div>
          <div className={styles.timePeriod}>{formatTimePeriod(event?.start, event?.end)}</div>
          <ConditionDiv condition={event.resource !== 0}>
            <div className={styles.noLongerValid}>/!\ This schedule is no longer valid /!\ </div>
          </ConditionDiv>
        </div>
      );
    case CalendarType.LocationSchedule:
      return (
        <div>
          <div className={styles.title}>{formatTimePeriod(event?.start, event?.end)}</div>
        </div>
      );
  }
}

function MonthEvent({ event, calendarType = 0 }: MonthEventProps): ReactElement {
  return (
    <div className={styles.main}>{renderMonthEventBasedOnCalendarType(calendarType, event)}</div>
  );
}

export default MonthEvent;
