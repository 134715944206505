import React, { ReactElement, useState, useEffect } from 'react';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import { FormLabel } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import Dialog from 'components/Dialog';
import DialogButton from 'components/DialogButton';
import styles from './StaffScheduleDialog.module.scss';
import {
  FrequencyType,
  FREQUENCY,
  getStartTimesArray,
  getEndTimesArray,
  displayDateForDatePicker,
} from 'lib/schedule';
import { ScheduleTypesNumberToString, ScheduleTypesStrings } from 'lib/scheduleTypes';
import { convertTo12From24 } from 'lib/schedule';
import { DoctorSchedule, StaffScheduleValidationError } from 'types';
import { UserApi } from 'api';

import Select from 'components/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { DatePicker } from '@material-ui/pickers';
import Checkbox from 'components/Checkbox';
import { Staff } from 'api/user/user.interfaces';
import { DialogButtonType } from 'components/DialogButton/DialogButton.view';
import {
  getAvailableStaff,
  getIndividualSchedule,
  getStaffSchedules,
  deleteException,
} from 'api/user';
import { ValidationError } from 'components/Forms/ValidationError';
import { ROLE_ID } from 'lib/user';
import formatDate, { DateTimeFormatString, FOREVER_YEAR } from 'lib/dateFormatter';
import useUnsavedChangesWarning from 'hook/useUnsavedChangesWarning';
import * as logger from 'api/logger';

interface StaffScheduleDialogprops {
  open?: boolean;
  title?: string;
  onClose: () => void;
  onSaveSuccess: () => Promise<void>;
  locationId: number;
  staffScheduleId?: number;
  dialogType?: string;
  startDate: Date;
  endDate: Date;
  scheduleType: number;
  staffScheduleInfo?: Array<any>;
  createStaffSchedule: boolean;
  validLocationScheduleTypes: Array<number>;
  staffStartTime?: string;
  staffEndTime?: string;
  userId: string | number;
  locationRecurrence: string;
  locationRecurrenceEndDate: string;
}

function StaffScheduleDialog(props: StaffScheduleDialogprops): ReactElement {
  const [untilDate, setUntilDate] = useState<Date>();
  const [staffSchedule, setStaffSchedule] = useState<DoctorSchedule>();
  const [startTimesArr, setStartTimesArr] = useState<Array<string>>([]);
  const [endTimesArr, setEndTimesArr] = useState<Array<string>>([]);
  const [conflictStartTimesFilteredArr, setConflictStartTimesFilteredArr] = useState<Array<string>>(
    []
  );
  const [conflictEndTimesFilteredArr, setConflictEndTimesFilteredArr] = useState<Array<string>>([]);
  const [userId, setUserId] = useState<number>();
  const [staffName, setStaffName] = useState<string>('');
  const [startTime, setStartTime] = useState<string>('');
  const [endTime, setEndTime] = useState<string>('');
  const [frequency, setFrequency] = useState<string>('never');
  const [forever, setForever] = useState<boolean>(false);
  const [autoAssignTypeIds, setAutoAssignTypeIds] = useState<Array<number>>();
  const [loading, setLoading] = useState(false);
  const [changeStaff, setChangeStaff] = useState<boolean>(false);
  const [changeAllFutureDates, setChangeAllFutureDates] = useState<boolean>(true);
  const [disableChangeType, setDisableChangeType] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<Array<StaffScheduleValidationError>>([]);
  const [staffList, setStaffList] = useState<any>([]);
  const [selectedChangeType, setSelectedChangeType] = useState<boolean>(false);
  const [cancelled, setCancelled] = useState<boolean>(false);

  let editdialogTitle = '';
  if (!props.createStaffSchedule) {
    editdialogTitle = props.title!;
  }

  const earliestRecerranceDate = moment(props.startDate).add(1, 'weeks');

  function resetLocalVariables() {
    setChangeAllFutureDates(true);
    setChangeStaff(false);
    setStaffSchedule(undefined);
    setConflictStartTimesFilteredArr([]);
    setConflictEndTimesFilteredArr([]);
    setStartTimesArr([]);
    setEndTimesArr([]);
    setEndTime('');
    setStartTime('');
    setStartTime('never');
    setValidationErrors([]);
    setSelectedChangeType(false);
    setUserId(0);
    setCancelled(false);
  }

  const onLocalClose = () => {
    resetLocalVariables();
    props.onClose();
  };

  const [
    Prompt,
    LocalPrompt,
    changesMade,
    setChangesMade,
    setWarningOpen,
  ] = useUnsavedChangesWarning(onLocalClose);

  function validateForm(fullCheck): boolean {
    let validated = true;
    const validationErrors: Array<StaffScheduleValidationError> = [];

    if (fullCheck) {
      if (startTime === '' || !startTimesArr.includes(startTime)) {
        const validationError: StaffScheduleValidationError = {
          field: 'startTime',
          message: 'Please select a start time',
        };

        validationErrors.push(validationError);
        validated = false;
      }

      if (endTime === '' || !endTimesArr.includes(endTime)) {
        const validationError: StaffScheduleValidationError = {
          field: 'endTime',
          message: 'Please select an end time',
        };

        validationErrors.push(validationError);
        validated = false;
      }

      if (!userId) {
        const validationError: StaffScheduleValidationError = {
          field: 'staffId',
          message: 'Please select a staff',
        };

        validationErrors.push(validationError);
        validated = false;
      }

      if (changeStaff && userId === props.userId) {
        const validationError: StaffScheduleValidationError = {
          field: 'changeStaffId',
          message: 'Please select a new staff member',
        };

        validationErrors.push(validationError);
        validated = false;
      }
    }

    if (!selectedChangeType && !props.createStaffSchedule) {
      const validationError: StaffScheduleValidationError = {
        field: 'changeType',
        message: 'Please select change type',
      };

      validationErrors.push(validationError);
      validated = false;
    }

    setValidationErrors(validationErrors);
    return validated;
  }

  function setError(message: string) {
    const validationErrors: Array<StaffScheduleValidationError> = [];

    const validationError: StaffScheduleValidationError = {
      field: 'updateError',
      message: message,
    };

    validationErrors.push(validationError);
    setValidationErrors(validationErrors);
  }

  async function fetchStaffConflicts() {
    setLoading(true);
    try {
      let startTimes: Array<string> = getStartTimesArray(
        moment(props.startDate).format('HH:mm'),
        moment(props.endDate).format('HH:mm')
      );
      let endTimes: Array<string> = getEndTimesArray(
        moment(props.startDate).format('HH:mm'),
        moment(props.endDate).format('HH:mm')
      );

      let endDateUse: Date | undefined = untilDate || undefined;

      if (frequency === `never`) {
        endDateUse = props.startDate;
      } else if (forever) {
        endDateUse = undefined;
      }
      const params = {
        startDate: props.startDate,
        endDate: endDateUse,
        endTime: props.endDate,
        frequency: frequency,
        locationId: props.locationId,
        type: props.scheduleType,
        scheduleId: props.staffScheduleId,
      };

      if (userId) {
        let staffConflicts = await getStaffSchedules(userId, params);
        staffConflicts = staffConflicts ? staffConflicts : [];
        for (const conflict of staffConflicts) {
          startTimes = startTimes.filter(time => {
            return !(time < conflict.endTime && time >= conflict.startTime);
          });
          endTimes = endTimes.filter(time => {
            return !(time <= conflict.endTime && time > conflict.startTime);
          });
        }
      }

      setConflictEndTimesFilteredArr(endTimes);
      setConflictStartTimesFilteredArr(startTimes);
    } catch (err) {
      logger.error(err.message);
    }
    setLoading(false);
  }

  function assignUntilDate() {
    if (staffSchedule && staffSchedule.endDate) {
      // Date Picker only goes to 2099 anything above this is considered forever
      if (moment(staffSchedule.endDate).year() > 2099) {
        setForever(true);
      } else {
        setForever(false);
      }
      setUntilDate(staffSchedule!.endDate);
    }
  }

  async function fetchStaffSchedule(userId, staffScheduleId) {
    setLoading(true);
    try {
      const schedule = await getIndividualSchedule(userId, staffScheduleId);
      setStaffSchedule(schedule);
      setStartTime(schedule!.startTime);
      setEndTime(schedule!.endTime);
      setFrequency(schedule!.recurrence);
      setCancelled(!!schedule.cancelled);
      if (schedule!.recurrence == 'never') {
        setChangeAllFutureDates(false);
        setSelectedChangeType(true);
      }
    } catch (e) {
      logger.error(e.message);
    }
    setLoading(false);
  }

  async function fetchAvailableStaff() {
    const data = { locationId: props.locationId };
    const response = await getAvailableStaff(data);
    setStaffList(response);
  }

  useEffect(() => {
    if (changeAllFutureDates && staffSchedule) {
      assignUntilDate();
    } else {
      setUntilDate(props.startDate);
    }
  }, [changeAllFutureDates, selectedChangeType]);

  useEffect(() => {
    if (!changeStaff && typeof props.userId == 'number') {
      setUserId(props.userId);
    }
  }, [props.userId, props.open, changeStaff]);

  useEffect(() => {
    if (props.staffScheduleId && props.userId) {
      fetchStaffSchedule(props.userId, props.staffScheduleId);
    } else {
      setStaffSchedule(undefined);
    }
  }, [props.staffScheduleId, props.userId]);

  useEffect(() => {
    setUntilDate(earliestRecerranceDate.toDate());
    fetchAvailableStaff();
  }, [props.startDate]);

  useEffect(() => {
    assignUntilDate();
  }, [staffSchedule]);

  useEffect(() => {
    if (!staffSchedule) return;
    if (untilDate && staffSchedule.endDate) {
      if (
        (moment(untilDate).diff(staffSchedule.endDate) !== 0 &&
          moment(props.startDate).diff(untilDate, 'days') !== 0) ||
        (!forever && moment(staffSchedule.endDate).year() > 2099) ||
        (forever && moment(staffSchedule.endDate).year() < 2099)
      ) {
        setChangeAllFutureDates(true);
        setDisableChangeType(true);
      } else {
        setDisableChangeType(false);
      }
    }
  }, [untilDate, forever]);

  useEffect(() => {
    if (!userId) {
      setStartTimesArr([]);
      setEndTimesArr([]);
      return;
    }
    fetchStaffConflicts();
  }, [userId, untilDate, frequency, forever, staffSchedule]);

  useEffect(() => {
    const startTimes = conflictStartTimesFilteredArr;
    let endTimes = conflictEndTimesFilteredArr;

    if (startTime) {
      endTimes = endTimes.filter(time => {
        return time > startTime;
      });
      let cutIndex = 1;
      for (; cutIndex < endTimes.length; cutIndex++) {
        if (
          moment(endTimes[cutIndex], 'HH:mm').diff(
            moment(endTimes[cutIndex - 1], 'HH:mm'),
            'minutes'
          ) != 15
        ) {
          break;
        }
      }
      endTimes = endTimes.slice(0, cutIndex);
    }

    setStartTimesArr(startTimes);
    setEndTimesArr(endTimes);
  }, [startTime, endTime, conflictEndTimesFilteredArr, conflictStartTimesFilteredArr]);

  const createServerScheduleBody = (scheduleId: number | undefined = undefined) => {
    let endDateUse: Date | undefined = untilDate || moment().toDate();
    if (frequency === `never`) {
      endDateUse = props.startDate;
    } else if (forever) {
      endDateUse = undefined;
    }
    const body = {
      scheduleId: scheduleId,
      locationId: props.locationId,
      endTime: endTime,
      startTime: startTime,
      recurrence: frequency as FrequencyType,
      startDate: props.startDate,
      endDate: endDateUse,
      autoAssignTypeIds: autoAssignTypeIds,
      type: props.scheduleType,
      changeAllFutureDates: changeAllFutureDates,
      cancelSchedule: false,
    };

    return body;
  };

  function onClose() {
    if (changesMade) {
      setWarningOpen(true);
    } else {
      onLocalClose();
    }
  }

  async function onSave(): Promise<void> {
    if (validateForm(true)) {
      setLoading(true);
      try {
        const body = createServerScheduleBody();
        if (userId) {
          await UserApi.createSchedule(userId, body);
        }
        props.onSaveSuccess();
        resetLocalVariables();
      } catch (e) {
        const error = e as any;
        if (error) {
          setError(error.response.data.message);
        }
        logger.error(error.message);
      }
      setLoading(false);
    }
  }
  async function onUpdate(): Promise<void> {
    setLoading(true);
    try {
      if (props.staffScheduleId && validateForm(true)) {
        const body = createServerScheduleBody(props.staffScheduleId!);
        if (userId) {
          await UserApi.updateSchedule(userId, body);
        }
        resetLocalVariables();
        props.onSaveSuccess();
      }
    } catch (e) {
      const error = e as any;
      if (error) {
        setError(error.response.data.message);
      }
      logger.error(error.message);
    }
    setLoading(false);
  }
  function createDeleteBody() {
    const body = {
      frequency: frequency,
      startDate: props.startDate,
      changeAllFutureDates: changeAllFutureDates,
    };
    return body;
  }
  async function onDelete(): Promise<void> {
    setLoading(true);
    try {
      if (props.staffScheduleId && validateForm(false)) {
        const body = createDeleteBody();
        await UserApi.deleteSchedule(props.staffScheduleId, body);
        resetLocalVariables();
        props.onSaveSuccess();
      }
    } catch (e) {
      const error = e as any;
      if (error) {
        setError(error.response.data.message);
      }
      logger.error(error.message);
    }
    setLoading(false);
  }

  async function onCancelStaff(): Promise<void> {
    setLoading(true);
    try {
      if (userId) {
        const body = createServerScheduleBody(props.staffScheduleId);
        if (cancelled) {
          if (props.staffScheduleId)
            await UserApi.deleteException(userId, props.staffScheduleId, body.startDate);
        } else {
          body.cancelSchedule = true;
          body.changeAllFutureDates = false;
          await UserApi.updateSchedule(userId, body);
        }
        resetLocalVariables();
        await props.onSaveSuccess();
      } else {
        setError('User id not found, please select a staff member');
      }
    } catch (e) {
      const error = e as any;
      if (error) {
        setError(error.response.data.message);
      }
      logger.error(error.message);
    }
    setLoading(false);
  }

  // Data confiramtion
  function checkDateSelection(date) {
    if (moment(date).diff(props.locationRecurrenceEndDate, 'days') <= 0) {
      setUntilDate(date);
    } else {
      setUntilDate(untilDate);
    }
  }

  //UI Creatiion
  function getStaffNameById(userId) {
    const staff = staffList?.find(staffUser => staffUser?.id === userId);
    return staff ? staff.name : 'No Staff Selected';
  }
  function getStaffMenuItemList(staffMembers = staffList) {
    return staffMembers?.map(staff => (
      <MenuItem key={staff?.id} value={staff?.id} className={styles.selectItem}>
        {staff?.name}
      </MenuItem>
    ));
  }
  function getStaffMenuItemListByAppointmentType() {
    const scheduleType = ScheduleTypesNumberToString(props.scheduleType);
    let staffListFiltered = [];
    switch (scheduleType) {
      case ScheduleTypesStrings.Procedure:
        staffListFiltered = staffList?.filter(
          staffUser =>
            staffUser.type === ROLE_ID.DOCTOR ||
            staffUser?.type === ROLE_ID.NURSE ||
            staffUser?.type === ROLE_ID.TECHNICIAN ||
            staffUser?.type === ROLE_ID.PHYSICIAN_ASSISTANT
        );
        break;
      case ScheduleTypesStrings.UltraSound:
        staffListFiltered = staffList?.filter(staffUser => staffUser.type === ROLE_ID.TECHNICIAN);
        break;
      case ScheduleTypesStrings.ConsultationAndfollowUp:
        staffListFiltered = staffList?.filter(staffUser => staffUser.type === ROLE_ID.DOCTOR);
        break;
      default:
        staffListFiltered = staffList;
    }
    return getStaffMenuItemList(staffListFiltered);
  }
  function viewStaffList() {
    if (changeStaff) {
      return (
        <Select
          fullWidth
          label="Update Staff To:"
          renderValue={value => <div className={styles.selectItem}>{getStaffNameById(value)}</div>}
          onChange={({ target }) => {
            setChangesMade(true);
            setUserId(target.value);
          }}
          disabled={loading}
        >
          {getStaffMenuItemListByAppointmentType()}
        </Select>
      );
    }
    return <></>;
  }
  function createOrEdit() {
    if (props.createStaffSchedule) {
      return (
        <div className={classNames(styles.staffSelect)}>
          <Select
            fullWidth
            label="Select Staff"
            renderValue={value => (
              <div className={styles.selectItem}>{getStaffNameById(value)}</div>
            )}
            onChange={({ target }) => {
              setChangesMade(true);
              setUserId(target.value);
            }}
            disabled={loading}
          >
            {getStaffMenuItemListByAppointmentType()}
          </Select>
          <ValidationError field={'staffId'} validationErrors={validationErrors} />
          <div className={styles.subTitle}>
            {props.title}
            <br />
            {ScheduleTypesNumberToString(props.scheduleType)}
          </div>
        </div>
      );
    } else {
      return (
        <div className={classNames(styles.dialogTopSection)}>
          <div className={classNames(styles.staffSelect)}>
            <div>
              <Checkbox
                title={'Change Staff'}
                onChange={({ target }) => setChangeStaff(target.checked)}
                disabled={loading}
              />
              <ValidationError field={'changeStaffId'} validationErrors={validationErrors} />
              <div className={styles.staffSelect}>{viewStaffList()}</div>
            </div>
            <div>
              <FormControl component="fieldset">
                <RadioGroup name="radio-buttons-group">
                  <FormControlLabel
                    value="All future dates"
                    control={
                      <Radio
                        onChange={() => {
                          setChangesMade(true);
                          setChangeAllFutureDates(true);
                          setSelectedChangeType(true);
                        }}
                        disabled={frequency === 'never' || loading}
                        checked={changeAllFutureDates && selectedChangeType}
                      />
                    }
                    label="All future dates"
                  />
                  <FormControlLabel
                    value="Only current date"
                    control={
                      <Radio
                        disabled={disableChangeType || loading}
                        onChange={() => {
                          setChangesMade(true);
                          setChangeAllFutureDates(false);
                          setSelectedChangeType(true);
                        }}
                        checked={!changeAllFutureDates && selectedChangeType}
                      />
                    }
                    label="Only current date"
                  />
                </RadioGroup>
              </FormControl>
              <ValidationError field={'changeType'} validationErrors={validationErrors} />
            </div>
          </div>
        </div>
      );
    }
  }

  function editDeleteButton() {
    if (!props.createStaffSchedule) {
      return (
        <div className={styles.editDeleteButton} onClick={onDelete}>
          Delete Schedule
        </div>
      );
    }
  }

  function cancelStaffButton() {
    if (!props.createStaffSchedule) {
      return (
        <DialogButton
          buttonType={DialogButtonType.AffirmativeLink}
          onClick={onCancelStaff}
          loading={loading}
        >
          {cancelled ? 'Include Schedule' : 'Exclude Schedule'}
        </DialogButton>
      );
    }
  }

  function recurrenceSelection(item) {
    // eslint-disable-next-line
    const locationRecurrence: string = props.locationRecurrence;
    if (locationRecurrence == 'weekly' || locationRecurrence == item.tag || item.tag == 'never') {
      return (
        <div key={item.id} className={styles.dialogButtonContainer}>
          <DialogButton
            className={styles.dialogButtonContainer}
            buttonType={DialogButtonType.Bordered}
            key={item.id}
            active={frequency! === item.tag}
            onClick={() => {
              setChangesMade(true);
              setFrequency(item.tag);
            }}
            disabled={loading}
          >
            {item.name}
          </DialogButton>
        </div>
      );
    }
    return (
      <div key={item.id} className={styles.dialogButtonContainer}>
        <DialogButton
          className={styles.dialogButton}
          buttonType={DialogButtonType.Bordered}
          key={item.id}
          disabled={true}
        >
          {item.name}
        </DialogButton>
      </div>
    );
  }
  function foreverCheckBox() {
    // eslint-disable-next-line
    const locationRecurrenceEndDate: string = props.locationRecurrenceEndDate;
    if (moment(locationRecurrenceEndDate).year() == 9999) {
      return (
        <div className={styles.checkBoxWrapper}>
          <Checkbox
            title={'Forever'}
            name="forever"
            onChange={e => setForever(e.target.checked)}
            checked={forever}
            disabled={loading}
          />
        </div>
      );
    }
    return <div></div>;
  }
  function endUntil() {
    if (
      (frequency || 'never') === 'never' ||
      (!props.createStaffSchedule && !changeAllFutureDates)
    ) {
      return <div></div>;
    } else {
      return (
        <div className={classNames(styles.until)}>
          <div className={styles.repeatSchedule}>
            <div>Repeat Until:</div>
            {foreverCheckBox()}
            <div className={styles.datePickerWrapper}>
              {!forever && (
                <DatePicker
                  label="Until"
                  value={displayDateForDatePicker(untilDate, props.startDate)}
                  fullWidth
                  onChange={value => {
                    setChangesMade(true);
                    checkDateSelection(value!.toDate());
                  }}
                  format="DD/MM/YYYY"
                  disabled={forever || loading}
                  shouldDisableDate={day => {
                    if (!day) return true;
                    if (moment(props.startDate).isAfter(day)) return true;
                    return moment().diff(moment(day.toISOString()), 'days') > 0; //In Past
                  }}
                  minDate={earliestRecerranceDate.format(DateTimeFormatString.DateOnly)}
                  maxDate={props.locationRecurrenceEndDate}
                />
              )}
            </div>
          </div>
          <p>
            <b>
              Location scheduled until:
              {moment(props.locationRecurrenceEndDate).year() === FOREVER_YEAR
                ? ' Forever'
                : ` ${moment(props.locationRecurrenceEndDate).format(
                    DateTimeFormatString.MonthDayYear
                  )}`}
            </b>
          </p>
        </div>
      );
    }
  }

  return (
    <Dialog open={props.open} title={`Staff Schedule ${editdialogTitle}`} onClose={onClose}>
      <div className={classNames(styles.dialogBody)}>
        <div className={styles.fields}>
          <p>
            <b>
              {moment(props.startDate.toDateString()).format('LL')}
              <br />
              {moment(props.startDate.toDateString()).format('dddd')}
            </b>
          </p>
          {createOrEdit()}

          <Grid container>
            <Grid item xs={12} sm={6}>
              <div className={classNames(styles.openingTimeWrapper)}>
                <Select
                  fullWidth
                  label="Start Time"
                  name={'startTime'}
                  onChange={({ target }) => {
                    setChangesMade(true);
                    setStartTime(target.value);
                  }}
                  value={startTime}
                  disabled={loading}
                >
                  {startTimesArr.map(item => (
                    <MenuItem key={item} value={item} className={styles.selectItem}>
                      {convertTo12From24(item)}
                    </MenuItem>
                  ))}
                </Select>
                <ValidationError field={'startTime'} validationErrors={validationErrors} />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={classNames(styles.closingTimeWrapper)}>
                <Select
                  fullWidth
                  label="End Time"
                  name={'endTime'}
                  onChange={({ target }) => {
                    setEndTime(target.value);
                    setChangesMade(true);
                  }}
                  value={endTime}
                  disabled={loading}
                >
                  {endTimesArr.map(item => (
                    <MenuItem key={item} value={item} className={styles.selectItem}>
                      {convertTo12From24(item)}
                    </MenuItem>
                  ))}
                </Select>
                <ValidationError field={'endTime'} validationErrors={validationErrors} />
              </div>
            </Grid>
          </Grid>
          <div className={styles.repeatContainer}>
            <Grid container direction="column">
              <Grid container>
                <Grid item xs={12}>
                  <FormLabel>Repeat:</FormLabel>
                  <div className={styles.buttonSection}>
                    {FREQUENCY.map(item => {
                      if (props.createStaffSchedule) {
                        return recurrenceSelection(item);
                      } else {
                        return (
                          <div key={item.id} className={styles.dialogButtonContainer}>
                            <DialogButton
                              className={styles.dialogButton}
                              buttonType={DialogButtonType.Bordered}
                              key={item.id}
                              active={frequency! === item.tag}
                              disabled={frequency! !== item.tag || loading}
                            >
                              {item.name}
                            </DialogButton>
                          </div>
                        );
                      }
                    })}
                  </div>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  {endUntil()}
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
        <ValidationError field={'updateError'} validationErrors={validationErrors} />
        <div className={classNames(styles.dialogBottom)}>
          <div className={styles.buttomButtonSection}>
            <DialogButton buttonType={DialogButtonType.NegationLink} onClick={onClose}>
              Cancel
            </DialogButton>
            {editDeleteButton()}
            {cancelStaffButton()}
            {props.staffScheduleId ? (
              <DialogButton
                buttonType={DialogButtonType.AffirmativeLink}
                onClick={onUpdate}
                loading={loading}
              >
                Update
              </DialogButton>
            ) : (
              <DialogButton
                buttonType={DialogButtonType.AffirmativeLink}
                onClick={onSave}
                loading={loading}
              >
                Save
              </DialogButton>
            )}
          </div>
        </div>
      </div>
      {LocalPrompt}
    </Dialog>
  );
}

export default StaffScheduleDialog;
