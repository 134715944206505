import React, { useState, useEffect } from 'react';
import ChangesInProgressWarningDialog from 'components/ChangesInProgressWarningDialog';
import { Prompt } from 'react-router-dom';

const useUnsavedChangesWarning = onConfirm => {
  const [changesMade, setChangesMade] = useState<boolean>(false);
  const [warningOpen, setWarningOpen] = useState<boolean>(false);

  useEffect(() => {
    window.onbeforeunload = changesMade
      ? () => 'Are you sure you would like ot discard chnages'
      : null;

    return () => {
      window.onbeforeunload = null;
    };
  }, [changesMade]);

  const localPrompt = ChangesInProgressWarningDialog({
    open: changesMade && warningOpen,
    onClose: () => setWarningOpen(false),
    onConfirm: () => {
      onConfirm();
      setChangesMade(false);
      setWarningOpen(false);
    },
  });

  const prompt = new Prompt({
    when: changesMade,
    message: 'this is a message',
  });

  return [prompt, localPrompt, changesMade, setChangesMade, setWarningOpen] as const;
};

export default useUnsavedChangesWarning;
