import React, { ReactElement, useEffect, useState } from 'react';

import styles from './StaffNotesDialog.module.scss';
import Dialog from 'components/Dialog';
import DialogButton from 'components/DialogButton';
import { Grid, TextField } from '@material-ui/core';
import EditStaffNotesDialog from './components/EditStaffNotesDialog';
import useUserApi from 'hook/useUserApi.hook';
import { User } from 'types';
import { AlertDialog } from 'components/AlertDialog';
import { handleAPIErrors } from 'lib/handleAPIErrors';

interface StaffNotesProps {
  open: boolean;
  staff?: User;
  onClose: () => void;
}

function StaffNotesDialog({ open, staff, onClose }: StaffNotesProps): ReactElement {
  const [openEditStaffNotes, setOpenEditStaffNotes] = useState<boolean>(false);
  const [staffNote, setStaffNote] = useState<string | undefined>();
  const { fetchStaffNotes, updateStaffNote } = useUserApi(staff?.id, false);
  const [openWarningDialog, setOpenWarningDialog] = useState<boolean>(false);
  const [popupMessage, setPopupMessage] = useState<string>('');

  async function onFetchStaffNotes() {
    try {
      const note = await fetchStaffNotes(staff?.id);
      setStaffNote(note?.note);
    } catch (e) {
      setPopupMessage(handleAPIErrors(e));
      setOpenWarningDialog(true);
    }
  }

  async function onStaffNotesSave(noteValues) {
    try {
      await updateStaffNote(staff, noteValues);
      onFetchStaffNotes();
    } catch (e) {
      setPopupMessage(handleAPIErrors(e));
      setOpenWarningDialog(true);
    }
  }

  useEffect(() => {
    if (!open) return;
    onFetchStaffNotes();
  }, [open]);

  function onEditStaffNotes() {
    setOpenEditStaffNotes(true);
  }

  return (
    <Dialog open={open} onClose={onClose} title={`${staff?.fullName}'s Notes`}>
      <div className={styles.container}>
        <Grid container className={styles.gridContainer}>
          <div>{staffNote}</div>

          <div className={styles.buttons}>
            <DialogButton onClick={onClose}>Close</DialogButton>
            <DialogButton className={styles.marginLeft} onClick={onEditStaffNotes}>
              {'Edit'}
            </DialogButton>
          </div>
        </Grid>
      </div>
      <EditStaffNotesDialog
        open={openEditStaffNotes}
        staff={staff}
        note={staffNote}
        onClose={() => {
          setOpenEditStaffNotes(false);
        }}
        onSave={onStaffNotesSave}
      />
      <AlertDialog
        open={openWarningDialog}
        title={'Error'}
        message={popupMessage}
        onClose={() => setOpenWarningDialog(false)}
      />
    </Dialog>
  );
}

export default StaffNotesDialog;
