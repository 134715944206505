import axios from 'axios';

import { Form, QuestionForm, NewBlobDocument, Document } from 'types';
import { GET_MESSAGE } from 'lib/config';

import { getDefaultHeader } from '../utils';

interface CheckBirthDateResult {
  isBirthDate: boolean;
}

export async function checkBirthDateForPatient(
  historyId: number | string,
  historyType: string,
  dateOfBirth: string,
  messageKey?: string
): Promise<CheckBirthDateResult> {
  const response = await axios.get(`${GET_MESSAGE}/dateOfBirth`, {
    params: {
      historyId: historyId,
      historyType: historyType,
      date: dateOfBirth,
    },
    headers: getDefaultHeader(messageKey),
  });

  return response.data;
}

export async function sendResponse(
  historyId: number | string,
  historyType: number | string,
  message: string,
  messageKey?: string
): Promise<QuestionForm> {
  const response = await axios(`${GET_MESSAGE}`, {
    method: 'POST',
    params: {
      historyId,
      historyType,
    },
    data: {
      message,
    },
    headers: getDefaultHeader(messageKey),
  });

  return response.data;
}

export async function sendMessage(caseId: number, message: string): Promise<QuestionForm> {
  const response = await axios(`${GET_MESSAGE}/send`, {
    method: 'POST',
    data: {
      caseId,
      message,
    },
    headers: getDefaultHeader(),
  });

  return response.data;
}
