import React, { useEffect, useState } from 'react';
import { stylesPdf } from 'lib/workflowPdf';
import { Page, Text, View, Document, PDFDownloadLink, Image, PDFViewer } from '@react-pdf/renderer';
import Dialog from 'components/Dialog';
import useWorkflowPDF from 'pages/WorkflowResult/components/WorkflowPDF/useWorkflowPDF.hook';
import DialogButton from 'components/DialogButton';
import styles from './FollowUpResultsPDFStyles.module.scss';
import {
  PatientMetaLabels,
  parseText,
} from 'pages/WorkflowResult/components/PatientResults/lib/calculatePatientResults';
import { FollowUpResultPDFFragment } from 'pages/WorkflowResult/components/FollowUpResultPDFFragment';

interface WorkflowPDFComponentProps {
  isOpen: boolean;
  onClose: () => void;
  caseId?: number;
  formId?: number;
  patientId?: number;
  paragraphTexts?;
  coverLetterHeaderFooter?;
  history?;
}

export const FollowUpResultsPDF = ({
  isOpen,
  onClose,
  formId,
  caseId,
  patientId,
}: WorkflowPDFComponentProps) => {
  const [pdfFollowUpResultsURL, setPDFFollowUpResultsURL] = useState('');
  const [generatedUrl, setGeneratedUrl] = useState('');
  const [urlLoading, setURLLoading] = useState(false);

  // Ensures the user only saves once
  const [savingInProgress, setSavingInProgress] = useState(false);

  const { patientFormResults, saveFollowUpResultsDocument } = useWorkflowPDF({
    pdfFollowUpResultsURL,
    patientId,
    formId,
    caseId,
  });

  const labelKeysPatientMeta = Array.from(PatientMetaLabels.keys());
  const FollowUpResultsDocument = (
    <FollowUpResultPDFFragment
      formId={formId}
      caseId={caseId}
      patientName={patientFormResults.get(labelKeysPatientMeta[0])?.value}
    />
  );

  async function onSave() {
    // Since we rediirect after save we don't need to change this state after it starts
    setSavingInProgress(true);
    const nextFollowUp = await patientFormResults.get('Follow-Up::RecommendedFollowUpIn')?.value;
    await saveFollowUpResultsDocument(nextFollowUp);
    onClose();
  }

  useEffect(() => {
    // Debounce FN since the genrated url may update a few times until it is finally ready
    const delayDebounceFn = setTimeout(() => {
      /*
            Tech images not loading
            & Url For pdf generator not loading
            & the pdf generator has a url
            & the final URL has not been set yet
        */
      if (!urlLoading && !!generatedUrl && !pdfFollowUpResultsURL) {
        setPDFFollowUpResultsURL(generatedUrl);
        setURLLoading(false);
      }
    }, 3000);

    return () => clearTimeout(delayDebounceFn);
  }, [generatedUrl]);

  return (
    <div>
      <Dialog open={isOpen} onClose={onClose} title={'Completed Follow-Up Form'}>
        <div className={styles.container}>
          <p className={styles.text}>
            The FollowUp Document is being generated and will be available under the Patient
            Documents
          </p>
          <div className={styles.buttons}>
            <DialogButton disabled={savingInProgress || !pdfFollowUpResultsURL} onClick={onSave}>
              {!pdfFollowUpResultsURL || savingInProgress ? 'Loading...' : 'Ok'}
            </DialogButton>
          </div>
        </div>
      </Dialog>
      <PDFDownloadLink document={FollowUpResultsDocument}>
        {({ blob, url, loading, error }) => {
          setURLLoading(loading);
          if (url) {
            setGeneratedUrl(url);
          }
          return <div></div>;
        }}
      </PDFDownloadLink>
    </div>
  );
};
