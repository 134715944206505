import { useState, useEffect } from 'react';
import useWorkflowResultApi from 'pages/WorkflowResult/useWorkflowApi.hook';
import { getTechnicianResults } from '../TechnicianResults/lib/calculateTechnicianResults';
import { CompositeImageApi } from 'api';

interface UseTechResultPDFFragmentProps {
  caseId?: number;
  formId?: number;
}

export const useTechResultPDFFragment = ({ formId, caseId }: UseTechResultPDFFragmentProps) => {
  const { formCaseResult } = useWorkflowResultApi(formId, caseId);
  const [imageSrcLeft, setImageSrcLeft] = useState<string>('');
  const [imageSrcRight, setImageSrcRight] = useState<string>('');
  const [techFormResults, setTechFormResults] = useState({
    leftLeg: {
      images: [''],
      GSV: [''],
      GSVThrombosed: [''],
      GSVAbove: [''],
      GSVBelow: [''],
      GSVStripped: [''],
      GSVAblated: [''],
      GSVReflux: [''],
      GSVRefluxMaxDiameter: [''],
      PSV: [''],
      GM: [''],
      Perforators: [''],
      comments: [''],
      SSVUpper: [''],
      SSVLower: [''],
      SSVRefluxMaxDiameter: [''],
      ASV: [''],
      SPJ: [''],
      SFJ: [''],
      VV: [''],
      SuperficialVeinThrombosis: [''],
    },
    rightLeg: {
      images: [''],
      GSV: [''],
      GSVThrombosed: [''],
      GSVAbove: [''],
      GSVBelow: [''],
      GSVStripped: [''],
      GSVAblated: [''],
      GSVReflux: [''],
      GSVRefluxMaxDiameter: [''],
      PSV: [''],
      GM: [''],
      Perforators: [''],
      comments: [''],
      SSVUpper: [''],
      SSVLower: [''],
      SSVRefluxMaxDiameter: [''],
      ASV: [''],
      SPJ: [''],
      SFJ: [''],
      VV: [''],
      SuperficialVeinThrombosis: [''],
    },
  });

  async function getImage(techFormResults): Promise<void> {
    // Get S3 Bucket URLs
    const imageUrlsForLeft = await CompositeImageApi.getImages(
      techFormResults.leftLeg.images,
      'VeinImages'
    );
    const imageUrlsForRight = await CompositeImageApi.getImages(
      techFormResults.rightLeg.images,
      'VeinImages'
    );

    // Apply Images to Canvas
    const canvasObjLeft = document.createElement('canvas');
    const canvasObjRight = document.createElement('canvas');
    canvasObjLeft.height = 400;
    canvasObjLeft.width = 400;
    canvasObjRight.height = 400;
    canvasObjRight.width = 400;

    const ctxLeftLeg = canvasObjLeft.getContext('2d');
    let leftLegImage: Array<Promise<void>> = [];

    if (canvasObjLeft) {
      leftLegImage = imageUrlsForLeft.map(url => {
        return new Promise((resolve, reject) => {
          const image = new Image();
          image.crossOrigin = 'anonymous';
          image.onload = function() {
            ctxLeftLeg?.drawImage(image, 0, 0, 400, 400);
            resolve();
          };
          image.src = url as string;
        });
      });
    }

    const ctxRighttLeg = canvasObjRight.getContext('2d');
    let rightLegImage: Array<Promise<void>> = [];

    if (canvasObjRight) {
      rightLegImage = imageUrlsForRight.map(url => {
        return new Promise((resolve, reject) => {
          const image = new Image();
          image.crossOrigin = 'anonymous';
          image.onload = function() {
            ctxRighttLeg?.drawImage(image, 0, 0, 400, 400);
            resolve();
          };
          image.src = url as string;
        });
      });
    }

    await Promise.all([...leftLegImage, ...rightLegImage]);
    if (canvasObjLeft && imageUrlsForLeft.length > 0) {
      const canvasLeft = canvasObjLeft as HTMLCanvasElement;
      setImageSrcLeft(canvasLeft.toDataURL());
    }

    if (canvasObjRight && imageUrlsForRight.length > 0) {
      const canvasRight = canvasObjRight as HTMLCanvasElement;
      setImageSrcRight(canvasRight.toDataURL());
    }
  }

  useEffect(() => {
    if (formCaseResult) {
      const tfresults = getTechnicianResults(formCaseResult?.answers);
      getImage(tfresults);
      setTechFormResults(tfresults);
    }
  }, [formCaseResult]);

  return {
    formCaseResult,
    techFormResults,
    imageSrcLeft,
    imageSrcRight,
  };
};

export default useTechResultPDFFragment;
