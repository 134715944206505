import React, { ReactElement, useEffect, useState } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { StringValueItem } from 'types';

import Select from '../../pages/Appointments/components/Select';
import { APPOINTMENT_STATUSES } from 'lib/appointments';

interface AppointmentStatusSelectProps {
  name?: string;
  label: string;
  value?: string | number;
  useFirstWhenEmpty?: boolean;
  onChange(e): void;
  [rest: string]: any;
}

function AppointmentStatusSelect({
  name,
  value,
  label,
  onChange,
  useFirstWhenEmpty = false,
  ...rest
}: AppointmentStatusSelectProps): ReactElement {
  const [appointmentStatus, setAppointmentStatus] = useState<Array<StringValueItem>>([]);

  useEffect(() => {
    setAppointmentStatus(APPOINTMENT_STATUSES);
  }, []);

  return (
    <Select {...rest} onChange={onChange} value={value} name={name} label={label}>
      {appointmentStatus.map(location => (
        <MenuItem key={location.id} value={location.name}>
          {location.name}
        </MenuItem>
      ))}
    </Select>
  );
}

export default AppointmentStatusSelect;
