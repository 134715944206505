import React, { ReactElement, ReactNode, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import { useFormik } from 'formik';
import { EditableUser, User } from 'types';

import Dialog from '../Dialog';

import styles from './UserInfoModalStyles.module.scss';
import DialogButton from '../DialogButton';

import { changePassword } from 'api/user';
import changePasswordValidator from './ChangePassword.validator';
import * as logger from 'api/logger';

interface ResetPasswordDialogProps {
  user: User | null;
  open: boolean;
  loading: boolean;
  onClose(): void;
  onSave(data: EditableUser): void;
}

function ResetPasswordDialog({
  user,
  open,
  loading,
  onClose,
  onSave,
}: ResetPasswordDialogProps): ReactElement {
  const [showResponse, setShowResponse] = useState(false);
  const [response, setResponse] = useState<{ responseIcon: ReactNode; responseMessage: string }>({
    responseIcon: null,
    responseMessage: '',
  });

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    validate: changePasswordValidator,
    onSubmit: async values => {
      try {
        const currentUserId = user && user.id ? user.id : null;
        await changePassword(currentUserId, values.newPassword, values.oldPassword);
        setResponse({
          responseIcon: <CheckCircleOutlineIcon className={styles.success} />,
          responseMessage: 'Password changed successfully!',
        });
      } catch (e) {
        setResponse({
          responseIcon: <ErrorOutlineIcon className={styles.error} />,
          responseMessage: 'Failed to change password',
        });
        logger.error(e.message);
        formik.setErrors({ oldPassword: e.response?.data?.[0].msg });
      }
      setShowResponse(true);
    },
  });

  function onCloseDialog(): void {
    setShowResponse(false);
    setResponse({
      responseIcon: null,
      responseMessage: '',
    });
    onClose();
  }

  return (
    <Dialog open={open} title="Change Password" onClose={onCloseDialog}>
      {showResponse ? (
        <div className={styles.response}>
          {response.responseIcon}
          {response.responseMessage}
        </div>
      ) : (
        <div className={styles.resetDialog}>
          <TextField
            error={!!formik.errors?.oldPassword}
            name="oldPassword"
            label="Old password"
            onChange={formik.handleChange}
            fullWidth
            type="password"
            helperText={formik.errors?.oldPassword}
          />
          <TextField
            error={!!formik.errors?.newPassword}
            name="newPassword"
            label="New password"
            onChange={formik?.handleChange}
            fullWidth
            type="password"
            helperText={formik.errors?.newPassword}
          />
          <TextField
            error={!!formik.errors?.confirmNewPassword}
            name="confirmNewPassword"
            label="Confirm new password"
            onChange={formik.handleChange}
            fullWidth
            type="password"
            helperText={formik.errors?.confirmNewPassword}
          />
          <div className={styles.buttons}>
            <DialogButton className={styles.button} onClick={onCloseDialog}>
              Cancel
            </DialogButton>
            <DialogButton loading={loading} onClick={formik.handleSubmit} lassName={styles.button}>
              Confirm
            </DialogButton>
          </div>
        </div>
      )}
    </Dialog>
  );
}

export default ResetPasswordDialog;
