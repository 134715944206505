import React, { ReactElement } from 'react';
import Button from 'components/Button';

import styles from './ResponsiveTopBar.module.scss';
import InputSearch from '../InputSearch';

import TitledSection from 'components/TitledSection';

interface ResponsiveTopBarProps {
  searchQuery: string | null;
  onChangeSearchQuery: (query) => void;
  onNewStaffClick: () => void;
}

function ResponsiveTopBar({
  searchQuery,
  onChangeSearchQuery,
  onNewStaffClick,
}: ResponsiveTopBarProps): ReactElement {
  return (
    <div className={styles.container}>
      <div className={styles.toolbar}>
        <TitledSection title="Staff List" />
        <div className={styles.selectors}>
          <div className={styles.selectors} style={{ justifyContent: 'flex-end' }}>
            <div style={{ margin: '2px', marginRight: '16px' }}>
              <InputSearch
                value={searchQuery}
                onChange={event => onChangeSearchQuery(event.target.value)}
              />
            </div>
            <div style={{ margin: '2px' }}>
              <Button
                className={`${styles.marginLeft} ${styles.marginTop}`}
                onClick={onNewStaffClick}
              >
                New Staff
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResponsiveTopBar;
