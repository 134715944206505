import moment from 'moment';

/**
 * Holiday Sources
 *
 * https://www2.gnb.ca/content/gnb/en/departments/finance/human_resources/content/policies_and_guidelines/leave_policies/statutory_public_holidays.html
 * https://www.ontario.ca/document/your-guide-employment-standards-act-0/public-holidays
 * https://www.saskatchewan.ca/business/employment-standards/public-statutory-holidays/list-of-saskatchewan-public-holidays
 */
export abstract class Holidays {
  abstract getobservedHolidays(year: number): moment.Moment[];

  abstract isDateIsHoliday(date: moment.Moment): boolean;

  protected third(year: number, month: string, day: string): moment.Moment {
    return this.first(year, month, day).add(2, 'weeks');
  }

  protected second(year: number, month: string, day: string): moment.Moment {
    return this.first(year, month, day).add(1, 'weeks');
  }

  protected first(year: number, month: string, day: string): moment.Moment {
    const theFirstDayOfTheMonth = moment(`${year}-${month}-01`)
      .startOf('month')
      .day(day);
    if (theFirstDayOfTheMonth.date() > 7) theFirstDayOfTheMonth.add(7, 'days');
    return theFirstDayOfTheMonth;
  }

  // -- Shared Holidays (apply to multiple provinces) --

  //third Monday in February
  protected familyDay(year: number): moment.Moment {
    return this.third(year, '2', 'monday');
  }

  //january first
  protected newYear(year: number): moment.Moment {
    return moment(`${year}-01-01`);
  }

  //friday before easter
  protected goodFriday(year: number): moment.Moment {
    const easter = this.easter(year);
    const goodFriday = moment(easter).subtract(2, 'days');
    return goodFriday;
  }

  //July 1st (If July 1st falls on Sunday, Monday, July 2nd replaces July 1st as Canada Day)
  protected canadaDay(year: number): moment.Moment {
    const canadaDay = moment(`${year}-07-01`);
    if (canadaDay.day() === 0) {
      canadaDay.add(1, 'day');
    }
    return canadaDay;
  }

  //the first Monday in September
  protected labourDay(year: number): moment.Moment {
    return this.first(year, '09', 'Monday');
  }

  //December 25
  protected christmasDay(year: number): moment.Moment {
    return moment(`${year}-12-25`);
  }

  //Easter Sunday is the first Sunday after the full moon after March 21st.
  //If the full moon falls on a Sunday, Easter is the next Sunday.
  protected easter(year: number): moment.Moment {
    const f = Math.floor;
    const G = year % 19;
    const C = f(year / 100);
    const H = (C - f(C / 4) - f((8 * C + 13) / 25) + 19 * G + 15) % 30;
    const I = H - f(H / 28) * (1 - f(29 / (H + 1)) * f((21 - G) / 11));
    const J = (year + f(year / 4) + I + 2 - C + f(C / 4)) % 7;
    const L = I - J;
    const month = 3 + f((L + 40) / 44);
    const day = L + 28 - 31 * f(month / 4);
    return moment(new Date(year, month - 1, day));
  }
  // -- Shared Holidays (apply to multiple provinces) --
}
