import React, { ReactElement, useState, memo, useEffect } from 'react';
import moment, { Moment } from 'moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import ResponsiveTopBar from './components/ResponsiveTopBar';
import MainSchedule from './components/MainSchedule';
import WeekNavigation from 'components/WeekNavigation';
import styles from './Locations.module.scss';
import { ScheduleTypes } from 'lib/scheduleTypes';
import { LocationsSchedule } from './useLocations.Interface';
import formatDate, { DateTimeFormat, DateTimeFormatString } from 'lib/dateFormatter';
import { Staff } from 'api/user/user.interfaces';
import { LocationListItem } from 'types';

import Ontario from '../../components/Holidays/Ontario';
import NewBrunswick from 'components/Holidays/NewBrunswick';
import NovaScotia from 'components/Holidays/NovaScotia';

const newBruinswickHoliday = new NewBrunswick();
const novaScotiaHoliday = new NovaScotia();
const ontarioHoliday = new Ontario();

interface LocationsProps {
  onChangeSearchQuery: (string) => void;
  onNewLocationClick: () => void;
  onSendSchedulesClick: () => void;
  onDialogSaveSuccess: () => Promise<void>;
  onDeleteLocationClick: (locationToDelete) => void;
  onEditLocationClick: (locationToEdit) => void;
  onSelectLocation: (locationId: number) => void;
  onSelectDate: (date: Moment) => void;
  locationIds?: Array<number>;
  searchQuery?: string;
  locations: Array<LocationListItem>;
  locationsSchedule: Array<LocationsSchedule>;
  date: Moment;
  staffList: Array<Staff>;
  highlightedStaff: number;
  setHighlightedStaff: (staff) => void;
  onLocationScheduleSaveSuccess: () => Promise<void>;
  updateUrlDate: (day) => void;
}

function Locations({
  onChangeSearchQuery,
  onNewLocationClick,
  onSendSchedulesClick,
  onDeleteLocationClick,
  onEditLocationClick,
  onSelectLocation,
  onDialogSaveSuccess,
  onSelectDate,
  setHighlightedStaff,
  highlightedStaff,
  searchQuery = '',
  locationIds = [],
  locations = [],
  locationsSchedule,
  date,
  staffList = [],
  onLocationScheduleSaveSuccess,
  updateUrlDate,
}: LocationsProps): ReactElement {
  const [selectedLocationIds, handleSelectedLocationIdsChange] = useState<number[]>([]);
  const [startOfWeekDate, setStartOfWeekDate] = useState<Moment>(moment().startOf('week'));

  // Ensures we constantly track the start of the week to be the basis of the calendar views
  useEffect((): void => {
    updateUrlDate(date);
    setStartOfWeekDate(moment(date).startOf('week'));
  }, [date]);

  // Finds all locations schedules for specfied location id
  function findLocationSchedulesByDay(locationId: number) {
    // TODO: Account for timezone ex. Really early start time might be wrong date
    // const timeZone: string = location.timeZone;

    // Extract all location schedules for specfied location
    const thisLocationsSchedules: Array<LocationsSchedule> = locationsSchedule.filter(
      (locationSchedule: LocationsSchedule) => {
        return locationSchedule.id === locationId;
      }
    );
    // Convert into map for each day of the week
    const mapOfScheduleByDate: Map<string, Array<LocationsSchedule>> = new Map();
    thisLocationsSchedules.forEach((locationSchedule: LocationsSchedule) => {
      const dateKey = formatDate(
        DateTimeFormat.DateOnly,
        moment(locationSchedule.start, DateTimeFormatString.APIDateAndTimeFormat)
      );
      const arrayToAppendTo: Array<LocationsSchedule> = mapOfScheduleByDate.get(dateKey) || [];
      arrayToAppendTo.push(locationSchedule);
      mapOfScheduleByDate.set(dateKey, arrayToAppendTo);
    });

    return mapOfScheduleByDate;
  }
  return (
    <div className={styles.container}>
      <div className={styles.innerContainerLimitWidthTmp}>
        <ResponsiveTopBar
          locationIds={selectedLocationIds}
          locations={locations}
          selectLocations={handleSelectedLocationIdsChange}
          searchQuery={searchQuery}
          highlightedStaff={highlightedStaff}
          setHighlightedStaff={setHighlightedStaff}
          onChangeSearchQuery={onChangeSearchQuery}
          onNewLocationClick={onNewLocationClick}
          onSendSchedulesClick={onSendSchedulesClick}
          staffList={staffList}
        />
        <WeekNavigation date={date} onDateChange={onSelectDate} type="locations" />

        <TableContainer>
          <Table aria-label="customized table" className={styles.table}>
            <TableBody>
              {locations.map((location, index) => {
                if (selectedLocationIds.length === 0 || selectedLocationIds.includes(location.id)) {
                  let scheduleTypes = [
                    ScheduleTypes.General,
                    ScheduleTypes.ConsultationAndfollowUp,
                    ScheduleTypes.Procedure,
                    ScheduleTypes.UltraSound,
                  ];
                  if (location.scheduleTypes && location.scheduleTypes.length > 0) {
                    scheduleTypes = location.scheduleTypes;
                  }

                  // Move general schedule type as first
                  const scheduleTypeGeneralIndex = scheduleTypes.findIndex(
                    el => el == ScheduleTypes.General
                  );
                  if (scheduleTypeGeneralIndex > -1) {
                    scheduleTypes.splice(scheduleTypeGeneralIndex, 1);
                    scheduleTypes.unshift(ScheduleTypes.General);
                  }

                  return (
                    <div key={index}>
                      <MainSchedule
                        staffList={staffList}
                        onEditLocationClick={onEditLocationClick}
                        locationId={location.id}
                        locationName={location.name}
                        locationExceptions={location.exceptions}
                        onDialogSaveSuccess={onDialogSaveSuccess}
                        highlightedStaff={highlightedStaff}
                        scheduleMap={findLocationSchedulesByDay(location.id)}
                        date={startOfWeekDate}
                        locationScheduleTypes={scheduleTypes}
                        onLocationScheduleSaveSuccess={onLocationScheduleSaveSuccess}
                      />
                    </div>
                  );
                } else {
                  return <div />;
                }
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default memo(Locations);
