import axios from 'axios';
import { TemplateType } from './template.types';
import { TEMPLATE } from 'lib/config';
import { getDefaultHeader } from 'api/utils';

export async function getTemplateTypes(): Promise<Array<TemplateType>> {
  const response = await axios.get(`${TEMPLATE}/templateTypes`, {
    headers: getDefaultHeader(),
  });

  const templateTypes: TemplateType[] = response.data.templateTypes.map(templateType => {
    return {
      id: templateType.id,
      name: templateType.template_type_description,
    };
  });

  return templateTypes;
}
