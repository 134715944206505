import React, { ReactElement } from 'react';
import { Route, match } from 'react-router-dom';

import FaxManagementRoute from 'routings/FaxManagement';

import FaxManagementHeader from './components/FaxManagementHeader';

import styles from './FaxManagementStyles.module.scss';

interface FaxManagementProps {
  match: match;
}

function FaxManagement({ match }: FaxManagementProps): ReactElement {
  return (
    <div className={styles.container}>
      <FaxManagementHeader parentUrl={match?.url} />
      <div className={styles.content}>
        <Route component={FaxManagementRoute} />
      </div>
    </div>
  );
}

export default FaxManagement;
