import React, { ReactElement, useEffect } from 'react';
import { Address } from 'types';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Dialog from '../Dialog';
import Select from '../Select';
import DialogButton from '../DialogButton';
import styles from './PatientEditAddressDialogStyles.module.scss';
import { DialogButtonType } from 'components/DialogButton/DialogButton.view';
import { CanadaProvinceFullName } from 'lib/addresses';

interface Error {
  line1?: string;
  line2?: string;
  city?: string;
  province?: string;
  postalCode?: string;
  country?: string;
}

interface PatientEditAddressDialogProps {
  open: boolean;
  value: Address;
  errors?: Error;
  title?: string;
  loading?: boolean;
  newPatient?: boolean;
  onChange: (name: string, value: string) => void;
  onClose?: () => void;
  onSave?: () => void;
}

function PatientEditAddressDialog({
  open = false,
  value,
  errors,
  title,
  onChange,
  onClose,
  onSave,
  loading = false,
}: PatientEditAddressDialogProps): ReactElement {
  function onChangeValue(name, value) {
    onChange(name, value);
  }

  return (
    <Dialog open={open} title={title || 'Edit patient address'} onClose={onClose}>
      <div className={styles.container}>
        <div>
          <form className={styles.formContainer}>
            <div className={styles.formColumn}>
              <div className={styles.formFieldContainer}>
                <TextField
                  fullWidth
                  name={'line1'}
                  label="Line 1"
                  placeholder="Enter Line 1 of Address"
                  onChange={({ target }) => onChangeValue(target.name, target.value)}
                  value={value.line1}
                  error={!!errors?.line1}
                  helperText={errors?.line1}
                />
              </div>
              <div className={styles.formFieldContainer}>
                <TextField
                  fullWidth
                  name={'line2'}
                  label="Line 2"
                  placeholder="Enter Line 2 of Address"
                  onChange={({ target }) => onChangeValue(target.name, target.value)}
                  value={value.line2}
                  error={!!errors?.line2}
                  helperText={errors?.line2}
                />
              </div>
              <div className={styles.formFieldContainer}>
                <TextField
                  fullWidth
                  name={'city'}
                  label="City"
                  placeholder="Enter City"
                  onChange={({ target }) => onChangeValue(target.name, target.value)}
                  value={value.city}
                  error={!!errors?.city}
                  helperText={errors?.city}
                />
              </div>
              <div className={styles.formFieldContainer}>
                <Select
                  fullWidth
                  label={'Province'}
                  name={'province'}
                  renderValue={value => <div className={styles.selectItem}>{value}</div>}
                  value={value.province}
                  onChange={({ target }) => {
                    if (target.value !== 'N/A' && value.country === 'International') {
                      onChangeValue('country', 'Canada');
                    }
                    onChangeValue(target.name, target.value);
                  }}
                >
                  <MenuItem key="N/A" value="N/A" className={styles.selectItem}>
                    {'N/A'}
                  </MenuItem>
                  {Object.values(CanadaProvinceFullName).map(item => (
                    <MenuItem key={item} value={item} className={styles.selectItem}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className={styles.formFieldContainer}>
                <TextField
                  fullWidth
                  name={'postalCode'}
                  label="Postal Code"
                  placeholder="Enter Postal Code"
                  onChange={({ target }) => onChangeValue(target.name, target.value)}
                  value={value.postalCode}
                  error={!!errors?.postalCode}
                  helperText={errors?.postalCode}
                />
              </div>
              <div className={styles.formFieldContainer}>
                <Select
                  fullWidth
                  label={'Country'}
                  name={'country'}
                  renderValue={value => <div className={styles.selectItem}>{value}</div>}
                  value={value.country}
                  onChange={({ target }) => {
                    if (target.value == 'International') {
                      onChangeValue('province', 'N/A');
                    }
                    onChangeValue(target.name, target.value);
                  }}
                >
                  <MenuItem key="Canada" value="Canada" className={styles.selectItem}>
                    {'Canada'}
                  </MenuItem>
                  <MenuItem key="International" value="International" className={styles.selectItem}>
                    {'International'}
                  </MenuItem>
                </Select>
              </div>
            </div>
          </form>
          <div className={styles.buttonSection}>
            <DialogButton buttonType={DialogButtonType.NegationLink} onClick={onClose}>
              Cancel
            </DialogButton>
            <DialogButton
              buttonType={DialogButtonType.AffirmativeLink}
              className={styles.btn}
              onClick={onSave}
              loading={loading}
            >
              Save
            </DialogButton>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default PatientEditAddressDialog;
