import * as base from './lib/baseActions';
import {
  RequestLogin,
  ResponseLogin,
  RequestResetPassword,
  ResponseResetPassword,
  RequestForgotPassword,
  ResponseForgotPassword,
  RequestGetUsers,
  ResponseGetUsers,
  TokenBody,
  User,
} from '../types';
import { ACCESS_TOKEN, REFRESH_TOKEN } from 'lib/const';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import { GET_MY_USER_INFO, LOGIN } from 'lib/config';

const fetchUser = async () => {
  const token = localStorage.getItem(ACCESS_TOKEN);

  const response = await axios(GET_MY_USER_INFO, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

export interface LoginUserData {
  email: string;
  password: string;
  passwordConfirmation?: string;
}

export interface AuthResult {
  data: {
    token: string;
  };
}

export interface LoginUserResult {
  data: {
    first_name?: string;
    email?: string;
  };
}

const login = async (data: RequestLogin): Promise<ResponseLogin> => {
  const email = data.email;
  const password = data.password;

  try {
    const loginResult = await axios(LOGIN, {
      method: 'POST',
      data: JSON.stringify({
        email: email,
        password: password,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    localStorage.setItem(ACCESS_TOKEN, loginResult.data.authToken);
    localStorage.setItem(REFRESH_TOKEN, loginResult.data.refreshToken);

    const token = localStorage.getItem(ACCESS_TOKEN);

    const responseMe = await axios(GET_MY_USER_INFO, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    const decoded: TokenBody = jwtDecode(loginResult.data.authToken);

    return {
      success: true,
      status: responseMe.status,
      data: {
        id: responseMe.data.id,
        role: decoded.role,
        firstName: responseMe.data.firstName,
        lastName: responseMe.data.lastName,
        email: responseMe.data.email,
        type: responseMe.data.type,
      },
    };
  } catch (err) {
    if (err.status === 400) {
      err.message = 'Incorrect Email or Password.';
    }

    return Promise.reject(err);
  }
};

/// logout user
const logoutUser = async () => {
  localStorage.removeItem(ACCESS_TOKEN);

  return Promise.resolve();
};

/// check confirmation helper function
const checkRegistrationInformation = (data: LoginUserData) => {
  const check: { success: boolean; errors?: any } = { success: true };
  const errors: Array<Error> = [];
  if (!data.email || data.email.length < 5) {
    // does not have email
    errors.push(new Error('You must fill in a valid email'));
  }

  if (!data.password || !data.passwordConfirmation) {
    errors.push(new Error('You must fill in the password and password confirmation field'));
  } else if (data.password.length < 8) {
    errors.push(new Error('The password has a minimum length of 8'));
  } else if (data.password !== data.passwordConfirmation) {
    errors.push(new Error('The password and password confirmation must be the same'));
  }

  if (errors.length > 0) {
    check.success = false;
    check.errors = errors;
  }

  return check;
};

// create login user
// data : { email: "email@email.com", password:"password", confirmPassword:"password" }
const createUser = async (data: LoginUserData) => {
  const registrationCheck = checkRegistrationInformation(data);

  if (!registrationCheck.success) {
    return Promise.reject('cannot create user, info failed');
  } else {
    const userResult = await base.post('users', data);

    localStorage.setItem('auth_token', userResult.data.token);
    return base.get('users/me');
  }
};

// check if user is Authorized
const userIsAuthorized = () => {
  const initialAuthToken = localStorage && localStorage.getItem('auth_token');
  return initialAuthToken ? true : false;
};

const resetPassword = async (
  data: RequestResetPassword,
  email: string,
  resetKey: string
): Promise<ResponseResetPassword> => {
  const endpoint = '/resetPassword?email=' + email + '&resetKey=' + resetKey;

  try {
    const resetPasswordResponse: any = await base.post(endpoint, data);

    const response: ResponseResetPassword = {
      status: resetPasswordResponse.status,
      success: true,
    };

    return Promise.resolve(response);
  } catch (error) {
    const response: ResponseResetPassword = {
      status: error.status,
      success: false,
      error: error,
    };

    return Promise.reject(response);
  }
};
// Request Forgot Password
const requestForgotPassword = async (
  data: RequestForgotPassword
): Promise<ResponseForgotPassword> => {
  try {
    const responseObject: any = await base.post('forgotPassword', data);

    const response: ResponseForgotPassword = {
      status: responseObject.status,
      success: true,
    };

    return Promise.resolve(response);
  } catch (err) {
    const response: ResponseForgotPassword = {
      status: err.status,
      success: false,
      error: err,
    };

    return Promise.reject(response);
  }
};

const getUsers = async (data: RequestGetUsers): Promise<ResponseGetUsers> => {
  try {
    const responseObject: any = await base.post('forgotPassword', data);

    const users: [User] = responseObject.data.map((item: any) => {
      const user: User = {
        id: item.id,
        email: item.email,
        firstName: item.firstName,
        lastName: item.lastName,
        fullName: item.fullName,
        type: item.type,
        addresses: item.addresses,
        contacts: item.contacts,
        patientInfo: item.patientInfo,
        staffInfo: item.staffInfo,
        createdAt: item.createdAt,
        updatedAt: item.updatedAt,
      };
      return user;
    });

    const response: ResponseGetUsers = {
      status: responseObject.status,
      success: true,
      data: users,
    };

    return Promise.resolve(response);
  } catch (err) {
    const response: ResponseGetUsers = {
      status: err.status,
      success: false,
      error: err,
    };

    return Promise.reject(response);
  }
};

export {
  fetchUser,
  login,
  getUsers,
  logoutUser,
  createUser,
  userIsAuthorized,
  resetPassword,
  requestForgotPassword,
};
