import React, { ReactElement } from 'react';
import { clone, capitalize } from 'lodash';
import Checkbox from 'components/Checkbox';

import styles from './QuestionSectionStyles.module.scss';
import classNames from 'classnames';

interface MultipleQuestionSectionProps {
  title?: string;
  description?: string;
  options?: Array<any>;
  value: Array<any>;
  onChange?(value): void;
  defaultValue: Array<any>;
  required?: boolean;
  optionExtractor?: (option) => string;
  answerExtractor?: (option) => string;
  optionCapitalize?: (option: string) => string;
}

function defaultOptionExtractor(option): string {
  return option?.value;
}

function isDefaultChecked(
  answers: Array<any>,
  option: any,
  answerExtractor,
  optionExtractor
): boolean {
  return !!answers?.find(a => answerExtractor(a) === optionExtractor(option));
}

function getAnswer(answers, option) {
  const answer = answers.find(a => {
    return a?.value === option?.value;
  });

  if (answer) {
    // eslint-disable-next-line @typescript-eslint/camelcase
    answer.questionOptionId = option.id;
  }

  return answer;
}

function MultipleQuestionSection({
  title,
  description,
  options = [],
  value = [],
  onChange = () => null,
  defaultValue = [],
  required,
  optionExtractor,
  answerExtractor,
  optionCapitalize,
}: MultipleQuestionSectionProps): ReactElement {
  const currentOptionExtractor = optionExtractor || defaultOptionExtractor;
  const currentAnswerExtractor = answerExtractor || defaultOptionExtractor;

  function handleChange(option, checked, currentValue): void {
    const newValues = (value && value.length > 0 ? clone(value) : clone(defaultValue)).filter(
      nv => {
        return nv.questionOptionId !== option.id;
      }
    );

    newValues.push({
      value: checked ? option.value : '',
      questionOptionId: option.id,
    });

    onChange(newValues);
  }

  return (
    <div className={styles.main}>
      <div className={styles.title}>
        {title} {required ? <span className={styles.reqMarker}>*</span> : null}
      </div>
      <div className={classNames(styles.title, styles.statement)}>{description}</div>
      <div className={styles.answerContainer}>
        <div className={classNames(styles.answer, styles.multipleChoice)}>
          {options.map((option, index) => {
            const currentValue = getAnswer(value, option);

            return (
              <Checkbox
                key={currentOptionExtractor(option)}
                title={
                  optionCapitalize
                    ? optionCapitalize(currentOptionExtractor(option))
                    : capitalize(currentOptionExtractor(option))
                }
                checked={isDefaultChecked(
                  value.length ? value : defaultValue,
                  option,
                  currentAnswerExtractor,
                  currentOptionExtractor
                )}
                onChange={e => {
                  const checked = e.target.checked;
                  handleChange(option, checked, currentValue);
                }}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default MultipleQuestionSection;
