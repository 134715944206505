import React, { ReactElement } from 'react';
import styles from './DayHeader.module.scss';

const DAY_TO_HEADER: { [index: number]: string } = {
  1: 'Consultations',
  2: 'Procedures',
  3: 'Follow ups',
};

interface DayHeaderProps {
  date?: Date;
  label?: string;
}

function DayHeader({ date = new Date() }: DayHeaderProps): ReactElement {
  const columnName: string = DAY_TO_HEADER[date.getDate()];

  return (
    <div className={styles.container}>
      <div className={styles.day}>{columnName}</div>
    </div>
  );
}

export default DayHeader;
