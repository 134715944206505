import React, { ReactElement } from 'react';
import ActionConfirmationDialog from '../ActionConfirmationDialog';

interface ChangesInProgressWarningDialogProps {
  open: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
}

function ChangesInProgressWarningDialog({
  open,
  onClose,
  onConfirm,
}: ChangesInProgressWarningDialogProps): ReactElement {
  return (
    <ActionConfirmationDialog
      open={open}
      title={'Changes have been made.'}
      description={'Are you sure your would like to proceed?'}
      onClose={onClose}
      onConfirm={onConfirm}
    />
  );
}

export default ChangesInProgressWarningDialog;
