import React, { ReactElement } from 'react';

import RowRecord from 'components/RowRecord';

import styles from './UserInfoStyles.module.scss';
import { ROLE } from 'lib/user';

interface UserInfoProps {
  avatar?: string;
  type?: string;
  phone?: string;
  email?: string;
  name?: string;
  ohipPhysicianId?: string;
  abbreviations?: string;
}

function UserInfo({
  avatar,
  name = 'N/A',
  type = 'N/A',
  phone = 'N/A',
  email = 'N/A',
  ohipPhysicianId = 'N/A',
  abbreviations = 'N/A',
}: UserInfoProps): ReactElement {
  return (
    <div className={styles.main}>
      <div className={styles.avatar} style={{ backgroundImage: `url(${avatar})` }} />
      <div className={styles.data}>
        <div className={styles.nameAndType}>
          <div className={styles.name}>{name}</div>
          <div className={styles.type}>{type}</div>
        </div>
        {/* <RowRecord name="Phone" value={phone} /> */}
        {type === ROLE.DOCTOR && <RowRecord name="Ohip Physician Id" value={ohipPhysicianId} />}
        {/* <RowRecord name="Email" value={email} /> */}
      </div>
    </div>
  );
}

export default UserInfo;
