import React, { ReactElement, useEffect, useRef, useState } from 'react';
import moment, { isMoment } from 'moment';
import Dialog from 'components/Dialog';
import Button from 'components/CVCButton';
import Paper from 'components/Paper';
import { AlertDialog } from 'components/AlertDialog';
import styles from './NewAppointmentDialog.module.scss';
import {
  User,
  Location,
  AppointmentInfo,
  Appointment,
  SubType,
  Document,
  RescheduleInfo,
  ContactType,
  OtherPhysician,
  DoctorSchedule,
} from 'types';
import Select from '../Select';
import {
  APPOINTMENT_TYPES,
  findByTypeName,
  APPOINTMENT_STATUSES,
  APPOINTMENT_TYPE_IDS,
  SLOT_LENGTH_MINIMUM_5_MINUTES,
  MINIMUM_APPOINTMENT_LENGTH,
} from 'lib/appointments';
import { Checkbox, CircularProgress, MenuItem, TextField } from '@material-ui/core';
import formatDate, { DateTimeFormat, DateTimeFormatString } from 'lib/dateFormatter';
import { AppointmentsApi, UserApi, LocationApi, CaseApi } from 'api';
import { Staff as UserStaff } from 'api/user/user.interfaces';
import AppointmentStatusSelect from 'components/AppointmentStatusSelect';
import SaveAppointmentDialog from '../SaveAppointmentsDialog/SaveAppointmentDialog.view';
import CVCDatePicker from 'components/CVCDatePicker';
import classNames from 'classnames';
import DialogButton from 'components/DialogButton';
import { DialogButtonType } from 'components/DialogButton/DialogButton.view';
import {
  getEndTimesArrayFiveMinInterval,
  getStartTimesArrayFiveMinInterval,
  convertTo12From24,
} from 'lib/schedule';
import { NewPatientDialogContainer } from '../../..//PatientList/components/NewPatientDialog';
import { Case } from 'api/case/case.interfaces';
import { ROLE_ID, ROLE, ADD_NEW_PHYSICIAN } from 'lib/user';
import Autocomplete from './components/Autocomplete';
import OtherPhysicianAutoComplete from 'components/OtherPhysicianAutoComplete';
import OtherPhysicianEditDialog from 'components/OtherPhysicianEditDialog';
import formatPhoneInput from 'lib/phoneNumFormatter';
import { healthCardValidationRule, versionCodeValidationRule } from 'lib/validator';
import { CanadaProvinceCodes } from 'lib/addresses';
import _ from 'lodash';
import { ValidationError } from 'components/Forms/ValidationError';
import InfoRow from 'components/InfoRow';
import { formatHealthCard } from 'lib/patient';
import useUnsavedChangesWarning from 'hook/useUnsavedChangesWarning';
import ValidateHealthcardDialog from 'components/ValidateHealthcardDialog';

interface NewAppointmentDialogContainer {
  open?: boolean;
  error: string;
  message: string;
  responseAppt?: any;
  staffListForLocation: Array<UserStaff>;
  staffSchedules: Array<DoctorSchedule>;
  appointmentCreated?: boolean;
  appointmentValues: AppointmentInfo | undefined;
  appointmentsScheduled;
  locationId: number;
  presetPatientId: number | undefined;
  rescheduleAppointmentInfo: RescheduleInfo | undefined;
  setRescheduleAppointmentInfo;
  date;
  subTypes;
  showConfirmation;
  past72HoursAppointment: boolean;
  setShowConfirmation: (boolean) => void;
  onClose: () => void;
  onSave: (data, referralFile, selectedPatientId) => void;
  onUpdate: (id, data, referralFile, selectedPatientId) => void;
}

function NewAppointmentDialog({
  open,
  error,
  message,
  staffListForLocation,
  staffSchedules,
  appointmentCreated,
  responseAppt,
  appointmentsScheduled,
  appointmentValues,
  locationId,
  date,
  subTypes,
  presetPatientId,
  rescheduleAppointmentInfo,
  setRescheduleAppointmentInfo,
  showConfirmation,
  past72HoursAppointment,
  setShowConfirmation,
  onClose,
  onSave,
  onUpdate,
}: NewAppointmentDialogContainer): ReactElement {
  const [openWarningDialog, setOpenWarningDialog] = useState<boolean>(false);
  const [existingAppointmentId, setExistingAppointmentId] = useState<number>(0);
  const [selectedLength, setSelectedLength] = useState<number | undefined>(60);
  const [staffList, setStaffList] = useState<Array<UserStaff>>([]);
  const [otherPhysicians, setOtherPhysicians] = useState<Array<OtherPhysician>>([]);
  const [selectedTypeStaffSchedules, setSelectedTypeStaffSchedules] = useState<Array<UserStaff>>(
    []
  );
  const [filteredStaffList, setFilteredStaffList] = useState<Array<UserStaff>>([]);
  const [nurseList, setNurseList] = useState<Array<UserStaff>>([]);
  const [technicianList, setTechnicianList] = useState<Array<UserStaff>>([]);
  const [selectedStaff, setSelectedStaff] = useState<UserStaff>();
  const [selectedOptionalNurse, setSelectedOptionalNurse] = useState<UserStaff>();
  const [selectedOptionalTechnician, setSelectedOptionalTechnician] = useState<UserStaff>();
  const [startTime, setStartTime] = useState<string>('');
  const [endTime, setEndTime] = useState<string>('');
  const [selectedSubTypes, setSelectedSubTypes] = useState<Array<SubType>>([]);
  const [selectedType, setSelectedType] = useState<number>(0);
  const [mainType, setMainType] = useState<number | undefined>(undefined);
  const [cancellationList, setCancellationList] = useState<boolean>(false);
  const [startTimes, setStartTimes] = useState<Array<string>>([]);
  const [endTimes, setEndTimes] = useState<Array<string>>([]);
  const [fullFilteredStartTimes, setFullFilteredStartTimes] = useState<Array<string>>([]);
  const [fullFilteredEndTimes, setFullFilteredEndTimes] = useState<Array<string>>([]);
  const [locationInfo, setLocationInfo] = useState<Location>();
  const [openNewPatientDialog, setOpenNewPatientDialog] = useState<boolean>(false);
  const [openValidateHealthcardDialog, setOpenValidateHealthcardDialog] = useState<boolean>(false);
  const [appointmentStatus, setAppointmentStatus] = useState<string>();
  const [selectedPatient, setSelectedPatient] = useState<User>();
  const [options, setOptions] = useState<any | undefined>([]);
  const [familyPhysicianOptions, setFamilyPhysicianOptions] = useState<any | undefined>([]);
  const [referrerPhysicianOptions, setReferrerPhysicianOptions] = useState<any | undefined>([]);
  const [patientSearchQuery, setPatientSearchQuery] = useState<string>('');
  const [familyPhysicianSearchQuery, setFamilyPhysicianSearchQuery] = useState<string>('');
  const [referrerPhysicianSearchQuery, setReferrerPhysicianSearchQuery] = useState<string>('');
  const [patientLoading, setPatientLoading] = useState<boolean>(true);
  const [patientInfoState, setPatientInfoState] = useState({
    dateOfBirth: '',
    healthCareProvinceCode: '',
    healthCardNumber: '',
    versionCode: '',
    email: '',
    phone: '',
    cellPhone: '',
    homePhone: '',
  });
  const [caseID, setCaseID] = useState<Case | undefined>();
  const [selectedFamilyPhysician, setSelectedFamilyPhysician] = useState<OtherPhysician>();
  const [description, setDescription] = useState<string>('');
  const [updateFamilyPhysician, setUpdateFamilyPhysician] = useState<boolean>(false);
  const [updateReferralPhysician, setUpdateReferralPhysician] = useState<boolean>(false);
  const [isInternationalPatient, setIsInternationalPatient] = useState<boolean>(false);
  const [updatePatientInfo, setUpdatePatientInfo] = useState<boolean>(false);
  const [selectedReferralPhysician, setSelectedReferralPhysician] = useState<OtherPhysician>();
  const [referrerDocument, setReferrerDocument] = useState<Document | null>(null);
  const [needReferral, setNeedReferral] = useState<boolean>(false);
  const [sameAsFamilyDoctor, setSameAsFamilyDoctor] = useState<boolean>(false);
  const fileInput = useRef<any>(null);
  const [referralFile, setReferralFile] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [allStaffSchedules, setAllStaffSchedules] = useState<Array<any>>([]);
  const [appointmentStatusChanged, setAppointmentStatusChanged] = useState<boolean>(false);
  const [oldAppt, setOldAppointment] = useState<any>();
  const [validationErrors, setValidationErrors] = useState<Array<any>>([]);
  const [healthCardError, setHealthCardError] = useState<boolean>(false);
  const [healthCardHelperText, setHealthCardHelperText] = useState<string>('');
  const [versionCodeError, setVersionCodeError] = useState<boolean>(false);
  const [versionCodeHelperText, setVersionCodeHelperText] = useState<string>('');
  const [createNewReferrerPhysician, setCreateNewReferrerPhysician] = useState<boolean>(false);
  const [createNewFamilyPhysician, setCreateNewFamilyPhysician] = useState<boolean>(false);
  const [openCreatePhysicianDialog, setOpenCreatePhysicianDialog] = useState<boolean>(false);
  const [askForEmailConfirmation, setAskForEmailConfirmation] = useState<boolean>(false);
  const [physicianToUpdate, setPhysicianToUpdate] = useState<OtherPhysician | undefined>(undefined);
  const [pastAppointment, setPastAppointment] = useState<boolean>(false);

  const resetReferralPhysician = (): void => {
    setSelectedReferralPhysician(undefined);
  };
  const resetState = (): void => {
    setPatientInfoState({
      dateOfBirth: '',
      healthCareProvinceCode: '',
      healthCardNumber: '',
      versionCode: '',
      email: '',
      phone: '',
      cellPhone: '',
      homePhone: '',
    });

    setPastAppointment(false);
    setSelectedFamilyPhysician(undefined);
    resetReferralPhysician();
    setValidationErrors([]);
    setUpdateFamilyPhysician(false);
    setUpdateReferralPhysician(false);
    setSameAsFamilyDoctor(false);
    setReferrerDocument(null);
    setReferralFile(null);
    setSelectedPatient(undefined);
    setSelectedStaff(undefined);
    setUpdatePatientInfo(false);
    setUpdateFamilyPhysician(false);
    setNeedReferral(false);
    setExistingAppointmentId(0);
    setAppointmentStatus(undefined);
    setSelectedOptionalTechnician(undefined);
    setSelectedOptionalNurse(undefined);
    setAppointmentStatusChanged(false);
    setOldAppointment(undefined);
    setEndTime('');
    setStartTime('');
    setStartTimes([]);
    setEndTimes([]);
    setDescription('');
    setMainType(undefined);
    setSelectedType(0);
    setSelectedSubTypes([]);
    setCancellationList(false);
    setCaseID(undefined);
    setAllStaffSchedules([]);
    setLoading(false);
  };

  const onLocalClose = (): void => {
    setShowConfirmation(false);
    onClose();
    resetState();
  };

  const [
    Prompt,
    LocalPrompt,
    changesMade,
    setChangesMade,
    setWarningOpen,
  ] = useUnsavedChangesWarning(onLocalClose);

  /*  
    Use filterByStaffType for data importing 
      - Set to TRUE for production 
      - FALSE if you want every staff available for every appointment type
  */
  const filterByStaffType = true;

  function validateForm(): boolean {
    let validated = true;
    const validationErrors: Array<any> = [];
    if (!selectedPatient) {
      const validationError = {
        field: 'selectedPatient',
        message: 'Select a patient',
      };
      validationErrors.push(validationError);
      validated = false;
    }
    if (!selectedType) {
      const validationError = {
        field: 'subType',
        message: 'Please select a sub type',
      };

      validationErrors.push(validationError);
      validated = false;
    }
    if (startTime === '' || !startTimes.includes(startTime)) {
      const validationError = {
        field: 'startTime',
        message: 'Please select a start time',
      };

      validationErrors.push(validationError);
      validated = false;
    }

    if (endTime === '' || !endTimes.includes(endTime)) {
      const validationError = {
        field: 'endTime',
        message: 'Please select an end time',
      };

      validationErrors.push(validationError);
      validated = false;
    }

    if (!selectedStaff) {
      const validationError = {
        field: 'selectedStaff',
        message: 'Please select a staff member',
      };

      validationErrors.push(validationError);
      validated = false;
    }

    if (!isInternationalPatient && !patientInfoState.healthCardNumber) {
      const validationError = {
        field: 'healthCard',
        message: 'Please provide a health card number',
      };

      validationErrors.push(validationError);
      validated = false;
    }
    if (patientInfoState.dateOfBirth == '') {
      const validationError = {
        field: 'dateOfBirth',
        message: 'Please enter date of birth',
      };

      setUpdatePatientInfo(true);
      validationErrors.push(validationError);
      validated = false;
    }

    setValidationErrors(validationErrors);
    return validated;
  }

  // *** API CALLS FOR STATE DATA *** //

  async function getPatients(searchQuery: string | undefined = undefined): Promise<void> {
    if (!open) return;
    setPatientLoading(true);
    const users = await UserApi.getPatientList(searchQuery, '', locationId, null, null, null);
    const patientAutocomplete = users?.map(option => {
      const lastNameLetter = option.lastName ? option.lastName[0].toUpperCase() : '';
      return {
        lastNameLetter: /[0-9]/.test(lastNameLetter) ? '0-9' : lastNameLetter,
        ...option,
      };
    });
    setOptions(
      patientAutocomplete.sort((a, b) => {
        return a.lastNameLetter.localeCompare(b.lastNameLetter);
      })
    );
    setPatientLoading(false);
  }

  async function getOtherPhysicians(searchQuery: string | undefined): Promise<any> {
    if (searchQuery === ADD_NEW_PHYSICIAN) return [];
    const otherPhysicians = await UserApi.getOtherPhysiciansList(searchQuery);

    const autoComplete = otherPhysicians?.map(physician => {
      const lastNameLetter = physician.lastName ? physician.lastName[0].toUpperCase() : '';
      return {
        lastNameLetter: /[0-9]/.test(lastNameLetter) ? '0-9' : lastNameLetter,
        ...physician,
      };
    });

    return autoComplete.sort((a, b) => {
      return a.lastNameLetter.localeCompare(b.lastNameLetter);
    });
  }

  async function getOtherPhysicianById(id: number): Promise<any> {
    return await UserApi.getOtherPhysicianById(id);
  }

  const getFamilyPhysicianList = async (
    searchQuery: string | undefined = undefined
  ): Promise<void> => {
    const filteredPhysicians = await getOtherPhysicians(searchQuery);
    setFamilyPhysicianOptions(filteredPhysicians);
  };

  const getReferrerPhysicianList = async (
    searchQuery: string | undefined = undefined
  ): Promise<void> => {
    const filteredPhysicians = await getOtherPhysicians(searchQuery);
    setReferrerPhysicianOptions(filteredPhysicians);
  };

  const onNewPatientSaveSuccess = async (): Promise<void> => {
    setOpenNewPatientDialog(false);
    await getPatients();
  };

  async function getLocationInfo(): Promise<void> {
    const locationInfo = await LocationApi.getLocation(locationId);
    setLocationInfo(locationInfo);
  }

  async function getPatientCaseInfo(userId): Promise<void> {
    const caseId = await CaseApi.getCaseByUserId(userId);
    setCaseID(caseId[0]);
  }
  // ***  *** //

  // *** ONCHANGE HANDLERS *** //
  const handlePatientInfoChange = (event): void => {
    setChangesMade(true);
    if (isMoment(event)) {
      setPatientInfoState(prevState => ({
        ...prevState,
        dateOfBirth: event.format(DateTimeFormatString.APIDateFormat),
      }));
    } else {
      const { name, value } = event.target;
      if (name === 'healthCardNumber' || name === 'healthCareProvinceCode') {
        const healthCardValidation = healthCardValidationRule(
          name === 'healthCardNumber' ? value : patientInfoState.healthCardNumber,
          name === 'healthCardNumber' ? patientInfoState.healthCareProvinceCode : value,
          isInternationalPatient
        );
        if (healthCardValidation) {
          setHealthCardError(false);
          setHealthCardHelperText('');
        } else {
          setHealthCardError(true);
          setHealthCardHelperText('Please type a valid health-card number');
        }
      } else if (name === 'versionCode') {
        const versionCodeValidation = versionCodeValidationRule(
          value,
          patientInfoState.healthCareProvinceCode
        );
        if (versionCodeValidation) {
          setVersionCodeError(false);
          setVersionCodeHelperText('');
        } else {
          setVersionCodeError(true);
          setVersionCodeHelperText('Please type a valid version code');
        }
      }
      setPatientInfoState(prevState => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleHealthCardCheckBox = (event): void => {
    setChangesMade(true);
    if (patientInfoState.healthCardNumber != '') {
      setOpenWarningDialog(true);
      return;
    }
    setIsInternationalPatient(event.target.checked);
  };

  const handleLengthChange = ({ target }): void => {
    setChangesMade(true);
    setSelectedLength(target.value);
  };

  const handleStartTimeChange = ({ target }): void => {
    setChangesMade(true);
    setStartTime(target.value);
  };

  const handleEndTimeChange = ({ target }): void => {
    setChangesMade(true);
    setEndTime(target.value);
  };

  const handleStaffChange = ({ target }): void => {
    setChangesMade(true);
    const tmp = filteredStaffList.find(staff => staff.id === target.value);
    if (selectedOptionalTechnician?.id === tmp?.id) setSelectedOptionalTechnician(undefined);
    if (selectedOptionalNurse?.id === tmp?.id) setSelectedOptionalNurse(undefined);
    setSelectedStaff(tmp);
  };

  const handleFileChange = (event): void => {
    setChangesMade(true);
    event.stopPropagation();
    event.preventDefault();
    const selectedFile = event.target.files[0];
    setReferralFile(selectedFile);
  };

  const handleNurseChange = ({ target }): void => {
    setChangesMade(true);
    const tmp = nurseList.find(staff => staff.id === target.value);
    setSelectedOptionalNurse(tmp);
  };

  const handleTechnicianChange = ({ target }): void => {
    setChangesMade(true);
    const tmp = technicianList.find(staff => staff.id === target.value);
    setSelectedOptionalTechnician(tmp);
  };

  const handleSubTypeChange = ({ target }): void => {
    setChangesMade(true);
    setSelectedType(target.value);
  };

  const openNewPatient = (): void => {
    setOpenNewPatientDialog(true);
  };

  const showChangesWarningDialog = () => {
    if (changesMade) {
      setWarningOpen(true);
    } else {
      onLocalClose();
    }
  };

  const handleReferrerPhysicianChange = (event, value): void => {
    setChangesMade(true);
    if (value?.fullName != ADD_NEW_PHYSICIAN) {
      setSelectedReferralPhysician(value);
      setCreateNewReferrerPhysician(false);
    } else {
      setSelectedReferralPhysician(undefined);
      setOpenCreatePhysicianDialog(true);
      setCreateNewFamilyPhysician(false);
      setCreateNewReferrerPhysician(true);
    }
  };

  const handleFamilyPhysicianChange = (event, value): void => {
    setChangesMade(true);
    if (value?.fullName != ADD_NEW_PHYSICIAN) {
      setSelectedFamilyPhysician(value);
      setCreateNewFamilyPhysician(false);
    } else {
      setSelectedFamilyPhysician(undefined);
      setOpenCreatePhysicianDialog(true);
      setCreateNewFamilyPhysician(true);
      setCreateNewReferrerPhysician(false);
    }
  };

  const handlePatientChange = async (event, value): Promise<void> => {
    if (value?.fullName != '+ Add New Patient') {
      setChangesMade(true);
      const patient = value;
      setSelectedPatient(patient);

      const DOB = patient?.patientInfo?.dateOfBirth ? patient.patientInfo?.dateOfBirth : '';
      const tmpEmail = patient?.email ? patient?.email : '';

      let mainPhone = '';
      let cellPhone = '';
      let homePhone = '';
      if (patient?.contacts) {
        const cellPhoneIndex = patient.contacts.findIndex(val => val.type == ContactType.Cell);
        const mainPhoneIndex = patient.contacts.findIndex(val => val.type == ContactType.Phone);
        const homePhoneIndex = patient.contacts.findIndex(val => val.type == ContactType.Home);

        if (mainPhoneIndex != -1) mainPhone = patient.contacts[mainPhoneIndex].value;
        if (cellPhoneIndex != -1) cellPhone = patient.contacts[cellPhoneIndex].value;
        if (homePhoneIndex != -1) homePhone = patient.contacts[homePhoneIndex].value;
      }

      if (patient?.patientInfo?.familyDoctorId) {
        const physician = await getOtherPhysicianById(patient.patientInfo.familyDoctorId);
        setSelectedFamilyPhysician(physician);
      }

      setPatientInfoState(prevState => ({
        ...prevState,
        dateOfBirth: DOB,
        healthCareProvinceCode: patient?.patientInfo?.healthCardProvinceCode || '',
        healthCardNumber: patient?.patientInfo?.healthCard || '',
        versionCode: patient?.patientInfo?.hcn || '',
        email: tmpEmail || '',
        phone: mainPhone || '',
        cellPhone: cellPhone || '',
        homePhone: homePhone || '',
      }));

      if (patient?.patientInfo?.healthCard === '') setIsInternationalPatient(true);

      getPatientCaseInfo(patient?.id);
    } else {
      openNewPatient();
    }
  };

  const handleDescriptionChange = (e): void => {
    setChangesMade(true);
    const { value } = e.target;
    setDescription(value);
  };

  const handleSameAsFamilyDoctor = (): void => {
    setChangesMade(true);
    const isSame = !sameAsFamilyDoctor;
    setSameAsFamilyDoctor(isSame);
    if (!isSame) {
      resetReferralPhysician();
    }
  };

  const onCreatePhysicianSaveSuccess = (physician: OtherPhysician) => {
    setPhysicianToUpdate(undefined);
    if (createNewFamilyPhysician) {
      setSelectedFamilyPhysician(physician);
      if (selectedReferralPhysician?.id == physician.id) setSelectedReferralPhysician(physician);
    } else if (createNewReferrerPhysician) {
      setSelectedReferralPhysician(physician);
      if (selectedFamilyPhysician?.id == physician.id) setSelectedFamilyPhysician(physician);
    }
    setOpenCreatePhysicianDialog(false);
  };

  const onValidateHealthcardSuccess = healthcardInfo => {
    setPatientInfoState(prevState => ({
      ...prevState,
      dateOfBirth: moment(healthcardInfo.dateOfBirth).format(DateTimeFormatString.APIDateFormat),
    }));
    setOpenValidateHealthcardDialog(false);
  };

  // ***  *** //

  // *** HANDLER FOR APPOINTMENT SAVE *** //
  const handleAppointmentSave = async (): Promise<void> => {
    if (!validateForm()) return;
    setLoading(true);
    const bodyDate = date.format(DateTimeFormatString.APIDateFormat);
    if (selectedStaff) {
      const apptStatusId = APPOINTMENT_STATUSES.find(status => status.name == appointmentStatus);
      const createAppointment = {
        staffId: selectedStaff.id,
        staffType: ROLE_ID[selectedStaff.type.toUpperCase()],
        caseId: caseID?.id,
        nurseStaffId: selectedOptionalNurse?.id,
        technicianStaffId: selectedOptionalTechnician?.id,
        locationId: locationId,
        startTime: bodyDate + ' ' + startTime,
        endTime: bodyDate + ' ' + endTime,
        anyDoctor: false,
        anyNotes: false,
        scheduleType: selectedType,
        appointmentStatus: apptStatusId?.id,
        description: description,
        cancellationList: cancellationList,
        referralPhysicianId: selectedReferralPhysician?.id,
        mainTypeId: mainType,
      };

      if (existingAppointmentId) {
        await onUpdate(existingAppointmentId, createAppointment, referralFile, selectedPatient?.id);
      } else {
        await onSave(createAppointment, referralFile, selectedPatient?.id);
      }
    }

    const updatePatientInfo: any = {};
    if (updateFamilyPhysician) {
      updatePatientInfo.patientInfo = {
        ...updatePatientInfo.patientInfo,
        familyDoctorId: selectedFamilyPhysician?.id,
      };
    }
    if (updatePatientInfo) {
      updatePatientInfo.patientInfo = {
        ...updatePatientInfo.patientInfo,
        healthCard: formatHealthCard(patientInfoState.healthCardNumber),
        healthCardProvinceCode: patientInfoState.healthCareProvinceCode,
        hcnVersion: patientInfoState.versionCode,
        dateOfBirth: patientInfoState.dateOfBirth,
      };
      updatePatientInfo.email = patientInfoState.email;
      updatePatientInfo.contacts = [
        { type: ContactType.Cell, value: patientInfoState.cellPhone },
        { type: ContactType.Phone, value: patientInfoState.phone },
        { type: ContactType.Home, value: patientInfoState.homePhone },
      ];
      if (selectedPatient) {
        await UserApi.updatePatient(selectedPatient.id, updatePatientInfo);
      }
    }

    setLoading(false);
  };

  const handleReschedule = (): void => {
    setRescheduleAppointmentInfo({
      id: existingAppointmentId,
      patientName: selectedPatient?.fullName,
      subTypeId: selectedType,
      mainType: appointmentValues?.appointmentType,
    });
    onLocalClose();
  };

  const getSubTypeArray = (): void => {
    let subTypesArr: Array<SubType> = [];
    const mainType = APPOINTMENT_TYPES.find(
      element => element.name == appointmentValues?.appointmentType
    );
    if (mainType) {
      subTypesArr = subTypes.filter(subType => subType.mainTypeId == mainType.id);
    }
    setMainType(mainType?.id);
    setSelectedSubTypes(subTypesArr);
  };

  const getStartEndTimes = (): void => {
    let startTimes = getStartTimesArrayFiveMinInterval(null, null);
    let endTimes = getEndTimesArrayFiveMinInterval(null, null);

    // Filter Times arrays to include times only between start and end time of staff schedules
    startTimes = startTimes.filter(time => {
      for (const staffSchedule of allStaffSchedules) {
        if (time >= staffSchedule.startTime && time < staffSchedule.endTime) return true;
      }
      return false;
    });
    endTimes = endTimes.filter(time => {
      for (const staffSchedule of allStaffSchedules) {
        if (time > staffSchedule.startTime && time <= staffSchedule.endTime) return true;
      }
      return false;
    });

    if (selectedStaff) {
      startTimes = startTimes.filter(time => {
        for (const staffAppt of appointmentsScheduled) {
          if (
            time >= staffAppt.appointmentStartTime &&
            time < staffAppt.appointmentEndTime &&
            existingAppointmentId != staffAppt.appointmentId
          )
            return false;
        }
        return true;
      });
      endTimes = endTimes.filter(time => {
        for (const staffAppt of appointmentsScheduled) {
          if (
            time > staffAppt.appointmentStartTime &&
            time <= staffAppt.appointmentEndTime &&
            existingAppointmentId != staffAppt.appointmentId
          )
            return false;
        }
        return true;
      });
    }

    setFullFilteredStartTimes(startTimes);
    setFullFilteredEndTimes(endTimes);
  };

  const filterOptionalStaff = (
    staffList,
    nurseList: Array<UserStaff> = [],
    technicianList: Array<UserStaff> = []
  ) => {
    if (staffList || nurseList) {
      const list = staffList ? staffList : nurseList;
      const nurseOnly = list.filter(staff => {
        return (
          (staff.type === ROLE.NURSE || staff.type === ROLE.PHYSICIAN_ASSISTANT) &&
          selectedStaff?.id != staff.id
        );
      });
      setNurseList(nurseOnly);
    }

    if (staffList || technicianList) {
      const list = staffList ? staffList : technicianList;
      const technicianOnly = list.filter(staff => {
        return staff.type === ROLE.TECHNICIAN && selectedStaff?.id != staff.id;
      });
      setTechnicianList(technicianOnly);
    }
  };

  const getStaffForTimeFrame = (): void => {
    if (startTime && endTime) {
      // Filter Staff That do not fit in that time frame
      const groupedSchedulesByStaffId = _.groupBy(allStaffSchedules, 'staffId');
      const filteredByTimesStaff: Array<UserStaff> = [];

      for (const [id, schedules] of Object.entries(groupedSchedulesByStaffId)) {
        const staff = staffList.find(staff => staff.id == Number(id));

        if (!staff) continue;
        for (const schedule of schedules) {
          if (
            startTime >= schedule.startTime &&
            endTime <= schedule.endTime &&
            schedule.type == mainType
          ) {
            filteredByTimesStaff.push(staff);
            break;
          }
        }
      }

      const optionalStaffSchedules = selectedTypeStaffSchedules.filter(staff => {
        const inMainStaffList = staffList.some(item => item.id === staff.id);
        const isAvailable = filteredByTimesStaff.some(item => item.id === staff.id);
        return !inMainStaffList || isAvailable;
      });

      filterOptionalStaff(optionalStaffSchedules);
      setFilteredStaffList(filteredByTimesStaff);
    }
  };

  async function getAppointment(id, staffList: Array<UserStaff>): Promise<void> {
    setExistingAppointmentId(id);
    const oldAppt = await AppointmentsApi.getAppointmentsById(id);
    setOldAppointment(oldAppt);

    if (oldAppt.referralPhysicianId) {
      const physician = await getOtherPhysicianById(oldAppt.referralPhysicianId);
      setSelectedReferralPhysician(physician);
      setNeedReferral(true);
    }

    if (oldAppt.referralDocument) {
      setReferrerDocument(oldAppt.referralDocument);
      setNeedReferral(true);
    }
    setReferralFile(null);

    const subType = oldAppt?.subTypeId ? oldAppt?.subTypeId : 0;

    if (!rescheduleAppointmentInfo) {
      const startTime = oldAppt.startTimeOnly ? oldAppt.startTimeOnly : '';
      const endTime = oldAppt.endTimeOnly ? oldAppt.endTimeOnly : '';
      setStartTime(startTime);
      setEndTime(endTime);
      const staff = staffList.find(staff => staff.id == oldAppt.staff?.id);
      setSelectedStaff(staff);
    }

    setSelectedType(subType);

    let appointmentStatus;
    if (date.isSame(oldAppt.start, 'days')) {
      appointmentStatus = oldAppt.status?.status;
    } else {
      appointmentStatus = APPOINTMENT_STATUSES[0].name;
      setAppointmentStatusChanged(true);
    }
    setAppointmentStatus(appointmentStatus);

    const patientId = oldAppt.patient?.id;
    if (patientId) {
      const patient = await UserApi.getUser(patientId);
      handlePatientChange(null, patient);
    }

    if (oldAppt?.description) setDescription(oldAppt.description);
    else setDescription('');

    if (oldAppt?.cancellationList) setCancellationList(oldAppt.cancellationList);
    else setCancellationList(false);
  }

  const getBaseInfo = async (): Promise<void> => {
    const appointmentType = APPOINTMENT_TYPES.find(
      type => appointmentValues?.appointmentType === type.name
    );
    const filteredStaffList: Array<UserStaff> = [];
    const filteredStaffSchedules = staffSchedules.filter(
      schedule => schedule?.type === appointmentType?.id
    );
    for (const schedule of filteredStaffSchedules) {
      const staff = staffListForLocation.find(staff => schedule.staffId === staff.id);
      if (staff) filteredStaffList.push(staff);
    }

    let currentStaff: UserStaff[] = filteredStaffList;
    if (filterByStaffType) {
      if (appointmentType?.id === APPOINTMENT_TYPE_IDS.Consultation) {
        currentStaff = filteredStaffList.filter(staff => staff.type === ROLE.DOCTOR);
      } else if (appointmentType?.id === APPOINTMENT_TYPE_IDS.Procedure) {
        currentStaff = filteredStaffList.filter(
          staff =>
            staff.type === ROLE.DOCTOR ||
            staff.type === ROLE.NURSE ||
            staff.type === ROLE.PHYSICIAN_ASSISTANT
        );
      } else if (appointmentType?.id === APPOINTMENT_TYPE_IDS.Ultrasound) {
        currentStaff = filteredStaffList.filter(staff => staff.type === ROLE.TECHNICIAN);
      }
    }

    getSubTypeArray();
    if (appointmentValues?.appointmentId && appointmentValues?.appointmentId > 0) {
      getAppointment(appointmentValues?.appointmentId, currentStaff);
    } else if (rescheduleAppointmentInfo && rescheduleAppointmentInfo.id) {
      getAppointment(rescheduleAppointmentInfo.id, currentStaff);
      if (appointmentValues) {
        setStartTime(appointmentValues.startTime);
        setEndTime(appointmentValues.endTime);
        setSelectedLength(appointmentValues.length);
      }
    } else {
      if (appointmentValues) {
        setStartTime(appointmentValues.startTime);
        setEndTime(appointmentValues.endTime);
        setSelectedLength(appointmentValues.length);
      }
      getStartEndTimes();
    }

    if (appointmentValues?.staff) {
      setSelectedStaff(
        filteredStaffList.find(staff => staff.id == appointmentValues?.staff?.staffInfo?.id)
      );
    }

    setAllStaffSchedules(staffSchedules);
    setSelectedTypeStaffSchedules(filteredStaffList);
    setStaffList(currentStaff);
    getPatients();
    getLocationInfo();
  };

  const handleUpdateFamilyPhysician = () => {
    if (!selectedFamilyPhysician) return;
    setCreateNewFamilyPhysician(true);
    setCreateNewReferrerPhysician(false);
    setOpenCreatePhysicianDialog(true);
    setPhysicianToUpdate(selectedFamilyPhysician);
  };

  const handleUpdateReferralPhysician = () => {
    if (!selectedReferralPhysician) return;
    setCreateNewReferrerPhysician(true);
    setCreateNewFamilyPhysician(false);
    setOpenCreatePhysicianDialog(true);
    setPhysicianToUpdate(selectedReferralPhysician);
  };

  function closeConfirmation(): void {
    setShowConfirmation(false);
  }

  useEffect(() => {
    if (!open) {
      resetState();
      return;
    }
    getBaseInfo();
  }, [staffSchedules, appointmentsScheduled]);

  useEffect(() => {
    if (!sameAsFamilyDoctor) return;
    setSelectedReferralPhysician(selectedFamilyPhysician);
  }, [sameAsFamilyDoctor, selectedFamilyPhysician]);

  useEffect(() => {
    getStaffForTimeFrame();
    getStartEndTimes();
    if (appointmentValues?.staff) {
      setSelectedStaff(
        filteredStaffList.find(staff => staff.id == appointmentValues?.staff?.staffInfo?.id)
      );
    } else {
      const groupedSchedulesByStaffId = _.groupBy(allStaffSchedules, 'staffId');
      let staffSelected;

      for (const [id, schedules] of Object.entries(groupedSchedulesByStaffId)) {
        const staff = staffList.find(staff => staff.id == Number(id));
        if (!staff) continue;
        const staffType = staff.type;
        for (const schedule of schedules) {
          if (startTime >= schedule.startTime && endTime <= schedule.endTime) {
            if (staffType === ROLE.DOCTOR && !staffSelected) {
              staffSelected = staff;
              break;
            } else if (
              staffType === ROLE.NURSE &&
              (!staffSelected || staffSelected.type === ROLE.TECHNICIAN)
            ) {
              staffSelected = staff;
              break;
            } else if (staffType === ROLE.TECHNICIAN && !staffSelected) {
              staffSelected = staff;
              break;
            }
          }
        }
      }
      setSelectedStaff(staffSelected);
    }
  }, [staffList, allStaffSchedules]);

  useEffect(() => {
    async function onOptionsChange(): Promise<void> {
      if (presetPatientId && options) {
        const patient = await UserApi.getUser(presetPatientId);
        handlePatientChange(null, patient);
      }
    }
    onOptionsChange();
  }, [options]);

  useEffect(() => {
    if (appointmentValues?.staff) {
      setSelectedStaff(
        filteredStaffList.find(staff => staff.id == appointmentValues?.staff?.staffInfo?.id)
      );
    }
    if (appointmentValues?.day) {
      setPastAppointment(past72HoursAppointment && !!existingAppointmentId);
    }
  }, [appointmentValues, filteredStaffList]);

  useEffect(() => {
    if (!presetPatientId && !open) setSelectedPatient(undefined);
  }, [presetPatientId]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getPatients(patientSearchQuery);
    }, 300);

    return (): void => clearTimeout(delayDebounceFn);
  }, [patientSearchQuery]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getFamilyPhysicianList(familyPhysicianSearchQuery);
    }, 300);

    return (): void => clearTimeout(delayDebounceFn);
  }, [familyPhysicianSearchQuery]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getReferrerPhysicianList(referrerPhysicianSearchQuery);
    }, 300);

    return (): void => clearTimeout(delayDebounceFn);
  }, [referrerPhysicianSearchQuery]);

  useEffect(() => {
    if (!open) return;

    let endTimes = fullFilteredEndTimes;
    let startTimes = fullFilteredStartTimes;

    if (startTime) {
      endTimes = endTimes.filter(time => {
        return (
          time > startTime &&
          moment(time, DateTimeFormatString.TimeOnlyWithoutMeridies).diff(
            moment(startTime, DateTimeFormatString.TimeOnlyWithoutMeridies),
            'minutes'
          ) >= MINIMUM_APPOINTMENT_LENGTH
        );
      });
      let cutIndex = 1;
      for (; cutIndex < endTimes.length; cutIndex++) {
        if (
          moment(endTimes[cutIndex], DateTimeFormatString.TimeOnlyWithoutMeridies).diff(
            moment(endTimes[cutIndex - 1], DateTimeFormatString.TimeOnlyWithoutMeridies),
            'minutes'
          ) != MINIMUM_APPOINTMENT_LENGTH
        ) {
          break;
        }
      }
      endTimes = endTimes.slice(0, cutIndex);
    }
    if (endTime) {
      startTimes = startTimes.filter(time => {
        return (
          time <= endTime &&
          moment(endTime, DateTimeFormatString.TimeOnlyWithoutMeridies).diff(
            moment(time, DateTimeFormatString.TimeOnlyWithoutMeridies),
            'minutes'
          ) >= MINIMUM_APPOINTMENT_LENGTH
        );
      });
      let cutIndex = startTimes.length - 2;
      for (; cutIndex < startTimes.length; cutIndex--) {
        if (
          moment(startTimes[cutIndex + 1], DateTimeFormatString.TimeOnlyWithoutMeridies).diff(
            moment(startTimes[cutIndex], DateTimeFormatString.TimeOnlyWithoutMeridies),
            'minutes'
          ) != MINIMUM_APPOINTMENT_LENGTH
        ) {
          break;
        }
      }
      startTimes = startTimes.slice(cutIndex + 1, startTimes.length);
    }
    if (startTime && endTime) {
      const startMoment = moment(startTime, DateTimeFormatString.TimeOnlyWithoutMeridies);
      const endMoment = moment(endTime, DateTimeFormatString.TimeOnlyWithoutMeridies);
      const duration = moment.duration(endMoment.diff(startMoment));
      if (endTimes.includes(endTime)) {
        setSelectedLength(duration.asMinutes());
      } else {
        setSelectedLength(undefined);
      }
      getStaffForTimeFrame();

      if (oldAppt) {
        const startTimeChanged = startTime != oldAppt.startTimeOnly;
        const endTimeChanged = endTime != oldAppt.endTimeOnly;

        if (!appointmentStatusChanged && (startTimeChanged || endTimeChanged)) {
          setAppointmentStatus(APPOINTMENT_STATUSES[0].name);
          setAppointmentStatusChanged(true);
        }
      }
    }

    setStartTimes(startTimes);
    setEndTimes(endTimes);
  }, [startTime, endTime, fullFilteredEndTimes, fullFilteredStartTimes, oldAppt]);

  useEffect(() => {
    getStartEndTimes();
    filterOptionalStaff(null, nurseList, technicianList);
  }, [selectedStaff]);

  useEffect(() => {
    if (!rescheduleAppointmentInfo && oldAppt) {
      const nurse = nurseList.find(staff => staff.id == oldAppt?.nurseStaffId);
      const technician = technicianList.find(staff => staff.id == oldAppt?.technicianStaffId);

      setSelectedOptionalNurse(nurse);
      setSelectedOptionalTechnician(technician);
    }
  }, [oldAppt]);

  useEffect(() => {
    if (selectedSubTypes.length == 0 && mainType) {
      setSelectedType(mainType);
    } else {
      setSelectedType(0);
    }
  }, [selectedSubTypes, mainType]);

  useEffect(() => {
    if (!open) {
      resetState();
      return;
    }
    getBaseInfo();
  }, [open]);

  useEffect(() => {
    if (!open) return;
    if (selectedLength) {
      setEndTime(
        moment(startTime, DateTimeFormatString.TimeOnlyWithoutMeridies)
          .add(selectedLength, 'minutes')
          .format(DateTimeFormatString.TimeOnlyWithoutMeridies)
      );
      setSelectedLength(selectedLength);
    }
  }, [selectedLength]);

  const createStaffOptionalDropdown = (
    list: Array<UserStaff>,
    defaultName: string
  ): Array<ReactElement> => {
    const staff = list.map(item => (
      <MenuItem key={item.id} value={item.id}>
        {item.name}
      </MenuItem>
    ));
    return [
      <MenuItem key={0} value={''}>
        {defaultName}
      </MenuItem>,
      ...staff,
    ];
  };

  const title = (): string => {
    if (rescheduleAppointmentInfo) {
      return 'Reschedule Appointment';
    } else if (existingAppointmentId) {
      return 'Edit Appointment';
    }
    return 'New Appointment';
  };

  // TODO: The selects used should be common components
  if (showConfirmation) {
    return (
      <SaveAppointmentDialog
        open={open}
        error={error}
        message={message}
        responseAppt={responseAppt}
        existingAppointmentId={existingAppointmentId}
        appointmentCreated={appointmentCreated}
        closeConfirmation={closeConfirmation}
        onClose={onLocalClose}
      />
    );
  }
  return (
    <Dialog open={open} onClose={showChangesWarningDialog} title={title()}>
      <div className={styles.container}>
        <div className={styles.containerHeader}>
          <div className={styles.appointmentOpeningInfo}>
            <p>
              Appointment Type:{' '}
              <span className={styles.blue}> {appointmentValues?.appointmentType} </span>
            </p>
            <p>
              Clinic: <span className={styles.blue}>{locationInfo?.name}</span>
            </p>
            <p>
              Appointment Date:{' '}
              <span className={styles.blue}>
                {formatDate(DateTimeFormat.DateWithFullMonthName, appointmentValues?.day)}
              </span>
            </p>
          </div>
          <div className={classNames(styles.buttonSection)}>
            {existingAppointmentId > 0 && !rescheduleAppointmentInfo && !pastAppointment ? (
              <DialogButton
                buttonType={DialogButtonType.AffirmativeLink}
                onClick={handleReschedule}
              >
                Reschedule
              </DialogButton>
            ) : (
              <></>
            )}
            <DialogButton buttonType={DialogButtonType.NegationLink} onClick={onLocalClose}>
              Cancel
            </DialogButton>
            {!loading ? (
              <DialogButton
                buttonType={DialogButtonType.AffirmativeLink}
                className={styles.btn}
                onClick={handleAppointmentSave}
                loading={loading}
              >
                Save
              </DialogButton>
            ) : (
              <CircularProgress size={16} style={{ padding: '10px 20px' }} />
            )}
          </div>
        </div>
        <div>
          <form className={styles.formContainer}>
            <div className={styles.formColumn}>
              <p className={styles.steps}>
                <span>Step 1:</span> Choose your doctor and your start time within range of the
                available opening.
              </p>
              <div className={styles.formFieldContainer}>
                <Select
                  label="Sub Type"
                  name={'subType'}
                  placeholder={'Sub Type'}
                  fullWidth
                  disabled={selectedSubTypes.length === 0 || pastAppointment}
                  onChange={handleSubTypeChange}
                  value={selectedType ? selectedType : ''}
                >
                  {selectedSubTypes.map(item => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.subTypeName}
                    </MenuItem>
                  ))}
                </Select>
                <ValidationError field={'subType'} validationErrors={validationErrors} />
              </div>
              <div className={classNames(styles.availableOpening, styles.openingTime)}>
                <span>
                  <Select
                    disabled={pastAppointment}
                    label="Start Time"
                    name={'startTime'}
                    placeholder={startTime}
                    value={startTime}
                    onChange={handleStartTimeChange}
                    fullWidth
                  >
                    {startTimes?.map(item => (
                      <MenuItem key={item} value={item}>
                        {convertTo12From24(item)}
                      </MenuItem>
                    ))}
                  </Select>
                  <ValidationError field={'startTime'} validationErrors={validationErrors} />
                  <Select
                    disabled={pastAppointment}
                    label="End Time"
                    name={'endTime'}
                    placeholder={endTime}
                    value={endTime}
                    onChange={handleEndTimeChange}
                    fullWidth
                  >
                    {endTimes.map(item => (
                      <MenuItem key={item} value={item}>
                        {convertTo12From24(item)}
                      </MenuItem>
                    ))}
                  </Select>
                  <ValidationError field={'endTime'} validationErrors={validationErrors} />
                </span>
              </div>
              <div className={styles.formFieldContainer}>
                <Select
                  disabled={pastAppointment}
                  label="Length of appointment"
                  name={'length'}
                  placeholder={'Pick length of appointment'}
                  renderValue={(value): string => {
                    if (value) {
                      return value < 60
                        ? `${value} minutes`
                        : `${Math.floor(value / 60)} hour${Math.floor(value / 60) > 1 ? 's' : ''} ${
                            value % 60 > 0 ? `and ${value % 60} minutes` : ''
                          }`;
                    } else {
                      return '';
                    }
                  }}
                  value={selectedLength ? selectedLength : ''}
                  onChange={handleLengthChange}
                  fullWidth
                >
                  {SLOT_LENGTH_MINIMUM_5_MINUTES.map(item => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className={styles.formFieldContainer}>
                <Select
                  disabled={pastAppointment}
                  label="Assign staff"
                  name={'staff'}
                  placeholder={'Select staff'}
                  fullWidth
                  onChange={handleStaffChange}
                  value={selectedStaff?.id ? selectedStaff?.id : ''}
                >
                  {filteredStaffList?.map(item => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                <ValidationError field={'selectedStaff'} validationErrors={validationErrors} />
              </div>
              <div className={styles.formFieldContainer}>
                <Select
                  disabled={pastAppointment}
                  label="Optional Nurse"
                  name={'nurse'}
                  placeholder={'Optional Nurse'}
                  fullWidth
                  onChange={handleNurseChange}
                  value={selectedOptionalNurse?.id ? selectedOptionalNurse?.id : ''}
                >
                  {createStaffOptionalDropdown(nurseList, 'Optional Nurse')}
                </Select>
              </div>
              <div className={styles.formFieldContainer}>
                <Select
                  disabled={pastAppointment}
                  label="Optional Technician"
                  name={'technician'}
                  placeholder={'Optional Technician'}
                  fullWidth
                  onChange={handleTechnicianChange}
                  value={selectedOptionalTechnician?.id ? selectedOptionalTechnician?.id : ''}
                >
                  {createStaffOptionalDropdown(technicianList, 'Optional Technician')}
                </Select>
              </div>
              <div className={styles.formFieldContainer}>
                <AppointmentStatusSelect
                  label="Appointment status"
                  name="appointmentStatusId"
                  useFirstWhenEmpty
                  placeholder="Set appointment status"
                  value={appointmentStatus ? appointmentStatus : APPOINTMENT_STATUSES[0].name}
                  onChange={({ target }): void => setAppointmentStatus(target.value)}
                  fullWidth
                />
              </div>
              <div className={classNames(styles.formFieldContainer, styles.checkbox)}>
                <Checkbox
                  title="cancellationList"
                  name="cancellationList"
                  checked={cancellationList}
                  disabled={pastAppointment}
                  onChange={({ target }): void => setCancellationList(target.checked)}
                />
                <div className={classNames(pastAppointment && styles.textDisabled)}>
                  {'Add to Cancellation List'}
                </div>
              </div>
            </div>
            <div className={styles.formColumn}>
              <p className={styles.steps}>
                <span>Step 2:</span> Choose or create a patient.
              </p>
              <div className={classNames(styles.formFieldContainer, styles.checkbox)}>
                <Checkbox
                  key={Math.random()}
                  disabled={!selectedPatient || pastAppointment}
                  value={updatePatientInfo}
                  defaultChecked={updatePatientInfo}
                  name={'updatePatientInfo'}
                  onChange={(): void => setUpdatePatientInfo(edit => !edit)}
                />
                <div
                  className={classNames(
                    (!selectedPatient || pastAppointment) && styles.textDisabled
                  )}
                >
                  {'Update Patient Info'}
                </div>
              </div>
              <div className={styles.formFieldContainer}>
                <Autocomplete
                  options={options}
                  disabled={!!existingAppointmentId}
                  handlePatientChange={handlePatientChange}
                  openNewPatient={openNewPatient}
                  loading={patientLoading}
                  value={selectedPatient}
                  patientSearchQuery={patientSearchQuery}
                  setPatientSearchQuery={setPatientSearchQuery}
                />
                <ValidationError field={'selectedPatient'} validationErrors={validationErrors} />
              </div>
              <div className={styles.formFieldContainer}>
                <CVCDatePicker
                  disabled={!updatePatientInfo}
                  value={
                    patientInfoState.dateOfBirth
                      ? moment(patientInfoState.dateOfBirth, DateTimeFormatString.APIDateFormat)
                      : undefined
                  }
                  onChange={handlePatientInfoChange}
                  label={'Date of Birth'}
                />
                <ValidationError field={'dateOfBirth'} validationErrors={validationErrors} />
              </div>
              <div className={classNames(styles.formFieldContainer, styles.checkbox)}>
                <Checkbox
                  checked={isInternationalPatient}
                  disabled={!updatePatientInfo}
                  name={'noHealthCard'}
                  onChange={(event): void => handleHealthCardCheckBox(event)}
                />
                <div className={classNames(!updatePatientInfo && styles.textDisabled)}>
                  {'No Health Card'}
                </div>
              </div>
              <div className={styles.formFieldContainer}>
                <DialogButton
                  buttonType={DialogButtonType.AffirmativeLink}
                  className={styles.btn}
                  onClick={() => setOpenValidateHealthcardDialog(true)}
                  disabled={patientInfoState.healthCareProvinceCode !== CanadaProvinceCodes.ON}
                >
                  Validate Healthcard
                </DialogButton>
              </div>
              <div className={styles.formFieldContainer}>
                <Select
                  fullWidth
                  label="Province"
                  name={'healthCareProvinceCode'}
                  renderValue={(value): ReactElement => value}
                  value={patientInfoState.healthCareProvinceCode}
                  disabled={!updatePatientInfo || isInternationalPatient}
                  onChange={handlePatientInfoChange}
                  InputLabelProps={{ shrink: true }}
                >
                  {Object.values(CanadaProvinceCodes).map(item => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className={styles.healthCardContainer}>
                <TextField
                  disabled={!updatePatientInfo || isInternationalPatient}
                  value={patientInfoState.healthCardNumber}
                  onChange={handlePatientInfoChange}
                  label="Health Card"
                  name={'healthCardNumber'}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  error={healthCardError}
                  helperText={healthCardHelperText}
                />
                <ValidationError field={'healthCard'} validationErrors={validationErrors} />
                {patientInfoState.healthCareProvinceCode === CanadaProvinceCodes.ON && (
                  <TextField
                    className={styles.hcn}
                    name={'versionCode'}
                    label="VC"
                    value={patientInfoState.versionCode}
                    InputLabelProps={{ shrink: true }}
                    onChange={handlePatientInfoChange}
                    disabled={!updatePatientInfo || isInternationalPatient}
                    error={versionCodeError}
                    helperText={versionCodeHelperText}
                  />
                )}
              </div>
              <div className={styles.formFieldContainer}>
                <TextField
                  disabled={!updatePatientInfo}
                  value={formatPhoneInput(patientInfoState.phone)}
                  onChange={handlePatientInfoChange}
                  label="Phone Number"
                  name={'phone'}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
                <ValidationError field={'phone'} validationErrors={validationErrors} />
              </div>
              <div className={styles.formFieldContainer}>
                <TextField
                  disabled={!updatePatientInfo}
                  value={formatPhoneInput(patientInfoState.cellPhone)}
                  onChange={handlePatientInfoChange}
                  label="Cell Number"
                  name={'cellPhone'}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </div>
              <div className={styles.formFieldContainer}>
                <TextField
                  disabled={!updatePatientInfo}
                  value={formatPhoneInput(patientInfoState.homePhone)}
                  onChange={handlePatientInfoChange}
                  label="Home Phone Number"
                  name={'homePhone'}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </div>
              <div className={styles.formFieldContainer}>
                <TextField
                  disabled={!updatePatientInfo}
                  InputLabelProps={{ shrink: true }}
                  label="Email"
                  name={'email'}
                  value={patientInfoState.email}
                  onChange={handlePatientInfoChange}
                  fullWidth
                />
                <ValidationError field={'email'} validationErrors={validationErrors} />
              </div>
              <p className={classNames(styles.steps, styles.step3)}>
                <span>Step 3:</span> Add or edit a family physician for your patient.
              </p>
              <div className={classNames(styles.formFieldContainer, styles.checkbox)}>
                <Checkbox
                  disabled={!selectedPatient || pastAppointment}
                  value={updateFamilyPhysician}
                  name={'updateFamilyPhysician'}
                  onChange={(): void => setUpdateFamilyPhysician(edit => !edit)}
                />
                <div
                  className={classNames(
                    (!selectedPatient || pastAppointment) && styles.textDisabled
                  )}
                >
                  {'Update Family Physician'}
                </div>
              </div>
              <div className={styles.formFieldContainer}>
                {/* TODO: disabled until backend is created to support this feature */}
                <OtherPhysicianAutoComplete
                  options={familyPhysicianOptions}
                  disabled={!updateFamilyPhysician}
                  value={selectedFamilyPhysician}
                  handleChange={handleFamilyPhysicianChange}
                  searchQuery={familyPhysicianSearchQuery}
                  setSearchQuery={setFamilyPhysicianSearchQuery}
                />
              </div>
              <div className={styles.formFieldContainer}>
                <InfoRow label={'Name'} value={selectedFamilyPhysician?.fullName || ''} />
              </div>
              <div className={styles.formFieldContainer}>
                <InfoRow
                  label={'Phone Number'}
                  value={formatPhoneInput(selectedFamilyPhysician?.phone || '')}
                />
              </div>
              <div className={styles.formFieldContainer}>
                <InfoRow label={'Email'} value={selectedFamilyPhysician?.email || ''} />
              </div>
              <div className={styles.formFieldContainer}>
                <InfoRow
                  label={'Fax'}
                  value={formatPhoneInput(selectedFamilyPhysician?.fax || '')}
                />
              </div>
              <div className={styles.formFieldContainer}>
                <InfoRow label={'Billing'} value={selectedFamilyPhysician?.billing || ''} />
              </div>
              {selectedFamilyPhysician ? (
                <div className={styles.buttonSection}>
                  <DialogButton
                    buttonType={DialogButtonType.AffirmativeLink}
                    className={styles.btn}
                    onClick={handleUpdateFamilyPhysician}
                  >
                    Edit Physician
                  </DialogButton>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className={styles.formColumn}>
              <p className={styles.steps}>
                <span>Step 4:</span> If necessary, select a referral doctor.
              </p>
              <div className={classNames(styles.formFieldContainer, styles.checkbox)}>
                <Checkbox
                  disabled={!selectedPatient || pastAppointment}
                  checked={needReferral}
                  name={'needReferral'}
                  onChange={(): void => setNeedReferral(ref => !ref)}
                />
                <div
                  className={classNames(
                    (!selectedPatient || pastAppointment) && styles.textDisabled
                  )}
                >
                  {'Need referral'}
                </div>
              </div>
              {needReferral && (
                <>
                  {/* if no referal documnent allow user to upload */}
                  {referrerDocument ? (
                    <Paper>
                      <div>Referral File Received</div>
                    </Paper>
                  ) : (
                    <Paper
                      className={classNames(
                        styles.formFieldContainer,
                        styles.fileSelect,
                        styles.padding
                      )}
                    >
                      <Button onClick={(): void => fileInput?.current?.click()}>
                        {referralFile ? 'Change Referral File' : 'Attach Referral File'}
                      </Button>
                      <input
                        type="file"
                        ref={fileInput}
                        style={{ display: 'none' }}
                        name="document"
                        onChange={handleFileChange}
                      />
                      <div className={styles.fileName}>{referralFile?.name || ''}</div>
                    </Paper>
                  )}
                  <div className={classNames(styles.formFieldContainer, styles.checkbox)}>
                    <Checkbox
                      disabled={pastAppointment}
                      checked={updateReferralPhysician}
                      name={'updateReferrelDoctor'}
                      onChange={(): void => {
                        setUpdateReferralPhysician(!updateReferralPhysician);
                      }}
                    />
                    <div className={classNames(pastAppointment && styles.textDisabled)}>
                      {'Update Referral Doctor'}
                    </div>
                  </div>
                  <div className={classNames(styles.formFieldContainer, styles.checkbox)}>
                    <Checkbox
                      checked={sameAsFamilyDoctor}
                      name={'sameAsFamilyDoctor'}
                      onChange={handleSameAsFamilyDoctor}
                      disabled={!updateReferralPhysician || pastAppointment}
                    />
                    <div
                      className={classNames(
                        (!updateReferralPhysician || pastAppointment) && styles.textDisabled
                      )}
                    >
                      {'Same as family doctor'}
                    </div>
                  </div>
                  <div className={styles.formFieldContainer}>
                    <OtherPhysicianAutoComplete
                      options={referrerPhysicianOptions}
                      disabled={!updateReferralPhysician || sameAsFamilyDoctor}
                      value={selectedReferralPhysician}
                      handleChange={handleReferrerPhysicianChange}
                      searchQuery={referrerPhysicianSearchQuery}
                      setSearchQuery={setReferrerPhysicianSearchQuery}
                    />
                  </div>
                  <div className={styles.formFieldContainer}>
                    <InfoRow label={'Name'} value={selectedReferralPhysician?.fullName || ''} />
                  </div>
                  <div className={styles.formFieldContainer}>
                    <InfoRow
                      label={'Phone Number'}
                      value={formatPhoneInput(selectedReferralPhysician?.phone || '')}
                    />
                  </div>
                  <div className={styles.formFieldContainer}>
                    <InfoRow label={'Email'} value={selectedReferralPhysician?.email || ''} />
                  </div>
                  <div className={styles.formFieldContainer}>
                    <InfoRow
                      label={'Fax'}
                      value={formatPhoneInput(selectedReferralPhysician?.fax || '')}
                    />
                  </div>
                  <div className={styles.formFieldContainer}>
                    <InfoRow label={'Billing'} value={selectedReferralPhysician?.billing || ''} />
                  </div>
                  {!sameAsFamilyDoctor && selectedReferralPhysician ? (
                    <div className={styles.buttonSection}>
                      <DialogButton
                        buttonType={DialogButtonType.AffirmativeLink}
                        className={styles.btn}
                        onClick={handleUpdateReferralPhysician}
                      >
                        Edit Physician
                      </DialogButton>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              )}
              <p className={styles.steps}>
                <span>Step 5:</span> Add appointment notes.
              </p>
              <div className={styles.formFieldContainer}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  value={description}
                  onChange={handleDescriptionChange}
                  label="Notes"
                  name={'description'}
                  multiline
                  rows={3}
                  fullWidth
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      {LocalPrompt}
      <NewPatientDialogContainer
        open={openNewPatientDialog}
        onClose={(): void => setOpenNewPatientDialog(false)}
        onSaveSuccess={onNewPatientSaveSuccess}
        handlePatientChange={handlePatientChange}
      />
      <OtherPhysicianEditDialog
        open={openCreatePhysicianDialog}
        onClose={(): void => {
          setOpenCreatePhysicianDialog(false);
          setPhysicianToUpdate(undefined);
        }}
        onSaveSuccess={onCreatePhysicianSaveSuccess}
        selectedPhysician={physicianToUpdate}
      />
      <ValidateHealthcardDialog
        open={openValidateHealthcardDialog}
        onClose={(): void => setOpenValidateHealthcardDialog(false)}
        onSaveSuccess={onValidateHealthcardSuccess}
        healthcardNumber={patientInfoState.healthCardNumber as string}
        healthcardCode={patientInfoState.versionCode as string}
        oldFirstName={''}
        oldLastName={''}
        oldDateOfBirth={moment(patientInfoState.dateOfBirth) as moment.Moment}
        oldGender={''}
        includeFirstName={false}
        includeLastName={false}
        includeDateOfBirth={true}
        includeGender={false}
      />
      <AlertDialog
        open={openWarningDialog}
        title={'Error'}
        message={'Please remove health card before selecting'}
        onClose={(): void => setOpenWarningDialog(false)}
      />
    </Dialog>
  );
}

export default NewAppointmentDialog;
