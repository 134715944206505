import React, { ReactElement, useState } from 'react';
import { useFormik } from 'formik';

import { forgotPassword } from 'api/user';

import ForgotPassword from './ForgotPassword.view';
import * as logger from 'api/logger';

const NEXT_STEP_MESSAGE = 'Please check your email for instructions';

function ForgotPasswordContainer(props): ReactElement {
  const [nextStepInstruction, setNextStepInstruction] = useState('');
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    // validate: forgotPValidator,
    onSubmit: async values => {
      try {
        if (!nextStepInstruction) {
          await forgotPassword(values.email);
          setNextStepInstruction(NEXT_STEP_MESSAGE);
        } else {
          props?.history?.push('/login');
        }
      } catch (e) {
        logger.error(e.message);
        formik.setErrors({ email: 'No such email' });
      }
    },
  });

  return (
    <ForgotPassword
      email={formik.values.email}
      onChange={formik.handleChange}
      errors={formik.errors}
      onSubmit={formik.handleSubmit}
      loading={formik.isSubmitting}
      nextStepInstruction={nextStepInstruction}
    />
  );
}

export default ForgotPasswordContainer;
