import React, { ReactElement, useState, useEffect } from 'react';

import { FaxFields, NewFaxValidationError } from 'types';
import NewFaxDialog from './NewFaxDialog.view';

const DEFAULT_TITLE = 'New Fax';

interface NewFaxContainerProps {
  open: boolean;
  onClose: () => void;
  onSaveSuccess: () => void;
}

function NewFaxContainer({ open, onClose, onSaveSuccess }: NewFaxContainerProps): ReactElement {
  let delayDebounceFn;
  const [validationErrors, setValidationErrors] = useState<Array<NewFaxValidationError>>([]);
  const [faxDocument, setFaxDocument] = useState<any>(null);
  const [fieldValues, setFieldValues] = useState<FaxFields>({
    title: '',
    faxNumber: '',
    note: '',
    recipientName: '',
    clinicName: '',
    patientName: '',
    dateOfBirth: '',
    healthcard: '',
  });

  function validateForm(showErrors = false): boolean {
    let validated = true;
    const validationErrors: Array<NewFaxValidationError> = [];

    if (!fieldValues.title) {
      const validationError: NewFaxValidationError = {
        field: 'title',
        message: 'Please create a title',
      };

      validationErrors.push(validationError);
      validated = false;
    }

    if (!fieldValues.faxNumber || fieldValues.faxNumber.length < 10) {
      const validationError: NewFaxValidationError = {
        field: 'faxNumber',
        message: 'Please add a valid fax number',
      };

      validationErrors.push(validationError);
      validated = false;
    }

    if (!faxDocument) {
      const validationError: NewFaxValidationError = {
        field: 'faxDocument',
        message: 'Please select a PDF to fax',
      };

      validationErrors.push(validationError);
      validated = false;
    }

    setValidationErrors(validationErrors);
    return validated;
  }

  function onChangeField(name, value): void {
    setFieldValues({ ...fieldValues, [name]: value });
  }

  const resetState = (): void => {
    setValidationErrors([]);
    setFieldValues({
      title: '',
      faxNumber: '',
      recipientName: '',
      note: '',
      clinicName: '',
      patientName: '',
      dateOfBirth: '',
      healthcard: '',
    });
    setFaxDocument(null);
  };

  useEffect(() => {
    if (!open) {
      resetState();
      return;
    }
  }, [open]);

  return (
    <NewFaxDialog
      open={open}
      title={DEFAULT_TITLE}
      validationErrors={validationErrors}
      fieldValues={fieldValues}
      faxDocument={faxDocument}
      setFaxDocument={setFaxDocument}
      validateForm={validateForm}
      onChangeField={onChangeField}
      onClose={onClose}
      onSaveSuccess={onSaveSuccess}
    />
  );
}

export default NewFaxContainer;
