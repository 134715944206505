import { useState, useEffect } from 'react';
import { NewDocument } from 'types';
import useDocumentsApi from 'pages/Patient/components/Documents/useDocumentsApi.hook';
import useWorkflowResultApi from 'pages/WorkflowResult/useWorkflowApi.hook';
import { calculatePatientResultsMap } from '../PatientResults/lib/calculatePatientResults';
import { MIME_TYPE_PDF } from 'lib/document';

interface WorkflowPDFComponentProps {
  pdfDocumentURL?: string;
  pdfDocumentCoverLetterURL?: string;
  pdfDocumentNecessityLetterURL?: string;
  pdfTechnicianResultsURL?: string;
  pdfFollowUpResultsURL?: string;
  patientId?: number;
  caseId?: number;
  formId?: number;
}

export const useWorkflowPDF = ({
  pdfDocumentURL = '',
  pdfDocumentCoverLetterURL = '',
  pdfDocumentNecessityLetterURL = '',
  pdfTechnicianResultsURL = '',
  pdfFollowUpResultsURL = '',
  patientId,
  formId,
  caseId,
}: WorkflowPDFComponentProps) => {
  const { createDocument, createAndSendDocument, loading } = useDocumentsApi(patientId);
  const { formCaseResult } = useWorkflowResultApi(formId, caseId);

  const [patientFormResults, setPatientFormResults] = useState<Map<string, any>>(new Map());

  async function saveReport(): Promise<void> {
    const fileResult = await fetch(pdfDocumentURL);
    const blob = await fileResult.blob();

    const fileName = `Consultation letter.pdf`;
    const fileObject = new File([blob], fileName, { type: MIME_TYPE_PDF });

    const data: NewDocument = {
      name: fileName,
      typeId: 1, //TODO:  Verify --> Doc Name, File Name, Type,
      document: fileObject,
    };

    if (data.document) await createDocument(data);
  }

  async function saveCoverLetter(): Promise<void> {
    const fileResult = await fetch(pdfDocumentCoverLetterURL);
    const blob = await fileResult.blob();

    const fileName = `Letter to Family Doctor.pdf`;
    const fileObject = new File([blob], fileName, { type: MIME_TYPE_PDF });

    const data: NewDocument = {
      name: fileName,
      typeId: 1, //TODO:  Verify --> Doc Name, File Name, Type,
      document: fileObject,
    };

    if (data.document) await createDocument(data);
  }

  async function saveNecessityLetter(): Promise<void> {
    const fileResult = await fetch(pdfDocumentNecessityLetterURL);
    const blob = await fileResult.blob();

    const fileName = `Letter of Necessity.pdf`;
    const fileObject = new File([blob], fileName, { type: MIME_TYPE_PDF });

    const data: NewDocument = {
      name: fileName,
      typeId: 1, //TODO:  Verify --> Doc Name, File Name, Type,
      document: fileObject,
    };

    if (data.document) await createDocument(data, true);
  }

  async function saveAndSendNecessityLetter(email): Promise<void> {
    const fileResult = await fetch(pdfDocumentNecessityLetterURL);
    const blob = await fileResult.blob();

    const fileName = `Letter of Necessity.pdf`;
    const fileObject = new File([blob], fileName, { type: MIME_TYPE_PDF });

    const data: NewDocument = {
      name: fileName,
      typeId: 1, //TODO:  Verify --> Doc Name, File Name, Type,
      document: fileObject,
    };

    if (data.document) await createAndSendDocument(data, email, patientId);
  }

  async function saveTechnicianResultsDocument(): Promise<void> {
    const fileResult = await fetch(pdfTechnicianResultsURL);
    const blob = await fileResult.blob();

    const fileName = `Technician Results.pdf`;
    const fileObject = new File([blob], fileName, { type: MIME_TYPE_PDF });

    const data: NewDocument = {
      name: fileName,
      typeId: 1, //TODO:  Verify --> Doc Name, File Name, Type,
      document: fileObject,
    };

    if (data.document) await createDocument(data);
  }

  async function saveFollowUpResultsDocument(nextFollowUp: string): Promise<void> {
    const fileResult = await fetch(pdfFollowUpResultsURL);
    const blob = await fileResult.blob();
    const fileName = `Follow-Up Results - ${nextFollowUp}.pdf`;
    const fileObject = new File([blob], fileName, { type: MIME_TYPE_PDF });

    const data: NewDocument = {
      name: fileName,
      typeId: 1, //TODO:  Verify --> Doc Name, File Name, Type,
      document: fileObject,
    };
    console.log(data);
    if (data.document) await createDocument(data);
  }

  useEffect(() => {
    if (formCaseResult) {
      const pfresults = calculatePatientResultsMap(formCaseResult?.answers);
      setPatientFormResults(pfresults);
    }
  }, [formCaseResult]);

  return {
    formCaseResult,
    patientFormResults,
    saveReport,
    saveCoverLetter,
    saveNecessityLetter,
    saveAndSendNecessityLetter,
    saveTechnicianResultsDocument,
    saveFollowUpResultsDocument,
    loading,
  };
};

export default useWorkflowPDF;
