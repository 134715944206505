import React, { ReactElement } from 'react';
import { styled } from '@material-ui/core';
import Button from '@material-ui/core/Button';

const MyButton = styled(Button)({
  background: '#B00000',
  border: 0,
  borderRadius: 5,
  color: 'white',
  height: 35,
  minWidth: 0,
  padding: '0 8px',
  fontWeight: 'bold',
  fontSize: '1rem',
  textTransform: 'none',
  whiteSpace: 'nowrap',

  '&:hover': {
    backgroundColor: '#900000',
  },
});

interface CVCButtonProps {
  children: any;
  [other: string]: any;
}

function CVCButton({ children, ...other }: CVCButtonProps): ReactElement {
  return <MyButton {...other}>{children}</MyButton>;
}

export default CVCButton;
