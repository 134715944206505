import React, { ReactElement, useEffect, useState } from 'react';

import { LocationApi } from 'api';

import { ScheduleTypes } from 'lib/scheduleTypes';
import EditLocationDialog from './EditLocationDialog.view';
import { NewLocationDialogValidationError, Address, ContactType } from 'types';
import useLocationApi from 'hook/useLocationApi.hook';
import { parseInt } from 'lodash';
import useUnsavedChangesWarning from 'hook/useUnsavedChangesWarning';
import { handleAPIErrors } from 'lib/handleAPIErrors';

interface EditLocationDialogContainer {
  locationId?: string;
  open?: boolean;
  onClose: () => void;
  onSaveSuccess: () => void;
  isValidUser?: boolean;
  dialogType?: string;
}

function EditLocationDialogContainer({
  locationId,
  open,
  onClose,
  onSaveSuccess,
  isValidUser,
  dialogType,
}: EditLocationDialogContainer): ReactElement {
  const [loading, setLoading] = useState(false);
  const [sortOrder, setSortOrder] = useState('');
  const [locationName, setLocationName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [emailId, setEmailId] = useState<number>();
  const [phoneId, setPhoneId] = useState<number>();
  const [line1, setLine1] = useState('');
  const [line2, setLine2] = useState('');
  const [city, setCity] = useState('');
  const [province, setProvince] = useState('');
  const [country, setCountry] = useState('Canada');
  const [postalCode, setPostalCode] = useState('');
  const [address, setAddress] = useState<Address>();
  const [timezone, setTimeZone] = useState('');
  const [generalChecked, setGeneralChecked] = useState<boolean>(false);
  const [consultationFollowUpChecked, setConsultationFollowUpChecked] = useState<boolean>(false);
  const [procedureChecked, setProcedureChecked] = useState<boolean>(false);
  const [ultraSoundChecked, setUltraSoundChecked] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [fieldsValidated, setFieldsValidated] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<Array<NewLocationDialogValidationError>>(
    []
  );
  const { location, setLocation } = useLocationApi(locationId);

  function resetForm(): void {
    setSortOrder('');
    setLocationName('');
    setTimeZone('');
    setLoading(false);
    setEmail('');
    setPhone('');
    setLine1('');
    setLine2('');
    setCity('');
    setProvince('');
    setCountry('Canada');
    setPostalCode('');
    setErrorMessage('');
    setFieldsValidated(false);
    // Clear ID's used to update in DB
    setAddress(undefined);
    setPhoneId(undefined);
    setEmailId(undefined);
    setGeneralChecked(false);
    setConsultationFollowUpChecked(false);
    setProcedureChecked(false);
    setUltraSoundChecked(false);
    setValidationErrors([]);
  }

  function fullClose() {
    onClose();
    resetForm();
  }
  const [
    Prompt,
    LocalPrompt,
    changesMade,
    setChangesMade,
    setWarningOpen,
  ] = useUnsavedChangesWarning(fullClose);

  function validateForm(showErrors = false): boolean {
    if (showErrors) setFieldsValidated(true);
    let validated = true;
    const validationErrors: Array<NewLocationDialogValidationError> = [];

    if (locationName === '') {
      const validationError: NewLocationDialogValidationError = {
        field: 'locationName',
        message: 'Please type location name',
      };

      validationErrors.push(validationError);
      validated = false;
    }

    if (line1 === '') {
      const validationError: NewLocationDialogValidationError = {
        field: 'line1',
        message: 'Please type address',
      };

      validationErrors.push(validationError);
      validated = false;
    }

    if (phone === '') {
      const validationError: NewLocationDialogValidationError = {
        field: 'phone',
        message: 'Please type phone number',
      };

      validationErrors.push(validationError);
      validated = false;
    }

    if (email === '') {
      const validationError: NewLocationDialogValidationError = {
        field: 'email',
        message: 'Please provide an email',
      };

      validationErrors.push(validationError);
      validated = false;
    }
    if (city === '') {
      const validationError: NewLocationDialogValidationError = {
        field: 'city',
        message: 'Please provide a city',
      };

      validationErrors.push(validationError);
      validated = false;
    }

    if (country === '') {
      const validationError: NewLocationDialogValidationError = {
        field: 'country',
        message: 'Please provide a country',
      };

      validationErrors.push(validationError);
      validated = false;
    }

    if (province === '') {
      const validationError: NewLocationDialogValidationError = {
        field: 'province',
        message: 'Please provide a province',
      };

      validationErrors.push(validationError);
      validated = false;
    }

    if (postalCode === '') {
      const validationError: NewLocationDialogValidationError = {
        field: 'postalCode',
        message: 'Please provide a postal code',
      };

      validationErrors.push(validationError);
      validated = false;
    }

    if (timezone === '') {
      const validationError: NewLocationDialogValidationError = {
        field: 'timezone',
        message: 'Please select the timezone',
      };

      validationErrors.push(validationError);
      validated = false;
    }

    if (!(generalChecked || procedureChecked || consultationFollowUpChecked || ultraSoundChecked)) {
      const validationError: NewLocationDialogValidationError = {
        field: 'scheduleTypes',
        message: 'Please select at least one schedule type',
      };

      validationErrors.push(validationError);
      validated = false;
    }

    if (fieldsValidated || showErrors) setValidationErrors(validationErrors);

    return validated;
  }

  useEffect(() => {
    validateForm();
  }, [
    locationName,
    email,
    phone,
    line1,
    line2,
    city,
    province,
    country,
    postalCode,
    timezone,
    generalChecked,
    consultationFollowUpChecked,
    procedureChecked,
    ultraSoundChecked,
  ]);

  useEffect(() => {
    if (location) {
      setLocationName(location.name);
      setSortOrder(location.sortOrder.toString());
      setTimeZone(location.timezone);

      const phoneContacts = location?.contacts?.filter(
        contact => contact.type === 'office' || contact.type === 'phone'
      );

      if (phoneContacts && phoneContacts.length > 0) {
        setPhone(phoneContacts[0].value);
        setPhoneId(phoneContacts[0].id);
      }

      const emailContacts = location.contacts?.filter(contact => contact.type === 'email');
      if (emailContacts && emailContacts.length > 0) {
        setEmail(emailContacts[0].value);
        setEmailId(emailContacts[0].id);
      }

      if (location?.addresses && location.addresses.length > 0) {
        setAddress(location.addresses[0]);
        setLine1(location.addresses[0].line1);
        setLine2(location.addresses[0].line2);
        setCity(location.addresses[0].city);
        setProvince(location.addresses[0].province);
        setCountry(location.addresses[0].country);
        setPostalCode(location.addresses[0].postalCode);
      }

      if (location?.scheduleTypes && location.scheduleTypes.length > 0) {
        setGeneralChecked(location.scheduleTypes.includes(ScheduleTypes.General));
        setConsultationFollowUpChecked(
          location.scheduleTypes.includes(ScheduleTypes.ConsultationAndfollowUp)
        );
        setProcedureChecked(location.scheduleTypes.includes(ScheduleTypes.Procedure));
        setUltraSoundChecked(location.scheduleTypes.includes(ScheduleTypes.UltraSound));
      }
    }
  }, [location]);

  function getCheckedScheduleTypes(): Array<number> {
    const out: Array<number> = [];
    if (generalChecked) {
      out.push(ScheduleTypes.General);
    }
    if (consultationFollowUpChecked) {
      out.push(ScheduleTypes.ConsultationAndfollowUp);
    }
    if (procedureChecked) {
      out.push(ScheduleTypes.Procedure);
    }
    if (ultraSoundChecked) {
      out.push(ScheduleTypes.UltraSound);
    }

    return out;
  }

  function onCloseAndReset(): void {
    if (changesMade) {
      setWarningOpen(true);
    } else {
      fullClose();
    }
  }

  async function onUpdate(): Promise<void> {
    if (validateForm(true)) {
      setLoading(true);

      const body = {
        locationId: location?.id,
        sortOrder: sortOrder,
        name: locationName,
        timezone: timezone,
        email: email,
        contacts: [
          {
            id: phoneId,
            type: ContactType.Phone,
            value: phone,
          },
          {
            id: emailId,
            type: ContactType.Email,
            value: email,
          },
        ],
        addresses: [
          {
            id: address?.id,
            type: 'primary',
            line1: line1,
            line2: line2,
            city: city,
            province: province,
            country: country,
            postalCode: postalCode,
          },
        ],
        scheduleType: getCheckedScheduleTypes(),
      };

      setLoading(true);
      if (locationId) {
        try {
          const newLocationData = await LocationApi.updateLocation(locationId, body);
          setLocation(newLocationData || undefined);
          fullClose();
          onSaveSuccess();
        } catch (error) {
          setErrorMessage(handleAPIErrors(error));
        }
      }
      setLoading(false);
    }
  }

  async function onDelete(): Promise<void> {
    if (locationId) {
      setLoading(true);
      try {
        await LocationApi.deleteLocation(parseInt(locationId));
        resetForm();
        onSaveSuccess();
      } catch (error) {
        setErrorMessage(handleAPIErrors(error));
      }
    }
  }

  async function onSave(): Promise<void> {
    if (validateForm(true)) {
      setLoading(true);
      const body = {
        sortOrder: sortOrder,
        name: locationName,
        timezone: timezone,
        email: email,
        contacts: [
          {
            type: ContactType.Phone,
            value: phone,
          },
          {
            type: ContactType.Email,
            value: email,
          },
        ],
        addresses: [
          {
            type: 'primary',
            line1: line1,
            line2: line2,
            city: city,
            province: province,
            country: country,
            postalCode: postalCode,
          },
        ],
        scheduleType: getCheckedScheduleTypes(),
      };

      try {
        await LocationApi.createLocation(body);
        resetForm();
        onSaveSuccess();
      } catch (error) {
        setErrorMessage(handleAPIErrors(error));
      }
      setLoading(false);
    }
  }

  return (
    <div>
      <EditLocationDialog
        open={open}
        onClose={onCloseAndReset}
        loading={loading}
        sortOrder={sortOrder}
        locationName={locationName}
        line1={line1}
        line2={line2}
        city={city}
        province={province}
        country={country}
        postalCode={postalCode}
        email={email}
        phone={phone}
        generalChecked={generalChecked}
        consultationFollowUpChecked={consultationFollowUpChecked}
        procedureChecked={procedureChecked}
        ultraSoundChecked={ultraSoundChecked}
        timezone={timezone}
        validationErrors={validationErrors}
        errorMessage={errorMessage}
        onLocationNameChange={value => {
          setChangesMade(true);
          setLocationName(value);
        }}
        onSortOrderChange={value => {
          setChangesMade(true);
          setSortOrder(value);
        }}
        onEmailChange={value => {
          setChangesMade(true);
          setEmail(value);
        }}
        onPhoneChange={value => {
          setChangesMade(true);
          setPhone(value);
        }}
        onLine1Change={value => {
          setChangesMade(true);
          setLine1(value);
        }}
        onLine2Change={value => {
          setChangesMade(true);
          setLine2(value);
        }}
        onCityChange={value => {
          setChangesMade(true);
          setCity(value);
        }}
        onProvinceChange={value => {
          setChangesMade(true);
          setProvince(value);
        }}
        onCountryChange={value => {
          setChangesMade(true);
          setCountry(value);
        }}
        onPostalCodeChange={value => {
          setChangesMade(true);
          setPostalCode(value);
        }}
        onTimeZoneChange={value => {
          setChangesMade(true);
          setTimeZone(value);
        }}
        onUpdate={onUpdate}
        onSave={onSave}
        onDelete={onDelete}
        onGeneralChecked={setGeneralChecked}
        onConsultationFollowUpChecked={setConsultationFollowUpChecked}
        onProcedureChecked={setProcedureChecked}
        onUltraSoundChecked={setUltraSoundChecked}
        dialogType={dialogType}
        isValidUser={isValidUser}
      />
      {LocalPrompt}
    </div>
  );
}

export default EditLocationDialogContainer;
