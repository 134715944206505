import React, { ReactElement, useEffect, useState } from 'react';

import { QuestionForm, User } from 'types';
import { FormApi } from 'api';
import Dialog from 'components/Dialog';
import { AlertDialog } from 'components/AlertDialog';
import DialogButton from 'components/DialogButton';

import Form from './Form.view';
import { TechnicianResultsPDF } from './components/TechnicianResultsPDF';
import { FollowUpResultsPDF } from './components/FollowUpResultsPDF';
import styles from './FormStyles.module.scss';
import { RequisitionResultsPDF } from './components/RequisitionResultsPDF';
import { PrescriptionResultsPDF } from './components/PrescriptionResultsPDF';
import { FormDescriptions } from 'lib/form';
import { getStaff, downloadSignature } from 'api/user';
import { ROLE } from 'lib/user';
import * as logger from 'api/logger';

enum FormStatus {
  NeedsToBeFilled = 1,
  InProgress = 2,
  Complete = 3,
}

enum FormTemplate {
  PatientForm = 1,
  TechnicianForm = 2,
  DoctorForm = 3,
}

function FormContainer(props): ReactElement {
  const formId = props?.match?.params?.formId;
  const caseId = props?.location?.state?.caseId;
  const patientId = props?.location?.state?.patientId;

  const [form, setForm] = useState<QuestionForm | null>(null);
  const [signatureUrl, setSignatureUrl] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [finalDialogShow, setFinalDialogShow] = useState<boolean>(false);
  const [doctors, setDoctors] = useState<Array<User>>([]);
  const [noChangeDialog, setNoChangeDialog] = useState<boolean>(false);
  const [addSignature, setAddSignature] = useState<boolean>(false);

  const fetchDoctors = async () => {
    const doctors = await getStaff('', ROLE.DOCTOR);
    setDoctors(doctors);
  };

  const needsDoctorSignature = () => {
    return (
      form?.description == FormDescriptions.REQUISITION ||
      form?.description == FormDescriptions.MEDICAL_COMPRESSION_PRESCRIPTION ||
      form?.description == FormDescriptions.MEDICAL_PRESCRIPTION
    );
  };

  async function downloadSignatureFile(s3Key, doctorId): Promise<any> {
    try {
      const signedRequest = (await downloadSignature(s3Key, doctorId)).signedRequest;
      setSignatureUrl(signedRequest);
    } catch (e) {
      logger.error(e.message);
      setSignatureUrl('');
      setAddSignature(false);
      setErrorMessage(e.response.data.message);
      setOpenAlert(true);
    }
  }

  useEffect(() => {
    try {
      FormApi.getForm(formId).then(setForm);
    } catch (e) {
      console.log('ERROR');
      props?.history?.push('/404');
    }
  }, []);

  useEffect(() => {
    const needsSignature = needsDoctorSignature();
    if (needsSignature) fetchDoctors();
  }, [form?.description]);

  useEffect(() => {
    if (!addSignature) setSignatureUrl('');
  }, [addSignature]);

  async function updateForm(answers): Promise<void> {
    const data = await FormApi.updateFormAnswers(formId, { templateQuestions: answers });

    if (
      data?.status_id === FormStatus.Complete &&
      (data.template_id === FormTemplate.TechnicianForm ||
        form?.description === FormDescriptions.FOLLOW_UP ||
        form?.description === FormDescriptions.REQUISITION ||
        form?.description === FormDescriptions.MEDICAL_COMPRESSION_PRESCRIPTION ||
        form?.description === FormDescriptions.MEDICAL_PRESCRIPTION)
    ) {
      setShowConfirm(true);
    } else {
      setFinalDialogShow(true);
    }
  }

  async function onSave(sectionsAnswer): Promise<void> {
    try {
      let answ: Array<any> = [];

      sectionsAnswer.forEach(section =>
        section.forEach(answers => answers.forEach(answer => answ.push(answer)))
      );

      answ = answ.filter(a => {
        return a.id || a.value || a.questionOptionId;
      });

      if (answ.length > 0) {
        updateForm(answ);
      } else {
        setNoChangeDialog(true);
      }
    } catch (e) {
      logger.error(e.message);
    }
  }

  function getSortedSections() {
    const useForms = form?.sections || [];
    // Assumes templateQuestions are in order
    return useForms.sort((a, b) => {
      const sectionAQuestion1Id = a?.templateQuestions[0]?.templateQuestionId || 0;
      const sectionBQuestion1Id = b?.templateQuestions[0]?.templateQuestionId || 0;
      return sectionAQuestion1Id > sectionBQuestion1Id ? 1 : -1;
    });
  }

  function handleClose() {
    setShowConfirm(false);
    props?.history?.goBack();
  }

  const handlePDFGeneration = () => {
    if (form?.description === FormDescriptions.FOLLOW_UP) {
      return (
        <FollowUpResultsPDF
          isOpen={showConfirm}
          onClose={handleClose}
          caseId={caseId}
          formId={formId}
          patientId={patientId}
        />
      );
    } else if (form?.description === FormDescriptions.MEDICAL_COMPRESSION_PRESCRIPTION) {
      return (
        <PrescriptionResultsPDF
          isOpen={showConfirm}
          onClose={handleClose}
          caseId={caseId}
          formId={formId}
          patientId={patientId}
          signatureUrl={signatureUrl}
          medical={false}
        />
      );
    } else if (form?.description === FormDescriptions.MEDICAL_PRESCRIPTION) {
      return (
        <PrescriptionResultsPDF
          isOpen={showConfirm}
          onClose={handleClose}
          caseId={caseId}
          formId={formId}
          patientId={patientId}
          signatureUrl={signatureUrl}
          medical={true}
        />
      );
    } else if (form?.description === FormDescriptions.REQUISITION) {
      return (
        <RequisitionResultsPDF
          isOpen={showConfirm}
          onClose={handleClose}
          caseId={caseId}
          formId={formId}
          patientId={patientId}
          signatureUrl={signatureUrl}
        />
      );
    } else {
      return (
        <TechnicianResultsPDF
          isOpen={showConfirm}
          onClose={handleClose}
          caseId={caseId}
          formId={formId}
          patientId={patientId}
        />
      );
    }
  };

  const onCreateDocument = async (): Promise<void> => {
    setShowConfirm(true);
    handlePDFGeneration();
  };

  return (
    <>
      <Form
        description={form?.description}
        sections={getSortedSections()}
        onCancel={() => props?.history?.goBack()}
        formId={formId}
        onSave={onSave}
        downloadSignatureFile={downloadSignatureFile}
        doctors={doctors}
        needsDoctorSignature={needsDoctorSignature()}
        addSignature={addSignature}
        setAddSignature={setAddSignature}
        signatureFound={!!signatureUrl}
      />
      <Dialog
        open={finalDialogShow}
        onClose={() => setFinalDialogShow(false)}
        title={form?.description}
      >
        <div className={styles.container}>
          <p className={styles.text}>
            You have successfully completed the {form?.description}. Remember to click review report
            to generate and review your report.
          </p>
          <div className={styles.buttons}>
            <DialogButton onClick={() => handleClose()}>{'Ok'}</DialogButton>
          </div>
        </div>
      </Dialog>
      <AlertDialog
        open={openAlert}
        title={'Download Signature Failed'}
        message={errorMessage}
        onClose={() => setOpenAlert(false)}
      />
      <Dialog
        open={noChangeDialog}
        onClose={(): void => setNoChangeDialog(false)}
        title={'No Changes Made'}
      >
        <div className={styles.container}>
          <div className={styles.buttons}>
            <DialogButton onClick={onCreateDocument}>{'Create Document'}</DialogButton>
            <DialogButton onClick={(): void => setNoChangeDialog(false)}>
              {'Continue Editing'}
            </DialogButton>
            <DialogButton onClick={handleClose}>{'Close'}</DialogButton>
          </div>
        </div>
      </Dialog>
      {showConfirm && handlePDFGeneration()}
    </>
  );
}

export default FormContainer;
