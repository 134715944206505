import { CanadaProvinceCodes } from 'lib/addresses';
import { formatHealthCard } from 'lib/patient';
import validator, {
  DynamicData,
  ValidationRule,
  emailValidationRule,
  healthCardValidationRule,
  versionCodeValidationRule,
} from 'lib/validator';
import moment from 'moment';

const NewPatientValidator: Array<ValidationRule> = [
  // {
  //   name: 'gender',
  //   rule: (data: DynamicData, name: string): boolean => !!data[name],
  //   message: 'Gender not selected',
  // },
  {
    name: 'firstName',
    rule: (data: DynamicData, name: string): boolean => {
      return !!data[name];
    },
    message: 'Please type first name',
  },
  {
    name: 'lastName',
    rule: (data: DynamicData, name: string): boolean => !!data[name],
    message: 'Please type last name',
  },
  {
    name: 'email',
    rule: (data: DynamicData, name: string): boolean => {
      const email = data[name];
      if (email.length > 0) {
        return emailValidationRule(data, name);
      }
      return true;
    },
    message: 'Please type a valid email',
  },
  {
    name: 'email',
    rule: (data: DynamicData, name: string): boolean => !!data[name] || !!data['phone'],
    message: 'Please enter either email or phone number',
  },
  {
    name: 'phone',
    rule: (data: DynamicData, name: string): boolean => !!data[name] || !!data['email'],
    message: 'Please enter either email or phone number',
  },
  {
    name: 'phone',
    rule: (data: DynamicData, name: string): boolean => {
      const phoneNum = data[name].replace(/[^+0-9]*/g, '');
      if (phoneNum.length > 0) {
        return phoneNum.length >= 10; // accommodate country code
      }
      return true;
    },
    message: 'Phone number should be at least 10 digits',
  },
  // {
  //   name: 'dateOfBirth',
  //   rule: (data: DynamicData, name: string): boolean => {
  //     return moment().isAfter(data[name], 'days');
  //   },
  //   message: 'Please select date of birth',
  // },
  {
    name: 'healthCard',
    rule: (data: DynamicData, name: string): boolean => {
      const province = data['healthCardProvinceCode'];
      const noHealthCard = data['noHealthCard'];
      const healthCard = formatHealthCard(data[name]);

      if (!noHealthCard && province == CanadaProvinceCodes.NT) {
        return healthCardValidationRule(healthCard, province, noHealthCard);
      }
      return true;
    },
    message: 'Please enter a valid health-card number',
  },
  {
    name: 'healthCard',
    rule: (data: DynamicData, name: string): boolean => {
      const province = data['healthCardProvinceCode'];
      const noHealthCard = data['noHealthCard'];
      const healthCard = formatHealthCard(data[name]);

      if (!noHealthCard) {
        return healthCardValidationRule(healthCard, province, noHealthCard);
      }
      return true;
    },
    message: 'Please enter a valid health-card number',
  },
  {
    name: 'province',
    rule: (data: DynamicData, name: string): boolean => {
      const province = data['healthCardProvinceCode'];
      const noHealthCard = data['noHealthCard'];
      return noHealthCard || !!province;
    },
    message: 'Please select a province',
  },
  {
    name: 'hcn',
    rule: (data: DynamicData, name: string): boolean => {
      const province = data['healthCardProvinceCode'];
      const versionCode = data[name];
      return versionCodeValidationRule(versionCode, province);
    },
    message: 'Please type a valid version code',
  },
  {
    name: 'familyDoctorId',
    rule: (data: DynamicData, name: string): boolean => {
      if (data['updateFamilyPhysician'] && data['newPatient']) {
        return !!data[name];
      }
      return true;
    },
    message: 'Family Doctor Not Specified',
  },
];

export default validator(NewPatientValidator);
