import React, { ReactElement, useState, useEffect } from 'react';
import { NewFaxValidationError, User } from 'types';
import _ from 'lodash';
import { UserApi } from 'api';
import SendFaxDialog from './SendFaxDialog.view';

const DEFAULT_TITLE = 'Send Fax';

interface Patient {
  id: number;
  name: string;
  dateOfBirth: string;
  phone: string;
  email: string;
  healthcard: string;
}
interface SendFaxDialogProps {
  open: boolean;
  onClose: () => void;
  onSendFaxClick: () => void;
}

function SendFaxDialogContainer({
  open,
  onClose,
  onSendFaxClick,
}: SendFaxDialogProps): ReactElement {
  const [validationErrors, setValidationErrors] = useState<Array<NewFaxValidationError>>([]);
  const [fieldsValidated, setFieldsValidated] = useState<boolean>(false);
  const [newRecipient, setNewRecipient] = useState<string>('');
  const [newRecipientList, setNewRecipientList] = useState<Array<string>>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [patientList, setPatientList] = useState<Array<Patient>>([]);
  const [recipients, setRecipients] = useState<Array<Patient>>([]);

  function transformUsersToPatients(users: User[]): Patient[] {
    return users.map(user => {
      let phone = '';
      if (user.contacts) {
        const phoneContacts = user.contacts.filter(contact => contact.type === 'phone');
        if (phoneContacts && phoneContacts.length > 0) {
          phone = phoneContacts[0].value;
        }
      }
      return {
        id: user.id,
        dateOfBirth: user.patientInfo ? user.patientInfo.dateOfBirth : '',
        email: user.email,
        healthcard: user.patientInfo ? user.patientInfo.healthCard : '',
        name: user.firstName + ' ' + user.lastName,
        phone,
      };
    });
  }

  async function getPatientList(searchQuery?: string): Promise<void> {
    const results = await UserApi.getPatientList(searchQuery, 'patient');
    const flattenedResults = results.flat();
    const uniqueResults = _.uniqBy(flattenedResults, 'id');
    const patients = transformUsersToPatients(uniqueResults);
    setPatientList(patients);
  }

  function onChangeOthers(e): void {
    setNewRecipient(e.target.value);
  }

  function onAddRecipient(): void {
    newRecipientList.push(newRecipient);
    setNewRecipient('');
  }

  function onRemoveRecipient(index): void {
    const tmpArray: Array<string> = newRecipientList.slice();
    tmpArray.splice(index, 1);
    setNewRecipientList(tmpArray);
  }

  function validateForm(showErrors = false): boolean {
    let validated = true;
    const validationErrors: Array<NewFaxValidationError> = [];
    if (showErrors) {
      setFieldsValidated(true);
    }

    if (newRecipientList.length === 0 && recipients.length === 0) {
      const validationErrorRecipients: NewFaxValidationError = {
        field: 'recipients',
        message: 'Please select a recipient or add a new one',
      };
      validationErrors.push(validationErrorRecipients);
      const validationErrorNewRecipients: NewFaxValidationError = {
        field: 'newRecipients',
        message: 'Please select a recipient or add a new one',
      };
      validationErrors.push(validationErrorNewRecipients);
      validated = false;
    }

    if (fieldsValidated || showErrors) {
      setValidationErrors(validationErrors);
    }
    return validated;
  }

  const searchPatients = (): void => {
    const query = searchQuery && searchQuery.length >= 2 ? searchQuery : '';
    getPatientList(query);
  };

  useEffect(() => {
    const debounce = setTimeout(() => {
      searchPatients();
    }, 400);
    return (): void => clearTimeout(debounce);
  }, [searchQuery]);

  const resetState = (): void => {
    setValidationErrors([]);
    setFieldsValidated(false);
    setNewRecipient('');
    setSearchQuery('');
    setRecipients([]);
    setNewRecipientList([]);
    setPatientList([]);
  };

  useEffect(() => {
    if (!open) {
      resetState();
      return;
    }
  }, [open]);

  return (
    <SendFaxDialog
      open={open}
      title={DEFAULT_TITLE}
      validationErrors={validationErrors}
      recipients={recipients}
      newRecipient={newRecipient}
      newRecipientList={newRecipientList}
      patientList={patientList}
      onChangeOthers={onChangeOthers}
      setRecipients={setRecipients}
      setSearchQuery={setSearchQuery}
      validateForm={validateForm}
      onAddRecipient={onAddRecipient}
      onRemoveRecipient={onRemoveRecipient}
      onClose={onClose}
      onSave={onSendFaxClick}
    />
  );
}

export default SendFaxDialogContainer;
