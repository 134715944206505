import { TextField } from '@material-ui/core';
import Dialog from 'components/Dialog';
import { UserApi, FaxApi, DocumentApi, CaseApi } from 'api';
import DialogButton from 'components/DialogButton';
import { DialogButtonType } from 'components/DialogButton/DialogButton.view';
import InfoRow from 'components/InfoRow';
import formatPhoneInput from 'lib/phoneNumFormatter';
import IFrame from 'components/IFrame';
import PatientAutoComplete from 'pages/Appointments/components/NewAppointmentDialog/components/Autocomplete';
import Autocomplete from 'components/Autocomplete';
import React, { useEffect, useState } from 'react';
import { User } from 'types';
import styles from './PreviewAndAssign.module.scss';
import { NewPatientDialogContainer } from 'pages/PatientList/components/NewPatientDialog';
import ActionConfirmationDialog from 'components/ActionConfirmationDialog';
import { AlertDialog } from 'components/AlertDialog';
import { MIME_TYPE_PDF } from 'lib/document';
import * as logger from 'api/logger';

interface PreviewAndAssignProps {
  open: boolean;
  fax: any;
  onClose: () => void;
  onComplete: (successMessage: string) => void;
}
function PreviewAndAssign({ open, fax, onClose, onComplete }: PreviewAndAssignProps) {
  const [patientOptions, setPatientOptions] = useState<any | undefined>([]);
  const [openNewPatientDialog, setOpenNewPatientDialog] = useState<boolean>(false);
  const [selectedPatient, setSelectedPatient] = useState<User>();
  const [patientSearchQuery, setPatientSearchQuery] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [selectedDocumentTypes, setSelectedDocumentTypes] = useState<Array<any>>([]);
  const [documentTypes, setDocumentTypes] = useState<Array<any>>([]);
  const [caseId, setCaseId] = useState<number>();
  const [signedUrl, setSignedUrl] = useState<string>();
  const [openActionConfirmationDialog, setOpenActionConfirmationDialog] = useState<boolean>(false);
  const [actionConfirmationMessage, setActionConfirmationMessage] = useState<string>('');
  const [alertDialogOpen, setAlertDialogOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [unassignFax, setUnassignFax] = useState<boolean>(false);

  async function getPatients(searchQuery: string | undefined = undefined): Promise<void> {
    if (!open) return;

    const users = await UserApi.getPatientList(searchQuery, '', null, null, null, null);

    const patientAutocomplete = users?.map(option => {
      const lastNameLetter = option.lastName ? option.lastName[0].toUpperCase() : '';
      return {
        lastNameLetter: /[0-9]/.test(lastNameLetter) ? '0-9' : lastNameLetter,
        ...option,
      };
    });
    setPatientOptions(
      patientAutocomplete.sort((a, b) => {
        return a.lastNameLetter.localeCompare(b.lastNameLetter);
      })
    );
  }

  const handlePatientChange = async (event, value): Promise<void> => {
    if (value?.fullName != '+ Add New Patient') {
      const patient = value;
      setSelectedPatient(patient);
    } else {
      setOpenNewPatientDialog(true);
    }
  };

  const reset = () => {
    setSignedUrl(undefined);
    setCaseId(undefined);
    setSelectedDocumentTypes([]);
    setTitle('');
    setPatientSearchQuery('');
    setSelectedPatient(undefined);
    setPatientOptions([]);
    setUnassignFax(false);
  };

  const openNewPatient = (): void => {
    setOpenNewPatientDialog(true);
  };

  const onDuplicateStatusChange = async () => {
    try {
      await FaxApi.duplicate(fax.id, !fax.duplicate);
      onComplete(!fax.duplicate ? 'Fax Marked As Duplicate' : 'Duplicate Status Removed');
    } catch (error) {
      setAlertMessage(error.response.data.message);
      setAlertDialogOpen(true);
    }
  };

  const updateSaveData = (reassign = false) => {
    return {
      title,
      types: selectedDocumentTypes,
      caseId: unassignFax ? 0 : caseId,
      reassign,
    };
  };
  const updateFax = async () => {
    try {
      if (!title || selectedDocumentTypes.length == 0 || !caseId) return;

      const data = updateSaveData(true);
      await FaxApi.assignUpdateFax(fax?.id, data);
      const message = unassignFax ? 'Unassigned' : 'Changes';
      onComplete(`${message} Successful`);
    } catch (error) {
      setAlertMessage(error.response.data.message);
      setAlertDialogOpen(true);
    }
  };

  const assignFax = async () => {
    try {
      if (!title || selectedDocumentTypes.length == 0 || !caseId) return;
      const data = updateSaveData();
      await FaxApi.assignFax(fax?.id, data);
      onComplete('Fax Assigned Successful');
    } catch (error) {
      setAlertMessage(error.response.data.message);
      setAlertDialogOpen(true);
    }
  };

  const onSave = () => {
    if (!fax.patientUserId) {
      assignFax();
    } else if (fax.patientUserId === selectedPatient?.id) {
      updateFax();
    } else {
      setActionConfirmationMessage('Are You sure you would like to reassign the fax?');
      setOpenActionConfirmationDialog(true);
    }
  };

  const onUnassign = () => {
    setUnassignFax(true);
    setActionConfirmationMessage('Are You sure you would like to unassign the fax?');
    setOpenActionConfirmationDialog(true);
  };

  const getDocumentTypes = async () => {
    const documentTypes = await DocumentApi.getDocumentTypes();
    setDocumentTypes(documentTypes);
  };

  const getCase = async () => {
    try {
      if (!selectedPatient) return;

      const cases = await CaseApi.getCaseByUserId(selectedPatient.id);
      if (!cases || cases.length == 0) return;
      setCaseId(cases[0].id);
    } catch (err) {
      logger.error(err.message);
    }
  };

  const getPatient = async () => {
    if (!fax.patientUserId) return;
    const patient = await UserApi.getUser(fax.patientUserId);
    setSelectedPatient(patient);
  };

  const getSignedUrl = async () => {
    const signedUrl = await FaxApi.getSignedUrl(fax.id);
    setSignedUrl(signedUrl.signedRequest);
  };

  const onNewPatientSaveSuccess = async (): Promise<void> => {
    setOpenNewPatientDialog(false);
    await getPatients();
  };

  const getActionTitle = () => {
    if (!fax || !fax.patientUserId) return 'Assign';
    if (fax.patientUserId !== selectedPatient?.id) return 'Reassign';
    return 'Update';
  };

  useEffect(() => {
    getCase();
  }, [selectedPatient]);

  useEffect(() => {
    if (!fax || !open) {
      reset();
      return;
    }
    getPatients();
    setSelectedDocumentTypes(fax.documentTypes);
    setTitle(fax.title);
    getPatient();
    getSignedUrl();
  }, [fax, open]);

  useEffect(() => {
    getDocumentTypes();
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getPatients(patientSearchQuery);
    }, 300);

    return (): void => clearTimeout(delayDebounceFn);
  }, [patientSearchQuery]);
  return (
    <Dialog fullWidth open={open} title={`${getActionTitle()} Fax`} onClose={onClose}>
      <div className={styles.container}>
        <form className={styles.formContainer}>
          <div className={styles.pdfColumn}>
            <IFrame url={signedUrl} type={MIME_TYPE_PDF} title={'Fax Preview'} />
          </div>
          <div className={styles.formColumn}>
            <div className={styles.stepOne}>
              <p className={styles.steps}>
                <span>Step 1:</span> Create a title and select document types
              </p>
              <div className={styles.formFieldContainer}>
                <Autocomplete
                  label="Document Types"
                  items={documentTypes}
                  value={selectedDocumentTypes}
                  getOptionLabel={type => `${type.type}`}
                  onChange={(event, newValue) => setSelectedDocumentTypes(newValue)}
                  multiple
                  fullWidth
                />
              </div>
              <div className={styles.formFieldContainer}>
                <TextField
                  className={styles.textField}
                  name={'title'}
                  label="Title"
                  placeholder="Enter name for file"
                  onChange={event => setTitle(event.target.value)}
                  value={title}
                  multiline={false}
                  inputProps={{ maxLength: 50 }}
                />
              </div>
            </div>
            <div className={styles.stepTwo}>
              <p className={styles.steps}>
                <span>Step 2:</span> Choose or create a patient
              </p>
              <div className={styles.formFieldContainer}>
                <PatientAutoComplete
                  loading={false}
                  options={patientOptions}
                  handlePatientChange={handlePatientChange}
                  openNewPatient={openNewPatient}
                  value={selectedPatient}
                  patientSearchQuery={patientSearchQuery}
                  setPatientSearchQuery={setPatientSearchQuery}
                />
              </div>
              <div className={styles.formFieldContainer}>
                <InfoRow label={'Name'} value={selectedPatient?.fullName || ''} />
              </div>
              <div className={styles.formFieldContainer}>
                <InfoRow
                  label={'Date of Birth'}
                  value={selectedPatient?.patientInfo?.dateOfBirth || ''}
                />
              </div>
              {selectedPatient?.patientInfo?.noHealthCard ? (
                <div>
                  <div className={styles.formFieldContainer}>
                    <InfoRow
                      label={'Province'}
                      value={selectedPatient?.patientInfo?.healthCardProvinceCode || ''}
                    />
                  </div>
                  <div className={styles.formFieldContainer}>
                    <InfoRow
                      label={'Health Card'}
                      value={selectedPatient?.patientInfo?.healthCard || ''}
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div className={styles.formFieldContainer}>
                <InfoRow label={'Email'} value={selectedPatient?.email || ''} />
              </div>

              {selectedPatient?.contacts.map(contact => {
                return (
                  <div key={contact.id} className={styles.formFieldContainer}>
                    <InfoRow label={contact.type} value={formatPhoneInput(contact.value || '')} />
                  </div>
                );
              })}
            </div>
          </div>
        </form>

        <div className={styles.buttonSection}>
          {fax?.patientUserId ? (
            <DialogButton
              buttonType={DialogButtonType.AffirmativeLink}
              className={styles.btn}
              onClick={onUnassign}
              loading={false}
            >
              Unassign
            </DialogButton>
          ) : (
            <DialogButton
              buttonType={DialogButtonType.AffirmativeLink}
              className={styles.btn}
              onClick={onDuplicateStatusChange}
              loading={false}
            >
              {fax?.duplicate ? 'Not Duplicate' : 'Duplicate'}
            </DialogButton>
          )}

          <div className={styles.verticalSeparator} />

          <DialogButton buttonType={DialogButtonType.NegationLink} onClick={onClose}>
            Cancel
          </DialogButton>
          {!fax?.duplicate && (
            <DialogButton
              buttonType={DialogButtonType.AffirmativeLink}
              className={styles.btn}
              onClick={onSave}
              loading={false}
            >
              {getActionTitle()}
            </DialogButton>
          )}
        </div>
      </div>
      <NewPatientDialogContainer
        open={openNewPatientDialog}
        onClose={(): void => setOpenNewPatientDialog(false)}
        onSaveSuccess={onNewPatientSaveSuccess}
        handlePatientChange={handlePatientChange}
        pdfUrl={signedUrl}
      />
      <ActionConfirmationDialog
        open={openActionConfirmationDialog}
        title="Update Fax"
        description={actionConfirmationMessage}
        onClose={() => {
          setOpenActionConfirmationDialog(false);
          setUnassignFax(false);
        }}
        onConfirm={() => {
          setOpenActionConfirmationDialog(false);
          updateFax();
        }}
      />
      <AlertDialog
        open={alertDialogOpen}
        message={alertMessage}
        title="Error"
        onClose={() => setAlertDialogOpen(false)}
      />
    </Dialog>
  );
}

export default PreviewAndAssign;
