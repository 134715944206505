import React, { ReactElement, SyntheticEvent } from 'react';
import { withStyles, Theme } from '@material-ui/core';
import MuiButton from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import classNames from 'classnames';

import styles from './ButtonStyles.module.scss';

interface ButtonProp {
  children: any;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  onClick?: (event: SyntheticEvent) => void;
  [other: string]: any;
}

const ColorButton = withStyles((theme: Theme) => ({
  root: {
    color: 'white',
    fontWeight: 'bold',
    height: '50px',
    fontSize: '1.1875rem',
    lineHeight: '1.375rem',
    backgroundColor: '#26315A',
    '&:hover': {
      backgroundColor: '#26315A',
      boxShadow: '0 0 2px #B00000',
    },
  },
  disabled: {
    backgroundColor: '#ABABAB',
    color: '#eaeaea !important',
    fontWeight: 'bold',
    lineHeight: '1.375rem',
  },
}))(MuiButton);

function Button({
  children,
  disabled = false,
  loading = false,
  onClick,
  className,
  ...other
}: ButtonProp): ReactElement {
  return (
    <div className={classNames(styles.container, className)}>
      <ColorButton {...other} disabled={disabled || loading} onClick={onClick}>
        {children}
      </ColorButton>
      {loading && <CircularProgress size={24} className={styles.progress} />}
    </div>
  );
}

export default Button;
