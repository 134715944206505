export default class CaseInsensitiveMap<T, U> extends Map<T, U> {
  set(key: T, value: U): this {
    if (typeof key === 'string') {
      key = (key.toUpperCase() as any) as T;
    }
    return super.set(key, value);
  }

  get(key: T): U | undefined {
    if (typeof key === 'string') {
      key = (key.toUpperCase() as any) as T;
    }
    return super.get(key);
  }
}
