import React, { ReactElement } from 'react';
import { Page, Text, View, Document, Image as PDFImage, StyleSheet } from '@react-pdf/renderer';
import FullLogoTagline from 'assets/images/full-logo-tagline.png';
import { receiptClinicCities } from 'lib/receipt';
import formatDate, { DateTimeFormat } from 'lib/dateFormatter';
import { AppointmentInfo } from 'api/appointment/appointment.interfaces';

interface ReceiptPDFRenderProps {
  header?: string;
  appointments: Array<AppointmentInfo>;
  patientName: string;
  patientBirthday: string;
  patientHealthCardNumber: string;
  patientGender: string;
}

export const useAppointmentHistoryPDFRender = ({
  header = 'Appointment History',
  appointments = [],
  patientName,
  patientBirthday,
  patientHealthCardNumber,
  patientGender,
}: ReceiptPDFRenderProps) => {
  const stylesPdf = StyleSheet.create({
    body: {
      paddingTop: 15,
      paddingBottom: 5,
      paddingHorizontal: 35,
      alignItems: 'center',
      justifyContent: 'center',
    },
    headerImage: {
      width: '60vw',
      objectFit: 'cover',
    },
    clinicCities: {
      fontSize: 8,
      paddingTop: 6,
      paddingBottom: 6,
    },
    subHeader: {
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: 15,
      paddingBottom: 2,
      flexWrap: 'wrap',
      maxWidth: 350,
    },
    header: {
      fontSize: 18,
      textTransform: 'uppercase',
    },
    appointmentTimeAndDate: {
      margin: '10px 0',
      fontSize: '14px',
      display: 'flex',
      alignSelf: 'flex-start',
    },
    textWrapper: { flex: 1 },
    paragraph: {
      margin: '10px 0px',
      fontSize: '13px',
      display: 'flex',
      alignSelf: 'flex-start',
    },
    signOff: {
      marginTop: '30px',
      margin: '10px 0px',
      fontSize: '14px',
      display: 'flex',
      alignSelf: 'flex-end',
    },
  });
  const cities = receiptClinicCities.join(' - ');
  const AppointmentHistoryReportLayout = (id): ReactElement => {
    const getPronouns = () => {
      switch (patientGender) {
        case 'female':
          return 'her';
        case 'male':
          return 'him';
        default:
          return 'them';
      }
    };
    return (
      <Document>
        <Page style={stylesPdf.body}>
          <View style={stylesPdf.headerImage}>
            <PDFImage src={FullLogoTagline}></PDFImage>
          </View>
          <View style={stylesPdf.textWrapper}>
            <View style={stylesPdf.clinicCities}>
              <Text> {cities} </Text>
            </View>
            <Text style={stylesPdf.paragraph}>
              I am writing to provide you with the appointment history of one of our patients at
              Canada Vein Clinics. The patient&apos;s name is {patientName},(
              {patientHealthCardNumber}/{patientBirthday}) and we have treated {getPronouns()} for a
              period of time.
            </Text>
            <Text style={stylesPdf.paragraph}>
              During this time, the patient has had appointments with our clinic(s). The appointment
              dates are as follows:
            </Text>

            {appointments.map((appt, index: number) => {
              return (
                <View
                  style={stylesPdf.appointmentTimeAndDate}
                  key={`appointment_history_report_pdf_row_${index}`}
                >
                  <Text>
                    {appt.location?.name || ''} Clinic
                    {'  '}
                    {formatDate(DateTimeFormat.DateWithFullMonthName, appt.start)}
                    {'  '}
                    {appt.startTimeOnly} - {appt.endTimeOnly}
                    {'  '}
                    {appt.type} - {appt.subTypeName}
                  </Text>
                </View>
              );
            })}
            <Text style={stylesPdf.paragraph}>
              Please note that the above information is accurate to the best of our knowledge and
              records. If you require any further information regarding the patient&apos;s medical
              history or treatment plan, please do not hesitate to contact our clinic
            </Text>
            <Text style={stylesPdf.paragraph}>
              We take patient confidentiality very seriously, and as such, we require written
              authorization from the patient in order to release any further information.
            </Text>
            <Text style={stylesPdf.paragraph}>
              Thank you for your understanding and cooperation in this matter.
            </Text>
            <Text style={stylesPdf.signOff}>CVC team</Text>
          </View>
        </Page>
      </Document>
    );
  };
  return {
    AppointmentHistoryReportLayout,
  };
};

export default useAppointmentHistoryPDFRender;
