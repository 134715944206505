import React, { ReactElement, useState, memo } from 'react';
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import ResponsiveTopBar from './components/ResponsiveTopBar';
import EnhancedTableHead from './components/EnhancedTableHead';
import styles from './PatientList.module.scss';
import { NavLink } from 'react-router-dom';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import classNames from 'classnames';

import { ReactComponent as RubishIcon } from 'assets/svgs/Rubish.svg';
import moment from 'moment';
import { protectByRole } from '../../App/routing/utils/protectByRole';
import { ROLE } from 'lib/user';
import formatPhoneInput from 'lib/phoneNumFormatter';
import { DateType } from '@date-io/type';
import { LocationListItem, SubType } from 'types';
import { Patient } from './PatientList.container';
import { DateTimeFormatString } from 'lib/dateFormatter';

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
      maxWidth: '300px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: '#F1F3FF',
      },
      cursor: 'pointer',
    },
  })
)(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  cellColored: {
    color: '#B00000',
    fontSize: 16,
    fontStyle: 'normal',
    borderRadius: 3,
    fontWeight: 'bold',
  },
  cell: {
    fontSize: 16,
    fontStyle: 'normal',
    borderRadius: 3,
    fontWeight: 'bold',
  },
  rubbish: {
    '&:hover': {
      backgroundColor: 'rgba(176, 0, 0, 0.05)',
    },
  },
});

interface PatientListProps {
  appointmentTypeId: string | number | null;
  selectAppointmentTypeId: (number) => void;
  onChangeSearchQuery: (string) => void;
  onNewPatientClick: () => void;
  cancellationValue: boolean | undefined;
  selectCancellationValue: (value: boolean) => void;
  onDeletePatientClick: (patientToDelete) => void;
  selectLocation: (number) => void;
  onClickPatient: (patient) => void;
  locationIds?: Array<number>;
  locations: Array<LocationListItem>;
  patientList?: Array<Patient>;
  searchQuery?: string;
  appointmentStartDay: DateType | null;
  selectAppointmentStartDate: (date) => void;
  subTypes: Array<SubType>;
}

function DeleteIcon(props): ReactElement {
  return <RubishIcon onClick={props?.onClick} />;
}

const protectedForStaff = protectByRole([ROLE.SUPER_ADMIN, ROLE.ADMIN], () => null);

const DeleteIconProtectedForAdmin = protectedForStaff(DeleteIcon);

function PatientList({
  appointmentTypeId,
  selectAppointmentTypeId,
  selectLocation,
  onChangeSearchQuery,
  onNewPatientClick,
  cancellationValue,
  selectCancellationValue,
  onDeletePatientClick,
  onClickPatient,
  searchQuery = '',
  patientList = [],
  locationIds = [],
  locations = [],
  appointmentStartDay,
  selectAppointmentStartDate,
  subTypes = [],
}: PatientListProps): ReactElement {
  const classes = useStyles();

  const getSelectedPatient = (id: number) => {
    return patientList.find(item => item.id === id);
  };

  function onClickRow(id: number) {
    const patient = getSelectedPatient(id);
    if (patient) {
      onClickPatient(patient);
    }
  }
  const getPatientBaseInfo = (id: number) => {
    const patient = getSelectedPatient(id);
    if (patient) return { id: patient.id, name: patient.name };
  };

  /*  function onClickDelete(event: React.MouseEvent<SVGSVGElement, MouseEvent>, row) {
    event.stopPropagation();
    onDeletePatientClick(row);
  }*/

  return (
    <div className={styles.container}>
      <ResponsiveTopBar
        appointmentTypeId={appointmentTypeId}
        selectAppointmentTypeId={selectAppointmentTypeId}
        locationIds={locationIds}
        locations={locations}
        selectLocation={selectLocation}
        searchQuery={searchQuery}
        onChangeSearchQuery={onChangeSearchQuery}
        onNewPatientClick={onNewPatientClick}
        cancellationValue={cancellationValue}
        selectCancellationValue={selectCancellationValue}
        appointmentStartDay={appointmentStartDay}
        selectAppointmentStartDate={selectAppointmentStartDate}
        subTypes={subTypes}
      />

      <TableContainer
        className={classNames(
          styles.tableContainer,
          locationIds.length > 0 && styles.tableContainerFilterLocations
        )}
      >
        <Table className={classes.table} aria-label="customized table">
          <EnhancedTableHead classes={classes} />
          <TableBody>
            {/* {PatientList.map(({ id, fullName, type, addresses: { city } }) => (
              <StyledTableRow key={id}>
                <StyledTableCell>{fullName}</StyledTableCell>
                <StyledTableCell>{type}</StyledTableCell>
                <StyledTableCell>{city}</StyledTableCell>
              </StyledTableRow>
            ))} */}
            {patientList.map((row, index) => {
              const labelId = `enhanced-table-checkbox-${index}`;
              let dob = '';
              if (row.dateOfBirth) {
                dob = moment(row.dateOfBirth)
                  .format('DD/MM/YYYY')
                  .toString();
              }

              return (
                <StyledTableRow
                  hover
                  onClick={event => onClickRow(row.id)}
                  role="checkbox"
                  tabIndex={-1}
                  key={row.id}
                >
                  <StyledTableCell id={labelId}>{row.name}</StyledTableCell>
                  <StyledTableCell>{row.id}</StyledTableCell>
                  <StyledTableCell>{dob}</StyledTableCell>
                  <StyledTableCell>{formatPhoneInput(row.phone)}</StyledTableCell>
                  <StyledTableCell>{row.email}</StyledTableCell>
                  <StyledTableCell>{row.healthcard + row.hcn}</StyledTableCell>
                  <StyledTableCell>{row.latestAppointmentStatus}</StyledTableCell>
                  <StyledTableCell>
                    <NavLink
                      onClick={event => event.stopPropagation()}
                      to={{
                        pathname: `/appointments/${moment().format(
                          DateTimeFormatString.APIDateFormat
                        )}`,
                        state: { presetPatient: getPatientBaseInfo(row.id) },
                      }}
                      className={styles.icon}
                      title="Go to appointments"
                    >
                      <ArrowForwardOutlinedIcon fontSize="small" />
                    </NavLink>
                  </StyledTableCell>

                  {/* <StyledTableCell className={classes.rubbish}>
                    <DeleteIconProtectedForAdmin onClick={event => onClickDelete(event, row)} />
                  </StyledTableCell> */}
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default memo(PatientList);
