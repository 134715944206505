import React, { Fragment, ReactElement } from 'react';
import Tasks from '../Tasks';
import usePatientInfo from 'pages/Patient/components/PatientInfo/usePatientApi.hook';
import useTasksApi from '../../hook/useTasksApi.hook';

import styles from './NotesAndTasksStyles.module.scss';

function NotesAndTasks(props): ReactElement {
  const patientId = props?.match?.params?.patientId;
  const { patient } = usePatientInfo(patientId);
  const { tasks } = useTasksApi({ patientId, showCompleted: true, hideTodo: true });

  return (
    <Fragment>
      <div className={styles.right}>
        <Tasks tasks={tasks} patient={patient} />
      </div>
    </Fragment>
  );
}

export default NotesAndTasks;
