import React, { ReactElement, useEffect, useState } from 'react';
import Button from 'components/Button';
import styles from './ResponsiveTopBar.module.scss';
import TitledSection from 'components/TitledSection';
import LocationSelector from 'components/LocationSelector/LocationSelector.container';
import Selector from 'components/Selector/';
import { Staff } from 'api/user/user.interfaces';
import { LocationListItem } from 'types';

interface ResponsiveTopBarProps {
  searchQuery: string | null;
  locationIds: Array<number>;
  locations: Array<LocationListItem>;
  selectLocations: (location) => void;
  onChangeSearchQuery: (query) => void;
  onNewLocationClick: () => void;
  onSendSchedulesClick: () => void;
  staffList: Array<Staff>;
  highlightedStaff: number;
  setHighlightedStaff: (staff) => void;
}

function ResponsiveTopBar({
  searchQuery,
  locationIds,
  locations,
  selectLocations,
  onChangeSearchQuery,
  highlightedStaff,
  setHighlightedStaff,
  staffList,
  onNewLocationClick,
  onSendSchedulesClick,
}: ResponsiveTopBarProps): ReactElement {
  function onChange(e) {
    setHighlightedStaff(e.target.value);
  }

  const [alteredStaffList, setAlteredStaffList] = useState<any>([]);

  useEffect(() => {
    const tmpArr: Array<any> = [];
    staffList.forEach(staff => {
      const tmp = {
        id: staff.id,
        name: staff.name,
      };
      tmpArr.push(tmp);
    });
    setAlteredStaffList(tmpArr);
  }, [staffList]);
  return (
    <div className={styles.container}>
      <div className={styles.toolbar}>
        <TitledSection title="Scheduling" />
        <div className={styles.selectors}>
          <div className={styles.topBarItem}>
            <Selector
              items={alteredStaffList}
              label="Staff"
              value={highlightedStaff}
              onChange={onChange}
            />
          </div>
          <div className={styles.topBarItem}>
            <LocationSelector
              locations={locations}
              value={locationIds}
              onChange={selectLocations}
            />
          </div>
          <div className={styles.topBarItemMargin}>
            <Button
              className={`${styles.marginLeft} ${styles.marginTop}`}
              onClick={onNewLocationClick}
            >
              New Location
            </Button>
          </div>
          <div className={styles.topBarItemMargin}>
            <Button
              className={`${styles.marginLeft} ${styles.marginTop}`}
              onClick={onSendSchedulesClick}
            >
              Send Schedules
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResponsiveTopBar;
