import React, { ReactElement, memo } from 'react';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

import Dialog from 'components/Dialog';
import Autocomplete from 'components/Autocomplete';
import DialogButton from 'components/DialogButton';
import styles from './EditStaffDialog.module.scss';
import { NewStaffDialogValidationError, Location } from 'types';
import { ValidationError } from 'components/Forms/ValidationError';
import formatPhoneInput, { MAX_PHONE_CHARACTER_LENGTH } from 'lib/phoneNumFormatter';

interface UserType {
  id: number;
  type: string;
}

interface EditStaffDialogProps {
  open?: boolean;
  updateStaff?: boolean;
  loading: boolean;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  OHIPPhysicianId?: string;
  abbreviations?: string;
  userType: UserType | null;
  userTypesList?: Array<UserType>;
  locationsList: Array<Location>;
  locations: Array<Location>;
  validationErrors?: Array<NewStaffDialogValidationError>;
  errorMessage?: string;
  dialogType?: string;
  onUpdate?: () => void;
  onSave?: () => void;
  onClose?: () => void;
  onUserTypeSelect?: (value) => void;
  onOHIPPhysicianId?: (value) => void;
  onFirstNameChange?: (value) => void;
  onLastNameChange?: (value) => void;
  onEmailChange?: (value) => void;
  onPhoneChange?: (value) => void;
  onUserTypeChange?: (value) => void;
  onLocationChange?: (value) => void;
  onAbbreviationsChange?: (value) => void;
}

function EditStaffDialog({
  open,
  updateStaff,
  loading = false,
  firstName = '',
  lastName = '',
  email = '',
  phone = '',
  userType = null,
  OHIPPhysicianId = '',
  abbreviations = '',
  userTypesList = [],
  locationsList = [],
  locations = [],
  validationErrors = [],
  errorMessage = '',
  dialogType,
  onSave,
  onUpdate,
  onClose,
  onOHIPPhysicianId = value => null,
  onUserTypeSelect = value => null,
  onFirstNameChange = value => null,
  onLastNameChange = value => null,
  onEmailChange = value => null,
  onPhoneChange = value => null,
  onUserTypeChange = value => null,
  onLocationChange = value => null,
  onAbbreviationsChange = value => null,
}: EditStaffDialogProps): ReactElement {
  return (
    <Dialog open={open} title={dialogType === 'new' ? 'New Staff' : 'Edit Staff'} onClose={onClose}>
      <div className={classNames(styles.dialogBody, styles.withPadding)}>
        <div className={styles.fields}>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <div className={styles.withPadding}>
                <TextField
                  label="First Name"
                  onChange={event => onFirstNameChange(event.target.value)}
                  fullWidth
                  value={firstName}
                />
                <ValidationError field={'firstName'} validationErrors={validationErrors} />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={styles.withPadding}>
                <TextField
                  label="Last Name"
                  onChange={event => onLastNameChange(event.target.value)}
                  fullWidth
                  value={lastName}
                />
                <ValidationError field={'lastName'} validationErrors={validationErrors} />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={styles.withPadding}>
                <TextField
                  label="Email"
                  onChange={event => onEmailChange(event.target.value)}
                  fullWidth
                  value={email}
                />
                <ValidationError field={'email'} validationErrors={validationErrors} />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={styles.withPadding}>
                <TextField
                  label="Phone"
                  onChange={event => onPhoneChange(event.target.value)}
                  fullWidth
                  value={formatPhoneInput(phone)}
                  inputProps={{
                    maxLength: MAX_PHONE_CHARACTER_LENGTH,
                  }}
                />
                <ValidationError field={'phone'} validationErrors={validationErrors} />
              </div>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={6}>
              <div className={styles.withPadding}>
                <Autocomplete
                  label="User Type"
                  items={userTypesList}
                  value={userType}
                  getOptionLabel={type => `${type.type}`}
                  onChange={(event, newValue) => onUserTypeSelect(newValue)}
                  onInputChange={event => onUserTypeChange(event.target.value)}
                  fullWidth
                  disabled={updateStaff}
                />
                <ValidationError field={'userType'} validationErrors={validationErrors} />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={styles.withPadding}>
                <TextField
                  label="Ohip Physician ID"
                  value={OHIPPhysicianId}
                  onChange={event => onOHIPPhysicianId(event.target.value)}
                  fullWidth
                />
              </div>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <div className={styles.withPadding}>
                <Autocomplete
                  label="Locations"
                  items={locationsList}
                  value={locations}
                  getOptionLabel={type => `${type.name}`}
                  onChange={(event, newValue) => onLocationChange(newValue)}
                  multiple
                  fullWidth
                />
                <ValidationError field={'locations'} validationErrors={validationErrors} />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={styles.withPadding}>
                <TextField
                  label="Abbreviations"
                  onChange={event => onAbbreviationsChange(event.target.value)}
                  fullWidth
                  value={abbreviations}
                />
              </div>
            </Grid>
          </Grid>
        </div>

        <div className={classNames(styles.buttonSection, styles.rightContent)}>
          <DialogButton onClick={onClose}>Cancel</DialogButton>
          {!loading ? (
            dialogType === 'new' ? (
              <DialogButton className={styles.btn} onClick={onSave} loading={loading}>
                Save
              </DialogButton>
            ) : (
              <DialogButton className={styles.btn} onClick={onUpdate} loading={loading}>
                Update
              </DialogButton>
            )
          ) : (
            <CircularProgress size={16} style={{ padding: '10px 20px' }} />
          )}
        </div>
        <div className={classNames(styles.textError)}>{errorMessage}</div>
      </div>
    </Dialog>
  );
}

export default memo(EditStaffDialog);
