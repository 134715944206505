import React, { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';

import styles from './FaxManagementHeaderStyles.module.scss';

interface FaxManagementHeaderProps {
  parentUrl?: string;
}

function FaxManagementHeader({ parentUrl = '' }: FaxManagementHeaderProps): ReactElement {
  const url = !parentUrl || parentUrl === '/' ? '' : parentUrl;

  return (
    <div className={styles.container}>
      <NavLink to={`${url || '/'}`} className={styles.link} activeClassName={styles.selected} exact>
        Received
      </NavLink>
      <NavLink to={`${url}/sent`} className={styles.link} activeClassName={styles.selected}>
        Sent
      </NavLink>
    </div>
  );
}

export default FaxManagementHeader;
