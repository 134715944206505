import React, { ReactElement, memo, useState, useEffect } from 'react';
import { Moment } from 'moment';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { TextField } from '@material-ui/core';

import Dialog from 'components/Dialog';
import DialogButton from 'components/DialogButton';
import Autocomplete from 'components/Autocomplete';
import { AppointmentsApi } from 'api';
import { emailValidationRule } from 'lib/validator';
import { Staff } from 'api/user/user.interfaces';
import { DoctorSchedule } from 'types';

import styles from './MailScheduleDialog.module.scss';

interface MailScheduleDialogProps {
  open?: boolean;
  onClose: () => void;
  onChange?: () => void;
  date: Moment;
  staffList: Array<Staff>;
  staffListForLocation: Array<Staff>;
  staffSchedules: Array<DoctorSchedule>;
  selectedLocation: number;
}

function MailScheduleDialog({
  open,
  onClose,
  date,
  staffList,
  staffListForLocation,
  staffSchedules,
  selectedLocation,
}: MailScheduleDialogProps): ReactElement {
  const [alteredStaffList, setAlteredStaffList] = useState<any>([]);
  const [responses, setResponses] = useState<Array<any>>([]);
  const [selectedStaff, setSelectedStaff] = useState<Array<Staff>>([]);
  const [selectedStaffToSend, setSelectedStaffToSend] = useState<Array<Staff>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [otherMail, setOtherMail] = useState<string>('');
  const [otherMailError, setOtherMailError] = useState<boolean>(false);
  const [otherMailErrorMessage, setOtherMailErrorMessage] = useState<string>('');
  const [staffErrorMessage, setStaffErrorMessage] = useState<string>('');
  const [otherMailList, setOtherMailList] = useState<Array<string>>([]);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    const filteredStaffList: Array<any> = [{ id: -1, name: 'All' }];
    const uniqueStaffSchedules = [
      ...Array.from(new Map(staffSchedules.map(schedule => [schedule.staffId, schedule])).values()),
    ];
    for (const schedule of uniqueStaffSchedules) {
      const staff = staffListForLocation.find(staff => schedule?.staffId === staff.id);
      if (staff) filteredStaffList.push(staff);
    }
    setAlteredStaffList(filteredStaffList);
  }, [staffListForLocation, staffSchedules]);

  function onChangeStaff(selectedStaffList): void {
    setError('');
    if (selectedStaffList.find(staff => staff.id === -1)) {
      setSelectAll(prevSelectAll => !prevSelectAll);
      if (!selectAll) {
        setSelectedStaff(alteredStaffList.slice(1));
      } else {
        setSelectedStaff([]);
      }
    } else {
      if (selectedStaffList.length === alteredStaffList.length) {
        setSelectAll(true);
      } else {
        setSelectAll(false);
      }
      setSelectedStaff(selectedStaffList);
    }
  }

  function onChangeStaffToSend(selectedStaffList): void {
    setError('');
    setSelectedStaffToSend(selectedStaffList);
  }

  function onChangeOthers(e): void {
    setOtherMailError(false);
    setOtherMailErrorMessage('');
    setError('');
    setOtherMail(e.target.value);
  }

  function onAddMail(): void {
    if (emailValidationRule({ email: otherMail }, 'email')) {
      otherMailList.push(otherMail);
      setOtherMail('');
    } else {
      setOtherMailError(true);
      setOtherMailErrorMessage('Enter a valid email address');
    }
  }

  function onRemoveMail(index): void {
    const tmpArray: Array<string> = otherMailList.slice();
    tmpArray.splice(index, 1);
    setOtherMailList(tmpArray);
  }

  function onCloseDialog(): void {
    onClose();
    setSelectedStaff([]);
    setSelectedStaffToSend([]);
    setLoading(false);
    setResponses([]);
    setOtherMailList([]);
    setOtherMail('');
    setError('');
    setOtherMailError(false);
    setOtherMailErrorMessage('');
    setStaffErrorMessage('');
  }

  async function onSend(): Promise<void> {
    if (selectedStaffToSend.length === 0 && otherMailList.length === 0) {
      setError('Please provide at least one receiver');
    } else if (selectedStaff.length === 0) {
      setStaffErrorMessage('Please select at least one staff member');
    } else {
      const staffEmails: Array<string> = [];
      selectedStaff.forEach(staff => {
        staffEmails.push(staff.email);
      });
      const otherEmails: Array<string> = [...otherMailList];
      selectedStaffToSend.forEach(staff => {
        otherEmails.push(staff.email);
      });

      setLoading(true);
      try {
        const response = await AppointmentsApi.mailSchedule(
          date.toISOString(true),
          selectedLocation,
          staffEmails,
          otherEmails
        );
        setResponses(response);
      } catch (e) {
        setError(e.response.data.message);
      }
      setLoading(false);
    }
  }

  return (
    <Dialog open={open} title={'Email to contacts'} onClose={onCloseDialog}>
      <div className={classNames(styles.dialogBody, styles.withPadding)}>
        {responses.length > 0 ? (
          responses.map(response => {
            if (response.value) {
              return <div className={classNames(styles.withPadding)}>{response.value.message}</div>;
            } else {
              return (
                <div className={classNames(styles.error, styles.withPadding)}>
                  {response.reason.message}
                </div>
              );
            }
          })
        ) : (
          <div className={styles.fields}>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <div className={styles.withPadding}>
                  <Autocomplete
                    label="Staff schedules"
                    name="Staff schedules"
                    items={alteredStaffList}
                    value={selectedStaff}
                    error={staffErrorMessage}
                    getOptionLabel={(staff): string => `${staff.name}`}
                    onChange={(event, newValue): void => onChangeStaff(newValue)}
                    multiple
                    fullWidth
                  />
                </div>
                <div className={styles.withPadding}>
                  <Autocomplete
                    label="Staff to send"
                    name="Staff to send"
                    items={staffList}
                    value={selectedStaffToSend}
                    getOptionLabel={(staff): string => `${staff.name}`}
                    onChange={(event, newValue): void => onChangeStaffToSend(newValue)}
                    multiple
                    fullWidth
                  />
                </div>
                <div className={styles.otherMails}>
                  <TextField
                    label="Additional emails"
                    onChange={onChangeOthers}
                    value={otherMail}
                    type="email"
                    error={otherMailError}
                    helperText={otherMailErrorMessage}
                    fullWidth
                  />
                  <AddCircleIcon className={styles.addCircleIcon} onClick={onAddMail} />
                </div>
                {otherMailList.length > 0 ? (
                  <div className={styles.allMails}>
                    {otherMailList.map((mail, index) => (
                      <div key={index} className={classNames(styles.otherMails, styles.space)}>
                        {mail}
                        <RemoveCircleIcon
                          className={styles.RemoveCircleIcon}
                          onClick={() => onRemoveMail(index)}
                        />
                      </div>
                    ))}
                  </div>
                ) : null}
              </Grid>
            </Grid>
          </div>
        )}
        <div className={classNames(styles.error, styles.withPadding)}>{error}</div>
        <div className={classNames(styles.buttonSection, styles.rightContent, styles.withPadding)}>
          <DialogButton onClick={onCloseDialog} className={styles.button}>
            {responses.length > 0 ? 'Ok' : 'Cancel'}
          </DialogButton>
          {responses.length === 0 ? (
            <DialogButton onClick={onSend} loading={loading} className={styles.button}>
              Send
            </DialogButton>
          ) : null}
        </div>
      </div>
    </Dialog>
  );
}

export default memo(MailScheduleDialog);
