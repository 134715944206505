import React, { ReactElement, useEffect, useState } from 'react';
import { StringValueItem } from 'types';

import Selector from 'components/Selector';
import { APPOINTMENT_STATUSES } from 'lib/appointments';

interface AppointmentStatusSelectorProps {
  value?: number | null;
  [other: string]: any;
}

function AppointmentStatusSelector({
  value,
  ...other
}: AppointmentStatusSelectorProps): ReactElement {
  const [items, setItems] = useState<Array<StringValueItem>>([]);

  useEffect(() => {
    setItems(APPOINTMENT_STATUSES);
  }, []);

  return <Selector {...other} items={items} label="Appointment Status" value={value} />;
}

export default AppointmentStatusSelector;
