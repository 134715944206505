import React, { ReactElement, useEffect, useState } from 'react';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { useFormik } from 'formik';
import styles from './SendDefaultDocumentDialogStyles.module.scss';
import { Document, FaxFields, User } from 'types';
import Dialog from 'components/Dialog';
import DialogButton from 'components/DialogButton';
import { SEND_TYPES, SEND_TYPE_LIST } from 'lib/document';

import { DocumentsApiHookInterface } from '../../../../useDocumentsApi.hook';
import SendDocumentDialogValidator from './SendDefaultDocumentDialog.validator';
import { MenuItem } from '@material-ui/core';
import Select from 'components/Select';
import { PDFDownloadLink } from '@react-pdf/renderer';
import useFaxApi from 'hook/useFaxApi.hook';
import useNewFaxPDFRender from 'pages/Fax/components/NewFaxDialog/useNewFaxPDFRender.hook';
import useNewFaxPDFActions from 'pages/Fax/components/NewFaxDialog/useFaxActions.hook';
import InfoRow from 'components/InfoRow';
import { AlertDialog } from 'components/AlertDialog';
import { handleAPIErrors } from 'lib/handleAPIErrors';

interface SendDefaultDocumentDialogProps {
  open?: boolean;
  onClose?: () => void;
  document?: Document;
  patient?: User | null;
  documentsApiHook: DocumentsApiHookInterface;
  toggle: (boolean) => void;
}

function SendDefaultDocumentDialog({
  open,
  onClose,
  document,
  patient,
  documentsApiHook,
  toggle,
}: SendDefaultDocumentDialogProps): ReactElement {
  const [loading, setloading] = useState<boolean>(false);
  const [openAlertDialog, setOpenAlertDialog] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const { sendGenericDefaultDocument } = documentsApiHook;
  const [sendType, setSendType] = useState<string>(SEND_TYPES.EMAIL);
  const [fieldValues, setFieldValues] = useState<FaxFields>({
    title: '',
    faxNumber: '',
    note: '',
    recipientName: '',
    clinicName: '',
    patientName: '',
    dateOfBirth: '',
    healthcard: '',
  });

  const {
    setCoverLetterURL,
    previewPdfDocument,
    coverLetterURL,
    createFaxWithCoverLetterDefault,
  } = useNewFaxPDFActions();
  const { CoverLetterLayout } = useNewFaxPDFRender(fieldValues);
  const { sendFaxDefaultDocument } = useFaxApi();

  const createCoverLetterAndSendFax = async (document: Document, faxNumber: string) => {
    try {
      const coverLetterS3Details = await createFaxWithCoverLetterDefault(
        document,
        faxNumber,
        fieldValues.recipientName
      );
      await sendFaxDefaultDocument(document, coverLetterS3Details.faxId, faxNumber);
    } catch (err) {
      const message = handleAPIErrors(err);
      setErrorMessage(message);
      setOpenAlertDialog(true);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: patient?.patientInfo?.familyPhysicianEmail,
      fax: patient?.patientInfo?.familyPhysicianFax,
      sendType: sendType,
    },
    validate: SendDocumentDialogValidator,
    onSubmit: async values => {
      setloading(true);
      try {
        if (sendType == SEND_TYPES.EMAIL) {
          sendGenericDefaultDocument(document, values.email, patient?.id);
        } else if (sendType == SEND_TYPES.FAX && document) {
          createCoverLetterAndSendFax(document, fieldValues.faxNumber);
        }
        toggle(false);
      } catch (err) {
        const message = handleAPIErrors(err);
        setErrorMessage(message);
        setOpenAlertDialog(true);
      }
      setloading(false);
    },
  });

  const resetState = () => {
    setSendType(SEND_TYPES.EMAIL);
    formik.resetForm();
  };

  const handlePreview = (): void => {
    previewPdfDocument();
  };

  useEffect(() => {
    formik.setFieldValue('sendType', sendType);
  }, [sendType]);

  useEffect(() => {
    if (open) {
      setFieldValues({
        ...fieldValues,
        faxNumber: formik.values.fax ? formik.values.fax : '',
        patientName: patient?.fullName ? patient.fullName : '',
      });
    } else {
      resetState();
      setFieldValues({
        title: '',
        faxNumber: '',
        note: '',
        recipientName: '',
        clinicName: '',
        patientName: '',
        dateOfBirth: '',
        healthcard: '',
      });
    }
  }, [open]);

  const sendDocumentToPatient = async () => {
    try {
      if (!document) return;
      await sendGenericDefaultDocument(document, undefined, patient?.id);
      toggle(false);
    } catch (err) {
      const message = handleAPIErrors(err);
      setErrorMessage(message);
      setOpenAlertDialog(true);
    }
  };

  function sendToFamilyDoctor(): void {
    if (patient?.patientInfo?.familyPhysicianFax && document) {
      createCoverLetterAndSendFax(document, patient?.patientInfo?.familyPhysicianFax);
      toggle(false);
    }
  }

  return (
    <Dialog open={open} title="Send Default Document" onClose={onClose}>
      <div className={styles.padding}>
        <div className={styles.details}>
          <div className={styles.info}>
            <InfoRow label={'Patient'} value={patient?.fullName} />
          </div>
          <div className={styles.info}>
            <InfoRow label={'Default Document'} value={document?.title} />
          </div>
        </div>

        <Grid container className={styles.options}>
          <Grid item sm={12} xs={12}>
            <div className={styles.padding}>
              <Select
                fullWidth
                label="Communication Type"
                name={'communicationType'}
                renderValue={value => <div className={styles.selectItem}>{value}</div>}
                onChange={event => setSendType(event.target.value)}
                value={sendType}
              >
                {SEND_TYPE_LIST.map(item => (
                  <MenuItem key={item} value={item} className={styles.selectItem}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </Grid>
          <Grid item sm={12} xs={12}>
            <div className={styles.padding}>
              {sendType == SEND_TYPES.FAX && (
                <TextField
                  fullWidth
                  name={'receiptient'}
                  label="Receipent Name"
                  placeholder="Enter Receipient Name"
                  onChange={event =>
                    setFieldValues({ ...fieldValues, recipientName: event.target.value })
                  }
                  value={fieldValues.recipientName}
                  type="text"
                />
              )}
            </div>
          </Grid>
          <Grid item sm={12} xs={12}>
            <div className={styles.padding}>
              {sendType == SEND_TYPES.FAX && (
                <TextField
                  fullWidth
                  name={'clinicName'}
                  label="Clinic Name"
                  placeholder="Enter Clinic Name"
                  onChange={event =>
                    setFieldValues({ ...fieldValues, clinicName: event.target.value })
                  }
                  value={fieldValues.clinicName}
                  type="text"
                />
              )}
            </div>
          </Grid>

          <Grid item sm={12} xs={12}>
            <div className={styles.padding}>
              {sendType == SEND_TYPES.EMAIL ? (
                <TextField
                  error={
                    formik.values.email && formik.values.email.length > 0 && !!formik.errors.email
                      ? true
                      : false
                  }
                  fullWidth
                  name={'email'}
                  label="Email"
                  placeholder="Enter destination email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  helperText={
                    formik.values.email && formik.values.email.length > 0 && !!formik.errors.email
                      ? formik.errors?.email
                      : ''
                  }
                  type="email"
                />
              ) : (
                <TextField
                  error={
                    !!(formik.values.fax && formik.values.fax.length > 0 && !!formik.errors.fax)
                  }
                  fullWidth
                  name={'fax'}
                  label="Fax Number"
                  placeholder="Enter fax number"
                  onChange={event => {
                    formik.handleChange(event);
                    setFieldValues({ ...fieldValues, faxNumber: event.target.value });
                  }}
                  value={formik.values.fax}
                  helperText={
                    formik.values.fax && formik.values.fax.length > 0 && !!formik.errors.fax
                      ? formik.errors?.fax
                      : ''
                  }
                  type="fax"
                />
              )}
            </div>
          </Grid>
        </Grid>
        <div className={classNames(styles.padding, styles.buttons)}>
          <DialogButton onClick={onClose}>Cancel</DialogButton>
          <DialogButton
            disabled={
              sendType == SEND_TYPES.EMAIL ? !formik.values.email : !formik.values.fax || loading
            }
            className={styles.marginLeft}
            onClick={formik.handleSubmit}
            loading={loading}
          >
            Send
          </DialogButton>
          {sendType === SEND_TYPES.FAX && patient?.patientInfo?.familyPhysicianFax && (
            <DialogButton
              loading={loading}
              disabled={loading}
              className={styles.marginLeft}
              onClick={sendToFamilyDoctor}
            >
              Send To Family Doctor
            </DialogButton>
          )}
          {sendType == SEND_TYPES.EMAIL ? (
            <DialogButton
              loading={loading}
              disabled={loading}
              className={styles.marginLeft}
              onClick={sendDocumentToPatient}
            >
              Send To Patient
            </DialogButton>
          ) : (
            <DialogButton disabled={loading} className={styles.marginLeft} onClick={handlePreview}>
              Preview Cover Letter
            </DialogButton>
          )}
        </div>
      </div>
      <PDFDownloadLink document={CoverLetterLayout()}>
        {({ blob, url, loading, error }) => {
          if (url) {
            setCoverLetterURL(url);
          }
          return <div></div>;
        }}
      </PDFDownloadLink>
      <AlertDialog
        open={openAlertDialog}
        message={errorMessage}
        title={'Error Sending Documents'}
        onClose={() => setOpenAlertDialog(false)}
      />
    </Dialog>
  );
}

export default SendDefaultDocumentDialog;
