import React, { ReactElement, memo, useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { CircularProgress } from '@material-ui/core';
import Dialog from 'components/Dialog';
import DialogButton from 'components/DialogButton';
import Autocomplete from 'components/Autocomplete';
import { UserApi } from 'api';

import { SendScheduleValidationError } from 'types';

import { Staff } from 'api/user/user.interfaces';
import styles from './SendScheduleDialog.module.scss';

interface SendScheduleDialogProps {
  open?: boolean;
  staffList: Array<Staff>;
  onClose: () => void;
  onSaveSuccess: () => void;
}

function SendScheduleDialog(props: SendScheduleDialogProps): ReactElement {
  const [validationErrors, setValidationErrors] = useState<Array<SendScheduleValidationError>>([]);
  const [staff, setStaff] = useState<Staff>();
  const [loading, setLoading] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);

  const validateForm = (): boolean => {
    let validated = true;
    const validationErrors: Array<SendScheduleValidationError> = [];
    if (!staff) {
      const validationError = {
        field: 'staff',
        message: 'Please select a staff member',
      };
      validationErrors.push(validationError);
      validated = false;
    }
    setValidationErrors(validationErrors);
    return validated;
  };

  const resetForm = (): void => {
    setValidationErrors([]);
    setLoading(false);
    setStaff(undefined);
    setShowError(false);
  };

  const sendStaffSchedules = async (): Promise<void> => {
    validateForm();
    setLoading(true);
    try {
      if (staff) {
        await UserApi.sendSchedule(staff.id);
      }
      setShowError(false);
    } catch (e) {
      setShowError(true);
    }
    setLoading(false);
    props.onClose();
    resetForm();
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} title={'Send Schedules'}>
      <form className={styles.container}>
        <Grid container className={styles.gridContainer}>
          <Grid item xs={12} sm={12}>
            <div className={styles.topPadding}>
              <Autocomplete
                label="Staff"
                name="Staff"
                items={props.staffList}
                value={staff}
                validationErrors={validationErrors}
                vaidationErrorKey="Staff"
                getOptionLabel={(type): string => `${type.name}`}
                onChange={(event, newValue): void => setStaff(newValue)}
                fullWidth
              />
            </div>
            {showError ? (
              <div className={styles.topPadding}>
                <div className={styles.textError}>Schedule could not be sent</div>
              </div>
            ) : null}
            <div className={styles.buttons}>
              <DialogButton onClick={props.onClose}>Cancel</DialogButton>
              {!loading ? (
                <DialogButton
                  className={styles.marginLeft}
                  loading={false}
                  onClick={sendStaffSchedules}
                >
                  Send
                </DialogButton>
              ) : (
                <CircularProgress
                  className={styles.circularProgress}
                  size={48}
                  style={{ padding: '10px' }}
                />
              )}
            </div>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
}

export default memo(SendScheduleDialog);
