import React, { ReactElement } from 'react';
import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';

import styles from './QuestionSectionStyles.module.scss';

interface OpenQuestionSectionProps {
  title?: string;
  value: Array<any>;
  defaultValue?: Array<any>;
  description?: string;
  required?: boolean;
  onChange?(value): void;
}

function OpenQuestionSection({
  title,
  value = [],
  defaultValue = [],
  description = 'Answer here',
  required,
  onChange = () => null,
}: OpenQuestionSectionProps): ReactElement {
  function handleChange(event): void {
    onChange([
      {
        ...(defaultValue[0]?.id && { id: defaultValue[0]?.id }),
        value: event.target.value,
      },
    ]);
  }

  return (
    <div className={styles.main}>
      <div className={styles.title}>
        {title} {required ? <span className={styles.reqMarker}>*</span> : null}
      </div>
      <div className={classNames(styles.title, styles.statement)}>{description}</div>
      <div className={styles.answerContainer}>
        <div className={classNames(styles.answer, styles.open)}>
          <TextField
            defaultValue={defaultValue[0]?.value_text || defaultValue[0]?.value || ''}
            value={value[0]?.value}
            onChange={handleChange}
            placeholder={description ? description : 'Answer here'}
            fullWidth
            multiline={true}
          />
        </div>
      </div>
    </div>
  );
}

export default OpenQuestionSection;
