import React, { ReactElement } from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

interface SuccessSnackBarProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  message: string;
}

function SuccessSnackBar({ open, message, setOpen }: SuccessSnackBarProps): ReactElement {
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') return;
    setOpen(false);
  };
  return (
    <Snackbar
      open={open}
      autoHideDuration={4000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    >
      <Alert onClose={handleClose} severity="success">
        {message}
      </Alert>
    </Snackbar>
  );
}

export default SuccessSnackBar;
