import React, { ReactElement, useState, useContext } from 'react';

import { Note as NoteApi } from 'types';

import ConditionDiv from 'components/ConditionDiv';
import TitledSection from 'components/TitledSection';
import Button from 'components/Button';

import Note from './components/Note';
import SendNoteDialog from './components/SendNoteDialog/SendNoteDialog.view';
import DeleteNoteDialog from './components/DeleteNodeDialog';

import formatDate, { DateTimeFormat } from 'lib/dateFormatter';
import styles from './NotesStyles.module.scss';
import moment from 'moment';

import { Context as ProfileContext } from 'context/profile';

interface NotesProps {
  loading: boolean;
  notes: Array<NoteApi>;
  onCreateNote: () => void;
  onSendNoteClick: (noteId, staffSendId) => void;
  onDeleteNoteClick: (noteId) => void;
  onUpdateNote: (note) => void;
}

function Notes({
  onCreateNote,
  onSendNoteClick,
  loading,
  notes,
  onDeleteNoteClick,
  onUpdateNote,
}: NotesProps): ReactElement {
  const [openModal, setOpenModal] = useState(-1);
  const [modalDeleteNoteOpen, setModalDeleteNoteOpen] = useState<boolean>(false);
  const [noteSelected, setNoteSelected] = useState<NoteApi | null>(null);
  const [staffSendId, setStaffSendId] = useState([]);
  const { user, setUser } = useContext(ProfileContext);
  const headContent = (
    <div className={styles.button}>
      <Button onClick={onCreateNote}>Create Note</Button>
    </div>
  );

  const openModalDeleteNote = (note: NoteApi) => {
    setNoteSelected(note);
    setModalDeleteNoteOpen(true);
  };

  const onConfirmDeleteNote = async () => {
    await onDeleteNoteClick(noteSelected?.id);
    setModalDeleteNoteOpen(false);
  };

  return (
    <>
      <TitledSection title="Notes" headContent={headContent}>
        <div className={styles.notes}>
          <div className={styles.paper}>
            <ConditionDiv condition={!!notes.length}>
              {notes.map((item: NoteApi) => {
                return (
                  <Note
                    key={`note-${item.id}`}
                    userLogged={user}
                    title={item.title}
                    createdAt={formatDate(DateTimeFormat.MonthDayYear, moment(item.createdAt))}
                    createdBy={item.createdBy?.fullName}
                    updatedAt={
                      item.updatedAt
                        ? formatDate(DateTimeFormat.MonthDayYear, moment(item.updatedAt))
                        : ''
                    }
                    updatedBy={item.updatedBy?.fullName}
                    description={item.contents}
                    onDeleteNoteClick={() => openModalDeleteNote(item)}
                    onSendNoteClick={() => setOpenModal(parseInt(item.id + ''))}
                    onEditNoteClick={() => onUpdateNote(item)}
                    className={styles.note}
                  />
                );
              })}
            </ConditionDiv>
            <ConditionDiv condition={!notes.length}>Currently no notes</ConditionDiv>
          </div>
        </div>
      </TitledSection>
      <SendNoteDialog
        open={openModal > -1}
        name="staffSendId"
        value={staffSendId}
        loading={loading}
        onChange={value => setStaffSendId(value)}
        onSave={async () => {
          await onSendNoteClick(openModal, staffSendId);
          setOpenModal(-1);
        }}
        onClose={() => setOpenModal(-1)}
      />
      <DeleteNoteDialog
        open={modalDeleteNoteOpen}
        note={noteSelected}
        loading={loading}
        onConfirm={onConfirmDeleteNote}
        onClose={() => setModalDeleteNoteOpen(false)}
      />
    </>
  );
}

export default Notes;
