import React from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';

interface ValidationErrorProps {
  field: string;
  validationErrors: Array<{ field: string; message: string }>;
}

export const ValidationError = ({ field, validationErrors }: ValidationErrorProps) => {
  const validationError = validationErrors.filter(item => item.field === field);

  if (validationError && validationError.length > 0) {
    return <div className={classNames(styles.validationError)}>{validationError[0].message}</div>;
  }

  return null;
};
