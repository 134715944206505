import React, { ReactElement, useEffect, useState } from 'react';
import MuiAutocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { ADD_NEW_PHYSICIAN } from 'lib/user';

interface OtherPhysicianAutoCompleteProps {
  options?: any;
  handleChange?: (event, value) => void;
  value?: any;
  disabled: boolean;
  searchQuery: string;
  setSearchQuery: (searchQuery: string) => void;
  loading?: boolean;
  helperText?: string;
}

function OtherPhysicianAutoComplete({
  options,
  handleChange,
  value,
  loading = false,
  disabled = false,
  searchQuery,
  setSearchQuery,
  helperText,
}: OtherPhysicianAutoCompleteProps): ReactElement {
  useEffect(() => {
    const inputState = value ? value.fullName : '';
    setSearchQuery(inputState);
  }, [value]);
  return (
    <MuiAutocomplete
      value={value}
      inputValue={searchQuery}
      disabled={disabled}
      onInputChange={(event, newInputValue) => {
        if (newInputValue != ADD_NEW_PHYSICIAN) setSearchQuery(newInputValue);
      }}
      options={options}
      placeholder={'Select physician'}
      filterOptions={(options, { inputValue }) => {
        const result = options;
        result.unshift({ fullName: ADD_NEW_PHYSICIAN });
        return result;
      }}
      getOptionLabel={option => option?.fullName || option?.key}
      groupBy={option => option?.lastNameLetter}
      onChange={handleChange}
      loading={loading}
      renderInput={params => (
        <TextField
          {...params}
          error={!!helperText}
          helperText={helperText}
          label="Select a Physician"
        />
      )}
      fullWidth
    />
  );
}

export default OtherPhysicianAutoComplete;
