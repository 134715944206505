import React, { Fragment, ReactElement, useEffect, useState } from 'react';
import Collapse from '@material-ui/core/Collapse';

import TitledSection from 'components/TitledSection';
import { formatDate, DateTimeFormat } from 'lib/dateFormatter';

import { DocumentsApiHookInterface } from '../../useDocumentsApi.hook';

import AddDocumentDialog from './components/AddDocumentDialog';
import DocumentElement from './components/Document';

import styles from './DocumentsStyles.module.scss';
import { Document, NewDefaultDocument, NewDocument, User } from 'types';
import { useFormik } from 'formik';
import AddNewDocumentDialog from './components/AddNewDocumentDialog';
import SendDefaultDocumentDialog from './components/SendDefaultDocumentDialog';
import Button from 'components/Button';
import CVCButton from 'components/CVCButton';
import IFrame from 'components/IFrame';
import PagePopup from 'pages/Patient/components/PagePopup';
import { MIME_TYPE_PDF } from 'lib/document';
import ActionConfirmationDialog from 'components/ActionConfirmationDialog';
import { AlertDialog } from 'components/AlertDialog';
import { handleAPIErrors } from 'lib/handleAPIErrors';
import { fetchUser } from 'actions/userActions';
import { ROLE_ID } from 'lib/user';
import SuccessSnackBar from 'components/SuccessSnackBar';
import { UserApi } from 'api';

interface DocumentsProps {
  patient: User | null;
  history: any;
  documentsApiHook: DocumentsApiHookInterface;
}

function Documents({ patient, history, documentsApiHook }: DocumentsProps): ReactElement {
  const [openTabKey, setOpenTabKey] = useState<string>('');
  const [documentAdd, setDocumentAdd] = useState<Document>();
  const [selectedUrl, setSelectedUrl] = useState<string>('');
  const [openPreview, setOpenPreview] = useState<boolean>(false);
  const [createNewDocument, setCreateNewDocument] = useState<boolean>(false);
  const [documentToDelete, setDocumentToDelete] = useState<Document>();
  const [genericDefaultDocument, setGenericDefaultDocument] = useState<Document>();
  const [openAlertDialog, setOpenAlertDialog] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [alertTitle, setAlertTitle] = useState<string>('');
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const [snackBarMessage, setSnackBarMessage] = useState<string>('');
  const [user, setUser] = useState<User>();
  const [openSendDefaultDocument, setOpenSendDefaultDocument] = useState<boolean>(false);

  const {
    loading,
    defaultDocuments,
    documentTypes,
    copyDefaultDocument,
    createDefaultDocument,
    downloadDefaultDocument,
    deleteDefaultDocument,
    sendGenericDefaultDocument,
  } = documentsApiHook;

  async function getUser(): Promise<void> {
    const response = await fetchUser();
    setUser(response);
  }

  const onSaveDocument = async newDocumentName => {
    if (documentAdd) {
      await copyDefaultDocument(newDocumentName, documentAdd.id);
      setDocumentAdd(undefined);
    }
  };
  const formik = useFormik({
    initialValues: {
      name: '',
    },
    onSubmit: async values => {
      onSaveDocument(values.name);
    },
  });

  const previewDocument = async (document: Document) => {
    setOpenPreview(true);
    const result = await downloadDefaultDocument(document.id);
    setSelectedUrl(result);
  };

  const collapsibleTabClicked = tabKey => {
    if (tabKey === openTabKey) {
      return setOpenTabKey('');
    }
    setOpenTabKey(tabKey);
  };

  const setSnackBar = (message: string) => {
    setSnackBarMessage(message);
    setOpenSnackBar(true);
  };

  const deleteDocument = async () => {
    try {
      if (!documentToDelete) return;
      await deleteDefaultDocument(documentToDelete.id);
      setSnackBar(`Default Document ${documentToDelete.title} Deleted`);
      setDocumentToDelete(undefined);
    } catch (err) {
      setOpenAlertDialog(true);
      setAlertMessage(handleAPIErrors(err));
      setAlertTitle('Error Deleting Document');
    }
  };

  const onCreateNewDocument = async (data: NewDefaultDocument) => {
    try {
      await createDefaultDocument(data);
      setSnackBar(`Default Document ${data.title} Created`);
      setCreateNewDocument(false);
    } catch (err) {
      setAlertMessage(handleAPIErrors(err));
      setAlertTitle('Error Creating Document');
      setOpenAlertDialog(true);
    }
  };

  const onSendGenericDocument = async (data: Document) => {
    setGenericDefaultDocument(data);
    setOpenSendDefaultDocument(true);
  };

  const fullAccess = (): boolean => {
    return user?.typeId === ROLE_ID.ADMIN || user?.typeId === ROLE_ID.SUPER_ADMIN;
  };

  const listing = () => {
    const out: Array<JSX.Element> = [];
    defaultDocuments.forEach((value, key) =>
      out.push(
        <div key={key}>
          <div className={styles.collapsible} onClick={() => collapsibleTabClicked(key)}>
            <h1 className={openTabKey === key ? styles.selected : ''}>{key}</h1>
          </div>
          <Collapse in={openTabKey === key}>
            {value.map(item => {
              return (
                <DocumentElement
                  key={`doc-${item.id}`}
                  className={styles.document}
                  title={item.title}
                  needsStamp={item.needsStamp}
                  addDocument={() => setDocumentAdd(item)}
                  deleteDocument={() => setDocumentToDelete(item)}
                  previewDocument={() => previewDocument(item)}
                  allowDelete={fullAccess()}
                  sendGenericDefaultDocument={() => onSendGenericDocument(item)}
                />
              );
            })}
          </Collapse>
        </div>
      )
    );
    return out;
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <Fragment>
      <TitledSection title="Default Documents">{listing()}</TitledSection>
      {fullAccess() && (
        <div className={styles.newDocument}>
          <Button onClick={() => setCreateNewDocument(true)}>New Default Document</Button>
        </div>
      )}

      <AddDocumentDialog
        open={!!documentAdd}
        onClose={() => setDocumentAdd(undefined)}
        loading={loading}
        defaultDocumentName={(documentAdd && documentAdd.title) || ''}
        formik={formik}
        onSave={onSaveDocument}
      />
      <AddNewDocumentDialog
        open={createNewDocument}
        onClose={() => setCreateNewDocument(false)}
        onSave={onCreateNewDocument}
        categories={documentTypes}
        loading={loading}
      />
      <SendDefaultDocumentDialog
        open={openSendDefaultDocument}
        onClose={() => setOpenSendDefaultDocument(false)}
        document={genericDefaultDocument}
        patient={patient}
        documentsApiHook={documentsApiHook}
        toggle={setOpenSendDefaultDocument}
      />
      <PagePopup
        open={openPreview}
        url={selectedUrl}
        type={MIME_TYPE_PDF}
        onClose={() => setOpenPreview(false)}
      />
      <AlertDialog
        open={openAlertDialog}
        title={alertTitle}
        message={alertMessage}
        onClose={() => setOpenAlertDialog(false)}
      />
      <ActionConfirmationDialog
        open={!!documentToDelete}
        title={'Delete Default Document'}
        description={`Are you sure you would like to delete ${documentToDelete?.title}.  This cannot be undone`}
        onClose={() => setDocumentToDelete(undefined)}
        onConfirm={deleteDocument}
      />
      <SuccessSnackBar open={openSnackBar} message={snackBarMessage} setOpen={setOpenSnackBar} />
    </Fragment>
  );
}

export default Documents;
