import React, { ReactElement, useEffect, useRef } from 'react';

import { CompositeImageApi } from 'api';

interface CompositeImageProps {
  folder?: string | null;
  keys?: Array<string>;
  width?: number;
  height?: number;
}

function CompositeImage({
  folder = null,
  keys = [],
  width = window.innerWidth,
  height = window.innerHeight,
}: CompositeImageProps): ReactElement {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  async function getImage(): Promise<void> {
    // Get S3 Bucket URLs
    const imageUrls = await CompositeImageApi.getImages(keys, folder);

    // Apply Images to Canvas
    const canvasObj = canvasRef.current;

    if (canvasObj) {
      const ctx = canvasObj.getContext('2d');
      imageUrls.forEach(url => {
        const image = new Image();

        image.crossOrigin = 'anonymous';

        image.onload = function() {
          ctx?.drawImage(image, 0, 0, width, width);
        };
        image.src = url as string;
      });
    }
  }

  useEffect(() => {
    getImage();
  }, []);

  return (
    <>
      <canvas ref={canvasRef} width={width} height={height} />
    </>
  );
}

export default CompositeImage;
