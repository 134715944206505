import { CaseResult, CaseResultAnswer } from 'types';
import CaseInsensitiveMap from 'lib/caseInsensitiveMap';

interface TechResults {
  ASV: Array<string>;
  GSV: Array<string>;
  GSVAbove: Array<string>;
  GSVBelow: Array<string>;
  GSVThrombosed: Array<string>;
  GSVStripped: Array<string>;
  GSVAblated: Array<string>;
  GSVReflux: Array<string>;
  GSVRefluxMaxDiameter: Array<string>;
  PSV: Array<string>;
  SPJ: Array<string>;
  SFJ: Array<string>;
  SSV: Array<string>;
  SSVUpper: Array<string>;
  SSVLower: Array<string>;
  SSVRefluxMaxDiameter: Array<string>;
  VV: Array<string>;
  SuperficialVeinThrombosis: Array<string>;
  Perforators: Array<string>;
  GM: Array<string>;
  comments: Array<string>;
  images: Array<string>;
}

export const exampleTechicianResultProps = {
  rightLeg: {
    aboveKneeGSVResult: ['normal'],
    belowKneeGSVResult: ['normal'],
    sFJResult: ['thrombosed'],
    sPJResult: ['normal'],
    aSVResult: ['thrombosed'],
    pSVResult: ['reflux'],
    upperSSVResult: ['ablated', 'reflux'],
    lowerSSVResult: ['reflux'],
    giacominiResult: ['AK Posterior Lateral', 'BK Posterior Lateral', 'BK Posterior Medial'],
    perforatorsResult: ['AK Posterior Lateral', 'BK Posterior Lateral', 'BK Posterior Medial'],
    superficialVeinThrombosisResult: ['AK Posterior Lateral', 'BK Posterior Medial'],
    images: [],
  },
  leftLeg: {
    aboveKneeGSVResult: ['Thrombosed', 'Stripped'],
    belowKneeGSVResult: ['Thrombosed', 'Ablated', 'Stripped'],
    sFJResult: ['reflux'],
    sPJResult: ['reflux'],
    aSVResult: [],
    pSVResult: ['thrombosed', 'reflux'],
    upperSSVResult: ['reflux'],
    lowerSSVResult: [],
    giacominiResult: ['giacomini'],
    perforatorsResult: ['AK Anterior Lateral'],
    superficialVeinThrombosisResult: ['AK Anterior Lateral'],
    images: [],
  },
};

const forceArr = obj => {
  if (!obj) {
    return [];
  }
  return Array.isArray(obj) ? obj : [obj];
};

/* Based on which of the keys incoming data has a piece of data is pused into one of two arrays */
const placeInArrayOnKey = (data, keys = ['0', '1'], insert, arr1, arr2) => {
  const checkArr = Array.isArray(data) ? data : [data];
  checkArr.forEach((value = '') => {
    const checkVal = value.toLowerCase();
    if (checkVal.includes(keys[0])) {
      arr1.push(insert);
    } else if (checkVal.includes(keys[1])) {
      arr2.push(insert);
    }
  });
};

const placeAboveBelow = (data, namePlace, aboveArr, belowArr) => {
  placeInArrayOnKey(data, ['above', 'below'], namePlace, aboveArr, belowArr);
};

const placeUpperLower = (data, namePlace, aboveArr, belowArr) => {
  placeInArrayOnKey(data, ['upper', 'lower'], namePlace, aboveArr, belowArr);
};

const calcResults = (resultMap, keyPrefix): TechResults => {
  // Compile GSV
  const aboveKneeGSV: Array<string> = [];
  const belowKneeGSV: Array<string> = [];
  placeAboveBelow(
    resultMap.get(`Tech::${keyPrefix}GSVStripped`),
    'Stripped',
    aboveKneeGSV,
    belowKneeGSV
  );
  placeAboveBelow(
    resultMap.get(`Tech::${keyPrefix}GSVAblated`),
    'Ablated',
    aboveKneeGSV,
    belowKneeGSV
  );
  placeAboveBelow(
    resultMap.get(`Tech::${keyPrefix}GSVReflux`),
    'Reflux',
    aboveKneeGSV,
    belowKneeGSV
  );

  // Compile SSV
  const aboveKneeSSV: Array<string> = [];
  const belowKneeSSV: Array<string> = [];
  placeUpperLower(
    resultMap.get(`Tech::${keyPrefix}SSVThrombosed`),
    'Thrombosed',
    aboveKneeSSV,
    belowKneeSSV
  );
  placeUpperLower(
    resultMap.get(`Tech::${keyPrefix}SSVStripped`),
    'Stripped',
    aboveKneeSSV,
    belowKneeSSV
  );
  placeUpperLower(
    resultMap.get(`Tech::${keyPrefix}SSVAblated`),
    'Ablated',
    aboveKneeSSV,
    belowKneeSSV
  );
  placeUpperLower(
    resultMap.get(`Tech::${keyPrefix}SSVReflux`),
    'Reflux',
    aboveKneeSSV,
    belowKneeSSV
  );

  // Compile ASV
  const resultsASV: Array<string> = forceArr(resultMap.get(`Tech::${keyPrefix}ASV`));
  const refluxLengthASV = resultMap.get(`Tech::${keyPrefix}ASVRefluxLength`);
  const refluxDiameterASV = resultMap.get(`Tech::${keyPrefix}ASVRefluxMaxDiameter`);

  const indexOfRefluxASV = resultsASV.findIndex(result => result === 'reflux');
  if (indexOfRefluxASV >= 0) {
    resultsASV.splice(indexOfRefluxASV, 1);
    resultsASV.push(`reflux ${refluxLengthASV ? `with length of ${refluxLengthASV}` : ''}`);
    if (refluxDiameterASV) {
      resultsASV.push(`ASV max diameter is ${refluxDiameterASV}`);
    }
  }

  // Compile PSV
  const resultsPSV: Array<string> = forceArr(resultMap.get(`Tech::${keyPrefix}PSV`));
  const refluxLengthPSV = resultMap.get(`Tech::${keyPrefix}PSVRefluxLength`);
  const refluxDiameterPSV = resultMap.get(`Tech::${keyPrefix}PSVRefluxMaxDiameter`);

  const indexOfRefluxPSV = resultsPSV.findIndex(result => result === 'reflux');
  if (indexOfRefluxPSV >= 0) {
    resultsPSV.splice(indexOfRefluxPSV, 1);
    resultsPSV.push(`reflux ${refluxLengthPSV ? `with length of ${refluxLengthPSV}` : ''}`);
    if (refluxDiameterPSV) {
      resultsPSV.push(`PSV max diameter is ${refluxDiameterPSV}`);
    }
  }

  return {
    GSV: forceArr(resultMap.get(`Tech::${keyPrefix}GSV`)),
    GSVAbove: forceArr(aboveKneeGSV),
    GSVBelow: forceArr(belowKneeGSV),
    GSVRefluxMaxDiameter: forceArr(resultMap.get(`Tech::${keyPrefix}GSVRefluxMaxDiameter`)),
    GSVThrombosed: forceArr(resultMap.get(`Tech::${keyPrefix}GSVthrombosed`)),
    GSVStripped: forceArr(resultMap.get(`Tech::${keyPrefix}GSVstripped`)),
    GSVAblated: forceArr(resultMap.get(`Tech::${keyPrefix}GSVablated`)),
    GSVReflux: forceArr(resultMap.get(`Tech::${keyPrefix}GSVreflux`)),
    SSV: forceArr(resultMap.get(`Tech::${keyPrefix}SSV`)),
    SSVUpper: forceArr(aboveKneeSSV),
    SSVLower: forceArr(belowKneeSSV),
    SSVRefluxMaxDiameter: forceArr(resultMap.get(`Tech::${keyPrefix}SSVRefluxMaxDiameter`)),
    ASV: forceArr(resultsASV),
    PSV: forceArr(resultsPSV),
    SPJ: forceArr(resultMap.get(`Tech::${keyPrefix}SPJ`)),
    SFJ: forceArr(resultMap.get(`Tech::${keyPrefix}SFJ`)),
    VV: forceArr(resultMap.get(`Tech::${keyPrefix}VisibleVaricosities`)),
    SuperficialVeinThrombosis: forceArr(
      resultMap.get(`Tech::${keyPrefix}SuperficialVeinThrombosis`)
    ),
    Perforators: forceArr(resultMap.get(`Tech::${keyPrefix}Perforators`)),
    GM: forceArr(resultMap.get(`Tech::${keyPrefix}GM`)),
    comments: forceArr(resultMap.get(`Tech::${keyPrefix}Comments`)),
    images: forceArr(resultMap.get(`Tech::${keyPrefix}-IMAGE`)),
  };
};

export const getTechnicianResults = answers => {
  const resultMap = new CaseInsensitiveMap();

  if (answers) {
    answers.forEach(m => {
      resultMap.set(m.id, m.value ? m.value : '');
    });
  }

  return {
    leftLeg: calcResults(resultMap, 'LeftLeg'),
    rightLeg: calcResults(resultMap, 'RightLeg'),
    technicianComplete: true,
  };
};
