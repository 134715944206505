import React, { ReactElement, memo } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import InboundEnhancedTableHead from '../InboundEnhancedTableHead';
import { useStyles, StyledTableCell, StyledTableRow } from 'lib/table';
import styles from './FaxReceived.module.scss';
import formatPhoneInput from 'lib/phoneNumFormatter';
import { NavLink } from 'react-router-dom';
import { FaxInbound } from 'types';
import { DateTimeFormat, formatDate } from 'lib/dateFormatter';
import classNames from 'classnames';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Close';
import moment from 'moment';

interface FaxReceived {
  id: number;
  name: string;
  sender: number;
  time: string;
}

interface FaxReceivedProps {
  faxes: Array<FaxInbound>;
  onClickFax: (id) => void;
}

function FaxReceived({ faxes, onClickFax }: FaxReceivedProps): ReactElement {
  const classes = useStyles();

  const getStatus = fax => {
    if (fax.duplicate) return 'Duplicate';
    if (fax.patientUserId)
      return (
        <NavLink
          onClick={event => event.stopPropagation()}
          to={{
            pathname: `patient/${fax.patientUserId}/documents`,
          }}
          title="Go to patient details"
        >
          {fax.patientFullName}
        </NavLink>
      );
  };
  return (
    <div className={styles.container}>
      <TableContainer className={styles.tableContainer}>
        <Table className={classes.table} aria-label="customized table">
          <InboundEnhancedTableHead
            classes={classes}
            numSelected={0}
            onSelectAllClick={(): boolean => true}
            rowCount={3}
          />
          <TableBody>
            {faxes.map((row, index) => {
              const labelId = `enhanced-table-checkbox-${index}`;
              const momentObj = moment.utc(row.receiveTime);
              const userOffset = moment().utcOffset();
              const localDateTime = momentObj.utcOffset(userOffset);
              return (
                <StyledTableRow
                  hover
                  role="checkbox"
                  aria-checked={false}
                  tabIndex={-1}
                  key={row.id}
                  selected={false}
                  onClick={() => {
                    return row.includesPdf ? onClickFax(row.id) : undefined;
                  }}
                >
                  <StyledTableCell id={labelId}>{row.id}</StyledTableCell>
                  <StyledTableCell>{getStatus(row)}</StyledTableCell>
                  <StyledTableCell>{formatPhoneInput(row.callerId)}</StyledTableCell>
                  <StyledTableCell>
                    {formatDate(DateTimeFormat.DateAndTimeWithoutMeridies12h, localDateTime)}
                  </StyledTableCell>
                  <StyledTableCell>
                    {row.includesPdf ? (
                      <CheckIcon className={styles.pdfPresent} />
                    ) : (
                      <ErrorIcon className={styles.pdfFailed} />
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default memo(FaxReceived);
