import React, { useEffect } from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image as PDFImage,
  Font,
} from '@react-pdf/renderer';
import FullLogoTagline from 'assets/images/full-logo-tagline.png';

import usePrescriptionResultPDFFragment from './components/usePrescriptionResultPDFFragment';
import formatPhoneInput from 'lib/phoneNumFormatter';
import formatDate, { DateTimeFormat } from 'lib/dateFormatter';

interface PrescriptionPdfProps {
  caseId?: number;
  formId?: number;
  signatureUrl?: string;
  medical: boolean;
}

function PrescriptionResultPDFFragment({
  caseId,
  formId,
  medical,
  signatureUrl,
}: PrescriptionPdfProps) {
  const { formResults } = usePrescriptionResultPDFFragment({
    formId,
    caseId,
    medical,
  });

  Font.register({
    family: 'Lora',
    fonts: [{ src: 'https://fonts.gstatic.com/s/lora/v17/0QI6MX1D_JOuGQbT0gvTJPa787z5vCJG.ttf' }],
  });
  const styles = StyleSheet.create({
    page: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'white',
      height: '100%',
    },

    // CVC image and Form title
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    headerImageContainer: {
      width: '100%',
      padding: 30,
    },
    signatureImageContainer: {
      width: '30%',
      maxHeight: '150px',
      paddingTop: 10,
      paddingBottom: 20,
      paddingLeft: 50,
    },
    headerText: {
      width: '100%',
      padding: 30,
      textAlign: 'center',
      justifyContent: 'center',
    },
    headerTextBig: {
      fontSize: 19,
      fontFamily: 'Helvetica',
      fontWeight: 200,
    },
    headerTextSmall: {
      fontSize: 7.75,
      marginTop: 1,
    },

    // Patient and Date
    formBaseDetails: {
      display: 'flex',
      flexDirection: 'row',
      padding: '20px 50px 20px 50px',
    },
    column: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    patientInformation: {
      paddingTop: 1,
      display: 'flex',
      flexDirection: 'row',
    },
    dateInfo: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      marginVertical: 'auto',
    },
    formDate: {
      paddingBottom: 10,
      width: '100%',
      marginRight: 'auto',
    },
    patientDetails: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    doctorInfo: {
      paddingTop: 20,
      paddingBottom: 20,
      paddingLeft: 50,
      marginLeft: 'auto',
    },
    separator: {
      backgroundColor: 'black',
      height: 1,
      marginLeft: 50,
      marginRight: 50,
    },
    baseDetailTitle: {
      fontSize: 12,
      marginVertical: 'auto',
      width: 50,
    },

    padding: {
      paddingTop: 10,
    },

    info: {
      paddingLeft: 10,
      fontSize: 12,
      marginVertical: 'auto',
    },

    signature: {
      display: 'flex',
      paddingLeft: 50,
      paddingTop: 30,
    },

    signatureContainer: {
      paddingTop: 10,
      justifyContent: 'flex-end',
    },

    resultsContainer: {
      width: '100%',
      padding: '10px 50px 10px 50px',
    },

    secondaryTitle: {
      fontSize: 12,
      marginVertical: 'auto',
    },
    singleItem: {
      paddingTop: 2,
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
  });

  const singleResult = (title, value) => {
    if (!value) return <></>;
    return (
      <View>
        <View style={styles.singleItem}>
          <Text style={styles.secondaryTitle}>{title}:</Text>
          <Text style={styles.info}>{value}</Text>
        </View>
      </View>
    );
  };

  const baseDetail = (title, value) => {
    if (!value) return <></>;
    return (
      <View style={styles.patientInformation}>
        <Text style={styles.baseDetailTitle}>{title}:</Text>
        <Text style={styles.info}>{value}</Text>
      </View>
    );
  };

  // Create Document Component
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View style={styles.headerImageContainer}>
            <PDFImage src={FullLogoTagline}></PDFImage>
          </View>
          <View style={styles.headerText}>
            <Text style={styles.headerTextBig}>Prescription</Text>
            <Text style={styles.headerTextSmall}>https://www.canadaveinclinics.ca/</Text>
            <Text style={styles.headerTextSmall}>1-888-785-4080</Text>
          </View>
        </View>

        <View style={styles.formBaseDetails}>
          <View style={styles.column}>
            <View style={styles.formDate}>
              <View style={styles.dateInfo}>
                <Text style={styles.baseDetailTitle}>{'Date'}:</Text>
                <Text style={styles.info}>{formResults?.date}</Text>
              </View>
            </View>

            <View style={styles.patientDetails}>
              {baseDetail('Name', formResults?.patient?.fullName)}
              {baseDetail(
                'DOB',
                formatDate(DateTimeFormat.DayMonthYear, formResults?.patient?.dateOfBirth)
              )}
              {baseDetail('HC', formResults?.patient?.hcn)}
              {baseDetail('Phone', formatPhoneInput(formResults?.patient?.phone))}
              {baseDetail('Cell', formatPhoneInput(formResults?.patient?.cellPhone))}
              {baseDetail('Home', formatPhoneInput(formResults?.patient?.homePhone))}
            </View>
          </View>

          <View style={styles.doctorInfo}>
            {baseDetail('Doctor', formResults?.doctor)}
            {baseDetail('Billing', formResults?.billing)}
            {baseDetail('Fax', formatPhoneInput(formResults?.fax))}
          </View>
        </View>

        <View style={styles.separator} />

        <View style={styles.resultsContainer}>
          <View style={styles.padding}>
            {singleResult(
              'Prescription',
              formResults?.prescriptionFor ? formResults?.prescriptionFor : ''
            )}
            {formResults?.prescriptionFor == 'Compression Stockings (Thigh High)' && (
              <>
                {singleResult('Pressure', formResults?.compressionStockingsPreasureMeasurementTH)}
                {singleResult(
                  'Amount',
                  formResults?.compressionStockingsAmountTH == 'Other (Specify)'
                    ? formResults?.compressionStockingsAmountSpecifyTH
                    : formResults?.compressionStockingsAmountTH
                )}
                {singleResult(
                  'Repeats',
                  formResults?.compressionStockingsRepeatsTH == 'Other (Specify)'
                    ? formResults?.compressionStockingsRepeatsSpecifyTH
                    : formResults?.compressionStockingsRepeatsTH
                )}
              </>
            )}
            {formResults?.prescriptionFor == 'Compression Stockings (Panty Hose)' && (
              <>
                {singleResult('Pressure', formResults?.compressionStockingsPreasureMeasurementPH)}
                {singleResult(
                  'Amount',
                  formResults?.compressionStockingsAmountPH == 'Other (Specify)'
                    ? formResults?.compressionStockingsAmountSpecifyPH
                    : formResults?.compressionStockingsAmountPH
                )}
                {singleResult(
                  'Repeats',
                  formResults?.compressionStockingsRepeatsPH == 'Other (Specify)'
                    ? formResults?.compressionStockingsRepeatsSpecifyPH
                    : formResults?.compressionStockingsRepeatsPH
                )}
              </>
            )}
            {formResults?.prescriptionFor == 'Compression Stockings (Knee High)' && (
              <>
                {singleResult('Pressure', formResults?.compressionStockingsPreasureMeasurementKH)}
                {singleResult(
                  'Amount',
                  formResults?.compressionStockingsAmountKH == 'Other (Specify)'
                    ? formResults?.compressionStockingsAmountSpecifyKH
                    : formResults?.compressionStockingsAmountKH
                )}
                {singleResult(
                  'Repeats',
                  formResults?.compressionStockingsRepeatsKH == 'Other (Specify)'
                    ? formResults?.compressionStockingsRepeatsSpecifyKH
                    : formResults?.compressionStockingsRepeatsKH
                )}
              </>
            )}

            {formResults?.prescriptionFor == 'Xarelto' && (
              <>
                {singleResult(
                  'Times Taken',
                  formResults?.xeraltoTimesTaken == 'Other (Specify)'
                    ? formResults?.xeraltoTimesTakenSpecify
                    : formResults?.xeraltoTimesTaken
                )}
                {singleResult(
                  'Period Of Time Taken',
                  formResults?.xeraltoPeriodOfTimeTaken == 'Other (Specify)'
                    ? formResults?.xeraltoPeriodOfTimeTakenSpecify
                    : formResults?.xeraltoPeriodOfTimeTaken
                )}
                {singleResult('Amount', formResults?.xeraltoAmount)}
                {singleResult(
                  'Repeats',
                  formResults?.xeraltoRepeats == 'Other (Specify)'
                    ? formResults?.xeraltoRepeatsSpecify
                    : formResults?.xeraltoRepeats
                )}
              </>
            )}

            {formResults?.prescriptionFor == 'Others' && (
              <>
                {singleResult('Name', formResults?.othersName)}
                {singleResult(
                  'Times Taken',
                  formResults?.othersTimesTaken == 'Other (Specify)'
                    ? formResults?.othersTimesTakenSpecify
                    : formResults?.othersTimesTaken
                )}
                {singleResult(
                  'Period Of Time Taken',
                  formResults?.othersPeriodOfTimeTaken == 'Other (Specify)'
                    ? formResults?.othersPeriodOfTimeTakenSpecify
                    : formResults?.othersPeriodOfTimeTaken
                )}
                {singleResult('Amount', formResults?.othersAmount)}
                {singleResult(
                  'Repeats',
                  formResults?.othersRepeats == 'Other (Specify)'
                    ? formResults?.othersRepeatsSpecify
                    : formResults?.othersRepeats
                )}
              </>
            )}
          </View>
          {formResults?.diagnostic && (
            <View style={styles.padding}>
              {singleResult(
                'Diagnostics',
                formResults?.diagnostic == 'Other (Specify)'
                  ? formResults?.diagnosticSpecify
                  : formResults?.diagnostic
              )}
            </View>
          )}
        </View>

        <View style={styles.signatureContainer}>
          <View style={styles.separator} />
          <View style={styles.signature}>
            <Text style={styles.secondaryTitle}>{'Signed'}:</Text>
          </View>
          {!!signatureUrl && (
            <View style={styles.signatureImageContainer}>
              <PDFImage src={signatureUrl}></PDFImage>
            </View>
          )}
        </View>
      </Page>
    </Document>
  );
}

export default PrescriptionResultPDFFragment;
