import React, { ReactElement, useCallback, useContext, useEffect, useState, useRef } from 'react';
import jwtDecode from 'jwt-decode';

import { getUser, refreshToken as refreshTokenApi } from 'api/user';
import { ROLE } from 'lib/user';
import { Context as AuthContext } from 'context/auth';
import { Context as ProfileContext } from 'context/profile';

import { ACCESS_TOKEN, REFRESH_TOKEN } from 'lib/const';
import { REFRESH_INTERVAL } from 'lib/config';

interface TokenBody {
  id: number;
  role: string;
}

interface InitWrapperProps {
  children: any;
}

function AppWrapper({ children }: InitWrapperProps): ReactElement {
  const { role, setRole } = useContext(AuthContext);
  const { setUser } = useContext(ProfileContext);
  const intervalId = useRef<any>(0); // just const not ref for HTML

  const [loading, setLoading] = useState(true);

  const refreshToken = useCallback(async (): Promise<string | null> => {
    const newToken = await refreshTokenApi();

    localStorage.setItem(ACCESS_TOKEN, newToken.authToken);
    if (newToken.refreshToken) {
      localStorage.setItem(REFRESH_TOKEN, newToken.refreshToken);
    }

    return newToken.authToken;
  }, []);

  function refreshTokenInterval(): void {
    intervalId.current = setInterval(async () => {
      try {
        refreshToken();
      } catch (e) {
        clearInterval(intervalId.current);
      }
    }, REFRESH_INTERVAL); // 20 min
  }

  const initialize = useCallback(async () => {
    try {
      const token = await refreshToken();

      if (token) {
        const decoded: TokenBody = jwtDecode(token);
        if (decoded.id) {
          const user = await getUser(decoded.id);

          setUser(user);
          setRole(decoded.role || ROLE.ANONYMOUS);
        }
      }
    } catch (e) {
      setRole(ROLE.ANONYMOUS);
    }
    setLoading(false);
  }, [setRole, setUser]);

  useEffect(() => {
    setRole(ROLE.ANONYMOUS);
    initialize().catch();
    refreshTokenInterval();

    return () => clearInterval(intervalId.current);
  }, [initialize]);

  return !loading ? children : <div>Loading</div>;
}

export default AppWrapper;
