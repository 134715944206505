import React, { ReactElement, useState } from 'react';
import moment from 'moment';

import useUserApi from 'hook/useUserApi.hook';
import Layout from 'Layout';

import Calendar from 'components/Calendar';

import useDoctorSchedule from './useDoctorSchedule.hook';
import MonthSelector from './components/MonthSelector';
import UserInfo from './components/UserInfo';

import styles from './DoctorStyles.module.scss';
import { Contact } from '../../types';

import { DateTimeFormatString } from 'lib/dateFormatter';

// TODO - FUTURE - MERGE OVERLAP WITH LOCATION PAGE

function getContactByName(contacts: Array<Contact>, name: string): string {
  const contact = contacts.find(item => item.type === name);

  return contact?.value || 'N/A';
}

function DoctorPage(props): ReactElement {
  const doctorId = props?.match?.params?.doctorId;
  const [open, setOpen] = useState<boolean>(false);
  const [dialogType, setDialogType] = useState<string>('new');
  const [date, setDate] = useState(moment());
  const [scheduleValue, setScheduleValue] = useState({
    endTime: '',
    startTime: '',
    frequency: 'never',
  });
  const [startDate, setStartDate] = useState(new Date());
  const [selectedSchedule, setSelectedSchedule] = useState({
    endTime: '',
    location: {
      id: -1,
      name: '',
    },
    scheduleId: -1,
    startTime: '',
  });
  const { user } = useUserApi(doctorId);
  const { events, schedule, refresh } = useDoctorSchedule(
    doctorId,
    date
      .clone()
      .startOf('month')
      .startOf('week')
      .toISOString(),
    date
      .clone()
      .endOf('month')
      .endOf('week')
      .toISOString()
  );

  function onSaveSuccess(): void {
    refresh();
    setOpen(false);
  }

  function onSelectSchedule(props): void {
    setStartDate(props?.start);
    setDialogType('update');
    schedule.forEach(schedByDate => {
      const foundSched = schedByDate.schedule.find(sched => {
        const selectedScheduleEndTime = moment(props?.end).format(
          DateTimeFormatString.TimeOnlyWithoutMeridies
        );
        const selectedScheduleStartTime = moment(props?.start).format(
          DateTimeFormatString.TimeOnlyWithoutMeridies
        );

        const schedByDateDate = moment(schedByDate.date).startOf('day');
        const selectedEventStart = moment(props?.start).startOf('day');

        if (
          sched.location.name.toLowerCase() === props?.title.toLowerCase() &&
          sched.endTime === selectedScheduleEndTime &&
          sched.startTime === selectedScheduleStartTime &&
          schedByDateDate.isSame(selectedEventStart)
        ) {
          return sched;
        }
      });

      if (foundSched) {
        setSelectedSchedule(foundSched);
        setOpen(true);
      }
    });
  }

  function onSelectSlot(props): void {
    setStartDate(props?.start);
    setDialogType('new');
    setOpen(true);
  }

  return (
    <Layout hideFooter>
      <div className={styles.main}>
        <div className={styles.doctorInfo}>
          <UserInfo
            name={user?.fullName}
            type={user?.type}
            ohipPhysicianId={user?.staffInfo?.ohipPhysicianId}
            email={user?.email}
            phone={getContactByName(user?.contacts || [], 'phone')}
            abbreviations={user?.staffInfo?.abbreviations}
          />
        </div>
        <div className={styles.calendar}>
          <MonthSelector value={date} onChange={setDate} />
          <Calendar
            selectable
            currentDate={date.toDate()}
            view="month"
            events={events}
            onSelectSlot={props => {
              onSelectSlot(props);
            }}
            onSelectEvent={props => {
              onSelectSchedule(props);
            }}
          />
        </div>
      </div>
    </Layout>
  );
}

export default DoctorPage;
