import React, { ReactElement } from 'react';
import classNames from 'classnames';
import Dialog from 'components/Dialog';
import styles from './DeleteNoteDialogStyles.module.scss';
import { Note } from 'types';
import DialogButton from 'components/DialogButton';

interface SendNoteDialogProps {
  open: boolean;
  loading: boolean;
  note: Note | null;
  onConfirm?(): void;
  onClose?(): void;
}

function DeleteNoteDialog({
  loading,
  open,
  note,
  onConfirm,
  onClose,
}: SendNoteDialogProps): ReactElement {
  return (
    <Dialog title="Delete Note" open={open} onClose={onClose}>
      <div className={styles.container}>
        <div className={styles.subtitle}>Are you sure you want to delete {`'${note?.title}'`}?</div>
        <div className={classNames(styles.margin, styles.buttons)}>
          <DialogButton onClick={onClose}>Cancel</DialogButton>
          <DialogButton className={styles.marginLeft} loading={loading} onClick={onConfirm}>
            Confirm
          </DialogButton>
        </div>
      </div>
    </Dialog>
  );
}

export default DeleteNoteDialog;
