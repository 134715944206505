import { getTechnicianResults } from './lib/calculateTechnicianResults';
import { CaseResult } from 'types';

interface TechnicianResultMapProps {
  formResults: any;
  formCaseResult: CaseResult | null;
}

export function TechnicianResultMap({ formResults, formCaseResult }: TechnicianResultMapProps) {
  return {
    formResults,
  };
}

interface TechnicianResultsProps {
  formCaseResult: CaseResult | null;
}

export const useTechnicianResults = ({ formCaseResult }: TechnicianResultsProps) => {
  const formResults = getTechnicianResults(formCaseResult?.answers);

  return TechnicianResultMap({
    formResults,
    formCaseResult,
  });
};

export default useTechnicianResults;
