import React, { ReactElement, SyntheticEvent } from 'react';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';

import Card from 'components/Card';
import Logo from 'assets/images/logo.png';

import Button from './components/Button';

import styles from './LoginStyles.module.scss';

const FORGOT_PASSWORD_LINK = '/forgot';

interface LoginProp {
  email: string;
  password: string;
  loading: boolean;
  errors?: {
    email?: string;
    password?: string;
  } | null;
  onLogin: () => void;
  onChange: (event: SyntheticEvent) => void;
}

function Login({ email, password, loading, errors, onChange, onLogin }: LoginProp): ReactElement {
  return (
    <form className={styles.container} onSubmit={onLogin}>
      <Card>
        <div className={styles.form}>
          <img className={styles.logo} src={Logo} alt="logo" />
          <div className={styles.fields}>
            <div className={styles.field}>
              <TextField
                error={!!errors?.email}
                label={'Email'}
                name="email"
                value={email}
                onChange={onChange}
                placeholder={'email'}
                helperText={errors?.email}
              />
            </div>
            <div className={styles.field}>
              <TextField
                error={!!errors?.password}
                label={'Password'}
                name="password"
                type="password"
                value={password}
                onChange={onChange}
                placeholder={'password'}
                helperText={errors?.password}
              />
            </div>
            <Link href={FORGOT_PASSWORD_LINK} className={styles.link}>
              Forgot Password?
            </Link>
          </div>
          <div className={styles.buttonWrapper}>
            <Button loading={loading} type="submit">
              Login
            </Button>
          </div>
        </div>
      </Card>
    </form>
  );
}

export default Login;
