import React, { ReactElement, useState } from 'react';
import styles from './PreviewPanel.module.scss';
import { WorkflowComponent } from '../../../WorkflowResult/Workflow.page';

interface PreviewPanelProps {
  formId?: number;
}

function PreviewPanel({ formId }: PreviewPanelProps): ReactElement {
  const [coverLetterHeaderFooter, setCoverLetterHeaderFooter] = useState();

  const onCoverLetterHeaderFooterSet = coverLetterHeaderFooter => {
    setCoverLetterHeaderFooter(coverLetterHeaderFooter);
  };

  return (
    <div className={styles.previewPanel}>
      <WorkflowComponent
        formId={formId}
        editable={false}
        coverLetterHeaderFooter={coverLetterHeaderFooter}
        onCoverLetterHeaderFooterSet={onCoverLetterHeaderFooterSet}
      />
    </div>
  );
}

export default PreviewPanel;
