import axios from 'axios';

import { Note } from 'types';
import { GET_CASE } from 'lib/config';

import { getDefaultHeader } from '../utils';

export async function createNote(caseId: number, note: Note): Promise<Note> {
  const response = await axios.post(`${GET_CASE}/${caseId}/notes`, note, {
    headers: getDefaultHeader(),
  });

  return response.data;
}

export async function getNotes(caseId: number | string): Promise<Array<Note>> {
  const response = await axios.get(`${GET_CASE}/${caseId}/notes`, {
    headers: getDefaultHeader(),
  });

  return response.data;
}

export async function sendNote(
  caseId: number,
  noteId: number,
  staffSendId: Array<number>
): Promise<void> {
  await axios.post(
    `${GET_CASE}/${caseId}/notes/${noteId}/send`,
    { staffSendId },
    { headers: getDefaultHeader() }
  );
}

export async function deleteNote(caseId: number, noteId: number): Promise<void> {
  await axios.delete(`${GET_CASE}/${caseId}/notes/${noteId}`, {
    headers: getDefaultHeader(),
  });
}

export async function updateNote(caseId: number, data): Promise<Note> {
  const response = await axios.put(`${GET_CASE}/${caseId}/notes/${data.noteId}`, data, {
    headers: getDefaultHeader(),
  });

  return response.data;
}
