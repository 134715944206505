import React, { ReactElement, useState, memo } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import Checkbox from '@material-ui/core/Checkbox';
import ResponsiveTopBar from './components/ResponsiveTopBar';
import EnhancedTableHead from './components/EnhancedTableHead';
import styles from './Staff.module.scss';
import { useStyles, StyledTableCell, StyledTableRow } from 'lib/table';

import { ReactComponent as RubishIcon } from 'assets/svgs/Rubish.svg';
import { ReactComponent as EditIcon } from 'assets/svgs/EditIcon.svg';
import formatPhoneInput from 'lib/phoneNumFormatter';

interface StaffProps {
  onChangeSearchQuery: (string) => void;
  onNewStaffClick: () => void;
  onDeleteStaffClick: (staffToDelete) => void;
  onSelectStaff: (staffId: number) => void;
  onEditStaffClick: (staffToDelete) => void;
  staff?: Array<any>;
  searchQuery?: string;
}

function Staff({
  onChangeSearchQuery,
  onNewStaffClick,
  onDeleteStaffClick,
  onSelectStaff,
  onEditStaffClick,
  searchQuery = '',
  staff = [],
}: StaffProps): ReactElement {
  const classes = useStyles();
  const [selected, setSelected] = useState<number[]>([]);

  function handleSelectAllClick(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.checked) {
      const newSelected = staff.map(({ id }) => id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  }

  function handleClick(event: React.MouseEvent<unknown>, id: number) {
    const selectedIndex = selected.indexOf(id);

    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  }

  function isSelected(id: number) {
    return selected.indexOf(id) !== -1;
  }

  function onClickCheckbox(event: React.MouseEvent<unknown>, rowId) {
    event.stopPropagation();
    handleClick(event, rowId);
  }

  function onClickDelete(event: React.MouseEvent<unknown>, row) {
    event.stopPropagation();
    onDeleteStaffClick(row);
  }

  function onClickEdit(event: React.MouseEvent<unknown>, row) {
    event.stopPropagation();
    onEditStaffClick(row);
  }

  return (
    <div className={styles.container}>
      <ResponsiveTopBar
        searchQuery={searchQuery}
        onChangeSearchQuery={onChangeSearchQuery}
        onNewStaffClick={onNewStaffClick}
      />

      <TableContainer className={styles.tableContainer}>
        <Table className={classes.table} aria-label="customized table">
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            rowCount={staff.length}
          />
          <TableBody>
            {/* {staff.map(({ id, fullName, type, addresses: { city } }) => (
              <StyledTableRow key={id}>
                <StyledTableCell>{fullName}</StyledTableCell>
                <StyledTableCell>{type}</StyledTableCell>
                <StyledTableCell>{city}</StyledTableCell>
              </StyledTableRow>
            ))} */}
            {staff.map((row, index) => {
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <StyledTableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                  <StyledTableCell id={labelId}>{row.name}</StyledTableCell>
                  <StyledTableCell>
                    <span className={classes.staffTypeLabel}>{row.type}</span>
                  </StyledTableCell>
                  <StyledTableCell>{row.email}</StyledTableCell>
                  <StyledTableCell>{formatPhoneInput(row.phone)}</StyledTableCell>
                  <StyledTableCell
                    className={classes.rubbish}
                    onClick={event => onClickDelete(event, row)}
                  >
                    <RubishIcon />
                  </StyledTableCell>
                  <StyledTableCell
                    className={classes.edit}
                    onClick={event => onClickEdit(event, row)}
                  >
                    <EditIcon title={'edit'} />
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default memo(Staff);
