import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { UserApi } from 'api';
import Staff from './Staff.view';
import Layout from 'Layout';
import { EditStaffDialogContainer } from './components/EditStaffDialog';
import DeleteConfirmationDialog from 'components/DeleteConfirmationDialog';
import { User } from 'types';
import { Staff as UserStaff } from 'api/user/user.interfaces';
import { DeleteConfirmationDialogType } from 'components/DeleteConfirmationDialog/DeleteConfirmationDialog.view';
import { NewStaffDialogContainer } from './components/NewStaffDialog';
import { handleAPIErrors } from 'lib/handleAPIErrors';
import { DateTimeFormatString } from 'lib/dateFormatter';
import moment from 'moment';

interface Staff {
  id: number;
  name: string;
  type: string;
  email: string;
  phone: string;
  location: string;
}

function StaffContainer({ history }: any): ReactElement | null {
  const [open, setOpen] = useState<boolean>(false);
  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [staffToDelete, setStaffToDelete] = useState<Staff | null>(null);
  const [staffIdToEdit, setStaffIdToEdit] = useState<string>('');
  const [deleting, setDeleting] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [locationIds, setLocationIds] = useState<Array<number>>([]);
  const [staff, setStaff] = useState<Array<Staff>>([]);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    document.title = 'CVC - Staff List';
  }, []);

  function transformUserToStaff(users: User[]): UserStaff[] {
    const transformedUsers: UserStaff[] = [];

    if (users) {
      for (const user of users) {
        let phone = '';
        let staffLocation = '';
        const phoneContacts = user.contacts?.filter(contact => contact.type === 'phone');
        if (phoneContacts && phoneContacts.length > 0) {
          phone = phoneContacts[0].value;
        }

        if (user.staffInfo && user.staffInfo.locations.length > 0) {
          staffLocation = user.staffInfo.locations[0].name;
        }

        const transformedUser: UserStaff = {
          id: user.id,
          email: user.email,
          name: user.firstName + ' ' + user.lastName,
          phone,
          type: user.type,
          location: staffLocation,
        };

        transformedUsers.push(transformedUser);
      }
    }
    return transformedUsers;
  }

  async function getStaff(searchQuery?: string, locationIds?: string): Promise<void> {
    const users = await UserApi.getStaff(
      searchQuery && searchQuery.length > 0 ? searchQuery : '',
      undefined,
      locationIds && locationIds.length > 0 ? locationIds : ''
    );

    let staffList: UserStaff[] = [];

    if (users) {
      staffList = transformUserToStaff(users);
    }

    setStaff(staffList);
  }

  function onSaveSuccess(): void {
    getStaff();
    setOpen(false);
  }

  function onEditSuccess(): void {
    getStaff();
    setEditOpen(false);
    setStaffIdToEdit('');
  }

  async function deleteStaff(staffToDelete: Staff): Promise<void> {
    setDeleting(true);
    try {
      if (staffToDelete.id) {
        await UserApi.deleteStaff(
          staffToDelete.id,
          moment().format(DateTimeFormatString.APIDateFormat)
        );
        setStaffToDelete(null);
        getStaff();
      }
    } catch (e) {
      setError(handleAPIErrors(e));
    }
    setDeleting(false);
  }

  useEffect(() => {
    getStaff(searchQuery, locationIds.toString() !== '-1' ? locationIds.toString() : '');
  }, [locationIds, searchQuery]);

  return (
    <Layout hideFooter>
      <Staff
        staff={staff}
        searchQuery={searchQuery}
        onChangeSearchQuery={setSearchQuery}
        onSelectStaff={useCallback(staffId => history?.push(`doctor/${staffId}`), [history])}
        onNewStaffClick={() => {
          setOpen(true);
        }}
        onDeleteStaffClick={staff => setStaffToDelete(staff)}
        onEditStaffClick={staff => {
          setEditOpen(true);
          setStaffIdToEdit(staff.id);
        }}
      />

      <EditStaffDialogContainer
        staffId={staffIdToEdit}
        open={editOpen}
        onClose={() => {
          setEditOpen(false);
          setStaffIdToEdit('');
        }}
        onSaveSuccess={onEditSuccess}
      />

      <NewStaffDialogContainer
        open={open}
        onClose={() => setOpen(false)}
        onSaveSuccess={onSaveSuccess}
      />

      <DeleteConfirmationDialog
        itemToDelete={staffToDelete}
        loading={deleting}
        error={error}
        onClose={() => {
          setStaffToDelete(null);
          setError('');
        }}
        onConfirm={deleteStaff}
        deleteConfirmDialogType={DeleteConfirmationDialogType.Staff}
      />
    </Layout>
  );
}

export default StaffContainer;
