import React, { ReactElement, useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import { pdf, PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import TextField from '@material-ui/core/TextField';
import { FaxFields, NewDocument, NewFaxValidationError } from 'types';
import { DialogButtonType } from 'components/DialogButton/DialogButton.view';
import { ValidationError } from 'components/Forms/ValidationError';
import Button from 'components/CVCButton';
import DialogButton from 'components/DialogButton';
import Dialog from 'components/Dialog';
import useNewFaxPDFRender from './useNewFaxPDFRender.hook';
import useFaxActions from './useFaxActions.hook';
import styles from './NewFaxDialog.module.scss';
import useFaxApi from 'hook/useFaxApi.hook';
import { pdfSupportFileType } from 'lib/document';
import useReceiptPDFRender from 'pages/Patient/components/Receipts/components/NewReceiptDialog/useReceiptPDFRender.hook';
import { MIME_TYPE_PDF, supportedFileTypesString } from 'lib/document';
import { pushCoverLetterToS3 } from 'api/fax';
import FileUpload from 'pages/Patient/components/Documents/components/Documents/components/NewDocumentDialog/components/FileUpload';
import { AlertDialog } from 'components/AlertDialog';
import { handleAPIErrors } from 'lib/handleAPIErrors';

interface NewFaxDialogProps {
  open?: boolean;
  title?: string;
  validationErrors: Array<NewFaxValidationError>;
  fieldValues: FaxFields;
  faxDocument: any;
  validateForm(showErrors?): boolean;
  onChangeField(name, value): void;
  setFaxDocument(document): void;
  onClose(): void;
  onSaveSuccess(): void;
}

function NewFaxDialog({
  open = false,
  title,
  validationErrors,
  fieldValues,
  faxDocument,
  onChangeField,
  validateForm,
  setFaxDocument,
  onClose,
  onSaveSuccess,
}: NewFaxDialogProps): ReactElement {
  const [loading, setLoading] = useState<boolean>(false);
  const [openAlertDialog, setOpenAlertDialog] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const fileInput = useRef<any>(null);
  const {
    setCoverLetterURL,
    previewPdfDocument,
    createFaxWithCoverLetter,
    createGenericDocument,
  } = useFaxActions();
  const { CoverLetterLayout } = useNewFaxPDFRender(fieldValues);
  const { sendMultipleFaxDocuments } = useFaxApi();

  const handleFileChange = (event): void => {
    event.stopPropagation();
    event.preventDefault();
    const selectedFile = event.target.files[0];
    setFaxDocument(selectedFile);
  };

  const handleSave = async () => {
    if (!validateForm(true)) return;
    setLoading(true);
    try {
      const documentData = await createGenericDocument({
        name: faxDocument.name,
        document: faxDocument,
      });

      const selectedDocumentId = [documentData.id];
      const faxNumber = fieldValues.faxNumber;
      const coverLetterS3Details = await createFaxWithCoverLetter(
        selectedDocumentId,
        fieldValues.title,
        faxNumber,
        fieldValues.recipientName,
        true
      );

      await sendMultipleFaxDocuments(
        selectedDocumentId,
        coverLetterS3Details.startingId,
        fieldValues.faxNumber,
        true
      );

      onSaveSuccess();
    } catch (error) {
      const message = handleAPIErrors(error);
      setErrorMessage(message);
      setOpenAlertDialog(true);
    }
    setLoading(false);
  };

  const handlePreview = (): void => {
    previewPdfDocument();
  };

  return (
    <div>
      <Dialog open={open} title={title} onClose={onClose}>
        <div className={classNames(styles.dialogBody)}>
          <div className={styles.padding}>
            <TextField
              label="Fax Name"
              name="title"
              fullWidth
              onChange={({ target }): void => onChangeField(target.name, target.value)}
              value={fieldValues.title}
            />
            <ValidationError field={'title'} validationErrors={validationErrors} />
          </div>
          <div className={styles.padding}>
            <TextField
              fullWidth
              name={'recipientName'}
              label="Receipent Name"
              placeholder="Enter Receipient Name"
              onChange={({ target }) => onChangeField(target.name, target.value)}
              value={fieldValues.recipientName}
              type="text"
            />
          </div>
          <div className={styles.padding}>
            <TextField
              fullWidth
              name={'clinicName'}
              label="Clinic Name"
              placeholder="Enter Clinic Name"
              onChange={({ target }) => onChangeField(target.name, target.value)}
              value={fieldValues.clinicName}
              type="text"
            />
          </div>
          <div className={styles.padding}>
            <TextField
              label="Recipient Fax Number"
              name="faxNumber"
              fullWidth
              onChange={({ target }): void => onChangeField(target.name, target.value)}
              value={fieldValues.faxNumber}
            />
            <ValidationError field={'faxNumber'} validationErrors={validationErrors} />
          </div>

          <div className={styles.padding}>
            <TextField
              fullWidth
              name={'note'}
              label="Note"
              placeholder="Enter note to be sent"
              onChange={({ target }) => {
                onChangeField(target.name, target.value);
              }}
              value={fieldValues.note}
              multiline
              rows={3}
            />
          </div>
          <div className={styles.padding}>
            <Button onClick={(): void => fileInput?.current?.click()}>
              {faxDocument ? 'Change Fax Document' : 'Attach Fax Document'}
            </Button>
            <input
              type="file"
              ref={fileInput}
              style={{ display: 'none' }}
              name="document"
              onChange={handleFileChange}
              accept={pdfSupportFileType}
            />
            <ValidationError field={'faxDocument'} validationErrors={validationErrors} />
            <div className={styles.fileName}>{faxDocument?.name || ''}</div>
          </div>
          <div className={styles.row}>
            <div className={classNames(styles.previewButton, styles.padding)}>
              <DialogButton
                disabled={loading}
                loading={loading}
                onClick={handleSave}
                buttonType={DialogButtonType.Bordered}
              >
                {'Send Fax'}
              </DialogButton>
            </div>
            <div className={classNames(styles.previewButton, styles.padding)}>
              <DialogButton
                disabled={loading}
                onClick={handlePreview}
                buttonType={DialogButtonType.Bordered}
              >
                {'Preview Cover Letter'}
              </DialogButton>
            </div>
          </div>
        </div>
      </Dialog>
      <PDFDownloadLink document={CoverLetterLayout()}>
        {({ blob, url, loading, error }) => {
          if (url) {
            setCoverLetterURL(url);
          }
          return <div></div>;
        }}
      </PDFDownloadLink>
      <AlertDialog
        open={openAlertDialog}
        message={errorMessage}
        title={'Error Sending Documents'}
        onClose={() => setOpenAlertDialog(false)}
      />
    </div>
  );
}

export default NewFaxDialog;
