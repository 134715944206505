import React, { ReactElement } from 'react';
import { CalendarProps, Event, TitleOptions } from 'react-big-calendar';
import TimeGrid from 'react-big-calendar/lib/TimeGrid';

const MINUTE = 60 * 1000;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
const TIMEZONE_OFFSET = new Date(0).getTimezoneOffset() * MINUTE;

const firstDay = new Date(TIMEZONE_OFFSET);
const secondDay = new Date(DAY + TIMEZONE_OFFSET);
const thirdDay = new Date(2 * DAY + TIMEZONE_OFFSET);

const RESOURCE_TO_DAY_OFFSET = {
  Consultation: 0,
  Procedure: DAY,
  'Follow-up': 2 * DAY,
};

function threeDayRange(): Array<Date> {
  return [firstDay, secondDay, thirdDay];
}

function convertEvent(event: Event): Event {
  const { start = new Date(), end = new Date(), resource, ...other } = event;
  const dayOffset = RESOURCE_TO_DAY_OFFSET[resource] || 0;

  return {
    ...other,
    start: new Date(
      start.getHours() * 3600000 + dayOffset + start.getMinutes() * 60000 + TIMEZONE_OFFSET
    ),
    end: new Date(
      end.getHours() * 3600000 + dayOffset + end.getMinutes() * 60000 + TIMEZONE_OFFSET
    ),
  };
}

function DayCalendar(props: CalendarProps): ReactElement {
  const { events = [] } = props;
  const range: Array<Date> = threeDayRange();

  const newEvent = events.map(convertEvent);

  return (
    <TimeGrid
      {...props}
      date={firstDay}
      range={range}
      eventOffset={15}
      events={newEvent}
      view="week"
    />
  );
}

DayCalendar.range = threeDayRange;

DayCalendar.navigate = (date: Date): Date => {
  return date;
};

DayCalendar.title = (date: Date, { localizer }: TitleOptions): string => {
  const [start, ...rest] = threeDayRange();

  return localizer.format({ start, end: rest.pop() }, 'dayRangeHeaderFormat');
};

export default DayCalendar;
