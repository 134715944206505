import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

//This is still being used in staff page
export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
      maxWidth: '300px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  })
)(TableCell);

export const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: '#F1F3FF',
      },
    },
  })
)(TableRow);

//This is still being used in staff page
export const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  cellColored: {
    color: '#B00000',
    fontSize: 16,
    fontStyle: 'normal',
    borderRadius: 3,
    fontWeight: 'bold',
  },
  cell: {
    fontSize: 16,
    fontStyle: 'normal',
    borderRadius: 3,
    fontWeight: 'bold',
  },
  staffTypeLabel: {
    textTransform: 'capitalize',
  },
  rubbish: {
    '&:hover': {
      backgroundColor: 'rgba(176, 0, 0, 0.05)',
    },
  },
  edit: {
    '&:hover': {
      backgroundColor: 'rgba(176, 0, 0, 0.05)',
    },
  },
});
