import React, { ReactElement } from 'react';
import Grid from '@material-ui/core/Grid';
import { formatDate, DateTimeFormat } from 'lib/dateFormatter';
import OddPaper from '../OddPaper';

import styles from './AppointmentHistoryReportDialog.module.scss';
import { AppointmentInfo } from 'api/appointment/appointment.interfaces';
import { Checkbox } from '@material-ui/core';

function AppointmentHistoryReportHead(): ReactElement {
  return (
    <OddPaper className={styles.head} isOdd>
      <Grid container>
        <Grid item xs={1} className={styles.dateContent}>
          {'  '}
        </Grid>
        <Grid item xs={2} className={styles.dateContent}>
          <div className={styles.date}>Date</div>
        </Grid>
        <Grid item xs={2}>
          <div className={styles.headItem}>Location</div>
        </Grid>
        <Grid item xs={3}>
          <div className={styles.headItem}>Type</div>
        </Grid>
        <Grid item xs={2}>
          <div className={styles.headItem}>SubType</div>
        </Grid>
        <Grid item xs={2}>
          <div className={styles.headItem}>Status</div>
        </Grid>
      </Grid>
    </OddPaper>
  );
}

interface AppointmentHistoryRowProps {
  appointment: AppointmentInfo;
  isOdd: boolean;
  addAppointmentToReport?: (arg1: AppointmentInfo, arg2: boolean) => void;
}

function AppointmentHistoryReportRow({
  appointment,
  isOdd,
  addAppointmentToReport,
}: AppointmentHistoryRowProps): ReactElement {
  return (
    <OddPaper className={styles.body} isOdd={isOdd}>
      <Grid container className={styles.container}>
        {addAppointmentToReport && (
          <Grid item xs={1} className={styles.dateContent}>
            <Checkbox
              name={'displayPdf'}
              onChange={event => {
                addAppointmentToReport(appointment, event.target.checked);
              }}
            />
          </Grid>
        )}
        <Grid item xs={2}>
          <div>{formatDate(DateTimeFormat.DayMonthYear, appointment.start)}</div>
        </Grid>
        <Grid item xs={2}>
          <div>{appointment.location?.name}</div>
        </Grid>
        <Grid item xs={3}>
          <div>{appointment.type}</div>
        </Grid>
        <Grid item xs={2}>
          <div>{appointment.subTypeName}</div>
        </Grid>
        <Grid item xs={2}>
          <div>{appointment.status?.status}</div>
        </Grid>
      </Grid>
    </OddPaper>
  );
}

export { AppointmentHistoryReportRow, AppointmentHistoryReportHead };
