import { useState, useEffect } from 'react';
import { StaffNote, User } from 'types';

import { getUser } from 'api/user';

import { updateStaffNote as updateStaffNoteApi, getStaffNotes as getStaffNotesApi } from 'api/user';
import * as logger from 'api/logger';

export interface UserState {
  loading: boolean;
  user: User | null;
  updateStaffNote: (staffId, data) => Promise<void>;
  fetchStaffNotes: (staffId) => Promise<StaffNote>;
}

function useUserApi(userId, getUsers = true): UserState {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<User | null>(null);

  async function fetchUser(userId: number): Promise<void> {
    setLoading(true);
    try {
      const user = await getUser(userId);
      setUser(user);
    } catch (e) {
      logger.error(e.message);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (userId && getUsers) {
      fetchUser(userId).catch();
    }
  }, [userId]);

  async function updateStaffNote(staff, data): Promise<void> {
    setLoading(true);
    try {
      await updateStaffNoteApi(staff.id, data);
    } catch (e) {
      setLoading(false);
      throw e;
    }
    setLoading(false);
  }

  async function fetchStaffNotes(staffId: number): Promise<StaffNote> {
    setLoading(true);
    try {
      const note = await getStaffNotesApi(staffId);
      setLoading(false);
      return note;
    } catch (e) {
      setLoading(false);
      throw e;
    }
  }

  return {
    loading,
    user,
    updateStaffNote,
    fetchStaffNotes,
  };
}

export default useUserApi;
