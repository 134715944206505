import React, { ReactElement } from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';

interface Data {
  messageId: string;
  title: string;
  completionTime: string;
  status: string;
  sentByFullName: string;
  destination: string;
  pagesSent: number;
}

interface HeadCell {
  id: keyof Data;
  label: string;
}

const headCells: HeadCell[] = [
  { id: 'messageId', label: 'Message Id' },
  { id: 'title', label: 'Title' },
  { id: 'status', label: 'Status' },
  { id: 'destination', label: 'Destination' },
  { id: 'pagesSent', label: 'Pages' },
  { id: 'sentByFullName', label: 'Sent By' },
  { id: 'completionTime', label: 'Sent Confirmed' },
];

interface EnhancedTableProps {
  classes: any;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  numSelected: number;
  rowCount: number;
}

function OutboundEnhancedTableHead(props: EnhancedTableProps): ReactElement {
  const { classes, onSelectAllClick, numSelected, rowCount } = props;

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            className={classes.cellColored}
            key={headCell.id}
            align={'left'}
            padding={'default'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default OutboundEnhancedTableHead;
