import { SlotLengthInMinutes } from 'lib/appointments';
import { AppointmentStatus } from 'lib/user';
import { Moment } from 'moment';
export interface Patient {
  id: number;
  fullName: string;
  phoneNumber?: string;
  dateOfBirth?: Moment;
  forms: Array<Form>;
  documents: Array<Document>;
}

export type Address = {
  id?: number;
  line1: string;
  line2: string;
  city: string;
  province: string;
  country: string;
  postalCode: string;
};

export type AppointmentAvailabilityByDay = {
  staff_id: 2;
  dates: Array<string>;
};

export type StaffToSend = {
  staffId: number;
  sendEmail: true;
};

export type ReferralDoctor = {
  id: number;
  fullName: string;
};

export type Note = {
  id: number;
  createdAt?: number;
  updatedAt?: number;
  title: string;
  staff?: Array<StaffToSend> | null;
  contents: string;
  createdBy?: {
    id?: number;
    firstName?: string;
    lastName?: string;
    fullName?: string;
  };
  updatedBy?: {
    id?: number;
    firstName?: string;
    lastName?: string;
    fullName?: string;
  };
};

export enum StaffType {
  ADMIN = 1,
  DOCTOR = 2,
  TECHNICIAN = 3,
  PATIENT = 4,
  RECEPTIONIST = 5,
  NURSE = 6,
  NOSTAFF = 7,
  SUPER_ADMIN = 8,
}

export type Task = {
  id: number;
  title: string;
  description: string;
  case_id: number;
  staff_id: number;
  created_by: number;
  created_at: string;
  updated_at: string;
  completed: boolean;
  assignedTo: string;
  completed_at: string;
  patientFullName: string;
  creatorFullName?: string;
  patientId?: number;
};

export type CreateReceipt = {
  createdBy: number;
  createdAt: string;
  serviceDate: string;
  receiptDate: string;
  header: string;
  total: number;
  sendToPatient: boolean;
  contents: string;
  province: string;
  refund: boolean;
};

export type Receipt = {
  id: number;
  createdBy: {
    id: number;
    firstName: string;
    lastName: string;
    fullName: string;
  };
  contents: string;
  createdAt: string;
  updatedAt: string;
  header: string;
  total: number;
  sent: number;
  refund: boolean;
};

export type ReceiptPdf = {
  invoiceRows: any;
  receiptHeader: string;
  receiptType: string;
  invoiceTotal: number;
  patientName: string;
  healthCard: string;
  selectedDateOfBirth?: Moment;
  selectedReceiptDate?: Moment;
  patientMessage: string;
  paidInFull: boolean;
  partiallyPaid: boolean;
  receiptId: number;
};

export type Document = {
  id: number | string;
  createdBy: number | string;
  title: string;
  uploadedAt: string;
  url: string;
  s3_url?: string;
  documentType?: string;
  fileType?: string;
  type?: string;
  folderName?: string;
  flagged?: boolean;
  selected?: boolean;
  needsStamp?: boolean;
};

export type NewDocument = {
  name: string;
  typeId?: number | string;
  document: File;
  mimeType?: string;
  appointmentId?: number;
  folderName?: string;
  status?: boolean;
};

export type NewDefaultDocument = {
  title: string;
  type: DocumentType;
  document: File;
  needsStamp: boolean;
};

export type MultipleNewDocuments = Array<NewDocument>;

export type ConfirmCompleteDocument = {
  documentId: number;
  signedRequest: string;
};

export type NewBlobDocument = {
  name: string;
  typeId: number;
  fileName: string;
  mimeType: string;
  document: Blob;
  replacedDocumentId: number | string | null;
  softDelete?: boolean;
};

export type DocumentType = {
  id: number;
  type: string;
};

export type QuestionTemplateOption = {
  id: number | string;
  type: string;
  value: string;
};

export type QuestionTemplateAnswer = {
  id?: number | string;
  value: string;
  key?: string;
  questionOptionId?: number | null;
  templateQuestionId: number;
};

export type QuestionTemplate = {
  id: number | string;
  type: string;
  title: string;
  order: number;
  section: any;
  templateQuestionId: number;
  userCanWrite: boolean;
  showField: boolean;
  default_value: string;
  requiresPrevious: Array<any>;
  description: string;
  options: Array<QuestionTemplateOption>;
  answers: Array<QuestionTemplateAnswer>;
  required?: boolean;
};

export type Form = {
  id?: number | string;
  case_id?: number | string;
  status?: string;
  description?: string;
  assigned_at?: number;
  updated_at?: number | string;
  template_id?: number | string;
  status_id?: number | string;
  created_at?: string;
  completed_at?: string;
  document_url?: string;
  type?: string;
  template_type_id?: number;
  doctor_signature?: number;
  can_read?: number;
  can_write?: number;
  can_delete?: number;
};

export type FormTemplate = {
  id?: number;
  title?: string;
};

export type FormStatus = {
  id: number;
  title: string;
};

export type Location = {
  id: number;
  name: string;
  timezone: string;
  addresses: Array<Address>;
  contacts: Array<Contact>;
  sortOrder: number;
  scheduleTypes: Array<number>;
  weeklyScheduleAvailability: WeeklyScheduleAvailability;
  exceptions?: Array<string>;
};

export type WeeklyScheduleAvailability = {
  consultation: {
    Mo?: boolean;
    Tu?: boolean;
    We?: boolean;
    Th?: boolean;
    Fr?: boolean;
    Sa?: boolean;
    Su?: boolean;
  };
  procedure: {
    Mo?: boolean;
    Tu?: boolean;
    We?: boolean;
    Th?: boolean;
    Fr?: boolean;
    Sa?: boolean;
    Su?: boolean;
  };
  ultrasound: {
    Mo?: boolean;
    Tu?: boolean;
    We?: boolean;
    Th?: boolean;
    Fr?: boolean;
    Sa?: boolean;
    Su?: boolean;
  };
};

export type Section = {
  sectionName: string;
  templateQuestions: Array<QuestionTemplate>;
};

export type QuestionForm = Form & {
  sections: Array<Section>;
};

export type TokenBody = {
  id: number;
  role: string;
};

export enum ContactType {
  Phone = 'phone',
  Email = 'email',
  Office = 'office',
  Cell = 'cell',
  Home = 'home',
}

export type Contact = {
  id: number;
  type: ContactType;
  value: string;
};

export type PatientInfoMeta = {
  id: number;
  key: string;
  value: string;
};

export type StringValueItem = {
  id: string | number;
  name: string;
};

export enum ReferrerStatus {
  NeedReferral = 'needReferral',
  ReferralReceived = 'referralReceived',
  NoReferralRequired = 'noReferralRequired',
}

export enum ReferrerStatusId {
  NeedReferral = 1,
  ReferralReceived = 2,
  NoReferralRequired = 3,
}

export enum ReferrerStatusShorthand {
  NeedReferral = '(NR)',
}

export type PatientInfo = {
  id: number;
  caseId: number;
  healthCard: string;
  hcn: string;
  gender: string;
  sex: string;
  prefix: string;
  healthCardProvinceCode: string;
  dateOfBirth: string;
  pronouns?: string;
  referrerStatusId: ReferrerStatusId;
  referrerFirstName: string;
  referrerLastName: string;
  referrerEmail: string;
  referrerPhone: string;
  referrerFax: string;
  referrerBilling: string;
  referrerPhysicianId: string;
  referrerDocument: Document;
  meta?: [PatientInfoMeta];
  latestAppointmentStatus: string;
  familyPhysicianFirstName?: string;
  familyPhysicianLastName?: string;
  familyPhysicianEmail?: string;
  familyPhysicianPhone?: string;
  familyPhysicianFax?: string;
  familyPhysicianBilling?: string;
  familyPhysicianId?: string;
  noHealthCard?: boolean;
  familyDoctorId?: number;
  signatureApprovedIds?: Array<number>;
  newPatient?: boolean;
};

export interface OperationInfo {
  id: number;
  name: string;
}

export interface RescheduleInfo {
  id: number;
  patientName: string;
  subTypeId: number;
  mainType: string;
}

export type StaffInfo = {
  id: number;
  ohipPhysicianId: string;
  locations: Location[];
  abbreviations: string;
  signatureApprovedIds?: Array<number>;
  signatureS3Key?: string;
  signatureS3Url?: string;
};

export type StaffNote = {
  note?: string;
};

export type User = {
  id: number;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  type: string;
  typeId?: number;
  addresses: Address[];
  contacts: Contact[];
  patientInfo?: PatientInfo;
  staffInfo?: StaffInfo;
  createdAt: string;
  updatedAt: string;
};

export type OtherPhysician = {
  id: number;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  fax: string;
  phone: string;
  billing: string;
  physicianId: string;
};

export type EditableUser = {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  cellPhone?: string;
  homePhone?: string;
  phoneType?: string;
  prefix?: string;
  contactId?: number;
  contacts?: Array<{ id?: number; type?: string; value?: string }>;
  healthCard?: string;
  hcn?: string;
  date?: Moment;
  provinceCode?: string;
  ohipPhysicianId?: string;
  healthCardProvinceCode?: string;
  dateOfBirth?: Moment;
  gender?: string;
  patientInfoId?: number;
  noHealthCard?: boolean;
  familyDoctorId?: number;
  abbreviations?: string;
  signatureApprovedUsers?: Array<User>;
  signatureFile?: File;
  address?: Address;
  removeSignature?: boolean;
  newPatient?: boolean;
};

export type FaxInbound = {
  id?: number;
  messageId?: number;
  receiveTime?: string;
  callerId?: string;
  caseId?: number;
  assignedBy?: number;
  mimeType?: string;
  uploadedAt?: string;
  patientFullName?: string;
  patientUserId?: string;
  title?: string;
  documentTypes?: Array<DocumentType>;
  includesPdf?: boolean;
  duplicate?: boolean;
};

export type FaxOutbound = {
  id?: number;
  messageId?: number;
  title?: string;
  statusId?: number;
  statusName?: string;
  submitTime?: string;
  completionTime?: string;
  sentToNumber?: string;
  sentToName?: string;
  sentBy?: number;
  pagesSubmitted?: number;
  pagesSent?: number;
};

export type Appointment = {
  id: number;
  staff: {
    id: number;
    type: string;
    fullName: string;
  };
  caseId: number;
  patient: User | null;
  createdAt: string;
  updatedAt: string;
  createdBy: number;
  startTime: string;
  endTime: string;
  endTimeExpected: string;
  description: string;
  type: string;
  typeId: number;
  status: {
    id: number;
    status: string;
    description: string;
  };
};

export type UrlLocationSearch = {
  search: UrlLocationSearchQuery[];
};

export type UrlLocationSearchQuery = {
  key: string;
  value: string;
};

type Response = {
  success: boolean;
  status: number;
  error?: string;
};

export type RequestLogin = {
  email: string;
  password: string;
};

export type ResponseLogin = Response & {
  data: {
    id: number;
    role: string;
    firstName: string;
    lastName: string;
    email: string;
    type: string;
  };
};

export interface AppointmentAvailability {
  staff_id: number;
  schedule: Array<{ start_time: string; end_time: string }>;
}

export interface QuickViewAppointmentAvailability {
  day: string;
  staff_schedules: Array<{ staff_id: number; schedules: StaffSchedules }>;
  openings: Array<AppointmentOpenings>;
}

export interface StaffSchedules {
  consultation: Array<string>;
  procedure: Array<string>;
  ultrasound: Array<string>;
}

export interface AppointmentOpenings {
  staffFullName: string;
  staffId: number;
  startTime: string;
  endTime: string;
}

export interface AppointmentAvailabilityNext {
  location_id: number;
  staff_id: number;
  date: string;
  start_time: string;
  location: string;
  staff_full_name: string;
}

export interface AvailableDoctor {
  fullName: string;
  id: number | string;
  userType: string;
}

export interface ScheduledStaff {
  fullName: string;
  id: number;
  userType: string;
  startTime: string;
  endTime: string;
  staffType: number;
  staffId: number;
  scheduleId: number;
  userId: number;
  cancelled?: boolean;
}

export interface AppointmentInfo {
  length?: number;
  day?: Moment;
  startTime: string;
  endTime: string;
  location?: number;
  scheduleKey?: string;
  patient?: any;
  appointmentType?: string;
  appointmentId?: number;
  staff?: User;
  endTimeExpected?: string;
  caseId?: number;
  status?: any;
  requiredStaffTypeId?: number;
  cancellationList?: boolean;
  anyDoctor?: boolean;
}

export interface DynamicAppointmentSelection {
  type: string;
  startTime: string;
  endTime: string;
}

export interface SubType {
  id: number;
  mainTypeId: number;
  mainTypeName: string;
  subTypeName: string;
  completeName;
}

export interface SubTypes {
  1: Array<SubType>;
  2: Array<SubType>;
  3: Array<SubType>;
}

export interface FaxFields {
  title: string;
  faxNumber: string;
  note: string;
  recipientName: string;
  clinicName: string;
  patientName: string;
  dateOfBirth: string;
  healthcard: string;
}

export type RequestResetPassword = {
  password: string;
};

export type ResponseResetPassword = Response;

export type RequestForgotPassword = {
  email: string;
};

export type DoctorSchedule = {
  scheduleId?: number;
  staffId?: number;
  locationId: number;
  startTime: string;
  endTime: string;
  startDate?: Date;
  endDate?: Date | null;
  recurrence: 'weekly' | 'monthly' | 'never';
  autoAssignTypeIds?: Array<number>;
  type?: number;
  cancelled?: boolean;
};

export type LocationSchedule = {
  locationId: number;
  scheduleId?: number;
  startTime: string;
  endTime: string;
  startDate?: Date;
  endDate?: Date | null;
  recurrence: 'weekly' | 'monthly' | 'never';
  type: number;
  staffMaxEndTime?: string;
  staffMinStartTime?: string;
  staffScheduleCount?: number;
  cancelled?: boolean;
};

export type ResponseForgotPassword = Response;

export type RequestGetUsers = {
  search?: string;
};

export type ResponseGetUsers = Response & {
  data?: [User];
};

export type NewPatientDialogValidationError = {
  field: string;
  message: string;
};

export type PossiblePatient = {
  id: number;
  fullName: string;
  email: string;
  healthCard: string;
  dob: string;
};

export type NewStaffDialogValidationError = {
  field: string;
  message: string;
};

export type NewLocationDialogValidationError = {
  field: string;
  message: string;
};

export type LocationScheduleValidationError = {
  field: string;
  message: string;
};

export type SendScheduleValidationError = {
  field: string;
  message: string;
};

export type StaffScheduleValidationError = {
  field: string;
  message: string;
};

export type AddPersonalTimeValidationError = {
  field: string;
  message: string;
};

export type DocumentValidationError = {
  field: string;
  message: string;
};

export type NewReceiptValidationError = {
  field: string;
  message: string;
};

export type NewFaxValidationError = {
  field: string;
  message: string;
};

export type EditPhysicianValidationError = {
  field: string;
  message: string;
};

export type TaskDialogValidationError = {
  field: string;
  message: string;
};

export type ReportType = {
  id: number;
  type: string;
};
export type ReportFilters = {
  minDate?: string;
  maxDate?: string;
  minSecondDate?: string;
  maxSecondDate?: string;
  appointmentTypes?: Array<any>;
  ultrasoundTypes?: Array<any>;
  staffs?: Array<any>;
  creatingStaffs?: Array<any>;
  hasReferral?: number;
  hasProcedure?: number;
  patientType?: number;
  nextAppointment?: number;
  procedureTypes?: Array<any>;
  bookingStatus?: Array<any>;
  billingStatus?: number;
  gender?: number;
  hearAboutUs?: Array<number>;
  mainConcern?: Array<number>;
  symptoms?: Array<number>;
  symptomLength?: Array<number>;
  endOfDayWorse?: number;
  triedStockings?: number;
  historyOfVaricoseVeins?: number;
  knownAlergies?: number;
  bloodClots?: number;
  treatedForVaricoseVeins?: number;
  medicalHistory?: Array<number>;
  takingBloodThinners?: number;
  minDateOfService?: string;
  maxDateOfService?: string;
  minDateOfReceipt?: string;
  maxDateOfReceipt?: string;
  receiptFor?: Array<any>;
};
export type CreateReport = {
  reportId: number;
  filters: ReportFilters;
};
export type ReportResult = {
  report: any;
  total: number;
};
export type ReportFilter = {
  id: number;
  value: string;
};

export type CSVHeader = {
  key: string;
  label: string;
};

export interface PaymentMethod {
  id: number;
  paymentMethod: string;
}

export interface ReceiptType {
  id: number;
  receiptType: string;
  isVisible: boolean;
}

export interface DownloadDocumentType {
  signedRequest: string;
}

export interface CaseResultAnswer {
  answerType: string;
  id: string;
  value: string | Array<string>;
  valueType: string;
}

export interface CaseResult {
  id: number;
  templateId: string;
  answers: Array<CaseResultAnswer>;
}

// TODO:T-278 define response interface
export interface CommunicationHistory {
  sent_at: Date;
  type: string;
  method: string;
  title: string;
  status: string;
  sentBy: { email?: string; fullName?: string };
  sentTo: { email?: string; fax?: string; fullName?: string };
  response: any;
}

export interface LocationListItem {
  id: number;
  email: string;
  name: string;
  phone: string;
  address: string;
  scheduleTypes: Array<number>;
  exceptions?: Array<string>;
  province?: string;
}

// Availability and Appointment Types
export enum AppointmentTypes {
  Consultation = 'Consultation',
  Procedure = 'Procedure',
  Ultrasound = 'Ultrasound',
}

export enum UserTypes {
  Doctor = 'doctor',
  Patient = 'patient',
  RegisteredNurse = 'registeredNurse',
  Admin = 'admin',
  SuperAdmin = 'superAdmin',
  Technician = 'technician',
  Nurse = 'nurse',
}

export interface DailyAppointmentAvailability {
  availabilityId: number;
  availabilityType: string;
  availabilityStartTime: string;
  availabilityEndTime: string;
  availabilityDay: string;
}

export interface AvailableBookingTimes {
  consultation: Array<string>;
  procedure: Array<string>;
  ultrasound: Array<string>;
}

export interface DailyAppointment {
  appointmentSlotLength: SlotLengthInMinutes;
  id: number;
  appointmentId: number; //Remove this or Id when the refactor of the AppointmentInfo are complete.
  appointmentStartTime: Date | string;
  appointmentEndTime: Date | string;
  appointmentType: AppointmentTypes;
  appointmentStatus: AppointmentStatus;
  appointmentNotes: string | null;
  appointmentSubTypeName: string;
  appointmentSubTypeId: number;
  appointmentCancellationList: boolean;
  doctor: AvailableDoctor;
  optionalTechnicianId?: number;
  optionalNurseId?: number;
  patient: Patient;
  referralDoctor: ReferralDoctor;
  appointmentInCancelledSchedule: boolean;
}

export interface DailyAppointmentInfo {
  locationOpeningTime: Date | string;
  locationClosingTime: Date | string;
  locationAppointmentAvailability: Array<DailyAppointmentAvailability>;
  locationAppointments: Array<DailyAppointment>;
}

export interface HealthCardValidationResults {
  auditUID: string;
  results: HealthCardValidation[];
}

export interface HealthCardValidation {
  dateOfBirth: Moment;
  dateOfBirthSpecified: boolean;
  expiryDate: Moment;
  expiryDateSpecified: boolean;
  firstName: string;
  gender: string;
  healthNumber: string;
  lastName: string;
  responseAction: string;
  responseCode: string;
  responseDescription: string;
  responseID: number;
  secondName: string;
  versionCode: string;
  feeServiceDetails?: any;
}

export interface HealthCardUpdate {
  dateOfBirth: Moment | undefined;
  firstName: string;
  gender: string;
  lastName: string;
}

export type UploadTemplateFormInput = {
  file: File;
  fileName: string;
  mimeType: string;
  title: string;
  description?: string;
  templateTypeId?: number;
  templateKey?: string;
  templateTypeDescription?: string;
  permissions: UploadTemplateFormBodyPermission;
  doctorSignature?: boolean;
};

export type UploadTemplateFormBodyPermission = {
  admin: {
    read: boolean;
    write: boolean;
    delete: boolean;
  };
  doctor: {
    read: boolean;
    write: boolean;
    delete: boolean;
  };
  technician: {
    read: boolean;
    write: boolean;
    delete: boolean;
  };
  patient: {
    read: boolean;
    write: boolean;
    delete: boolean;
  };
  nurse: {
    read: boolean;
    write: boolean;
    delete: boolean;
  };
  receptionist: {
    read: boolean;
    write: boolean;
    delete: boolean;
  };
  physicianAssistant: {
    read: boolean;
    write: boolean;
    delete: boolean;
  };
  noStaff: {
    read: boolean;
    write: boolean;
    delete: boolean;
  };
  superAdmin: {
    read: boolean;
    write: boolean;
    delete: boolean;
  };
};

type UploadTemplateFormBodyFile = {
  fileName: string;
  mimeType: string;
};

type UploadTemplateFormBodyTemplate = {
  title: string;
  description?: string;
  templateTypeId?: number;
  templateKey?: string;
  templateTypeDescription?: string;
};

export type UploadTemplateFormBody = {
  file: UploadTemplateFormBodyFile;
  template: UploadTemplateFormBodyTemplate;
  permissions: UploadTemplateFormBodyPermission;
  doctorSignature?: boolean;
};

export interface BaseResponse {
  success: boolean;
  errorMessage?: string;
}
