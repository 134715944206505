import React, { useEffect, useState } from 'react';
import { stylesPdf } from 'lib/workflowPdf';
import { Page, Text, View, Image as PDFImage } from '@react-pdf/renderer';

import useTechResultPDFFragment from './useTechResultPDFFragment.hook';
import VeinLogo from 'assets/images/vein-legend.jpg';
interface TechResultPDFFragmentProps {
  caseId?: number;
  formId?: number;
  setLoading?: (state: boolean) => void;
}

export const TechResultPDFFragment = ({
  formId,
  caseId,
  setLoading,
}: TechResultPDFFragmentProps) => {
  const { techFormResults, imageSrcLeft, imageSrcRight } = useTechResultPDFFragment({
    formId,
    caseId,
  });

  // If the result cares about the loading status
  useEffect(() => {
    if (setLoading) {
      setLoading(imageSrcLeft == '' && imageSrcRight == '');
    }
  }, [imageSrcLeft, imageSrcRight]);

  const LLGSV = Array.isArray(techFormResults.leftLeg.GSV)
    ? techFormResults.leftLeg.GSV
    : [techFormResults.leftLeg.GSV];
  const LLGSVThrombosed = Array.isArray(techFormResults.leftLeg.GSVThrombosed)
    ? techFormResults.leftLeg.GSVThrombosed
    : [techFormResults.leftLeg.GSVThrombosed];
  const LLGSVAbove = Array.isArray(techFormResults.leftLeg.GSVAbove)
    ? techFormResults.leftLeg.GSVAbove
    : [techFormResults.leftLeg.GSVAbove];
  const LLGSVBelow = Array.isArray(techFormResults.leftLeg.GSVBelow)
    ? techFormResults.leftLeg.GSVBelow
    : [techFormResults.leftLeg.GSVBelow];
  const LLGSVStripped = Array.isArray(techFormResults.leftLeg.GSVStripped)
    ? techFormResults.leftLeg.GSVStripped
    : [techFormResults.leftLeg.GSVStripped];
  const LLGSVAblated = Array.isArray(techFormResults.leftLeg.GSVAblated)
    ? techFormResults.leftLeg.GSVAblated
    : [techFormResults.leftLeg.GSVAblated];
  const LLGSVReflux = Array.isArray(techFormResults.leftLeg.GSVReflux)
    ? techFormResults.leftLeg.GSVReflux
    : [techFormResults.leftLeg.GSVReflux];
  const LLGSVRefluxMaxDiameter = Array.isArray(techFormResults.leftLeg.GSVRefluxMaxDiameter)
    ? techFormResults.leftLeg.GSVRefluxMaxDiameter
    : [techFormResults.leftLeg.GSVRefluxMaxDiameter];
  const LLPSV = Array.isArray(techFormResults.leftLeg.PSV)
    ? techFormResults.leftLeg.PSV
    : [techFormResults.leftLeg.PSV];
  const LLGM = Array.isArray(techFormResults.leftLeg.GM)
    ? techFormResults.leftLeg.GM
    : [techFormResults.leftLeg.GM];
  const LLPerforators = Array.isArray(techFormResults.leftLeg.Perforators)
    ? techFormResults.leftLeg.Perforators
    : [techFormResults.leftLeg.Perforators];
  const LLcomments = Array.isArray(techFormResults.leftLeg.comments)
    ? techFormResults.leftLeg.comments
    : [techFormResults.leftLeg.comments];
  const LLSSVUpper = Array.isArray(techFormResults.leftLeg.SSVUpper)
    ? techFormResults.leftLeg.SSVUpper
    : [techFormResults.leftLeg.SSVUpper];
  const LLSSVLower = Array.isArray(techFormResults.leftLeg.SSVLower)
    ? techFormResults.leftLeg.SSVLower
    : [techFormResults.leftLeg.SSVLower];
  const LLSSVRefluxMaxDiameter = Array.isArray(techFormResults.leftLeg.SSVRefluxMaxDiameter)
    ? techFormResults.leftLeg.SSVRefluxMaxDiameter
    : [techFormResults.leftLeg.SSVRefluxMaxDiameter];
  const LLASV = Array.isArray(techFormResults.leftLeg.ASV)
    ? techFormResults.leftLeg.ASV
    : [techFormResults.leftLeg.ASV];
  const LLSPJ = Array.isArray(techFormResults.leftLeg.SPJ)
    ? techFormResults.leftLeg.SPJ
    : [techFormResults.leftLeg.SPJ];
  const LLSFJ = Array.isArray(techFormResults.leftLeg.SFJ)
    ? techFormResults.leftLeg.SFJ
    : [techFormResults.leftLeg.SFJ];
  const LLVV = Array.isArray(techFormResults.leftLeg.VV)
    ? techFormResults.leftLeg.VV
    : [techFormResults.leftLeg.VV];
  const LLSuperficialVeinThrombosis = Array.isArray(
    techFormResults.leftLeg.SuperficialVeinThrombosis
  )
    ? techFormResults.leftLeg.SuperficialVeinThrombosis
    : [techFormResults.leftLeg.SuperficialVeinThrombosis];

  const RLGSV = Array.isArray(techFormResults.rightLeg.GSV)
    ? techFormResults.rightLeg.GSV
    : [techFormResults.rightLeg.GSV];
  const RLGSVThrombosed = Array.isArray(techFormResults.rightLeg.GSVThrombosed)
    ? techFormResults.rightLeg.GSVThrombosed
    : [techFormResults.rightLeg.GSVThrombosed];
  const RLGSVAbove = Array.isArray(techFormResults.rightLeg.GSVAbove)
    ? techFormResults.rightLeg.GSVAbove
    : [techFormResults.rightLeg.GSVAbove];
  const RLGSVBelow = Array.isArray(techFormResults.rightLeg.GSVBelow)
    ? techFormResults.rightLeg.GSVBelow
    : [techFormResults.rightLeg.GSVBelow];
  const RLGSVStripped = Array.isArray(techFormResults.rightLeg.GSVStripped)
    ? techFormResults.rightLeg.GSVStripped
    : [techFormResults.rightLeg.GSVStripped];
  const RLGSVAblated = Array.isArray(techFormResults.rightLeg.GSVAblated)
    ? techFormResults.rightLeg.GSVAblated
    : [techFormResults.rightLeg.GSVAblated];
  const RLGSVReflux = Array.isArray(techFormResults.rightLeg.GSVReflux)
    ? techFormResults.rightLeg.GSVReflux
    : [techFormResults.rightLeg.GSVReflux];
  const RLGSVRefluxMaxDiameter = Array.isArray(techFormResults.rightLeg.GSVRefluxMaxDiameter)
    ? techFormResults.rightLeg.GSVRefluxMaxDiameter
    : [techFormResults.rightLeg.GSVRefluxMaxDiameter];
  const RLPSV = Array.isArray(techFormResults.rightLeg.PSV)
    ? techFormResults.rightLeg.PSV
    : [techFormResults.rightLeg.PSV];
  const RLGM = Array.isArray(techFormResults.rightLeg.GM)
    ? techFormResults.rightLeg.GM
    : [techFormResults.rightLeg.GM];
  const RLPerforators = Array.isArray(techFormResults.rightLeg.Perforators)
    ? techFormResults.rightLeg.Perforators
    : [techFormResults.rightLeg.Perforators];
  const RLcomments = Array.isArray(techFormResults.rightLeg.comments)
    ? techFormResults.rightLeg.comments
    : [techFormResults.rightLeg.comments];
  const RLSSVUpper = Array.isArray(techFormResults.rightLeg.SSVUpper)
    ? techFormResults.rightLeg.SSVUpper
    : [techFormResults.rightLeg.SSVUpper];
  const RLSSVLower = Array.isArray(techFormResults.rightLeg.SSVLower)
    ? techFormResults.rightLeg.SSVLower
    : [techFormResults.rightLeg.SSVLower];
  const RLSSVRefluxMaxDiameter = Array.isArray(techFormResults.rightLeg.SSVRefluxMaxDiameter)
    ? techFormResults.rightLeg.SSVRefluxMaxDiameter
    : [techFormResults.rightLeg.SSVRefluxMaxDiameter];
  const RLASV = Array.isArray(techFormResults.rightLeg.ASV)
    ? techFormResults.rightLeg.ASV
    : [techFormResults.rightLeg.ASV];
  const RLSPJ = Array.isArray(techFormResults.rightLeg.SPJ)
    ? techFormResults.rightLeg.SPJ
    : [techFormResults.rightLeg.SPJ];
  const RLSFJ = Array.isArray(techFormResults.rightLeg.SFJ)
    ? techFormResults.rightLeg.SFJ
    : [techFormResults.rightLeg.SFJ];
  const RLVV = Array.isArray(techFormResults.rightLeg.VV)
    ? techFormResults.rightLeg.VV
    : [techFormResults.rightLeg.VV];
  const RLSuperficialVeinThrombosis = Array.isArray(
    techFormResults.rightLeg.SuperficialVeinThrombosis
  )
    ? techFormResults.rightLeg.SuperficialVeinThrombosis
    : [techFormResults.rightLeg.SuperficialVeinThrombosis];

  return (
    <View>
      <View style={stylesPdf.header}>
        <Text>Technician Results</Text>
        <View style={stylesPdf.legendImage}>
          <PDFImage src={VeinLogo}></PDFImage>
        </View>
      </View>
      <View style={stylesPdf.resultsColumn}>
        <View style={stylesPdf.resultsBreakdown}>
          <Text style={stylesPdf.subheader}>Right Leg</Text>
          <View style={stylesPdf.resultsContainer}>
            <View style={stylesPdf.column}>
              {RLGSVThrombosed && RLGSVThrombosed.length > 0 ? (
                <View>
                  <Text style={stylesPdf.veinSubheader}>{'GSV Thrombosed:'}</Text>
                  {RLGSVThrombosed.length > 0 && (
                    <View>
                      <View>
                        {RLGSVThrombosed.filter(r => !!r).map((fr, index) => (
                          <View key={index} style={stylesPdf.issueText}>
                            <Text>• {fr}</Text>
                          </View>
                        ))}
                      </View>
                    </View>
                  )}
                </View>
              ) : (
                <></>
              )}
              <View>
                <Text style={stylesPdf.veinSubheader}>{'GSV Above Knee:'}</Text>
                <View>
                  <View>
                    {RLGSVAbove && RLGSVAbove.length > 0 ? (
                      RLGSVAbove.filter(r => !!r).map((fr, index) => (
                        <View key={index} style={stylesPdf.issueText}>
                          <Text>• {fr}</Text>
                        </View>
                      ))
                    ) : (
                      <View style={stylesPdf.issueText}>
                        <Text>• Normal</Text>
                      </View>
                    )}
                  </View>
                </View>
              </View>
              <View>
                <Text style={stylesPdf.veinSubheader}>{'GSV Below Knee:'}</Text>
                <View>
                  <View>
                    {RLGSVBelow && RLGSVBelow.length > 0 ? (
                      RLGSVBelow.filter(r => !!r).map((fr, index) => (
                        <View key={index} style={stylesPdf.issueText}>
                          <Text>• {fr}</Text>
                        </View>
                      ))
                    ) : (
                      <View style={stylesPdf.issueText}>
                        <Text>• Normal</Text>
                      </View>
                    )}
                  </View>
                </View>
              </View>
              {RLGSVStripped && RLGSVStripped.length > 0 ? (
                <View>
                  <Text style={stylesPdf.veinSubheader}>{'GSV Stripped:'}</Text>
                  {RLGSVStripped.length > 0 && (
                    <View>
                      <View>
                        {RLGSVStripped.filter(r => !!r).map((fr, index) => (
                          <View key={index} style={stylesPdf.issueText}>
                            <Text>• {fr}</Text>
                          </View>
                        ))}
                      </View>
                    </View>
                  )}
                </View>
              ) : (
                <></>
              )}
              {RLGSVAblated && RLGSVAblated.length > 0 ? (
                <View>
                  <Text style={stylesPdf.veinSubheader}>{'GSV Ablated:'}</Text>
                  {RLGSVAblated.length > 0 && (
                    <View>
                      <View>
                        {RLGSVAblated.filter(r => !!r).map((fr, index) => (
                          <View key={index} style={stylesPdf.issueText}>
                            <Text>• {fr}</Text>
                          </View>
                        ))}
                      </View>
                    </View>
                  )}
                </View>
              ) : (
                <></>
              )}
              {RLGSVReflux && RLGSVReflux.length > 0 ? (
                <View>
                  <Text style={stylesPdf.veinSubheader}>{'GSV Reflux:'}</Text>
                  {RLGSVReflux.length > 0 && (
                    <View>
                      <View>
                        {RLGSVReflux.filter(r => !!r).map((fr, index) => (
                          <View key={index} style={stylesPdf.issueText}>
                            <Text>• {fr}</Text>
                          </View>
                        ))}
                      </View>
                    </View>
                  )}
                </View>
              ) : (
                <></>
              )}
              {RLGSVRefluxMaxDiameter && RLGSVRefluxMaxDiameter.length > 0 ? (
                <View>
                  <Text style={stylesPdf.veinSubheader}>{'GSV Reflux Max Diameter:'}</Text>
                  {RLGSVRefluxMaxDiameter.length > 0 && (
                    <View>
                      <View>
                        {RLGSVRefluxMaxDiameter.filter(r => !!r).map((fr, index) => (
                          <View key={index} style={stylesPdf.issueText}>
                            <Text>• {fr}</Text>
                          </View>
                        ))}
                      </View>
                    </View>
                  )}
                </View>
              ) : (
                <></>
              )}

              <View>
                <Text style={stylesPdf.veinSubheader}>{'PSV:'}</Text>
                <View>
                  <View>
                    {RLPSV && RLPSV.length > 0 ? (
                      RLPSV.filter(r => !!r).map((fr, index) => (
                        <View key={index} style={stylesPdf.issueText}>
                          <Text>• {fr}</Text>
                        </View>
                      ))
                    ) : (
                      <View style={stylesPdf.issueText}>
                        <Text>• Normal</Text>
                      </View>
                    )}
                  </View>
                </View>
              </View>

              <View>
                <Text style={stylesPdf.veinSubheader}>{'Giacomini (GM) Abnormal:'}</Text>
                <View>
                  <View>
                    {RLGM && RLGM.length > 0 ? (
                      RLGM.filter(r => !!r).map((fr, index) => (
                        <View key={index} style={stylesPdf.issueText}>
                          <Text>• {fr}</Text>
                        </View>
                      ))
                    ) : (
                      <View style={stylesPdf.issueText}>
                        <Text>• None</Text>
                      </View>
                    )}
                  </View>
                </View>
              </View>

              {RLPerforators && RLPerforators.length > 0 ? (
                <View>
                  <Text style={stylesPdf.veinSubheader}>{'Incompetent Perforators:'}</Text>
                  {RLPerforators.length > 0 && (
                    <View>
                      <View>
                        {RLPerforators.filter(r => !!r).map((fr, index) => (
                          <View key={index} style={stylesPdf.issueText}>
                            <Text>• {fr}</Text>
                          </View>
                        ))}
                      </View>
                    </View>
                  )}
                </View>
              ) : (
                <></>
              )}

              {RLcomments && RLcomments.length > 0 ? (
                <View>
                  <Text style={stylesPdf.veinSubheader}>{'Tech Comments:'}</Text>
                  {RLcomments.length > 0 && (
                    <View>
                      <View>
                        {RLcomments.filter(r => !!r).map((fr, index) => (
                          <View key={index} style={stylesPdf.issueText}>
                            <Text>{fr}</Text>
                          </View>
                        ))}
                      </View>
                    </View>
                  )}
                </View>
              ) : (
                <></>
              )}
            </View>
            <View style={stylesPdf.column}>
              <View>
                <Text style={stylesPdf.veinSubheader}>{'SSV Upper:'}</Text>
                <View>
                  <View>
                    {RLSSVUpper && RLSSVUpper.length > 0 ? (
                      RLSSVUpper.filter(r => !!r).map((fr, index) => (
                        <View key={index} style={stylesPdf.issueText}>
                          <Text>• {fr}</Text>
                        </View>
                      ))
                    ) : (
                      <View style={stylesPdf.issueText}>
                        <Text>• Normal</Text>
                      </View>
                    )}
                  </View>
                </View>
              </View>

              <View>
                <Text style={stylesPdf.veinSubheader}>{'SSV Lower:'}</Text>
                <View>
                  <View>
                    {RLSSVLower && RLSSVLower.length > 0 ? (
                      RLSSVLower.filter(r => !!r).map((fr, index) => (
                        <View key={index} style={stylesPdf.issueText}>
                          <Text>• {fr}</Text>
                        </View>
                      ))
                    ) : (
                      <View style={stylesPdf.issueText}>
                        <Text>• Normal</Text>
                      </View>
                    )}
                  </View>
                </View>
              </View>

              {RLSSVRefluxMaxDiameter && RLSSVRefluxMaxDiameter.length > 0 ? (
                <View>
                  <Text style={stylesPdf.veinSubheader}>{'SSV Reflux Max Diameter:'}</Text>
                  {RLSSVRefluxMaxDiameter.length > 0 && (
                    <View>
                      <View>
                        {RLSSVRefluxMaxDiameter.filter(r => !!r).map((fr, index) => (
                          <View key={index} style={stylesPdf.issueText}>
                            <Text>• {fr}</Text>
                          </View>
                        ))}
                      </View>
                    </View>
                  )}
                </View>
              ) : (
                <></>
              )}

              {RLASV && RLASV.length > 0 ? (
                <View>
                  <Text style={stylesPdf.veinSubheader}>{'ASV:'}</Text>
                  {RLASV.length > 0 && (
                    <View>
                      <View>
                        {RLASV.filter(r => !!r).map((fr, index) => (
                          <View key={index} style={stylesPdf.issueText}>
                            <Text>• {fr}</Text>
                          </View>
                        ))}
                      </View>
                    </View>
                  )}
                </View>
              ) : (
                <></>
              )}

              {RLSPJ && RLSPJ.length > 0 ? (
                <View>
                  <Text style={stylesPdf.veinSubheader}>{'SPJ: '}</Text>
                  {RLSPJ.length > 0 && (
                    <View>
                      <View>
                        {RLSPJ.filter(r => !!r).map((fr, index) => (
                          <View key={index} style={stylesPdf.issueText}>
                            <Text>• {fr}</Text>
                          </View>
                        ))}
                      </View>
                    </View>
                  )}
                </View>
              ) : (
                <></>
              )}

              {RLSFJ && RLSFJ.length > 0 ? (
                <View>
                  <Text style={stylesPdf.veinSubheader}>{'SFJ: '}</Text>
                  {RLSFJ.length > 0 && (
                    <View>
                      <View>
                        {RLSFJ.filter(r => !!r).map((fr, index) => (
                          <View key={index} style={stylesPdf.issueText}>
                            <Text>• {fr}</Text>
                          </View>
                        ))}
                      </View>
                    </View>
                  )}
                </View>
              ) : (
                <></>
              )}
              {RLVV && RLVV.length > 0 ? (
                <View>
                  <Text style={stylesPdf.veinSubheader}>{'Visible Varicosities: '}</Text>
                  <View>
                    <View>
                      {RLVV.filter(r => !!r).map((fr, index) => (
                        <View key={index} style={stylesPdf.issueText}>
                          <Text>• {fr}</Text>
                        </View>
                      ))}
                    </View>
                  </View>
                </View>
              ) : (
                <></>
              )}
              {RLSuperficialVeinThrombosis && RLSuperficialVeinThrombosis.length > 0 ? (
                <View>
                  <Text style={stylesPdf.veinSubheader}>{'Superficial Vein Thrombosis: '}</Text>
                  <View>
                    <View>
                      {RLSuperficialVeinThrombosis.filter(r => !!r).map((fr, index) => (
                        <View key={index} style={stylesPdf.issueText}>
                          <Text>• {fr}</Text>
                        </View>
                      ))}
                    </View>
                  </View>
                </View>
              ) : (
                <></>
              )}
            </View>
          </View>
        </View>
        <PDFImage style={stylesPdf.column} src={imageSrcRight} />
      </View>
      <View break style={stylesPdf.resultsColumn}>
        <View style={stylesPdf.resultsBreakdown}>
          <Text style={stylesPdf.subheader}>Left Leg</Text>
          <View style={stylesPdf.resultsContainer}>
            <View style={stylesPdf.column}>
              {LLGSVThrombosed && LLGSVThrombosed.length > 0 ? (
                <View>
                  <Text style={stylesPdf.veinSubheader}>{'GSV Thrombosed:'}</Text>
                  {LLGSVThrombosed.length > 0 && (
                    <View>
                      <View style={stylesPdf.issue}>
                        {LLGSVThrombosed.filter(r => !!r).map((fr, index) => (
                          <View key={index} style={stylesPdf.issueText}>
                            <Text>• {fr}</Text>
                          </View>
                        ))}
                      </View>
                    </View>
                  )}
                </View>
              ) : (
                <></>
              )}
              <View>
                <Text style={stylesPdf.veinSubheader}>{'GSV Above Knee:'}</Text>
                <View>
                  <View>
                    {LLGSVAbove && LLGSVAbove.length > 0 ? (
                      LLGSVAbove.filter(r => !!r).map((fr, index) => (
                        <View key={index} style={stylesPdf.issueText}>
                          <Text>• {fr}</Text>
                        </View>
                      ))
                    ) : (
                      <View style={stylesPdf.issueText}>
                        <Text>• Normal</Text>
                      </View>
                    )}
                  </View>
                </View>
              </View>
              <View>
                <Text style={stylesPdf.veinSubheader}>{'GSV Below Knee:'}</Text>
                <View>
                  <View>
                    {LLGSVBelow && LLGSVBelow.length > 0 ? (
                      LLGSVBelow.filter(r => !!r).map((fr, index) => (
                        <View key={index} style={stylesPdf.issueText}>
                          <Text>• {fr}</Text>
                        </View>
                      ))
                    ) : (
                      <View style={stylesPdf.issueText}>
                        <Text>• Normal</Text>
                      </View>
                    )}
                  </View>
                </View>
              </View>
              {LLGSVStripped && LLGSVStripped.length > 0 ? (
                <View>
                  <Text style={stylesPdf.veinSubheader}>{'GSV Stripped:'}</Text>
                  {LLGSVStripped.length > 0 && (
                    <View>
                      <View>
                        {LLGSVStripped.filter(r => !!r).map((fr, index) => (
                          <View key={index} style={stylesPdf.issueText}>
                            <Text>• {fr}</Text>
                          </View>
                        ))}
                      </View>
                    </View>
                  )}
                </View>
              ) : (
                <></>
              )}
              {LLGSVAblated && LLGSVAblated.length > 0 ? (
                <View>
                  <Text style={stylesPdf.veinSubheader}>{'GSV Ablated:'}</Text>
                  {LLGSVAblated.length > 0 && (
                    <View>
                      <View>
                        {LLGSVAblated.filter(r => !!r).map((fr, index) => (
                          <View key={index} style={stylesPdf.issueText}>
                            <Text>• {fr}</Text>
                          </View>
                        ))}
                      </View>
                    </View>
                  )}
                </View>
              ) : (
                <></>
              )}
              {LLGSVReflux && LLGSVReflux.length > 0 ? (
                <View>
                  <Text style={stylesPdf.veinSubheader}>{'GSV Reflux:'}</Text>
                  {LLGSVReflux.length > 0 && (
                    <View>
                      <View>
                        {LLGSVReflux.filter(r => !!r).map((fr, index) => (
                          <View key={index} style={stylesPdf.issueText}>
                            <Text>• {fr}</Text>
                          </View>
                        ))}
                      </View>
                    </View>
                  )}
                </View>
              ) : (
                <></>
              )}
              {LLGSVRefluxMaxDiameter && LLGSVRefluxMaxDiameter.length > 0 ? (
                <View>
                  <Text style={stylesPdf.veinSubheader}>{'GSV Reflux Max Diameter:'}</Text>
                  {LLGSVRefluxMaxDiameter.length > 0 && (
                    <View>
                      <View>
                        {LLGSVRefluxMaxDiameter.filter(r => !!r).map((fr, index) => (
                          <View key={index} style={stylesPdf.issueText}>
                            <Text>• {fr}</Text>
                          </View>
                        ))}
                      </View>
                    </View>
                  )}
                </View>
              ) : (
                <></>
              )}

              <View>
                <Text style={stylesPdf.veinSubheader}>{'PSV:'}</Text>
                <View>
                  <View>
                    {LLPSV && LLPSV.length > 0 ? (
                      LLPSV.filter(r => !!r).map((fr, index) => (
                        <View key={index} style={stylesPdf.issueText}>
                          <Text>• {fr}</Text>
                        </View>
                      ))
                    ) : (
                      <View style={stylesPdf.issueText}>
                        <Text>• Normal</Text>
                      </View>
                    )}
                  </View>
                </View>
              </View>

              <View>
                <Text style={stylesPdf.veinSubheader}>{'Giacomini (GM) Abnormal:'}</Text>
                <View>
                  <View>
                    {LLGM && LLGM.length > 0 ? (
                      LLGM.filter(r => !!r).map((fr, index) => (
                        <View key={index} style={stylesPdf.issueText}>
                          <Text>• {fr}</Text>
                        </View>
                      ))
                    ) : (
                      <View style={stylesPdf.issueText}>
                        <Text>• None</Text>
                      </View>
                    )}
                  </View>
                </View>
              </View>

              {LLPerforators && LLPerforators.length > 0 ? (
                <View>
                  <Text style={stylesPdf.veinSubheader}>{'Incompetent Perforators:'}</Text>
                  {LLPerforators.length > 0 && (
                    <View>
                      <View>
                        {LLPerforators.filter(r => !!r).map((fr, index) => (
                          <View key={index} style={stylesPdf.issueText}>
                            <Text>• {fr}</Text>
                          </View>
                        ))}
                      </View>
                    </View>
                  )}
                </View>
              ) : (
                <></>
              )}

              {LLcomments && LLcomments.length > 0 ? (
                <View>
                  <Text style={stylesPdf.veinSubheader}>{'Tech Comments:'}</Text>
                  {LLcomments.length > 0 && (
                    <View>
                      <View>
                        {LLcomments.filter(r => !!r).map((fr, index) => (
                          <View key={index} style={stylesPdf.issueText}>
                            <Text>{fr}</Text>
                          </View>
                        ))}
                      </View>
                    </View>
                  )}
                </View>
              ) : (
                <></>
              )}
            </View>
            <View style={stylesPdf.column}>
              <View>
                <Text style={stylesPdf.veinSubheader}>{'SSV Upper:'}</Text>
                <View>
                  <View>
                    {LLSSVUpper && LLSSVUpper.length > 0 ? (
                      LLSSVUpper.filter(r => !!r).map((fr, index) => (
                        <View key={index} style={stylesPdf.issueText}>
                          <Text>• {fr}</Text>
                        </View>
                      ))
                    ) : (
                      <View style={stylesPdf.issueText}>
                        <Text>• Normal</Text>
                      </View>
                    )}
                  </View>
                </View>
              </View>

              <View>
                <Text style={stylesPdf.veinSubheader}>{'SSV Lower:'}</Text>
                <View>
                  <View>
                    {LLSSVLower && LLSSVLower.length > 0 ? (
                      LLSSVLower.filter(r => !!r).map((fr, index) => (
                        <View key={index} style={stylesPdf.issueText}>
                          <Text>• {fr}</Text>
                        </View>
                      ))
                    ) : (
                      <View style={stylesPdf.issueText}>
                        <Text>• Normal</Text>
                      </View>
                    )}
                  </View>
                </View>
              </View>

              {LLSSVRefluxMaxDiameter && LLSSVRefluxMaxDiameter.length > 0 ? (
                <View>
                  <Text style={stylesPdf.veinSubheader}>{'SSV Reflux Max Diameter:'}</Text>
                  {LLSSVRefluxMaxDiameter.length > 0 && (
                    <View>
                      <View>
                        {LLSSVRefluxMaxDiameter.filter(r => !!r).map((fr, index) => (
                          <View key={index} style={stylesPdf.issueText}>
                            <Text>• {fr}</Text>
                          </View>
                        ))}
                      </View>
                    </View>
                  )}
                </View>
              ) : (
                <></>
              )}

              {LLASV && LLASV.length > 0 ? (
                <View>
                  <Text style={stylesPdf.veinSubheader}>{'ASV:'}</Text>
                  {LLASV.length > 0 && (
                    <View>
                      <View>
                        {LLASV.filter(r => !!r).map((fr, index) => (
                          <View key={index} style={stylesPdf.issueText}>
                            <Text>• {fr}</Text>
                          </View>
                        ))}
                      </View>
                    </View>
                  )}
                </View>
              ) : (
                <></>
              )}

              {LLSPJ && LLSPJ.length > 0 ? (
                <View>
                  <Text style={stylesPdf.veinSubheader}>{'SPJ: '}</Text>
                  {LLSPJ.length > 0 && (
                    <View>
                      <View>
                        {LLSPJ.filter(r => !!r).map((fr, index) => (
                          <View key={index} style={stylesPdf.issueText}>
                            <Text>• {fr}</Text>
                          </View>
                        ))}
                      </View>
                    </View>
                  )}
                </View>
              ) : (
                <></>
              )}

              {LLSFJ && LLSFJ.length > 0 ? (
                <View>
                  <Text style={stylesPdf.veinSubheader}>{'SFJ: '}</Text>
                  {LLSFJ.length > 0 && (
                    <View>
                      <View>
                        {LLSFJ.filter(r => !!r).map((fr, index) => (
                          <View key={index} style={stylesPdf.issueText}>
                            <Text>• {fr}</Text>
                          </View>
                        ))}
                      </View>
                    </View>
                  )}
                </View>
              ) : (
                <></>
              )}

              {LLVV && LLVV.length > 0 ? (
                <View>
                  <Text style={stylesPdf.veinSubheader}>{'Visible Varicosities: '}</Text>
                  <View>
                    <View>
                      {LLVV.filter(r => !!r).map((fr, index) => (
                        <View key={index} style={stylesPdf.issueText}>
                          <Text>• {fr}</Text>
                        </View>
                      ))}
                    </View>
                  </View>
                </View>
              ) : (
                <></>
              )}

              {LLSuperficialVeinThrombosis && LLSuperficialVeinThrombosis.length > 0 ? (
                <View>
                  <Text style={stylesPdf.veinSubheader}>{'Superficial Vein Thrombosis: '}</Text>
                  <View>
                    <View>
                      {LLSuperficialVeinThrombosis.filter(r => !!r).map((fr, index) => (
                        <View key={index} style={stylesPdf.issueText}>
                          <Text>• {fr}</Text>
                        </View>
                      ))}
                    </View>
                  </View>
                </View>
              ) : (
                <></>
              )}
            </View>
          </View>
        </View>
        <PDFImage style={stylesPdf.column} src={imageSrcLeft} />
      </View>
    </View>
  );
};
