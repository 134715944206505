import Dialog from 'components/Dialog';
import IFrame from 'components/IFrame';
import React from 'react';
import styles from './PagePopup.module.scss';

interface PagePopupProps {
  open: boolean;
  url: string;
  type?: string;
  onClose: () => void;
}
function PagePopup({ open, url, type, onClose }: PagePopupProps) {
  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <div className={styles.container}>
        <IFrame url={url} type={type} title={'Form Preview'} />
      </div>
    </Dialog>
  );
}

export default PagePopup;
