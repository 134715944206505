import React, { ReactElement } from 'react';

import styles from './InfoRow.module.scss';

interface InfoRowProps {
  label?: string;
  value?: string;
}

function InfoRow({ label, value }: InfoRowProps): ReactElement {
  return (
    <div className={styles.data}>
      <div className={styles.label}>{label}:</div>
      <div className={styles.value}>{value}</div>
    </div>
  );
}

export default InfoRow;
