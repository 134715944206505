import React, { ReactElement } from 'react';
import { Page, Text, View, Document, Image as PDFImage, StyleSheet } from '@react-pdf/renderer';
import { Invoice } from './components/InvoiceRow/InvoiceRow.view';
import { PaymentRow } from './NewReceiptDialog.view';
import FullLogoTagline from 'assets/images/full-logo-tagline.png';
import { Moment } from 'moment';
import {
  receiptClinicCities,
  receiptPhoneContact,
  receiptFaxContact,
  receiptIssuedByMessage,
} from 'lib/receipt';
import { DateTimeFormatString } from 'lib/dateFormatter';
import { CanadaProvinceFullName } from 'lib/addresses';

interface ReceiptPDFRenderProps {
  invoiceRows: Array<Invoice>;
  paymentRows: Array<PaymentRow>;
  header: string;
  contents: string;
  invoiceTotal: number;
  patientName: string;
  healthCard: string;
  phoneNumber: string;
  receiptDateOfBirth?: Moment;
  receiptDate?: Moment;
  serviceDate?: Moment;
  messageToPatient: string;
  paidInFull: boolean;
  partiallyPaid: boolean;
  province: string;
  reservationFee: boolean;
  refund: boolean;
}

export const useReceiptPDFRender = ({
  invoiceRows,
  paymentRows,
  contents,
  invoiceTotal,
  patientName,
  header,
  receiptDateOfBirth,
  serviceDate,
  receiptDate,
  phoneNumber,
  messageToPatient,
  paidInFull,
  partiallyPaid,
  province,
  reservationFee,
  refund,
}: ReceiptPDFRenderProps) => {
  const stylesPdf = StyleSheet.create({
    body: {
      paddingTop: 15,
      paddingBottom: 5,
      paddingHorizontal: 35,
      alignItems: 'center',
      justifyContent: 'center',
      bottom: 50,
    },
    receiptId: {
      fontSize: 13,
      left: 250,
      bottom: 40,
    },
    headerImage: {
      width: '60vw',
      objectFit: 'cover',
    },
    clinicCities: {
      fontSize: 8,
      paddingTop: 6,
      paddingBottom: 6,
    },
    subHeader: {
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: 15,
      paddingBottom: 2,
      flexWrap: 'wrap',
      maxWidth: 350,
    },
    header: {
      fontSize: 18,
      textTransform: 'uppercase',
    },
    title: {
      fontSize: 14,
      paddingBottom: 4,
    },
    receiptFor: {
      fontSize: 12,
    },
    seperator: {
      border: 1,
      width: '75vw',
    },
    seperatorLight: {
      border: 0.5,
      borderColor: 'light-grey',
      width: '75vw',
    },
    contactInfo: {
      fontSize: 14,
      textTransform: 'capitalize',
      paddingBottom: 35,
    },
    patientReceiptRow: {
      flexDirection: 'row',
      right: '11%',
    },
    patientReceiptInfo: {
      textTransform: 'uppercase',
    },
    patientInfoTextRow: {
      width: '50%',
      marginBottom: 5,
      fontSize: 11,
    },
    patientInfoServiceDateRow: {
      paddingTop: 8,
      marginBottom: 5,
      width: '50%',
      fontSize: 11,
    },
    patientInfoReceiptDateRow: {
      paddingBottom: 15,
      width: '50%',
      fontSize: 11,
    },
    tableContainer: {
      top: 30,
    },
    receiptTable: {
      flexDirection: 'row',
      left: '7%',
      width: '80vw',
    },
    invoiceLineHeader: {
      width: '80%',
      border: 0.5,
      padding: 10,
      fontSize: 14,
      textTransform: 'capitalize',
    },
    invoiceAmountHeader: {
      width: '20%',
      border: 0.5,
      padding: 10,
      fontSize: 14,
    },
    invoiceLine: {
      width: '80%',
      border: 0.5,
      padding: 10,
      fontSize: 12,
    },
    invoiceAmount: {
      width: '20%',
      border: 0.5,
      padding: 10,
      fontSize: 12,
    },
    paymentLine: {
      marginTop: 90,
      paddingBottom: 20,
    },
    paymentMessage: {
      fontSize: 21,
    },
    paymentMessageText: {
      textTransform: 'uppercase',
      fontFamily: 'Times-Italic',
      fontWeight: 'bold',
    },
    patientMessage: {
      marginTop: 30,
      fontSize: 14,
    },
    receiptMessageText: {
      fontSize: 10,
      marginTop: 23,
    },
    paymentMethod: {
      fontSize: 16,
      marginBottom: 5,
    },
    receiptPaymentMethod: {
      fontSize: 14,
      marginBottom: 2,
    },
    provinceText: {
      marginTop: 25,
      fontSize: 14,
    },
    reservationFee: {
      marginBottom: 10,
      textAlign: 'center',
      width: '75vw',
    },
  });
  const cities = receiptClinicCities.join(' - ');

  const ReceiptLayout = (id): ReactElement => {
    return (
      <Document>
        <Page style={stylesPdf.body}>
          <Text style={stylesPdf.receiptId}>ID: {id}</Text>
          <View style={stylesPdf.headerImage}>
            <PDFImage src={FullLogoTagline}></PDFImage>
          </View>
          <View style={stylesPdf.clinicCities}>
            <Text> {cities} </Text>
          </View>
          <View style={stylesPdf.subHeader}>
            <Text style={stylesPdf.header}>{header}</Text>
          </View>
          <View style={stylesPdf.subHeader}>
            <Text style={stylesPdf.receiptFor}>{`${refund ? 'Refund' : 'Receipt'} for:`}</Text>
            <Text style={stylesPdf.title}>{contents}</Text>
            <hr style={stylesPdf.seperator}></hr>
          </View>
          <Text style={stylesPdf.contactInfo}>
            Phone: {receiptPhoneContact}, Fax: {receiptFaxContact}
          </Text>
          <View style={stylesPdf.patientReceiptRow}>
            <Text style={stylesPdf.patientInfoTextRow}>
              <div style={stylesPdf.patientReceiptInfo}>Name: </div>
              {patientName}
            </Text>
          </View>
          <View style={stylesPdf.patientReceiptRow}>
            <Text style={stylesPdf.patientInfoTextRow}>
              <div style={stylesPdf.patientReceiptInfo}>Date of Birth: </div>
              {receiptDateOfBirth?.format(DateTimeFormatString.DayFullMonthYear)}
            </Text>
          </View>
          <View style={stylesPdf.patientReceiptRow}>
            <Text style={stylesPdf.patientInfoTextRow}>
              <div style={stylesPdf.patientReceiptInfo}>Phone Number: </div>
              {phoneNumber}
            </Text>
          </View>
          <View style={stylesPdf.patientReceiptRow}>
            <Text style={stylesPdf.patientInfoServiceDateRow}>
              <div style={stylesPdf.patientReceiptInfo}>Date of Service: </div>
              {serviceDate?.format(DateTimeFormatString.DayFullMonthYear)}
            </Text>
          </View>
          <View style={stylesPdf.patientReceiptRow}>
            <Text style={stylesPdf.patientInfoReceiptDateRow}>
              <div style={stylesPdf.patientReceiptInfo}>{`Date of ${
                refund ? 'Refund' : 'Receipt'
              }: `}</div>
              {receiptDate?.format(DateTimeFormatString.DayFullMonthYear)}
            </Text>
          </View>
          <Text style={stylesPdf.patientMessage}>{messageToPatient}</Text>
          <hr style={stylesPdf.seperatorLight}></hr>
          <View style={stylesPdf.tableContainer}>
            {invoiceRows
              .filter(invoiceRow => invoiceRow.invoiceLine != '' || invoiceRow.invoiceAmount != 0)
              .map(invoiceRow => {
                return (
                  <View style={stylesPdf.receiptTable} key={invoiceRow.invoiceId}>
                    <Text style={stylesPdf.invoiceLine}>{invoiceRow.invoiceLine}</Text>
                    <Text style={stylesPdf.invoiceAmount}>${invoiceRow.invoiceAmount}</Text>
                  </View>
                );
              })}
            <View style={stylesPdf.receiptTable}>
              <Text style={stylesPdf.invoiceLineHeader}>{`Total ${
                refund ? 'Refund' : 'Amount'
              }:`}</Text>
              <Text style={stylesPdf.invoiceAmountHeader}>${invoiceTotal}</Text>
            </View>
          </View>
          <View style={stylesPdf.paymentLine}>
            {paidInFull && !refund && (
              <Text style={stylesPdf.paymentMessage}>
                <div style={stylesPdf.paymentMessageText}>Paid in full</div>
              </Text>
            )}
            {partiallyPaid && !refund && (
              <Text style={stylesPdf.paymentMessage}>
                <div style={stylesPdf.paymentMessageText}>Partially paid</div>
              </Text>
            )}
          </View>
          {reservationFee && (
            <View style={stylesPdf.reservationFee}>
              <Text style={stylesPdf.receiptMessageText}>
                This Reservation fee is nonrefundable.You agree that, in the event of cancellation
                by you, such Reservation Fee shall be forfeited by you and paid to Canada Vein
                Clinics, as liquidated damages and not as a penalty. You agree with us that such
                amount is a reasonable estimate of the damages incurred by Canada Vein Clinics.
              </Text>
            </View>
          )}
          {invoiceTotal !== 0 && (
            <Text style={stylesPdf.paymentMethod}>{`${
              refund ? 'Refund To' : 'Payment Method(s)'
            }`}</Text>
          )}
          {invoiceTotal !== 0 &&
            paymentRows?.map(paymentRow => {
              return (
                <Text style={stylesPdf.receiptPaymentMethod} key={paymentRow.paymentId}>
                  {`${paymentRow.paymentMethod}: $${paymentRow.amount}`}
                </Text>
              );
            })}
          <Text style={stylesPdf.receiptMessageText}>{receiptIssuedByMessage}</Text>
          <Text style={stylesPdf.provinceText}> {CanadaProvinceFullName[province]} </Text>
        </Page>
      </Document>
    );
  };
  return {
    ReceiptLayout,
  };
};

export default useReceiptPDFRender;
