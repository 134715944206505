import React, { ReactElement, ReactNode } from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MuiSelect from '@material-ui/core/Select';

interface SelectProps {
  children: ReactNode;
  name?: string;
  fullWidth?: boolean;
  displayEmpty?: boolean;
  disabled?: boolean;
  [rest: string]: any;
}

function Select({
  children,
  name,
  label,
  fullWidth,
  disabled,
  displayEmpty,
  ...rest
}: SelectProps): ReactElement {
  return (
    <FormControl fullWidth={fullWidth} disabled={disabled}>
      <InputLabel id={`select-${label}`} shrink={displayEmpty}>
        {label}
      </InputLabel>
      <MuiSelect {...rest} labelId={`select-${label}`} name={name} displayEmpty={displayEmpty}>
        {children}
      </MuiSelect>
    </FormControl>
  );
}

export default Select;
