import { TemplateType, getTemplateTypes } from 'api/template';
import { useEffect, useState } from 'react';

export interface TemplateApiHook {
  templateTypes: TemplateType[];
}

function useTemplatesApi(): TemplateApiHook {
  const [templateTypes, setTemplateTypes] = useState<TemplateType[]>([]);

  useEffect(() => {
    getTemplateTypes().then(types => {
      setTemplateTypes(types);
    });
  }, []);

  return {
    templateTypes,
  };
}

export default useTemplatesApi;
