import {
  sendMultipleDocuments as sendMultipleFaxDocumentsAPI,
  sendDefaultDocument as sendDefaultDocumentAPI,
} from 'api/fax';
import { Document } from 'types';
import * as logger from 'api/logger';

export interface FaxState {
  sendMultipleFaxDocuments: (
    documentIds: (string | number)[],
    startingId: number,
    faxNumber: string | undefined,
    generic?: boolean
  ) => void;
  sendFaxDefaultDocument: (
    document: Document,
    faxId: number,
    faxNumber: string | undefined,
    generic?: boolean
  ) => void;
}

function useFaxApi(): FaxState {
  async function sendMultipleFaxDocuments(
    documentIds,
    startingId,
    faxNumber,
    generic = false
  ): Promise<void> {
    try {
      return await sendMultipleFaxDocumentsAPI(documentIds, startingId, faxNumber, generic);
    } catch (e) {
      logger.error(e.message);
      return Promise.reject(e);
    }
  }

  async function sendFaxDefaultDocument(
    document,
    faxId,
    faxNumber,
    generic = false
  ): Promise<void> {
    try {
      return await sendDefaultDocumentAPI(document, faxId, faxNumber, generic);
    } catch (e) {
      logger.error(e.message);
      throw e;
    }
  }

  return {
    sendMultipleFaxDocuments,
    sendFaxDefaultDocument,
  };
}

export default useFaxApi;
