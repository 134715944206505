import { useState, useEffect } from 'react';
import { CaseResult } from 'types';

import { getCaseResultById } from 'api/case';
import { getForm } from 'api/form';
import * as logger from 'api/logger';

export interface WorkflowResultApi {
  loading: boolean;
  formCaseResult: CaseResult | null;
}

function useWorkflowResultApi(formId, caseId): WorkflowResultApi {
  const [loading, setLoading] = useState(true);
  const [formCaseResult, setFormCaseResult] = useState<CaseResult | null>(null);

  async function fetchWorkflowResults(formId: number): Promise<void> {
    try {
      let foundCaseId = caseId;

      if (!caseId) {
        const form = await getForm(formId);
        foundCaseId = form.case_id;
      }

      const caseResults = await getCaseResultById(foundCaseId);
      setFormCaseResult(caseResults);
    } catch (e) {
      logger.error(e.message);
    }
  }

  useEffect(() => {
    setLoading(true);
    fetchWorkflowResults(formId).then(() => setLoading(false));
  }, [formId]);

  return {
    loading,
    formCaseResult,
  };
}

export default useWorkflowResultApi;
