import React, { ReactElement, useEffect, useState } from 'react';

import ConditionDiv from 'components/ConditionDiv';
import TitledSection from 'components/TitledSection';
import Button from 'components/Button';
import { formatDate, DateTimeFormat } from 'lib/dateFormatter';

import Form from './components/Form';
import SendFormDialog from './components/SendFormDialog';
import useFormsApi from './useFormsApi.hook';
import { FormStatus, FormStatusTypesSelection } from 'lib/form';

import styles from './FormsStyles.module.scss';
import CopyFormUrlDialog from './components/CopyFormUrlDialog';
import CreateNewSetOfFormsConfirmationDialog from '../../components/CreateNewSetOfFormsConfirmationDialog/CreateNewSetOfFormsConfirmationDialog.view';
import SuccessSnackBar from 'components/SuccessSnackBar';
import { AlertDialog } from 'components/AlertDialog';
import InputSearch from 'pages/Staff/components/InputSearch';
import { MenuItem } from '@material-ui/core';
import Select from 'components/Select';
import { FormTemplate, Form as FormType, UploadTemplateFormInput } from 'types';
import NewDefaultFormDialog from './components/NewDefaultFormDialog';
import ActionConfirmationDialog from 'components/ActionConfirmationDialog';
import useProfileRoles from 'hook/useProfileRoles.hook';

function createInfoString(date: number | string | undefined, status): string {
  const formattedDate = formatDate(DateTimeFormat.DateWithFullMonthName, date);
  if (status === FormStatus.Complete || status === FormStatus.SentToPatient) {
    return `${status}: ${formattedDate}`;
  }
  return status;
}

function Forms(props): ReactElement {
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const [copyFormUrlDialogOpen, setCopyFormUrlDialogOpen] = useState<boolean>(false);
  const [newDefaultFormDialogOpen, setNewDefaultFormDialogOpen] = useState<boolean>(false);
  const [newDefaultFormLoading, setNewDefaultFormLoading] = useState<boolean>(false);
  const [deleteFormDialogLoading, setDeleteFormDialogLoading] = useState<boolean>(false);

  const patientId = props?.match?.params?.patientId;

  const [searchQuery, setSearchQuery] = useState<string | undefined>('');

  const [filteredForms, setFilteredForms] = useState<Array<FormType>>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<number>(0);
  const [selectedStatus, setSelectedStatus] = useState<number>(0);
  const [templatesList, setTemplatesList] = useState<Array<FormTemplate>>([]);
  const [createNewDefaultFormError, setCreateNewDefaultFormError] = useState<string>();
  const [formToDelete, setFormToDelete] = useState<FormType>();
  const [showDeleteFormConfirmationDialog, setShowDeleteFormConfirmationDialog] = useState<boolean>(
    false
  );
  const [deleteFormError, setDeleteFormError] = useState<string>();
  const { isAdmin } = useProfileRoles();

  const {
    forms,
    fetchForms,
    formsReadyToSend,
    loading,
    setLoading,
    sendForm,
    caseId,
    errorMessage,
    getNewSetsOfForms,
    getNewCopyOfForm,
    setActionFailed,
    setActionSuccess,
    setSuccessSnackbarMessage,
    successSnackbarMessage,
    actionFailed,
    actionSuccess,
    createNewDefaultForm,
    deleteFormTemplate,
  } = useFormsApi(patientId);

  function createNewForms(): void {
    getNewSetsOfForms(caseId);
  }

  const duplicateForm = formId => {
    getNewCopyOfForm(formId);
  };

  const [sendFormDialogOpen, setSendFormDialogOpen] = useState(false);

  function showConfirmDialogBox(): void {
    setShowConfirmDialog(true);
  }

  function onConfirm(): void {
    createNewForms();
    setShowConfirmDialog(false);
  }

  function closeConfirmDialogBox(): void {
    setShowConfirmDialog(false);
  }

  function closeCopyFormUrlDialog(): void {
    setCopyFormUrlDialogOpen(false);
  }
  function mainFormsCompleted(forms): boolean {
    if (!forms) {
      return false;
    }
    const mainForms = forms.filter(form => {
      if (form.key === 'Doctor' || form.key === 'Patient' || form.key === 'Tech') {
        return form;
      }
    });

    if (!mainForms) {
      return false;
    }
    return mainForms.every(form => {
      return FormStatus.Complete === form.status;
    });
  }

  function reviewReport(): void {
    props?.history?.push(`/case/${caseId}/workflowResult`, {
      patientId: patientId,
      everyFormCompleted: mainFormsCompleted(forms),
    });
  }

  function getTemplateList() {
    const types: Array<FormTemplate> = [{ id: 0, title: 'All Types' }];
    forms.forEach(form => {
      if (!types.find(type => type.id == form.template_type_id)) {
        types.push({ id: form.template_type_id, title: form.type });
      }
    });
    setTemplatesList(types);
  }

  useEffect(() => {
    setFilteredForms(forms);
    getTemplateList();
  }, [forms]);

  async function onTemplateChange(e) {
    setSelectedTemplate(e?.target?.value);
  }

  async function onStatusChange(e) {
    setSelectedStatus(e?.target?.value);
  }
  useEffect(() => {
    const filterStatus = selectedStatus > 0;
    const filterTemplate = selectedTemplate > 0;
    const filterSearch = searchQuery && searchQuery.length >= 2 ? searchQuery : '';

    const filteredForms = forms.filter(form => {
      const fStatus = !filterStatus || selectedStatus == form.status_id;
      const fTemplate = !filterTemplate || selectedTemplate == form.template_type_id;
      const fSearch =
        !filterSearch ||
        (searchQuery &&
          form.description &&
          form.description.toLowerCase().includes(searchQuery.toLowerCase()));

      return fStatus && fTemplate && fSearch;
    });
    setFilteredForms(filteredForms);
  }, [selectedStatus, selectedTemplate, searchQuery]);

  const headContent = (
    <div>
      <div className={styles.formListButtonContainer}>
        <div className={styles.searchBar}>
          <InputSearch value={searchQuery} onChange={event => setSearchQuery(event.target.value)} />
        </div>
        <div className={styles.folder}>
          <Select fullWidth name={'template'} onChange={onTemplateChange} value={selectedTemplate}>
            {templatesList?.map(item => (
              <MenuItem key={item.id} value={item.id} className={styles.selectItem}>
                {item.title}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className={styles.folder}>
          <Select fullWidth name={'status'} onChange={onStatusChange} value={selectedStatus}>
            {FormStatusTypesSelection.map(item => (
              <MenuItem key={item.id} value={item.id} className={styles.selectItem}>
                {item.title}
              </MenuItem>
            ))}
          </Select>
        </div>
        {isAdmin && (
          <div className={styles.button}>
            <Button onClick={(): void => setNewDefaultFormDialogOpen(true)}>
              New Default Form
            </Button>
          </div>
        )}

        <ConditionDiv condition={mainFormsCompleted(forms)}>
          <div className={styles.button}>
            <Button onClick={showConfirmDialogBox}>Create New Forms</Button>
          </div>
        </ConditionDiv>
        <div className={styles.button}>
          <Button onClick={reviewReport}>Review Report</Button>
        </div>
        <div className={styles.button}>
          <Button onClick={(): void => setCopyFormUrlDialogOpen(true)}>Copy Form URL</Button>
        </div>
        <div className={styles.button}>
          <Button onClick={(): void => setSendFormDialogOpen(true)}>Send form</Button>
        </div>
      </div>
    </div>
  );

  function onSendForm(formId: number): void {
    const form = forms.find(form => form.id === formId);
    if (form && form.document_url) {
      props?.history?.push(`/formDocument/${formId}`, {
        patientId: patientId,
        caseId: caseId,
        sendToPatient: true,
      });
      setSendFormDialogOpen(false);
    } else {
      sendForm(formId).then(() => {
        setActionSuccess(true);
        setSendFormDialogOpen(false);
      });
    }
  }

  function onConfirmCreateNewDefaultForm(input: UploadTemplateFormInput): void {
    setCreateNewDefaultFormError(undefined);
    setNewDefaultFormLoading(true);
    createNewDefaultForm(input)
      .then(() => {
        setNewDefaultFormLoading(false);
        setNewDefaultFormDialogOpen(false);
        setLoading(true);
        fetchForms(patientId)
          .then(() => setLoading(false))
          .catch(() => setLoading(false));
      })
      .catch(error => {
        setCreateNewDefaultFormError('An error occurred. Please try again');
        setNewDefaultFormLoading(false);
      });
  }

  function onClickDeleteForm(id: string | number | undefined): void {
    const selectedFormToDelete = filteredForms.find(filteredForm => filteredForm.id === id);
    setFormToDelete(selectedFormToDelete);
    setShowDeleteFormConfirmationDialog(true);
  }

  function confirmDeleteForm(): void {
    if (formToDelete && formToDelete.template_id) {
      setDeleteFormDialogLoading(true);
      deleteFormTemplate(formToDelete.template_id)
        .then(() => {
          setShowDeleteFormConfirmationDialog(false);
          fetchForms(patientId)
            .then(() => setDeleteFormDialogLoading(false))
            .catch(() => setDeleteFormDialogLoading(false));
        })
        .catch(error => {
          setDeleteFormDialogLoading(false);
          setDeleteFormError('An error occured. Please try again');
        });
    }
  }

  return (
    <TitledSection title="Forms" headContent={headContent}>
      <ConditionDiv condition={!!forms.length}>
        {filteredForms.map(form => (
          <Form
            id={form.id}
            key={`form-${form.id}`}
            className={styles.form}
            title={form.description}
            info={createInfoString(form.completed_at, form.status)}
            statusId={form.status_id}
            createdAt={formatDate(DateTimeFormat.DateWithFullMonthName, form.created_at)}
            onClick={(): void => {
              if (!!form.can_read && !!form.can_write) {
                props?.history?.push(
                  `${form.document_url ? '/formDocument/' : '/form/'}${form.id}`,
                  {
                    patientId: patientId,
                    caseId: caseId,
                    sendToPatient: false,
                  }
                );
              }
            }}
            duplicateForm={duplicateForm}
            onClickDeleteForm={onClickDeleteForm}
            showDeleteOption={!!form.document_url && !!form.can_delete}
          />
        ))}
      </ConditionDiv>
      <ConditionDiv condition={!forms.length}>Currently no forms</ConditionDiv>
      <SendFormDialog
        forms={formsReadyToSend}
        loading={loading}
        open={sendFormDialogOpen}
        onSend={onSendForm}
        onClose={(): void => setSendFormDialogOpen(false)}
      />
      <CreateNewSetOfFormsConfirmationDialog
        show={showConfirmDialog}
        onClose={closeConfirmDialogBox}
        onConfirm={onConfirm}
        reviewReport={reviewReport}
      />
      <CopyFormUrlDialog
        open={copyFormUrlDialogOpen}
        forms={formsReadyToSend}
        patientId={patientId}
        onClose={closeCopyFormUrlDialog}
        loading={loading}
      />
      <SuccessSnackBar
        message={successSnackbarMessage}
        open={actionSuccess}
        setOpen={setActionSuccess}
      />
      <AlertDialog
        title={'Error Creating New Form'}
        message={errorMessage}
        open={actionFailed}
        onClose={() => setActionFailed(false)}
      />
      <NewDefaultFormDialog
        show={newDefaultFormDialogOpen}
        loading={newDefaultFormLoading}
        onClose={(): void => setNewDefaultFormDialogOpen(false)}
        onConfirm={onConfirmCreateNewDefaultForm}
        error={createNewDefaultFormError}
      />
      {formToDelete !== undefined && (
        <ActionConfirmationDialog
          open={showDeleteFormConfirmationDialog}
          title={`Delete Form ${formToDelete.description || ''}`}
          description={'Are you sure you want to delete this form for ALL patients in the system?'}
          onClose={(): void => setShowDeleteFormConfirmationDialog(false)}
          onConfirm={confirmDeleteForm}
          error={deleteFormError}
          loading={deleteFormDialogLoading}
        />
      )}
    </TitledSection>
  );
}

export default Forms;
