import React, { useEffect, useState } from 'react';
import { stylesPdf } from 'lib/workflowPdf';
import { Page, Text, View, Document, PDFDownloadLink } from '@react-pdf/renderer';
import Dialog from 'components/Dialog';
import useWorkflowPDF from 'pages/WorkflowResult/components/WorkflowPDF/useWorkflowPDF.hook';
import DialogButton from 'components/DialogButton';
import styles from './TechnicianResultsPDFStyles.module.scss';
import {
  PatientMetaLabels,
  parseText,
} from 'pages/WorkflowResult/components/PatientResults/lib/calculatePatientResults';
import { TechResultPDFFragment } from 'pages/WorkflowResult/components/TechResultPDFFragment';

interface WorkflowPDFComponentProps {
  isOpen: boolean;
  onClose: () => void;
  caseId?: number;
  formId?: number;
  patientId?: number;
  paragraphTexts?;
  coverLetterHeaderFooter?;
  history?;
}

export const TechnicianResultsPDF = ({
  isOpen,
  onClose,
  formId,
  caseId,
  patientId,
}: WorkflowPDFComponentProps) => {
  const [pdfTechnicianResultsURL, setPDFTechnicianResultsURL] = useState('');
  const [generatedUrl, setGeneratedUrl] = useState('');
  const [urlLoading, setURLLoading] = useState(false);
  // Images are loading from the moment the component is loaded
  const [techImagesLoading, setTechImagesLoading] = useState(true);
  // Ensures the user only saves once
  const [savingInProgress, setSavingInProgress] = useState(false);

  const { patientFormResults, saveTechnicianResultsDocument } = useWorkflowPDF({
    pdfTechnicianResultsURL,
    patientId,
    formId,
    caseId,
  });

  const labelKeysPatientMeta = Array.from(PatientMetaLabels.keys());

  const TechnicianResultsDocument = (
    <Document>
      <Page size="A4" style={stylesPdf.page}>
        <View>
          <View>
            <View style={stylesPdf.header}>
              <Text>Patient Data</Text>
            </View>
            <View>
              <Text>Basic Info</Text>
              <View>
                {patientFormResults &&
                  labelKeysPatientMeta.map(pmk => {
                    return (
                      <View key={patientFormResults.get(pmk) && patientFormResults.get(pmk).id}>
                        {patientFormResults.get(pmk) && (
                          <Text style={stylesPdf.infoMeta}>
                            {PatientMetaLabels.get(pmk)}: {parseText(patientFormResults.get(pmk))}
                          </Text>
                        )}
                      </View>
                    );
                  })}
              </View>
            </View>
          </View>
          <TechResultPDFFragment
            caseId={caseId}
            formId={formId}
            setLoading={setTechImagesLoading}
          ></TechResultPDFFragment>
        </View>
      </Page>
    </Document>
  );

  async function onSave() {
    // Since we rediirect after save we don't need to change this state after it starts
    setSavingInProgress(true);
    await saveTechnicianResultsDocument();
    onClose();
  }

  useEffect(() => {
    // Debounce FN since the genrated url may update a few times until it is finally ready
    const delayDebounceFn = setTimeout(() => {
      /*
            Tech images not loading
            & Url For pdf generator not loading
            & the pdf generator has a url
            & the final URL has not been set yet
        */
      if (!techImagesLoading && !urlLoading && !!generatedUrl && !pdfTechnicianResultsURL) {
        setPDFTechnicianResultsURL(generatedUrl);
        setURLLoading(false);
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [generatedUrl]);

  return (
    <div>
      <Dialog open={isOpen} onClose={onClose} title={'Completed Technician Form'}>
        <div className={styles.container}>
          <p className={styles.text}>
            The Technician Document is being generated and will be available under the Patient
            Documents
          </p>
          <div className={styles.buttons}>
            <DialogButton disabled={savingInProgress || !pdfTechnicianResultsURL} onClick={onSave}>
              {!pdfTechnicianResultsURL || savingInProgress ? 'Loading...' : 'Ok'}
            </DialogButton>
          </div>
        </div>
      </Dialog>
      <PDFDownloadLink document={TechnicianResultsDocument}>
        {({ blob, url, loading, error }) => {
          setURLLoading(loading);
          if (url) {
            setGeneratedUrl(url);
          }
          return <div></div>;
        }}
      </PDFDownloadLink>
    </div>
  );
};
