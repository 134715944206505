import React, { ReactElement, useState, Fragment } from 'react';
import { Motion } from 'react-motion';
import styles from './TextCollapse.module.scss';

interface TextCollapseProps {
  description: string;
}

function TextCollapse({ description }: TextCollapseProps): ReactElement {
  const [collapse, setCollapse] = useState(true);

  function RenderCollapseButton(): ReactElement {
    return (
      <Fragment>
        {collapse ? (
          <div className={styles.collapseBtn}>...read more</div>
        ) : (
          <div className={styles.collapseBtn}>read less</div>
        )}
      </Fragment>
    );
  }

  function toggleAction() {
    setCollapse(!collapse);
  }
  const descriptionLength = description ? description.split('').length : 0;
  const overflowLimit = 45;

  return (
    <Motion defaultStyle={{ h: 20 }}>
      {({ h }) => (
        <Fragment>
          <div
            style={{
              display: `block`,
              overflow: collapse ? `hidden` : `auto`,
              height: collapse ? `${h}` + 'px' : 'auto',
            }}
          >
            {description}
          </div>
          <div
            onClick={toggleAction}
            style={{ visibility: descriptionLength >= overflowLimit ? 'visible' : 'hidden' }}
          >
            <RenderCollapseButton />
          </div>
        </Fragment>
      )}
    </Motion>
  );
}

export default TextCollapse;
