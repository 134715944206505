import React, { ReactElement } from 'react';
import moment from 'moment';

import CVCDatePicker from 'components/CVCDatePicker';
import styles from './QuestionSectionStyles.module.scss';
import classNames from 'classnames';

interface DateQuestionSectionProps {
  title?: string;
  value: Array<any>;
  required?: boolean;
  defaultValue?: Array<any>;
  onChange?(value): void;
}

function DateQuestionSection({
  title,
  value = [],
  defaultValue = [],
  required,
  onChange = () => null,
}: DateQuestionSectionProps): ReactElement {
  function handleChange(value): void {
    onChange([
      {
        ...(defaultValue[0]?.id && { id: defaultValue[0]?.id }),
        value: value,
      },
    ]);
  }

  const curDefaultValue = defaultValue[0]?.value;
  const curValue = value[0]?.value;
  const finalValue = value[0]?.value || defaultValue[0]?.value;

  return (
    <div className={styles.main}>
      <div className={styles.title}>
        {title} {required ? <span className={styles.reqMarker}>*</span> : null}
      </div>
      <div className={styles.answerContainer}>
        <div className={classNames(styles.answer, styles.open)}>
          <CVCDatePicker onChange={handleChange} value={moment(finalValue)} />
        </div>
      </div>
    </div>
  );
}

export default DateQuestionSection;
