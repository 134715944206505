import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const StyledButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: '18px',
    fontWeight: 'bold',
    border: '1px solid',
    backgroundColor: '#white',
    borderColor: '#4052B6;',
    color: '#4052B6',
    padding: '0px 15px',
    lineHeight: '35px',
    height: '35px',
    maxHeight: '35px',
    whiteSpace: 'nowrap',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      backgroundColor: '#4052B6',
      color: 'white',
      borderColor: '#4052B6',
      boxShadow: 'none',
    },
    '&:active': {
      // boxShadow: 'none',
      // backgroundColor: '#0062cc',
      // borderColor: '#005cbf',
    },
    '&:focus': {
      // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
})(Button);

export default StyledButton;
