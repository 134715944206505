import moment from 'moment';
import { DateTimeFormatString } from 'lib/dateFormatter';

export const PatientMetaLabels = new Map<string, string>([
  ['PatientMetaData::fullName', 'Name'],
  ['PatientMetaData::gender', 'Gender'],
  ['PatientMetaData::healthCard', 'Health Card'],
  ['PatientMetaData::dateOfBirth', 'DOB'],
]);

export const PatientLabels = new Map<string, string>([
  ['Patient::howHeardAboutCVC', 'How They Heard About CVC'],
  ['Patient::MedicalProfessionalWhoReferred', 'Medical Professional Who Referred Them'],
  ['Patient::healthCard', 'Health Card'],
  ['Patient::dateOfBirth', 'DOB'],
  ['Patient::FriendWhoReferred', 'Friend Who Referred Them'],
  ['Patient::MainConcern', 'Main Concern'],
  ['Patient::whichLegPatient', 'Leg Affected'],
  ['Patient::HowLongHadHadSymptoms', 'Length of Time Had Symptoms'],
  ['Patient::WhichSymptoms', 'Symptoms'],
  ['Patient::OtherSymptoms', 'Other Symptoms'],
  ['Patient::AreSymptomsWorse', 'Are Symptoms Worse at the end of the day?'],
  ['Patient::TriedCompressionStockings', 'Tried compression stockings'],
  ['Patient::DidCompressionStockingsHelp', 'Compression stockings helped'],
  ['Patient::FamilyHistoryVaricoseVeins', 'Family history of Varicose veins'],
  ['Patient::WhoInFamilyHasVaricoseVeins', 'Who in their family has Varicose veins'],
  ['Patient::KnownAllergies', 'Has Known Allergies'],
  ['Patient::AllergicTo', 'Allergies'],
  ['Patient::allergyOtherSpecify', 'Other Allergies'],
  ['Patient::DiagnosedWithBloodClots', 'Has been diagnosed with blood clots'],
  ['Patient::WhereWereBloodClots', 'Where Blood Clots were'],
  ['Patient::WhenDidYouHaveTheBloodClots', 'When did they have the blood clots?'],
  ['Patient::PreviouslyBeenTreatedByDoctor', 'Has been previously treated by a doctor'],
  ['Patient::NameOfVeignTreatments', 'Knows treatments'],
  ['Patient::Treatments', 'Name of Vein treatments'],
  ['Patient::YearOfSclerotherapy', 'Years of Schlerotherapy'],
  ['Patient::YearOfStripping', 'Years of Stripping'],
  ['Patient::YearOfLigation', 'Years of Ligation'],
  ['Patient::YearOfLaserAblation', 'Years of laser Ablation'],
  ['Patient::YearOfClarivein', 'Years of Clarivein'],
  ['Patient::YearOfVenaseal', 'Years of Venaseal'],
  ['Patient::YearOfFoamSclerotherapy', 'Year of Foam Sclerotherapy'],
  ['Patient::MedicalHistory', 'Medical History'],
  ['Patient::BleedingDisorder', 'Has Bleeding Disorder'],
  ['Patient::BleedingDisorderName', 'Bleeding Disorder Name'],
  ['Patient::BloodThinner', 'Uses Blood Thinner'],
  ['Patient::WhichBloodThinner', 'Blood Thinners'],
  ['Patient::MedicalHistoryOther', 'Other Medical History'],
  ['Patient::FamilyPhysicianFirstName', 'Family Physician'],
  ['Patient::FamilyPhysicianLastName', 'Family Physician'],
]);

export const listText = entries => {
  if (!entries) {
    return '';
  }

  return entries.length > 1
    ? `${entries.slice(0, entries.length - 1).join(', ')} and ${entries[entries.length - 1]}`
    : `${entries.join(', ')}`;
};

export function calculatePatientResultsMap(answers) {
  const resultMap = new Map();

  answers &&
    answers.forEach(m => {
      resultMap.set(m.id, m);
    });

  return resultMap;
}

export const parseText = answer => {
  if (!answer || !answer.value) {
    return '';
  }

  if (answer.valueType === 'date' || answer.valueType == 'Date') {
    const answerDate = moment(answer.value);

    // Try to parse to date. if succeed, display as date.
    if (answerDate.isValid()) {
      return answerDate.format(DateTimeFormatString.DayMonthYear);
    }
  }

  const isArray = Array.isArray(answer.value);

  if (isArray) {
    const arrayEmptyValuesRemoved = answer.value.filter(entry => !!entry);
    return listText(arrayEmptyValuesRemoved);
  }

  return answer.value;
};
