import { getUserTypes } from 'api/user';
import { UserType } from 'api/user/user.interfaces';
import { useEffect, useState } from 'react';

export interface UserTypesApiHook {
  userTypes: UserType[];
}

function useUserTypesApi(): UserTypesApiHook {
  const [userTypes, setUserTypes] = useState<UserType[]>([]);

  useEffect(() => {
    getUserTypes().then(types => {
      setUserTypes(types);
    });
  }, []);

  return {
    userTypes,
  };
}

export default useUserTypesApi;
