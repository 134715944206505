import React, { ReactElement, useContext, useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import { useFormik } from 'formik';

import { TokenBody } from 'types';

import { login, getUser } from 'api/user';
import { Tokens } from 'api/user/user.interfaces';

import { ROLE } from 'lib/user';
import { Context as AuthContext } from 'context/auth';
import { Context as ProfileContext } from 'context/profile';

import Login from './Login.view';

import loginValidator from './utils/Login.validator';

function LoginContainer(props: any): ReactElement {
  const authContext = useContext(AuthContext);
  const profileContext = useContext(ProfileContext);

  useEffect(() => {
    if (authContext.role !== ROLE.ANONYMOUS) props.history.push('/');
  }, []);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validate: loginValidator,
    onSubmit: async values => {
      try {
        const token: Tokens = await login(values.email, values.password);

        const decoded: TokenBody = jwtDecode(token.authToken);

        authContext.setRole(decoded.role);

        const user = await getUser(decoded.id);

        profileContext.setUser(user);

        props.history.push('/');
      } catch (e) {
        // console.log('error', e.response?.data?.[0].msg);
        formik.setErrors({ email: e.response?.data?.[0].msg });
      }
    },
  });

  return (
    <Login
      email={formik.values.email}
      password={formik.values.password}
      loading={formik.isSubmitting}
      errors={formik.errors}
      onChange={formik.handleChange}
      onLogin={formik.handleSubmit}
    />
  );
}

export default LoginContainer;
