import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import styles from './EditableParagraph.module.scss';
import classNames from 'classnames';

interface EditableFieldProps {
  fieldText?: string;
  setFieldText: (string) => void;
  editable?: boolean;
}

export const EditableField = ({ fieldText, setFieldText }: EditableFieldProps) => {
  if (!fieldText) {
    return <p className={styles.paragraph}>{null}</p>;
  }

  return (
    <TextField
      className={styles.textArea}
      multiline={false}
      fullWidth
      value={fieldText}
      onChange={e => {
        setFieldText(e.target.value);
      }}
      InputProps={{ disableUnderline: true }}
    />
  );
};

export default EditableField;
