import React, { ReactElement } from 'react';

import Dialog from 'components/Dialog';
import styles from './DeleteConfirmationDialogStyles.module.scss';
import Button from '@material-ui/core/Button';

interface DeleteConfirmationDialogProps {
  itemToDelete?: any | null;
  loading?: boolean;
  error?: string;
  onClose: () => void;
  onConfirm: (itemToDelete) => void;
  deleteConfirmDialogType?: DeleteConfirmationDialogType;
}

export enum DeleteConfirmationDialogType {
  Default = '',
  Appointments = 'Appointments',
  Staff = 'Staff',
  Locations = 'Locations',
}

function DeleteConfirmationDialog({
  itemToDelete,
  loading,
  error,
  onClose,
  onConfirm,
  deleteConfirmDialogType = DeleteConfirmationDialogType.Default,
}: DeleteConfirmationDialogProps): ReactElement {
  const deleteAppointmentBlurb = `Are you sure you want to delete ${itemToDelete?.appointmentType} appointment with ${itemToDelete?.patient?.fullName}`;
  const deleteDefaultBlurb = `Are you sure you want to delete ${itemToDelete?.name}`;

  return (
    <Dialog open={!!itemToDelete} onClose={onClose} title={`Delete ${deleteConfirmDialogType}`}>
      <div className={styles.container}>
        {itemToDelete ? (
          <div className={styles.text}>
            {deleteConfirmDialogType === DeleteConfirmationDialogType.Appointments
              ? deleteAppointmentBlurb
              : deleteDefaultBlurb}
          </div>
        ) : (
          `Closing..`
        )}
        <div className={styles.buttons}>
          <Button onClick={onClose}>No</Button>
          <Button onClick={() => onConfirm(itemToDelete)} disabled={loading}>
            Yes
          </Button>
        </div>
        {error && <div className={styles.error}>{error}</div>}
      </div>
    </Dialog>
  );
}

export default DeleteConfirmationDialog;
