import React, { ReactElement } from 'react';

import styles from './CardStyles.module.scss';

interface CardProps {
  children: any;
  className?: string;
}

function Card({ children, className }: CardProps): ReactElement {
  return <div className={`${styles.container} ${className}`}>{children}</div>;
}

export default Card;
