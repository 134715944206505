import axios from 'axios';

import { GET_USERS } from 'lib/config';
import { GET_CASES } from 'lib/config';
import { ACCESS_TOKEN } from 'lib/const';

import { Case } from './case.interfaces';
import { CaseResult, CommunicationHistory } from 'types';

async function getCaseByUserId(userId: number): Promise<Array<Case>> {
  const token = localStorage.getItem(ACCESS_TOKEN);

  const response = await axios.get(`${GET_USERS}/${userId}/cases`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
}

async function getCaseResultById(caseId: number): Promise<CaseResult> {
  const token = localStorage.getItem(ACCESS_TOKEN);

  ///cases/:caseId/result
  const response = await axios.get(`${GET_CASES}/${caseId}/result`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
}

async function getCommunicationHistory(cases: Array<Case>): Promise<Array<CommunicationHistory>> {
  if (cases.length) {
    const token = localStorage.getItem(ACCESS_TOKEN);

    ///cases/:caseId/communications
    const response = await axios.get(`${GET_CASES}/${cases[0].id}/communications`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  }
  return [];
}

export { getCaseByUserId, getCaseResultById, getCommunicationHistory };
