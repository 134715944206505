import React, { useEffect } from 'react';
import { Page, Text, View, Image, Document } from '@react-pdf/renderer';
import { stylesPdf } from 'lib/workflowPdf';

import useFollowUpResult, {
  useFollowUpResultPDFFragment,
} from './useFollowUpResultPDFFragment.hook';
import VeinLogo from 'assets/images/full-logo-tagline.png';
import moment from 'moment';
import { DateTimeFormatString } from 'lib/dateFormatter';
interface TechResultPDFFragmentProps {
  caseId?: number;
  formId?: number;
  patientName?: string;
  setLoading?: (state: boolean) => void;
}

export const FollowUpResultPDFFragment = ({
  formId,
  caseId,
  setLoading,
  patientName,
}: TechResultPDFFragmentProps) => {
  const { followUpFormResults } = useFollowUpResultPDFFragment({
    formId,
    caseId,
  });
  const date = moment().format(DateTimeFormatString.DateMonthYear);

  const deepVeinThrombosis = followUpFormResults.deepVeinThrombosis
    ? followUpFormResults.deepVeinThrombosis[0].toUpperCase() +
      followUpFormResults.deepVeinThrombosis.slice(1)
    : 'N/A';
  const symptomsImproved = followUpFormResults.symptomsImproved
    ? followUpFormResults.symptomsImproved[0].toUpperCase() +
      followUpFormResults.symptomsImproved.slice(1)
    : 'N/A';
  const USConfirmedVeinClosed = followUpFormResults.USConfirmedVeinClosed
    ? followUpFormResults.USConfirmedVeinClosed[0].toUpperCase() +
      followUpFormResults.USConfirmedVeinClosed.slice(1)
    : 'N/A';
  const cosmeticResultsImprovedVaricoseVeins = followUpFormResults.cosmeticResultsImprovedVaricoseVeins
    ? followUpFormResults.cosmeticResultsImprovedVaricoseVeins[0].toUpperCase() +
      followUpFormResults.cosmeticResultsImprovedVaricoseVeins.slice(1)
    : 'N/A';
  const cosmeticResultsImprovedSpiderVeins = followUpFormResults.cosmeticResultsImprovedSpiderVeins
    ? followUpFormResults.cosmeticResultsImprovedSpiderVeins[0].toUpperCase() +
      followUpFormResults.cosmeticResultsImprovedSpiderVeins.slice(1)
    : 'N/A';
  const postOPPicturesTaken = followUpFormResults.postOPPicturesTaken
    ? followUpFormResults.postOPPicturesTaken[0].toUpperCase() +
      followUpFormResults.postOPPicturesTaken.slice(1)
    : 'N/A';
  const satisfaction = followUpFormResults.satisfaction ? followUpFormResults.satisfaction : 'N/A';
  const treatmentRecommended = followUpFormResults.treatmentRecommended
    ? followUpFormResults.treatmentRecommended
    : 'N/A';
  const additionalNotes = followUpFormResults.additionalNotes
    ? followUpFormResults.additionalNotes
    : 'N/A';
  const recommendedFollowUpIn = followUpFormResults.recommendedFollowUpIn
    ? followUpFormResults.recommendedFollowUpIn
    : 'N/A';
  const recommendedBy = followUpFormResults.recommendedBy
    ? followUpFormResults.recommendedBy
    : 'N/A';
  const chargeOrNoCharge = followUpFormResults.chargeOrNoCharge
    ? followUpFormResults.chargeOrNoCharge
    : 'N/A';

  useEffect(() => {
    return;
  }, [followUpFormResults, formId, caseId, date, patientName]);
  return (
    <Document>
      <Page wrap size="A4" style={stylesPdf.followUpPage}>
        <View style={stylesPdf.followUpHeaderImage}>
          <Image src={VeinLogo} />
        </View>
        <View style={stylesPdf.followUpContainer}>
          <View style={stylesPdf.followUpHeader}>
            <Text>Patient Name: </Text>
            <Text>{patientName}</Text>
          </View>
          <View style={stylesPdf.followUpHeader}>
            <Text>Follow-Up Assessment Date: </Text>
            <Text>{date}</Text>
          </View>
          <View style={stylesPdf.followUpResultsHeader}>
            <Text>{'RESULTS:'}</Text>
          </View>
          <View style={stylesPdf.followUpResults}>
            <Text>{'Deep Vein Thrombosis: '}</Text>
            <Text>{deepVeinThrombosis}</Text>
          </View>
          <View style={stylesPdf.followUpResults}>
            <Text>{'Symptoms Improved: '}</Text>
            <Text>{symptomsImproved}</Text>
          </View>
          <View style={stylesPdf.followUpResults}>
            <Text>{'U/S Confirmed Vein Closed: '}</Text>
            <Text>{USConfirmedVeinClosed}</Text>
          </View>
          <View style={stylesPdf.followUpResults}>
            <Text>{'Cosmetic Results Improved Varicose Veins: '}</Text>
            <Text>{cosmeticResultsImprovedVaricoseVeins}</Text>
          </View>
          <View style={stylesPdf.followUpResults}>
            <Text>{'Cosmetic Results Improved Spider/Reticular Veins: '}</Text>
            <Text>{cosmeticResultsImprovedSpiderVeins}</Text>
          </View>
          <View style={stylesPdf.followUpResults}>
            <Text>{'Post OP Pictures Taken: '}</Text>
            <Text>{postOPPicturesTaken}</Text>
          </View>
          <View style={stylesPdf.followUpResults}>
            <Text>{'Satisfaction: '}</Text>
            <Text>{satisfaction}</Text>
          </View>
          <View style={stylesPdf.followUpResults}>
            <Text>{'Treatment Recommended: '}</Text>
            <Text>{treatmentRecommended}</Text>
          </View>
          <View style={stylesPdf.followUpResults}>
            <Text>{'Recommended by: '}</Text>
            <Text>{recommendedBy}</Text>
          </View>
          <View style={stylesPdf.followUpResults}>
            <Text>{'Charge / No-Charge: '}</Text>
            <Text>{chargeOrNoCharge}</Text>
          </View>
        </View>
      </Page>
      <Page wrap size="A4" style={stylesPdf.followUpPageAdditionalNotes}>
        <View style={stylesPdf.followUpContainer}>
          <View style={stylesPdf.followUpAdditionalNotesHeader}>
            <Text>Additional Notes: </Text>
          </View>
          <Text style={stylesPdf.followUpAdditionalNotes}>{additionalNotes}</Text>
          <View style={stylesPdf.followUpResults}>
            <Text>{'Recommended Follow-Up In: '}</Text>
            <Text>{recommendedFollowUpIn}</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};
