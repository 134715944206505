import React, { ReactElement } from 'react';

import Layout from 'Layout';

import Patient from './components/PatientInfo/PatientInfo.container';
import PatientManagement from './components/PatientManagement/PatientManagement.view';

import styles from './PatientStyles.module.scss';

function PatientPage(props): ReactElement {
  const { patientId } = props?.match?.params;

  return (
    <Layout hideFooter>
      <div className={styles.container}>
        <Patient id={patientId} />
        <PatientManagement match={props?.match} />
      </div>
    </Layout>
  );
}

export default PatientPage;
