import axios from 'axios';

import { COMPOSITE_IMAGE } from 'lib/config';
import { getDefaultHeader } from '../utils';

export async function getImage(key: string, folder: string | null): Promise<string> {
  const response = await axios.post(
    COMPOSITE_IMAGE,
    {
      folder,
      key,
    },
    {
      headers: getDefaultHeader(),
    }
  );

  return Array.isArray(response?.data) ? response.data[0] : '';
}

export async function getImages(
  keys: Array<string>,
  folder: string | null
): Promise<Array<string>> {
  const response = await axios.post(
    COMPOSITE_IMAGE,
    {
      folder,
      key: keys,
    },
    {
      headers: getDefaultHeader(),
    }
  );

  return Array.isArray(response?.data) ? response.data : [];
}
