import React, { Fragment, useContext, useState } from 'react';
import { IconButton, Icon } from '@material-ui/core';
import DehazeIcon from '@material-ui/icons/Dehaze';
import { buildDate } from 'lib/config';
import SidebarComponent from './Sidebar';
import { ROLE } from 'lib/user';
import { Context as AuthContext } from 'context/auth';
import { Context as ProfileContext } from 'context/profile';
import useTasksApi from 'hook/useTasksApi.hook';
import TaskSidebar from './TaskSidebar';
import Nav from './Nav';
import { UserInfoModalContainer } from 'components/UserInfoModal';

import MainLogo from 'assets/images/logo.png';
import styles from './styles.module.scss';

const Header = () => {
  const { user } = useContext(ProfileContext);
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [taskSidebarOpen, setTaskSidebarOpen] = useState<boolean>(false);
  const [userInfoOpen, setUserInfoOpen] = useState(false);
  const { role } = useContext(AuthContext);
  const { allUserTasks: tasks, createTask, fetchAllUserTasks, updateTask } = useTasksApi({
    patientId: user?.id || 0,
  });

  const onClickSidebar = () => {
    const value = !sidebarOpen;
    setSidebarOpen(value);
  };

  const onCloseSidebar = () => {
    setSidebarOpen(false);
  };

  return (
    <Fragment>
      <header className={styles.header}>
        {role !== ROLE.ANONYMOUS && (
          <>
            <SidebarComponent
              open={sidebarOpen}
              onClose={onCloseSidebar}
              onCloseSidebar={onCloseSidebar}
            />
            <IconButton onClick={onClickSidebar}>
              <Icon>
                <DehazeIcon />
              </Icon>
            </IconButton>
          </>
        )}
        <img className={styles.headerLogo} alt="Logo" src={MainLogo} />
        {role !== ROLE.ANONYMOUS && (
          <>
            <div> Build Date: {buildDate}</div>
          </>
        )}
        {role !== ROLE.ANONYMOUS && (
          <>
            <div className={styles.nav}>
              <Nav
                tasks={tasks}
                onClick={() => setUserInfoOpen(true)}
                onTaskClick={() => setTaskSidebarOpen(true)}
              />
            </div>
            <TaskSidebar
              user={user}
              tasks={tasks}
              createTask={createTask}
              fetchAllUserTasks={fetchAllUserTasks}
              updateTask={updateTask}
              open={taskSidebarOpen}
              onClose={() => setTaskSidebarOpen(false)}
            />
            <UserInfoModalContainer open={userInfoOpen} onClose={() => setUserInfoOpen(false)} />
          </>
        )}
      </header>
    </Fragment>
  );
};

export default Header;
