import React, { ReactElement } from 'react';
import TextField from '@material-ui/core/TextField';

import styles from './QuestionSectionStyles.module.scss';

interface OpenChoiceOptionProps {
  title?: string;
  description?: string;
  value: Array<any>;
  defaultValue?: Array<any>;
  onChange?(value): void;
}

function OpenChoiceOption({
  title,
  description,
  value = [],
  defaultValue = [],
  onChange = () => null,
}: OpenChoiceOptionProps): ReactElement {
  function handleChange(event): void {
    onChange([
      {
        ...(defaultValue[0]?.id && { id: defaultValue[0]?.id }),
        value: event.target.value,
      },
    ]);
  }

  return (
    <div className={styles.openChoiceOption}>
      <div className={styles.title}>{description}</div>
      <div>
        <div>
          <TextField
            defaultValue={defaultValue[0]?.value_text || defaultValue[0]?.value || ''}
            value={value[0]?.value}
            onChange={handleChange}
            placeholder="Answer here"
            fullWidth
            multiline={true}
          />
        </div>
      </div>
    </div>
  );
}

export default OpenChoiceOption;
