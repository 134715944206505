import React, { FC, ReactNode } from 'react';
import styles from './styles.module.scss';

interface ContentWrapperProps {
  children: ReactNode;
}

const ContentWrapper: FC<ContentWrapperProps> = ({ children }: ContentWrapperProps) => {
  return <div className={styles.contentWrapper}>{children}</div>;
};

export default ContentWrapper;
