import React from 'react';
import styles from './TechnicianResultsStyles.module.scss';
import { CaseResult } from 'types';
import CompImage from 'components/CompositeImage';
import VeinLogo from 'assets/images/vein-legend.jpg';
import { exampleTechicianResultProps } from './lib/calculateTechnicianResults';
import useTechnicianResults from './useTechnicianResults.hook';

interface EditProcessResultProps {
  vein: string;
  result: Array<string>;
}

const EditProcessResult = ({ vein, result }: EditProcessResultProps) => {
  let finalResult = Array.isArray(result) ? result : [result];
  // Remove empty entires
  finalResult = finalResult.filter(r => !!r);

  return (
    <div className={styles.column}>
      <div>{vein}</div>
      {finalResult.length > 0 && (
        <div>
          <ul>
            {finalResult.map((fr, index) => (
              <li key={index}>{fr}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

interface EditImageResultProps {
  images: Array<string>;
}

const EditImageResult = ({ images }: EditImageResultProps) => {
  return <CompImage folder={'VeinImages'} keys={images} width={400} height={400} />;
};

interface TechnicianLegWorkflowResultProps {
  ASV: Array<string>;
  GSV: Array<string>;
  GSVAbove: Array<string>;
  GSVBelow: Array<string>;
  GSVThrombosed: Array<string>;
  GSVStripped: Array<string>;
  GSVAblated: Array<string>;
  GSVReflux: Array<string>;
  GSVRefluxMaxDiameter: Array<string>;
  PSV: Array<string>;
  SPJ: Array<string>;
  SFJ: Array<string>;
  SSV: Array<string>;
  SSVUpper: Array<string>;
  SSVLower: Array<string>;
  SSVRefluxMaxDiameter: Array<string>;
  VV: Array<string>;
  SuperficialVeinThrombosis: Array<string>;
  Perforators: Array<string>;
  GM: Array<string>;
  comments: Array<string>;
  images: Array<string>;
  title: string;
}

export function TechnicianLegWorkflowResult({
  ASV,
  GSV,
  GSVAbove,
  GSVBelow,
  GSVThrombosed,
  GSVStripped,
  GSVAblated,
  GSVReflux,
  GSVRefluxMaxDiameter,
  PSV,
  SPJ,
  SFJ,
  SSV,
  SSVUpper,
  SSVLower,
  SSVRefluxMaxDiameter,
  VV,
  SuperficialVeinThrombosis,
  Perforators,
  GM,
  comments,
  images,
  title,
}: TechnicianLegWorkflowResultProps) {
  return (
    <>
      <div className={styles.title}>{title}</div>
      <div className={styles.workflowEntriesWrapper}>
        <div className={styles.resultColumn}>
          {GSV &&
          GSV.length > 0 &&
          GSV.findIndex(val => val.toLocaleLowerCase() === 'normal') >= 0 ? (
            <EditProcessResult vein={'GSV:'} result={GSV} />
          ) : (
            <></>
          )}
          {GSVThrombosed && GSVThrombosed.length > 0 ? (
            <EditProcessResult vein={'GSV Thrombosed:'} result={GSVThrombosed} />
          ) : (
            <></>
          )}
          {GSVAbove && GSVAbove.length > 0 ? (
            <EditProcessResult vein={'GSV Above Knee:'} result={GSVAbove} />
          ) : (
            <></>
          )}
          {GSVBelow && GSVBelow.length > 0 ? (
            <EditProcessResult vein={'GSV Below Knee:'} result={GSVBelow} />
          ) : (
            <></>
          )}

          {GSVStripped && GSVStripped.length > 0 ? (
            <EditProcessResult vein={'GSV Stripped:'} result={GSVStripped} />
          ) : (
            <></>
          )}

          {GSVAblated && GSVAblated.length > 0 ? (
            <EditProcessResult vein={'GSV Ablated:'} result={GSVAblated} />
          ) : (
            <></>
          )}

          {GSVReflux && GSVReflux.length > 0 ? (
            <EditProcessResult vein={'GSV Reflux:'} result={GSVReflux} />
          ) : (
            <></>
          )}

          {GSVRefluxMaxDiameter && GSVRefluxMaxDiameter.length > 0 ? (
            <EditProcessResult vein={'GSV Reflux Max Diameter:'} result={GSVRefluxMaxDiameter} />
          ) : (
            <></>
          )}

          {PSV && PSV.length > 0 ? <EditProcessResult vein={'PSV:'} result={PSV} /> : <></>}

          {GM && GM.length > 0 ? (
            <EditProcessResult vein={'Giacomini (GM) Abnormal:'} result={GM} />
          ) : (
            <EditProcessResult vein={'Giacomini (GM) Abnormal:'} result={['No']} />
          )}

          {Perforators && Perforators.length > 0 ? (
            <EditProcessResult vein={'Incompetent Perforators:'} result={Perforators} />
          ) : (
            <EditProcessResult vein={'Incompetent Perforators:'} result={['No']} />
          )}

          {comments && comments.length > 0 ? (
            <EditProcessResult vein={'Tech Comments:'} result={comments} />
          ) : (
            <></>
          )}
        </div>
        <div className={styles.resultColumn}>
          {SSV &&
          SSV.length > 0 &&
          SSV.findIndex(val => val.toLocaleLowerCase() === 'normal') >= 0 ? (
            <EditProcessResult vein={'SSV:'} result={SSV} />
          ) : (
            <></>
          )}
          {SSVUpper && SSVUpper.length > 0 ? (
            <EditProcessResult vein={'SSV Upper:'} result={SSVUpper} />
          ) : (
            <></>
          )}
          {SSVLower && SSVLower.length > 0 ? (
            <EditProcessResult vein={'SSV Lower:'} result={SSVLower} />
          ) : (
            <></>
          )}
          {SSVRefluxMaxDiameter && SSVRefluxMaxDiameter.length > 0 ? (
            <EditProcessResult vein={'SSV Reflux Max Diameter:'} result={SSVRefluxMaxDiameter} />
          ) : (
            <></>
          )}

          {ASV && ASV.length > 0 ? <EditProcessResult vein={'ASV:'} result={ASV} /> : <></>}

          {SPJ && SPJ.length > 0 ? <EditProcessResult vein={'SPJ:'} result={SPJ} /> : <></>}

          {SFJ && SFJ.length > 0 ? <EditProcessResult vein={'SFJ:'} result={SFJ} /> : <></>}

          {VV && VV.length > 0 ? (
            <EditProcessResult vein={'Visible Varicosities:'} result={VV} />
          ) : (
            <EditProcessResult vein={'Visible Varicosities:'} result={['No']} />
          )}

          {SuperficialVeinThrombosis && SuperficialVeinThrombosis.length > 0 ? (
            <EditProcessResult
              vein={'Superficial Vein Thrombosis:'}
              result={SuperficialVeinThrombosis}
            />
          ) : (
            <EditProcessResult vein={'Superficial Vein Thrombosis:'} result={['No']} />
          )}
        </div>
        <div className={styles.resultColumn}>
          <EditImageResult images={images} />
        </div>
      </div>
    </>
  );
}

interface TechnicianWorkflowResultProps {
  formCaseResult: CaseResult | null;
}

export const TechnicianWorkflowResult = ({ formCaseResult }: TechnicianWorkflowResultProps) => {
  const { formResults } = useTechnicianResults({
    formCaseResult,
  });

  // TODO: use caseId
  if (!formCaseResult) {
    return <div>loading...</div>;
  }

  return (
    <div className={styles.legEntries}>
      <div className={styles.pageTitle}>Technician Results</div>
      <img className={styles.legend} src={VeinLogo} alt=""></img>
      <TechnicianLegWorkflowResult
        {...{
          ...exampleTechicianResultProps.rightLeg,
          ...formResults.rightLeg,
          title: 'Right Leg',
        }}
      />
      <TechnicianLegWorkflowResult
        {...{
          ...exampleTechicianResultProps.leftLeg,
          ...formResults.leftLeg,
          title: 'Left Leg',
        }}
      />
    </div>
  );
};

export default TechnicianWorkflowResult;
