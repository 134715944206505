import React, { ReactElement, useState } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import classNames from 'classnames';

import { Form } from 'types';
import Dialog from 'components/Dialog';
import Select from 'components/Select';
import DialogButton from 'components/DialogButton';

import styles from './SendFormDialogStyles.module.scss';

interface SendFormDialogProps {
  open?: boolean;
  loading: boolean;
  forms?: Array<Form>;
  onSend?(formId): void;
  onClose?(): void;
}

function getFormName(id: number, forms: Array<Form>): string {
  const foundForm = forms.find(form => form.id === id);

  return foundForm?.description || '';
}

function SendFormDialog({
  open = false,
  loading = false,
  forms = [],
  onClose,
  onSend = () => null,
}: SendFormDialogProps): ReactElement {
  const [formId, setFormId] = useState<number | null>(null);

  return (
    <Dialog title="Send form" open={open} onClose={onClose}>
      <div className={styles.main}>
        <Select
          fullWidth
          label="Choose form"
          name={'form'}
          renderValue={value => (
            <div className={styles.selectItem}>{getFormName(value, forms)}</div>
          )}
          onChange={e => setFormId(e?.target?.value)}
          value={formId}
        >
          {forms.map(item => (
            <MenuItem key={item.id} value={item.id} className={styles.selectItem}>
              {item.description}
            </MenuItem>
          ))}
        </Select>
        <div className={classNames(styles.buttons, styles.padding)}>
          <DialogButton onClick={onClose} className={styles.paddingRight}>
            Cancel
          </DialogButton>
          <DialogButton loading={loading} onClick={() => onSend(formId)}>
            Send
          </DialogButton>
        </div>
      </div>
    </Dialog>
  );
}

export default SendFormDialog;
