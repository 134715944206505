import axios from 'axios';
import moment from 'moment';

import { GET_LOCATIONS, GET_SCHEDULE_EXCEPTION } from 'lib/config';

import { Location, LocationSchedule, ScheduledStaff } from 'types';
import { DateTimeFormatString } from 'lib/dateFormatter';
import { getDefaultHeader } from '../utils';
import { Schedule, ScheduleItemResponse } from 'api/user/user.interfaces';
import { transformUserScheduleResponse } from 'api/user/user.transformer';

export async function getLocations(
  searchName = '',
  date = moment().format(DateTimeFormatString.DateOnly),
  userId = undefined
): Promise<Array<Location>> {
  const response = await axios(GET_LOCATIONS, {
    method: 'GET',
    params: {
      searchName,
      date,
      userId,
    },
    headers: getDefaultHeader(),
  });

  return response.data;
}

export async function getLocation(id: number): Promise<Location> {
  const response = await axios(`${GET_LOCATIONS}/${id}`, {
    method: 'GET',
    headers: getDefaultHeader(),
  });

  return response.data;
}

export async function deleteLocation(locationId: number): Promise<void> {
  const response = await axios(`${GET_LOCATIONS}/${locationId}`, {
    method: 'DELETE',
    headers: getDefaultHeader(),
  });

  return response.data;
}

export async function createLocation(data): Promise<Location | null> {
  try {
    const response = await axios(`${GET_LOCATIONS}`, {
      method: 'POST',
      data,
      headers: getDefaultHeader(),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function excludeDate(locationId: number, date: string): Promise<null> {
  try {
    const response = await axios(`${GET_SCHEDULE_EXCEPTION}/${locationId}/exclude`, {
      method: 'POST',
      data: { date },
      headers: getDefaultHeader(),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function includeDate(locationId: number, date: string): Promise<null> {
  try {
    const response = await axios(`${GET_SCHEDULE_EXCEPTION}/${locationId}/include`, {
      method: 'DELETE',
      data: { date },
      headers: getDefaultHeader(),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function updateLocation(locationId, data): Promise<Location | null> {
  try {
    const response = await axios(`${GET_LOCATIONS}/${locationId}`, {
      method: 'PUT',
      data,
      headers: getDefaultHeader(),
    });

    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function cancelLocationSchedule(
  locationScheduleId: number,
  date: string
): Promise<null> {
  try {
    const response = await axios(`${GET_SCHEDULE_EXCEPTION}/cancelLocationScheduleException`, {
      method: 'DELETE',
      data: { date, locationScheduleId },
      headers: getDefaultHeader(),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function addLocationScheduleException(
  locationScheduleId,
  date
): Promise<Location | null> {
  try {
    const response = await axios(`${GET_LOCATIONS}/addLocationScheduleException`, {
      method: 'POST',
      data: { locationScheduleId, date },
      headers: getDefaultHeader(),
    });

    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getLocationSpecificScheduleNoExtrapolation(
  locationId: number,
  startDate: string,
  day: string
): Promise<ScheduleItemResponse> {
  const response = await axios(`${GET_LOCATIONS}/${locationId}/specificSchedule`, {
    method: 'GET',
    params: {
      startDate,
      day,
    },
    headers: getDefaultHeader(),
  });
  return response.data;
}

export async function getLocationSchedule(
  locationId: number,
  startDate: string | null = null,
  endDate: string | null = null
): Promise<Array<Schedule>> {
  const response = await axios(`${GET_LOCATIONS}/${locationId}/schedule`, {
    method: 'GET',
    params: {
      startDate,
      endDate,
      locationId,
    },
    headers: getDefaultHeader(),
    transformResponse: transformUserScheduleResponse,
  });

  return response.data;
}

export async function getLocationScheduleById(
  locationId: number,
  scheduleId: number
): Promise<LocationSchedule> {
  const response = await axios(`${GET_LOCATIONS}/${locationId}/schedule/${scheduleId}`, {
    method: 'GET',
    headers: getDefaultHeader(),
    transformResponse: [
      function(data) {
        const body = JSON.parse(data)[0];
        const schedule = {
          scheduleId: body.schedule_id,
          locationId: body.location_id,
          startTime: body.start_time,
          endTime: body.end_time,
          startDate: body.start_date,
          endDate: body.end_date,
          recurrence: body.recurrence,
          type: body.type,
          day: body.day,
          name: body.name,
          timezone: body.timezone,
          staffMaxEndTime: body.staff_max_end_time,
          staffMinStartTime: body.staff_min_start_time,
          staffScheduleCount: body.staff_schedule_count,
          cancelled: body.cancelled,
        };
        return schedule;
      },
    ],
  });
  return response.data;
}

export async function getAvailableHoursForLocationSchedule({
  locationId: locationId,
  locationScheduleId: locationScheduleId,
  startDate: startDate,
  endDate: endDate,
  type: type,
  frequency: frequency,
}): Promise<Array<LocationSchedule>> {
  const response = await axios.get(
    `${GET_LOCATIONS}/${locationId}/locationSchedule/availableHours`,
    {
      params: {
        startDate,
        endDate,
        type,
        locationScheduleId,
        frequency,
      },
      headers: getDefaultHeader(),
    }
  );

  return response.data;
}

export async function getStaffSchedulesByLocationId(
  locationId: number,
  end: string,
  start?: string,
  type?: number,
  recurrence?: string
): Promise<Array<ScheduledStaff>> {
  const response = await axios.get(`${GET_LOCATIONS}/${locationId}/staffSchedules`, {
    params: {
      start,
      end,
      type,
      recurrence,
    },
    headers: getDefaultHeader(),
  });

  return response.data;
}

export async function getMultipleLocationsSchedule(
  locationIds: Array<number> = [],
  startDate: string | null = null,
  endDate: string | null = null,
  searchName: string | null = null
): Promise<Array<Schedule>> {
  const locationIdsQueryParams = locationIds.join();

  const response = await axios(
    `${GET_LOCATIONS}/schedules?locationsIds=${locationIdsQueryParams}`,
    {
      method: 'GET',
      params: {
        startDate,
        endDate,
        searchName,
      },
      headers: getDefaultHeader(),
      transformResponse: transformUserScheduleResponse,
    }
  );

  return response.data;
}

export async function createLocationSchedule(
  schedule: LocationSchedule
): Promise<LocationSchedule> {
  const response = await axios.post(`${GET_LOCATIONS}/schedule`, schedule, {
    headers: getDefaultHeader(),
  });

  return response.data;
}

export async function updateLocationSchedule(
  locationId: number,
  schedule: LocationSchedule
): Promise<LocationSchedule> {
  const response = await axios.put(`${GET_LOCATIONS}/${locationId}/schedule`, schedule, {
    headers: getDefaultHeader(),
  });

  return response.data;
}

export async function deleteException(scheduleId: number): Promise<void> {
  const response = await axios.delete(`${GET_LOCATIONS}/${scheduleId}/scheduleException`, {
    headers: getDefaultHeader(),
  });

  return response.data;
}

export async function deleteLocationSchedule(
  locationId: number,
  body
): Promise<Array<LocationSchedule>> {
  const response = await axios.delete(`${GET_LOCATIONS}/${locationId}/schedule`, {
    headers: getDefaultHeader(),
    data: body,
  });

  return response.data;
}
