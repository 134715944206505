import { useEffect, useState } from 'react';

import { FaxApi } from 'api';
import { StyleSheet } from '@react-pdf/renderer';
import { NewDocument } from 'types';
import { MIME_TYPE_PDF } from 'lib/document';
import * as logger from 'api/logger';

function useFaxActions() {
  const [coverLetterURL, setCoverLetterURL] = useState('');

  async function createFaxWithCoverLetter(
    selectedDocumentIds,
    title,
    faxNumber,
    recipientName,
    generic = false
  ): Promise<any> {
    let uploadStatus;
    try {
      const fileResult = await fetch(coverLetterURL);
      const blob = await fileResult.blob();
      const fileName = 'fileName';
      const fileObject = new File([blob], fileName, { type: MIME_TYPE_PDF });

      const document: NewDocument = {
        name: fileName,
        document: fileObject,
      };

      uploadStatus = await FaxApi.createOutgoingFaxWithCoverLetter(
        document,
        selectedDocumentIds,
        title,
        faxNumber,
        recipientName,
        generic
      );
    } catch (e) {
      logger.error(e.message);
    }
    return uploadStatus;
  }

  async function createFaxWithCoverLetterDefault(
    selectedDocumentId,
    faxNumber,
    recipientName,
    generic = false
  ): Promise<any> {
    let uploadStatus;
    try {
      const fileResult = await fetch(coverLetterURL);
      const blob = await fileResult.blob();
      const fileName = 'fileName';
      const fileObject = new File([blob], fileName, { type: MIME_TYPE_PDF });

      const document: NewDocument = {
        name: fileName,
        document: fileObject,
      };

      uploadStatus = await FaxApi.createOutgoingFaxWithCoverLetterDefault(
        document,
        selectedDocumentId,
        faxNumber,
        recipientName,
        generic
      );
    } catch (e) {
      logger.error(e.message);
    }
    return uploadStatus;
  }

  async function createGenericDocument(data: NewDocument): Promise<any> {
    let documentData;
    try {
      documentData = await FaxApi.createGenericDocument(data);
      await FaxApi.pushCoverLetterToS3(documentData.signedRequest, data);
    } catch (e) {
      logger.error(e.message);
      throw e;
    }
    return documentData;
  }

  async function previewPdfDocument(): Promise<void> {
    window.open(coverLetterURL, '_blank');
  }

  return {
    previewPdfDocument,
    coverLetterURL,
    setCoverLetterURL,
    createFaxWithCoverLetter,
    createFaxWithCoverLetterDefault,
    createGenericDocument,
  };
}

export default useFaxActions;
