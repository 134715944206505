import React from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import { ReferrerStatusId } from 'types';

interface Data {
  name: string;
  id: string;
  dateOfBirth: string;
  phone: string;
  email: string;
  healthcard: string;
  referrerStatusId: ReferrerStatusId;
  createAppointment: any;
}

interface HeadCell {
  id: keyof Data;
  label: string;
}

const headCells: HeadCell[] = [
  { id: 'name', label: 'Name' },
  { id: 'id', label: 'Id' },
  { id: 'dateOfBirth', label: 'Date of Birth' },
  { id: 'phone', label: 'Phone number' },
  { id: 'email', label: 'Email' },
  { id: 'healthcard', label: 'Healthcard #' },
  { id: 'referrerStatusId', label: 'Latest Appt. Status' },
  { id: 'createAppointment', label: 'New Appt.' },
];

interface EnhancedTableProps {
  classes: any;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes } = props;

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            className={headCell.id === 'name' ? classes.cellColored : classes.cell}
            key={headCell.id}
            align={'left'}
            padding={'default'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default EnhancedTableHead;
