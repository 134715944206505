export const receiptClinicCities = [
  'Richmond Hill',
  'Vaughan',
  'Markham',
  'Toronto',
  'Mississauga',
  'Halifax',
  'Moncton',
  'Ottawa',
  'Orleans',
  'Kanata',
  'Kawartha Lakes',
];
export const receiptPhoneContact = '(888) 876-8346';
export const receiptFaxContact = '(705) 880-1211';
export const receiptIssuedByMessage =
  'This receipt is issued by Canada Vein Clinics on its own behalf and as an agent of CVC';
