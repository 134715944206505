import React, { ReactElement } from 'react';
import { FormControl, InputLabel, Select as MuiSelect, FormHelperText } from '@material-ui/core';

import ConditionDiv from '../ConditionDiv';

interface SelectProps {
  name?: string;
  error?: string;
  value?: any;
  label?: string;
  children?: any;
  disabled?: boolean;
  onChange?: (event) => void;
  renderValue?: (value) => any;
  fullWidth?: boolean;
  className?: string;
}

function Select({
  name,
  error,
  value,
  label,
  onChange,
  renderValue,
  children,
  disabled,
  fullWidth = false,
  className,
}: SelectProps): ReactElement {
  return (
    <FormControl error={!!error} fullWidth={fullWidth} className={className}>
      <InputLabel id="select-label">{label}</InputLabel>
      <MuiSelect
        labelId="select-label"
        id="demo-simple-select-error"
        name={name}
        value={value}
        onChange={onChange}
        renderValue={renderValue}
        disabled={disabled}
      >
        {children}
      </MuiSelect>
      <ConditionDiv condition={!!error}>
        <FormHelperText>{error}</FormHelperText>
      </ConditionDiv>
    </FormControl>
  );
}

export default Select;
