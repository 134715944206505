import { Grid, TextField, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import React, { ReactElement, useEffect, useRef } from 'react';
import Button from 'components/CVCButton';
import { getDocumentTypes } from 'api/document';
import styles from './FileUploadStyles.module.scss';
import { DocumentType } from 'types';
import { supportedFileTypesString } from 'lib/document';
import classNames from 'classnames';
import { FormikErrors } from 'formik';
import CircularProgress from '@material-ui/core/CircularProgress';

interface FileUploadProps {
  onChangeFile: (event, index) => void;
  onChangeName: (event, index) => void;
  onChangeTypeId: (event, index) => void;
  onDeleteRow: (index) => void;
  errors?: any;
  documentTypes: Array<DocumentType>;
  values: any;
  status: null | PromiseSettledResult<any>;
  index: number;
  isProgress: boolean;
}
export default function FileUpload({
  onChangeFile,
  onChangeName,
  onChangeTypeId,
  onDeleteRow,
  errors,
  status,
  documentTypes,
  values,
  index,
  isProgress,
}: FileUploadProps): ReactElement {
  const fileInput = useRef<any>(null);

  return (
    <div className={styles.container}>
      <Grid container>
        <Grid item sm={4} xs={12}>
          <div className={styles.padding}>
            <TextField
              fullWidth
              name={'name'}
              label="Document name"
              placeholder="Enter document name"
              onChange={event => onChangeName(event, index)}
              value={values.name}
              error={errors?.name}
            />
          </div>
        </Grid>
        <Grid item sm={4} xs={12}>
          <div className={styles.padding}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Document type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="typeId"
                value={values.typeId}
                onChange={event => onChangeTypeId(event, index)}
                error={errors.typeId}
              >
                {documentTypes.map(dc => (
                  <MenuItem key={dc.id} value={dc.id}>
                    {dc.type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </Grid>
        <Grid item sm={1} xs={12}>
          <div className={classNames(styles.fileSelect, styles.padding)}>
            <Button onClick={() => fileInput?.current?.click()}>Upload file</Button>
            <input
              type="file"
              ref={fileInput}
              style={{ display: 'none' }}
              name="document"
              onChange={event => onChangeFile(event, index)}
              multiple
              accept={supportedFileTypesString()}
            />
          </div>
          {errors?.document && <span className={styles.error}>{errors.document}</span>}
        </Grid>
        <Grid item sm={1} xs={12}>
          <div className={classNames(styles.fileSelect, styles.padding)}>
            <Button onClick={() => onDeleteRow(index)}>Delete Row</Button>
          </div>
        </Grid>
        {isProgress && (
          <Grid item sm={1} xs={12}>
            <div className={classNames(styles.fileSelect, styles.padding)}>
              <CircularProgress variant="indeterminate" />
            </div>
          </Grid>
        )}
        <Grid item sm={1} xs={12}>
          <div className={classNames(styles.fileSelect, styles.padding)}>
            {status?.status == 'fulfilled' && (
              <p className={styles.success}>Uploaded File Successfully!</p>
            )}
            {status?.status == 'rejected' && (
              <p className={styles.error}>
                Error: File not uploaded successfully. Please try again
              </p>
            )}
          </div>
        </Grid>
      </Grid>
      <div className={styles.choseFileHint}>File name: {values.document?.name || ''} </div>
    </div>
  );
}
