import React, { Fragment, ReactElement, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import EnhancedTableHead from './components/EnhancedTableHead';
import SendEmailDialog from './components/SendEmailDialog';
import Grid from '@material-ui/core/Grid';
import TitledSection from 'components/TitledSection';
import styles from './CommunicationStyles.module.scss';
import useCommunicationHistoryApi from 'hook/useCommunicationHistoryApi.hook';
import ConditionDiv from 'components/ConditionDiv';
import Button from 'components/Button';
import { useStyles, StyledTableCell, StyledTableRow } from 'lib/table';
import { formatDate, DateTimeFormat } from 'lib/dateFormatter';
import formatPhoneInput from 'lib/phoneNumFormatter';

interface CommunicationHistoryRowProps {
  date: string;
  type: string;
  sentByFullName?: string;
  sentTo?: string;
  status?: string;
  title: string;
}

function CommunicationHistoryRow({
  date,
  type,
  sentByFullName = '',
  sentTo = '',
  title,
  status = '',
}: CommunicationHistoryRowProps): ReactElement {
  return (
    <StyledTableRow hover role="checkbox" tabIndex={-1}>
      <StyledTableCell>{date}</StyledTableCell>
      <StyledTableCell>
        <div className={styles.title}>{title}</div>
      </StyledTableCell>
      <StyledTableCell>{type}</StyledTableCell>
      <StyledTableCell>{sentByFullName}</StyledTableCell>
      <StyledTableCell>{sentTo}</StyledTableCell>
      <StyledTableCell>{status}</StyledTableCell>
    </StyledTableRow>
  );
}

interface CommunicationResponseRowProps {
  date: string;
  response: string;
  sentByFullName?: string;
  status?: string;
}

function CommunicationResponseRow({
  date,
  response,
  sentByFullName = '',
  status = '',
}: CommunicationResponseRowProps): ReactElement {
  return (
    <StyledTableRow hover role="checkbox" tabIndex={-1}>
      <StyledTableCell>{date}</StyledTableCell>
      <StyledTableCell>{response}</StyledTableCell>
      <StyledTableCell>REPLY</StyledTableCell>
      <StyledTableCell>{sentByFullName}</StyledTableCell>
      <StyledTableCell>{status}</StyledTableCell>
    </StyledTableRow>
  );
}

function Communication(props): ReactElement {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const patientId = props?.match?.params?.patientId;
  const { caseId, communications, loading, fetchCommunicationHistory } = useCommunicationHistoryApi(
    patientId
  );
  const classes = useStyles();

  function onOpenDialog(): void {
    setOpenDialog(true);
  }

  function onCloseDialog(): void {
    setOpenDialog(false);
  }

  const headContent = (
    <div className={styles.button}>
      <Button onClick={onOpenDialog}>Send Email</Button>
    </div>
  );

  const getSentTo = comm => {
    let sentTo = '';
    if (comm.sentTo.email) {
      sentTo = comm.sentTo.email;
    } else if (comm.sentTo.fax) {
      sentTo += comm.sentTo.fullName ? `${comm.sentTo.fullName}  ` : '';
      sentTo += `${formatPhoneInput(comm.sentTo.fax)}`;
    }
    return sentTo;
  };

  return (
    <Fragment>
      <Grid container>
        <Grid item sm={12} xs={12}>
          <TitledSection title="History" headContent={headContent}>
            <TableContainer>
              <Table className={classes.table} aria-label="customized table">
                <EnhancedTableHead classes={classes} />
                <TableBody>
                  {!!communications?.length &&
                    communications?.map((comm, index) => (
                      <>
                        <CommunicationHistoryRow
                          key={index}
                          date={formatDate(
                            DateTimeFormat.DateWithFullMonthNameAndTime12h,
                            comm.sent_at
                          )}
                          type={comm.type}
                          sentByFullName={comm.sentBy.fullName}
                          sentTo={getSentTo(comm)}
                          title={comm.title ? comm.title.replace(/<br>/g, '\n') : ''}
                          status={comm.status}
                        />
                        {!!comm.response && (
                          <CommunicationResponseRow
                            date={formatDate(
                              DateTimeFormat.DateWithFullMonthNameAndTime12h,
                              comm.response.updatedAt
                            )}
                            sentByFullName={comm.sentTo.fullName}
                            response={comm.response.response}
                          />
                        )}
                      </>
                    ))}
                </TableBody>
              </Table>
              <ConditionDiv condition={!communications?.length}>
                {loading ? 'Loading...' : 'Currently no communications history'}
              </ConditionDiv>
            </TableContainer>
          </TitledSection>
        </Grid>
      </Grid>
      <SendEmailDialog
        open={openDialog}
        onCloseDialog={onCloseDialog}
        caseId={caseId}
        onSend={() => fetchCommunicationHistory(patientId)}
      />
    </Fragment>
  );
}

export default Communication;
