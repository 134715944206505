import React, { ReactElement } from 'react';
import Dialog from 'components/Dialog';
import styles from './InfoModalStyles.module.scss';

interface InfoModalProps {
  open?: boolean;
  title?: string;
  text?: string;
  onClose: () => void;
}

function InfoModal({ open, onClose, title, text }: InfoModalProps): ReactElement {
  return (
    <Dialog open={open} onClose={onClose} title={title}>
      <div className={styles.container}>
        <div className={styles.text}>{text}</div>
      </div>
    </Dialog>
  );
}

export default InfoModal;
