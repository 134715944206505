import React, { ReactElement } from 'react';
import TextField from '@material-ui/core/TextField';

import Card from 'components/Card';
import Logo from 'assets/images/logo.png';

import Button from '../Login/components/Button';

import styles from './ResetPasswordStyles.module.scss';

interface ResetPasswordProps {
  value: {
    password: string;
    confirmPassword: string;
  };
  errors?: {
    password?: string;
    confirmPassword?: string;
  };
  loading?: boolean;
  onChange(e): void;
  onSubmit(): void;
}

function ResetPassword({
  value,
  loading = false,
  errors,
  onChange,
  onSubmit,
}: ResetPasswordProps): ReactElement {
  return (
    <form className={styles.container} onSubmit={onSubmit}>
      <Card>
        <div className={styles.form}>
          <img className={styles.logo} src={Logo} alt="logo" />
          <div className={styles.fields}>
            <div className={styles.field}>
              <TextField
                label={'Password'}
                name="password"
                type="password"
                value={value.password}
                onChange={onChange}
                placeholder={'password'}
                error={!!errors?.password}
                helperText={errors?.password}
              />
            </div>
            <div className={styles.field}>
              <TextField
                label={'Confirm Password'}
                name="confirmPassword"
                value={value.confirmPassword}
                type="password"
                onChange={onChange}
                placeholder={'Confirm password'}
                error={!!errors?.confirmPassword}
                helperText={errors?.confirmPassword}
              />
            </div>
          </div>
          <Button loading={loading} type="submit" className={styles.button}>
            Change Password
          </Button>
        </div>
      </Card>
    </form>
  );
}

export default ResetPassword;
