import React, { ReactElement } from 'react';

import Dialog from 'components/Dialog';
import styles from './ActionConfirmationDialogStyles.module.scss';
import Button from '@material-ui/core/Button';
import DialogButton from 'components/DialogButton';

interface ActionConfirmationDialogProps {
  open: boolean;
  title?: string;
  description?: string;
  onClose?: () => void;
  onConfirm?: () => void;
  error?: string;
  loading?: boolean;
}

function ActionConfirmationDialog({
  open,
  title,
  description,
  onClose,
  onConfirm,
  error,
  loading,
}: ActionConfirmationDialogProps): ReactElement {
  return (
    <Dialog open={open} onClose={onClose} title={title}>
      <div className={styles.container}>
        <div className={styles.text}>{description}</div>
        <div className={styles.error}>{error || ''}</div>
        {loading !== undefined ? (
          <div className={styles.buttons}>
            <DialogButton onClick={onClose}>No</DialogButton>
            <DialogButton loading={loading} onClick={onConfirm}>
              Yes
            </DialogButton>
          </div>
        ) : (
          <div className={styles.buttons}>
            <Button onClick={onClose}>No</Button>
            <Button onClick={onConfirm}>Yes</Button>
          </div>
        )}
      </div>
    </Dialog>
  );
}

export default ActionConfirmationDialog;
