import React, { ReactElement } from 'react';

import styles from './QuestionSectionStyles.module.scss';
import classNames from 'classnames';

interface TextQuestionSectionProps {
  title?: string;
  description?: string;
  defaultValue: Array<string>;
  value: Array<string>;
  optionExtractor?: (option) => string;
}

function TextQuestionSection({
  title,
  description,
  value,
  defaultValue,
}: TextQuestionSectionProps): ReactElement {
  const displayValue =
    (value && value.length > 0 && value[0]) ||
    (defaultValue && defaultValue.length > 0 && defaultValue[0]) ||
    '';

  return (
    <div className={styles.main}>
      <div className={styles.title}>{title}</div>
      <div className={classNames(styles.title, styles.statement)}>{description}</div>
      <div className={styles.answerContainer}>
        <div className={styles.answer}>
          <div>{displayValue}</div>
        </div>
      </div>
    </div>
  );
}

export default TextQuestionSection;
