import moment from 'moment';
import { DateTimeFormatString } from 'lib/dateFormatter';

export type FrequencyType = 'weekly' | 'monthly' | 'never';

export interface Frequency {
  id: number;
  name: string;
  tag: FrequencyType;
}

export const FREQUENCY = [
  {
    id: 1,
    name: 'Never',
    tag: 'never' as FrequencyType,
  },
  {
    id: 2,
    name: 'Weekly',
    tag: 'weekly' as FrequencyType,
  },
  {
    id: 3,
    name: 'Monthly',
    tag: 'monthly' as FrequencyType,
  },
];

export const TIMES: Array<string> = [];
export const TIMES_FIVE: Array<string> = [];
export const fiveMinuteIntervalTimes: Array<string> = [];

export const quarterHours = ['00', '15', '30', '45'];
const fiveMinuteIntervals = [
  '00',
  '05',
  '10',
  '15',
  '20',
  '25',
  '30',
  '35',
  '40',
  '45',
  '50',
  '55',
];

export function convertToTwelveHr(array, value, i) {
  if (i === 12) {
    array.push(i + ':' + value + ' pm');
  } else if (i < 12) {
    array.push(i + ':' + value + ' am');
  } else {
    array.push(i - 12 + ':' + value + ' pm');
  }
}

// hours are from 7am to 9pm
for (let i = 7; i < 21; i++) {
  for (let j = 0; j < 4; j++) {
    let time = i + ':' + quarterHours[j];

    if (i < 10) {
      time = '0' + time;
    }
    TIMES.push(time);
  }
}
for (let i = 7; i < 21; i++) {
  for (let j = 0; j < 12; j++) {
    let time = i + ':' + fiveMinuteIntervals[j];
    if (i < 10) {
      time = '0' + time;
    }
    TIMES_FIVE.push(time);
  }
}
for (let i = 7; i < 21; i++) {
  for (let j = 0; j < fiveMinuteIntervals.length; j++) {
    let time = i + ':' + fiveMinuteIntervals[j];
    if (i < 10) {
      time = '0' + time;
    }

    fiveMinuteIntervalTimes.push(time);
  }
}

export function setDefaultRecurrence(value, item) {
  return value?.frequency ? item.name.toLowerCase() === value?.frequency.toLowerCase() : false;
}

export function getTimesArray(
  startTime: string | undefined | null,
  endTime: string | undefined | null
): Array<string> {
  const timesArray: Array<string> = [];

  for (let index = 0; index < TIMES.length; index++) {
    if (
      ((startTime && TIMES[index] >= startTime) || !startTime) &&
      ((endTime && endTime >= TIMES[index]) || !endTime)
    ) {
      timesArray.push(TIMES[index]);
    }
  }
  return timesArray;
}

export function getStartTimesArray(
  startTime: string | undefined | null,
  endTime: string | undefined | null,
  isFiveMinIncrement: boolean | undefined | null = null
): Array<string> {
  const timesArray: Array<string> = [];
  const globalTimes = isFiveMinIncrement ? TIMES_FIVE : TIMES;
  for (let index = 0; index < globalTimes.length - 1; index++) {
    if (
      ((startTime && globalTimes[index] >= startTime) || !startTime) &&
      ((endTime && endTime > globalTimes[index]) || !endTime)
    ) {
      timesArray.push(globalTimes[index]);
    }
  }
  return timesArray;
}

export function getEndTimesArray(
  startTime: string | undefined | null,
  endTime: string | undefined | null
): Array<string> {
  const timesArray: Array<string> = [];

  for (let index = 0; index < TIMES.length; index++) {
    if (
      ((startTime && TIMES[index] > startTime) || !startTime) &&
      ((endTime && endTime >= TIMES[index]) || !endTime)
    ) {
      timesArray.push(TIMES[index]);
    }
  }
  return timesArray;
}

export function getStartTimesArrayFiveMinInterval(
  startTime: string | undefined | null,
  endTime: string | undefined | null
): Array<string> {
  const timesArray: Array<string> = [];

  for (let index = 0; index < fiveMinuteIntervalTimes.length - 1; index++) {
    if (
      ((startTime && fiveMinuteIntervalTimes[index] >= startTime) || !startTime) &&
      ((endTime && endTime > fiveMinuteIntervalTimes[index]) || !endTime)
    ) {
      timesArray.push(fiveMinuteIntervalTimes[index]);
    }
  }
  return timesArray;
}

export function getEndTimesArrayFiveMinInterval(
  startTime: string | undefined | null,
  endTime: string | undefined | null
): Array<string> {
  const timesArray: Array<string> = [];

  for (let index = 0; index < fiveMinuteIntervalTimes.length; index++) {
    if (
      ((startTime && fiveMinuteIntervalTimes[index] > startTime) || !startTime) &&
      ((endTime && endTime >= fiveMinuteIntervalTimes[index]) || !endTime)
    ) {
      timesArray.push(fiveMinuteIntervalTimes[index]);
    }
  }
  return timesArray;
}

export const filterTimesOnlyAfterStartTimeUntilInterval = (
  startTime: string,
  startTimesArr: Array<string>
) => {
  const newEndTime: Array<string> = [];
  let i = startTimesArr.indexOf(startTime) + 1;
  while (
    startTimesArr[i] ==
    moment(startTimesArr[i - 1], 'HH:mm')
      .add('15', 'm')
      .format('HH:mm')
      .toString()
  ) {
    if (startTimesArr[i] > startTime) {
      newEndTime.push(startTimesArr[i]);
    }
    i++;
  }
  return newEndTime;
};

export const filterTimesOnlyAfterEndTime = (endTime: string, startTimesArr: Array<string>) => {
  const newStartTimeArr: Array<string> = [];
  startTimesArr.forEach(time => {
    if (time < endTime) {
      newStartTimeArr.push(time);
    }
  });
  return newStartTimeArr;
};

export const displayDateForDatePicker = (untilDate: Date | null | undefined, startDate: Date) => {
  return untilDate && moment(untilDate).year() < 2099 ? untilDate : startDate;
};

export const convertTo12From24 = time => {
  return moment(time, DateTimeFormatString.TimeOnlyWithoutMeridies).format(
    DateTimeFormatString.TimeOnlyWithoutZero
  );
};
