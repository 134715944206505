import React, { ComponentType, ReactElement, useContext } from 'react';

import { Context } from 'context/auth';

const requirementCheck = (requirementArray: Array<string>, currentAuthData: string): boolean =>
  !requirementArray || !requirementArray.length || requirementArray.includes(currentAuthData);

const protectByRole = (
  requirementArray: Array<string>,
  ComponentToDrawWhenNotAllowed: ComponentType
) => (ComponentToProtect: ComponentType) => {
  function Protector(props): ReactElement {
    const authContext = useContext(Context);

    return requirementCheck(requirementArray, authContext.role) ? (
      <ComponentToProtect {...props} />
    ) : (
      <ComponentToDrawWhenNotAllowed />
    );
  }

  return Protector;
};

export { protectByRole };
