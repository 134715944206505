import React, { ReactElement, useEffect, useState } from 'react';
import moment from 'moment';
import {
  Page,
  Text,
  View,
  Document,
  Image as PDFImage,
  StyleSheet,
  Font,
} from '@react-pdf/renderer';
import { receiptClinicCities, receiptPhoneContact, receiptFaxContact } from 'lib/receipt';
import { DateTimeFormatString } from 'lib/dateFormatter';
import FullLogoTagline from 'assets/images/full-logo-tagline.png';
import { styles } from '@material-ui/pickers/views/Calendar/Calendar';
import formatPhoneInput from 'lib/phoneNumFormatter';

interface FaxPDFRenderProps {
  title: string;
  recipientName: string;
  faxNumber: string;
  note: string;
  clinicName: string;
  patientName: string;
  dateOfBirth: string;
  healthcard: string;
}

export const useNewFaxPDFRender = ({
  title,
  recipientName,
  faxNumber,
  note,
  clinicName,
  patientName,
  dateOfBirth,
  healthcard,
}: FaxPDFRenderProps): { CoverLetterLayout: () => ReactElement } => {
  Font.register({
    family: 'Lora',
    fonts: [{ src: 'https://fonts.gstatic.com/s/lora/v17/0QI6MX1D_JOuGQbT0gvTJPa787z5vCJG.ttf' }],
  });
  const stylesPdf = StyleSheet.create({
    body: {
      paddingTop: 15,
      paddingBottom: 5,
      paddingHorizontal: 35,
      alignItems: 'center',
      justifyContent: 'center',
      bottom: 175,
    },
    headerImage: {
      width: '24.5vw',
      objectFit: 'cover',
      alignItems: 'center',
      justifyContent: 'center',
    },
    subHeader: {
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: 15,
      paddingBottom: 1,
      flexWrap: 'wrap',
      maxWidth: 350,
      fontFamily: 'Helvetica-Bold',
      fontSize: 11,
      fontWeight: 900,
    },
    details: {
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
      paddingBottom: 10,
      paddingTop: 16,
    },
    contactInfo: {
      fontSize: 11,
      textTransform: 'capitalize',
      paddingBottom: 2,
    },
    recipientMessage: {
      marginTop: 16,
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      fontSize: 10.35,
      paddingHorizontal: 35,
      lineHeight: 1.3,
    },
  });
  const cities = receiptClinicCities.join(' - ');
  const createToFields = () => {
    if (!recipientName && !clinicName && !faxNumber) return <></>;
    return (
      <View style={stylesPdf.details}>
        <Text style={stylesPdf.subHeader}>To:</Text>
        <Text style={stylesPdf.contactInfo}>{recipientName}</Text>
        <Text style={stylesPdf.contactInfo}>{clinicName}</Text>
        <Text style={stylesPdf.contactInfo}>{formatPhoneInput(faxNumber)}</Text>
      </View>
    );
  };
  const createReFields = () => {
    if (!patientName && !dateOfBirth && !healthcard && !title) return <></>;
    return (
      <View style={stylesPdf.details}>
        <Text style={stylesPdf.subHeader}>Re:</Text>
        {patientName ? (
          <Text style={stylesPdf.contactInfo}>{`Patient: ${patientName}`}</Text>
        ) : (
          <></>
        )}
        {dateOfBirth ? (
          <Text style={stylesPdf.contactInfo}>{`D.O.B.: ${dateOfBirth}`}</Text>
        ) : (
          <></>
        )}
        {healthcard ? <Text style={stylesPdf.contactInfo}>{`HC: ${healthcard}`}</Text> : <></>}
      </View>
    );
  };

  const createNote = () => {
    if (!note) return <></>;
    return (
      <View style={stylesPdf.details}>
        <Text style={stylesPdf.subHeader}>Note:</Text>
        <Text style={stylesPdf.recipientMessage}>{note}</Text>
      </View>
    );
  };

  const CoverLetterLayout = (): ReactElement => {
    return (
      <Document>
        <Page style={stylesPdf.body}>
          <View style={stylesPdf.headerImage}>
            <PDFImage src={FullLogoTagline}></PDFImage>
          </View>
          <Text style={stylesPdf.subHeader}>From:</Text>
          <Text style={stylesPdf.contactInfo}>Canada Vein Clinic</Text>
          {createToFields()}
          {createReFields()}
          <Text style={stylesPdf.title}></Text>
          {createNote()}
        </Page>
      </Document>
    );
  };
  return {
    CoverLetterLayout,
  };
};

export default useNewFaxPDFRender;
