export const formatPhoneInput = value => {
  if (!value) return value;

  // only allows 0-9 inputs
  const currentValue = value.replace(/[^+0-9]*/g, '');
  const cvLength = currentValue.length;

  if (value.length > 0) {
    // has country code (eg: +1)
    if (value.includes('+')) {
      if (cvLength < 3) return currentValue;
      if (cvLength < 4) return `${currentValue.slice(0, 2)} ${currentValue.slice(2)}`;
      return `${currentValue.slice(0, 2)} (${currentValue.slice(2, 5)}) ${currentValue.slice(
        5,
        8
      )}-${currentValue.slice(8)}`;
    } else {
      if (cvLength < 4) return currentValue;
      if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;

      // has country code (eg: 1 647...)
      if (cvLength > 10) {
        return `${currentValue.slice(0, 1)} (${currentValue.slice(1, 4)}) ${currentValue.slice(
          4,
          7
        )}-${currentValue.slice(7)}`;

        // no country code
      } else {
        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6)}`;
      }
    }
  }
};

export default formatPhoneInput;

export const MAX_PHONE_CHARACTER_LENGTH = 14;
