import { useState, useEffect } from 'react';
import moment from 'moment';

import { getCaseByUserId } from 'api/case';
import { getAppointmentsByCaseId } from 'api/appointment';

import { AppointmentInfo } from 'api/appointment/appointment.interfaces';
import * as logger from 'api/logger';

export interface Appointment {
  loading: boolean;
  appointment: AppointmentInfo | null;
}

function useNextAppointment(patientId): Appointment {
  const [loading, setLoading] = useState(true);
  const [appointment, setAppointment] = useState<AppointmentInfo | null>(null);

  async function fetchNextAppointment(id: number | null): Promise<void> {
    setLoading(true);
    try {
      if (id) {
        const cases = await getCaseByUserId(id);
        if (cases.length) {
          const appointments = await getAppointmentsByCaseId(cases[0].id);

          // Find first next appointment
          const appointment = appointments.reduce((firstAppt: AppointmentInfo | null, appt) => {
            if (moment(appt.start).isAfter(moment())) {
              if (appt && appt.start && firstAppt && firstAppt.start) {
                return firstAppt.start < appt.start ? firstAppt : appt;
              }
              return appt;
            }
            return firstAppt;
          }, null);
          setAppointment(appointment || null);
        }
      }
    } catch (e) {
      logger.error(e.message);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (patientId) fetchNextAppointment(patientId).catch();
  }, [patientId]);

  return {
    loading,
    appointment,
  };
}

export default useNextAppointment;
