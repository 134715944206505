import React, { ReactElement, useEffect } from 'react';
import MuiAutocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { DateTimeFormatString } from 'lib/dateFormatter';
import moment from 'moment';

interface AutocompleteProps {
  options?: any;
  handlePatientChange?: (event, value) => void;
  openNewPatient?: () => void;
  value?: any;
  disabled?: boolean;
  patientSearchQuery: string;
  setPatientSearchQuery: (searchQuery: string) => void;
  loading: boolean;
}
function Autocomplete({
  options,
  handlePatientChange,
  value,
  openNewPatient,
  loading,
  disabled = false,
  patientSearchQuery,
  setPatientSearchQuery,
}: AutocompleteProps): ReactElement {
  useEffect(() => {
    const inputState = value ? value.fullName : '';
    setPatientSearchQuery(inputState);
  }, [value]);

  const displayInfo = option => {
    const fullName = option?.fullName ? option?.fullName : undefined;
    const dob = option?.patientInfo?.dateOfBirth
      ? moment(option?.patientInfo?.dateOfBirth).format(DateTimeFormatString.DayMonthYear)
      : '';
    const healthCard = option?.patientInfo?.healthCard ? option?.patientInfo?.healthCard : '';

    if (fullName) {
      let display = fullName;
      if (dob) display += ` - ${dob}`;
      if (healthCard) display += ` - ${healthCard}`;
      return display;
    }
    return undefined;
  };

  return (
    <MuiAutocomplete
      value={value}
      inputValue={patientSearchQuery}
      disabled={disabled}
      onInputChange={(event, newInputValue) => {
        setPatientSearchQuery(newInputValue);
      }}
      options={options}
      placeholder={'Select patient'}
      filterOptions={(options, { inputValue }) => {
        const result = options;
        result.unshift({ fullName: '+ Add New Patient' });
        return result;
      }}
      getOptionLabel={option => displayInfo(option) || option?.key}
      groupBy={option => option?.lastNameLetter}
      onChange={handlePatientChange}
      loading={loading}
      renderInput={params => <TextField {...params} label="Select a Patient" />}
      fullWidth
    />
  );
}

export default Autocomplete;
