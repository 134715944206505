import { useState, useEffect } from 'react';
import { CommunicationHistory } from 'types';

import { getCaseByUserId, getCommunicationHistory } from 'api/case';
import * as logger from 'api/logger';

export interface CommunicationsState {
  loading: boolean;
  caseId: number;
  communications: Array<CommunicationHistory> | null;
  fetchCommunicationHistory: (patientId: number) => Promise<void>;
}

function useCommunicationHistoryApi(patientId): CommunicationsState {
  const [loading, setLoading] = useState(false);
  const [caseId, setCaseId] = useState<number>(0);
  const [communications, setCommunications] = useState<Array<CommunicationHistory> | null>(null);

  async function fetchCommunicationHistory(patientId: number): Promise<void> {
    setLoading(true);
    try {
      const cases = await getCaseByUserId(patientId);
      const communications = await getCommunicationHistory(cases);
      setCaseId(cases[0].id);
      setCommunications(communications);
    } catch (e) {
      logger.error(e.message);
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchCommunicationHistory(patientId).catch();
  }, [patientId]);

  return {
    loading,
    caseId,
    communications,
    fetchCommunicationHistory,
  };
}

export default useCommunicationHistoryApi;
