import React, { Fragment, ReactElement, useState } from 'react';

import { Document as DocumentObj, MultipleNewDocuments, PatientInfo, User } from 'types';
import TitledSection from 'components/TitledSection';
import Button from 'components/CVCButton';
import { copyDocument } from 'api/document';
import { convertEditableMimeTypeToFileExtension } from 'lib/document';
import { formatDate, DateTimeFormat } from 'lib/dateFormatter';
import { DocumentsApiHookInterface } from '../../useDocumentsApi.hook';
import NewDocumentDialog from './components/NewDocumentDialog';
import SendDocumentDialog from './components/SendDocumentDialog';
import ActionConfirmationDialog from 'components/ActionConfirmationDialog';
import Document from './components/Document';
import styles from './DocumentsStyles.module.scss';
import SuccessSnackBar from 'components/SuccessSnackBar';
import RenameDocumentDialog from './components/RenameDocumentDialog';
import AddDocumentDialog from '../DefaultDocuments/components/AddDocumentDialog';
import { useFormik } from 'formik';
import PagePopup from 'pages/Patient/components/PagePopup';

interface DocumentsProps {
  patientId: number | string;
  history: any;
  documentsApiHook: DocumentsApiHookInterface;
  familyPhysicianEmail: string | undefined;
  familyPhysicianFax: string | undefined;
  patientEmail: string;
  patientName: string;
  patient: User | null;
}

function Documents({
  patientId,
  history,
  documentsApiHook,
  familyPhysicianEmail,
  familyPhysicianFax,
  patientName,
  patientEmail,
  patient,
}: DocumentsProps): ReactElement {
  const [isOpenNewDocumentDialog, setIsOpenNewDocumentDialog] = useState(false);
  const [isSendDocumentDialogOpen, toggleSendDocumentDialog] = useState(false);
  const [isFlaggedDocumentDialogOpen, setIsFlaggedDocumentDialogOpen] = useState<boolean>(false);
  const [flagDocument, setFlagDocument] = useState<DocumentObj | undefined>(undefined);
  const [selectedDocuments, setSelectedDocuments] = useState<Array<DocumentObj>>([]);
  const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
  const [renameDocumentId, setRenameDocumentId] = useState<number | string | undefined>(undefined);
  const [documentAdd, setDocumentAdd] = useState<DocumentObj>();
  const [openPagePopup, setOpenPagePopup] = useState<boolean>(false);
  const [selectedUrl, setSelectedUrl] = useState<any>();
  const [selectedType, setSelectedType] = useState<string>();
  const {
    loading,
    documents,
    documentTypes,
    copyDocument,
    createDocument,
    createMultipleDocuments,
    downloadDocument,
    updateDocument,
  } = documentsApiHook;

  const onSave = async (data: MultipleNewDocuments): Promise<PromiseSettledResult<any>[]> => {
    let response;
    if (data.length == 1) {
      response = await createDocument(data[0]);
    } else {
      response = await createMultipleDocuments(data);
    }
    return response;
  };

  const sendMultipleDocuments = () => {
    toggleSendDocumentDialog(true);
  };

  const getHeadContent = selectedDocuments => {
    return (
      <div className={styles.buttons}>
        <div className={styles.button}>
          <Button disabled={selectedDocuments.length === 0} onClick={sendMultipleDocuments}>
            Send Documents
          </Button>
        </div>
        <div className={styles.button}>
          <Button onClick={(): void => setIsOpenNewDocumentDialog(true)}>New Document</Button>
        </div>
      </div>
    );
  };

  // We Can only Edit certain types of documents
  const documentCanEdit = (document): boolean => {
    if (document.fileType && convertEditableMimeTypeToFileExtension(document.fileType)) {
      return true;
    }
    return false;
  };

  const onFlagDocument = (document: DocumentObj): void => {
    setFlagDocument(document);
    setIsFlaggedDocumentDialogOpen(true);
  };

  const updateFlagStatus = async () => {
    if (!flagDocument) return;
    updateDocument(flagDocument.id, { flagged: !flagDocument.flagged });
    setIsFlaggedDocumentDialogOpen(false);
  };

  const updateDocumentDetails = (documentId, params) => {
    updateDocument(documentId, params);
    setRenameDocumentId(undefined);
  };

  const flagDiscription = () => {
    return flagDocument?.flagged
      ? 'Are you sure you would like remove the flag from this document?'
      : 'Are you sure you would like to flag this document as incorrect?';
  };

  const onSaveDocument = async newDocumentName => {
    if (documentAdd) {
      copyDocument(newDocumentName, documentAdd.id);
      setDocumentAdd(undefined);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    onSubmit: async values => {
      onSaveDocument(values.name);
    },
  });

  const setSelectedDocument = (document: DocumentObj) => {
    const index = selectedDocuments.map(doc => doc.id).indexOf(document.id);
    const docs = [...selectedDocuments];
    if (index >= 0) {
      docs.splice(index, 1);
    } else {
      docs.push(document);
    }
    setSelectedDocuments(docs);
  };

  const setCopyDocument = (documentId: string | number | undefined) => {
    const document = documents.find(doc => doc.id == documentId);
    setDocumentAdd(document);
  };

  const openDocument = async (item, type) => {
    setOpenPagePopup(true);
    const result = await downloadDocument(item);
    setSelectedType(type);
    setSelectedUrl(result);
  };

  return (
    <Fragment>
      <TitledSection title="Documents" headContent={getHeadContent(selectedDocuments)}>
        {documents.map(item => (
          <Document
            id={item.id}
            key={`doc-${item.id}`}
            className={styles.document}
            title={item.folderName ? item.folderName + ' / ' + item.title : item.title}
            openDocument={(): void => {
              openDocument(item.id, item.fileType);
            }}
            editDocument={
              documentCanEdit(item)
                ? (): void => history?.push(`/patient/${patientId}/documents/${item.id}/edit`)
                : null
            }
            uploadedAt={item.uploadedAt}
            createdBy={item.createdBy}
            type={item.type}
            flagDocument={(): void => onFlagDocument(item)}
            flagged={item.flagged}
            selectDocument={() => setSelectedDocument(item)}
            selected={selectedDocuments.some(doc => item.id == doc.id)}
            setSnackBarOpen={setSnackBarOpen}
            setRenameDocumentId={setRenameDocumentId}
            setCopyDocument={setCopyDocument}
          />
        ))}
      </TitledSection>
      <AddDocumentDialog
        open={!!documentAdd}
        onClose={() => setDocumentAdd(undefined)}
        loading={loading}
        formik={formik}
        onSave={onSaveDocument}
        defaultDocumentName={(documentAdd && documentAdd.title) || ''}
      />
      <RenameDocumentDialog
        open={!!renameDocumentId}
        document={documents.find(doc => doc.id == renameDocumentId)}
        onClose={(): void => {
          setRenameDocumentId(undefined);
        }}
        onSave={updateDocumentDetails}
        documentTypes={documentTypes}
        loading={loading}
      />
      <NewDocumentDialog
        open={isOpenNewDocumentDialog}
        onClose={(): void => setIsOpenNewDocumentDialog(false)}
        loading={loading}
        onSave={onSave}
        documentTypes={documentTypes}
      />
      <SendDocumentDialog
        open={isSendDocumentDialogOpen}
        onClose={(): void => toggleSendDocumentDialog(false)}
        selectedDocuments={selectedDocuments}
        familyPhysicianEmail={familyPhysicianEmail}
        familyPhysicianFax={familyPhysicianFax}
        documentsApiHook={documentsApiHook}
        patientEmail={patientEmail}
        patientName={patientName}
        patientId={patientId}
        patient={patient}
        toggle={toggleSendDocumentDialog}
      />
      <ActionConfirmationDialog
        open={isFlaggedDocumentDialogOpen}
        title="Flag Document"
        description={flagDiscription()}
        onClose={() => setIsFlaggedDocumentDialogOpen(false)}
        onConfirm={() => updateFlagStatus()}
      />
      <SuccessSnackBar
        open={snackBarOpen}
        setOpen={setSnackBarOpen}
        message={'Document URL copied to clipboard'}
      />
      <PagePopup
        open={openPagePopup}
        url={selectedUrl}
        type={selectedType}
        onClose={() => setOpenPagePopup(false)}
      />
    </Fragment>
  );
}

export default Documents;
