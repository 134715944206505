import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import Tasks from 'components/Tasks';

// import styles from '../../../pages/Appointments/Appointments.module.scss';
import { ReactComponent as PlusIcon } from '../../../assets/svgs/Plus.svg';

import styles from './TaskSidebarStyles.module.scss';
import AssignNewTaskModal from '../../../components/NotesAndTasks/components/AssignNewTaskModal';
import { UserApi } from '../../../api';
import { Staff as UserStaff } from '../../../api/user/user.interfaces';
import { transformUserToStaff } from '../../../api/user/user.transformer';
import { User, Task } from '../../../types';
import * as logger from '../../../api/logger';

interface TaskSidebarProps {
  open: boolean;
  user: User | null;
  tasks: Task[];
  onClose(): void;
  createTask(patientId, newTask): void;
  fetchAllUserTasks(): Promise<void>;
  updateTask(taskId, task): void;
}

function TaskSidebar({
  open,
  user,
  tasks,
  onClose,
  createTask,
  fetchAllUserTasks,
  updateTask,
}: TaskSidebarProps): ReactElement {
  const [isAssignNewTaskModal, setIsAssignNewTaskModal] = useState(false);
  const [taskToEdit, setTaskToEdit] = useState<any>(null);
  const [description, setDescription] = useState('');
  const [staff, setStaff] = useState<User | null>(null);
  const [title, setTitle] = useState('');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [isEditTask, setIsEditTask] = useState(false);
  const [staffList, setStaffList] = useState<Array<UserStaff>>([]);
  const [patients, setPatients] = useState<Array<User>>([]);
  const [patient, setPatient] = useState<User | null>(null);
  const [loading, setLoading] = useState(false);
  const [showResponse, setShowResponse] = useState(false);
  const [response, setResponse] = useState<{ responseIcon: ReactNode; responseMessage: string }>({
    responseIcon: null,
    responseMessage: '',
  });

  async function getStaff(): Promise<void> {
    const users = await UserApi.getStaff('', undefined, '');
    let staffList: UserStaff[] = [];

    if (users) staffList = await transformUserToStaff(users);

    setStaffList(staffList);
  }

  function getPatients(searchQuery): void {
    UserApi.getPatientList(searchQuery).then(setPatients);
  }

  function resetForm(): void {
    setTitle('');
    setDescription('');
    setStaff(null);
  }

  async function changeTaskStatus(taskId, task): Promise<void> {
    try {
      await updateTask(taskId, task);
      await fetchAllUserTasks();
    } catch (e) {
      logger.error(e.message);
    }
  }

  async function onNewTaskSave(): Promise<void> {
    setLoading(true);
    if (taskToEdit) {
      const task = {
        title: taskToEdit?.title,
        description: taskToEdit?.description,
        staffId: staff?.id,
      };
      try {
        await updateTask(taskToEdit.id, task);
        setResponse({
          responseIcon: <CheckCircleOutlineIcon className={styles.success} />,
          responseMessage: 'Task created successfully!',
        });
      } catch (e) {
        setResponse({
          responseIcon: <ErrorOutlineIcon className={styles.error} />,
          responseMessage: 'Failed to create task',
        });
        logger.error(e.message);
      }
      await fetchAllUserTasks();
    } else {
      const newTask = {
        title,
        description,
        staffId: staff?.id,
      };
      try {
        await createTask(patient?.id, newTask);
        await fetchAllUserTasks();
        setResponse({
          responseIcon: <CheckCircleOutlineIcon className={styles.success} />,
          responseMessage: 'Task created successfully!',
        });
      } catch (e) {
        setResponse({
          responseIcon: <ErrorOutlineIcon className={styles.error} />,
          responseMessage: 'Failed to create task',
        });
        logger.error(e.message);
      }
    }
    setShowResponse(true);
    setLoading(false);
    resetForm();
  }

  useEffect(() => {
    getStaff().catch();
  }, []);

  useEffect(() => {
    if (open) fetchAllUserTasks().catch();
  }, [open]);

  useEffect(() => {
    getPatients('');
  }, []);

  useEffect(() => {
    if (searchQuery.length >= 2) {
      getPatients(searchQuery);
    }
  }, [searchQuery]);

  const appointmentsViewTasksHeadContent = (
    <div className={styles.titleContainer}>
      <div className={styles.tasksAmount}>{tasks.length ? tasks.length : 0}</div>
      <div className={styles.addBtnIcon} onClick={(): void => setIsAssignNewTaskModal(true)}>
        <PlusIcon />
      </div>
    </div>
  );

  function onCloseDialog(): void {
    setSearchQuery('');
    setTaskToEdit(null);
    setIsEditTask(false);
    setIsAssignNewTaskModal(false);
    setShowResponse(false);
    setResponse({
      responseIcon: null,
      responseMessage: '',
    });
    getPatients('');
  }

  return (
    <Drawer open={open} anchor="right" onClose={onClose}>
      <div className={styles.taskList}>
        <div className={styles.task}>
          <Tasks
            tasks={tasks}
            patient={user}
            headContent={appointmentsViewTasksHeadContent}
            setTaskToEdit={setTaskToEdit}
            changeTaskStatus={changeTaskStatus}
            onReassign={(): void => setIsEditTask(true)}
          />
          <AssignNewTaskModal
            open={isAssignNewTaskModal || isEditTask}
            isEditTask={isEditTask}
            taskToEdit={taskToEdit}
            loading={loading}
            patients={patients}
            patient={patient || (taskToEdit ? { fullName: taskToEdit.patientFullName } : null)}
            staff={staff}
            showResponse={showResponse}
            response={response}
            title={title}
            description={description}
            staffList={staffList}
            onTitleChange={setTitle}
            onDescriptionChange={setDescription}
            onChangeSearchQuery={setSearchQuery}
            onStaffSelect={setStaff}
            onPatientSelect={setPatient}
            onClose={onCloseDialog}
            onSave={onNewTaskSave}
          />
        </div>
      </div>
    </Drawer>
  );
}

export default TaskSidebar;
