import _ from 'lodash';
import { CaseResult } from 'types';
import moment from 'moment';
import formatPhoneInput from 'lib/phoneNumFormatter';
import CaseInsensitiveMap from 'lib/caseInsensitiveMap';

// Head Office: 37 William St. N Lindsay, ON K9V 3Z9 Phone: 1-888-876-8346 Fax: 705-880-1211</div>
// DATE: Wed Jun 03 2020
// RE: test test
// D.O.B.: October 10, 1990
// Thank you kindly for allowing us to participate in the care of Mr. test who is a pleasant 29 year old male. He was seen regarding both legs. He has itching. He tried compression stockings and they have helped. He has allergies to surgical tape. Test has never been diagnosed with blood clots. He has been treated previously for veins but does not remember how. Medical history consists of diabetes. He is on a blood thinner - Plavix.
// With regards to the right leg we found signs of corona phelebectatica, and spider veins.
// With regards to the left leg we found spider veins.
// Ultrasound Doppler examination was performed. right Leg GSV found Normal. SFJ found to be thrombosed on the right leg. SPJ found to be normal on the right leg. right ASV found Thrombosed. right PSV found Reflux. right SSV found Ablated, and Reflux. SSV ablated in the right leg found lower. Giacomini on the right leg was found to be thrombosed. No incompetent perforators on the right leg. No superficial vein thrombosis right leg. Left GSV found Thrombosed, Ablated, and Stripped. Ablation in the left leg GSV found below knee, and above knee. Left GSV thrombosed below knee. The left GSV was found stripped below knee. Left leg SFJ shown to be reflux. Reflux left leg SPJ. Left PSV found Thrombosed, and Reflux. Left SSV found Reflux. Lower reflux in the SSV. Left Giacomini normal. Incompetent Perforators in the left leg found in the following areas: AK Anterior Lateral. Left leg no visible varicosities. Left leg superficial vein thrombosis found in the following areas: AK Anterior Lateral.
// Assessment & Plan
// I discussed the results with test today and explained that he has chronic venous insufficiency. He has C4b disease in the right leg according to the CEAP classification. He has C4a disease in the left leg according to the CEAP classification. We have discussed treatment options including Venous Stripping and Endovenous Ablation. For his treatment I recommend Endovenous Ablation. With regards to the left leg I recommend Non-Thermal Ablation such as Clarivein.
// I have answered any of his questions or concerns including the possible complications and outcomes of treatment. We discussed that these procedures are not covered under the provincial health care plan and discussed the costs as well.
// I would recommend that he wear compression stockings with 20-30 mmHg. He will consider the treatment options and will let us know if he would like to proceed. Mr. Test might require Compression Sclerotherapy after the treatment, but we recommend waiting a few months in that case. Thank you again for the referral.
// Sincerely,
// Dictated, not read
// Dr. A. Matz MD, FACS, FRCSC.

const displayAnswer = (resultMap, answerKey) => {
  return resultMap.get(answerKey) && resultMap.get(answerKey).value;
};

const listText = entries => {
  if (!entries) {
    return '';
  }

  if (typeof entries === 'string') {
    return entries;
  }

  // Filter empty strings out
  const entriesUse = entries.filter(entry => !!entry);

  return entriesUse.length > 1
    ? `${entriesUse.slice(0, entriesUse.length - 1).join(', ')}, and ${
        entriesUse[entriesUse.length - 1]
      }`
    : `${entriesUse.join(', ')}`;
};

const legText1 = (leg, vein, result, hideLeg = false, byPassShowFullReflux = false) => {
  //hides the right leg or left leg part, and show just the vein part
  if (!result || result.length <= 0) {
    return '';
  }
  const containsReflux = listText(result).includes('reflux');
  if (byPassShowFullReflux && containsReflux) {
    return ` ${vein} found to be ${listText(result)}`;
  } else if (hideLeg && containsReflux) {
    return ` ${vein} reflux found`;
  } else if (hideLeg) {
    return ` ${vein} found to be ${listText(result)}`;
  }
  if (!byPassShowFullReflux && containsReflux) {
    return `${_.capitalize(leg === 'left' ? 'left leg' : 'right leg')} ${vein} reflux found. `;
  } else {
    return `${_.capitalize(
      leg === 'left' ? 'left leg' : 'right leg'
    )} ${vein} found to be ${listText(result)}. `;
  }
};

const areaText = (leg, issue, areas) => {
  if (!areas || areas.length <= 0) {
    return '';
  }

  return `${_.capitalize(issue)} found on the ${
    leg === 'left' ? 'left leg' : 'right leg'
  } at the following locations: ${_.lowerFirst(listText(areas))}. `;
};

const convertBoolean = valueConvert => {
  if (!valueConvert) {
    return false;
  }
  const isYes = Array.isArray(valueConvert)
    ? valueConvert[0].toUpperCase() === 'YES'
    : valueConvert.toUpperCase() === 'YES';

  return isYes;
};

// two arrays of results
const GSVResult = (aboveKneeResult, belowKneeResult) => {
  const bothEntries = _.intersection(aboveKneeResult, belowKneeResult).map(entry => {
    return `${entry} above and below the knee`;
  });
  const upperEntries = _.difference(aboveKneeResult, belowKneeResult).map(entry => {
    return `${entry} above knee`;
  });
  const lowerEntries = _.difference(belowKneeResult, aboveKneeResult).map(entry => {
    return `${entry} below knee`;
  });
  return _.concat(bothEntries, upperEntries, lowerEntries);
};

const SSVResult = (aboveKneeResult, belowKneeResult) => {
  const bothEntries = _.intersection(aboveKneeResult, belowKneeResult).map(entry => {
    return `upper and lower ${entry}`;
  });
  const upperEntries = _.difference(aboveKneeResult, belowKneeResult).map(entry => {
    return `upper ${entry}`;
  });
  const lowerEntries = _.difference(belowKneeResult, aboveKneeResult).map(entry => {
    return `lower ${entry}`;
  });
  return _.concat(bothEntries, upperEntries, lowerEntries);
};

export interface CoverLetterParagraphEntry {
  hide?: boolean;
  text: string;
}

type AnswerEntry = Array<string> | string;

interface CalculateCoverLetterParagraphsProps {
  name?: string;
  firstname?: string;
  lastname?: string;
  age?: string;
  gender?: string;
  genderPrefix?: string;
  genderPronoun?: string;
  affectedLegs?: string;
  patientMainConcern?: string;
  patientHowLongHadSymptoms?: string;
  patientSymptoms?: AnswerEntry;
  patientSymptomsAreWorseEOD?: boolean;
  issues?: string;
  allergies?: boolean;
  allergicTo?: AnswerEntry;
  usedCompressionStockings?: boolean;
  compressionStockingsWorked?: boolean;
  familyVaricoseVeins?: boolean;
  familyWhoHasVaricoseVeins?: AnswerEntry;
  bloodClots?: boolean;
  bloodClotsLocation?: AnswerEntry;
  whenDidYouHaveTheBloodClots?: AnswerEntry;
  previousTreatedFor?: boolean;
  previousTreatments?: AnswerEntry;
  medicalHistory?: AnswerEntry;
  bloodThinners?: boolean;
  usingBloodThinners?: AnswerEntry;
  rightLegIssues?: Array<string>;
  leftLegIssues?: Array<string>;
  rightGSVNormal?: boolean;
  rightAboveKneeGSVResult?: Array<string>;
  rightBelowKneeGSVResult?: Array<string>;
  rightLegGSVRefluxMaxDiameter?: string;
  rightLegFullyThrombosed?: boolean;
  rightLegPartiallyThrombosed?: boolean;
  LeftLegFullyThrombosed?: boolean;
  LeftLegPartiallyThrombosed?: boolean;
  rightSFJResult?: Array<string>;
  rightSPJResult?: Array<string>;
  rightASVResult?: Array<string>;
  rightPSVResult?: Array<string>;
  rightPSVRefluxMaxDiameter?: string;
  rightSSVNormal?: boolean;
  rightUpperSSVResult?: Array<string>;
  rightLowerSSVResult?: Array<string>;
  rightLegSSVRefluxMaxDiameter?: string;
  rightLegHasVisibleVaricosities?: boolean;
  rightGiacominiResult?: Array<string>;
  rightPerforatorsResult?: Array<string>;
  rightSuperficialVeinThrombosisResult?: Array<string>;
  rightLegComments?: string;
  LeftGSVNormal?: boolean;
  LeftAboveKneeGSVResult?: Array<string>;
  LeftBelowKneeGSVResult?: Array<string>;
  LeftLegGSVRefluxMaxDiameter?: string;
  LeftSFJResult?: Array<string>;
  LeftSPJResult?: Array<string>;
  LeftASVResult?: Array<string>;
  LeftPSVResult?: Array<string>;
  LeftPSVRefluxMaxDiameter?: string;
  LeftSSVNormal?: boolean;
  LeftUpperSSVResult?: Array<string>;
  LeftLowerSSVResult?: Array<string>;
  LeftLegSSVRefluxMaxDiameter?: string;
  LeftLegHasVisibleVaricosities?: boolean;
  LeftGiacominiResult?: Array<string>;
  LeftPerforatorsResult?: Array<string>;
  LeftSuperficialVeinThrombosisResult?: Array<string>;
  LeftLegComments?: string;
  diseases?: Array<string>;
  rightLegDiseases?: string;
  leftLegDiseases?: string;
  rightLegCEAP?: string;
  leftLegCEAP?: string;
  treatmentOptions?: Array<string>;
  genderAdverb?: string;
  genderPossessivePronoun?: string;
  recommendedOptions?: Array<string>;
  leftLegRecommendedOptions?: Array<string>;
  rightLegRecommendedOptions?: Array<string>;
  hasRecommendations?: boolean;
  whichLegSwellingEdema?: string;
  whichLegsSclerotherapySpiderVeins?: string;
  whichLegsSclerotherapyReticularVeins?: string;
  otherAssessment?: string;

  doctorName?: string;

  formCaseResult?: CaseResult;

  technicianComplete?: boolean;
  doctorFormComplete?: boolean;

  necessityLetter?: boolean;
  procedureDate?: Date;
}

const insertText = text => {
  if (typeof text === 'undefined') {
    return '*???*';
  }

  return text;
};

const getPossesiveS = lastname => {
  if (lastname && lastname.slice(-1)) {
    const lastCharacterInName = lastname.slice(-1);
    if (lastCharacterInName == 's' || lastCharacterInName == 'S') {
      return "'";
    }
    return "'s";
  }
};

const getGenderPronoun = gender => {
  switch (gender) {
    case 'male':
      return 'he';
    case 'female':
      return 'she';
    default:
      return 'the patient';
  }
};

const getgenderPossessivePronoun = gender => {
  switch (gender) {
    case 'male':
      return 'his';
    case 'female':
      return 'her';
    default:
      return 'the patients';
  }
};

const getGenderPrefix = gender => {
  switch (gender) {
    case 'male':
      return 'Mr';
    case 'female':
      return 'Ms';
    default:
      return 'Mx';
  }
};

const getGenderAdverb = gender => {
  switch (gender) {
    case 'male':
      return 'him';
    case 'female':
      return 'her';
    default:
      return 'the patient';
  }
};

interface CalculateLegThrombosedResult {
  isNormal: boolean;
  partiallyThrombosed: boolean;
  fullyThrombosed: boolean;
  refluxDiameter?: string;
  aboveKneeResult: Array<string>;
  belowKneeResult: Array<string>;
}

/// Calculate Leg Results
const valueEquals = (value, equalValue) => {
  if (!value && equalValue) {
    return false;
  }

  if (!value && !equalValue) {
    return true;
  }

  if (value === equalValue) {
    return true;
  }

  if (Array.isArray(value) && value.find(r => r === equalValue)) {
    return true;
  }

  return false;
};

const calculateUpperLowerLeg = ({
  issues,
  thrombosed,
  reflux,
  refluxDiameter,
  stripped,
  ablated,
}): CalculateLegThrombosedResult => {
  const results = {
    isNormal: valueEquals(issues, 'normal'),
    partiallyThrombosed: valueEquals(thrombosed, 'partially'),
    fullyThrombosed: valueEquals(thrombosed, 'fully'),
    refluxDiameter: refluxDiameter,
    aboveKneeResult: [] as Array<string>,
    belowKneeResult: [] as Array<string>,
  };

  if (valueEquals(thrombosed, 'above knee') || valueEquals(thrombosed, 'upper')) {
    results.aboveKneeResult.push('thrombosed');
  }

  if (valueEquals(thrombosed, 'below knee') || valueEquals(thrombosed, 'lower')) {
    results.belowKneeResult.push('thrombosed');
  }

  if (valueEquals(reflux, 'above knee') || valueEquals(reflux, 'upper')) {
    results.aboveKneeResult.push('reflux');
  }

  if (valueEquals(reflux, 'below knee') || valueEquals(reflux, 'lower')) {
    results.belowKneeResult.push('reflux');
  }

  if (valueEquals(stripped, 'above knee') || valueEquals(stripped, 'upper')) {
    results.aboveKneeResult.push('stripped');
  }

  if (valueEquals(stripped, 'below knee') || valueEquals(stripped, 'lower')) {
    results.belowKneeResult.push('stripped');
  }

  if (valueEquals(ablated, 'above knee') || valueEquals(ablated, 'upper')) {
    results.aboveKneeResult.push('ablated');
  }

  if (valueEquals(ablated, 'below knee') || valueEquals(ablated, 'lower')) {
    results.belowKneeResult.push('ablated');
  }

  return results;
};

const LeftLegGSVResults = resultMap => {
  const vein = calculateUpperLowerLeg({
    issues: resultMap.get('Tech::LeftLegGSV') && resultMap.get('Tech::LeftLegGSV').value,
    thrombosed:
      resultMap.get('Tech::LeftLegGSVthrombosed') &&
      resultMap.get('Tech::LeftLegGSVthrombosed').value,
    reflux:
      resultMap.get('Tech::LeftLegGSVreflux') && resultMap.get('Tech::LeftLegGSVreflux').value,
    refluxDiameter:
      resultMap.get('Tech::LeftLegGSVrefluxMaxDiameter') &&
      resultMap.get('Tech::LeftLegGSVrefluxMaxDiameter').value,
    stripped:
      resultMap.get('Tech::LeftLegGSVstripped') && resultMap.get('Tech::LeftLegGSVstripped').value,
    ablated:
      resultMap.get('Tech::LeftLegGSVablated') && resultMap.get('Tech::LeftLegGSVablated').value,
  });

  return {
    normal: vein.isNormal,
    aboveKneeResult: vein.aboveKneeResult,
    belowKneeResult: vein.belowKneeResult,
    legRefluxMaxDiameter: vein.refluxDiameter,
    legPartiallyThrombosed: vein.partiallyThrombosed,
    legFullyThrombosed: vein.fullyThrombosed,
  };
};

const rightLegGSVResults = resultMap => {
  const vein = calculateUpperLowerLeg({
    issues: resultMap.get('Tech::RightLegGSV') && resultMap.get('Tech::RightLegGSV').value,
    thrombosed:
      resultMap.get('Tech::RightLegGSVthrombosed') &&
      resultMap.get('Tech::RightLegGSVthrombosed').value,
    reflux:
      resultMap.get('Tech::RightLegGSVreflux') && resultMap.get('Tech::RightLegGSVreflux').value,
    refluxDiameter:
      resultMap.get('Tech::RightLegGSVrefluxMaxDiameter') &&
      resultMap.get('Tech::RightLegGSVrefluxMaxDiameter').value,
    stripped:
      resultMap.get('Tech::RightLegGSVstripped') &&
      resultMap.get('Tech::RightLegGSVstripped').value,
    ablated:
      resultMap.get('Tech::RightLegGSVablated') && resultMap.get('Tech::RightLegGSVablated').value,
  });
  return {
    normal: vein.isNormal,
    aboveKneeResult: vein.aboveKneeResult,
    belowKneeResult: vein.belowKneeResult,
    legRefluxMaxDiameter: vein.refluxDiameter,
    legPartiallyThrombosed: vein.partiallyThrombosed,
    legFullyThrombosed: vein.fullyThrombosed,
  };
};

const LeftLegSSVResults = resultMap => {
  const vein = calculateUpperLowerLeg({
    issues: resultMap.get('Tech::LeftLegSSV') && resultMap.get('Tech::LeftLegSSV').value,
    thrombosed:
      resultMap.get('Tech::LeftLegSSVthrombosed') &&
      resultMap.get('Tech::LeftLegSSVthrombosed').value,
    reflux:
      resultMap.get('Tech::LeftLegSSVreflux') && resultMap.get('Tech::LeftLegSSVreflux').value,
    refluxDiameter:
      resultMap.get('Tech::LeftLegSSVrefluxMaxDiameter') &&
      resultMap.get('Tech::LeftLegSSVrefluxMaxDiameter').value,
    stripped:
      resultMap.get('Tech::LeftLegSSVstripped') && resultMap.get('Tech::LeftLegSSVstripped').value,
    ablated:
      resultMap.get('Tech::LeftLegSSVablated') && resultMap.get('Tech::LeftLegSSVablated').value,
  });

  return {
    normal: vein.isNormal,
    aboveKneeResult: vein.aboveKneeResult,
    belowKneeResult: vein.belowKneeResult,
    legRefluxMaxDiameter: vein.refluxDiameter,
    legPartiallyThrombosed: vein.partiallyThrombosed,
    legFullyThrombosed: vein.fullyThrombosed,
  };
};

const rightLegSSVResults = resultMap => {
  const vein = calculateUpperLowerLeg({
    issues: resultMap.get('Tech::RightLegSSV') && resultMap.get('Tech::RightLegSSV').value,
    thrombosed:
      resultMap.get('Tech::RightLegSSVthrombosed') &&
      resultMap.get('Tech::RightLegSSVthrombosed').value,
    reflux:
      resultMap.get('Tech::RightLegSSVreflux') && resultMap.get('Tech::RightLegSSVreflux').value,
    refluxDiameter:
      resultMap.get('Tech::RightLegSSVrefluxMaxDiameter') &&
      resultMap.get('Tech::RightLegSSVrefluxMaxDiameter').value,
    stripped:
      resultMap.get('Tech::RightLegSSVstripped') &&
      resultMap.get('Tech::RightLegSSVstripped').value,
    ablated:
      resultMap.get('Tech::RightLegSSVAblated') && resultMap.get('Tech::RightLegSSVablated').value,
  });

  return {
    normal: vein.isNormal,
    aboveKneeResult: vein.aboveKneeResult,
    belowKneeResult: vein.belowKneeResult,
    legRefluxMaxDiameter: vein.refluxDiameter,
    legPartiallyThrombosed: vein.partiallyThrombosed,
    legFullyThrombosed: vein.fullyThrombosed,
  };
};

const calculatedPSVResults = (resultMap, leftLeg) => {
  let resultsRaw = '';
  let refluxLength = '';
  let refluxDiameter = '';
  if (leftLeg) {
    resultsRaw = displayAnswer(resultMap, 'Tech::LeftLegPSV') || '';
    refluxLength = displayAnswer(resultMap, 'Tech::LeftLegPSVrefluxLength') || '';
    refluxDiameter = displayAnswer(resultMap, 'Tech::LeftLegPSVrefluxMaxDiameter') || '';
  } else {
    resultsRaw = displayAnswer(resultMap, 'Tech::RightLegPSV') || '';
    refluxLength = displayAnswer(resultMap, 'Tech::RightLegPSVrefluxLength') || '';
    refluxDiameter = displayAnswer(resultMap, 'Tech::RightLegPSVrefluxMaxDiameter') || '';
  }

  // Get selected list of treatments
  const resultsUse: Array<string> = Array.isArray(resultsRaw) ? resultsRaw : [resultsRaw];

  const indexOfReflux = resultsUse.findIndex(result => result === 'reflux');
  if (indexOfReflux >= 0) {
    resultsUse.splice(indexOfReflux, 1);
    resultsUse.push(`reflux ${refluxLength ? `with length of ${refluxLength}` : ''}`);
    if (refluxDiameter) {
      resultsUse.push(`PSV max diameter is ${refluxDiameter}`);
    }
  }

  return resultsUse;
};

const calculatedASVResults = (resultMap, leftLeg) => {
  let resultsRaw = '';
  let refluxLength = '';
  let refluxDiameter = '';
  if (leftLeg) {
    resultsRaw = displayAnswer(resultMap, 'Tech::LeftLegASV') || '';
    refluxLength = displayAnswer(resultMap, 'Tech::LeftLegASVrefluxLength') || '';
    refluxDiameter = displayAnswer(resultMap, 'Tech::LeftLegASVrefluxMaxDiameter') || '';
  } else {
    resultsRaw = displayAnswer(resultMap, 'Tech::RightLegASV') || '';
    refluxLength = displayAnswer(resultMap, 'Tech::RightLegASVrefluxLength') || '';
    refluxDiameter = displayAnswer(resultMap, 'Tech::RightLegASVrefluxMaxDiameter') || '';
  }

  // Get selected list of treatments
  const resultsUse: Array<string> = Array.isArray(resultsRaw) ? resultsRaw : [resultsRaw];

  const indexOfReflux = resultsUse.findIndex(result => result === 'reflux');
  if (indexOfReflux >= 0) {
    resultsUse.splice(indexOfReflux, 1);
    resultsUse.push(`reflux ${refluxLength ? `with length of ${refluxLength}` : ''}`);
    if (refluxDiameter) {
      resultsUse.push(`ASV max diameter is ${refluxDiameter}`);
    }
  }

  return resultsUse;
};

const patientPreviousTreatmentsResults = resultMap => {
  // Get selected list of treatments
  const prevTreatmentRaw: Array<string> | string =
    (resultMap.get('Patient::Treatments') && resultMap.get('Patient::Treatments').value) || '';
  const prevTreatment: Array<string> = Array.isArray(prevTreatmentRaw)
    ? prevTreatmentRaw
    : [prevTreatmentRaw];

  // Calculate output
  const outputTreatmentsAccountedFor = new Set();
  const outputWithYear: Array<string> = [];
  if (resultMap.get('Patient::YearOfSclerotherapy')) {
    outputWithYear.push(
      `Sclerotherapy/Injections in ${resultMap.get('Patient::YearOfSclerotherapy').value}`
    );
    outputTreatmentsAccountedFor.add('sclerotherapy/injections');
  }
  if (resultMap.get('Patient::YearOfStripping')) {
    outputWithYear.push(`Stripping in ${resultMap.get('Patient::YearOfStripping').value}`);
    outputTreatmentsAccountedFor.add('stripping');
  }
  if (resultMap.get('Patient::YearOfLigation')) {
    outputWithYear.push(`Ligation in ${resultMap.get('Patient::YearOfLigation').value}`);
    outputTreatmentsAccountedFor.add('ligation');
  }
  if (resultMap.get('Patient::YearOfLaserAblation')) {
    outputWithYear.push(`Laser Ablation in ${resultMap.get('Patient::YearOfLaserAblation').value}`);
    outputTreatmentsAccountedFor.add('laser ablation');
    outputTreatmentsAccountedFor.add('laserablation');
  }
  if (resultMap.get('Patient::YearOfClarivein')) {
    outputWithYear.push(`Clarivein in ${resultMap.get('Patient::YearOfClarivein').value}`);
    outputTreatmentsAccountedFor.add('Clarivein');
  }
  if (resultMap.get('Patient::YearOfVenaseal')) {
    outputWithYear.push(`VenaSeal in ${resultMap.get('Patient::YearOfVenaseal').value}`);
    outputTreatmentsAccountedFor.add('VenaSeal');
  }
  if (resultMap.get('Patient::YearOfFoamSclerotherapy')) {
    outputWithYear.push(
      `Foam Sclerotherapy in ${resultMap.get('Patient::YearOfFoamSclerotherapy').value}`
    );
    outputTreatmentsAccountedFor.add('foamsclerotherapy');
  }

  const outputNoYear: Array<string> = prevTreatment.filter(
    // Do check in lowercase
    treatment => !outputTreatmentsAccountedFor.has(`${treatment}`.toLowerCase())
  );

  return [...outputNoYear, ...outputWithYear];
};

const patientMedicalHistoryResults = (resultMap, patientName, genderPronoun) => {
  const medicalHistoryRaw: Array<string> | string =
    (resultMap.get('Patient::MedicalHistory') && resultMap.get('Patient::MedicalHistory').value) ||
    '';
  const medicalHistory: Array<string> = Array.isArray(medicalHistoryRaw)
    ? medicalHistoryRaw
    : [medicalHistoryRaw];

  const medicalHistoryOther: string =
    resultMap.get('Patient::MedicalHistoryOther') &&
    resultMap.get('Patient::MedicalHistoryOther').value;
  if (
    medicalHistoryOther &&
    !medicalHistory.includes(medicalHistoryOther) // Do not push 'other' if it exists in medicalHistory already
  ) {
    medicalHistory.push(medicalHistoryOther);
  }

  const indexOfBleedingDisorder = medicalHistory.findIndex(name => name === 'A bleeding disorder');
  if (indexOfBleedingDisorder > 0) {
    medicalHistory.splice(indexOfBleedingDisorder, 1);
    if (resultMap.get('Patient::BleedingDisorderName')) {
      medicalHistory.push(
        `the bleeding disorder ${listText(resultMap.get('Patient::BleedingDisorderName').value)}`
      );
    } else {
      medicalHistory.push(
        `a bleeding disorder. ${insertText(
          _.capitalize(patientName)
        )} does not know which bleeding disorder ${genderPronoun} has`
      );
    }
  }

  return medicalHistory;
};

/// Doctor Results
const getLegIssues = (
  { LegIssues, VaricoseVeins, UlcerationMedial, UlcerationLateral },
  isLeftLeg,
  genderPossessivePronoun
) => {
  const results = [] as Array<string>;

  const legIssues = Array.isArray(LegIssues) ? LegIssues : [LegIssues];

  if (LegIssues) {
    legIssues.forEach(li => {
      switch ((li || '').toLowerCase()) {
        case 'varicose veins':
        case 'a lower leg ulceration':
          // do nothing for these.
          break;
        case 'spider veins':
          results.push('spider veins are noticeable');
          break;
        case 'signs of corona phelebectatica':
          results.push('there are signs of corona phelebectatica');
          break;
        case 'we found signs of lipodermatosclerosis':
          results.push('there are signs of lipodermatosclerosis');
          break;
        default:
          results.push(li);
          break;
      }
    });
  }

  let resultText = '';
  if (results.length > 0) {
    resultText = listText(results);
  }

  // One or the other not both Question is a radio button
  if (UlcerationMedial || UlcerationLateral) {
    let sentenceFragment = '';
    if (UlcerationMedial) {
      const leadingTerm =
        UlcerationMedial.toLowerCase() != 'active' ? 'a healed before' : 'an active';
      sentenceFragment = `${leadingTerm} lower leg ulceration is noticeable on the lower medial part of ${genderPossessivePronoun} calf and ankle`;
    } else if (UlcerationLateral) {
      const leadingTerm =
        UlcerationLateral.toLowerCase() != 'active' ? 'a healed before' : 'an active';
      sentenceFragment = `${leadingTerm} lower leg ulceration is noticeable on the lower lateral part of ${genderPossessivePronoun} calf and ankle`;
    }

    resultText += resultText ? `. ${_.capitalize(sentenceFragment)}` : sentenceFragment;
  }

  if (VaricoseVeins && VaricoseVeins.length > 0) {
    const sentenceFragment = `varicose veins are noticeable ${listText(VaricoseVeins)}`;
    resultText += resultText ? `. ${_.capitalize(sentenceFragment)}` : sentenceFragment;
  }

  return resultText;
};

const getLeftLegProblems = (resultMap, genderPossessivePronoun) => {
  return getLegIssues(
    {
      LegIssues: displayAnswer(resultMap, 'Doctor::LeftLegIssues'),
      VaricoseVeins: displayAnswer(resultMap, 'Doctor::LeftLegVaricoseVeins'),
      UlcerationMedial: displayAnswer(resultMap, 'Doctor::LeftLegLowerUlcerationMedial'),
      UlcerationLateral: displayAnswer(resultMap, 'Doctor::LeftLegLowerUlcerationLateral'),
    },
    true,
    genderPossessivePronoun
  );
};

const getrightLegProblems = (resultMap, genderPossessivePronoun) => {
  return getLegIssues(
    {
      LegIssues: displayAnswer(resultMap, 'Doctor::RightLegIssues'),
      VaricoseVeins: displayAnswer(resultMap, 'Doctor::RightLegVaricoseVeins'),
      UlcerationMedial: displayAnswer(resultMap, 'Doctor::RightLegLowerUlcerationMedial'),
      UlcerationLateral: displayAnswer(resultMap, 'Doctor::RightLegLowerUlcerationLateral'),
    },
    false,
    genderPossessivePronoun
  );
};

/// Recommendations
const getRecommendations = ({ listOfRecommendations, additionalRecommendations }) => {
  const listOfRecommendationsRaw = listOfRecommendations ? listOfRecommendations : [];
  const listOfRecommendationsUse = Array.isArray(listOfRecommendationsRaw)
    ? [...listOfRecommendationsRaw]
    : [listOfRecommendationsRaw];
  const finalListOfRecomendations = [] as Array<string>;

  listOfRecommendationsUse.forEach(li => {
    switch ((li || '').toLowerCase()) {
      case 'endovenous laser ablation':
        finalListOfRecomendations.push('Endovenous Laser Ablation');
        break;
      case 'endovenous laser ablation with mini phlebectomy':
        finalListOfRecomendations.push('Endovenous Laser Ablation with Mini Phlebectomy');
        break;
      case 'non-thermal ablation such as clarivein':
        finalListOfRecomendations.push(
          'Clarivein usually offers better cosmetic results and fewer complications and would be the preferred option for the treatment of the below knee vein segments (add to assessment)'
        );
        break;
      case 'non-thermal ablation such as venaseal':
        finalListOfRecomendations.push(
          'VenaSeal, which usually offers better cosmetic results and would be the preferred option for the treatment of the below knee vein segments'
        );
        break;
      case 'compression sclerotherapy':
        finalListOfRecomendations.push(
          'Compression Sclerotherapy because of inconsistent mild reflux'
        );
        break;
      case 'preferably non-thermal ablation - clarivein or venaseal':
        finalListOfRecomendations.push(
          'preferably Non-Thermal Ablation - Clarivein or VenaSeal. Both methods usually offer better cosmetic results and fewer complications for treatment of the below knee vein segments'
        );
        break;
    }
  });

  if (additionalRecommendations && listText(finalListOfRecomendations)) {
    finalListOfRecomendations.push(
      `in addition I recommend ${_.lowerFirst(additionalRecommendations)}`
    );
  } else if (additionalRecommendations) {
    finalListOfRecomendations.push(additionalRecommendations);
  }

  return finalListOfRecomendations.filter(emptyStrChecker => !!emptyStrChecker);
};

const getrightLegRecommendations = resultMap => {
  return getRecommendations({
    listOfRecommendations: displayAnswer(resultMap, 'Doctor::RecommendationRightLeg'),
    additionalRecommendations: displayAnswer(resultMap, 'Doctor::RecommendationRightLegAdditions'),
  });
};

const getAkBkToFullName = toProcess => {
  // Check for values first
  if (!toProcess) {
    return [];
  }

  // Check Array
  const originalStrs = Array.isArray(toProcess) ? toProcess : [toProcess];

  const out: Array<string> = [];
  originalStrs.forEach(str => {
    if (str) {
      let outStr = str.trim();
      outStr = outStr.replace(/ak/gi, 'Above knee');
      outStr = outStr.replace(/bk/gi, 'Below knee');
      out.push(outStr);
    }
  });

  return out;
};

const getLeftLegRecommendations = resultMap => {
  return getRecommendations({
    listOfRecommendations: displayAnswer(resultMap, 'Doctor::RecommendationLeftLeg'),
    additionalRecommendations: displayAnswer(resultMap, 'Doctor::RecommendationLeftLegAdditions'),
  });
};

const getEffectedLeg = (resultMap, genderPossessivePronoun) => {
  const affectedLegs = displayAnswer(resultMap, 'Doctor::WhichLegSeenFor');

  switch (affectedLegs) {
    case 'their left leg':
      return `${genderPossessivePronoun} left leg`;
    case 'their right leg':
      return `${genderPossessivePronoun} right leg`;
    case 'both legs':
      return 'both legs';
    default:
      return '';
  }
};

const getPatientSymptoms = resultMap => {
  const symptomsRaw = displayAnswer(resultMap, 'Patient::WhichSymptoms');
  const symptoms: Array<string> = Array.isArray(symptomsRaw) ? symptomsRaw : [symptomsRaw];
  const otherSymptoms = displayAnswer(resultMap, 'Patient::OtherSymptoms');
  let sympotomsOut: Array<string> = [];
  if (symptoms && symptoms.length > 0) {
    sympotomsOut = symptoms.filter(
      symptom => symptom && symptom.length > 0 && symptom.toUpperCase() !== 'OTHER'
    );
    if (otherSymptoms && sympotomsOut.length > 0) {
      sympotomsOut.push(`has other symptoms of ${otherSymptoms}`);
    } else if (otherSymptoms) {
      sympotomsOut.push(`other symptoms of ${otherSymptoms}`);
    }
    // TODO: Confirm Removal with Darin
    // else {
    //   sympotomsOut.push(`other symptoms`);
    // }
  }
  return sympotomsOut;
};

const getPatientAllergies = resultMap => {
  const allergiesRaw = displayAnswer(resultMap, 'Patient::AllergicTo');
  const allergies: Array<string> = Array.isArray(allergiesRaw) ? allergiesRaw : [allergiesRaw];
  const otherAllergies = displayAnswer(resultMap, 'Patient::allergyOtherSpecify');

  let allergiesOut: Array<string> = [];
  if (allergies && allergies.length > 0) {
    allergiesOut = allergies.filter(
      allergy => allergy && allergy.length > 0 && allergy.toUpperCase() !== 'OTHER'
    );

    if (allergiesOut.length < allergies.length) {
      if (otherAllergies && allergiesOut.length > 0) {
        allergiesOut.push(`has other allergies of ${otherAllergies}`);
      } else if (otherAllergies) {
        allergiesOut.push(`other allergies of ${otherAllergies}`);
      }
    }
  }
  return allergiesOut;
};

/// get Results
export const getCoverLetterResults = ({ answers }, editable) => {
  const resultMap = new CaseInsensitiveMap();

  answers.forEach(m => {
    resultMap.set(m.id, m);
  });

  const leftLegGSV = LeftLegGSVResults(resultMap);
  const rightLegGSV = rightLegGSVResults(resultMap);
  const leftLegSSV = LeftLegSSVResults(resultMap);
  const rightLegSSV = rightLegSSVResults(resultMap);
  const patientPreviousTreatments = patientPreviousTreatmentsResults(resultMap);
  const genderPrefix =
    displayAnswer(resultMap, 'PatientMetaData::prefix') ||
    getGenderPrefix(displayAnswer(resultMap, 'PatientMetaData::gender'));
  const genderPronoun =
    displayAnswer(resultMap, 'PatientMetaData::pronoun') ||
    getGenderPronoun(displayAnswer(resultMap, 'PatientMetaData::gender'));
  const genderAdverb =
    displayAnswer(resultMap, 'PatientMetaData::genderAdverb') ||
    getGenderAdverb(displayAnswer(resultMap, 'PatientMetaData::gender'));
  const genderPossessivePronoun =
    displayAnswer(resultMap, 'PatientMetaData::genderPossessivePronoun') ||
    getgenderPossessivePronoun(displayAnswer(resultMap, 'PatientMetaData::gender'));
  const name = displayAnswer(resultMap, 'PatientMetaData::firstName');

  const rawMap = {
    consultationOfficeAddress: displayAnswer(
      resultMap,
      'PatientMetaData::consultationLocationAddressPrimary'
    ),
    consultationOfficePrimaryNumber: formatPhoneInput(
      displayAnswer(resultMap, 'PatientMetaData::consultationLocationContactPrimaryNumber')
    ),
    consultationOfficeFaxNumber: formatPhoneInput(
      displayAnswer(resultMap, 'PatientMetaData::consultationLocationContactFaxNumber')
    ),
    date: moment().format('MMM Do YYYY'),
    title: `RE: Patient ${displayAnswer(
      resultMap,
      'PatientMetaData::fullName'
    )} D.O.B.: ${displayAnswer(resultMap, 'PatientMetaData::dateOfBirth')}`,
    name: name,
    firstname: displayAnswer(resultMap, 'PatientMetaData::firstName'),
    lastname: displayAnswer(resultMap, 'PatientMetaData::lastName'),
    dob: displayAnswer(resultMap, 'PatientMetaData::dateOfBirth'),
    age: displayAnswer(resultMap, 'PatientMetaData::age'),
    gender: displayAnswer(resultMap, 'PatientMetaData::gender'),
    genderPrefix: genderPrefix,
    genderPronoun: genderPronoun,
    genderAdverb: genderAdverb,
    genderPossessivePronoun: genderPossessivePronoun,
    affectedLegs: getEffectedLeg(resultMap, genderPossessivePronoun),
    patientMainConcern: displayAnswer(resultMap, 'Patient::MainConcern'),
    patientHowLongHadSymptoms: displayAnswer(resultMap, 'Patient::HowLongHadHadSymptoms'),
    patientSymptoms: getPatientSymptoms(resultMap),
    patientSymptomsAreWorseEOD: convertBoolean(
      displayAnswer(resultMap, 'Patient::AreSymptomsWorse')
    ),
    issues: displayAnswer(resultMap, 'Doctor::DrAssessment'),
    usedCompressionStockings: convertBoolean(
      displayAnswer(resultMap, 'Patient::TriedCompressionStockings')
    ),
    compressionStockingsWorked: convertBoolean(
      displayAnswer(resultMap, 'Patient::DidCompressionStockingsHelp')
    ),
    familyVaricoseVeins: convertBoolean(
      displayAnswer(resultMap, 'Patient::FamilyHistoryVaricoseVeins')
    ),
    familyWhoHasVaricoseVeins: displayAnswer(resultMap, 'Patient::WhoInFamilyHasVaricoseVeins'),
    allergies: convertBoolean(displayAnswer(resultMap, 'Patient::KnownAllergies')),
    allergicTo: getPatientAllergies(resultMap),
    bloodClots: convertBoolean(displayAnswer(resultMap, 'Patient::DiagnosedWithBloodClots')),
    bloodClotsLocation: displayAnswer(resultMap, 'Patient::WhereWereBloodClots?'),
    whenDidYouHaveTheBloodClots: displayAnswer(resultMap, 'Patient::WhenDidYouHaveTheBloodClots'),
    previousTreatedFor: convertBoolean(
      displayAnswer(resultMap, 'Patient::PreviouslyBeenTreatedByDoctor')
    ),
    previousTreatments: patientPreviousTreatments,
    medicalHistory: patientMedicalHistoryResults(resultMap, name, genderPronoun),
    bloodThinners: convertBoolean(displayAnswer(resultMap, 'Patient::BloodThinner')),
    usingBloodThinners: displayAnswer(resultMap, 'Patient::WhichBloodThinner'),
    rightLegIssues: displayAnswer(resultMap, 'Doctor::RightLegIssues'),
    leftLegIssues: displayAnswer(resultMap, 'Doctor::LeftLegIssues'),
    rightGSVNormal: rightLegGSV.normal,
    rightAboveKneeGSVResult: rightLegGSV.aboveKneeResult,
    rightBelowKneeGSVResult: rightLegGSV.belowKneeResult,
    rightLegGSVRefluxMaxDiameter: rightLegGSV.legRefluxMaxDiameter,
    rightLegFullyThrombosed: rightLegGSV.legFullyThrombosed,
    rightLegPartiallyThrombosed: rightLegGSV.legPartiallyThrombosed,
    rightSFJResult: displayAnswer(resultMap, 'Tech::RightLegSFJ'),
    rightSPJResult: displayAnswer(resultMap, 'Tech::RightLegSPJ'),
    rightASVResult: calculatedASVResults(resultMap, false),
    rightPSVResult: calculatedPSVResults(resultMap, false),
    rightSSVNormal: rightLegSSV.normal,
    rightUpperSSVResult: rightLegSSV.aboveKneeResult,
    rightLowerSSVResult: rightLegSSV.belowKneeResult,
    rightLegSSVRefluxMaxDiameter: rightLegSSV.legRefluxMaxDiameter,
    rightLegHasVisibleVaricosities: !valueEquals(
      displayAnswer(resultMap, 'Tech::RightLegHasVisibleVaricosities'),
      'no'
    ),
    rightGiacominiResult: displayAnswer(resultMap, 'Tech::RightLegGM'),
    rightPerforatorsResult: getAkBkToFullName(
      displayAnswer(resultMap, 'Tech::RightLegPerforators')
    ),
    rightSuperficialVeinThrombosisResult: getAkBkToFullName(
      displayAnswer(resultMap, 'Tech::RightLegSuperficialVeinThrombosis')
    ),
    rightLegComments: displayAnswer(resultMap, 'Tech::RightLegComments'),
    LeftGSVNormal: leftLegGSV.normal,
    LeftAboveKneeGSVResult: leftLegGSV.aboveKneeResult,
    LeftBelowKneeGSVResult: leftLegGSV.belowKneeResult,
    LeftLegGSVRefluxMaxDiameter: leftLegGSV.legRefluxMaxDiameter,
    LeftLegFullyThrombosed: leftLegGSV.legFullyThrombosed,
    LeftLegPartiallyThrombosed: leftLegGSV.legPartiallyThrombosed,
    LeftSFJResult: displayAnswer(resultMap, 'Tech::LeftLegSFJ'),
    LeftSPJResult: displayAnswer(resultMap, 'Tech::LeftLegSPJ'),
    LeftASVResult: calculatedASVResults(resultMap, true),
    LeftPSVResult: calculatedPSVResults(resultMap, true),
    LeftSSVNormal: leftLegSSV.normal,
    LeftUpperSSVResult: leftLegSSV.aboveKneeResult,
    LeftLowerSSVResult: leftLegSSV.belowKneeResult,
    LeftLegSSVRefluxMaxDiameter: leftLegSSV.legRefluxMaxDiameter,
    LeftLegHasVisibleVaricosities: !valueEquals(
      displayAnswer(resultMap, 'Tech::LeftLegHasVisibleVaricosities'),
      'no'
    ),
    LeftGiacominiResult: displayAnswer(resultMap, 'Tech::LeftLegGM'),
    LeftPerforatorsResult: getAkBkToFullName(displayAnswer(resultMap, 'Tech::LeftLegPerforators')),
    LeftSuperficialVeinThrombosisResult: getAkBkToFullName(
      displayAnswer(resultMap, 'Tech::LeftLegSuperficialVeinThrombosis')
    ),
    LeftLegComments: displayAnswer(resultMap, 'Tech::LeftLegComments'),
    diseases: displayAnswer(resultMap, 'Doctor::DrAssessment'),
    rightLegDiseases: getrightLegProblems(resultMap, genderPossessivePronoun),
    leftLegDiseases: getLeftLegProblems(resultMap, genderPossessivePronoun),
    rightLegCEAP: displayAnswer(
      resultMap,
      'Doctor::ProblematicLegsVenousinsufficiencyRightLegCEAP'
    ),
    leftLegCEAP: displayAnswer(resultMap, 'Doctor::ProblematicLegsVenousinsufficiencyLeftLegCEAP'),
    leftLegRecommendedOptions: getLeftLegRecommendations(resultMap),
    rightLegRecommendedOptions: getrightLegRecommendations(resultMap),
    hasRecommendations:
      getrightLegRecommendations(resultMap) || getLeftLegRecommendations(resultMap) ? true : false,
    whichLegSwellingEdema: displayAnswer(resultMap, 'Doctor::WhichLegSwellingEdema'),
    whichLegsSclerotherapySpiderVeins: displayAnswer(resultMap, 'Doctor::SclerotherapySpiderVeins'),
    whichLegsSclerotherapyReticularVeins: displayAnswer(
      resultMap,
      'Doctor::SclerotherapyReticularVeins'
    ),
    otherAssessment: displayAnswer(resultMap, 'Doctor::OtherAssessment'),
    doctorName: '*Your Name*',
    patientFormComplete: resultMap.get('Patient::MainConcern') ? true : false,
    technicianComplete: resultMap.get('Tech::LeftLegGSV') ? true : false,
    doctorFormComplete: resultMap.get('Doctor::WhichLegSeenFor') ? true : false,
    editable: editable,
  };

  return {
    ...rawMap,
    ...LeftLegGSVResults(resultMap),
    ...rightLegGSVResults(resultMap),
  };
};

export function calculateCoverLetterParagraphs({
  name,
  firstname,
  lastname,
  age,
  gender,
  genderPrefix,
  genderPronoun,
  affectedLegs,
  patientMainConcern,
  patientHowLongHadSymptoms,
  patientSymptoms,
  patientSymptomsAreWorseEOD,
  issues,
  usedCompressionStockings,
  compressionStockingsWorked,
  familyVaricoseVeins,
  familyWhoHasVaricoseVeins,
  allergies,
  allergicTo,
  bloodClots,
  bloodClotsLocation,
  whenDidYouHaveTheBloodClots,
  previousTreatedFor,
  previousTreatments,
  medicalHistory,
  bloodThinners,
  usingBloodThinners,
  rightLegIssues,
  leftLegIssues,
  rightGSVNormal,
  rightAboveKneeGSVResult,
  rightBelowKneeGSVResult,
  rightLegGSVRefluxMaxDiameter,
  rightSFJResult,
  rightSPJResult,
  rightASVResult,
  rightPSVResult,
  rightSSVNormal,
  rightUpperSSVResult,
  rightLowerSSVResult,
  rightLegSSVRefluxMaxDiameter,
  rightLegHasVisibleVaricosities,
  rightGiacominiResult,
  rightPerforatorsResult,
  rightSuperficialVeinThrombosisResult,
  rightLegComments,
  LeftGSVNormal,
  LeftAboveKneeGSVResult,
  LeftBelowKneeGSVResult,
  LeftLegGSVRefluxMaxDiameter,
  LeftSFJResult,
  LeftSPJResult,
  LeftASVResult,
  LeftPSVResult,
  LeftPSVRefluxMaxDiameter,
  LeftSSVNormal,
  LeftUpperSSVResult,
  LeftLowerSSVResult,
  LeftLegSSVRefluxMaxDiameter,
  LeftLegHasVisibleVaricosities,
  LeftGiacominiResult,
  LeftPerforatorsResult,
  LeftSuperficialVeinThrombosisResult,
  LeftLegComments,
  diseases,
  rightLegDiseases,
  leftLegDiseases,
  rightLegCEAP,
  leftLegCEAP,
  genderAdverb,
  genderPossessivePronoun,

  leftLegRecommendedOptions,
  rightLegRecommendedOptions,
  hasRecommendations,
  whichLegSwellingEdema,
  whichLegsSclerotherapySpiderVeins,
  whichLegsSclerotherapyReticularVeins,
  otherAssessment,

  technicianComplete,
  doctorFormComplete,
  rightLegFullyThrombosed,
  rightLegPartiallyThrombosed,
  LeftLegFullyThrombosed,
  LeftLegPartiallyThrombosed,
  necessityLetter,
  procedureDate,
}: CalculateCoverLetterParagraphsProps) {
  // TODO: come as an option

  const genderIsOther = !(gender == 'male' || gender == 'female');
  const drLymph = listText(issues).toLowerCase() === 'lymphedema';
  const drScleo = listText(issues).toLowerCase() === 'sclerotherapy';
  const drVenos = listText(issues).toLowerCase() === 'venous insufficiency';
  const drOther = listText(issues).toLowerCase() === 'other';

  const NameCreation = {
    rotation: 0,
    getName: function(sentenceStart: boolean, plural: boolean) {
      let text = '';
      switch (this.rotation) {
        case 0:
          text += `${insertText(_.capitalize(name))}`;
          text += plural ? getPossesiveS(insertText(_.capitalize(name))) : '';
          break;
        case 1:
          if (plural) {
            text += sentenceStart
              ? `${insertText(_.capitalize(genderPossessivePronoun))}`
              : `${insertText(genderPossessivePronoun)}`;
          } else {
            text += sentenceStart
              ? `${insertText(_.capitalize(genderPronoun))}`
              : `${insertText(genderPronoun)}`;
          }
          break;
        case 2:
          text += `${insertText(_.capitalize(genderPrefix))}. ${insertText(
            _.capitalize(lastname)
          )}`;
          text += plural ? getPossesiveS(insertText(_.capitalize(lastname))) : '';
          break;
      }
      this.rotation = ++this.rotation % 3;
      return text;
    },
  };

  let paragraphDefaults;

  if (necessityLetter) {
    paragraphDefaults = [
      {
        hide: !(
          name ||
          firstname ||
          lastname ||
          age ||
          gender ||
          genderPronoun ||
          genderPrefix ||
          affectedLegs ||
          genderAdverb ||
          genderPossessivePronoun ||
          issues
        ),
        text: `${
          !(patientMainConcern || patientHowLongHadSymptoms)
            ? ''
            : `${NameCreation.getName(
                true,
                false
              )} had a medical procedure in our clinic on ${moment(procedureDate).format(
                'MMMM Do YYYY'
              )} regarding ${genderAdverb} legs. ${_.capitalize(
                genderAdverb
              )} main concern is ${insertText(
                _.lowerFirst(patientMainConcern)
              )} with symptoms over the last ${patientHowLongHadSymptoms}.`
        } ${
          listText(patientSymptoms)
            ? `${NameCreation.getName(true, false)} has been complaining about ${listText(
                patientSymptoms
              )}.${
                patientSymptomsAreWorseEOD ? ' Symptoms appear worse at the end of the day.' : ''
              }`
            : ``
        } ${
          !familyVaricoseVeins
            ? ``
            : ` There is a family history of varicose veins, ${
                listText(familyWhoHasVaricoseVeins)
                  ? `specifically related to ${NameCreation.getName(false, true)} ${listText(
                      familyWhoHasVaricoseVeins
                    )}.`
                  : '.'
              }`
        } ${
          !allergies
            ? `${NameCreation.getName(true, false)} has no known allergies.`
            : `${NameCreation.getName(true, false)} has allergies${
                listText(allergicTo) ? ` to ${listText(allergicTo)}.` : `.`
              }`
        }`,
      },
      {
        text: `
          ${
            !rightLegCEAP
              ? ''
              : `Upon Examination the patient has ${rightLegCEAP} disease in the right leg according to the CEAP classification`
          } ${
          !leftLegCEAP
            ? ''
            : `${
                !rightLegCEAP ? 'Upon Examination the patient has ' : ` and `
              }${leftLegCEAP} disease in the left leg according to the CEAP classification`
        }.`,
      },
      {
        hide: false,
        text: `The Venaseal procedure was performed as a treatment for Chronic venous insufficiency for medical reason.`,
      },
      {
        hide: false,
        text: `If you require further information, please let us know.`,
      },
    ];
  } else {
    paragraphDefaults = [
      {
        hide: !(
          name ||
          firstname ||
          lastname ||
          age ||
          gender ||
          genderPronoun ||
          genderPrefix ||
          affectedLegs ||
          genderAdverb ||
          genderPossessivePronoun ||
          issues
        ),
        text: `Thank you kindly for allowing us to participate in the care of ${
          genderIsOther
            ? `${insertText(_.capitalize(name))} ${insertText(_.capitalize(lastname))}`
            : `${insertText(_.capitalize(genderPrefix))}. ${insertText(lastname)}`
        } who is a pleasant ${insertText(age)} year old ${
          genderIsOther ? '' : ` ${insertText(gender)}`
        }. ${
          insertText(affectedLegs)
            ? `${NameCreation.getName(true, false)} was seen regarding ${insertText(affectedLegs)}.`
            : ``
        } ${
          !(patientMainConcern || patientHowLongHadSymptoms)
            ? ''
            : ` ${NameCreation.getName(true, true)} main concern is ${insertText(
                _.lowerFirst(patientMainConcern)
              )} with symptoms over the last ${patientHowLongHadSymptoms}.`
        } ${
          listText(patientSymptoms)
            ? `${NameCreation.getName(true, false)} has been complaining about ${listText(
                patientSymptoms
              )}.${
                patientSymptomsAreWorseEOD ? ' Symptoms appear worse at the end of the day.' : ''
              }`
            : ``
        } ${
          !usedCompressionStockings
            ? `${NameCreation.getName(true, false)} has not tried compression stockings.`
            : `${NameCreation.getName(true, false)} has tried compression stockings and it ${
                compressionStockingsWorked ? 'helped.' : 'did not help.'
              }`
        } ${
          !familyVaricoseVeins
            ? ``
            : ` There is a family history of varicose veins, ${
                listText(familyWhoHasVaricoseVeins)
                  ? `specifically related to ${NameCreation.getName(false, true)} ${listText(
                      familyWhoHasVaricoseVeins
                    )}.`
                  : '.'
              }`
        } ${
          !allergies
            ? `${NameCreation.getName(true, false)} has no known allergies.`
            : `${NameCreation.getName(true, false)} has allergies${
                listText(allergicTo) ? ` to ${listText(allergicTo)}.` : `.`
              }`
        }
        `,
      },
      {
        hide: !(
          name ||
          lastname ||
          age ||
          gender ||
          genderPronoun ||
          genderPrefix ||
          affectedLegs ||
          genderAdverb ||
          genderPossessivePronoun ||
          issues
        ),
        text: `
          ${
            bloodClots && whenDidYouHaveTheBloodClots && !bloodClotsLocation
              ? `${NameCreation.getName(true, false)} had blood clots ${listText(
                  whenDidYouHaveTheBloodClots
                )}.`
              : ``
          } ${
          bloodClots && !whenDidYouHaveTheBloodClots && bloodClotsLocation
            ? `${NameCreation.getName(true, false)} had blood clots in the ${listText(
                bloodClotsLocation
              )}.`
            : ``
        } ${
          bloodClots && whenDidYouHaveTheBloodClots && bloodClotsLocation
            ? `${NameCreation.getName(true, false)} had blood clots ${listText(
                whenDidYouHaveTheBloodClots
              )} in the ${listText(bloodClotsLocation)}.`
            : ``
        } ${
          !bloodClots && !whenDidYouHaveTheBloodClots && !bloodClotsLocation
            ? `${NameCreation.getName(true, false)} has never been diagnosed with blood clots.`
            : ``
        } ${
          !previousTreatedFor
            ? ''
            : `${NameCreation.getName(true, false)} ${
                listText(previousTreatments)
                  ? `underwent ${listText(previousTreatments)}.`
                  : `has been treated previously for veins but does not remember treatment name.`
              }`
        } ${
          !listText(medicalHistory)
            ? ''
            : `${NameCreation.getName(true, true)} medical history consists of ${listText(
                medicalHistory
              )}.`
        } ${
          !bloodThinners
            ? ''
            : `${NameCreation.getName(true, false)} is currently taking a blood thinner${
                listText(usingBloodThinners) ? ` - ${listText(usingBloodThinners)}` : ``
              }.`
        }`,
      },
      {
        hide: !(
          name ||
          lastname ||
          age ||
          gender ||
          genderPronoun ||
          genderPrefix ||
          affectedLegs ||
          genderAdverb ||
          genderPossessivePronoun ||
          issues
        ),
        text: `
          ${
            drLymph
              ? `On examination, we found swelling of ${
                  insertText(whichLegSwellingEdema) === 'both legs' ? '' : `the `
                }${insertText(
                  whichLegSwellingEdema
                )} and edema. No visible varicosities or spider veins present.`
              : ''
          } ${
          drScleo
            ? `On examination, spider veins found in ${
                insertText(whichLegsSclerotherapySpiderVeins) === 'both legs' ? '' : `the `
              }${insertText(whichLegsSclerotherapySpiderVeins)}. `
            : ''
        } ${
          drScleo
            ? `Reticular veins found in ${
                insertText(whichLegsSclerotherapyReticularVeins) === 'both legs' ? '' : `the `
              }${insertText(whichLegsSclerotherapyReticularVeins)}. `
            : ''
        } ${
          // If right & left leg to be displayed
          drVenos && rightLegDiseases && leftLegDiseases
            ? `On examination, with regard to the right leg ${listText(
                rightLegDiseases
              )}; on the left leg ${listText(leftLegDiseases)}.`
            : ''
        } ${
          drVenos && rightLegDiseases && !leftLegDiseases
            ? `On examination, with regard to the right leg ${listText(rightLegDiseases)}.`
            : ''
        } ${
          drVenos && !rightLegDiseases && leftLegDiseases
            ? `On examination, with regard to the left leg ${listText(leftLegDiseases)}.`
            : ''
        } ${
          // If not one specfic issue list them all
          !drLymph && !drScleo && !drVenos && !drOther && listText(issues)
            ? `${NameCreation.getName(true, false)} has ${listText(issues)}.`
            : ``
        } 
        `,
      },
      {
        hide: !technicianComplete,
        text: `Ultrasound Doppler examination was performed.`,
      },
      {
        hide: !technicianComplete,
        text: `On the right leg ${[
          rightGSVNormal ? 'GSV found to be Normal' : '',
          rightLegFullyThrombosed ? 'GSV found to be Fully Thrombosed' : '',
          rightLegPartiallyThrombosed ? 'GSV found to be Partially Thrombosed' : '',
          legText1(
            'right',
            'GSV',
            GSVResult(rightAboveKneeGSVResult, rightBelowKneeGSVResult),
            true,
            true
          ),
          rightLegGSVRefluxMaxDiameter ? `GSV max diameter is ${rightLegGSVRefluxMaxDiameter}` : '',
          legText1('right', 'SFJ', rightSFJResult, true),
          legText1('right', 'SPJ', rightSPJResult, true),
          legText1('right', 'ASV', rightASVResult, true, true),
          legText1('right', 'PSV', rightPSVResult, true, true),
          rightSSVNormal ? 'SSV found to be Normal' : '',
          legText1(
            'right',
            'SSV',
            SSVResult(rightUpperSSVResult, rightLowerSSVResult),
            false,
            true
          ),
          rightGiacominiResult ? legText1('right', 'giacomini', rightGiacominiResult, true) : '',
          rightLegSSVRefluxMaxDiameter ? `SSV max diameter is ${rightLegSSVRefluxMaxDiameter}` : '',
          !rightGiacominiResult ? 'giacomini is not detected' : '',
          !rightPerforatorsResult ? 'No incompetent perforators on right leg found' : '',
        ]
          .filter(entry => !!entry)
          .join(', ')}. ${[
          rightPerforatorsResult
            ? areaText('right', 'Incompetent Perforators', rightPerforatorsResult)
            : '',
          !rightLegHasVisibleVaricosities ? 'No visible varicosities found on right leg.' : '',
          areaText('right', 'Superficial Vein Thrombosis', rightSuperficialVeinThrombosisResult),
        ]
          .filter(entry => !!entry)
          .join(' ')}`,
      },
      {
        hide: !technicianComplete || !rightLegComments,
        text: `Additional comments for the right leg: ${rightLegComments}.`,
      },
      {
        hide: !technicianComplete,
        text: `On the left leg ${[
          LeftGSVNormal ? 'GSV found to be Normal' : '',
          LeftLegFullyThrombosed ? 'GSV found to be Fully Thrombosed' : '',
          LeftLegPartiallyThrombosed ? 'GSV found to be Partially Thrombosed' : '',
          legText1(
            'left',
            'GSV',
            GSVResult(LeftAboveKneeGSVResult, LeftBelowKneeGSVResult),
            true,
            true
          ),
          LeftLegGSVRefluxMaxDiameter ? `GSV max diameter is ${LeftLegGSVRefluxMaxDiameter}` : '',
          legText1('left', 'SFJ', LeftSFJResult, true),
          legText1('left', 'SPJ', LeftSPJResult, true),
          legText1('left', 'ASV', LeftASVResult, true, true),
          legText1('left', 'PSV', LeftPSVResult, true, true),
          LeftSSVNormal ? 'SSV found to be Normal' : '',
          legText1('left', 'SSV', SSVResult(LeftUpperSSVResult, LeftLowerSSVResult), false, true),
          LeftGiacominiResult ? legText1('left', 'giacomini', rightGiacominiResult, true) : '',
          LeftLegSSVRefluxMaxDiameter ? `SSV max diameter is ${LeftLegSSVRefluxMaxDiameter}` : '',
          !LeftGiacominiResult ? 'giacomini is not detected' : '',
        ]
          .filter(entry => !!entry)
          .join(', ')}. ${[
          LeftPerforatorsResult
            ? areaText('left', 'Incompetent Perforators', LeftPerforatorsResult)
            : '',
          !LeftLegHasVisibleVaricosities ? 'No incompetent perforators found on left leg.' : '',
          areaText('left', 'Superficial Vein Thrombosis', LeftSuperficialVeinThrombosisResult),
        ]
          .filter(entry => !!entry)
          .join(' ')}`,
      },
      {
        hide: !technicianComplete || !LeftLegComments,
        text: `Additional comments for the left leg: ${LeftLegComments}.`,
      },
      {
        hide: !doctorFormComplete,
        field: true,
        text: 'Assessment & Plan',
      },
      {
        hide: !doctorFormComplete || !drOther,
        text: `${otherAssessment}`,
      },
      {
        hide: !doctorFormComplete || drVenos,
        text: `
        ${
          !listText(rightLegRecommendedOptions) && !listText(leftLegRecommendedOptions)
            ? ''
            : ` For ${NameCreation.getName(false, true)} treatment I recommend`
        } ${
          !listText(rightLegRecommendedOptions)
            ? ''
            : ` ${listText(rightLegRecommendedOptions)} for the right leg.`
        } ${
          !listText(leftLegRecommendedOptions)
            ? ''
            : ` ${
                listText(rightLegRecommendedOptions) ? 'For the left leg I recommend ' : ''
              }${listText(leftLegRecommendedOptions)}${
                !listText(rightLegRecommendedOptions) ? ' for the left leg' : ''
              }.`
        }`,
      },
      {
        hide: !doctorFormComplete || !drLymph,
        text: `I explained the results to ${NameCreation.getName(
          false,
          false
        )} today, ${NameCreation.getName(
          false,
          false
        )} does not have signs of significant chronic venous insufficiency. ${NameCreation.getName(
          true,
          true
        )} symptoms such as edema and swelling of the lower leg is probably related to lymphostasis or lymphedema.`,
      },
      {
        hide: !doctorFormComplete || !drLymph,
        text: `For further management I would recommend conservative measure which include  wearing compression stockings with 20-30 mmHg, having regular massages for lymphatic drainage and specific physiotherapy. I did explain to ${NameCreation.getName(
          false,
          false
        )} that this is a chronic problem and unfortunately cannot be resolved with any types of intervention with promising results so far. Thank you again for the referral.`,
      },
      {
        hide: !doctorFormComplete || !drScleo,
        text: `I explained the results to ${NameCreation.getName(
          false,
          false
        )} today and discussed treatment options with ${NameCreation.getName(
          false,
          false
        )}. ${NameCreation.getName(
          true,
          false
        )} does not have significant venous insufficiency present. ${NameCreation.getName(
          true,
          false
        )} is mostly concerned about appearance of ${NameCreation.getName(
          false,
          true
        )} legs and for that I would recommend Compression Sclerotherapy for the affected areas.`,
      },
      {
        hide: !doctorFormComplete || !drScleo,
        text: `I discussed this treatment option with them including the possible complications and outcomes including but not limited to discoloration, pain, swelling, possible allergic reaction or even blood clots. I have answered any of ${NameCreation.getName(
          false,
          true
        )} questions or concerns. We discussed that this procedure is not covered under the provincial health care plan and discussed the costs as well. ${NameCreation.getName(
          true,
          false
        )} is aware that several sessions may be required.`,
      },
      {
        hide: !doctorFormComplete || !drScleo,
        text: `I would recommend that ${NameCreation.getName(
          false,
          false
        )} wears compression stockings 20-30 mmHg. ${NameCreation.getName(
          true,
          false
        )} will consider the treatment options and will let us know if ${NameCreation.getName(
          false,
          false
        )} would like to proceed with the treatment. Thank you again for the referral.`,
      },
      {
        hide: !doctorFormComplete || !drVenos,
        text: `I discussed the results with ${NameCreation.getName(
          false,
          false
        )} today and explained that ${NameCreation.getName(
          false,
          false
        )} has chronic venous insufficiency which probably explains ${NameCreation.getName(
          false,
          true
        )} symptoms.`,
      },
      {
        hide: !doctorFormComplete,
        text: `
          ${
            !!rightLegCEAP || !!leftLegCEAP
              ? ' We have discussed treatment options including Venous Stripping and Endovenous Ablation including their success rates and possible downtime and complications.'
              : ''
          }
          ${
            !rightLegCEAP
              ? ''
              : ` The patient has ${rightLegCEAP} disease in the right leg according to the CEAP classification.`
          } ${
          !listText(rightLegRecommendedOptions)
            ? ''
            : ` For the treatment on  ${NameCreation.getName(
                false,
                true
              )} right leg I recommend ${listText(rightLegRecommendedOptions)}.`
        } ${
          !leftLegCEAP
            ? ''
            : ` The patient has ${leftLegCEAP} disease in the left leg according to the CEAP classification.`
        } ${
          !listText(leftLegRecommendedOptions)
            ? ''
            : ` For the treatment on  ${NameCreation.getName(
                false,
                true
              )} left leg I recommend ${listText(leftLegRecommendedOptions)}.`
        }`,
      },
      {
        hide: !doctorFormComplete || !drVenos,
        text: `I have answered any of ${NameCreation.getName(
          false,
          true
        )} questions or concerns including the possible complications and outcomes of treatment. We discussed that these procedures are not covered under the provincial health care plan and discussed the costs as well.`,
      },
      {
        hide: !doctorFormComplete || !drVenos,
        text: `I would recommend that ${NameCreation.getName(
          false,
          false
        )} wears compression stockings 20-30 mmHg. ${NameCreation.getName(
          true,
          false
        )} will consider the treatment options and will let us know if ${NameCreation.getName(
          false,
          false
        )} would like to proceed. ${NameCreation.getName(
          true,
          false
        )} might require Compression Sclerotherapy after the treatment, but we recommend waiting a few months in that case. Thank you again for the referral.`,
      },
      {
        hide: true,
        text: ` 
          ${rightLegPartiallyThrombosed ? 'Right Leg found to be Partially Thrombosed.' : ''}
          ${legText1('right', 'GSV', GSVResult(rightAboveKneeGSVResult, rightBelowKneeGSVResult))}
          ${legText1('right', 'SFJ', rightSFJResult)}
          ${legText1('right', 'SPJ', rightSPJResult)}
          ${legText1('right', 'ASV', rightASVResult)}
          ${legText1('right', 'PSV', rightPSVResult)}
          ${legText1('right', 'SSV', SSVResult(rightUpperSSVResult, rightLowerSSVResult))}
        `,
      },
      {
        hide: true,
        text: `
          ${LeftLegPartiallyThrombosed ? 'Left Leg found to be Partially Thrombosed.' : ''}
          ${legText1('left', 'GSV', GSVResult(LeftAboveKneeGSVResult, LeftBelowKneeGSVResult))}
          ${legText1('left', 'SFJ', LeftSFJResult)}
          ${legText1('left', 'SPJ', LeftSPJResult)}
          ${legText1('left', 'ASV', LeftASVResult)}
          ${legText1('left', 'PSV', LeftPSVResult)}
          ${legText1('left', 'SSV', SSVResult(LeftUpperSSVResult, LeftLowerSSVResult))}
        `,
      },
      {
        hide: true,
        text: `
        ${areaText('right', 'Giacomini', rightGiacominiResult)}
        ${areaText('right', 'Incompetent Perforators', rightPerforatorsResult)}
        ${areaText('right', 'Superficial Vein Thrombosis', rightSuperficialVeinThrombosisResult)}
        ${areaText('left', 'Giacomini', LeftGiacominiResult)}
        ${areaText('left', 'Incompetent Perforators', LeftPerforatorsResult)}
        ${areaText('left', 'Superficial Vein Thrombosis', LeftSuperficialVeinThrombosisResult)}   
      `,
      },
      {
        hide: true || !doctorFormComplete, // PRE OCT-2020-VERSION
        text: `
        We have discussed treatment options.
        I discussed the results with ${NameCreation.getName(
          false,
          false
        )} and explained that ${NameCreation.getName(false, false)} has ${listText(diseases)}. ${
          leftLegRecommendedOptions && leftLegRecommendedOptions.length > 0
            ? `With regards to the left leg I recommended ${listText(leftLegRecommendedOptions)}.`
            : ''
        } ${
          rightLegRecommendedOptions && rightLegRecommendedOptions.length > 0
            ? `With regards to the left leg I recommended ${listText(rightLegRecommendedOptions)}.`
            : ''
        }
      `,
      },
      {
        hide: true || !doctorFormComplete || !hasRecommendations, // PRE OCT-2020-VERSION
        text: `I have answered any of ${genderAdverb} questions or concerns including the possible complications and outcomes of treatment.
        We discussed that these procedures are not covered under the provincial health care plan and discussed the costs as well.`,
      },
      {
        hide: true || !doctorFormComplete || hasRecommendations, // PRE OCT-2020-VERSION
        text: `I would recommend that ${NameCreation.getName(
          false,
          false
        )} wears compression stockings 20-30 mmHg.
        ${NameCreation.getName(
          true,
          false
        )} will consider the treatment options and will let us know if ${NameCreation.getName(
          false,
          false
        )} would like to proceed. ${NameCreation.getName(
          true,
          false
        )} might require Compression Sclerotherapy after the treatment, but we recommend waiting a few months in that case.
        Thank you again for the referral.
      `,
      },
    ];
  }

  return paragraphDefaults;
}
