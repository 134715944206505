import validator, { DynamicData, ValidationRule } from 'lib/validator';

const ChangePasswordValidator: Array<ValidationRule> = [
  {
    name: 'oldPassword',
    rule: (data: DynamicData, name: string): boolean => !!data[name],
    message: 'Please enter the old password.',
  },
  {
    name: 'newPassword',
    rule: (data: DynamicData, name: string): boolean => !!data[name],
    message: 'Please enter the new password',
  },
  {
    name: 'confirmNewPassword',
    rule: (data: DynamicData, name: string): boolean => !!data[name],
    message: 'Please confirm your new password',
  },
  {
    name: 'newPassword',
    rule: (data: DynamicData, name: string): boolean => {
      return data.oldPassword !== data.newPassword;
    },
    message: 'Your new password should be different from your old password.',
  },
  {
    name: 'confirmNewPassword',
    rule: (data: DynamicData, name: string): boolean => {
      return data.confirmNewPassword === data.newPassword;
    },
    message: "This passwords don't match.",
  },
];

export default validator(ChangePasswordValidator);
