import React, { ReactElement, useState, useEffect } from 'react';
import styles from './ConfirmDocumentDialog.module.scss';
import DialogButton, { DialogButtonType } from 'components/DialogButton/DialogButton.view';
import IFrame from 'components/IFrame';
import { MIME_TYPE_PDF } from 'lib/document';
import Dialog from 'components/Dialog';

interface PatientEditDialogProps {
  open: boolean;
  saveTitle?: string;
  pdfUrl?: string;
  onClose?: () => void;
  onSave?: () => void;
}

function ConfirmDocumentDialog({
  open = false,
  saveTitle,
  pdfUrl,
  onClose,
  onSave,
}: PatientEditDialogProps): ReactElement {
  return (
    <Dialog fullWidth={true} open={open} title={'Confirm Document'} onClose={onClose}>
      <div className={styles.container}>
        <div className={styles.instructions}>
          Please confirm that the changes you made are correct
        </div>
        <div className={styles.iframe}>
          <IFrame
            url={pdfUrl}
            type={MIME_TYPE_PDF}
            buttonsAtTop={true}
            onClose={onClose}
            saveTitle={saveTitle}
            onSave={onSave}
          />
        </div>
      </div>
    </Dialog>
  );
}

export default ConfirmDocumentDialog;
