import React, { ReactElement } from 'react';

import Layout from 'Layout';

import FaxManagement from './components/FaxManagement/FaxManagement.view';

import styles from './Fax.module.scss';

function FaxPage(props): ReactElement | null {
  return (
    <Layout hideFooter>
      <div className={styles.container}>
        <FaxManagement match={props?.match} />
      </div>
    </Layout>
  );
}

export default FaxPage;
