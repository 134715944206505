/* eslint-disable @typescript-eslint/ban-ts-ignore */
import axios from 'axios';

import { formGetDocumentsResponse, formGetDocumentResponse } from './document.transformer';

import {
  NewDocument,
  Document,
  DocumentType,
  NewBlobDocument,
  DownloadDocumentType,
  MultipleNewDocuments,
  NewDefaultDocument,
} from 'types';
import { GET_CASE, GET_CASE_TYPES, GET_DOCUMENT, GET_FAX } from 'lib/config';

import { getDefaultHeader } from '../utils';
import { DOCUMENT_STATUS_IDS } from 'lib/document';
import { PROMISE_ALL_SETTLED_RESULT, allSettledPolyfill } from 'lib/promise';

export async function getDefaultDocuments(): Promise<Array<Document>> {
  const response = await axios.get(`${GET_DOCUMENT}/defaults`, {
    headers: getDefaultHeader(),
    transformResponse: formGetDocumentsResponse,
  });

  return response.data;
}

export async function getDocuments(caseId: number | string): Promise<Array<Document>> {
  const response = await axios.get(`${GET_CASE}/${caseId}/documents`, {
    headers: getDefaultHeader(),
    transformResponse: formGetDocumentsResponse,
  });

  return response.data;
}

export async function getDefaultDocumentTypes(caseId: number | string): Promise<Array<Document>> {
  const response = await axios.get(`${GET_DOCUMENT}/defaultTypes`, {
    headers: getDefaultHeader(),
  });

  return response.data;
}

export async function getDocument(documentId: number | string): Promise<Document> {
  const response = await axios.get(`${GET_DOCUMENT}/${documentId}`, {
    headers: getDefaultHeader(),
    transformResponse: formGetDocumentResponse,
  });

  return response.data;
}

export async function downloadDocument(documentId: number | string): Promise<DownloadDocumentType> {
  const response = await axios.get(`${GET_DOCUMENT}/${documentId}/download`, {
    headers: getDefaultHeader(),
  });

  return response.data;
}

export async function downloadDefaultDocument(
  documentId: number | string
): Promise<DownloadDocumentType> {
  const response = await axios.get(`${GET_DOCUMENT}/${documentId}/downloadDefaultDocument`, {
    headers: getDefaultHeader(),
  });

  return response.data;
}

export async function sendDocument(documentId: number | string, email, patientId): Promise<void> {
  await axios.post(
    `${GET_DOCUMENT}/${documentId}/send`,
    {
      email: email,
      patientId: patientId,
    },
    { headers: getDefaultHeader() }
  );
}

export async function sendGenericDefaultDocument(document, email, patientId): Promise<void> {
  await axios.post(
    `${GET_DOCUMENT}/defaults/${document.id}/send`,
    {
      email: email,
      patientId: patientId,
    },
    { headers: getDefaultHeader() }
  );
}

export async function sendMultipleDocuments(
  documentIds: [number | string],
  email,
  patientId,
  note
): Promise<void> {
  await axios.post(
    `${GET_DOCUMENT}/sendMultipleDocuments`,
    {
      documentIds: documentIds,
      email: email,
      patientId: patientId,
      note: note,
    },
    { headers: getDefaultHeader() }
  );
}

export async function updateDocument(
  documentId: number | string,
  params,
  token?: string
): Promise<void> {
  await axios.put(`${GET_DOCUMENT}/${documentId}/`, params, { headers: getDefaultHeader(token) });
}

export async function stampDocument(
  documentId: number | string,
  formId: number | string,
  token?: string,
  signingDoctorId?: number,
  signingDoctorBillingNumber?: string
): Promise<any> {
  const response = await axios.put(
    `${GET_DOCUMENT}/${documentId}/stampDocument`,
    { formId, signingDoctorId, signingDoctorBillingNumber },
    {
      headers: getDefaultHeader(token),
    }
  );

  return response.data;
}

export async function getDocumentTypes(): Promise<Array<DocumentType>> {
  const response = await axios.get(`${GET_CASE_TYPES}`, {
    headers: getDefaultHeader(),
  });

  return response.data;
}

const putFile = async (endpoint: string, data: any) => {
  return axios.put(endpoint, data, { headers: { 'Content-Type': 'multipart/form-data' } });
};

export async function copyDefaultDocument(
  documentId: number | string,
  caseId: number | string,
  fileName: string
): Promise<Document> {
  const response = await axios.post(
    `${GET_DOCUMENT}/defaults/${documentId}/copy`,
    {
      fileName: fileName,
      caseId: caseId,
    },
    {
      headers: getDefaultHeader(),
    }
  );

  return response.data;
}

export async function copyDocument(
  documentId: number | string,
  caseId: number | string,
  fileName: string
): Promise<Document> {
  const response = await axios.post(
    `${GET_DOCUMENT}/${documentId}/copy`,
    {
      fileName: fileName,
      caseId: caseId,
    },
    {
      headers: getDefaultHeader(),
    }
  );

  return response.data;
}
export async function createDefaultDocument(
  data: NewDefaultDocument
): Promise<Document | Error | unknown> {
  try {
    const response = await axios.post(
      `${GET_DOCUMENT}/defaultDocuments`,
      {
        mimeType: data.document.type,
        fileName: data.document.name,
        title: data.title,
        type: data.type,
        needsStamp: data.needsStamp,
      },
      {
        headers: getDefaultHeader(),
      }
    );

    const uploadUrl = response.data.signedRequest;
    const awsResponse = await allSettledPolyfill([putFile(uploadUrl, data.document)]);
    return awsResponse;
  } catch (e) {
    throw e;
  }
}
export async function createDocument(
  caseId: number | string,
  data: NewDocument
): Promise<Document | Error | unknown> {
  try {
    const response = await axios.post(
      `${GET_CASE}/${caseId}/documents`,
      {
        name: data.name,
        typeId: data.typeId,
        mimeType: data.document.type,
        fileName: data.document.name,
        original: 1,
        appointmentId: data.appointmentId,
        folderName: data.folderName,
        documentStatusId: DOCUMENT_STATUS_IDS.IN_PROGRESS,
      },
      {
        headers: getDefaultHeader(),
      }
    );

    const uploadUrl = response.data.signedRequest;
    const awsResponse = await allSettledPolyfill([putFile(uploadUrl, data.document)]);

    const uploadStatusId =
      awsResponse[0].status == PROMISE_ALL_SETTLED_RESULT.FULFILLED
        ? DOCUMENT_STATUS_IDS.SUCCESSFUL
        : DOCUMENT_STATUS_IDS.FAILED;

    await updateDocument(response.data.id, {
      //update db with status of file upload
      documentStatusId: uploadStatusId,
    });
    awsResponse[0].value.data = response.data;
    return awsResponse;
  } catch (e) {
    return e;
  }
}

export async function createAndSendDocument(
  caseId: number | string,
  data: NewDocument,
  email: string,
  patientId
): Promise<Document | Error | unknown> {
  try {
    const response = await axios.post(
      `${GET_CASE}/${caseId}/documents`,
      {
        name: data.name,
        typeId: data.typeId,
        mimeType: data.document.type,
        fileName: data.document.name,
        original: 1,
        appointmentId: data.appointmentId,
        folderName: data.folderName,
        documentStatusId: DOCUMENT_STATUS_IDS.IN_PROGRESS,
      },
      {
        headers: getDefaultHeader(),
      }
    );

    const uploadUrl = response.data.signedRequest;
    const awsResponse = await allSettledPolyfill([putFile(uploadUrl, data.document)]);

    const uploadStatusId =
      awsResponse[0].status == PROMISE_ALL_SETTLED_RESULT.FULFILLED
        ? DOCUMENT_STATUS_IDS.SUCCESSFUL
        : DOCUMENT_STATUS_IDS.FAILED;

    await updateDocument(response.data.id, {
      //update db with status of file upload
      documentStatusId: uploadStatusId,
    });

    await axios.post(
      `${GET_DOCUMENT}/sendMultipleDocuments`,
      {
        documentIds: [response.data.id],
        email: email,
        patientId: patientId,
        note: '',
      },
      { headers: getDefaultHeader() }
    );

    return awsResponse;
  } catch (e) {
    return e;
  }
}

export async function createMultipleDocuments(
  caseId: number | string,
  data: MultipleNewDocuments
): Promise<any> {
  const fd = new FormData();
  data.map(val => (val.mimeType = val.document.type));
  fd.append('files', JSON.stringify(data));

  const response = await axios.post(
    `${GET_CASE}/${caseId}/multipleDocuments`,
    {
      files: JSON.stringify(data),
    },
    {
      headers: getDefaultHeader(),
    }
  );

  const uploadUrl = response.data;
  const uploadStatuses = await allSettledPolyfill(
    uploadUrl.map((url, index) => {
      return putFile(url.signedRequest, data[index].document);
    })
  );

  await Promise.all(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    uploadStatuses.map((uploadStatus, index) => {
      const uploadStatusId =
        uploadStatus.status == PROMISE_ALL_SETTLED_RESULT.FULFILLED
          ? DOCUMENT_STATUS_IDS.SUCCESSFUL
          : DOCUMENT_STATUS_IDS.FAILED;
      updateDocument(uploadUrl[index].id, { documentStatusId: uploadStatusId });
    })
  );
  return uploadStatuses;
}

export async function createBlobDocument(
  caseId: number | string,
  data: NewBlobDocument
): Promise<Document> {
  const fd = new FormData();

  fd.append('name', data.name);
  fd.append('typeId', data.typeId.toString());
  fd.append('mimeType', data.mimeType);
  fd.append('fileName', data.fileName);

  const response = await axios.post(
    `${GET_CASE}/${caseId}/documents`,
    {
      name: data.name,
      typeId: data.typeId,
      mimeType: data.mimeType,
      fileName: data.fileName,
      replacedDocumentId: data.replacedDocumentId,
    },
    {
      headers: getDefaultHeader(),
    }
  );

  const uploadUrl = response.data.signedRequest;
  const awsResponse = await allSettledPolyfill([putFile(uploadUrl, data.document)]);

  const uploadStatusId =
    awsResponse[0].status == PROMISE_ALL_SETTLED_RESULT.FULFILLED
      ? DOCUMENT_STATUS_IDS.SUCCESSFUL
      : DOCUMENT_STATUS_IDS.FAILED;

  await updateDocument(response.data.id, {
    documentStatusId: uploadStatusId,
  });
  return response.data;
}

export async function deleteDocument(documentId: number | string): Promise<Document> {
  const response = await axios.delete(`${GET_DOCUMENT}/${documentId}`, {
    headers: getDefaultHeader(),
  });

  return response.data;
}

export async function deleteDefaultDocument(documentId: number | string): Promise<Document> {
  const response = await axios.delete(`${GET_DOCUMENT}/${documentId}/defaultDocument`, {
    headers: getDefaultHeader(),
  });

  return response.data;
}

export async function verifyDocumentValid(documentId: number | string): Promise<boolean> {
  const response = await axios.get(`${GET_DOCUMENT}/${documentId}/verifyDocumentNoError`, {
    headers: getDefaultHeader(),
  });
  return response.data.isValid;
}
