import React from 'react';
import styles from './LocationHours.module.scss';
import { LocationsSchedule } from '../../useLocations.Interface';
import { convertTo12From24 } from 'lib/schedule';
import formatDate, { DateTimeFormat, DateTimeFormatString } from 'lib/dateFormatter';
import moment from 'moment';

// TODO: Add or change location hours openingTime and closingTime based on day
interface LocationHoursProps {
  scheduleMap: Map<string, Array<LocationsSchedule>>;
}

function LocationHours({ scheduleMap }: LocationHoursProps): JSX.Element {
  interface RangeObjType {
    start: string;
    end: string;
  }

  const locationHoursData: Map<string, Array<string>> = new Map();

  // Calculate opening hours
  scheduleMap.forEach((scheduleForDate: Array<LocationsSchedule>, dateKey: string) => {
    // TODO: Account for timezone
    const dayOfWeek = formatDate(
      DateTimeFormat.DayOnly,
      moment(dateKey, DateTimeFormatString.DateOnly)
    );

    const hoursRangeMap = new Map();
    scheduleForDate.forEach((schedule: LocationsSchedule) => {
      const startTime = formatDate(
        DateTimeFormat.TimeOnlyWithoutMeridies,
        moment(schedule.start, DateTimeFormatString.APIDateAndTimeFormat)
      );
      const endTime = formatDate(
        DateTimeFormat.TimeOnlyWithoutMeridies,
        moment(schedule.end, 'DateTimeFormatString.APIDateAndTimeFormat')
      );
      const rangeString = `${convertTo12From24(startTime)} - ${convertTo12From24(endTime)}`;

      const timeRange: RangeObjType = {
        start: schedule.start,
        end: schedule.end,
      };

      if (hoursRangeMap.size <= 0) {
        hoursRangeMap.set(rangeString, timeRange);
      }

      // If we have data check to see if we can add/append
      const outSideOfRanges: Array<RangeObjType> = [];
      const withinRanges: Array<RangeObjType> = [];
      const overlapsReplacesRange: Array<RangeObjType> = [];

      hoursRangeMap.forEach((rangeObj: RangeObjType, key) => {
        const startNew = new Date(timeRange.start);
        const endNew = new Date(timeRange.end);
        const startOld = new Date(rangeObj.start);
        const endOld = new Date(rangeObj.end);

        const newStartAfterOldStart = startNew > startOld;
        const newStartAfterOldEnd = startNew > startOld;
        const newStartBeforeOrOnOldStart = startNew <= endOld;

        const newEndBeforeOldEnd = endNew < endOld;
        const newEndBeforeOldStart = endNew < startOld;
        const newEndAfterOrOnOldEnd = endNew >= endOld;

        // Is within existing range ignore
        if (newStartAfterOldStart && newEndBeforeOldEnd) {
          withinRanges.push(key);
          return;
        }
        // Is outside of existing ranges
        else if (newEndBeforeOldStart || newStartAfterOldEnd) {
          outSideOfRanges.push(key);
        }
        // Overlaps and/or extends old range
        else if (newStartBeforeOrOnOldStart && newEndAfterOrOnOldEnd) {
          overlapsReplacesRange.push(key);
        }
      });

      // IF within existing range we do not add
      if (withinRanges.length > 0) {
        return;
      }

      // IF outside of exisitng range then we add it
      if (outSideOfRanges.length > 0) {
        hoursRangeMap.set(rangeString, timeRange);
      }

      // IF overlaps we add it and remove old ranges
      if (overlapsReplacesRange.length > 0) {
        overlapsReplacesRange.forEach(keyRemove => {
          hoursRangeMap.delete(keyRemove);
        });
        hoursRangeMap.set(rangeString, timeRange);
      }
    });

    const hoursStrings: Array<string> = [];
    hoursRangeMap.forEach((rangeObj: RangeObjType, rangeString) => {
      hoursStrings.push(rangeString);
    });

    locationHoursData.set(dayOfWeek, hoursStrings);
  });

  const days: Array<string> = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];
  const daysOfWeek: Array<string> = [];
  const hours: Array<Array<string>> = [];

  for (const i in days) {
    daysOfWeek.push(days[i]);
    if (locationHoursData.has(days[i])) {
      hours.push(locationHoursData.get(days[i]) || []);
    } else {
      hours.push(['CLOSED']);
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <b>Opening Hours</b>
      </div>

      {locationHoursData.size != 0 ? (
        daysOfWeek.map((day, index) => {
          return (
            <div key={day}>
              <b>{day} </b>
              {hours[index].map(hoursStr => {
                return (
                  <div key={hoursStr}>
                    {hoursStr}
                    <br></br>
                  </div>
                );
              })}
            </div>
          );
        })
      ) : (
        <b>All Week CLOSED</b>
      )}
    </div>
  );
}
export default LocationHours;
