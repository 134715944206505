import React, { ReactElement, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';

import Dialog from 'components/Dialog';
import Button from 'components/CVCButton';
import DialogButton from 'components/DialogButton';

import styles from './NewDocumentDialogStyles.module.scss';
import { FormikTouched, useFormik } from 'formik';
import { NewDocument, DocumentType, MultipleNewDocuments } from 'types';
import { supportedFileTypesString } from 'lib/document';
import FileUpload from './components/FileUpload';

interface NewDocumentDialogProps {
  open?: boolean;
  onClose: () => void;
  onSave: (data: MultipleNewDocuments) => Promise<PromiseSettledResult<any>[]>;
  loading: boolean;
  documentTypes: Array<DocumentType>;
}

function NewDocumentDialog({
  open,
  onClose,
  onSave,
  loading,
  documentTypes,
}: NewDocumentDialogProps): ReactElement {
  const [folder, setFolder] = useState<any>(null);
  const fileInput = useRef<any>(null);
  const [errors, setErrors] = useState<any>([{ name: '', typeId: '', document: '' }]);
  const [status, setStatus] = useState<any>([null]);
  const [isProgress, setIsProgress] = useState<boolean>(false);
  const initRowValue = {
    name: '',
    typeId: '1',
    document: new File([''], ''),
    folderName: folder,
    progress: false,
  };
  const validateValues = values => {
    let isValid = true;
    for (let i = 0; i < values.length; i++) {
      const doc = values[i];
      if (doc.name == '') {
        errors[i].name = 'Error: Name is required';
        isValid = false;
      }
      if (doc.typeId == '') {
        errors[i].typeId = 'Error: Type is required';
        isValid = false;
      }
      if (doc.document.name == '' || doc.document.size == 0) {
        errors[i].document = 'Error: Cannot upload empty document';
        isValid = false;
      }
    }
    return isValid;
  };

  const formik = useFormik({
    initialValues: [
      {
        name: '',
        typeId: '1',
        document: new File([''], ''),
        folderName: '',
        progress: false,
      },
    ],
    onSubmit: async values => {
      if (validateValues(values)) {
        setIsProgress(true);
        const allFileUploadStatuses = await onSave(values);
        setIsProgress(false);
        setStatus(allFileUploadStatuses);
        if (allFileUploadStatuses.filter(status => status.status == 'rejected').length == 0) {
          alert(`All ${allFileUploadStatuses.length} files uploaded successfully.`);
          onClose();
        } else {
          alert(`Only some files were uploaded successfully. Please re-upload files which failed.`);

          // Remove files which were successful. Keep only files which were not successful so that they can upload again.
          allFileUploadStatuses.reverse().map((file, index) => {
            if (file.status == 'fulfilled') {
              formik.setValues(prevArray => {
                prevArray.splice(index, 1);
                return [...prevArray];
              });
              setErrors(prevErrors => {
                prevErrors.splice(index, 1);
                return [...prevErrors];
              });
            }
          });
        }
      }
    },
  });

  useEffect(() => {
    formik.setValues([
      { name: '', typeId: '1', document: new File([''], ''), folderName: '', progress: false },
    ]);
    setErrors([{ name: '', typeId: '', document: '' }]);
    setStatus([]);
    setFolder('');
  }, [open]);

  const onChangeFile = (event, index): void => {
    event.stopPropagation();
    event.preventDefault();
    const { files } = event.target;
    formik.setValues(prevArray => {
      prevArray[index].document = files[0];
      prevArray[index].name = files[0].name;
      return [...prevArray];
    });

    setErrors(prevErrors => {
      return [...prevErrors, { name: '', typeId: '', document: '' }];
    });

    const tmpArray: any = [];
    if (event.target.files.length === 1) return;
    for (let i = 1; i < files.length; i++) {
      const file = files.item(i);
      const tmp = initRowValue;
      tmp.document = file;
      tmp.name = file.name;
      tmpArray.push(tmp);
      setErrors(prevErrors => {
        return [...prevErrors, { name: '', typeId: '', document: '' }];
      });
    }
    formik.setValues(prevArray => {
      return [...prevArray, ...tmpArray];
    });
  };

  const onUploadMultiple = (event): void => {
    event.stopPropagation();
    event.preventDefault();
    const { files } = event.target;
    const tmpArray: any = [];
    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);
      const tmp = {
        name: file.name,
        typeId: '1',
        document: file,
        folderName: folder,
        progress: false,
      };
      tmpArray.push(tmp);
      setErrors(prevErrors => {
        return [...prevErrors, { name: '', typeId: '', document: '' }];
      });
    }
    formik.setValues(prevArray => {
      if (prevArray.length == 1 && prevArray[0].document.name == '') return [...tmpArray];
      return [...prevArray, ...tmpArray];
    });
  };

  const onChangeName = (event, index): void => {
    const { value } = event.target;
    formik.setValues(prevArray => {
      prevArray[index].name = value;
      return [...prevArray];
    });
  };

  const onChangeTypeId = (event, index): void => {
    const { value } = event.target;
    formik.setValues(prevArray => {
      prevArray[index].typeId = value;
      return prevArray;
    });
  };

  const onChangeFolder = (event): void => {
    const { value } = event.target;
    setFolder(value);
    formik.setValues(prevArray => {
      prevArray.map(val => (val.folderName = value));
      return [...prevArray];
    });
  };

  const onDeleteRow = (index): void => {
    formik.setValues(prevArray => {
      prevArray.splice(index, 1);
      return [...prevArray];
    });
    setErrors(prevErrors => {
      prevErrors.splice(index, 1);
      return [...prevErrors];
    });
  };

  const onAddRow = (): void => {
    formik.setValues(prevArray => {
      return [...prevArray, initRowValue];
    });
    setErrors(prevErrors => {
      return [...prevErrors, { name: '', typeId: '', document: '' }];
    });
  };

  return (
    <Dialog open={open} title="Add Documents" onClose={onClose}>
      <div className={styles.padding}>
        <div className={styles.folderName}>
          <TextField
            name={'name'}
            label="Folder name"
            placeholder="Enter optional folder name"
            onChange={onChangeFolder}
            value={folder}
          />
        </div>
        {formik.values.map((values, index) => (
          <FileUpload
            values={values}
            index={index}
            errors={errors[index]}
            onChangeFile={onChangeFile}
            documentTypes={documentTypes}
            onChangeName={onChangeName}
            onChangeTypeId={onChangeTypeId}
            onDeleteRow={onDeleteRow}
            status={status[index]}
            key={index}
            isProgress={isProgress}
          />
        ))}
        <div className={classNames(styles.padding, styles.buttons)}>
          <div className={styles.left}>
            <DialogButton onClick={onAddRow}>Add Row</DialogButton>
            <Button onClick={() => fileInput?.current?.click()}>Upload Multiple Files</Button>
            <input
              type="file"
              ref={fileInput}
              style={{ display: 'none' }}
              name="document"
              onChange={event => onUploadMultiple(event)}
              multiple
              accept={supportedFileTypesString()}
            />
          </div>
          <div className={styles.right}>
            <DialogButton onClick={onClose}>Cancel</DialogButton>
            <DialogButton
              className={styles.marginLeft}
              loading={loading}
              onClick={formik.handleSubmit}
            >
              Save
            </DialogButton>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default NewDocumentDialog;
