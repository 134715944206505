import React, { ReactElement } from 'react';
import moment from 'moment';
import { Calendar, View, momentLocalizer, Event } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';

import './BigCalendar.scss';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';

import WeekHeader from './components/WeekHeader';
import WeekEvent from './components/WeekEvent';
import MonthEvent from './components/MonthEvent';
import DayCalendar from './components/DayCalendar';
import DayHeader from './components/DayHeader';
import MonthHeader from './components/MonthHeader';

import styles from './Calendar.module.scss';
import classNames from 'classnames';

interface CalendarProps {
  onSelectEvent?: (Event: any) => void;
  onEditEvent?: (Event: any) => void;
  currentDate?: Date;
  events?: Array<Event>;
  view?: View;
  scrollToTime?: Date;
  [other: string]: any;
  calendarType?: CalendarType;
}

interface ReactBigCalEventProps {
  event?: Event;
  [rest: string]: any;
}

export enum CalendarType {
  DoctorSchedule = 0,
  LocationSchedule = 1,
  Appointments = 3,
}

const localizer = momentLocalizer(moment);

const DnDCalendar = withDragAndDrop(Calendar);

function CustomCalendar({
  events = [],
  view = 'week',
  currentDate = new Date(),
  onEditEvent,
  calendarType,
  scrollToTime = new Date(),
  ...other
}: CalendarProps): ReactElement | null {
  function renderWeekHeader(props: object): ReactElement {
    return <WeekHeader {...props} currentDate={currentDate} />;
  }

  function renderEvent(props: object): ReactElement {
    return <WeekEvent {...props} onEditEvent={onEditEvent} calendarType={calendarType} />;
  }

  function renderDayHeader(props: object): ReactElement {
    return <DayHeader {...props} />;
  }

  function renderMonthEvent(props: ReactBigCalEventProps): ReactElement {
    return <MonthEvent {...props} calendarType={calendarType} />;
  }

  return (
    <DnDCalendar
      {...other}
      localizer={localizer}
      events={events}
      view={view}
      onView={() => null}
      onNavigate={() => null}
      toolbar={false}
      views={{
        month: true,
        week: true,
        day: DayCalendar,
      }}
      date={currentDate}
      className={classNames(styles.bigCalendar, 'doctorPageCal')}
      components={{
        week: {
          header: renderWeekHeader,
          event: renderEvent,
        },
        day: {
          header: renderDayHeader,
          event: renderEvent,
        },
        month: {
          header: MonthHeader,
          event: renderMonthEvent,
        },
      }}
    />
  );
}

export default CustomCalendar;
