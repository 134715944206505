import React, { ReactElement } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { InputLabel, Select, FormControl } from '@material-ui/core';
import styles from './QuestionSectionStyles.module.scss';
import classNames from 'classnames';

interface DropDownQuestionProps {
  title?: string;
  description?: string;
  options?: Array<any>;
  optionExtractor?: (option) => string;
  value: Array<any>;
  defaultValue?: Array<any>;
  required?: boolean;
  onChange?(value): void;
  valueExtractor?: (option) => string;
}

function defaultOptionExtractor(option): string {
  return option?.value ? option.value.toString() : '';
}

function DropDownQuestionSection({
  title,
  description,
  options = [],
  value = [],
  defaultValue = [],
  required,
  onChange = () => null,
  optionExtractor,
  valueExtractor = defaultOptionExtractor,
}: DropDownQuestionProps): ReactElement {
  const currentOptionExtractor = optionExtractor || defaultOptionExtractor;
  const currentDefaultValue = defaultValue || [];

  function handleChange(value): void {
    onChange([
      {
        ...(currentDefaultValue[0]?.id && { id: currentDefaultValue[0]?.id }),
        value,
      },
    ]);
  }
  return (
    <div className={styles.openChoiceOption}>
      <div className={styles.title}>
        {title} {required ? <span className={styles.reqMarker}>*</span> : null}
      </div>
      <div className={classNames(styles.title, styles.statement)}>{description}</div>
      <div>
        <div>
          <FormControl fullWidth>
            <InputLabel className={styles.label} id="select-label" shrink={false}>
              {!value[0] && valueExtractor(currentDefaultValue[0]) === '' ? 'Please select' : ''}
            </InputLabel>
            <Select
              defaultValue={valueExtractor(currentDefaultValue[0])}
              onChange={({ target }) => handleChange(target.value)}
              labelId="select-label"
              className={styles.dropDownContainer}
            >
              {options.map((option, index) => (
                <MenuItem key={index} value={currentOptionExtractor(option)}>
                  {currentOptionExtractor(option)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
    </div>
  );
}

export default DropDownQuestionSection;
