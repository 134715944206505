import React, { ReactElement } from 'react';
import classNames from 'classnames';

import styles from './PaperStyles.module.scss';

interface PaperProps {
  className?: string;
  children?: any;
  [rest: string]: any;
}

function Paper({ className, children, ...rest }: PaperProps): ReactElement {
  return (
    <div {...rest} className={classNames(className, styles.paper)}>
      {children}
    </div>
  );
}

export default Paper;
