import { useState } from 'react';
import { User } from 'types';

import { getUsers as getUsersRequest, getStaff as getStaffRequest } from 'api/user';
import * as logger from 'api/logger';

export interface UserState {
  loading: boolean;
  users: Array<User>;
  staff: Array<User>;
  getUsers(searchName?: string, userType?: string, locationId?: number | null);
  getStaff(searchName?: string, userType?: string, locationId?: number | null);
}

function useUserApi(): UserState {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<Array<User>>([]);
  const [staff, setStaff] = useState<Array<User>>([]);

  async function getUsers(
    searchName?: string,
    userType?: string,
    locationId?: number | null
  ): Promise<void> {
    setLoading(true);
    try {
      const users = await getUsersRequest(searchName, userType, locationId?.toString());
      setUsers(users);
    } catch (e) {
      logger.error(e.message);
    }
    setLoading(false);
  }

  async function getStaff(
    searchName?: string,
    userType?: string,
    locationId?: number | null
  ): Promise<void> {
    setLoading(true);
    try {
      const staff = await getStaffRequest(searchName, userType, locationId?.toString());
      setStaff(staff);
    } catch (e) {
      logger.error(e.message);
    }
    setLoading(false);
  }

  return {
    loading,
    users,
    staff,
    getUsers,
    getStaff,
  };
}

export default useUserApi;
