import React, { ReactElement } from 'react';
import { AppProvider } from 'context';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Provider as AuthProvider } from 'context/auth';
import { Provider as ProfileProvider } from 'context/profile';
import Routing from './routing';
import { H } from 'highlight.run';

import AppWrapper from 'Layout/AppWrapper';

import './App.scss';
import { baseAPIUrl } from 'lib/config';

H.init('ldw27jkd', {
  serviceName: 'cvc-frontend-app',
  tracingOrigins: [baseAPIUrl],
  networkRecording: {
    enabled: true,
    recordHeadersAndBody: true,
    urlBlocklist: [
      // insert full or partial urls that you don't want to record here
      // Out of the box, Highlight will not record these URLs (they can be safely removed):
      'https://www.googleapis.com/identitytoolkit',
      'https://securetoken.googleapis.com',
    ],
  },
});

function App(): ReactElement {
  return (
    <>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <AppProvider>
          <AuthProvider>
            <ProfileProvider>
              <AppWrapper>
                <Routing />
              </AppWrapper>
            </ProfileProvider>
          </AuthProvider>
        </AppProvider>
      </MuiPickersUtilsProvider>
    </>
  );
}

export default App;
