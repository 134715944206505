import React, { ReactElement, ReactNode, useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from 'components/Dialog';
import Grid from '@material-ui/core/Grid';
import DialogButton from 'components/DialogButton';
import Autocomplete from 'components/Autocomplete';

import styles from './AssignNewTaskModalStyles.module.scss';
import { Staff as UserStaff } from 'api/user/user.interfaces';
import { User, Task } from 'types';
import { TaskDialogValidationError } from 'types';

interface AssignNewTaskModalProps {
  open: boolean;
  isEditTask: boolean;
  isPreSelected?: boolean;
  taskToEdit: Task | null;
  loading?: boolean;
  showResponse?: boolean;
  patients?: Array<User>;
  staff: User | null;
  title: string;
  response: { responseIcon: ReactNode; responseMessage: string };
  description: string;
  patient?: User | { fullName: string } | null;
  staffList?: Array<UserStaff>;
  onTitleChange: (value) => void;
  onDescriptionChange: (value) => void;
  onStaffSelect: (value) => void;
  onChangeSearchQuery: (value) => void;
  onPatientSelect: (value) => void;
  onClose: () => void;
  onSave: () => void;
}

function AssignNewTaskModal({
  open,
  isEditTask,
  isPreSelected,
  taskToEdit,
  loading,
  showResponse,
  response,
  title,
  description,
  staff,
  patient,
  patients = [],
  staffList,
  onStaffSelect,
  onPatientSelect,
  onDescriptionChange,
  onChangeSearchQuery,
  onTitleChange,
  onClose,
  onSave,
}: AssignNewTaskModalProps): ReactElement {
  const [staffValidationErrors, setStaffValidationErrors] = useState<
    Array<TaskDialogValidationError>
  >([]);
  const [patientValidationErrors, setPatientValidationErrors] = useState<
    Array<TaskDialogValidationError>
  >([]);
  const [titleError, setTitleError] = useState(false);
  const [descError, setDescError] = useState(false);

  useEffect(() => {
    if (taskToEdit && isEditTask && staffList) {
      onTitleChange(taskToEdit.title);
      onDescriptionChange(taskToEdit.description);
      const staffSelected = staffList.find(staff => staff.id === taskToEdit.staff_id);
      onStaffSelect(staffSelected);
    }
  }, [isEditTask]);

  function validateForm(): boolean {
    let validated = true;
    const staffValidationErrors: Array<TaskDialogValidationError> = [];
    const patientValidationErrors: Array<TaskDialogValidationError> = [];

    if (!staff) {
      const validationError: TaskDialogValidationError = {
        field: 'staff',
        message: 'Please assign a staff member',
      };

      staffValidationErrors.push(validationError);
      validated = false;
    }

    if (!patient) {
      const validationError: TaskDialogValidationError = {
        field: 'patient',
        message: 'Please assign a patient',
      };

      patientValidationErrors.push(validationError);
      validated = false;
    }

    setStaffValidationErrors(staffValidationErrors);
    setPatientValidationErrors(patientValidationErrors);
    if (!title) {
      setTitleError(true);
      validated = false;
    }

    if (!description) {
      setDescError(true);
      validated = false;
    }
    return validated;
  }

  function onChangeTitle(value): void {
    onTitleChange(value);
    onDescriptionChange(description);
    if (value === '') {
      setTitleError(true);
    } else {
      setTitleError(false);
    }
  }

  function onChangeDescription(value): void {
    onDescriptionChange(value);
    onTitleChange(title);
    if (value === '') {
      setDescError(true);
    } else {
      setDescError(false);
    }
  }

  function onCloseForm(): void {
    onPatientSelect(null);
    onStaffSelect(null);
    onChangeTitle('');
    onChangeDescription('');
    setDescError(false);
    setTitleError(false);
    setStaffValidationErrors([]);
    setPatientValidationErrors([]);
    onClose();
  }

  function onSaveTask(): void {
    const validForm = validateForm();
    if (!validForm) return;
    onSave();
    onCloseForm();
  }

  return (
    <Dialog open={open} onClose={onCloseForm} title={isEditTask ? 'Reassign Task' : 'New Task'}>
      {showResponse ? (
        <div className={styles.response}>
          {response.responseIcon}
          {response.responseMessage}
        </div>
      ) : (
        <form className={styles.container}>
          <Grid container className={styles.gridContainer}>
            <Grid item xs={12} sm={6}>
              <div className={styles.paddingRight}>
                {isPreSelected || isEditTask ? (
                  <TextField
                    label="Patient name"
                    name="patientName"
                    value={patient?.fullName}
                    disabled
                    fullWidth
                  />
                ) : (
                  <Autocomplete
                    label="Patient name"
                    name="patientId"
                    items={patients}
                    value={patient}
                    validationErrors={patientValidationErrors}
                    vaidationErrorKey="patient"
                    getOptionLabel={(item): string => `${item.fullName}`}
                    onChange={(event, newValue): void => onPatientSelect(newValue)}
                    onInputChange={(event): void => onChangeSearchQuery(event.target.value)}
                    fullWidth
                  />
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={styles.paddingLeft}>
                <Autocomplete
                  label="Assign to staff"
                  name="staffId"
                  items={staffList}
                  value={staff}
                  validationErrors={staffValidationErrors}
                  vaidationErrorKey="staff"
                  getOptionLabel={(item): string => `${item.name}`}
                  onChange={(event, newValue): void => onStaffSelect(newValue)}
                  fullWidth
                />
              </div>
            </Grid>
          </Grid>
          <TextField
            error={titleError}
            label="Task name"
            name="title"
            onChange={(event): void => onChangeTitle(event.target.value)}
            value={title}
            multiline={true}
            helperText={titleError ? 'Cannot be empty' : ''}
          />
          <TextField
            error={descError}
            label="Description"
            className={styles.topPadding}
            name="description"
            onChange={(event): void => onChangeDescription(event.target.value)}
            value={description}
            multiline={true}
            helperText={descError ? 'Cannot be empty' : ''}
          />

          <div className={styles.buttons}>
            <DialogButton onClick={onCloseForm}>Cancel</DialogButton>
            <DialogButton className={styles.marginLeft} loading={loading} onClick={onSaveTask}>
              Save
            </DialogButton>
          </div>
        </form>
      )}
    </Dialog>
  );
}

export default AssignNewTaskModal;
