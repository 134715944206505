import React, { ReactElement } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { NewFaxValidationError } from 'types';
import { ValidationError } from 'components/Forms/ValidationError';
import Autocomplete from 'components/Autocomplete';
import DialogButton from 'components/DialogButton';
import Dialog from 'components/Dialog';
import styles from '../NewFaxDialog/NewFaxDialog.module.scss';

interface FaxFields {
  header: string;
  recipientName: string;
  faxDate: moment.Moment | undefined;
  faxNumber: string;
  messageToRecipient: string;
}

interface Patient {
  id: number;
  name: string;
  dateOfBirth: string;
  phone: string;
  email: string;
  healthcard: string;
}
interface SendFaxDialogProps {
  open?: boolean;
  title?: string;
  validationErrors: Array<NewFaxValidationError>;
  patientList: Array<Patient>;
  recipients: Array<Patient>;
  newRecipientList: Array<string>;
  newRecipient: string;
  onChangeOthers(value): void;
  setRecipients(value): void;
  setSearchQuery(value): void;
  validateForm(showErrors): boolean;
  onRemoveRecipient(index): void;
  onAddRecipient(): void;
  onClose(): void;
  onSave(): void;
}

function SendFaxDialog({
  open = false,
  title,
  validationErrors,
  patientList,
  recipients,
  newRecipient,
  newRecipientList,
  onChangeOthers,
  setRecipients,
  setSearchQuery,
  validateForm,
  onAddRecipient,
  onRemoveRecipient,
  onClose,
  onSave,
}: SendFaxDialogProps): ReactElement {
  const handleSave = (): void => {
    if (validateForm(true)) {
      onSave();
    }
  };

  return (
    <div>
      <Dialog open={open} title={title} onClose={onClose}>
        <div className={classNames(styles.dialogBody)}>
          <div className={styles.fullFlex}>
            <Autocomplete
              label="Recipients"
              name="recipients"
              items={patientList}
              value={recipients}
              getOptionLabel={(patient): string => `${patient.name}`}
              onChange={(event, newValue): void => setRecipients(newValue)}
              onInputChange={(event): void => setSearchQuery(event.target.value)}
              multiple
              fullWidth
            />
            <ValidationError field={'recipients'} validationErrors={validationErrors} />
          </div>
          <div className={styles.fullFlex}>
            <div className={styles.newRecipients}>
              <TextField
                label="Add New Recipients"
                name="newRecipients"
                onChange={onChangeOthers}
                value={newRecipient}
                fullWidth
              />
              <AddCircleIcon className={styles.addCircleIcon} onClick={onAddRecipient} />
            </div>
            <ValidationError field={'newRecipients'} validationErrors={validationErrors} />
          </div>
          {newRecipientList.length > 0 ? (
            <div className={styles.allRecipients}>
              {newRecipientList.map((recipient, index) => (
                <div key={index} className={classNames(styles.newRecipients, styles.space)}>
                  {recipient}
                  <RemoveCircleIcon
                    className={styles.RemoveCircleIcon}
                    onClick={(): void => onRemoveRecipient(index)}
                  />
                </div>
              ))}
            </div>
          ) : null}
          <div className={classNames(styles.buttonSection, styles.flexEnd, styles.padding)}>
            <DialogButton onClick={onClose} className={styles.button}>
              Cancel
            </DialogButton>
            <DialogButton onClick={handleSave} className={styles.button}>
              Send
            </DialogButton>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default SendFaxDialog;
