import React, { ReactElement, useEffect, useState } from 'react';
import classNames from 'classnames';
import { MIME_TYPE_PDF } from 'lib/document';

import styles from './IFrame.module.scss';
import DialogButton from 'components/DialogButton';
import { DialogButtonType } from 'components/DialogButton/DialogButton.view';

interface FrameProps {
  url?: string;
  type?: string;
  title?: string;
  height?: number;
  width?: number;
  className?: string;
  buttonsAtTop?: boolean;
  saveTitle?: string;
  onClose?: () => void;
  onSave?: () => void;
}

function IFrame({
  url,
  type,
  title,
  height,
  width,
  className,
  buttonsAtTop,
  saveTitle,
  onClose,
  onSave,
}: FrameProps): ReactElement {
  const [isPdf, setIsPdf] = useState<boolean>(false);

  const openInNewTab = () => {
    window.open(url, '_blank');
  };

  useEffect(() => {
    if (type === MIME_TYPE_PDF) {
      setIsPdf(true);
    }
  }, [type]);

  return (
    <div className={classNames(className, styles.iframe)}>
      <div className={styles.buttons}>
        <div className={styles.btn}>
          <DialogButton buttonType={DialogButtonType.Bordered} onClick={openInNewTab}>
            View In New Tab
          </DialogButton>
        </div>
        {buttonsAtTop && (
          <div className={styles.buttonSection}>
            <DialogButton buttonType={DialogButtonType.NegationLink} onClick={onClose}>
              Cancel
            </DialogButton>
            <DialogButton
              buttonType={DialogButtonType.AffirmativeLink}
              className={styles.btn}
              onClick={onSave}
            >
              {saveTitle}
            </DialogButton>
          </div>
        )}
      </div>

      {isPdf ? (
        <iframe
          src={url}
          title={title}
          height={height ? height : '100%'}
          width={width ? width : '100%'}
        />
      ) : (
        <img src={url} title={title} className={styles.scaledFrame} />
      )}
    </div>
  );
}

export default IFrame;
