import React, { ReactElement } from 'react';
import { EditStaffDialogContainer } from '../EditStaffDialog';

interface NewStaffDialogContainer {
  open?: boolean;
  onClose: () => void;
  onSaveSuccess: () => void;
  dialogType?: string;
  staffToEditId?: string;
}

function NewStaffDialogContainer({
  open,
  onClose,
  dialogType = 'new',
  onSaveSuccess,
  staffToEditId,
}: NewStaffDialogContainer): ReactElement {
  return (
    <EditStaffDialogContainer
      staffId={staffToEditId}
      open={open}
      onClose={onClose}
      onSaveSuccess={onSaveSuccess}
      dialogType={dialogType}
    />
  );
}

export default NewStaffDialogContainer;
