import React, { ReactElement, useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import styles from './AppointmentPersonalTime.module.scss';
import { DailyAppointment } from 'types';
import { IconButton } from '@material-ui/core';
import { ReactComponent as EditIcon } from 'assets/svgs/EditIcon.svg';
import { ReactComponent as RubishIcon } from 'assets/svgs/Rubish.svg';
import classNames from 'classnames';
import { AppointmentStatus, getAppointmentStatusNameById, SLOT_LENGTH } from 'lib/appointments';
import formatPhoneInput from 'lib/phoneNumFormatter';
import { DateTimeFormatString } from 'lib/dateFormatter';
import { convertTo12From24 } from 'lib/schedule';
import DeleteConfirmationDialog, {
  DeleteConfirmationDialogType,
} from 'components/DeleteConfirmationDialog/DeleteConfirmationDialog.view';
import { DOCUMENT_TYPES, REFERRAL_DOCUMENT_VALID_MONTHS } from 'lib/document';

interface AppointmentPersonalTimeProps {
  indexedTimes: Array<{ id: number; time: string }>;
  appointment: DailyAppointment;
  types?: Array<any>;
  filterAppointmentStatus?: number | null;
  filterAppointmentType?: string | number | null;
  filterSelectedStaff?: number | null | undefined;
  highlightOnlyCancellationAppts?: boolean;
  reschedulingAppointment?: boolean;
  patientPreset?: boolean;
  past72HoursAppointment?: boolean;
  goToAppointmentId?: number | undefined;
  readOnly?: boolean;
  date?: Moment;
  error?: boolean;
  selectedLocationId?: number;
  eventToDelete?: any;
  deleting?: boolean;
  deletePersonalTime?: () => Promise<void>;
  setEventToDelete?: (event) => void;
  appointmentDate?: Moment;
  onUpdateAppointmentSuccess?: () => void;
  editAppointment?: (appt) => void;
  setResponseAppt?: (appointment) => void;
  setShowConfirmation?: (boolean) => void;
  onUpdateApptStatus?: (appointment) => void;
}

function AppointmentPersonalTime({
  appointmentDate,
  indexedTimes,
  appointment,
  types,
  filterAppointmentStatus,
  filterAppointmentType,
  filterSelectedStaff,
  highlightOnlyCancellationAppts,
  reschedulingAppointment,
  patientPreset = false,
  past72HoursAppointment,
  goToAppointmentId,
  readOnly,
  date,
  selectedLocationId,
  eventToDelete,
  deleting,
  deletePersonalTime,
  setEventToDelete,
  onUpdateAppointmentSuccess,
  editAppointment,
  setResponseAppt,
  setShowConfirmation,
  onUpdateApptStatus,
}: AppointmentPersonalTimeProps): ReactElement {
  const [filtered, setFiltered] = useState<boolean>(false);
  const [highlighted, setHighlighted] = useState<boolean>(false);

  function onConfirmDelete() {
    appointment.appointmentStatus = AppointmentStatus.Deleted;
    if (setEventToDelete) setEventToDelete(appointment);
    if (onUpdateAppointmentSuccess) onUpdateAppointmentSuccess();
    if (setResponseAppt) setResponseAppt(appointment);
  }

  const onDeleteHandler = async (): Promise<void> => {
    if (deletePersonalTime) deletePersonalTime();
  };

  const converTimeToOffset = (indexedTimes, appointmentStartTime) => {
    const indexOffset = indexedTimes.find(time => {
      return time.time === appointmentStartTime;
    });
    const heightOfBlock = 15;
    return indexOffset.id * heightOfBlock + 1; // +1 to better line up appointments to have non-overlapping borders
  };

  const convertSlotLengthToSize = (indexedSlotLength, appointmentSlotLength) => {
    const indexSize = indexedSlotLength.find(slotLength => {
      return slotLength.id == appointmentSlotLength;
    });
    const heightOfBlock = 15;
    const offsetHeight = indexSize.offsetSpaceUse * heightOfBlock;
    return offsetHeight;
  };

  useEffect(() => {
    if (types) {
      const subType = types.find(appType => appType.id == filterAppointmentType);
      let subTypeName = types.find(appType => appType.id == filterAppointmentType)?.name;
      if (!subTypeName) {
        subTypeName = 'None';
      }
      setHighlighted(false);
      if (
        // if appointmentStatus filter is enabled and does not match this appointment
        filterAppointmentStatus &&
        filterAppointmentStatus != -1 &&
        filterAppointmentStatus != Number(appointment.appointmentStatus)
      ) {
        setFiltered(true);
      } else if (
        // if appointmentType filter is enabled and does not match this appointment, and does not match this appt subType
        filterAppointmentType &&
        filterAppointmentType != -1 &&
        appointment.appointmentSubTypeId !== subType?.id
      ) {
        setFiltered(true);
      } else if (
        // if selectedStaff filter is enabled and does not match this appointment
        filterSelectedStaff &&
        filterSelectedStaff != -1 &&
        filterSelectedStaff != appointment.doctor.id &&
        filterSelectedStaff != appointment.optionalTechnicianId &&
        filterSelectedStaff != appointment.optionalNurseId
      ) {
        setFiltered(true);
      } else if (
        // if cancellationList filter is enabled and does not match this appointment
        highlightOnlyCancellationAppts &&
        highlightOnlyCancellationAppts != appointment.appointmentCancellationList
      ) {
        setFiltered(true);
      } else {
        setFiltered(false);
        if (
          (filterAppointmentType && filterAppointmentType != -1) ||
          (filterAppointmentStatus && filterAppointmentStatus != -1) ||
          (filterSelectedStaff && filterSelectedStaff != -1) ||
          highlightOnlyCancellationAppts
        ) {
          setHighlighted(true);
        }
      }
    }
  }, [
    selectedLocationId,
    date,
    filterAppointmentStatus,
    filterAppointmentType,
    filterSelectedStaff,
    highlightOnlyCancellationAppts,
  ]);

  return (
    // (convert StartTime of appt as an index) * 30 (Height of Block)
    <div
      className={classNames(
        styles.appointmentInfoContainer,
        (filtered ||
          (goToAppointmentId && goToAppointmentId != appointment.appointmentId) ||
          reschedulingAppointment ||
          patientPreset) &&
          styles.filtered,
        (highlighted || (goToAppointmentId && goToAppointmentId === appointment.appointmentId)) &&
          styles.highlighted,
        appointment.appointmentInCancelledSchedule && styles.cancelled
      )}
      style={{
        top: converTimeToOffset(indexedTimes, appointment.appointmentStartTime),
        minHeight: convertSlotLengthToSize(SLOT_LENGTH, appointment.appointmentSlotLength),
        height: convertSlotLengthToSize(SLOT_LENGTH, appointment.appointmentSlotLength),
        position: 'absolute',
        width: '100%',
        overflowY: 'hidden',
        overflowX: 'hidden',
      }}
    >
      <div className={styles.appointmentInfoFlexRow}>
        <div className={styles.appointmentInfoFlexColumn}>
          <p className={styles.appointmentHours}>
            {convertTo12From24(appointment.appointmentStartTime)} -{' '}
            {convertTo12From24(appointment.appointmentEndTime)}
          </p>
          <p className={styles.appointmentType}>{'Personal Time'}</p>
          <p className={styles.appointmentType}>{appointment.doctor.fullName}</p>
        </div>

        {!past72HoursAppointment ? (
          <IconButton
            aria-label="delete"
            className={styles.delete}
            onClick={onConfirmDelete}
            disabled={reschedulingAppointment || patientPreset}
          >
            <RubishIcon title={'delete'} />
          </IconButton>
        ) : (
          <></>
        )}
      </div>
      <DeleteConfirmationDialog
        itemToDelete={eventToDelete}
        loading={deleting}
        onClose={() => {
          if (setEventToDelete) setEventToDelete(null);
        }}
        onConfirm={onDeleteHandler}
        deleteConfirmDialogType={DeleteConfirmationDialogType.Appointments}
      />
    </div>
  );
}

export default AppointmentPersonalTime;
