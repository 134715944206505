import React, { ReactElement } from 'react';

import styles from './QuestionSectionStyles.module.scss';

interface ImageQuestionSectionProps {
  title?: string;
  description?: string;
}

function ImageQuestionSection({ title, description }: ImageQuestionSectionProps): ReactElement {
  return (
    <div className={styles.main}>
      <div className={styles.title}>{title}</div>
      <div className={styles.answerContainer}>
        <div className={styles.answer}>
          <img alt={title} src={description} className={styles.img} />
        </div>
      </div>
    </div>
  );
}

export default ImageQuestionSection;
