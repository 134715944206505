import React, { ReactElement, useRef, useState } from 'react';

import Dialog from 'components/Dialog';
import styles from './NewDefaultFormDialogStyles.module.scss';
import DialogButton from 'components/DialogButton';
import { UploadTemplateFormBodyPermission as FormPermission, UploadTemplateFormInput } from 'types';
import { FormikErrors, useFormik } from 'formik';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  TextField,
} from '@material-ui/core';
import Button from 'components/CVCButton';
import useTemplatesApi from 'hook/useTemplatesApi.hook';
import { TemplateType } from 'api/template';
import Select from 'components/Select';
import useUserTypesApi from 'hook/useUserTypesApi.hook';

interface NewDefaultFormDialogProps {
  show?: boolean;
  onClose: () => void;
  onConfirm: (input: UploadTemplateFormInput) => void;
  loading: boolean;
  error?: string;
}

interface FormikValues {
  file: File | undefined;
  title: string;
  templateTypeId?: number;
  templateKey?: string;
  permissions: FormPermission;
  doctorSignature?: boolean;
  templateTypeDescription?: string;
}

function NewDefaultFormDialog({
  show,
  onClose,
  onConfirm,
  loading,
  error,
}: NewDefaultFormDialogProps): ReactElement {
  const fileInput = useRef<any>();
  const [selectedFile, setSelectedFile] = useState<File | null>();
  const [selectedTemplateType, setSelectedTemplateType] = useState<TemplateType>();
  const [showCreateNewTemplateType, setShowCreateNewTemplateType] = useState<boolean>(false);
  const usersApi = useUserTypesApi();
  const { userTypes } = usersApi;
  const templatesApi = useTemplatesApi();
  const { templateTypes } = templatesApi;

  const formik = useFormik({
    initialValues: {
      file: undefined,
      title: '',
      templateKey: '',
      templateTypeId: undefined,
      doctorSignature: false,
      permissions: {
        admin: {
          read: true,
          write: true,
          delete: true,
        },
        doctor: {
          read: false,
          write: false,
          delete: false,
        },
        technician: {
          read: false,
          write: false,
          delete: false,
        },
        patient: {
          read: false,
          write: false,
          delete: false,
        },
        nurse: {
          read: false,
          write: false,
          delete: false,
        },
        receptionist: {
          read: false,
          write: false,
          delete: false,
        },
        physicianAssistant: {
          read: false,
          write: false,
          delete: false,
        },
        noStaff: {
          read: false,
          write: false,
          delete: false,
        },
        superAdmin: {
          read: true,
          write: true,
          delete: true,
        },
      },
    },
    validate: (values: FormikValues) => {
      const errors: FormikErrors<FormikValues> = {};

      if (!values.title || values.title === '') {
        errors.title = 'Enter title';
      }

      if (!selectedFile) {
        errors.file = 'Select file to upload';
      }

      if (showCreateNewTemplateType && !values.templateTypeDescription) {
        errors.templateTypeId = 'Enter new template type';
      } else if (!showCreateNewTemplateType && !selectedTemplateType) {
        errors.templateTypeId = 'Select template type';
      }

      return errors;
    },
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values: FormikValues) => {
      if (selectedFile) {
        // Patient write permissions are always the same as read
        const permissions = values.permissions;
        permissions.patient.write = permissions.patient.read;
        onConfirm({
          fileName: selectedFile.name,
          mimeType: selectedFile.type,
          title: values.title,
          description: values.title,
          templateTypeId: selectedTemplateType?.id,
          templateKey: values.title,
          templateTypeDescription: values.templateTypeDescription,
          permissions,
          file: selectedFile,
          doctorSignature: values.doctorSignature,
        });
        formik.resetForm();
        setSelectedFile(null);
        setSelectedTemplateType(undefined);
      }
    },
  });

  const onChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    event.preventDefault();
    if (event.target.files) {
      const file = event.target.files[0];
      setSelectedFile(file);
    }
  };

  const snakeCaseToCamelCase = (str: string) => {
    return str.replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
  };

  const handleTemplateTypeChange = event => {
    if (event.target.value === 'createNew') {
      setShowCreateNewTemplateType(true);
      setSelectedTemplateType(undefined);
    } else {
      setShowCreateNewTemplateType(false);
      formik.setFieldValue('templateTypeDescription', undefined);
      const templateType = templatesApi.templateTypes.find(item => item.id == event.target.value);
      setSelectedTemplateType(templateType);
    }
  };

  function onCloseForm() {
    formik.resetForm();
    setSelectedFile(null);
    setSelectedTemplateType(undefined);
    onClose();
  }

  return (
    <Dialog open={show} onClose={onCloseForm} title={`Create New Default Form`}>
      <div className={styles.container}>
        <Grid container className={styles.gridContainer}>
          <TextField
            label="Title"
            name="title"
            value={formik.values.title}
            inputProps={{ maxLength: 500 }}
            onChange={formik.handleChange}
            fullWidth
          />
          <div className={styles.error}>{formik.errors.title || ''}</div>
          <Grid item sm={12} xs={12}>
            <div className={styles.padding}>
              <Button onClick={(): void => fileInput?.current?.click()}>{'Select PDF file'}</Button>
              <input
                type="file"
                ref={fileInput}
                style={{ display: 'none' }}
                name="file"
                onChange={onChangeFile}
                accept={'.pdf'}
              />
              <div className={styles.fileName}>{selectedFile?.name || ''}</div>
            </div>
          </Grid>
          <div className={styles.error}>{formik.errors.file || ''}</div>
          <Grid item sm={12} xs={12} style={{ marginBottom: '1rem' }}>
            <div className={styles.containerTitle}>Permissions</div>
            <div className={styles.containerSubtitle}>
              Admin and Super Admin will be set to have Read/Write/Delete Privileges
            </div>
            <div className={styles.padding}>
              {userTypes.length > 0 &&
                userTypes.map(userType => {
                  if (
                    userType.type !== 'admin' &&
                    userType.type.toLowerCase() !== 'superadmin' &&
                    userType.type !== 'patient'
                  ) {
                    const fieldName = snakeCaseToCamelCase(userType.type);
                    return (
                      <div key={userType.id} className={styles.rowContainer}>
                        <div className={styles.rowText}>
                          {userType.type.toUpperCase().replace('_', ' ')}
                        </div>
                        <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name={`permissions.${fieldName}.read`}
                                checked={formik.values.permissions[fieldName].read}
                                disabled={formik.values.permissions[fieldName].write}
                                onChange={formik.handleChange}
                              />
                            }
                            label="Can Read"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                name={`permissions.${fieldName}.write`}
                                checked={formik.values.permissions[fieldName].write}
                                onChange={e => {
                                  if (e.target.checked) {
                                    formik.setFieldValue(`permissions.${fieldName}.read`, true);
                                  }
                                  formik.handleChange(e);
                                }}
                              />
                            }
                            label="Can Write"
                          />
                        </FormGroup>
                      </div>
                    );
                  }
                })}
              <div key="patient" className={styles.rowContainer}>
                <div className={styles.rowText}>PATIENT</div>
                <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={`permissions.patient.read`}
                        checked={formik.values.permissions.patient.read}
                        onChange={formik.handleChange}
                      />
                    }
                    label="Can Read & Write"
                  />
                </FormGroup>
              </div>
            </div>
          </Grid>
          <Grid item sm={12} xs={12}>
            <div className={styles.padding}>
              <Select
                label="Template Type"
                name={'templateTypeId'}
                fullWidth
                onChange={handleTemplateTypeChange}
                value={selectedTemplateType ? selectedTemplateType.id : ''}
              >
                <MenuItem key="createNew" value="createNew">
                  Create new template type...
                </MenuItem>
                {templateTypes.map(templateType => (
                  <MenuItem key={templateType.id} value={templateType.id}>
                    {templateType.name}
                  </MenuItem>
                ))}
              </Select>
              <div className={styles.error}>{formik.errors.templateTypeId || ''}</div>
            </div>
          </Grid>
          {showCreateNewTemplateType && (
            <Grid item sm={12} xs={12}>
              <div className={styles.padding}>
                <TextField
                  label="New Template Type"
                  name="templateTypeDescription"
                  value={formik.values.templateTypeDescription || ''}
                  inputProps={{ maxLength: 255 }}
                  onChange={formik.handleChange}
                  fullWidth
                />
                <div className={styles.error}>{formik.errors.templateTypeDescription || ''}</div>
              </div>
            </Grid>
          )}
          <Grid item sm={12} xs={12}>
            <div className={styles.padding}>
              <FormControlLabel
                control={
                  <Checkbox
                    name={`doctorSignature`}
                    checked={formik.values.doctorSignature}
                    onChange={formik.handleChange}
                  />
                }
                label="Require Doctor's Signature"
              />
            </div>
          </Grid>
        </Grid>
        <div className={styles.error}>{error || ''}</div>
        <div className={styles.buttons}>
          <DialogButton onClick={onCloseForm}>Cancel</DialogButton>
          <DialogButton loading={loading} onClick={formik.handleSubmit}>
            Confirm
          </DialogButton>
        </div>
      </div>
    </Dialog>
  );
}

export default NewDefaultFormDialog;
