import React, { ReactElement } from 'react';
import Link from '@material-ui/core/Link';
import { NavLink } from 'react-router-dom';

import Paper from 'components/Paper';
import ConditionDiv from 'components/ConditionDiv';

import styles from './TaskStyles.module.scss';

interface TaskProps {
  title?: string;
  data?: {
    title?: string;
    patientName?: string;
    createdAt?: string;
    createdBy?: string;
    assignedTo?: string;
    description?: string;
    userId?: number;
    patientId?: number;
  };
  completed?: boolean;
  className?: string;
  setTaskToEdit: () => void;
  onClickComplete?: () => void;
  onReassign?: () => void;
  parentUrl: string;
}

function Task({
  title,
  data,
  completed,
  setTaskToEdit,
  onClickComplete,
  onReassign,
  className,
  parentUrl,
}: TaskProps): ReactElement {
  const url = !parentUrl || parentUrl === '/' ? '' : parentUrl;

  const infoRow = (title, info) => {
    return (
      <div className={styles.infoRow}>
        <div className={styles.infoTitle}>{`${title}: `}</div>
        <div className={styles.info}>{info}</div>
      </div>
    );
  };

  return (
    <Paper className={className}>
      <div className={styles.title}>{title}</div>
      {infoRow(
        'Patient',
        <NavLink
          to={`${url}/patient/${data?.patientId}`}
          className={styles.info}
          activeClassName={styles.selected}
          exact
        >
          {data?.patientName}
        </NavLink>
      )}
      {infoRow('Created At', data?.createdAt)}
      {infoRow('Created By', data?.createdBy)}
      {infoRow('Assigned To', data?.assignedTo)}

      <div className={styles.description}>{data?.description}</div>
      <div className={styles.buttons}>
        <ConditionDiv condition={!onReassign} />
        <ConditionDiv condition={!!onReassign}>
          <Link
            className={styles.link}
            onClick={() => {
              setTaskToEdit();
              onReassign && onReassign();
            }}
          >
            Edit
          </Link>
        </ConditionDiv>
        <ConditionDiv condition={completed}>
          <div className={styles.status}>Completed</div>
        </ConditionDiv>
        <ConditionDiv condition={!completed && !!onClickComplete}>
          <Link className={styles.link} onClick={onClickComplete}>
            Mark as complete
          </Link>
        </ConditionDiv>
      </div>
    </Paper>
  );
}

export default Task;
