import React, { createContext, ReactElement, useState } from 'react';
import { User } from 'types';

interface ProfileData {
  user: User | null;
  setUser(user: User | null);
}

interface ProfileProps {
  children: any;
}

const Context = createContext<ProfileData>({ user: null, setUser: () => null });

const { Provider, Consumer } = Context;

function ProfileProvider({ children }: ProfileProps): ReactElement {
  const [user, setUser] = useState<User | null>(null);

  return <Provider value={{ user, setUser }}>{children}</Provider>;
}

export { Context, Consumer, ProfileProvider as Provider };
