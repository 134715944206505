import { EMAIL_REGEX_RFC_5322 } from './const';
import { CanadaProvinceCodes } from 'lib/addresses';
import { SEND_TYPES } from './document';

const healthCardCheckSum = (healthCard: string): boolean => {
  const newHealthCard = healthCard.split('').map(i => Number(i));
  for (let i = 0; i < newHealthCard.length; i += 2) {
    const value = newHealthCard[i];
    const sum = (value * 2).toString();
    const newValue = sum.length > 1 ? parseInt(sum[0]) + parseInt(sum[1]) : parseInt(sum[0]);
    newHealthCard[i] = newValue;
  }
  newHealthCard.pop();
  const lastDigit = newHealthCard
    .reduce((a, b) => a + b, 0)
    .toString()
    .split('')
    .pop();
  let checkDigit;
  if (lastDigit === '0' && healthCard.charAt(9) === '0') return true;
  if (lastDigit) checkDigit = (10 - parseInt(lastDigit)).toString();
  if (healthCard.charAt(9) === checkDigit) {
    return true;
  }
  return false;
};

export interface DynamicData {
  [other: string]: any;
}

export interface ValidationRule {
  name: string;
  rule: (data: DynamicData, name: string) => boolean;
  message: string;
}

export interface Errors {
  [other: string]: string;
}

export const emailValidationRule = (data: DynamicData, name: string): boolean => {
  if (data.sendType != SEND_TYPES.EMAIL) return true;
  return data[name] && EMAIL_REGEX_RFC_5322.test(data[name]);
};

export const faxNumberValidationRule = (data: DynamicData, name: string): boolean => {
  if (data.sendType != SEND_TYPES.FAX) return true;
  return data[name] && data[name].length >= 10;
};

export const versionCodeValidationRule = (versionCode: string, province: string): boolean =>
  province === CanadaProvinceCodes.ON ? /^[A-Z()]*$/.test(versionCode) : true;

export const healthCardValidationRule = (
  healthCard: string,
  province: string,
  noHealthCard: boolean
): boolean => {
  if (noHealthCard) {
    return true;
  } else if (province === CanadaProvinceCodes.ON) {
    if (isNaN(+healthCard) || healthCard.length !== 10) {
      return false;
    }
    return healthCardCheckSum(healthCard);
  } else if (
    province === CanadaProvinceCodes.AB ||
    province === CanadaProvinceCodes.MB ||
    province === CanadaProvinceCodes.NB ||
    province === CanadaProvinceCodes.SK ||
    province === CanadaProvinceCodes.NU ||
    province === CanadaProvinceCodes.YT
  ) {
    if (isNaN(+healthCard) || healthCard.length !== 9) {
      return false;
    }
  } else if (province === CanadaProvinceCodes.NT) {
    if (healthCard.length !== 8) {
      return false;
    }
    const allowedCharacters = ['N', 'D', 'M', 'T'];
    const firstLetter = healthCard.charAt(0);
    if (!allowedCharacters.includes(firstLetter)) {
      return false;
    }
  } else if (province === CanadaProvinceCodes.PE) {
    if (isNaN(+healthCard) || healthCard.length !== 8) {
      return false;
    }
  } else if (province === CanadaProvinceCodes.NL) {
    if (isNaN(+healthCard) || healthCard.length !== 12) {
      return false;
    }
  } else if (province === CanadaProvinceCodes.BC || province === CanadaProvinceCodes.NS)
    if (isNaN(+healthCard) || healthCard.length !== 10) {
      return false;
    }
  return true;
};

const validator = (rules: Array<ValidationRule>) => (data: DynamicData): Errors => {
  const errors: Errors = {};

  rules.forEach((item: ValidationRule) => {
    if (!item.rule(data, item.name)) {
      errors[item.name] = errors[item.name] || item.message;
    }
  });
  return errors;
};

export default validator;
