import React from 'react';
import styles from './PatientResultsStyles.module.scss';
import { CaseResult, CaseResultAnswer } from 'types';
import { PatientLabels, PatientMetaLabels, parseText } from './lib/calculatePatientResults';
import usePatientResults from './usePatientResults.hook';

interface PatientWorkflowResultProps {
  formCaseResult: CaseResult | null;
}

interface DisplayDataType {
  answer?: CaseResultAnswer;
  label?: string;
}

const DisplayData = ({ answer, label }: DisplayDataType) => {
  if (!answer) {
    return null;
  }

  return (
    <li key={answer.id}>
      {label}: {parseText(answer)}
    </li>
  );
};

interface DisplayResultsProps {
  answersMap: Map<string, any>;
  labelsMap: Map<string, string>;
  title: string;
}

const DisplayResults = ({ answersMap, labelsMap, title }: DisplayResultsProps) => {
  const labelKeys = Array.from(labelsMap.keys());

  return (
    <div>
      <div className={styles.title}>{title}</div>
      <ul>
        {labelKeys.map(pmk => {
          return <DisplayData key={pmk} answer={answersMap.get(pmk)} label={labelsMap.get(pmk)} />;
        })}
      </ul>
    </div>
  );
};

export const PatientWorkflowResult = ({ formCaseResult }: PatientWorkflowResultProps) => {
  const { answersMap } = usePatientResults({
    formCaseResult,
  });

  if (!formCaseResult || !formCaseResult.answers) {
    return <div>loading...</div>;
  }

  return (
    <div className={styles.legEntries}>
      <div className={styles.pageTitle}>Patient Data</div>
      <div>
        <DisplayResults
          answersMap={answersMap}
          labelsMap={PatientMetaLabels}
          title={'Basic Info'}
        />
        <DisplayResults
          answersMap={answersMap}
          labelsMap={PatientLabels}
          title={'Patient Form Result'}
        />
      </div>
    </div>
  );
};

export default PatientWorkflowResult;
