import React, { useState } from 'react';
import { CreateReport, CSVHeader, ReportType } from 'types';
import { ReportApi } from 'api';
import * as logger from 'api/logger';

interface ReportInfo {
  types: Array<ReportType>;
  csvHeaders: Array<CSVHeader>;
  report: any;
  totalResults: number;
  fetchTypes: () => void;
  createReport: (data: CreateReport) => any;
  resetReport: () => void;
}

function useReport(): ReportInfo {
  const [types, setTypes] = useState<Array<ReportType>>([]);
  const [totalResults, setTotalResults] = useState<number>(0);
  const [csvHeaders, setCSVHeaders] = useState<Array<CSVHeader>>([]);
  const [report, setReport] = useState<Array<any>>([]);

  const fetchTypes = async () => {
    try {
      const types = await ReportApi.fetchTypes();
      setTypes(types);
    } catch (error) {
      logger.error(error.message);
    }
  };

  const createReport = async (data: CreateReport) => {
    try {
      const reportResult = await ReportApi.getReport(data);
      const report = reportResult.report;
      let headerKeys: Array<CSVHeader> = [];
      if (report.length) {
        headerKeys = Object.keys(report[0]).map(key => {
          return {
            key: key,
            label: key,
          };
        });
      }
      setCSVHeaders(headerKeys);
      setReport(report);
      setTotalResults(reportResult.total);
    } catch (error) {
      logger.error(error.message);
    }
  };

  const resetReport = () => {
    setReport([]);
  };
  return {
    report,
    types,
    csvHeaders,
    totalResults,
    fetchTypes,
    createReport,
    resetReport,
  };
}

export default useReport;
