import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { IconButton } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import FlagIcon from '@material-ui/icons/Flag';
import SettingsIcon from '@material-ui/icons/Settings';
import styles from './DocumentStyles.module.scss';
import Paper from 'components/Paper';
import { ReactComponent as EditIcon } from 'assets/svgs/EditIcon.svg';
import classNames from 'classnames';
import Checkbox from '@material-ui/core/Checkbox';
import { DocumentApi } from 'api';
import formatDate, { DateTimeFormat } from 'lib/dateFormatter';

interface DocumentProps {
  id?: number | string;
  title?: string;
  uploadedAt?: string;
  createdBy?: string | number;
  type?: string;
  status?: string;
  className?: string;
  flagged?: boolean;
  selected?: boolean;
  selectDocument?: () => void;
  openDocument: () => void;
  editDocument: (() => void) | null;
  flagDocument: () => void;
  setSnackBarOpen: (open: boolean) => void;
  setRenameDocumentId: (documentId: string | number | undefined) => void;
  setCopyDocument: (documentId: string | number | undefined) => void;
}

function Document({
  id,
  title,
  uploadedAt,
  createdBy,
  type,
  status,
  className,
  flagged,
  selected,
  selectDocument,
  openDocument,
  editDocument,
  flagDocument,
  setSnackBarOpen,
  setRenameDocumentId,
  setCopyDocument,
}: DocumentProps): ReactElement {
  const [displaySettings, setDisplaySettings] = useState<boolean>(false);
  const clickDocument = useCallback(async () => {
    openDocument();
  }, []);

  async function getDocumentUrl(): Promise<void> {
    setDisplaySettings(false);
    if (!id) return;
    const result = await DocumentApi.downloadDocument(id);
    navigator.clipboard.writeText(result.signedRequest);
    setSnackBarOpen(true);
  }

  const assignRenameDocument = () => {
    setRenameDocumentId(id);
    setDisplaySettings(false);
  };

  const copyDocument = () => {
    setCopyDocument(id);
    setDisplaySettings(false);
  };

  const settingsDropdownId = () => {
    return `settings${id}`;
  };

  const infoRow = info => {
    return (
      <div className={styles.infoRow}>
        <div className={styles.title}>{`• `}</div>
        <div className={styles.info}>{info}</div>
      </div>
    );
  };

  useEffect(() => {
    const buttonDiv = document.getElementById(settingsDropdownId());

    const checkIfClickedOutside = e => {
      if (buttonDiv?.contains(e.target)) {
        return false;
      }
      setDisplaySettings(false);
    };
    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [displaySettings]);

  return (
    <Paper className={classNames(className, flagged && styles.flagged)}>
      <div className={styles.main}>
        <Checkbox onClick={selectDocument} checked={selected} />
        <div className={styles.content}>
          <div className={styles.title} onClick={clickDocument}>
            {title}
          </div>
          <div className={styles.subInfo}>
            {infoRow(formatDate(DateTimeFormat.DayMonthYearWithTime, uploadedAt))}
            {infoRow(createdBy)}
            {infoRow(type)}
            <div className={styles.right}>{status}</div>
          </div>
        </div>
        {editDocument ? (
          <IconButton aria-label="edit" className={styles.remove} onClick={editDocument}>
            <EditIcon title={'edit'} />
          </IconButton>
        ) : null}
        <div className={styles.dropdown} id={settingsDropdownId()}>
          <IconButton
            aria-label="flag"
            className={styles.remove}
            onClick={() => setDisplaySettings(true)}
          >
            <SettingsIcon />
          </IconButton>
          {displaySettings && (
            <div className={styles.dropdownContent}>
              <a onClick={assignRenameDocument}>Rename</a>
              <a onClick={copyDocument}>Create Duplicate</a>
              <a onClick={getDocumentUrl}>Copy URL</a>
            </div>
          )}
        </div>
        <IconButton aria-label="flag" className={styles.remove} onClick={flagDocument}>
          <FlagIcon />
        </IconButton>
      </div>
    </Paper>
  );
}

export default Document;
