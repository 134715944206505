import React, { ReactElement, useEffect, useState } from 'react';
import styles from './ConfirmAppointment.module.scss';

import { AppointmentsApi, LocationApi } from 'api';
import { handleAPIErrors } from 'lib/handleAPIErrors';
import { AlertDialog } from 'components/AlertDialog';
import MainLogo from 'assets/images/logo.png';
import formatPhoneInput from 'lib/phoneNumFormatter';
import { ContactType, Location } from 'types';
import jwtDecode from 'jwt-decode';

interface TokenBody {
  id: number;
  appointmentId: number;
  locationPhone: string;
}

function ConfirmAppointment(props): ReactElement {
  const appointmentId = props?.match?.params?.appointmentId;
  const token = props?.match?.params?.jwtToken;

  const [appointmentUpdated, setAppointmentUpdated] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [locationPhone, setLocationPhone] = useState<string>('');

  const confirmAppointment = async token => {
    try {
      await AppointmentsApi.patientConfirmAppointment(appointmentId, token);
      setAppointmentUpdated(true);
    } catch (err) {
      const message = handleAPIErrors(err);
      setErrorMessage(message);
      setError(true);
    }
  };

  useEffect(() => {
    if (token) {
      try {
        const tokenBody: TokenBody = jwtDecode(token);
        setLocationPhone(tokenBody.locationPhone);
        confirmAppointment(token);
      } catch (e) {
        console.log('ERROR');
        props?.history?.push('/404');
      }
    }
  }, []);

  const getMessage = () => {
    let message = 'Confirming Appointment...';
    let extraInfo = '';
    let phone = '';

    if (locationPhone && (error || appointmentUpdated)) {
      phone = formatPhoneInput(locationPhone);
      if (error) message = errorMessage;
      if (appointmentUpdated) message = 'Thank you for confirming your appointment';
      extraInfo = `If you need to change appointment please contact us right away at office@canadaveinclinics.ca or call ${phone}`;
    }

    return (
      <div className={styles.confirmed}>
        {message}
        {phone && <div className={styles.extraInfo}>{extraInfo}</div>}
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <img className={styles.backgroundImage} alt="Logo" src={MainLogo} />
      {getMessage()}
    </div>
  );
}

export default ConfirmAppointment;
