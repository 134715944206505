import React, { ReactElement } from 'react';
import styles from './LocationSchedule.module.scss';
import StaffSchedule from '../StaffSchedule';
import { ReactComponent as LocationScheduleProfileIcon } from 'assets/svgs/ProfileIcon.svg';
import { ReactComponent as LocationScheduleEditIcon } from 'assets/svgs/EditIcon.svg';
import { ScheduledStaff } from 'types';
import { convertTo12From24 } from 'lib/schedule';
import { LocationsSchedule } from '../../useLocations.Interface';
import classNames from 'classnames';

interface LocationScheduleProps {
  startTime: string;
  endTime: string;
  schedule: LocationsSchedule;
  staffScheduleInfo: Array<unknown>;
  highlightedStaff: number;
  locationException: boolean;
  handleLocationScheduleEditIconClick: () => void;
  handleProfileIconClick: (locationRecurrence, locationRecurrenceEndDate) => void;
  handleStaffScheduleEditIconClick: (
    locationRecurrence,
    locationRecurrenceEndDate,
    staffScheduleId,
    userId
  ) => void;
}

function LocationSchedule({
  startTime,
  endTime,
  schedule,
  staffScheduleInfo,
  highlightedStaff,
  locationException,
  handleLocationScheduleEditIconClick,
  handleProfileIconClick,
  handleStaffScheduleEditIconClick,
}: LocationScheduleProps): ReactElement {
  const staffSchedules: Array<ScheduledStaff> = schedule.staffSchedules;

  const createStaffSchedule = (): void => {
    handleProfileIconClick(schedule.recurrence, schedule.recurrenceEndDate);
  };

  const editStaffSchedule = (staffScheduleId, userId): void => {
    handleStaffScheduleEditIconClick(
      schedule.recurrence,
      schedule.recurrenceEndDate,
      staffScheduleId,
      userId
    );
  };

  return (
    <div className={classNames(styles.container, schedule.cancelled && styles.cancelled)}>
      <div className={styles.labelIconContainer}>
        <b>
          {convertTo12From24(startTime)} - {convertTo12From24(endTime)}
          {/*TODO: Add ability to update hours when new staff schedule added */}
        </b>
        <div className={styles.icon}>
          <LocationScheduleEditIcon
            title="Edit Schedule"
            onClick={handleLocationScheduleEditIconClick}
            className={styles.editIcon}
          />
        </div>
        {!locationException && !schedule.cancelled ? (
          <div className={styles.icon}>
            <LocationScheduleProfileIcon
              title="Create Staff Schedule"
              className={styles.profileIcon}
              onClick={createStaffSchedule}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
      <div>
        {staffSchedules.map((staffSchedule: ScheduledStaff, index) => {
          // TODO: staff Type should be derieved from schedule
          // TODO: Map staff type to colour

          return (
            <StaffSchedule
              key={index}
              fullName={staffSchedule.fullName}
              staffScheduleId={staffSchedule.scheduleId}
              userId={staffSchedule.userId}
              staffId={staffSchedule.id}
              staffScheduleInfo={staffScheduleInfo}
              type={schedule.type}
              startTime={startTime}
              staffStartTime={staffSchedule.startTime}
              staffEndTime={staffSchedule.endTime}
              staffType={staffSchedule.staffType}
              endTime={endTime}
              highlightedStaff={highlightedStaff}
              locationRecurrence={schedule.recurrence}
              handleStaffScheduleEditIconClick={editStaffSchedule}
              cancelled={staffSchedule.cancelled}
            />
          );
        })}
      </div>
    </div>
  );
}

export default LocationSchedule;
