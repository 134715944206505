import axios from 'axios';

import { getDefaultHeader } from '../utils';
import { allSettledPolyfill } from 'lib/promise';
import { SEND_LOGS } from 'lib/config';

export const error = async (err: string) => {
  console.error(err);
  const response = await axios.post(
    SEND_LOGS,
    { level: 'error', message: err },
    { headers: getDefaultHeader() }
  );
  return response;
};

export const log = async (log: string) => {
  console.log(log);
  const response = await axios.post(
    SEND_LOGS,
    { level: 'info', message: log },
    { headers: getDefaultHeader() }
  );
  return response;
};
