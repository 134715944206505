import React, { ReactElement, memo } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import OutboundEnhancedTableHead from '../OutboundEnhancedTableHead';
import { useStyles, StyledTableCell, StyledTableRow } from 'lib/table';
import styles from './FaxSent.module.scss';
import { FaxOutbound } from 'types';
import formatPhoneInput from 'lib/phoneNumFormatter';
import formatDate, { DateTimeFormat } from 'lib/dateFormatter';

interface FaxReceivedProps {
  faxes: Array<FaxOutbound>;
}

function FaxSent({ faxes }: FaxReceivedProps): ReactElement {
  const classes = useStyles();

  const getDestination = (fax: FaxOutbound) => {
    let sentTo = '';
    sentTo += fax.sentToName ? `${fax.sentToName}  ` : '';
    sentTo += `${formatPhoneInput(fax.sentToNumber)}`;

    return sentTo;
  };

  const getStatusName = row => {
    if (!row.statusName) {
      return row.statusId > 0 ? 'FAILED' : 'IN PROGRESS';
    }

    return row.statusName;
  };
  return (
    <div className={styles.container}>
      <TableContainer className={styles.tableContainer}>
        <Table className={classes.table} aria-label="customized table">
          <OutboundEnhancedTableHead
            classes={classes}
            numSelected={0}
            onSelectAllClick={(): boolean => true}
            rowCount={3}
          />
          <TableBody>
            {faxes.map((row, index) => {
              return (
                <StyledTableRow
                  hover
                  role="checkbox"
                  aria-checked={false}
                  tabIndex={-1}
                  key={row.id}
                  selected={false}
                >
                  <StyledTableCell id={`${row.id}`}>
                    {row.messageId ? row.messageId : ''}
                  </StyledTableCell>
                  <StyledTableCell id={`${row.id}`}>{row.title}</StyledTableCell>
                  <StyledTableCell>{getStatusName(row)}</StyledTableCell>
                  <StyledTableCell>{getDestination(row)}</StyledTableCell>
                  <StyledTableCell>{row.pagesSent}</StyledTableCell>
                  <StyledTableCell>{row.sentBy}</StyledTableCell>
                  <StyledTableCell>
                    {formatDate(DateTimeFormat.DateAndTimeWithoutMeridies12h, row.completionTime)}
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default memo(FaxSent);
