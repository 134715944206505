import { MenuItem } from '@material-ui/core';
import Dialog from 'components/Dialog';
import Select from 'components/Select';
import {
  convertTo12From24,
  getEndTimesArrayFiveMinInterval,
  getStartTimesArrayFiveMinInterval,
} from 'lib/schedule';
import moment, { Moment } from 'moment';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import {
  AddPersonalTimeValidationError,
  DoctorSchedule,
  Location,
  LocationListItem,
  StaffInfo,
  StaffScheduleValidationError,
} from 'types';
import styles from './AddPersonalTimeDialog.module.scss';
import { Context as ProfileContext } from 'context/profile';
import DialogButton, { DialogButtonType } from 'components/DialogButton/DialogButton.view';
import { AppointmentStatus, APPOINTMENT_TYPES } from 'lib/appointments';
import InfoRow from 'components/InfoRow';
import useAppointmentsApi from 'pages/Appointments/useAppointmentsApi.hook';
import formatDate, { DateTimeFormat } from 'lib/dateFormatter';
import { AlertDialog } from 'components/AlertDialog';
import { handleAPIErrors } from 'lib/handleAPIErrors';
import { ROLE_ID } from 'lib/user';
import { ValidationError } from 'components/Forms/ValidationError';

interface AddPersonalTimeDialogProps {
  open: boolean;
  onSave: (data, referralFile, selectedPatientId) => void;
  onClose: () => void;
  resetError: () => void;
  date: Moment;
  location: LocationListItem | undefined;
  staffSchedules: Array<DoctorSchedule>;
  scheduledAppointments: Array<any>;
}
function AddPersonalTimeDialog({
  date,
  location,
  open,
  staffSchedules,
  scheduledAppointments,
  resetError,
  onSave,
  onClose,
}: AddPersonalTimeDialogProps): ReactElement {
  const [startTime, setStartTime] = useState<string>();
  const [endTime, setEndTime] = useState<string>();
  const [startTimes, setStartTimes] = useState<Array<string>>([]);
  const [endTimes, setEndTimes] = useState<Array<string>>([]);
  const [mainTypes, setMainTypes] = useState<Array<any>>([]);
  const [selectedMainType, setSelectedMainType] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [openAlertDialog, setOpenAlertDialog] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [validationErrors, setValidationErrors] = useState<Array<AddPersonalTimeValidationError>>(
    []
  );

  const { user } = useContext(ProfileContext);

  function validateForm(): boolean {
    let validated = true;
    const validationErrors: Array<AddPersonalTimeValidationError> = [];

    if (!startTime) {
      const validationError: StaffScheduleValidationError = {
        field: 'startTime',
        message: 'Please select a start time',
      };

      validationErrors.push(validationError);
      validated = false;
    }

    if (!endTime) {
      const validationError: StaffScheduleValidationError = {
        field: 'endTime',
        message: 'Please select an end time',
      };

      validationErrors.push(validationError);
      validated = false;
    }

    if (!selectedMainType) {
      const validationError: StaffScheduleValidationError = {
        field: 'type',
        message: 'Please select a tyoe',
      };

      validationErrors.push(validationError);
      validated = false;
    }

    setValidationErrors(validationErrors);
    return validated;
  }

  const getFilteredStaffSchedules = () => {
    const scheduleTypes = new Set();
    staffSchedules.forEach(schedule => {
      if (schedule.staffId == user?.staffInfo?.id) scheduleTypes.add(schedule.type);
    });
    const types = Array.from(scheduleTypes);

    setMainTypes(APPOINTMENT_TYPES.filter(type => types.includes(type.id)));
    const schedules = staffSchedules.filter(schedule => {
      return types.includes(schedule.type);
    });
    return schedules;
  };

  const getStartEndTimes = (): void => {
    let startTimes = getStartTimesArrayFiveMinInterval(null, null);
    let endTimes = getEndTimesArrayFiveMinInterval(null, null);

    const staffSchedules = getFilteredStaffSchedules();

    // Filter Times arrays to include times only between start and end time of staff schedules
    startTimes = startTimes.filter(time => {
      for (const staffSchedule of staffSchedules) {
        const afterStartTime = time >= staffSchedule.startTime;
        const beforeEndTime = time < staffSchedule.endTime;
        return afterStartTime && beforeEndTime;
      }
    });
    endTimes = endTimes.filter(time => {
      for (const staffSchedule of staffSchedules) {
        const afterStartTime = time > staffSchedule.startTime;
        const beforeEndTime = time <= staffSchedule.endTime;
        return afterStartTime && beforeEndTime;
      }
    });
    startTimes = startTimes.filter(time => {
      for (const staffAppt of scheduledAppointments) {
        const afterStartTime = time >= staffAppt.appointmentStartTime;
        const beforeEndTime = time < staffAppt.appointmentEndTime;
        return !(afterStartTime && beforeEndTime);
      }
      return true;
    });
    endTimes = endTimes.filter(time => {
      for (const staffAppt of scheduledAppointments) {
        const afterStartTime = time > staffAppt.appointmentStartTime;
        const beforeEndTime = time <= staffAppt.appointmentEndTime;
        return !(afterStartTime && beforeEndTime);
      }
      return true;
    });

    if (startTime) {
      endTimes = endTimes.filter(time => time > startTime);
    }

    if (endTime) {
      startTimes = startTimes.filter(time => time < endTime);
    }

    setStartTimes(startTimes);
    setEndTimes(endTimes);
  };

  const reset = () => {
    setStartTime(undefined);
    setEndTime(undefined);
    setStartTimes([]);
    setEndTimes([]);
    setMainTypes([]);
    setLoading(false);
    setSelectedMainType(undefined);
    setOpenAlertDialog(false);
    setAlertMessage('');
    setValidationErrors([]);
  };

  const handleStartTimeChange = e => {
    setStartTime(e.target.value);
  };

  const handleEndTimeChange = e => {
    setEndTime(e.target.value);
  };

  const onLocalSave = async () => {
    if (!user) return;
    if (!validateForm()) return;
    const dateOnly = formatDate(DateTimeFormat.APIDateFormat, date);
    setLoading(true);

    const createAppointment = {
      staffId: user?.staffInfo?.id,
      staffType: ROLE_ID[user?.type.toUpperCase()],
      caseId: 0,
      locationId: location?.id,
      startTime: dateOnly + ' ' + startTime,
      endTime: dateOnly + ' ' + endTime,
      anyDoctor: false,
      anyNotes: false,
      description: '',
      cancellationList: false,
      scheduleType: 0,
      mainTypeId: selectedMainType.id,
    };
    try {
      await onSave(createAppointment, undefined, undefined);
      onClose();
    } catch (e) {
      const message = handleAPIErrors(e);
      setAlertMessage(message);
      setOpenAlertDialog(true);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (!open) {
      reset();
      return;
    }
    getStartEndTimes();
  }, [open, startTime, endTime, user, staffSchedules]);

  return (
    <Dialog open={open} title={'Add Personal Time'} onClose={onClose}>
      <div className={styles.container}>
        <div>
          <InfoRow label={'Staff'} value={user?.fullName} />
          <InfoRow label={'Location'} value={location?.name} />
        </div>
        <div className={styles.times}>
          <Select
            label="Start Time"
            name={'startTime'}
            value={startTime}
            onChange={handleStartTimeChange}
            fullWidth
          >
            {startTimes?.map(item => (
              <MenuItem key={item} value={item}>
                {convertTo12From24(item)}
              </MenuItem>
            ))}
          </Select>
          <ValidationError field={'startTime'} validationErrors={validationErrors} />
          <Select
            label="End Time"
            name={'endTime'}
            value={endTime}
            onChange={handleEndTimeChange}
            fullWidth
          >
            {endTimes.map(item => (
              <MenuItem key={item} value={item}>
                {convertTo12From24(item)}
              </MenuItem>
            ))}
          </Select>
          <ValidationError field={'endTime'} validationErrors={validationErrors} />
          <Select
            label="Type"
            name={'type'}
            value={selectedMainType}
            onChange={e => setSelectedMainType(e.target.value)}
            fullWidth
          >
            {mainTypes?.map(item => (
              <MenuItem key={item} value={item}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
          <ValidationError field={'type'} validationErrors={validationErrors} />
        </div>
        <div className={styles.dialogBottom}>
          <div className={styles.buttomButtonSection}>
            <DialogButton buttonType={DialogButtonType.NegationLink} onClick={onClose}>
              Cancel
            </DialogButton>
            <DialogButton
              buttonType={DialogButtonType.AffirmativeLink}
              onClick={onLocalSave}
              loading={loading}
            >
              Save
            </DialogButton>
          </div>
        </div>
      </div>
      <AlertDialog
        open={openAlertDialog}
        message={alertMessage}
        title="Error"
        onClose={() => {
          setOpenAlertDialog(false);
          resetError();
        }}
      />
    </Dialog>
  );
}

export default AddPersonalTimeDialog;
