import React, { ReactElement } from 'react';

import Selector from 'components/Selector';

import { LocationListItem } from 'types';

interface LocationSelectorProps {
  value?: Array<number>;
  locations: Array<LocationListItem>;
  [other: string]: any;
  readOnly?: boolean;
  onChange?(value): void;
}

function LocationSelector({
  value,
  locations,
  readOnly,
  onChange = () => null,
  ...other
}: LocationSelectorProps): ReactElement {
  function onChangeValue(event): void {
    const value = event.target.value;

    if (value.length && value[0] === -1) {
      onChange([value[1]]);
      return;
    }

    if (value[value.length - 1] === -1) onChange([]);
    else onChange(value);
  }

  return (
    <Selector
      {...other}
      items={locations}
      label="Location"
      value={!value?.length ? [-1] : value}
      multiple
      onChange={onChangeValue}
      readOnly={readOnly}
    />
  );
}

export default LocationSelector;
