import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import classNames from 'classnames';
import Dialog from 'components/Dialog';
import DialogButton from 'components/DialogButton';
import { ValidationError } from 'components/Forms/ValidationError';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import {
  Document,
  DocumentType,
  DocumentValidationError,
  StaffScheduleValidationError,
} from 'types';
import FileUpload from '../NewDocumentDialog/components/FileUpload';
import styles from './RenameDocumentDialog.module.scss';

interface RenameDocumentDialogProps {
  open: boolean;
  document: Document | undefined;
  onClose: () => void;
  onSave: (documentId, params) => void;
  documentTypes: Array<DocumentType>;
  loading: boolean;
}

function RenameDocumentDialog({
  open,
  document,
  onClose,
  onSave,
  documentTypes,
  loading,
}: RenameDocumentDialogProps): ReactElement {
  const [title, setTitle] = useState<string>();
  const [documentType, setDocumentType] = useState<DocumentType | undefined>();
  const [validationErrors, setValidationErrors] = useState<Array<DocumentValidationError>>([]);

  function validateForm(): boolean {
    let validated = true;
    const validationErrors: Array<DocumentValidationError> = [];

    if (!title) {
      const validationError: DocumentValidationError = {
        field: 'title',
        message: 'Please create a title',
      };

      validationErrors.push(validationError);
      validated = false;
    }

    if (!documentType) {
      const validationError: DocumentValidationError = {
        field: 'documentType',
        message: 'Please select a document type',
      };

      validationErrors.push(validationError);
      validated = false;
    }

    setValidationErrors(validationErrors);
    return validated;
  }

  const onLocalSave = async () => {
    if (!validateForm()) return;
    const params = {
      title: title,
      documentTypeId: documentType?.id,
    };
    onSave(document?.id, params);
  };

  const reset = () => {
    setTitle('');
    setDocumentType(undefined);
  };

  const onLocalClose = () => {
    reset();
    onClose();
  };

  const onDocumentTypeChange = document => {
    const docType = documentTypes.find(doc => doc.id == document);
    setDocumentType(docType);
  };

  useEffect(() => {
    if (!open || !document) return;
    setTitle(document.title);
    const docType = documentTypes.find(doc => doc.type == document.type);
    setDocumentType(docType);
  }, [open]);

  return (
    <Dialog open={open} title={'Edit Document'} onClose={onLocalClose}>
      <div className={styles.container}>
        <div className={styles.fields}>
          <TextField
            fullWidth
            name={'name'}
            label="Document name"
            placeholder="Enter document name"
            onChange={event => setTitle(event.target.value)}
            value={title}
          />
          <ValidationError field={'title'} validationErrors={validationErrors} />
        </div>
        <div className={styles.fields}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Document type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="typeId"
              value={documentType ? documentType.id : -1}
              onChange={event => onDocumentTypeChange(event.target.value)}
            >
              {documentTypes.map(dc => (
                <MenuItem key={dc.id} value={dc.id}>
                  {dc.type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <ValidationError field={'documentType'} validationErrors={validationErrors} />
        </div>
      </div>
      <div className={classNames(styles.padding, styles.buttons)}>
        <div className={styles.right}>
          <DialogButton onClick={onClose}>Cancel</DialogButton>
          <DialogButton className={styles.marginLeft} loading={loading} onClick={onLocalSave}>
            Save
          </DialogButton>
        </div>
      </div>
    </Dialog>
  );
}

export default RenameDocumentDialog;
