import React, { useRef, useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import styles from './EditableParagraph.module.scss';

interface EditableParagraphProps {
  paragraphText?: string;
  setParagraphText: (string) => void;
}

export const EditableParagraph = ({ paragraphText, setParagraphText }: EditableParagraphProps) => {
  const [text, setText] = useState<string>(paragraphText || '');

  const textField = useRef<HTMLInputElement>(null);

  const strip = value => value.replace(/\n/g, '').replace(/\s+/g, ' ');

  const cleanupWhitespace = async textFullOfWhitespace => {
    const cursor = textField && textField?.current?.selectionStart;

    const beforeCursor = textFullOfWhitespace.slice(0, cursor);
    const afterCursor = textFullOfWhitespace.slice(cursor, textFullOfWhitespace.length);

    const filterdBeforeCursor = strip(beforeCursor);
    const filterAfterCursor = strip(afterCursor);
    await setText(filterdBeforeCursor + filterAfterCursor);

    const newCursor = filterdBeforeCursor.length;

    if (textField?.current?.selectionStart) {
      textField.current.selectionStart = newCursor;
      textField.current.selectionEnd = newCursor;
    }
  };

  useEffect(() => {
    const debounce = setTimeout(() => {
      setParagraphText(text);
    }, 250);
    return (): void => clearTimeout(debounce);
  }, [text]);

  if (!paragraphText) {
    return <p className={styles.paragraph}>{null}</p>;
  }

  return (
    <TextField
      className={styles.textArea}
      multiline={true}
      fullWidth
      value={strip(text)}
      inputRef={textField}
      onChange={async e => {
        cleanupWhitespace(e.currentTarget.value);
      }}
      InputProps={{ disableUnderline: true }}
    />
  );
};

export default EditableParagraph;
