import axios from 'axios';
import { GET_REPORT } from 'lib/config';
import { getDefaultHeader } from '../utils';
import { CreateReport, ReportResult, ReportType } from 'types';

export async function fetchTypes(): Promise<Array<ReportType>> {
  const response = await axios(`${GET_REPORT}/types`, {
    method: 'GET',
    headers: getDefaultHeader(),
  });
  return response.data;
}

export async function getReport(data: CreateReport): Promise<ReportResult> {
  const response = await axios.post(`${GET_REPORT}/${data.reportId}`, data, {
    headers: getDefaultHeader(),
  });
  return response.data;
}
