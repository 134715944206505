import React, { ReactElement, useEffect, useState } from 'react';

import styles from './UpdateAppointmentStatusDialog.module.scss';
import Dialog from 'components/Dialog';
import DialogButton from 'components/DialogButton';
import { DialogButtonType } from 'components/DialogButton/DialogButton.view';
import AppointmentStatusSelect from 'components/AppointmentStatusSelect';
import { AppointmentStatus, APPOINTMENT_STATUSES } from 'lib/appointments';
import { AppointmentInfo } from 'api/appointment/appointment.interfaces';
import useAppointmentsApi from 'pages/Appointments/useAppointmentsApi.hook';
import ActionConfirmationDialog from 'components/ActionConfirmationDialog';
import { AlertDialog } from 'components/AlertDialog';
import { AppointmentsApi } from 'api';
import * as logger from 'api/logger';

interface UpdateAppointmentStatusDialogProps {
  open: boolean;
  appointmentId?: number;
  onClose: () => void;
  refresh: () => void;
}

function UpdateAppointmentStatusDialog({
  open,
  appointmentId,
  onClose,
  refresh,
}: UpdateAppointmentStatusDialogProps): ReactElement {
  const [appointmentStatus, setAppointmentStatus] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [appointment, setAppointment] = useState<AppointmentInfo>();

  const { updateAppointment } = useAppointmentsApi();

  const onSave = async () => {
    setLoading(true);
    setOpenConfirmation(false);

    const isPastAppointment = appointment?.start && appointment?.start < new Date();

    try {
      await updateAppointment(
        appointment?.id,
        {
          appointmentStatus: appointmentStatus?.id,
          checkRequired: false,
          isPastAppointment,
          caseId: appointment?.caseId,
        },
        false
      );
      refresh();
      onClose();
    } catch (err) {
      setOpenAlert(true);
      logger.error(err.message);
    }
    setLoading(false);
  };

  const getAppointmentStatus = (apptStatus: string | undefined) => {
    return APPOINTMENT_STATUSES.find(status => status.name == apptStatus);
  };

  const getAppointment = async id => {
    if (!id) return;
    const appt = await AppointmentsApi.getAppointmentsById(id);
    setAppointment(appt);
    setAppointmentStatus(getAppointmentStatus(appt?.status?.status));
  };

  const permanentAction = () => {
    return (
      appointmentStatus.id == AppointmentStatus.Deleted ||
      appointmentStatus.id == AppointmentStatus.Cancelled
    );
  };

  const getAlertTitle = () => {
    if (!appointmentStatus) return '';
    const label = appointmentStatus.id == AppointmentStatus.Deleted ? 'Delete' : 'Cancel';
    return `Change Appointment Status To ${label}`;
  };

  useEffect(() => {
    getAppointment(appointmentId);
  }, [appointmentId]);

  return (
    <Dialog open={open} onClose={onClose} title="Update Appointment Status">
      <div className={styles.container}>
        <div>
          <form className={styles.formContainer}>
            <div className={styles.formColumn}>
              <div className={styles.formFieldContainer}>
                <AppointmentStatusSelect
                  label="Appointment status"
                  name="appointmentStatusId"
                  useFirstWhenEmpty
                  placeholder="Set appointment status"
                  value={appointmentStatus ? appointmentStatus.name : APPOINTMENT_STATUSES[0].name}
                  onChange={({ target }): void =>
                    setAppointmentStatus(getAppointmentStatus(target.value))
                  }
                  fullWidth
                />
              </div>
            </div>
          </form>
          <div className={styles.buttonSection}>
            <DialogButton buttonType={DialogButtonType.NegationLink} onClick={onClose}>
              Cancel
            </DialogButton>
            <DialogButton
              buttonType={DialogButtonType.AffirmativeLink}
              className={styles.btn}
              onClick={() => (permanentAction() ? setOpenConfirmation(true) : onSave())}
              loading={loading}
            >
              Save
            </DialogButton>
          </div>
        </div>
      </div>
      <ActionConfirmationDialog
        open={openConfirmation}
        title={getAlertTitle()}
        description={'Are your sure?  This cannot be undone'}
        onClose={() => setOpenConfirmation(false)}
        onConfirm={onSave}
      />
      <AlertDialog
        open={openAlert}
        title={'Update Failed'}
        message={'Please try again. Contact Ollon if problem persists'}
        onClose={() => setOpenAlert(false)}
      />
    </Dialog>
  );
}

export default UpdateAppointmentStatusDialog;
