import React, { useEffect } from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image as PDFImage,
  Font,
} from '@react-pdf/renderer';
import FullLogoTagline from 'assets/images/full-logo-tagline.png';

import useRequisitionResultPDFFragment from './components/useRequisitionResultPDFFragment';
import formatPhoneInput from 'lib/phoneNumFormatter';
import formatDate, { DateTimeFormat } from 'lib/dateFormatter';
import { green } from '@material-ui/core/colors';

interface RequisitionPdfProps {
  caseId?: number;
  formId?: number;
  patientId?: number;
  signatureUrl?: string;
}

function RequisitionResultPDFFragment({
  caseId,
  formId,
  patientId,
  signatureUrl,
}: RequisitionPdfProps) {
  const { formResults } = useRequisitionResultPDFFragment({
    formId,
    caseId,
  });

  Font.register({
    family: 'Lora',
    fonts: [{ src: 'https://fonts.gstatic.com/s/lora/v17/0QI6MX1D_JOuGQbT0gvTJPa787z5vCJG.ttf' }],
  });
  const styles = StyleSheet.create({
    page: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'white',
    },

    // CVC image and Form title
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },

    headerImageContainer: {
      width: '100%',
      padding: 30,
    },
    headerText: {
      width: '100%',
      padding: 30,
      textAlign: 'center',
      justifyContent: 'center',
    },
    headerTextBig: {
      fontSize: 19,
      fontFamily: 'Helvetica',
      fontWeight: 200,
    },
    headerTextSmall: {
      fontSize: 7.75,
      marginTop: 1,
    },

    // Patient and Date
    formBaseDetails: {
      display: 'flex',
      flexDirection: 'row',
      padding: '20px 50px 0px 50px',
    },
    patientInformation: {
      paddingTop: 1,
      display: 'flex',
      flexDirection: 'row',
    },
    dateInfo: {
      paddingTop: 3,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginVertical: 'auto',
    },
    formDate: {
      width: '100%',
      marginLeft: 'auto',
    },
    patientDetails: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },

    baseDetailTitle: {
      fontSize: 12,
      fontWeight: 'bold',
      paddingTop: 3,
      marginVertical: 'auto',
      width: 40,
    },
    baseDetailInfo: {
      fontSize: 12,
      marginVertical: 'auto',
    },

    padding: {
      paddingTop: 20,
    },

    // Form Details
    infoGroup: {
      width: '100%',
      padding: '5px 20px 0px 20px',
    },

    info: {
      paddingLeft: 10,
      fontSize: 12,
      paddingTop: 2,
      marginVertical: 'auto',
    },

    resultsContainer: {
      width: '100%',
      padding: '10px 50px 0px 50px',
    },
    exam: {
      marginTop: 40,
    },

    examHeader: {
      width: '100%',
    },
    examHeaderText: {
      fontSize: 16,
      textDecoration: 'underline',
    },

    separator: {
      backgroundColor: 'black',
      height: 1,
      marginLeft: 50,
      marginRight: 50,
    },

    signatureImageContainer: {
      width: '30%',
      maxHeight: '150px',
      paddingTop: 20,
      paddingBottom: 20,
      paddingLeft: 50,
    },

    signature: {
      display: 'flex',
      paddingLeft: 50,
      paddingTop: 30,
    },

    spacing: {
      paddingTop: 30,
    },

    secondaryTitle: {
      paddingLeft: 5,
      fontSize: 14,
      marginVertical: 'auto',
    },
    secondaryBody: {},
    listItem: {
      paddingTop: 2,
    },
    singleItem: {
      paddingTop: 2,
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    subCategory: {
      paddingTop: 9,
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      paddingTop: 2,
    },
    half: { width: '50%' },
  });

  const listElements = (title, items, other?) => {
    if (!items || items.length == 0) return <></>;
    return (
      <View>
        <View style={styles.secondaryBody}>
          {items
            .filter(item => {
              return !!item && item.toLowerCase() != 'other';
            })
            .map(item => {
              return (
                <View key={item} style={styles.listItem}>
                  <Text style={styles.info}>{item}</Text>
                </View>
              );
            })}
          {items.includes('Other') && (
            <View style={styles.listItem}>
              <Text style={styles.info}>{other}</Text>
            </View>
          )}
        </View>
      </View>
    );
  };

  const singleResult = (title, value) => {
    if (!value) return <></>;
    return (
      <View>
        <View style={styles.singleItem}>
          <Text style={styles.secondaryTitle}>{title}:</Text>
          <Text style={styles.info}>{value}</Text>
        </View>
      </View>
    );
  };

  const baseDetail = (title, value) => {
    if (!value) return <></>;
    return (
      <View style={styles.patientInformation}>
        <Text style={styles.baseDetailTitle}>{title}:</Text>
        <Text style={styles.info}>{value}</Text>
      </View>
    );
  };

  // Create Document Component
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View style={styles.headerImageContainer}>
            <PDFImage src={FullLogoTagline}></PDFImage>
          </View>
          <View style={styles.headerText}>
            <Text style={styles.headerTextBig}>Requisition Form</Text>
            <Text style={styles.headerTextSmall}>https://www.canadaveinclinics.ca/</Text>
            <Text style={styles.headerTextSmall}>1-888-785-4080</Text>
          </View>
        </View>

        <View style={styles.formBaseDetails}>
          <View style={styles.patientDetails}>
            {baseDetail('Name', formResults?.patient?.fullName)}
            {baseDetail(
              'DOB',
              formatDate(DateTimeFormat.DayMonthYear, formResults?.patient?.dateOfBirth)
            )}
            {baseDetail('HC', formResults?.patient?.hcn)}
            {baseDetail('Phone', formatPhoneInput(formResults?.patient?.phone))}
            {baseDetail('Cell', formatPhoneInput(formResults?.patient?.cellPhone))}
            {baseDetail('Home', formatPhoneInput(formResults?.patient?.homePhone))}
          </View>

          <View style={styles.formDate}>
            <View style={styles.dateInfo}>
              <Text style={styles.baseDetailTitle}>{'Date'}:</Text>
              <Text style={styles.info}>{formResults?.date}</Text>
            </View>
          </View>
        </View>

        <View style={styles.resultsContainer}>
          {baseDetail('Doctor', formResults?.doctor)}
          {baseDetail('Billing', formResults?.billing)}
          {baseDetail('Fax', formatPhoneInput(formResults?.fax))}

          <View style={styles.padding}>
            <View style={styles.row}>
              <View style={styles.half}>
                {singleResult(
                  'Urgent Booking',
                  formResults?.urgentBooking ? formResults?.urgentBooking.toUpperCase() : ''
                )}
              </View>
              {!!formResults?.clinicalInformation && (
                <View style={styles.half}>
                  <View>
                    <Text style={styles.secondaryTitle}>Clinical Information</Text>
                  </View>

                  <Text style={styles.info}>{formResults?.clinicalInformation}</Text>
                </View>
              )}
            </View>
          </View>

          {formResults?.typeOfExam.includes('Venous Duplex LE') && (
            <View style={styles.exam}>
              <View style={styles.examHeader}>
                <Text style={styles.examHeaderText}>Venous Duplex Exam Lower Extremities</Text>
              </View>
              <View style={styles.row}>
                <View style={styles.half}></View>
              </View>
              <View style={styles.row}>
                {formResults?.reasonForExamLe.includes('Superficial Veins') &&
                  formResults?.venousDuplexExamLESuperficial &&
                  formResults?.venousDuplexExamLESuperficial.length > 0 && (
                    <View style={styles.half}>
                      <View style={styles.subCategory}>
                        <Text style={styles.secondaryTitle}>{'Superficial'}</Text>
                        <View style={styles.infoGroup}>
                          {singleResult('Location', formResults?.legLESuperficial)}
                        </View>
                        <View style={styles.infoGroup}>
                          {formResults.venousDuplexExamLESuperficial.includes('NP-Ultrasound LE') &&
                            singleResult(
                              'NP-Ultrasound Lower Extremities',
                              formResults.venousDuplexExamLEUltrasound
                            )}
                          {formResults.venousDuplexExamLESuperficial.includes('F/up 3m') &&
                            singleResult('F/up 3m', formResults.venousDuplexExamLEFollowUp3m)}
                          {formResults.venousDuplexExamLESuperficial.includes('F/up 6m') &&
                            singleResult('F/up 6m', formResults.venousDuplexExamLEFollowUp6m)}
                          {formResults.venousDuplexExamLESuperficial.includes('F/up 12m') &&
                            singleResult('F/up 12m', formResults.venousDuplexExamLEFollowUp12m)}
                          {formResults.venousDuplexExamLESuperficial.includes(
                            'F/up >12m (Specify)'
                          ) && (
                            <>
                              {singleResult(
                                'F/up >12m',
                                formResults.venousDuplexExamLEFollowUpGreaterThan12m
                              )}
                              {singleResult(
                                'F/up >12m Specify',
                                formResults.venousDuplexExamLEFollowUpSpecify
                              )}
                            </>
                          )}
                          {formResults.venousDuplexExamLESuperficial.includes('Post SVT') &&
                            singleResult('Post SVT', formResults.venousDuplexExamLEPostSVT)}
                        </View>
                      </View>
                    </View>
                  )}

                {formResults?.reasonForExamLe.includes('Deep Veins') &&
                  formResults?.venousDuplexExamLEDeep &&
                  formResults.venousDuplexExamLEDeep.length > 0 && (
                    <View style={styles.subCategory}>
                      <Text style={styles.secondaryTitle}>{'Deep'}</Text>
                      <View style={styles.infoGroup}>
                        {singleResult('Location', formResults?.legLEDeep)}
                      </View>
                      <View style={styles.infoGroup}>
                        {formResults.venousDuplexExamLEDeep.includes('Pre-Proc Ablation') &&
                          singleResult(
                            'Pre-Proc Ablation',
                            formResults?.venousDuplexExamLEPreProcAblation
                          )}
                        {formResults.venousDuplexExamLEDeep.includes('Post-Proc Ablation') &&
                          singleResult(
                            'Post-Proc Ablation',
                            formResults?.venousDuplexExamLEPostProcAblation
                          )}
                        {formResults.venousDuplexExamLEDeep.includes('Post DVT') &&
                          singleResult('Post DVT', formResults?.venousDuplexExamLEPostDVTLower)}
                      </View>
                    </View>
                  )}
              </View>
              <View style={styles.subCategory}>
                <Text style={styles.secondaryTitle}>{'Indication'}</Text>
                <View style={styles.infoGroup}>
                  {listElements(
                    'Indication',
                    formResults?.venousDuplexExamLEIndication,
                    formResults?.venousDuplexExamLEOtherIndication
                  )}
                </View>
              </View>
            </View>
          )}

          {formResults?.typeOfExam.includes('Venous Duplex UE') && (
            <View style={styles.exam}>
              <View style={styles.examHeader}>
                <Text style={styles.examHeaderText}>Venous Duplex Exam Upper Extremities</Text>
              </View>
              <View style={styles.row}>
                {formResults?.reasonForExamUe.includes('Superficial Veins') &&
                  formResults.venousDuplexExamUESuperficial &&
                  formResults.venousDuplexExamUESuperficial.length > 0 && (
                    <View style={styles.half}>
                      <View style={styles.subCategory}>
                        <Text style={styles.secondaryTitle}>{'Superficial'}</Text>
                        <View style={styles.infoGroup}>
                          {singleResult('Location', formResults?.armUESuperficial)}
                        </View>
                        <View style={styles.infoGroup}>
                          {formResults.venousDuplexExamUESuperficial.includes('NP-Ultrasound UE') &&
                            singleResult(
                              'NP-Ultrasound Upper Extremities',
                              formResults.venousDuplexExamUEUltrasound
                            )}
                          {formResults.venousDuplexExamUESuperficial.includes('F/up 3m') &&
                            singleResult('F/up 3m', formResults.venousDuplexExamUEFollowUp3m)}
                          {formResults.venousDuplexExamUESuperficial.includes('F/up 6m') &&
                            singleResult('F/up 6m', formResults.venousDuplexExamUEFollowUp6m)}
                          {formResults.venousDuplexExamUESuperficial.includes('F/up 12m') &&
                            singleResult('F/up 12m', formResults.venousDuplexExamUEFollowUp12m)}
                          {formResults.venousDuplexExamUESuperficial.includes(
                            'F/up >12m (Specify)'
                          ) && (
                            <>
                              {singleResult(
                                'F/up >12m',
                                formResults.venousDuplexExamUEFollowUpGreaterThan12m
                              )}
                              {singleResult(
                                'F/up >12m Specify',
                                formResults.venousDuplexExamUEFollowUpSpecify
                              )}
                            </>
                          )}
                          {formResults.venousDuplexExamUESuperficial.includes('Post SVT') &&
                            singleResult('Post SVT', formResults.venousDuplexExamUEPostSVT)}
                        </View>
                      </View>
                    </View>
                  )}

                {formResults?.reasonForExamUe.includes('Deep Veins') &&
                  formResults?.venousDuplexExamUEDeep &&
                  formResults.venousDuplexExamUEDeep.length > 0 && (
                    <View style={styles.subCategory}>
                      <Text style={styles.secondaryTitle}>{'Deep'}</Text>
                      <View style={styles.infoGroup}>
                        {singleResult('Location', formResults?.armUEDeep)}
                      </View>
                      <View style={styles.infoGroup}>
                        {formResults.venousDuplexExamUEDeep.includes('Pre-Proc Ablation') &&
                          singleResult(
                            'Pre-Proc Ablation',
                            formResults?.venousDuplexExamUEPreProcAblation
                          )}
                        {formResults.venousDuplexExamUEDeep.includes('Post-Proc Ablation') &&
                          singleResult(
                            'Post-Proc Ablation',
                            formResults?.venousDuplexExamUEPostProcAblation
                          )}
                        {formResults.venousDuplexExamUEDeep.includes('Post DVT') &&
                          singleResult('Post DVT', formResults?.venousDuplexExamUEPostDVTupper)}
                      </View>
                    </View>
                  )}
              </View>
              <View style={styles.subCategory}>
                <Text style={styles.secondaryTitle}>{'Indication'}</Text>
                <View style={styles.infoGroup}>
                  {listElements(
                    'Indication',
                    formResults?.venousDuplexExamUEIndication,
                    formResults?.venousDuplexExamUEOtherIndication
                  )}
                </View>
              </View>
            </View>
          )}

          {formResults?.typeOfExam.includes('Arterial Duplex LE') && (
            <View style={styles.exam}>
              <View style={styles.examHeader}>
                <Text style={styles.examHeaderText}>Arterial Duplex Exam Lower Extremities</Text>
              </View>
              <View style={styles.row}>
                <View style={styles.half}>
                  <View style={styles.infoGroup}>
                    {singleResult('Lower Extremities', formResults?.lowerExtremities)}
                  </View>
                </View>
                <View style={styles.subCategory}>
                  <Text style={styles.secondaryTitle}>{'Indication'}</Text>
                  <View style={styles.infoGroup}>
                    {listElements(
                      'Indication',
                      formResults?.arterialDuplexExamLEIndication,
                      formResults?.arterialDuplexExamLEOtherIndication
                    )}
                  </View>
                </View>
              </View>
            </View>
          )}

          {formResults?.typeOfExam.includes('Arterial Duplex UE') && (
            <View style={styles.exam}>
              <View style={styles.examHeader}>
                <Text style={styles.examHeaderText}>Arterial Duplex Exam Upper Extremities</Text>
              </View>

              <View style={styles.row}>
                <View style={styles.half}>
                  <View style={styles.infoGroup}>
                    {singleResult('Upper Extremities', formResults?.upperExtremities)}
                  </View>
                </View>
                <View style={styles.subCategory}>
                  <Text style={styles.secondaryTitle}>{'Indication'}</Text>
                  <View style={styles.infoGroup}>
                    {listElements(
                      'Indication',
                      formResults?.arterialDuplexExamUEIndication,
                      formResults?.arterialDuplexExamUEOtherIndication
                    )}
                  </View>
                </View>
              </View>
            </View>
          )}

          {formResults?.typeOfExam.includes('Carotid Duplex') && (
            <View style={styles.exam}>
              <View style={styles.examHeader}>
                <Text style={styles.examHeaderText}>Carotid Duplex</Text>
              </View>
              <View style={styles.row}>
                <View style={styles.subCategory}>
                  <Text style={styles.secondaryTitle}>{'Risk Factors'}</Text>
                  <View style={styles.infoGroup}>
                    {listElements(
                      'Risk Factors',
                      formResults?.carotidDuplexRiskFactors,
                      formResults?.carotidDuplexOtherRiskFactor
                    )}
                  </View>
                </View>
                <View style={styles.subCategory}>
                  <Text style={styles.secondaryTitle}>{'Indication'}</Text>
                  <View style={styles.infoGroup}>
                    {listElements(
                      'Indication',
                      formResults?.carotidDuplexIndication,
                      formResults?.carotidDuplexOtherIndication
                    )}
                  </View>
                </View>
              </View>
            </View>
          )}

          {formResults?.typeOfExam.includes('Aortic Screening') && (
            <View style={styles.exam}>
              <View style={styles.examHeader}>
                <Text style={styles.examHeaderText}>Aortic Screening</Text>
              </View>
              <View style={styles.row}>
                <View style={styles.subCategory}>
                  <Text style={styles.secondaryTitle}>{'Risk Factors'}</Text>
                  <View style={styles.infoGroup}>
                    {listElements(
                      'Risk Factors',
                      formResults?.aorticScreeningExamRiskFactors,
                      formResults?.aorticScreeningExamOtherRiskFactor
                    )}
                  </View>
                </View>
                <View style={styles.subCategory}>
                  <Text style={styles.secondaryTitle}>{'Indication'}</Text>
                  <View style={styles.infoGroup}>
                    {listElements(
                      'Indication',
                      formResults?.aorticScreeningExamIndication,
                      formResults?.aorticScreeningExamOtherIndication
                    )}
                  </View>
                </View>
              </View>
            </View>
          )}

          {formResults?.typeOfExam.includes('Other (Specify)') && (
            <View style={styles.exam}>
              <View style={styles.examHeader}>
                <Text style={styles.examHeaderText}>{'Other Exam Type (Specifiy)'}</Text>
              </View>
              {formResults?.otherTypeOfExamSpecify && (
                <View style={styles.infoGroup}>
                  {singleResult('Other', formResults.otherTypeOfExamSpecify)}
                </View>
              )}
            </View>
          )}
        </View>

        <View style={styles.spacing}>
          <View style={styles.separator} />
          <View style={styles.signature}>
            <Text style={styles.secondaryTitle}>{'Signed'}:</Text>
          </View>
          {!!signatureUrl && (
            <View style={styles.signatureImageContainer}>
              <PDFImage src={signatureUrl}></PDFImage>
            </View>
          )}
        </View>
      </Page>
    </Document>
  );
}

export default RequisitionResultPDFFragment;
