import moment from 'moment';

export enum DateTimeFormat {
  DateAndTime = 'dateAndTime',
  DateAndTimeWithoutMeridies = 'DateAndTimeWithoutMeridies',
  DateAndTimeWithoutMeridies12h = 'DateAndTimeWithoutMeridies12h',
  DateOnly = 'dateOnly',
  TimeOnly = 'timeOnly',
  TimeOnlyWithoutMeridies = 'timeOnlyWithoutMeridies',
  DateWithFullMonthName = 'DateWithFullMonthName',
  DateWithFullMonthNameAndTime = 'DateWithFullMonthNameAndTime',
  DateWithFullMonthNameAndTime12h = 'DateWithFullMonthNameAndTime12',
  APIDateFormat = 'APIDateFormat',
  DayOnly = 'DayOnly',
  DayMonthYear = 'DayMonthYear',
  DayFullMonthYear = 'DayFullMonthYear',
  DayMonthYearWithTime = ' DayMonthYearWithTime',
  MonthDayYear = 'MonthDayYear',
  DateMonthYear = 'DateMonthYear',
}

export const DateTimeFormatString = {
  DateAndTime: 'YYYY/MMM/D HH:mm a',
  DateAndTimeWithoutMeridies: 'DD/MM/YYYY HH:mm',
  DateAndTimeWithoutMeridies12h: 'DD/MM/YYYY h:mm a',
  DateWithFullMonthNameAndTime12h: 'YYYY MMMM DD h:mm a',
  DateOnly: 'YYYY/MMM/D',
  TimeOnly: 'hh:mm a',
  TimeOnlyWithoutZero: 'h:mm a',
  TimeOnlyCompact: 'h:mmA',
  TimeOnlyWithoutMeridies: 'HH:mm',
  DateWithFullMonthName: 'YYYY MMMM DD',
  DateWithFullMonthNameAndTime: 'YYYY MMMM DD HH:mm',
  APIDateFormat: 'YYYY-MM-DD',
  APIDateAndTimeFormat: 'YYYY-MM-DD HH:mm',
  DayOnly: 'dddd',
  DayMonthYear: 'DD MMM YYYY',
  DayMonthYearWithTime: 'DD MMM YYYY h:mm a',
  DayFullMonthYear: 'D MMMM, YYYY',
  DateMonthYear: 'DD/MMM/YYYY',
  MonthDayYear: 'LL',
};

export const FOREVER_YEAR = 9999;

export const formatDate = (format: DateTimeFormat, date) => {
  if (!date) return '';
  switch (format) {
    case DateTimeFormat.DateAndTime:
      return moment(date).format(DateTimeFormatString.DateAndTime);
    case DateTimeFormat.DateAndTimeWithoutMeridies:
      return moment(date).format(DateTimeFormatString.DateAndTimeWithoutMeridies);
    case DateTimeFormat.DateAndTimeWithoutMeridies12h:
      return moment(date).format(DateTimeFormatString.DateAndTimeWithoutMeridies12h);
    case DateTimeFormat.DateOnly:
      return moment(date).format(DateTimeFormatString.DateOnly);
    case DateTimeFormat.TimeOnly:
      return moment(date).format(DateTimeFormatString.TimeOnly);
    case DateTimeFormat.TimeOnlyWithoutMeridies:
      return moment(date).format(DateTimeFormatString.TimeOnlyWithoutMeridies);
    case DateTimeFormat.DateWithFullMonthName:
      return moment(date).format(DateTimeFormatString.DateWithFullMonthName);
    case DateTimeFormat.DateWithFullMonthNameAndTime:
      return moment(date).format(DateTimeFormatString.DateWithFullMonthNameAndTime);
    case DateTimeFormat.DateWithFullMonthNameAndTime12h:
      return moment(date).format(DateTimeFormatString.DateWithFullMonthNameAndTime12h);
    case DateTimeFormat.APIDateFormat:
      return moment(date).format(DateTimeFormatString.APIDateFormat);
    case DateTimeFormat.DayOnly:
      return moment(date).format(DateTimeFormatString.DayOnly);
    case DateTimeFormat.DayMonthYear:
      return moment(date).format(DateTimeFormatString.DayMonthYear);
    case DateTimeFormat.DayFullMonthYear:
      return moment(date).format(DateTimeFormatString.DayFullMonthYear);
    case DateTimeFormat.DateMonthYear:
      return moment(date).format(DateTimeFormatString.DateMonthYear);
    case DateTimeFormat.MonthDayYear:
      return moment(date).format(DateTimeFormatString.MonthDayYear);
    case DateTimeFormat.DayMonthYearWithTime:
      return moment(date).format(DateTimeFormatString.DayMonthYearWithTime);
  }
};

export const YEAR = 'year';
export const MONTH = 'month';
export const DAY = 'day';

const TIME_FORMAT = 'hh:mmA';

export const formatTimePeriod = (startTime?: Date, endTime?: Date): string => {
  return startTime && endTime
    ? `${moment(startTime).format(TIME_FORMAT)}-${moment(endTime).format(TIME_FORMAT)}`
    : '';
};

export const today = moment().format(DateTimeFormatString.APIDateFormat);

export const getYears = (minYear?: number, maxYear?: number) => {
  const min = minYear ? minYear : moment().get('year') - 10;
  const max = maxYear ? maxYear : moment().get('year') + 5;
  const years: Array<number> = [];
  for (let i = min; i <= max; i++) {
    years.push(i);
  }
  return years;
};

export default formatDate;
