import React, { ReactElement } from 'react';

import Dialog from 'components/Dialog';
import styles from './DeleteConfirmationDialogStyles.module.scss';
import Button from '@material-ui/core/Button';

interface Patient {
  id: number;
  name: string;
  dateOfBirth: string;
  phone: string;
  email: string;
  healthcard: string;
}

interface DeleteConfirmationDialogProps {
  patient?: Patient | null;
  loading?: boolean;
  onClose: () => void;
  onConfirm: (staffToDelete) => void;
}

function DeleteConfirmationDialog({
  patient,
  loading,
  onClose,
  onConfirm,
}: DeleteConfirmationDialogProps): ReactElement {
  return (
    <Dialog open={!!patient} onClose={onClose} title="Delete Patient">
      <div className={styles.container}>
        <div className={styles.text}>Are you sure you want to delete {patient?.name}</div>
        <div className={styles.buttons}>
          <Button onClick={onClose}>No</Button>
          <Button onClick={() => onConfirm(patient)} disabled={loading}>
            Yes
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default DeleteConfirmationDialog;
