export const handleAPIErrors = (e, defaultMessage?: string): string => {
  const id = e?.response?.data?.id;

  switch (id) {
    case 'user.create.existingUserEmail':
      return 'A user with that email already exists.';
    case 'user.create.invalidEmail':
      return 'Email address invalid - please check before saving';
    case 'user.create.genericError':
      return 'There was an error, please check the fields you entered.';
    default:
      return e?.response?.data?.msg || e?.response?.data?.message || e.message || defaultMessage;
  }
};
