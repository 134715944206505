import React, { ReactElement, memo } from 'react';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';

import Dialog from 'components/Dialog';
import DialogButton from 'components/DialogButton';
import Select from 'components/Select';
import Checkbox from 'components/Checkbox';

import styles from './EditLocationDialog.module.scss';
import { NewStaffDialogValidationError } from 'types';
import { ValidationError } from 'components/Forms/ValidationError';
import formatPhoneInput, { MAX_PHONE_CHARACTER_LENGTH } from 'lib/phoneNumFormatter';
import { ScheduleTypesStrings } from 'lib/scheduleTypes';
import { DialogButtonType } from 'components/DialogButton/DialogButton.view';
import { formatEmail } from 'lib/user';
import { CanadaProvinceCodes } from 'lib/addresses';

interface UserType {
  id: number;
  type: string;
}

interface Location {
  id: string | number;
  name: string;
}

const timezones = ['America/Toronto', 'America/Halifax'];

interface EditLocationDialogProps {
  open?: boolean;
  loading: boolean;
  sortOrder?: string;
  locationName?: string;
  line1?: string;
  line2?: string;
  city?: string;
  province?: string;
  country?: string;
  postalCode?: string;
  email?: string;
  phone?: string;
  generalChecked?: boolean;
  consultationFollowUpChecked?: boolean;
  procedureChecked?: boolean;
  ultraSoundChecked?: boolean;
  validationErrors?: Array<NewStaffDialogValidationError>;
  errorMessage?: string;
  dialogType?: string;
  timezone?: string;
  isValidUser?: boolean;
  onUpdate: () => void;
  onSave: () => void;
  onClose: () => void;
  onDelete: () => void;
  onSortOrderChange: (value) => void;
  onLocationNameChange: (value) => void;
  onEmailChange: (value) => void;
  onPhoneChange: (value) => void;
  onLine1Change: (value) => void;
  onLine2Change: (value) => void;
  onCityChange: (value) => void;
  onProvinceChange: (value) => void;
  onCountryChange: (value) => void;
  onPostalCodeChange: (value) => void;
  onTimeZoneChange: (value) => void;
  onGeneralChecked: (checked) => void;
  onConsultationFollowUpChecked: (checked) => void;
  onProcedureChecked: (checked) => void;
  onUltraSoundChecked: (checked) => void;
}

function EditLocationDialog({
  open,
  loading = false,
  sortOrder = '',
  locationName = '',
  email = '',
  phone = '',
  line1 = '',
  line2 = '',
  city = '',
  province = '',
  country = '',
  postalCode = '',
  generalChecked = false,
  consultationFollowUpChecked = false,
  procedureChecked = false,
  ultraSoundChecked = false,
  validationErrors = [],
  errorMessage = '',
  dialogType = '',
  timezone = '',
  isValidUser,
  onSave,
  onUpdate,
  onDelete,
  onClose,
  onSortOrderChange,
  onEmailChange,
  onPhoneChange,
  onLocationNameChange,
  onLine1Change,
  onLine2Change,
  onCityChange,
  onProvinceChange,
  onCountryChange,
  onPostalCodeChange,
  onTimeZoneChange,
  onGeneralChecked,
  onConsultationFollowUpChecked,
  onProcedureChecked,
  onUltraSoundChecked,
}: EditLocationDialogProps): ReactElement {
  return (
    <Dialog
      open={open}
      title={dialogType === 'new' ? 'New Location' : 'Edit Location'}
      onClose={onClose}
    >
      <div className={classNames(styles.dialogBody, styles.withPadding)}>
        <div className={styles.fields}>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <div className={styles.orderNumber}>
                <TextField
                  label="Order Number"
                  onChange={(event): void => onSortOrderChange(event.target.value)}
                  value={sortOrder}
                />
                <ValidationError field={'sortOrder'} validationErrors={validationErrors} />
              </div>
              <div className={styles.withPadding}>
                <TextField
                  label="Location Name"
                  onChange={(event): void => onLocationNameChange(event.target.value)}
                  fullWidth
                  value={locationName}
                />
                <ValidationError field={'locationName'} validationErrors={validationErrors} />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={styles.withPadding}>
                <TextField
                  label="Email"
                  onChange={(event): void => onEmailChange(formatEmail(event.target.value))}
                  fullWidth
                  value={email}
                />
                <ValidationError field={'email'} validationErrors={validationErrors} />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={styles.withPadding}>
                <TextField
                  label="Phone"
                  onChange={(event): void => onPhoneChange(event.target.value)}
                  fullWidth
                  value={formatPhoneInput(phone)}
                  inputProps={{
                    maxLength: MAX_PHONE_CHARACTER_LENGTH,
                  }}
                />
                <ValidationError field={'phone'} validationErrors={validationErrors} />
              </div>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <div className={styles.withPadding}>
                <TextField
                  label="Address Line 1"
                  onChange={(event): void => onLine1Change(event.target.value)}
                  fullWidth
                  value={line1}
                />
                <ValidationError field={'line1'} validationErrors={validationErrors} />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={styles.withPadding}>
                <TextField
                  label="Address Line 2"
                  onChange={(event): void => onLine2Change(event.target.value)}
                  fullWidth
                  value={line2}
                />
                <ValidationError field={'line2'} validationErrors={validationErrors} />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={styles.withPadding}>
                <TextField
                  label="City"
                  onChange={(event): void => onCityChange(event.target.value)}
                  fullWidth
                  value={city}
                />
                <ValidationError field={'city'} validationErrors={validationErrors} />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={styles.withPadding}>
                <Select
                  fullWidth
                  label="Province"
                  renderValue={(value): ReactElement => (
                    <div className={styles.selectItem}>{value}</div>
                  )}
                  value={province}
                  onChange={(event): void => onProvinceChange(event.target.value)}
                >
                  {Object.values(CanadaProvinceCodes).map(item => (
                    <MenuItem key={item} value={item} className={styles.selectItem}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
                <ValidationError field={'province'} validationErrors={validationErrors} />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={styles.withPadding}>
                <TextField
                  label="Country"
                  onChange={(event): void => onCountryChange(event.target.value)}
                  fullWidth
                  value={country}
                />
                <ValidationError field={'country'} validationErrors={validationErrors} />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={styles.withPadding}>
                <TextField
                  label="Postal Code"
                  onChange={(event): void => onPostalCodeChange(event.target.value)}
                  fullWidth
                  value={postalCode}
                />
                <ValidationError field={'postalCode'} validationErrors={validationErrors} />
              </div>
            </Grid>

            <Grid item xs={12} sm={6}>
              <div className={styles.withPadding}>
                <Select
                  fullWidth
                  label="TimeZone"
                  name={'timezone'}
                  renderValue={(value): ReactElement => (
                    <div className={styles.selectItem}>{value}</div>
                  )}
                  value={timezone}
                  onChange={(event): void => onTimeZoneChange(event.target.value)}
                >
                  {timezones.map(item => (
                    <MenuItem key={item} value={item} className={styles.selectItem}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
                <ValidationError field={'timezone'} validationErrors={validationErrors} />
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={styles.scheduleType}>
              <Checkbox
                title={ScheduleTypesStrings.General}
                onChange={(event): void => onGeneralChecked(event.target.checked)}
                checked={generalChecked}
              />
              <Checkbox
                title={ScheduleTypesStrings.ConsultationAndfollowUp}
                onChange={(event): void => onConsultationFollowUpChecked(event.target.checked)}
                checked={consultationFollowUpChecked}
              />
              <Checkbox
                title={ScheduleTypesStrings.Procedure}
                onChange={(event): void => onProcedureChecked(event.target.checked)}
                checked={procedureChecked}
              />
              <Checkbox
                title={ScheduleTypesStrings.UltraSound}
                onChange={(event): void => onUltraSoundChecked(event.target.checked)}
                checked={ultraSoundChecked}
              />
            </div>
            <ValidationError field={'scheduleTypes'} validationErrors={validationErrors} />
          </Grid>
        </div>

        <div className={styles.buttonSection}>
          <DialogButton buttonType={DialogButtonType.NegationLink} onClick={onClose}>
            <b>Cancel</b>
          </DialogButton>
          {!loading ? (
            dialogType === 'new' ? (
              <DialogButton buttonType={DialogButtonType.AffirmativeLink} onClick={onSave}>
                <b>Save</b>
              </DialogButton>
            ) : (
              <React.Fragment>
                {isValidUser && (
                  <button onClick={onDelete} className={styles.deleteButton}>
                    <b>Delete Location</b>
                  </button>
                )}
                <DialogButton buttonType={DialogButtonType.AffirmativeLink} onClick={onUpdate}>
                  <b>Update</b>
                </DialogButton>
              </React.Fragment>
            )
          ) : (
            <CircularProgress size={16} style={{ padding: '10px 20px' }} />
          )}
        </div>
        <div className={classNames(styles.textError)}>{errorMessage}</div>
      </div>
    </Dialog>
  );
}

export default memo(EditLocationDialog);
