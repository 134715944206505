import { useState, useEffect } from 'react';
import { Invoice } from './components/InvoiceRow/InvoiceRow.view';
import { StyleSheet } from '@react-pdf/renderer';
import { getPaymentMethods, getReceiptTypes } from 'api/receipts';
import { PaymentMethod, ReceiptType } from 'types';

function useReceiptPDFActions() {
  const [receiptURL, setReceiptURL] = useState<string>('');
  const [paymentMethods, setPaymentMethods] = useState<Array<PaymentMethod>>([]);
  const [receiptTypes, setReceiptTypes] = useState<Array<ReceiptType>>([]);

  async function fetchReceiptTypes(): Promise<void> {
    const response = await getReceiptTypes();
    setReceiptTypes(response);
  }

  async function fetchPaymentMethods(): Promise<void> {
    const response = await getPaymentMethods();
    setPaymentMethods(response);
  }

  const stylesPdf = StyleSheet.create({
    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    title: {
      fontSize: 24,
      textAlign: 'center',
    },
    author: {
      fontSize: 12,
      textAlign: 'center',
      marginBottom: 40,
    },
    subtitle: {
      fontSize: 18,
      margin: 12,
    },
    text: {
      margin: 12,
      fontSize: 14,
      textAlign: 'justify',
    },
    image: {
      marginVertical: 15,
      marginHorizontal: 100,
    },
    header: {
      fontSize: 12,
      marginBottom: 20,
      textAlign: 'center',
      color: 'grey',
    },
    pageNumber: {
      position: 'absolute',
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey',
    },
  });

  const [invoiceRows, setInvoiceRows] = useState<Array<Invoice>>([
    /* Preset invoicesRows First Row to have the dropdown always visible.  
       If not used it will not be included in PDF */
    {
      id: undefined,
      invoiceId: 0,
      invoiceLine: '',
      invoiceAmount: 0,
    },
  ]);

  const [invoiceTotal, setInvoiceTotal] = useState<number>(0);

  const _getSum = (accumulator, a) => {
    return accumulator + a;
  };

  const getInvoiceAmount = (rows: Array<Invoice>) => {
    const amounts = rows.map(a => a.invoiceAmount);
    const total = amounts.reduce(_getSum, 0);
    setInvoiceTotal(total);
  };

  async function previewReceiptDocument(): Promise<void> {
    window.open(receiptURL, '_blank');
  }

  useEffect(() => {
    fetchPaymentMethods().catch();
    fetchReceiptTypes().catch();
  }, []);

  return {
    getInvoiceAmount,
    invoiceTotal,
    invoiceRows,
    setInvoiceRows,
    previewReceiptDocument,
    receiptTypes,
    paymentMethods,
    stylesPdf,
    receiptURL,
    setReceiptURL,
  };
}

export default useReceiptPDFActions;
