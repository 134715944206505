import axios from 'axios';

import { GET_SCHEDULE_EXCEPTION } from 'lib/config';

import { getDefaultHeader } from '../utils';

import { ScheduleExceptionResponse } from './scheduleException.interfaces';

export async function getScheduleExceptions(): Promise<Array<ScheduleExceptionResponse>> {
  const response = await axios(GET_SCHEDULE_EXCEPTION, {
    method: 'GET',
    headers: getDefaultHeader(),
  });

  return response.data;
}

export async function getLocationExceptions(locationId: number, date: string): Promise<any> {
  const response = await axios(`${GET_SCHEDULE_EXCEPTION}/${locationId}`, {
    method: 'GET',
    params: { date },
    headers: getDefaultHeader(),
  });
  return response.data;
}
