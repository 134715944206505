import React, { ReactElement } from 'react';
import FormControl from '@material-ui/core/FormControl';

import SearchIcon from '@material-ui/icons/Search';
import Input, { InputProps } from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';

import styles from './InputSearch.module.scss';

function InputSearch(props: InputProps): ReactElement {
  return (
    <FormControl className={styles.container}>
      <Input
        {...props}
        id="standard-adornment-password"
        type={'text'}
        placeholder={'Search'}
        endAdornment={
          <InputAdornment position="end">
            <IconButton type="submit" aria-label="search">
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
}

export default InputSearch;
