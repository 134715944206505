import { useState, useEffect } from 'react';

import { getCaseByUserId } from 'api/case';
import { getAppointmentsByCaseId } from 'api/appointment';
import { AppointmentInfo } from 'api/appointment/appointment.interfaces';
import * as logger from 'api/logger';

export interface AppointmentState {
  loading: boolean;
  appointments: Array<AppointmentInfo>;
  changeOrder(): void;
  refresh(): void;
}

function useNextAppointment(patientId, deleted = false): AppointmentState {
  const [loading, setLoading] = useState(true);
  const [appointments, setAppointments] = useState<Array<AppointmentInfo>>([]);

  function changeOrder(): void {
    setAppointments(appointments.reverse());
  }

  async function fetchAppointment(id: number): Promise<void> {
    setLoading(true);
    try {
      const cases = await getCaseByUserId(id);
      if (cases.length) {
        const appts = await getAppointmentsByCaseId(cases[0].id, deleted);
        setAppointments(appts);
      }
    } catch (e) {
      logger.error(e.message);
    }
    setLoading(false);
  }

  const refresh = async () => {
    if (!patientId) return;
    await fetchAppointment(patientId).catch();
  };

  useEffect(() => {
    fetchAppointment(patientId).catch();
  }, [patientId]);

  return {
    loading,
    appointments,
    changeOrder,
    refresh,
  };
}

export default useNextAppointment;
