import React, { ReactElement, useEffect } from 'react';
import classNames from 'classnames';
import MenuItem from '@material-ui/core/MenuItem';

import Dialog from 'components/Dialog';
import DialogButton from 'components/DialogButton';
import Select from 'pages/Appointments/components/Select';

import useStaffApi from './useStaffApi.hook';

import styles from './SendNoteDialogStyles.module.scss';

interface SendNoteDialogProps {
  open: boolean;
  loading: boolean;
  name: string;
  value: Array<number>;
  onSave?(): void;
  onClose?(): void;
  onChange(value): void;
  [rest: string]: any;
}

function SendNoteDialog({
  loading,
  open,
  name,
  onSave,
  onClose,
  value,
  onChange,
  ...rest
}: SendNoteDialogProps): ReactElement {
  const { staff, getStaff } = useStaffApi();

  useEffect(() => {
    getStaff();
  }, []);

  return (
    <Dialog title="Send Note" open={open} onClose={onClose}>
      <div className={styles.margin}>
        <Select
          {...rest}
          name={name}
          label="Choose Staff"
          multiple
          value={value}
          onChange={event => onChange(event.target.value)}
          fullWidth
        >
          {staff.map(user => (
            <MenuItem key={user.staffInfo?.id} value={user.staffInfo?.id}>
              {user.fullName}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div className={classNames(styles.margin, styles.buttons)}>
        <DialogButton onClick={onClose}>Cancel</DialogButton>
        <DialogButton className={styles.marginLeft} loading={loading} onClick={onSave}>
          Send
        </DialogButton>
      </div>
    </Dialog>
  );
}

export default SendNoteDialog;
