import React, { ReactElement, useEffect, useState } from 'react';
import Dialog from 'components/Dialog';
import styles from './FormDocumentStyles.module.scss';
import { convertEditableMimeTypeToFileExtension, MIME_TYPE_PDF } from 'lib/document';

import useFormDocumentsApi from './useFormDocumentApi.hook';

import FormDocumentView from './FormDocument.view';

import moment from 'moment';
import { DateTimeFormatString } from 'lib/dateFormatter';
import { AlertDialog } from 'components/AlertDialog';
import { AlertDialog as SuccessAlertDialog } from 'components/AlertDialog';
import { handleAPIErrors } from 'lib/handleAPIErrors';
import IFrame from 'components/IFrame';
import ConfirmDocumentDialog from './components/ConfirmDocumentDialog';
import { ConfirmCompleteDocument } from 'types';
import * as logger from 'api/logger';

function FormDocumentContainer(props): ReactElement {
  const formId = props?.match?.params?.formId;
  const caseId = props?.location.state.caseId;
  const patientId = props?.location.state.patientId;
  const sendToPatient = props?.location.state.sendToPatient || false;
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [openSuccessAlert, setOpenSuccessAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [newDocument, setNewDocument] = useState<ConfirmCompleteDocument>();
  const [selectedDoctorId, setSelectedDoctorId] = useState<number>(0);
  const [billing, setBilling] = useState<string>('');

  const { createBlobDocument, form, confirmDocument } = useFormDocumentsApi(
    patientId,
    caseId,
    formId,
    sendToPatient
  );

  const [saving, setSaving] = useState<any>(null);

  const goBack = () => {
    props?.history?.go(-2);
  };

  const onSave = async blob => {
    setSaving(true);
    if (!form) {
      setSaving(false);
      alert('SAVE ERROR NO DOCUMENT LOADED');
      return;
    }

    // We only support PDFS
    const fileExtenstion = convertEditableMimeTypeToFileExtension('PDF');
    if (!fileExtenstion) {
      setSaving(false);
      alert('SAVE ERROR - Please Reload Page');
      return;
    }

    const curDate = moment().format(DateTimeFormatString.APIDateFormat);
    const fileNamePrepend = sendToPatient ? 'Prepared-To-Send-To-Patient-' : 'Completed-';
    const documentOut = {
      document: blob,
      typeId: 1, // Overwritten by server, defaults to 1
      name: `${fileNamePrepend}${form.description}`,
      mimeType: MIME_TYPE_PDF,
      fileName: `${fileNamePrepend}-${form.description}.${fileExtenstion}`,
      replacedDocumentId: null,
    };

    try {
      const documentDetails = await createBlobDocument(documentOut, selectedDoctorId, billing);
      setNewDocument(documentDetails);
    } catch (e) {
      const message = handleAPIErrors(e, 'Error Sending Document, Please Try Again');
      setAlertMessage(message);
      setOpenAlert(true);
      logger.error(e.message);
    }
    setSaving(false);
  };

  const confirmCompletedDocument = async () => {
    if (!newDocument) return;
    await confirmDocument(newDocument.documentId, sendToPatient);
    setNewDocument(undefined);
    setOpenSuccessAlert(true);
  };

  const onCancel = () => {
    goBack();
  };

  return (
    <>
      <FormDocumentView
        onCancel={onCancel}
        onSave={onSave}
        url={form?.document_url || ''}
        setSaving={setSaving}
        saving={saving}
        sendToPatient={sendToPatient}
        doctorSignature={!!form?.doctor_signature}
        setDoctorId={setSelectedDoctorId}
        setBilling={setBilling}
      />

      <SuccessAlertDialog
        open={openSuccessAlert}
        title={'Success'}
        message={sendToPatient ? 'Form Successfully Sent' : 'Form Successfully Created'}
        onClose={() => {
          setOpenSuccessAlert(false);
          goBack();
        }}
      />

      <ConfirmDocumentDialog
        open={!!newDocument}
        pdfUrl={newDocument?.signedRequest}
        onClose={() => setNewDocument(undefined)}
        onSave={confirmCompletedDocument}
        saveTitle={sendToPatient ? 'Send' : 'Complete'}
      />

      <AlertDialog
        open={openAlert}
        title={'Error'}
        message={alertMessage}
        onClose={() => setOpenAlert(false)}
      />
    </>
  );
}

export default FormDocumentContainer;
