import React, { ReactElement, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Dialog from 'components/Dialog';
import DialogButton from 'components/DialogButton';

import styles from './AddDocumentDialogStyles.module.scss';

interface AddDocumentDialogProps {
  open?: boolean;
  onClose?: () => void;
  handleChooseStamp?: () => void;
  formik?: any;
  onSave: (documentName) => void;
  loading: boolean;
  defaultDocumentName: string;
}

function AddDocumentDialog({
  open,
  onClose,
  formik,
  handleChooseStamp,
  loading,
  onSave,
  defaultDocumentName,
}: AddDocumentDialogProps): ReactElement {
  useEffect(() => {
    formik?.setValues({ name: defaultDocumentName });
  }, [open]);

  return (
    <Dialog open={open} title="Copy Document" onClose={onClose}>
      <div className={styles.padding}>
        <Grid container>
          <Grid item sm={12} xs={12}>
            <div className={styles.padding}>{`Adding Document: ${defaultDocumentName}`}</div>
          </Grid>
          <Grid item sm={12} xs={12}>
            <div className={styles.padding}>
              <TextField
                fullWidth
                name={'name'}
                label="Modify Document name"
                placeholder="Enter document name"
                onChange={formik?.handleChange}
                value={formik?.values.name}
              />
            </div>
          </Grid>
        </Grid>
        <div className={classNames(styles.padding, styles.buttons)}>
          <DialogButton onClick={onClose}>Cancel</DialogButton>
          <DialogButton
            className={styles.marginLeft}
            loading={loading}
            onClick={() => {
              onSave(formik.values.name);
            }}
          >
            Add Document
          </DialogButton>
        </div>
      </div>
    </Dialog>
  );
}

export default AddDocumentDialog;
