import moment from 'moment';
import { AppointmentResponse, AppointmentInfo } from './appointment.interfaces';

export function transformOneAppointment({
  startTime,
  endTime,
  type,
  ...other
}: AppointmentResponse): AppointmentInfo {
  return {
    ...other,
    type,
    resource: type !== 'Consultation' && type !== 'Follow-up' ? 'Procedure' : type,
    start: moment(startTime).toDate(),
    end: moment(endTime).toDate(),
    appointmentId: 0,
  };
}

export function fromGetAppointmentResponse(data: string): Array<AppointmentInfo> {
  return JSON.parse(data).map(transformOneAppointment);
}

export function fromGetOneAppointmentResponse(data: string): AppointmentInfo {
  return transformOneAppointment(JSON.parse(data));
}
