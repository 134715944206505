import React, { useState, useEffect } from 'react';
import styles from './StaffSchedule.module.scss';
import './StaffSchedule.module.scss';
import { ReactComponent as EditIcon } from 'assets/svgs/EditIcon.svg';
import { convertTo12From24 } from 'lib/schedule';
import { StaffType } from 'types';
interface StaffScheduleProps {
  startTime: string;
  endTime: string;
  staffStartTime: string;
  staffEndTime: string;
  staffType: number;
  fullName: string;
  type: number;
  userId: number;
  staffId: number;
  staffScheduleId: string | number;
  staffScheduleInfo: Array<any>;
  handleStaffScheduleEditIconClick: (staffScheduleId, staffId) => void;
  highlightedStaff: number;
  locationRecurrence: string;
  cancelled?: boolean;
}
function StaffSchedule({
  fullName,
  startTime,
  endTime,
  userId,
  staffId,
  staffScheduleId,
  staffStartTime,
  staffEndTime,
  staffType,
  type,
  highlightedStaff,
  locationRecurrence,
  handleStaffScheduleEditIconClick,
  cancelled,
}: StaffScheduleProps) {
  let staffTypeClass = '';
  if (cancelled) {
    staffTypeClass = styles.Cancelled;
  } else {
    switch (staffType) {
      case StaffType.SUPER_ADMIN:
        staffTypeClass = styles.Admin;
        break;
      case StaffType.ADMIN:
        staffTypeClass = styles.Admin;
        break;
      case StaffType.DOCTOR:
        staffTypeClass = styles.Doctor;
        break;
      case StaffType.TECHNICIAN:
        staffTypeClass = styles.Tech;
        break;
      case StaffType.RECEPTIONIST:
        staffTypeClass = styles.GeneralStaff;
        break;
      case StaffType.NURSE:
        staffTypeClass = styles.Nurse;
        break;
      case StaffType.NOSTAFF:
        staffTypeClass = styles.NoStaff;
        break;
      default:
        staffTypeClass = styles.GeneralStaff;
        break;
    }
  }

  const [highlightStatus, setHighlightStatus] = useState<string>('');
  useEffect(() => {
    if (userId === highlightedStaff) {
      setHighlightStatus(styles.isHighlighted);
    } else if (highlightedStaff === -1) {
      setHighlightStatus(styles.noneAreHighlighted);
    } else {
      setHighlightStatus(styles.isNotHighlighted);
    }
  }, [highlightedStaff]);

  function onEditIconClick() {
    handleStaffScheduleEditIconClick(staffScheduleId, userId);
  }

  return (
    <div className={`${styles.container} ${highlightStatus} ${staffTypeClass}`}>
      <div className={styles.staffContent}>
        <b>
          {fullName}
          <br />
          {convertTo12From24(staffStartTime)} - {convertTo12From24(staffEndTime)}{' '}
        </b>
      </div>
      <div className={styles.editIcon}>
        <EditIcon
          className={styles.editIcon}
          onClick={onEditIconClick}
          title="Edit Staff Schedule"
        />
      </div>
    </div>
  );
}

export default StaffSchedule;
