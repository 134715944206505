import React, { ReactElement, memo } from 'react';

import Dialog from 'components/Dialog';
import styles from './FamDocInfoConfirmDialogStyles.module.scss';
import Button from '@material-ui/core/Button';

interface FamDocInfoConfirmDialogProps {
  open?: boolean;
  onCancel: () => void;
  onSave: (value) => void;
  emptyFamDocFields: string[];
}

const FamDocInfoConfirmDialog = ({
  open,
  onCancel,
  onSave,
  emptyFamDocFields,
}: FamDocInfoConfirmDialogProps): ReactElement => {
  const confirmQuestionText = `Are you sure you want to leave out this information about the physician?`;
  const onSaveButtonText = `Save Anyway`;
  const onCancelButtonText = `Go Back`;
  return (
    <Dialog open={open} onClose={onCancel} title={`Family Physician Information Confirmation`}>
      <div className={styles.container}>
        <div className={styles.text}>
          {confirmQuestionText}
          <ul>
            {emptyFamDocFields.map(emptyFamDocField => {
              return <li key={emptyFamDocField}>{emptyFamDocField}</li>;
            })}
          </ul>
        </div>
        <div className={styles.buttons}>
          <Button onClick={onSave}>{onSaveButtonText}</Button>
          <Button onClick={onCancel}>{onCancelButtonText}</Button>
        </div>
      </div>
    </Dialog>
  );
};

export default memo(FamDocInfoConfirmDialog);
