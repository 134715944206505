import moment, { Moment } from 'moment';
import { EARLIEST_OPENING_HOUR, LATEST_CLOSING_HOUR } from 'lib/const';

export enum AppointmentStatus {
  Unconfirmed = 1,
  NeedsToBeMoved = 2,
  Confirmed = 3,
  Cancelled = 4,
  Deleted = 5,
  Arrived = 6,
  Departed = 7,
  Billed = 8,
  FollowUpScheduled = 9,
  NoShow = 10,
  NotPerformed = 11,
}

export const APPOINTMENT_STATUSES = [
  { id: 1, name: 'Unconfirmed' },
  { id: 2, name: 'Needs to be moved' },
  { id: 3, name: 'Confirmed' },
  { id: 4, name: 'Cancelled' },
  { id: 5, name: 'Deleted' },
  { id: 6, name: 'Arrived' },
  { id: 7, name: 'Departed' },
  { id: 8, name: 'Billed' },
  { id: 9, name: 'Follow-up Scheduled' },
  { id: 10, name: 'No Show' },
  { id: 11, name: 'Not Performed' },
];

export const getAppointmentStatusNameById = (statusId: AppointmentStatus): string => {
  const status = APPOINTMENT_STATUSES.find(status => {
    return statusId === status.id;
  });
  return status ? status.name : '';
};

export enum APPOINTMENT_TYPE_IDS {
  Consultation = 1,
  Procedure = 2,
  Ultrasound = 3,
}

export const APPOINTMENT_TYPES = [
  {
    id: APPOINTMENT_TYPE_IDS.Consultation,
    name: 'Consultation',
  },
  {
    id: APPOINTMENT_TYPE_IDS.Procedure,
    name: 'Procedure',
  },
  {
    id: APPOINTMENT_TYPE_IDS.Ultrasound,
    name: 'Ultrasound',
  },
];

/*
  Auto generate list of slots and spacing to be used by appointments calendar view

  Example:
  [
    ...
    { id: 150, offsetSpace: 10, name: '2.5' },
    { id: 180, offsetSpace: 12, name: '3' },
    ...
  ]
*/

export const MINIMUM_APPOINTMENT_LENGTH = 5;
const hoursOpen = LATEST_CLOSING_HOUR - EARLIEST_OPENING_HOUR;
const slotsPerHour = 60 / MINIMUM_APPOINTMENT_LENGTH;
const maxAppointmentSlots = hoursOpen * slotsPerHour + 1;

export const SLOT_LENGTH = Array(maxAppointmentSlots)
  .fill(0)
  .map((v, offsetSpace) => {
    const offsetSpaceUse = offsetSpace;
    const id = offsetSpaceUse * 5;
    const minute = id % 60;
    const hour = Math.floor(id / 60);

    let name = `${minute}`;
    if (hour > 0 && minute >= 0) {
      if (minute == 5) {
        name = `${hour}:0${minute}`;
      } else if (minute == 0) {
        name = `${hour}:00`;
      } else {
        name = `${hour}:${minute}`;
      }
    } else if (hour > 0) {
      name = `${hour}`;
    }
    return { id, offsetSpaceUse, name };
  });

export const SLOT_LENGTH_MINIMUM_15_MINUTES = SLOT_LENGTH.slice(3);
export const SLOT_LENGTH_MINIMUM_5_MINUTES = SLOT_LENGTH.slice(1);

export enum SlotLengthInMinutes {
  Fifteen = 15,
  Thirty = 30,
  FourtyFive = 45,
  Sixty = 60,
  SeventyFive = 75,
  Ninety = 90,
  OneTwenty = 120,
  OneFifty = 150,
  OneEighty = 180,
  TwoTen = 210,
  TwoForty = 240,
}

export const DEFAULT_NEW_APPOINTMENT_DATA = {
  length: SLOT_LENGTH[3].id,
  // scheduleKey: '09:15',
  typeId: APPOINTMENT_TYPES[0].id,
  day: moment(),
  appointmentStatusId: APPOINTMENT_STATUSES[0].id,
  cancellationList: false,
};

export const GENDERS = ['Male', 'Female', 'Other'];

export function getStartTime(day: Moment, startTime = '00:00:00'): Moment {
  const mStartTime = moment(startTime, 'hh:mm:s');

  return day
    .clone()
    .startOf('day')
    .add(mStartTime.hours(), 'hours')
    .add(mStartTime.minutes(), 'minutes');
}

export function findByTypeId(id: number, types: Array<{ id: number; name: string }>) {
  return types.find(item => item.id === id);
}

export function findByTypeName(name: string, types: Array<{ id: number; name: string }>) {
  return types.find(item => item.name === name);
}

export const getQuarterTimes = (startTime: number, endTime: number) => {
  const quarterHours = ['00', '15', '30', '45'];
  const times: Array<string> = [];
  for (let i = startTime; i < endTime; i++) {
    for (let j = 0; j < 4; j++) {
      let firstDigit = '';
      if (i < 10) {
        firstDigit += '0';
      }
      times.push(firstDigit + i + ':' + quarterHours[j]);
    }
  }

  return times;
};

export const getFiveMinTimes = (startTime: number, endTime: number) => {
  const fiveMin: string[] = ['00', '05'];
  for (let i = 2; i < 12; i++) {
    fiveMin.push(String(i * 5));
  }
  const times: Array<string> = [];
  for (let i = startTime; i < endTime; i++) {
    for (let j = 0; j < 12; j++) {
      let firstDigit = '';
      if (i < 10) {
        firstDigit += '0';
      }
      times.push(firstDigit + i + ':' + fiveMin[j]);
    }
  }

  return times;
};

export const getQuarterTimeIndexed = (startTime: number, endTime: number) => {
  const quarterTimes = getQuarterTimes(startTime, endTime);
  return quarterTimes.map((time, index) => {
    return {
      id: index,
      time: time,
    };
  });
};

export const getFiveMinTimeIndexed = (startTime: number, endTime: number) => {
  const quarterTimes = getFiveMinTimes(startTime, endTime);
  return quarterTimes.map((time, index) => {
    return {
      id: index,
      time: time,
    };
  });
};
