import React, { ReactElement } from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';

interface Data {
  name: string;
  sender: number;
  time: string;
  assignedTo: string;
  pdfIncluded: string;
  info: string;
}

interface HeadCell {
  id: keyof Data;
  label: string;
}

const headCells: HeadCell[] = [
  { id: 'name', label: 'ID' },
  { id: 'info', label: 'Info' },
  { id: 'sender', label: 'Sending Number' },
  { id: 'time', label: 'Received Time' },
  { id: 'pdfIncluded', label: 'PDF' },
];

interface EnhancedTableProps {
  classes: any;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  numSelected: number;
  rowCount: number;
}

function InboundEnhancedTableHead(props: EnhancedTableProps): ReactElement {
  const { classes, onSelectAllClick, numSelected, rowCount } = props;

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            className={classes.cellColored}
            key={headCell.id}
            align={'left'}
            padding={'default'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default InboundEnhancedTableHead;
