import React, { ReactElement, useContext, useState } from 'react';

import { Receipt } from 'types';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

import Snackbar from '@material-ui/core/Snackbar';
import TitledSection from 'components/TitledSection';
import ConditionDiv from 'components/ConditionDiv';
import Button from 'components/Button';

import { ROLE } from 'lib/user';
import { Context } from 'context/auth';

import ReceiptItem from './components/ReceiptItem';

import styles from './ReceiptsStyles.module.scss';

import DeleteConfirmationDialog from './components/DeleteConfirmationDialog.view';
import SuccessSnackBar from 'components/SuccessSnackBar';
import * as logger from 'api/logger';
import useProfileRoles from 'hook/useProfileRoles.hook';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface ReceiptsProps {
  loading?: boolean;
  receipts: Array<Receipt>;
  viewReceipt(id: number): void;
  onCreateReceiptClick?(): void;
  onDeleteReceipt?(id: number | undefined): void;
  onSendReceipt?(id: number): void;
}

function Receipts({
  receipts = [],
  loading = false,
  onCreateReceiptClick,
  onDeleteReceipt = id => console.log('remove', id),
  onSendReceipt = id => console.log('send', id),
  viewReceipt,
}: ReceiptsProps): ReactElement {
  const [successSnackbarMessage, setSuccessSnackbarMessage] = useState('');
  const [isSuccessSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [eventToDelete, setEventToDelete] = useState(false);
  const [idToDelete, setIdToDelete] = useState<number>();
  const { isAdmin } = useProfileRoles();

  async function onSend(id: number) {
    try {
      await onSendReceipt(id);
      setSuccessSnackbarMessage('The Receipt has been sent');
      setSuccessSnackbarOpen(true);
    } catch (e) {
      logger.error(e.message);
    }
  }

  async function ConfirmedDeletion() {
    try {
      loading = true;
      await onDeleteReceipt(idToDelete);
      setEventToDelete(false);
      setSuccessSnackbarMessage('The Receipt has been successfully deleted');
      setSuccessSnackbarOpen(true);
      loading = false;
    } catch (e) {
      logger.error(e.message);
    }
  }

  async function onDelete(id: number) {
    setEventToDelete(true);
    setIdToDelete(id);
  }

  const { role } = useContext(Context);

  const headContent = (
    <div className={styles.headButton}>
      <Button onClick={onCreateReceiptClick}>Create Receipt</Button>
    </div>
  );

  return (
    <TitledSection title="Receipts" headContent={headContent}>
      <ConditionDiv condition={!!receipts.length}>
        {receipts.map((item, index) => (
          <ReceiptItem
            key={index}
            loading={loading}
            className={styles.receipt}
            value={{
              title: item.header,
              receipt: item.contents,
              amount: item.total,
              sent: item.sent,
              createdByFullName: item.createdBy.fullName,
              createdAt: item.createdAt,
              refund: item.refund,
            }}
            showDeleteButton={isAdmin}
            onDelete={isAdmin ? () => onDelete(item.id) : undefined}
            onSend={() => onSend(item.id)}
            viewReceipt={() => viewReceipt(item.id)}
          />
        ))}
      </ConditionDiv>
      <ConditionDiv condition={!receipts.length} className={styles.emptyText}>
        Currently no receipts
      </ConditionDiv>
      <SuccessSnackBar
        open={isSuccessSnackbarOpen}
        setOpen={setSuccessSnackbarOpen}
        message={successSnackbarMessage}
      />
      <DeleteConfirmationDialog
        open={eventToDelete}
        loading={loading}
        onClose={() => setEventToDelete(false)}
        onConfirm={() => ConfirmedDeletion()}
      />
    </TitledSection>
  );
}

export default Receipts;
