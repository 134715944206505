import { UserApi } from 'api';
import formatDate, { DateTimeFormat } from 'lib/dateFormatter';
import moment from 'moment';
import useWorkflowResultApi from 'pages/WorkflowResult/useWorkflowApi.hook';
import React, { useEffect, useState } from 'react';
import { User } from 'types';

interface RequisitionResultPDFFragmentProps {
  formId?: number;
  caseId?: number;
}

function useRequisitionResultPDFFragment({ formId, caseId }: RequisitionResultPDFFragmentProps) {
  const { formCaseResult } = useWorkflowResultApi(formId, caseId);
  const [formResults, setFormResults] = useState<any>();

  const convertArr = (toConvert: any) => {
    if (!toConvert) return undefined;
    return Array.isArray(toConvert) ? toConvert : [toConvert];
  };

  useEffect(() => {
    const answers = formCaseResult?.answers;
    if (answers) {
      const data = {
        patient: {
          fullName: answers.find(ans => ans.id == 'PatientMetaData::fullName')?.value as string,
          dateOfBirth: answers.find(ans => ans.id == 'PatientMetaData::dateOfBirth')
            ?.value as string,
          hcn: answers.find(ans => ans.id == 'PatientMetaData::healthCard')?.value as string,
          cellPhone: answers.find(ans => ans.id == 'PatientMetaData::contactValue')
            ?.value as string,
          homePhone: answers.find(ans => ans.id == 'PatientMetaData::contact1Value')
            ?.value as string,
          phone: answers.find(ans => ans.id == 'PatientMetaData::contact2Value')?.value as string,
        },
        date: formatDate(DateTimeFormat.DayMonthYear, moment()),
        typeOfExam: convertArr(
          answers.find(ans => ans.id == 'Requisition Form::typeOfExam')?.value
        ) as Array<string>,
        carotidDuplexLEIndication: convertArr(
          answers.find(ans => ans.id == 'Requisition Form::carotidDuplexLEIndication')?.value
        ) as Array<string>,
        carotidDuplexLESuperficial: convertArr(
          answers.find(ans => ans.id == 'Requisition Form::carotidDuplexLESuperficial')?.value
        ) as Array<string>,
        carotidDuplexUEIndication: convertArr(
          answers.find(ans => ans.id == 'Requisition Form::carotidDuplexUEIndication')?.value
        ) as Array<string>,
        carotidDuplexUESuperficial: convertArr(
          answers.find(ans => ans.id == 'Requisition Form::carotidDuplexUESuperficial')?.value
        ) as Array<string>,
        venousDuplexExamLEDeep: convertArr(
          answers.find(ans => ans.id == 'Requisition Form::venousDuplexExamLEDeep')?.value
        ) as Array<string>,
        venousDuplexExamUEDeep: convertArr(
          answers.find(ans => ans.id == 'Requisition Form::venousDuplexExamUEDeep')?.value
        ) as Array<string>,
        venousDuplexExamUEIndication: convertArr(
          answers.find(ans => ans.id == 'Requisition Form::venousDuplexExamUEIndication')?.value
        ) as Array<string>,
        venousDuplexExamUESuperficial: convertArr(
          answers.find(ans => ans.id == 'Requisition Form::venousDuplexExamUESuperficial')?.value
        ) as Array<string>,
        venousDuplexExamLEIndication: convertArr(
          answers.find(ans => ans.id == 'Requisition Form::venousDuplexExamLEIndication')?.value
        ) as Array<string>,
        venousDuplexExamLESuperficial: convertArr(
          answers.find(ans => ans.id == 'Requisition Form::venousDuplexExamLESuperficial')?.value
        ) as Array<string>,
        arterialDuplexExamLEIndication: convertArr(
          answers.find(ans => ans.id == 'Requisition Form::arterialDuplexExamLEIndication')?.value
        ) as Array<string>,
        arterialDuplexExamUEIndication: convertArr(
          answers.find(ans => ans.id == 'Requisition Form::arterialDuplexExamUEIndication')?.value
        ) as Array<string>,
        carotidDuplexIndication: convertArr(
          answers.find(ans => ans.id == 'Requisition Form::carotidDuplexIndication')?.value
        ) as Array<string>,
        carotidDuplexRiskFactors: convertArr(
          answers.find(ans => ans.id == 'Requisition Form::carotidDuplexRiskFactors')?.value
        ) as Array<string>,
        aorticScreeningExamIndication: convertArr(
          answers.find(ans => ans.id == 'Requisition Form::aorticScreeningExamIndication')?.value
        ) as Array<string>,
        aorticScreeningExamRiskFactors: convertArr(
          answers.find(ans => ans.id == 'Requisition Form::aorticScreeningExamRiskFactors')?.value
        ) as Array<string>,
        reasonForExamLe: convertArr(
          answers.find(ans => ans.id == 'Requisition Form::reasonForExamLe')?.value
        ) as Array<string>,
        reasonForExamUe: convertArr(
          answers.find(ans => ans.id == 'Requisition Form::reasonForExamUe')?.value
        ) as Array<string>,
        doctor: answers.find(ans => ans.id == 'Requisition Form::doctor')?.value as string,
        billing: answers.find(ans => ans.id == 'Requisition Form::billing')?.value as string,
        fax: answers.find(ans => ans.id == 'Requisition Form::fax')?.value as string,
        otherTypeOfExamSpecify: answers.find(
          ans => ans.id == 'Requisition Form::otherTypeOfExamSpecify'
        )?.value as string,
        urgentBooking: answers.find(ans => ans.id == 'Requisition Form::urgentBooking')
          ?.value as string,

        venousDuplexExamLEUltrasound: answers.find(
          ans => ans.id == 'Requisition Form::venousDuplexExamLEUltrasound'
        )?.value as string,
        venousDuplexExamLEFollowUp3m: answers.find(
          ans => ans.id == 'Requisition Form::venousDuplexExamLEFollowUp3m'
        )?.value as string,
        venousDuplexExamLEFollowUp6m: answers.find(
          ans => ans.id == 'Requisition Form::venousDuplexExamLEFollowUp6m'
        )?.value as string,
        venousDuplexExamLEFollowUp12m: answers.find(
          ans => ans.id == 'Requisition Form::venousDuplexExamLEFollowUp12m'
        )?.value as string,
        venousDuplexExamLEFollowUpGreaterThan12m: answers.find(
          ans => ans.id == 'Requisition Form::venousDuplexExamLEFollowUpGreaterThan12m'
        )?.value as string,
        venousDuplexExamLEFollowUpSpecify: answers.find(
          ans => ans.id == 'Requisition Form::venousDuplexExamLEFollowUpSpecify'
        )?.value as string,
        venousDuplexExamLEPostSVT: answers.find(
          ans => ans.id == 'Requisition Form::venousDuplexExamLEPostSVT'
        )?.value as string,
        venousDuplexExamLEPreProcAblation: answers.find(
          ans => ans.id == 'Requisition Form::VenousDuplexExamLE-PreProcAblation'
        )?.value as string,
        venousDuplexExamLEPostProcAblation: answers.find(
          ans => ans.id == 'Requisition Form::VenousDuplexExamLE-PostProcAblation'
        )?.value as string,
        venousDuplexExamLEPostDVTLower: answers.find(
          ans => ans.id == 'Requisition Form::venousDuplexExamLEPostDVTLower'
        )?.value as string,
        venousDuplexExamUEUltrasound: answers.find(
          ans => ans.id == 'Requisition Form::venousDuplexExamUEUltrasound'
        )?.value as string,
        venousDuplexExamUEFollowUp3m: answers.find(
          ans => ans.id == 'Requisition Form::venousDuplexExamUEFollowUp3m'
        )?.value as string,
        venousDuplexExamUEFollowUp6m: answers.find(
          ans => ans.id == 'Requisition Form::venousDuplexExamUEFollowUp6m'
        )?.value as string,
        venousDuplexExamUEFollowUp12m: answers.find(
          ans => ans.id == 'Requisition Form::venousDuplexExamUEFollowUp12m'
        )?.value as string,
        venousDuplexExamUEFollowUpGreaterThan12m: answers.find(
          ans => ans.id == 'Requisition Form::venousDuplexExamUEFollowUpGreaterThan12m'
        )?.value as string,
        venousDuplexExamUEFollowUpSpecify: answers.find(
          ans => ans.id == 'Requisition Form::venousDuplexExamUEFollowUpSpecify'
        )?.value as string,
        venousDuplexExamUEPostSVT: answers.find(
          ans => ans.id == 'Requisition Form::venousDuplexExamUEPostSVT'
        )?.value as string,
        venousDuplexExamUEPreProcAblation: answers.find(
          ans => ans.id == 'Requisition Form::VenousDuplexExamUE-PreProcAblation'
        )?.value as string,
        venousDuplexExamUEPostProcAblation: answers.find(
          ans => ans.id == 'Requisition Form::VenousDuplexExamUE-PostProcAblation'
        )?.value as string,
        venousDuplexExamUEPostDVTUpper: answers.find(
          ans => ans.id == 'Requisition Form::venousDuplexExamUEPostDVTUpper'
        )?.value as string,
        lowerExtremities: answers.find(ans => ans.id == 'Requisition Form::lowerExtremities')
          ?.value as string,
        upperExtremities: answers.find(ans => ans.id == 'Requisition Form::upperExtremities')
          ?.value as string,
        legLESuperficial: answers.find(
          ans => ans.id == 'Requisition Form::whichLegforVenousDuplexLESuperficalVeins'
        )?.value as string,
        armUESuperficial: answers.find(
          ans => ans.id == 'Requisition Form::whichArmforVenousDuplexUESuperficalVeins'
        )?.value as string,
        legLEDeep: answers.find(
          ans => ans.id == 'Requisition Form::whichLegforVenousDuplexLEDeepVeins'
        )?.value as string,
        armUEDeep: answers.find(
          ans => ans.id == 'Requisition Form::whichArmforVenousDuplexUEDeepVeins'
        )?.value as string,
        clinicalInformation: answers.find(ans => ans.id == 'Requisition Form::clinicalInformation')
          ?.value as string,
        venousDuplexExamLEOtherIndication: answers.find(
          ans => ans.id == 'Requisition Form::venousDuplexExamLEOtherIndication'
        )?.value as string,
        venousDuplexExamUEOtherIndication: answers.find(
          ans => ans.id == 'Requisition Form::venousDuplexExamUEOtherIndication'
        )?.value as string,
        arterialDuplexExamLEOtherIndication: answers.find(
          ans => ans.id == 'Requisition Form::arterialDuplexExamLEOtherIndication'
        )?.value as string,
        arterialDuplexExamUEOtherIndication: answers.find(
          ans => ans.id == 'Requisition Form::arterialDuplexExamUEOtherIndication'
        )?.value as string,
        aorticScreeningExamOtherRiskFactor: answers.find(
          ans => ans.id == 'Requisition Form::aorticScreeningExamOtherRiskFactor'
        )?.value as string,
        aorticScreeningExamOtherIndication: answers.find(
          ans => ans.id == 'Requisition Form::aorticScreeningExamOtherIndication'
        )?.value as string,
        carotidDuplexOtherRiskFactor: answers.find(
          ans => ans.id == 'Requisition Form::carotidDuplexOtherRiskFactor'
        )?.value as string,
        carotidDuplexOtherIndication: answers.find(
          ans => ans.id == 'Requisition Form::carotidDuplexOtherIndication'
        )?.value as string,
      };
      setFormResults(data);
    }
  }, [formCaseResult]);

  return {
    formResults,
  };
}

export default useRequisitionResultPDFFragment;
