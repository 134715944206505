import { MenuItem } from '@material-ui/core';
import { DateRange } from '@material-ui/icons';
import Autocomplete from 'components/Autocomplete';
import Select from 'components/Select';
import useReports from 'hook/useReport.hook';
import Layout from 'Layout';
import React, { ReactElement, useEffect, useState } from 'react';
import { ReportFilters, ReportType, Location } from 'types';
import styles from './Reports.module.scss';
import { DatePicker } from '@material-ui/pickers';
import useAppointmentsApi from 'pages/Appointments/useAppointmentsApi.hook';
import CSVDownload from 'components/CSVDownload';
import moment from 'moment';
import formatDate, { DateTimeFormat, DateTimeFormatString, getYears } from 'lib/dateFormatter';
import useReceiptApi from 'hook/useReceiptApi.hook';
import { AlertDialog } from 'components/AlertDialog';
import CVCButton from 'components/CVCButton';
import { getLocations } from 'api/location';
import { taxRegions } from 'lib/reports';
import Checkbox from 'components/Checkbox';
import Button from 'components/Button';
import { subtract } from 'lodash';

function Reports(): ReactElement {
  const [selectedFilters, setSelectedFilters] = useState<ReportFilters>({});
  const [openAlertDialog, setOpenAlertDialog] = useState<boolean>(false);
  const [reportSearched, setReportSearched] = useState<boolean>(false);
  const [selectedReport, setSelectedReport] = useState<ReportType>();

  const {
    fetchTypes,
    types,
    totalResults,
    createReport,
    csvHeaders,
    report,
    resetReport,
  } = useReports();
  const { receiptTypes, fetchReceiptTypes } = useReceiptApi();
  const { staffList, subTypes } = useAppointmentsApi();
  const [locations, setLocations] = useState<Location[]>([]);
  const [openEndDatePicker, setOpenEndDatePicker] = useState<boolean>(false);
  const [openSecondEndDatePicker, setOpenSecondEndDatePicker] = useState<boolean>(false);
  const allConsultations = subTypes.filter(item => item.id >= 28 && item.id <= 33);
  const allProcedures = subTypes.filter(item => item.id >= 4 && item.id <= 27);
  const allUltrasounds = subTypes.filter(item => item.id >= 34 && item.id <= 42);
  const vceProcedures = subTypes.filter(item => item.id >= 4 && item.id <= 12);
  const kneeHighStockings = receiptTypes.filter(item => item.id == 21 || item.id == 22);
  const thighHighOpenToeStockings = receiptTypes.filter(item => item.id == 23 || item.id == 24);
  const thighHighCloseToeStockings = receiptTypes.filter(item => item.id == 25 || item.id == 26);
  const pantyHoseStockings = receiptTypes.filter(item => item.id == 27 || item.id == 28);
  const leggingsStockings = receiptTypes.filter(item => item.id == 29 || item.id == 30);
  const sportKneeHighStockings = receiptTypes.filter(item => item.id >= 31 && item.id <= 34);

  const commonSubTypes: string[] = (() => {
    const subTypeNames = new Set<string>();
    const commonSubTypeNames: string[] = [];

    subTypes.forEach(appt => {
      const { mainTypeId, subTypeName } = appt;
      if (mainTypeId === 1 || mainTypeId === 2) {
        if (!subTypeNames.has(subTypeName)) {
          subTypeNames.add(subTypeName);
        } else {
          commonSubTypeNames.push(subTypeName);
        }
      }
    });

    return commonSubTypeNames;
  })();

  const MONTHS = [
    { id: 0, month: 'January' },
    { id: 1, month: 'February' },
    { id: 2, month: 'March' },
    { id: 3, month: 'April' },
    { id: 4, month: 'May' },
    { id: 5, month: 'June' },
    { id: 6, month: 'July' },
    { id: 7, month: 'August' },
    { id: 8, month: 'September' },
    { id: 9, month: 'October' },
    { id: 10, month: 'November' },
    { id: 11, month: 'December' },
  ];

  const YEARS = getYears();

  // filter out consultations that share the same subtype with a procedure inside arr
  const mergeConsultationsProcedures = (arr: Array<any>): Array<any> => {
    return arr.filter(
      item => !(item.mainTypeId === 1 && commonSubTypes.includes(item.subTypeName))
    );
  };

  // dropdown filter label - display appt name + subtype name OR just display subtype name
  const shouldDisplayFullName = appt => {
    if (
      (appt.mainTypeId === 1 || appt.mainTypeId === 2) &&
      commonSubTypes.includes(appt.subTypeName)
    ) {
      return `${appt.subTypeName}`;
    } else {
      return `${appt.mainTypeName}: ${appt.subTypeName}`;
    }
  };

  const setFiltering = (key, newValue) => {
    setSelectedFilters(prev => {
      return {
        ...prev,
        [key]: newValue,
      };
    });
    if (selectedReport && selectedReport.id === 1) {
      if (key === 'creatingStaffs') {
        setSelectedFilters(prev => {
          return {
            ...prev,
            ['staffs']: undefined,
          };
        });
      }
      if (key === 'staffs') {
        setSelectedFilters(prev => {
          return {
            ...prev,
            ['creatingStaffs']: undefined,
          };
        });
      }
    }
  };

  const filters = {
    appointmentTypes: {
      label: 'Appointment Type',
      type: 'multiPreset',
      items: mergeConsultationsProcedures(subTypes),
      getOptionLabel: type => shouldDisplayFullName(type),
      buttons: [
        { label: 'Consultations', selectItems: allConsultations },
        { label: 'Procedures', selectItems: allProcedures },
        { label: 'Ultrasounds', selectItems: allUltrasounds },
        { label: 'VCE', selectItems: vceProcedures },
      ],
    },
    ultrasoundTypes: {
      label: 'Original Ultrasound Type',
      type: 'multi',
      items: subTypes.filter(type => type.id >= 34 && type.id <= 37),
      getOptionLabel: type => `${type.mainTypeName}: ${type.subTypeName}`,
    },
    receiptFor: {
      label: 'Receipt Type',
      type: 'multiPreset',
      items: receiptTypes,
      getOptionLabel: type => `${type.receiptType}`,
      buttons: [
        { label: 'KH', selectItems: kneeHighStockings },
        { label: 'THOT', selectItems: thighHighOpenToeStockings },
        { label: 'THCT', selectItems: thighHighCloseToeStockings },
        { label: 'PH', selectItems: pantyHoseStockings },
        { label: 'LEG', selectItems: leggingsStockings },
        { label: 'SKH', selectItems: sportKneeHighStockings },
      ],
    },
    staffs: {
      label: 'Staff',
      type: 'multi',
      items: staffList,
      getOptionLabel: (staff): string => `${staff.name}`,
    },
    creatingStaffs: {
      type: 'multi',
      label: 'Creating Staff',
      items: staffList,
      getOptionLabel: (staff): string => `${staff.name}`,
    },
    locations: {
      type: 'multi',
      label: 'Locations',
      items: locations,
      getOptionLabel: (location): string => `${location.name}`,
    },
    hasReferral: {
      type: 'select',
      label: 'Has Referral',
      options: ['BOTH', 'YES', 'NO'],
    },
    hasProcedure: {
      type: 'select',
      label: 'Has Procedure',
      options: ['BOTH', 'YES', 'NO'],
    },
    hasConsultation: {
      type: 'select',
      label: 'Has Consultation',
      options: ['BOTH', 'YES', 'NO'],
    },
    patientType: {
      type: 'select',
      label: 'Patient Type',
      options: ['BOTH', 'NEW', 'EXISTING'],
    },
    nextAppointment: {
      type: 'select',
      label: 'Has a follow up consultations',
      options: ['BOTH', 'YES', 'NO'],
    },
    procedureTypes: {
      type: 'multiPreset',
      label: 'Procedure Type',
      items: subTypes.filter(type => (type.id >= 4 && type.id <= 27) || type.id == 78),
      getOptionLabel: type => shouldDisplayFullName(type),
      buttons: [
        { label: 'Procedures', selectItems: allProcedures },
        { label: 'VCE', selectItems: vceProcedures },
      ],
    },
    consultationNPTypes: {
      type: 'multi',
      label: 'New Patient Consultation',
      items: subTypes.filter(type => type.id >= 28 && type.id <= 30),
      getOptionLabel: type => shouldDisplayFullName(type),
    },
    consultations: {
      type: 'multi',
      label: 'Consultation',
      items: subTypes.filter(type => type.id >= 28 && type.id <= 33),
      getOptionLabel: type => shouldDisplayFullName(type),
    },
    procedures: {
      type: 'multi',
      label: 'Procedure',
      items: subTypes.filter(type => (type.id >= 4 && type.id <= 27) || type.id == 78),
      getOptionLabel: type => shouldDisplayFullName(type),
    },
    bookingStatus: {
      type: 'select',
      label: 'Has Procedure Booked',
      options: ['ANY', 'BOOKED', 'NOT BOOKED'],
    },
    billingStatus: {
      type: 'select',
      label: 'Billing Status',
      options: ['ANY', 'BILLED', 'NOT BILLED'],
    },
    gender: {
      type: 'select',
      label: 'Gender',
      options: ['ANY', 'MALE', 'FEMALE', 'OTHER'],
    },
    hearAboutUs: {
      type: 'multi',
      label: 'How do you hear about us?',
      items: [
        { id: 1, value: 'Referral from a friend' },
        { id: 2, value: 'Medical professional' },
        { id: 3, value: 'Canada Vein Clinics ad' },
        { id: 4, value: 'Newspaper' },
        { id: 5, value: 'Flyer' },
        { id: 6, value: 'Google' },
        { id: 7, value: 'Facebook' },
        { id: 8, value: 'Instagram' },
        { id: 9, value: 'Linkedin' },
        { id: 10, value: 'Other' },
      ],
      getOptionLabel: type => type.value,
    },
    mainConcern: {
      type: 'select',
      label: 'Main Concern',
      options: ['ANY', 'VARICOSE VEINS', 'SPIDER VEINS'],
    },
    symptomLength: {
      type: 'multi',
      label: 'How long have you had symptoms?',
      items: [
        { id: 1, value: '1-3 months' },
        { id: 2, value: '3-6 months' },
        { id: 3, value: '6 months - 1 year' },
        { id: 4, value: '1-5 years' },
        { id: 5, value: '5-10 years' },
        { id: 6, value: 'More than 10 years' },
      ],
      getOptionLabel: type => type.value,
    },
    symptoms: {
      type: 'multi',
      label: 'Symptoms',
      items: [
        { id: 1, value: 'Pain/aching' },
        { id: 2, value: 'Heaviness' },
        { id: 3, value: 'Itching' },
        { id: 4, value: 'Tiredness/fatigue' },
        { id: 5, value: 'Cramping' },
        { id: 6, value: 'Numbness' },
        { id: 7, value: 'Restless legs' },
        { id: 8, value: 'Swelling' },
        { id: 9, value: 'Leg ulcers' },
        { id: 10, value: 'Bleeding from varicose veins' },
        { id: 11, value: 'Spider veins' },
        { id: 12, value: 'Cosmetic issues' },
        { id: 13, value: 'Other' },
      ],
      getOptionLabel: type => type.value,
    },
    endOfDayWorse: {
      type: 'select',
      label: 'Worse at end of the day?',
      options: ['ANY', 'YES', 'NO'],
    },
    triedStockings: {
      type: 'select',
      label: 'Tried compression stockings?',
      options: ['ANY', 'YES', 'NO'],
    },
    historyOfVaricoseVeins: {
      type: 'select',
      label: 'History of varicose veins',
      options: ['ANY', 'YES', 'NO'],
    },
    knownAllergies: {
      type: 'select',
      label: 'Know allergies',
      options: ['ANY', 'YES', 'NO'],
    },
    bloodClots: {
      type: 'select',
      label: 'Blood Clots',
      options: ['ANY', 'YES', 'NO'],
    },
    treatedForVaricoseVeins: {
      type: 'select',
      label: 'Treated for vein conditions',
      options: ['ANY', 'YES', 'NO'],
    },
    medicalHistory: {
      type: 'multi',
      label: 'Medical History',
      items: [
        { id: 1, value: 'No History' },
        { id: 2, value: 'Hypertension' },
        { id: 3, value: 'Heart disease' },
        { id: 4, value: 'Diabetes' },
        { id: 5, value: 'A bleeding disorder' },
        { id: 6, value: 'Blood clots' },
        { id: 7, value: 'Factor v Leyden deficiency' },
        { id: 8, value: 'Protein C deficiency' },
        { id: 9, value: 'Protein S deficiency' },
        { id: 10, value: 'Other hypercoagulopathy' },
        { id: 11, value: 'Cancer' },
        { id: 12, value: 'Epilepsy' },
        { id: 13, value: 'Other' },
      ],
      getOptionLabel: type => type.value,
    },
    takingBloodThinners: {
      type: 'select',
      label: 'Taking blood thinners',
      options: ['ANY', 'YES', 'NO'],
    },
    symptomsImproved: {
      type: 'select',
      label: 'Symptoms Improved',
      options: ['ANY', 'YES', 'NO'],
    },
    cosmeticResultsImprovedVaricoseVeins: {
      type: 'select',
      label: 'Cosmetic results improved varicose veins',
      options: ['ANY', 'YES', 'NO'],
    },
    cosmeticResultsImprovedspiderReticularVeins: {
      type: 'select',
      label: 'Cosmetic results improved spider/reticular veins',
      options: ['ANY', 'YES', 'NO'],
    },
    satisfaction: {
      type: 'select',
      label: 'Satisfaction',
      options: ['ANY', 'Very', 'Somewhat', 'Not yet'],
    },
    treatmentRecommendation: {
      type: 'select',
      label: 'Treatment recommendation',
      options: ['Any', 'Sclerotherapy', 'Re-do', 'N/A'],
    },
    recommendedByMdOrNurse: {
      type: 'select',
      label: 'Recommended by MD or RN',
      options: ['ANY', 'MD', 'RN'],
    },
    charge: {
      type: 'select',
      label: 'Charge or no Charge',
      options: ['ANY', 'Charge', 'No-Charge'],
    },
    hasCompressionStockings: {
      type: 'select',
      label: 'Compression Stockings Recommended',
      options: ['ANY', 'YES', 'NO'],
    },
    recommendedFUp: {
      type: 'select',
      label: 'Recommended Follow Up In',
      options: ['ANY', '1 Week', '1 Month', '2 Month', '3 Month', '6 Month', 'N/A'],
    },
    Date: {
      type: 'date',
      label: 'Date',
    },
    SecondDate: {
      type: 'date',
      label: '2nd Date',
    },
    DateOfService: {
      type: 'date',
      label: 'Date Of Service',
    },
    DateOfReceipt: {
      type: 'date',
      label: 'Date Of Receipt',
    },
    taxRegions: {
      type: 'multi',
      label: 'Tax Region',
      items: taxRegions,
      getOptionLabel: type => type.value,
    },
    includeDeleted: {
      type: 'checkbox',
      label: 'Include Deleted/Cancelled/No Show/Not Performed',
    },
    whichLeg: {
      type: 'select',
      label: 'Which Leg They Were Seen For',
      options: ['ANY', 'Their left leg', 'Their right leg', 'Both legs'],
    },
    assessment: {
      type: 'multi',
      label: 'Assessment',
      items: [
        { id: 1, value: 'Lymphedema' },
        { id: 2, value: 'Sclerotherapy' },
        { id: 3, value: 'Venous Insufficiency' },
        { id: 4, value: 'Other' },
      ],
      getOptionLabel: type => type.value,
    },
    problematicLegs: {
      type: 'multi',
      label: 'Problematic Legs',
      items: [
        { id: 1, value: 'Left leg' },
        { id: 2, value: 'Right leg' },
      ],
      getOptionLabel: type => type.value,
    },
    swellingEdemaLeg: {
      type: 'select',
      label: 'Which Leg(s) Has Swelling and Edema',
      options: ['ANY', 'Left leg', 'Right leg', 'Both legs'],
    },
    spiderVeins: {
      type: 'select',
      label: 'Spider Veins',
      options: ['ANY', 'Left leg', 'Right leg', 'Both legs'],
    },
    reticularVeins: {
      type: 'select',
      label: 'Reticular Veins',
      options: ['ANY', 'Left leg', 'Right leg', 'Both legs'],
    },
    recommendationLeg: {
      type: 'multi',
      label: 'Recommendation',
      items: [
        { id: 1, value: 'Right leg' },
        { id: 2, value: 'Left leg' },
      ],
      getOptionLabel: type => type.value,
    },
    issuesRight: {
      type: 'multi',
      label: 'Right Leg Issues',
      items: [
        { id: 1, value: 'Varicose veins' },
        { id: 2, value: 'Spider veins' },
        { id: 3, value: 'Signs of corona phelebectatica' },
        { id: 4, value: 'Signs of lipodermatosclerosis' },
        { id: 5, value: 'A lower leg ulceration' },
      ],
      getOptionLabel: type => type.value,
    },
    varicoseRight: {
      type: 'multi',
      label: 'Right Leg Varicose Veins',
      items: [
        { id: 1, value: 'Above knee anterior medial' },
        { id: 2, value: 'Above knee anterior lateral' },
        { id: 3, value: 'Above knee posterior medial' },
        { id: 4, value: 'Above knee posterior lateral' },
        { id: 5, value: 'Below knee anterior medial' },
        { id: 6, value: 'Below knee anterior lateral' },
        { id: 7, value: 'Below knee posterior medial' },
        { id: 8, value: 'Below knee posterior lateral' },
      ],
      getOptionLabel: type => type.value,
    },
    lowerUlcerationRight: {
      type: 'select',
      label: 'Right Leg Lower Ulceration',
      options: ['ANY', 'Medial', 'Lateral'],
    },
    lowerUlcerationMedialRight: {
      type: 'select',
      label: 'Right Leg Lower Ulceration - Medial',
      options: ['ANY', 'Active', 'Healed'],
    },
    lowerUlcerationLateralRight: {
      type: 'select',
      label: 'Right Leg Lower Ulceration - Lateral',
      options: ['ANY', 'Active', 'Healed'],
    },
    ceapRight: {
      type: 'select',
      label: 'Right Leg CEAP',
      options: ['ANY', 'C0', 'C1', 'C2', 'C3', 'C4a', 'C4b', 'C5', 'C6'],
    },
    issuesLeft: {
      type: 'multi',
      label: 'Left Leg Issues',
      items: [
        { id: 1, value: 'Varicose veins' },
        { id: 2, value: 'Spider veins' },
        { id: 3, value: 'Signs of corona phelebectatica' },
        { id: 4, value: 'Signs of lipodermatosclerosis' },
        { id: 5, value: 'A lower leg ulceration' },
      ],
      getOptionLabel: type => type.value,
    },
    varicoseLeft: {
      type: 'multi',
      label: 'Left Leg Varicose Veins',
      items: [
        { id: 1, value: 'Above knee anterior medial' },
        { id: 2, value: 'Above knee anterior lateral' },
        { id: 3, value: 'Above knee posterior medial' },
        { id: 4, value: 'Above knee posterior lateral' },
        { id: 5, value: 'Below knee anterior medial' },
        { id: 6, value: 'Below knee anterior lateral' },
        { id: 7, value: 'Below knee posterior medial' },
        { id: 8, value: 'Below knee posterior lateral' },
      ],
      getOptionLabel: type => type.value,
    },
    lowerUlcerationLeft: {
      type: 'select',
      label: 'Left Leg Lower Ulceration',
      options: ['ANY', 'Medial', 'Lateral'],
    },
    lowerUlcerationMedialLeft: {
      type: 'select',
      label: 'Left Leg Lower Ulceration - Medial',
      options: ['ANY', 'Active', 'Healed'],
    },
    lowerUlcerationLateralLeft: {
      type: 'select',
      label: 'Left Leg Lower Ulceration - Lateral',
      options: ['ANY', 'Active', 'Healed'],
    },
    ceapLeft: {
      type: 'select',
      label: 'Left Leg CEAP',
      options: ['ANY', 'C0', 'C1', 'C2', 'C3', 'C4a', 'C4b', 'C5', 'C6'],
    },
    recommendationRight: {
      type: 'multi',
      label: 'Recommendation - Right Leg',
      items: [
        { id: 1, value: 'Endovenous laser ablation' },
        { id: 2, value: 'Endovenous laser ablation with mini phlebectomy' },
        { id: 3, value: 'Non-thermal ablation such as Clarivein' },
        { id: 4, value: 'Non-thermal ablation such as Venaseal' },
        { id: 5, value: 'Compression sclerotherapy' },
        { id: 6, value: 'Preferably non-thermal ablation - Clarivein or Venaseal' },
        { id: 7, value: 'Medical Compression Stockings 20-30 mm HG' },
      ],
      getOptionLabel: type => type.value,
    },
    recommendationLeft: {
      type: 'multi',
      label: 'Recommendation - Left Leg',
      items: [
        { id: 1, value: 'Endovenous laser ablation' },
        { id: 2, value: 'Endovenous laser ablation with mini phlebectomy' },
        { id: 3, value: 'Non-thermal ablation such as Clarivein' },
        { id: 4, value: 'Non-thermal ablation such as Venaseal' },
        { id: 5, value: 'Compression sclerotherapy' },
        { id: 6, value: 'Preferably non-thermal ablation - Clarivein or Venaseal' },
        { id: 7, value: 'Medical Compression Stockings 20-30 mm HG' },
      ],
      getOptionLabel: type => type.value,
    },
    doctorRecommendationRight: {
      type: 'multi',
      label: 'Doctor Recommendation - Right Leg',
      items: [
        { id: 1, value: 'Endovenous laser ablation' },
        { id: 2, value: 'Endovenous laser ablation with mini phlebectomy' },
        { id: 3, value: 'Non-thermal ablation such as Clarivein' },
        { id: 4, value: 'Non-thermal ablation such as Venaseal' },
        { id: 5, value: 'Compression sclerotherapy' },
        { id: 6, value: 'Preferably non-thermal ablation - Clarivein or Venaseal' },
        { id: 7, value: 'Medical Compression Stockings 20-30 mm HG' },
        { id: 8, value: 'Additional Recommendations' },
      ],
      getOptionLabel: type => type.value,
    },
    doctorRecommendationLeft: {
      type: 'multi',
      label: 'Doctor Recommendation - Left Leg',
      items: [
        { id: 1, value: 'Endovenous laser ablation' },
        { id: 2, value: 'Endovenous laser ablation with mini phlebectomy' },
        { id: 3, value: 'Non-thermal ablation such as Clarivein' },
        { id: 4, value: 'Non-thermal ablation such as Venaseal' },
        { id: 5, value: 'Compression sclerotherapy' },
        { id: 6, value: 'Preferably non-thermal ablation - Clarivein or Venaseal' },
        { id: 7, value: 'Medical Compression Stockings 20-30 mm HG' },
        { id: 8, value: 'Additional Recommendations' },
      ],
      getOptionLabel: type => type.value,
    },
  };

  // All labels here are a 1 - 1 corresondence with the API
  const reportFilters = {
    0: [],
    1: [
      'Date',
      'appointmentTypes',
      'staffs',
      'creatingStaffs',
      'locations',
      'hasReferral',
      'hasProcedure',
      'patientType',
      'includeDeleted',
    ],
    2: [
      'Date',
      'ultrasoundTypes',
      'staffs',
      'locations',
      'hasReferral',
      'hasProcedure',
      'patientType',
      'nextAppointment',
      'includeDeleted',
    ],
    3: ['Date', 'procedureTypes', 'staffs', 'creatingStaffs', 'locations', 'includeDeleted'],
    4: [
      'Date',
      'consultationNPTypes',
      'staffs',
      'locations',
      'bookingStatus',
      'procedureTypes',
      'doctorRecommendationRight',
      'doctorRecommendationLeft',
      'includeDeleted',
    ],
    5: ['Date', 'appointmentTypes', 'staffs', 'locations', 'billingStatus', 'includeDeleted'],
    6: ['Date', 'appointmentTypes', 'locations', 'includeDeleted'],
    7: [],
    8: [
      'Date',
      'hearAboutUs',
      'symptoms',
      'symptomLength',
      'medicalHistory',
      'gender',
      'mainConcern',
      'endOfDayWorse',
      'triedStockings',
      'historyOfVaricoseVeins',
      'knownAllergies',
      'bloodClots',
      'treatedForVaricoseVeins',
      'takingBloodThinners',
      'hasProcedure',
      'hasConsultation',
      'procedures',
      'consultations',
    ],
    9: ['DateOfReceipt', 'DateOfService', 'receiptFor'],
    10: ['DateOfReceipt', 'DateOfService', 'taxRegions', 'receiptFor'],
    11: ['Date', 'locations', 'staffs'],
    12: [
      'Date',
      'creatingStaffs',
      'locations',
      'symptomsImproved',
      'cosmeticResultsImprovedVaricoseVeins',
      'cosmeticResultsImprovedspiderReticularVeins',
      'satisfaction',
      'treatmentRecommendation',
      'recommendedByMdOrNurse',
      'charge',
      'hasCompressionStockings',
      'recommendedFUp',
    ],
    13: [
      'Date',
      'assessment',
      'whichLeg',
      'problematicLegs',
      'swellingEdemaLeg',
      'spiderVeins',
      'reticularVeins',
      'recommendationLeg',
      'issuesRight',
      'varicoseRight',
      'lowerUlcerationRight',
      'lowerUlcerationMedialRight',
      'lowerUlcerationLateralRight',
      'ceapRight',
      'issuesLeft',
      'varicoseLeft',
      'lowerUlcerationLeft',
      'lowerUlcerationMedialLeft',
      'lowerUlcerationLateralLeft',
      'ceapLeft',
      'recommendationRight',
      'recommendationLeft',
    ],
    14: [
      'Date',
      'SecondDate',
      'appointmentTypes',
      'staffs',
      'creatingStaffs',
      'locations',
      'hasReferral',
      'hasProcedure',
      'patientType',
      'includeDeleted',
    ],
  };

  const areDateFieldsValid = () => {
    const isGeneralMaxMinDateEmpty = !selectedFilters.maxDate || !selectedFilters.minDate;
    const isSecondMaxMinDateEmpty =
      !selectedFilters.maxSecondDate || !selectedFilters.minSecondDate;
    const isDateOfServiceMaxMinDateEmpty =
      !selectedFilters.minDateOfService || !selectedFilters.maxDateOfService;
    const isDateOfReceiptMaxMinDateEmpty =
      !selectedFilters.minDateOfReceipt || !selectedFilters.minDateOfReceipt;

    if (selectedReport?.id == 7) {
      // Empty Report
      return true;
    } else if (selectedReport?.id && reportFilters[selectedReport?.id].includes('SecondDate')) {
      // Ensures Both Fields Are Not Empty
      return !isGeneralMaxMinDateEmpty && !isSecondMaxMinDateEmpty;
    } else {
      return (
        !isGeneralMaxMinDateEmpty ||
        !isDateOfServiceMaxMinDateEmpty ||
        !isDateOfReceiptMaxMinDateEmpty
      );
    }
  };

  const localCreateReport = async () => {
    setReportSearched(false);
    resetReport();
    const validDateFields = areDateFieldsValid();

    if (!validDateFields || !selectedReport) {
      setOpenAlertDialog(true);
      return;
    }

    await createReport({
      reportId: selectedReport.id,
      filters: selectedFilters,
    });
    setReportSearched(true);
  };

  const locationsGetter = async () => {
    setLocations(await getLocations());
  };

  const checkForSame = (startDate: string, endDate: string) => {
    if (!startDate || !endDate) return false;
    const sameMonth = moment(startDate).isSame(endDate, 'month');
    const startOfMonth = moment(startDate).isSame(moment(startDate).startOf('month'), 'day');
    const endOfMonth = moment(endDate).isSame(moment(endDate).endOf('month'), 'day');
    return sameMonth && startOfMonth && endOfMonth;
  };

  const getMonth = (date: string) => {
    const month = moment(date).get('month');
    return MONTHS.find(m => m.id == month);
  };

  const getYear = (date: string) => {
    return moment(date).get('year');
  };

  // Opens or Closes the appropriate 'End Date' Datepicker depending on the key specified
  const setDatePickerOpen = (shouldOpen: boolean, key: string) => {
    if (key == 'Date') {
      setOpenEndDatePicker(shouldOpen);
    } else if (key == 'SecondDate') {
      setOpenSecondEndDatePicker(shouldOpen);
    }
  };

  useEffect(() => {
    fetchTypes();
    fetchReceiptTypes();
    locationsGetter();
  }, []);

  useEffect(() => {
    setSelectedFilters({});
    setReportSearched(false);
    resetReport();
  }, [selectedReport]);

  return (
    <Layout hideFooter>
      <div className={styles.container}>
        <Select
          label="Report Types"
          name={'reportTypes'}
          value={selectedReport?.type}
          onChange={e => setSelectedReport(types.find(type => e.target.value === type.id))}
          fullWidth
        >
          {types.map(item => (
            <MenuItem key={item.id} value={item.id}>
              {item.type}
            </MenuItem>
          ))}
        </Select>
        <div className={styles.filter}>
          {selectedFilters &&
            selectedReport?.id &&
            reportFilters[selectedReport?.id] &&
            reportFilters[selectedReport?.id].map(key => {
              switch (filters[key].type) {
                case 'multi':
                  return (
                    <Autocomplete
                      label={filters[key].label}
                      name={filters[key].label}
                      items={filters[key].items}
                      value={selectedFilters[key] || []}
                      getOptionLabel={filters[key].getOptionLabel}
                      onChange={(event, newValue) => {
                        setFiltering(key, newValue);
                      }}
                      multiple
                      fullWidth
                    />
                  );
                case 'multiPreset':
                  return (
                    <div className={styles.multiPreset}>
                      <Autocomplete
                        label={filters[key].label}
                        name={filters[key].label}
                        items={filters[key].items}
                        value={selectedFilters[key] || []}
                        getOptionLabel={filters[key].getOptionLabel}
                        onChange={(event, newValue) => {
                          setFiltering(key, newValue);
                        }}
                        multiple
                        fullWidth
                      />
                      <div className={styles.multiPresetButtons}>
                        {filters[key].buttons.map((button, index) => (
                          <Button
                            key={index}
                            onClick={() =>
                              setFiltering(
                                key,
                                (selectedFilters[key] || []).concat(
                                  button.selectItems.filter(
                                    item => !selectedFilters[key]?.includes(item)
                                  )
                                )
                              )
                            }
                          >
                            {button.label}
                          </Button>
                        ))}
                      </div>
                    </div>
                  );
                case 'select':
                  return (
                    <Select
                      label={filters[key].label}
                      name={filters[key].label}
                      value={selectedFilters[key] || 0}
                      onChange={({ target }) => setFiltering(key, target.value)}
                      className={styles.smallSelect}
                    >
                      {filters[key].options.map((item, index: number) => (
                        <MenuItem key={item} value={index}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  );
                case 'checkbox':
                  return (
                    <Checkbox
                      title={filters[key].label}
                      onChange={({ target }) => setFiltering(key, target.checked)}
                    />
                  );
                case 'date':
                  return (
                    <div>
                      <div className={styles.monthYear}>
                        <Select
                          label={'Month'}
                          name={'month'}
                          fullWidth
                          value={
                            checkForSame(selectedFilters['min' + key], selectedFilters['max' + key])
                              ? getMonth(selectedFilters['min' + key])?.id
                              : ''
                          }
                          onChange={({ target }) => {
                            const month = MONTHS.find(month => month.id == target.value);
                            if (!month) return;
                            const minDate = moment(selectedFilters['min' + key])
                              .set('month', month.id)
                              .startOf('month');
                            const maxDate = moment(selectedFilters['max' + key])
                              .set('month', month.id)
                              .endOf('month');

                            setFiltering(
                              'min' + key,
                              minDate.format(DateTimeFormatString.APIDateFormat)
                            );
                            setFiltering(
                              'max' + key,
                              maxDate.format(DateTimeFormatString.APIDateFormat)
                            );
                          }}
                          className={styles.smallSelect}
                        >
                          {MONTHS.map((item, index: number) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.month}
                            </MenuItem>
                          ))}
                        </Select>
                        <Select
                          label={'Year'}
                          name={'year'}
                          fullWidth
                          value={
                            checkForSame(selectedFilters['min' + key], selectedFilters['max' + key])
                              ? `${getYear(selectedFilters['min' + key])}`
                              : ''
                          }
                          onChange={({ target }) => {
                            const minDate = moment(selectedFilters['min' + key])
                              .set('year', target.value)
                              .startOf('month');
                            const maxDate = moment(selectedFilters['max' + key])
                              .set('year', target.value)
                              .endOf('month');

                            setFiltering(
                              'min' + key,
                              minDate.format(DateTimeFormatString.APIDateFormat)
                            );
                            setFiltering(
                              'max' + key,
                              maxDate.format(DateTimeFormatString.APIDateFormat)
                            );
                          }}
                          className={styles.smallSelect}
                        >
                          {YEARS.map((item, index: number) => (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                      <div className={styles.dateFilter}>
                        <p>{filters[key].label}</p>
                        <DateRange />
                        <DatePicker
                          orientation={'portrait'}
                          variant={'dialog'}
                          openTo="date"
                          format="DD  MMM YYYY"
                          autoOk
                          value={selectedFilters['min' + key] || null}
                          onChange={value => {
                            setFiltering(
                              'min' + key,
                              formatDate(DateTimeFormat.APIDateFormat, value)
                            );
                            setDatePickerOpen(true, key);
                          }}
                        />
                        <span>—</span>
                        <DateRange />
                        <DatePicker
                          open={key == 'Date' ? openEndDatePicker : openSecondEndDatePicker}
                          orientation={'portrait'}
                          variant={'dialog'}
                          openTo="date"
                          format="DD  MMM YYYY"
                          value={selectedFilters['max' + key] || null}
                          onOpen={() => setDatePickerOpen(true, key)}
                          onClose={() => setDatePickerOpen(false, key)}
                          autoOk
                          onChange={value => {
                            setFiltering(
                              'max' + key,
                              formatDate(DateTimeFormat.APIDateFormat, value)
                            );
                            setDatePickerOpen(false, key);
                          }}
                        />
                      </div>
                    </div>
                  );
              }
            })}
        </div>
        <div className={styles.submissionComponents}>
          <CVCButton className={styles.Button} onClick={localCreateReport}>
            <div className={styles.getReportButton}>Get Report</div>
          </CVCButton>
          {reportSearched && (
            <div className={styles.total}>
              {' '}
              {report.length ? (
                <>
                  <span>Total: </span>
                  {totalResults}
                </>
              ) : (
                <>{'No Results Found'}</>
              )}{' '}
            </div>
          )}
          <CSVDownload
            headers={csvHeaders}
            values={report}
            disabled={!report.length}
            filename={`${selectedReport?.type}-${moment().format(
              DateTimeFormatString.APIDateFormat
            )}`}
          />
        </div>
      </div>
      <AlertDialog
        open={openAlertDialog}
        onClose={() => setOpenAlertDialog(false)}
        title="Missing Information"
        message="Please select a Report Type and a Date Range"
      />
    </Layout>
  );
}

export default Reports;
