import React, { ReactElement } from 'react';

import Header from './Header';
import Footer from './Footer';
import ContentWrapper from './ContentWrapper';

import styles from './LayoutStyles.module.scss';

export interface LayoutPresentationProps {
  children: React.ReactNode;
  hideHeader?: boolean;
  hideFooter?: boolean;
}

function LayoutPresentation({
  children,
  hideHeader,
  hideFooter,
}: LayoutPresentationProps): ReactElement {
  return (
    <div className={styles.main}>
      {!hideHeader && <Header />}
      <ContentWrapper>{children}</ContentWrapper>
      {!hideFooter && <Footer />}
    </div>
  );
}

export default LayoutPresentation;
