import { calculatePatientResultsMap } from './lib/calculatePatientResults';
import { CaseResult } from 'types';

interface PatientResultMapProps {
  answersMap: any;
  formCaseResult: CaseResult | null;
}

export function PatientResultMap({ answersMap, formCaseResult }: PatientResultMapProps) {
  return {
    answersMap,
  };
}

interface PatientResultsProps {
  formCaseResult: CaseResult | null;
}

export const usePatientResults = ({ formCaseResult }: PatientResultsProps) => {
  const answersMap = calculatePatientResultsMap(formCaseResult?.answers);

  return PatientResultMap({
    answersMap,
    formCaseResult,
  });
};

export default usePatientResults;
