import React, { ReactElement } from 'react';
import { useFormik } from 'formik';
import jwtDecode from 'jwt-decode';

import { UserApi } from 'api';
import ResetPassword from './ResetPassword.page';
import resetPasswordValidator from './utils/resetPassword.validator';

interface DecodedToken {
  email: string;
}

function ResetPasswordContainer(props): ReactElement {
  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validate: resetPasswordValidator,
    onSubmit: async values => {
      try {
        const token = props?.match?.params?.jwtToken;
        const decoded: DecodedToken = jwtDecode(token);

        await UserApi.resetPasswordFromLink(decoded.email, values.password, token);

        props?.history.replace('/');
      } catch (e) {
        if (e.response?.status === 404) formik.setErrors({ password: 'link is not valid' });
        else formik.setErrors({ password: e.response?.data?.[0].msg });
      }
    },
  });

  return (
    <ResetPassword
      value={formik.values}
      errors={formik.errors}
      loading={formik.isSubmitting}
      onChange={formik.handleChange}
      onSubmit={formik.handleSubmit}
    />
  );
}

export default ResetPasswordContainer;
