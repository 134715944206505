import React, { createContext, ReactElement, ReactNode, useState } from 'react';
import { ACCESS_TOKEN, REFRESH_TOKEN } from 'lib/const';
import { ROLE } from 'lib/user';

export interface AuthData {
  role: string;
  setRole: (role: string) => void;
  logout(): void;
}

interface AuthProp {
  children: ReactNode;
}

const initialState = {
  role: ROLE.ANONYMOUS,
  setRole: (role: string) => null,
  logout: () => null,
};

const Context = createContext<AuthData>(initialState);

const { Provider, Consumer } = Context;

function AuthProvider({ children }: AuthProp): ReactElement {
  const [role, setRole] = useState(ROLE.ANONYMOUS);

  function logout(): void {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    setRole(ROLE.ANONYMOUS);
  }

  return <Provider value={{ role, setRole, logout }}>{children}</Provider>;
}

export { ROLE, Context, Consumer, AuthProvider as Provider };
