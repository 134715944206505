import { LocationListItem } from 'types';
import { Location } from 'types';

export default function createLocationsRowFromLocations(locations: Location[]): LocationListItem[] {
  const locationRow: LocationListItem[] = [];

  if (locations) {
    locations.forEach(location => {
      let email = '';
      let phone = '';
      let address = '';
      let province = '';

      const phoneContacts = location.contacts?.filter(
        contact => contact.type === 'office' || contact.type === 'phone'
      );
      if (phoneContacts && phoneContacts.length > 0) {
        phone = phoneContacts[0].value;
      }

      const emailContacts = location.contacts?.filter(contact => contact.type === 'email');
      if (emailContacts && emailContacts.length > 0) {
        email = emailContacts[0].value;
      }

      if (location.addresses && location.addresses.length > 0) {
        address = `${location.addresses[0].line1}, ${location.addresses[0].city}`;
        province = location.addresses[0].province;
      }

      const locationListItem: LocationListItem = {
        id: location.id,
        email,
        name: location.name,
        phone,
        address,
        scheduleTypes: location.scheduleTypes,
        province,
      };

      locationRow.push(locationListItem);
    });
  }
  return locationRow;
}
