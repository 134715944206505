import React from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { ReactComponent as Polygon } from 'assets/svgs/Polygon.svg';

interface Data {
  date: string;
  type: string;
  method: string;
  sentBy: { email?: string; fullName?: string };
  sentTo: { email?: string; fullName?: string };
  title: string;
  status: string;
}

interface HeadCell {
  id: keyof Data;
  label: string;
}

const headCells: HeadCell[] = [
  { id: 'date', label: 'Date' },
  { id: 'title', label: 'Content' },
  { id: 'type', label: 'Type' },
  { id: 'sentBy', label: 'Sender Name' },
  { id: 'sentTo', label: 'Receiver' },
  { id: 'status', label: 'Status' },
];

interface EnhancedTableProps {
  classes: any;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes } = props;

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            className={headCell.id === 'date' ? classes.cellColored : classes.cell}
            key={headCell.id}
            align={'left'}
            padding={'default'}
          >
            {headCell.label}
            {headCell.id === 'date' && <Polygon className={classes.polygon} />}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default EnhancedTableHead;
