import React, { ReactElement, useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import styles from './AppointmentInfo.module.scss';
import { DailyAppointment, UserTypes } from 'types';
import { IconButton } from '@material-ui/core';
import { ReactComponent as EditIcon } from 'assets/svgs/EditIcon.svg';
import { ReactComponent as RubishIcon } from 'assets/svgs/Rubish.svg';
import classNames from 'classnames';
import { AppointmentStatus, getAppointmentStatusNameById, SLOT_LENGTH } from 'lib/appointments';
import formatPhoneInput from 'lib/phoneNumFormatter';
import { DateTimeFormatString } from 'lib/dateFormatter';
import { convertTo12From24 } from 'lib/schedule';
import { DOCUMENT_TYPES, REFERRAL_DOCUMENT_VALID_MONTHS } from 'lib/document';

interface AppointmentInfoProps {
  indexedTimes: Array<{ id: number; time: string }>;
  appointment: DailyAppointment;
  types?: Array<any>;
  filterAppointmentStatus?: number | null;
  filterAppointmentType?: string | number | null;
  filterSelectedStaff?: number | null | undefined;
  highlightOnlyCancellationAppts?: boolean;
  reschedulingAppointment?: boolean;
  patientPreset?: boolean;
  past72HoursAppointment?: boolean;
  goToAppointmentId?: number | undefined;
  readOnly?: boolean;
  date?: Moment;
  selectedLocationId?: number;
  setEventToDelete?: (event) => void;
  appointmentDate?: Moment;
  onUpdateAppointmentSuccess?: () => void;
  editAppointment?: (appt) => void;
  setResponseAppt?: (appointment) => void;
  onUpdateApptStatus?: (appointment) => void;
  openStaffNotes?: (doctor) => void;
}

function AppointmentInfo({
  appointmentDate,
  indexedTimes,
  appointment,
  types,
  filterAppointmentStatus,
  filterAppointmentType,
  filterSelectedStaff,
  highlightOnlyCancellationAppts,
  reschedulingAppointment,
  patientPreset = false,
  goToAppointmentId,
  readOnly,
  date,
  selectedLocationId,
  setEventToDelete,
  onUpdateAppointmentSuccess,
  editAppointment,
  setResponseAppt,
  onUpdateApptStatus,
  openStaffNotes,
  past72HoursAppointment,
}: AppointmentInfoProps): ReactElement {
  const [filtered, setFiltered] = useState<boolean>(false);
  const [highlighted, setHighlighted] = useState<boolean>(false);

  const onEdit = () => {
    if (editAppointment) editAppointment(appointment);
  };

  function onConfirmDelete() {
    if (onUpdateAppointmentSuccess) onUpdateAppointmentSuccess();
    appointment.appointmentStatus = AppointmentStatus.Deleted;
    if (setEventToDelete) setEventToDelete(appointment);
    if (setResponseAppt) setResponseAppt(appointment);
  }

  const converTimeToOffset = (indexedTimes, appointmentStartTime) => {
    const indexOffset = indexedTimes.find(time => {
      return time.time === appointmentStartTime;
    });
    const heightOfBlock = 15;
    return indexOffset.id * heightOfBlock + 1; // +1 to better line up appointments to have non-overlapping borders
  };

  const convertSlotLengthToSize = (indexedSlotLength, appointmentSlotLength) => {
    const indexSize = indexedSlotLength.find(slotLength => {
      return slotLength.id == appointmentSlotLength;
    });
    const heightOfBlock = 15;
    const offsetHeight = indexSize.offsetSpaceUse * heightOfBlock;
    return offsetHeight;
  };

  const getSubType = () => {
    return appointment.appointmentSubTypeName ? appointment.appointmentSubTypeName : '';
  };

  const getCompletedForms = () => {
    return appointment.patient?.forms
      ? appointment.patient?.forms.filter(form => !!form.completed_at)
      : [];
  };

  const checkForReferralDoc = () => {
    return appointment?.patient?.documents.some(document => {
      if (!appointmentDate) return false;
      const referral = document.type == DOCUMENT_TYPES.REFERRAL;
      const referralValidMonths =
        appointmentDate.diff(document.uploadedAt, 'months') <= REFERRAL_DOCUMENT_VALID_MONTHS;
      const referralBeforeAppt = appointmentDate.diff(document.uploadedAt, 'days') >= 0;

      return referral && referralValidMonths && referralBeforeAppt;
    });
  };

  useEffect(() => {
    if (types) {
      const subType = types.find(appType => appType.id == filterAppointmentType);
      let subTypeName = types.find(appType => appType.id == filterAppointmentType)?.name;
      if (!subTypeName) {
        subTypeName = 'None';
      }
      setHighlighted(false);
      if (
        // if appointmentStatus filter is enabled and does not match this appointment
        filterAppointmentStatus &&
        filterAppointmentStatus != -1 &&
        filterAppointmentStatus != Number(appointment.appointmentStatus)
      ) {
        setFiltered(true);
      } else if (
        // if appointmentType filter is enabled and does not match this appointment, and does not match this appt subType
        filterAppointmentType &&
        filterAppointmentType != -1 &&
        appointment.appointmentSubTypeId !== subType?.id
      ) {
        setFiltered(true);
      } else if (
        // if selectedStaff filter is enabled and does not match this appointment
        filterSelectedStaff &&
        filterSelectedStaff != -1 &&
        filterSelectedStaff != appointment.doctor.id &&
        filterSelectedStaff != appointment.optionalTechnicianId &&
        filterSelectedStaff != appointment.optionalNurseId
      ) {
        setFiltered(true);
      } else if (
        // if cancellationList filter is enabled and does not match this appointment
        highlightOnlyCancellationAppts &&
        highlightOnlyCancellationAppts != appointment.appointmentCancellationList
      ) {
        setFiltered(true);
      } else {
        setFiltered(false);
        if (
          (filterAppointmentType && filterAppointmentType != -1) ||
          (filterAppointmentStatus && filterAppointmentStatus != -1) ||
          (filterSelectedStaff && filterSelectedStaff != -1) ||
          highlightOnlyCancellationAppts
        ) {
          setHighlighted(true);
        }
      }
    }
  }, [
    selectedLocationId,
    date,
    filterAppointmentStatus,
    filterAppointmentType,
    filterSelectedStaff,
    highlightOnlyCancellationAppts,
  ]);

  const getStylesKey = () => {
    switch (appointment.appointmentStatus) {
      case 1:
        return 'unconfirmed';
      case 2:
        return 'needsMoved';
      case 3:
        return 'confirmed';
      case 4:
        return 'cancelled';
      case 5:
        return 'arrived';
      case 6:
        return 'departed';
      case 7:
        return 'billed';
      case 8:
        return 'followup';
      case 9:
        return 'noShow';
      case 10:
        return 'notPerformed';
      default:
        return '';
    }
  };

  return (
    // (convert StartTime of appt as an index) * 30 (Height of Block)
    <div
      className={classNames(
        styles.appointmentInfoContainer,
        (filtered ||
          (goToAppointmentId && goToAppointmentId != appointment.appointmentId) ||
          reschedulingAppointment ||
          patientPreset) &&
          styles.filtered,
        (highlighted || (goToAppointmentId && goToAppointmentId === appointment.appointmentId)) &&
          styles.highlighted,
        appointment.appointmentInCancelledSchedule && styles.cancelled
      )}
      style={{
        top: converTimeToOffset(indexedTimes, appointment.appointmentStartTime),
        minHeight: convertSlotLengthToSize(SLOT_LENGTH, appointment.appointmentSlotLength),
        height: convertSlotLengthToSize(SLOT_LENGTH, appointment.appointmentSlotLength),
        position: 'absolute',
        width: '100%',
        overflowY: 'hidden',
        overflowX: 'hidden',
      }}
    >
      <div className={styles.appointmentInfoFlexRow}>
        <div className={styles.appointmentInfoFlexColumn}>
          <p className={styles.appointmentHours}>
            {convertTo12From24(appointment.appointmentStartTime)} -{' '}
            {convertTo12From24(appointment.appointmentEndTime)}
          </p>
          <p className={styles.appointmentType}>{getSubType()}</p>
        </div>
        <div className={styles.appointmentInfoBorder} />
        <div className={styles.appointmentInfoFlexColumn}>
          <button
            className={styles.appointmentStatusButton}
            onClick={() => {
              onUpdateApptStatus?.(appointment);
            }}
          >
            <p className={classNames(styles.appointmentStatus, styles[getStylesKey()])}>
              {getAppointmentStatusNameById(appointment?.appointmentStatus)}
            </p>
          </button>
          <button
            className={styles.appointmentStaffNotes}
            onClick={() => {
              openStaffNotes?.(appointment.doctor);
            }}
          >
            <p className={styles.appointmentDoctor}>{appointment.doctor.fullName}</p>
          </button>
        </div>
        <div className={styles.appointmentInfoBorder} />
        <div className={styles.appointmentInfoFlexColumn}>
          <a
            href={'/patient/' + appointment.patient.id}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.appointmentPatient}
          >
            {appointment.patient.fullName}
          </a>
          <p className={styles.appointmentPhoneNumber}>
            {formatPhoneInput(appointment.patient.phoneNumber)}
          </p>
          <p className={styles.appointmentPhoneNumber}>
            {moment(appointment.patient.dateOfBirth)?.format(DateTimeFormatString.APIDateFormat)}
          </p>
        </div>
        <div className={styles.appointmentInfoBorder} />
        <div className={styles.appointmentInfoFlexColumn}>
          <p className={styles.appointmentNumForms}>
            Forms Not Completed:{' '}
            {appointment.patient?.forms
              ? appointment.patient.forms?.filter(arr => !arr.completed_at).length
              : 0}
          </p>
          <div>
            <p className={styles.appointmentNumDocuments}>
              Documents:{' '}
              {appointment.patient?.documents ? appointment.patient.documents?.length : 0}
            </p>
            <p className={checkForReferralDoc() ? styles.hasReferralDoc : styles.noReferralDoc}>
              Referral Document
            </p>
          </div>
          <p className={styles.appointmentNumDocuments}>
            {appointment.referralDoctor.fullName
              ? `Referral Doctor: ${appointment.referralDoctor.fullName}`
              : ''}
          </p>
        </div>

        {!readOnly && !past72HoursAppointment ? (
          <>
            <div className={styles.appointmentInfoBorder} />
            {!!appointment.appointmentCancellationList && (
              <div className={styles.appointmentInCancellationListLabel}>{'C'}</div>
            )}
            <IconButton
              aria-label="edit"
              className={styles.edit}
              onClick={onEdit}
              disabled={reschedulingAppointment || patientPreset}
            >
              <EditIcon title={'edit'} />
            </IconButton>
            <IconButton
              aria-label="delete"
              className={styles.delete}
              onClick={onConfirmDelete}
              disabled={reschedulingAppointment || patientPreset}
            >
              <RubishIcon title={'delete'} />
            </IconButton>
          </>
        ) : (
          <></>
        )}
      </div>
      <div className={styles.appointmentNotes}>Notes: {appointment.appointmentNotes}</div>
      <div className={styles.appointmentFormsAndDocs}>
        <div className={styles.appointmentForms}>
          Forms:{' '}
          {getCompletedForms().map(form => {
            return (
              <div key={form.id} className={styles.formCompleted}>
                {form.description}
              </div>
            );
          })}
        </div>
        <div className={styles.appointmentInfoBorder} />
        <div className={styles.appointmentDocuments}>
          Documents:{' '}
          {appointment.patient?.documents?.map(doc => {
            return (
              <a key={doc.id} className={styles.document} href={doc.s3_url}>
                {doc.title}
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default AppointmentInfo;
