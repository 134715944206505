import React, { ReactElement, useState, useEffect } from 'react';
import { Section, QuestionTemplateAnswer, QuestionTemplate, User } from 'types';
import Link from '@material-ui/core/Link';
import classNames from 'classnames';
import _ from 'lodash';

import styles from './FormStyles.module.scss';

import ConditionDiv from 'components/ConditionDiv';
import DialogButton from 'components/DialogButton';

import QuestionSection from './components/QuestionSection';
import PreviewPanel from './components/PreviewPanel';
import Layout from 'Layout';
import useFormQuestions from './useFormQuestions.hook';
import Dialog from 'components/Dialog';
import { FormDescriptions, formTemplateQuestionIds } from 'lib/form';
import Select from 'components/Select';
import { MenuItem } from '@material-ui/core';
import Checkbox from 'components/Checkbox';
import { CVC_FAX_NUMBER } from 'lib/const';
import Button from '@material-ui/core/Button';
import { MATZ_BILLING_NUMBERS, MATZ_ID } from 'lib/user';

interface PatientFormProps {
  description?: string;
  sections: Array<Section>;
  onCancel(): void;
  onSave(answers): void;
  downloadSignatureFile?(s3Key, doctorId): void;
  formId?: string | number;
  firstName?: string;
  lastName?: string;
  doctors?: Array<User>;
  needsDoctorSignature?: boolean;
  addSignature?: boolean;
  signatureFound?: boolean;
  setAddSignature?: (addSignature: boolean) => void;
}

export interface AnswerValueType {
  id?: string | number;
  value: string;
  questionOptionId?: number | null;
}

interface OnChangeAnswerProps {
  values: Array<AnswerValueType>;
  templateQuestionId: number;
}

interface VisibleQuestionsProps {
  question;
  currentTabAnswers;
  answers;
  currentTab;
  checkIfQuestionIsShown: (question, currentTabAnswers) => boolean;
  resetAnswersForHiddenQuestions: (question, currentTabAnswers, answers) => void;
  onChangeAnswer;
}

const VisibleQuestions = ({
  question,
  currentTabAnswers,
  answers,
  currentTab,
  checkIfQuestionIsShown,
  resetAnswersForHiddenQuestions,
  onChangeAnswer,
}: VisibleQuestionsProps) => {
  const showQuestion = checkIfQuestionIsShown(question, currentTabAnswers);

  if (!showQuestion) {
    resetAnswersForHiddenQuestions(question, currentTabAnswers, answers);
    return <></>;
  }

  const optionCapitalize = option => {
    return option.charAt(0).toUpperCase() + option.slice(1);
  };

  return (
    <QuestionSection
      key={question.templateQuestionId}
      variant={question.type}
      title={question.title}
      required={question.required}
      options={question.options}
      description={question.description}
      value={answers[currentTab]?.get(question.templateQuestionId) || []}
      onChange={values => {
        onChangeAnswer({ values, templateQuestionId: question.templateQuestionId });
      }}
      optionCapitalize={optionCapitalize}
      defaultValue={
        question.answers?.length
          ? question.answers
          : question.default_value
          ? [question.default_value]
          : []
      }
    />
  );
};

function Form({
  description,
  sections,
  onCancel,
  onSave,
  downloadSignatureFile,
  doctors,
  formId,
  firstName,
  lastName,
  needsDoctorSignature,
  addSignature,
  signatureFound,
  setAddSignature,
}: PatientFormProps): ReactElement {
  // The first Array entry is the current Tab the answer should be on.
  const [answers, setAnswers] = useState<Array<Map<number, QuestionTemplateAnswer[]>>>([new Map()]);
  const [currentTab, setCurrentTab] = useState(0);
  const [isSaveErrorDialogOpen, setSaveErrorDialogOpen] = useState<boolean>(false);
  const [errorDialogMessage, setErrorDialogMessage] = useState<string>();

  const [selectedDoctor, setSelectedDoctor] = useState<User>();

  const [selectedMatzLocation, setSelectedMatzLocation] = useState<string>();
  const [matzPopupVisible, setMatzPopupVisible] = useState<boolean>(false);

  const {
    resetAnswersOnChange,
    questions,
    resetAnswersForHiddenQuestions,
    checkIfQuestionIsShown,
  } = useFormQuestions(sections, answers[currentTab], setAnswers);

  // Ensure correct amount of answer sections exist
  useEffect(() => {
    if (answers.length !== sections.length) {
      const newAnswers = new Array(sections.length).fill(new Map());
      // We want to keep any existing indices where they are
      answers.forEach((answerMap, index) => newAnswers.splice(index, 1, answerMap));
      setAnswers(newAnswers);
    }
  }, [sections]);

  useEffect(() => {
    if (!addSignature || !downloadSignatureFile) return;
    downloadSignatureFile(selectedDoctor?.staffInfo?.signatureS3Key, selectedDoctor?.id);
  }, [addSignature]);

  function onChangeAnswer({ values, templateQuestionId }: OnChangeAnswerProps): void {
    const newAnswers = [...answers];

    if (!newAnswers[currentTab]) {
      newAnswers[currentTab] = new Map();
    }

    newAnswers[currentTab].set(templateQuestionId, []);

    resetAnswersOnChange(templateQuestionId, answers);

    const mappedValues = values.map((val: AnswerValueType) => {
      return {
        value: val.value,
        questionOptionId: val?.questionOptionId || 0, // default to 0
        templateQuestionId: templateQuestionId,
      };
    });

    if (mappedValues) {
      newAnswers[currentTab].set(templateQuestionId, mappedValues);
    }

    setAnswers(newAnswers);
  }

  function populateAnswersTab(tabFill) {
    if (answers[tabFill].size === 0) {
      questions?.[tabFill]?.forEach((q: QuestionTemplate) => {
        q.answers.forEach((a: QuestionTemplateAnswer) => {
          const arrayOfAnswersToAddToMap: QuestionTemplateAnswer[] =
            answers[tabFill].get(a.templateQuestionId) || [];
          const uniqueArrayOfAnswersToAddToMap = _.uniqBy(
            arrayOfAnswersToAddToMap,
            'questionOptionId'
          );
          answers[tabFill].set(a.templateQuestionId, uniqueArrayOfAnswersToAddToMap);
        });
      });
    }
  }

  populateAnswersTab(currentTab);

  answers.forEach((answerSectionArray: Map<number, QuestionTemplateAnswer[]>) => {
    answerSectionArray.forEach((questionAnswers: QuestionTemplateAnswer[]) => {
      questionAnswers.forEach((a: QuestionTemplateAnswer) => {
        const arrayOfAnswersToAddToMap = answers[currentTab].get(a.templateQuestionId) || [];
        const uniqueArrayOfAnswersToAddToMap = _.uniqBy(
          arrayOfAnswersToAddToMap,
          'questionOptionId'
        );
        answers[currentTab].set(a.templateQuestionId, uniqueArrayOfAnswersToAddToMap);
      });
    });
  });

  const hasInvalidValue = (answerValueObjectsArray: QuestionTemplateAnswer[] | undefined) => {
    return answerValueObjectsArray?.some(answerValueObject => {
      return !answerValueObject.value && answerValueObject.value !== '';
    });
  };

  const hasOneValidAnswer = (answerValueObjectsArray: QuestionTemplateAnswer[] | undefined) => {
    return answerValueObjectsArray?.some(answerValueObject => {
      return !!answerValueObject.value;
    });
  };

  const setUnsavedAnswers = (
    questions: _.Dictionary<QuestionTemplate[]> | undefined,
    answers: Map<number, QuestionTemplateAnswer[]>[]
  ) => {
    return _.keys(questions).some(tab => {
      return questions?.[tab]?.some((question: QuestionTemplate) => {
        let answersArray = answers[tab]?.get(question.templateQuestionId);

        if (
          question.answers &&
          question.answers.length &&
          (!answersArray || !answersArray.length)
        ) {
          if (question.answers[0].value) {
            answersArray = [
              {
                questionOptionId: question.answers[0].questionOptionId || 0,
                templateQuestionId: question.templateQuestionId,
                value: question.answers[0].value,
              },
            ];
            answers[tab]?.set(question.templateQuestionId, answersArray);
          }
        }
      });
    });
  };

  // Checks if any required questions are incomplete, across all tabs
  const requiredQuestionUnanswered = (
    questions: _.Dictionary<QuestionTemplate[]> | undefined,
    answers: Map<number, QuestionTemplateAnswer[]>[]
  ) => {
    return _.keys(questions).some(tab => {
      return questions?.[tab]?.some((question: QuestionTemplate) => {
        if (question.required) {
          const showQuestion = checkIfQuestionIsShown(question, answers[tab]);
          const answersArray = answers[tab]?.get(question.templateQuestionId);
          const newAnswersValid = hasInvalidValue(answersArray) || !hasOneValidAnswer(answersArray);
          const existingAnswersValid =
            !question.answers?.length ||
            hasInvalidValue(question.answers) ||
            !hasOneValidAnswer(question.answers);
          // NOTE: This is a falsy check, true if invalid answer found false if valid answer
          // Only need to run validation on questions that are displayed to the end user
          return showQuestion && (answersArray?.length ? newAnswersValid : existingAnswersValid);
        }
      });
    });
  };

  const onSaveErrorDialogClose = () => {
    setSaveErrorDialogOpen(false);
  };

  const onFormSave = (
    questions: _.Dictionary<QuestionTemplate[]> | undefined,
    answers: Map<number, QuestionTemplateAnswer[]>[]
  ) => {
    if (!signatureFound && needsDoctorSignature) {
      setErrorDialogMessage('Doctor signature required for this form');
      setSaveErrorDialogOpen(true);
      return;
    }
    if (requiredQuestionUnanswered(questions, answers)) {
      setErrorDialogMessage('Please answer all required questions');
      setSaveErrorDialogOpen(true);
    } else {
      setUnsavedAnswers(questions, answers);
      onSave(answers);
    }
  };

  const switchTab = index => {
    const questionElement = document.getElementById('questions');
    if (questionElement) {
      questionElement.scrollTop = -10; //why -10? we're just trying to get as high as possible in the div, to the top, 0 is not the top.
    }
    setCurrentTab(index);
  };

  const onClose = () => {
    setSelectedMatzLocation(undefined);
    setMatzPopupVisible(false);
  };

  const handleDoctorSelection = event => {
    if (!doctors) return;
    const id = event.target.value;
    const doctor = doctors.find(doctor => doctor.id == id);
    setSelectedDoctor(doctor);

    if (addSignature && downloadSignatureFile && doctor?.staffInfo?.signatureS3Key) {
      downloadSignatureFile(selectedDoctor?.staffInfo?.signatureS3Key, doctor?.id);
    } else {
      if (setAddSignature) setAddSignature(false);
    }

    if (!doctor || !doctor.staffInfo || !description) return;

    const templateIds = formTemplateQuestionIds[description];
    if (!templateIds) return;

    onChangeAnswer({
      values: [{ value: doctor?.fullName }],
      templateQuestionId: templateIds.doctor,
    });
    if (doctor.id == MATZ_ID) {
      onChangeAnswer({
        values: [{ value: '' }],
        templateQuestionId: templateIds.billing,
      });
      setMatzPopupVisible(true);
    } else {
      onChangeAnswer({
        values: [{ value: doctor?.staffInfo?.ohipPhysicianId }],
        templateQuestionId: templateIds.billing,
      });
    }
  };

  const handleMatzLocationSelection = event => {
    const location = event.target.value;

    setSelectedMatzLocation(location);

    if (!description) return;

    const templateIds = formTemplateQuestionIds[description];
    if (!templateIds) return;

    onChangeAnswer({
      values: [{ value: MATZ_BILLING_NUMBERS[location] }],
      templateQuestionId: templateIds.billing,
    });
  };

  useEffect(() => {
    if (!description) return;
    const templateIds = formTemplateQuestionIds[description];
    if (templateIds?.fax) {
      onChangeAnswer({
        values: [{ value: CVC_FAX_NUMBER }],
        templateQuestionId: templateIds.fax,
      });
    }
  }, [description]);

  return (
    <Layout hideFooter>
      <Dialog open={matzPopupVisible} onClose={onClose} title={`Please Select Location`}>
        <div className={styles.selectdiv}>
          <Select
            label="Select Location"
            name={'location'}
            fullWidth
            onChange={handleMatzLocationSelection}
            value={selectedMatzLocation ? selectedMatzLocation : ''}
          >
            <MenuItem key={'NS'} value={'NS'}>
              Nova Scotia
            </MenuItem>
            <MenuItem key={'NB'} value={'NB'}>
              New Brunswick
            </MenuItem>
            <MenuItem key={'ON'} value={'ON'}>
              Ontario
            </MenuItem>
          </Select>
        </div>
        <div className={styles.buttondiv}>
          <Button className={styles.button} onClick={onClose}>
            OK
          </Button>
        </div>
      </Dialog>
      <div className={styles.container}>
        {needsDoctorSignature && (
          <div className={styles.doctorSelection}>
            <div className={styles.selection}>
              <Select
                label="Select Doctor"
                name={'doctor'}
                fullWidth
                onChange={handleDoctorSelection}
                value={selectedDoctor?.id ? selectedDoctor?.id : ''}
              >
                {doctors?.map(item => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.fullName}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            {selectedDoctor && (
              <div className={styles.addSignature}>
                {selectedDoctor?.staffInfo?.signatureS3Key ? (
                  <div>
                    <Checkbox
                      title="Add Signature"
                      checked={addSignature}
                      disabled={!selectedDoctor}
                      onChange={event => {
                        if (setAddSignature) setAddSignature(event.target.checked);
                      }}
                    />
                    {signatureFound && <div>Signature Found</div>}
                  </div>
                ) : (
                  <div>No Signature Uploaded</div>
                )}
              </div>
            )}
          </div>
        )}
        <div className={styles.main}>
          <ConditionDiv
            condition={
              !!formId &&
              description != FormDescriptions.FOLLOW_UP &&
              description != FormDescriptions.REQUISITION &&
              description != FormDescriptions.MEDICAL_COMPRESSION_PRESCRIPTION &&
              description != FormDescriptions.MEDICAL_PRESCRIPTION
            }
            className={styles.previewContainer}
          >
            <PreviewPanel formId={typeof formId === 'string' ? parseInt(formId) : formId} />
          </ConditionDiv>
          <div className={styles.formContainer}>
            <div className={styles.header}>
              <ConditionDiv
                condition={!!firstName && !!lastName}
                className={styles.previewContainer}
              >
                Patient : {firstName} {lastName}
              </ConditionDiv>
              <h1>{description}</h1>
              <ConditionDiv className={styles.headTabs} condition={sections.length > 1}>
                <p>Sections: </p>
                <div className={styles.tabs}>
                  {sections.map((section, index) => (
                    <div key={index}>
                      <Link
                        key={section.sectionName}
                        className={classNames(styles.tab, index === currentTab && styles.active)}
                        onClick={() => {
                          switchTab(index);
                        }}
                      >
                        {section.sectionName}
                      </Link>
                    </div>
                  ))}
                </div>
              </ConditionDiv>
            </div>
            <div id="questions" className={styles.questions}>
              {questions?.[currentTab]?.map(question => {
                return (
                  <VisibleQuestions
                    key={question.id}
                    question={question}
                    currentTabAnswers={answers[currentTab]}
                    answers={answers}
                    currentTab={currentTab}
                    checkIfQuestionIsShown={checkIfQuestionIsShown}
                    resetAnswersForHiddenQuestions={resetAnswersForHiddenQuestions}
                    onChangeAnswer={onChangeAnswer}
                  />
                );
              })}
            </div>
            <div className={styles.buttons}>
              <DialogButton className={styles.button} onClick={onCancel}>
                Cancel
              </DialogButton>
              <DialogButton
                className={styles.button}
                onClick={() => {
                  onFormSave(questions, answers);
                }}
              >
                Save
              </DialogButton>
            </div>
          </div>
        </div>
      </div>

      <Dialog open={isSaveErrorDialogOpen} title={'Save Error'} onClose={onSaveErrorDialogClose}>
        <div className={classNames(styles.dialogBody, styles.withPadding)}>
          {errorDialogMessage}
        </div>
      </Dialog>
    </Layout>
  );
}

export default Form;
