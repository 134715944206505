import React, { ReactElement, ReactNode } from 'react';
import MaterialDialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import ConditionDiv from 'components/ConditionDiv';

import styles from './Dialog.module.scss';

interface DialogProps {
  open?: boolean;
  title?: string;
  onClose?: () => void;
  children?: ReactNode;
  fullWidth?: boolean;
}

function Dialog({
  open = false,
  title,
  onClose,
  children,
  fullWidth = false,
}: DialogProps): ReactElement {
  return (
    <MaterialDialog open={open} fullWidth={fullWidth} className={styles.dialog} maxWidth={'xl'}>
      <div className={styles.container}>
        <ConditionDiv className={styles.header} condition={!!title || !!onClose}>
          <div className={styles.title}>{title}</div>
          <ConditionDiv className={styles.closeButton} condition={!!onClose}>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </ConditionDiv>
        </ConditionDiv>
        {children}
      </div>
    </MaterialDialog>
  );
}

export default Dialog;
