import React, { ReactElement } from 'react';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import MailIcon from '@material-ui/icons/Mail';

import { Location, WeeklyScheduleAvailability } from 'types';
import styles from './LocationInfo.module.scss';

interface LocationInfoProps {
  location: Location;
  weeklyScheduleAvailability: WeeklyScheduleAvailability;
  setSelectedLocationId: (id: number) => void;
  selectedLocationId: number;
  onMailScheduleClick: () => void;
}

function LocationInfo({
  location,
  weeklyScheduleAvailability,
  setSelectedLocationId,
  selectedLocationId,
  onMailScheduleClick,
}: LocationInfoProps): ReactElement {
  const sorter = {
    Su: 0,
    Mo: 1,
    Tu: 2,
    We: 3,
    Th: 4,
    Fr: 5,
    Sa: 6,
  };

  const isActive = selectedLocationId === location.id;
  const hasConsultation = Object.keys(weeklyScheduleAvailability['consultation']).length !== 0;
  const hasProcedure = Object.keys(weeklyScheduleAvailability['procedure']).length !== 0;
  const hasUltrasound = Object.keys(weeklyScheduleAvailability['ultrasound']).length !== 0;
  const consultationAvailability = Object.keys(weeklyScheduleAvailability['consultation']).sort(
    (a, b) => {
      return sorter[a] - sorter[b];
    }
  );
  const procedureAvailability = Object.keys(weeklyScheduleAvailability['procedure']).sort(
    (a, b) => {
      return sorter[a] - sorter[b];
    }
  );
  const ultrasoundAvailability = Object.keys(weeklyScheduleAvailability['ultrasound']).sort(
    (a, b) => {
      return sorter[a] - sorter[b];
    }
  );
  const renderScheduleForLocation = () => {
    return (
      <div className={styles.appointmentInfoTypeContainer}>
        <div className={styles.apptSummary}>
          <p
            className={classNames(
              styles.apptLabel,
              hasConsultation ? styles.active : styles.notActive
            )}
          >
            Consultation
          </p>
          <div className={styles.apptDays}>
            {consultationAvailability?.map((day, i, arr) => {
              return (
                <p
                  key={day}
                  className={
                    /* eslint-disable react/prop-types */
                    weeklyScheduleAvailability['consultation'][day] ? styles.partial : ''
                  }
                >
                  {day}
                  {arr.length - 1 === i ? '' : ','}
                </p>
              );
            })}
          </div>
        </div>
        <div className={styles.apptSummary}>
          <p
            className={classNames(
              styles.apptLabel,
              hasProcedure ? styles.active : styles.notActive
            )}
          >
            Procedure
          </p>
          <div className={styles.apptDays}>
            {procedureAvailability?.map((day, i, arr) => {
              return (
                <p
                  key={day}
                  className={
                    /* eslint-disable react/prop-types */
                    weeklyScheduleAvailability['procedure'][day] ? styles.partial : ''
                  }
                >
                  {day}
                  {arr.length - 1 === i ? '' : ','}
                </p>
              );
            })}
          </div>
        </div>
        <div className={styles.apptSummary}>
          <p
            className={classNames(
              styles.apptLabel,
              hasUltrasound ? styles.active : styles.notActive
            )}
          >
            Ultrasound
          </p>
          <div className={styles.apptDays}>
            {ultrasoundAvailability?.map((day, i, arr) => {
              return (
                <p
                  key={day}
                  className={
                    /* eslint-disable react/prop-types */
                    weeklyScheduleAvailability['ultrasound'][day] ? styles.partial : ''
                  }
                >
                  {day}
                  {arr.length - 1 === i ? '' : ','}
                </p>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={classNames(styles.locationInfo, isActive && styles.active)}>
      <div className={styles.appointmentInfoLocContainer}>
        <p className={styles.locationName}>{location.name}</p>
        {isActive ? (
          <div title="Email schedule">
            <MailIcon className={styles.mailIcon} onClick={onMailScheduleClick} />
          </div>
        ) : (
          <Button
            className={styles.viewButton}
            onClick={() => {
              setSelectedLocationId(location.id);
            }}
          >
            View
          </Button>
        )}
      </div>
      <div className={styles.appointmentInfoTypeContainer}>{renderScheduleForLocation()}</div>
    </div>
  );
}

export default LocationInfo;
