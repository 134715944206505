import React, { ReactElement } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputBase from '@material-ui/core/InputBase';
import FormControl from '@material-ui/core/FormControl';
import withStyles from '@material-ui/core/styles/withStyles';

import styles from './Selector.module.scss';

const BootstrapInput = withStyles(() => ({
  input: {
    padding: 0,
    paddingBottom: '2px',
    color: '#B00000',
    fontWeight: 'bold',
    fontSize: '15px',
  },
}))(InputBase);

interface LocationProps {
  items: Array<any>;
  value?: any;
  label?: string;
  multiple?: boolean;
  [other: string]: any;
  readOnly?: boolean;
}

interface RenderValueProps {
  selected: any;
  items: Array<any>;
}

function getItemById(id: number, items: Array<any> = []): any | undefined {
  return items.find(item => item.id === id);
}

function RenderValue({ selected, items }: RenderValueProps): ReactElement {
  if (Array.isArray(selected) && selected.length && selected[0] === -1) return <div>All</div>;
  if (selected === -1) return <div>All</div>;

  return (
    <>
      {Array.isArray(selected) ? (
        selected.map(id => (
          <div className={styles.valueItem} key={`rval-${id}`}>
            {getItemById(id, items)?.name}
          </div>
        ))
      ) : (
        <div>{selected ? getItemById(selected, items)?.name : 'All'}</div>
      )}
    </>
  );
}

function Selector({
  items = [],
  value,
  label,
  onChange,
  readOnly,
  multiple = false,
  ...other
}: LocationProps): ReactElement {
  return (
    <div className={styles.container}>
      <div className={styles.title}>{label}:</div>
      <FormControl className={styles.selectContainer}>
        <Select
          {...other}
          id="demo-customized-select"
          value={multiple || value ? value : ''}
          multiple={multiple}
          input={<BootstrapInput />}
          onChange={onChange}
          defaultValue={-1}
          className={styles.selectComponent}
          renderValue={selected => <RenderValue selected={selected} items={items} />}
          disabled={readOnly}
        >
          <MenuItem selected className={styles.MenuItem} value={-1}>
            All
          </MenuItem>
          {items.map(item => (
            <MenuItem className={styles.MenuItem} key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default Selector;
