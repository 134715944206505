import axios from 'axios';

import { Task } from 'types';
import { GET_CASE, GET_TASKS } from 'lib/config';

import { getDefaultHeader } from '../utils';

export async function getAllTasksForUser(
  showCompleted = false,
  hideTodo = false
): Promise<Array<Task>> {
  const response = await axios.get(
    `${GET_TASKS}/me?showCompleted=${showCompleted}&hideTodo=${hideTodo}`,
    {
      headers: getDefaultHeader(),
    }
  );

  return response.data;
}

export async function getTasks(
  caseId: number | string,
  showCompleted = false,
  hideTodo = false
): Promise<Array<Task>> {
  const response = await axios.get(
    `${GET_CASE}/${caseId}/tasks?showCompleted=${showCompleted}&hideTodo=${hideTodo}`,
    {
      headers: getDefaultHeader(),
    }
  );

  return response.data;
}

export async function createTask(caseId: number, task): Promise<Task> {
  const response = await axios.post(`${GET_CASE}/${caseId}/tasks`, task, {
    headers: getDefaultHeader(),
  });

  return response.data;
}

export async function updateTask(taskId: number, taskToEdit): Promise<Task> {
  const response = await axios.put(`${GET_TASKS}/${taskId}`, taskToEdit, {
    headers: getDefaultHeader(),
  });

  return response.data;
}
