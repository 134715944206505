import React, { ReactElement } from 'react';
import styles from './MonthHeaderStyles.module.scss';

const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

interface DayHeaderProps {
  date?: Date;
  label?: string;
  [rest: string]: any;
}

function MonthHeader({ date = new Date(), ...rest }: DayHeaderProps): ReactElement {
  return (
    <div {...rest} className={styles.container}>
      {DAYS[date?.getDay()]}
    </div>
  );
}

export default MonthHeader;
